import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useGetCartItems from "../hooks/useGetCartItems";
import useGetPaymentDetails from "../hooks/useGetPaymentDetails";
import { useGetProductDetails } from "../hooks/useGetProductDetails";
import useGetRewards from "../hooks/useGetRewards";
import { useMakeCartPurchase } from "../hooks/useMakeCartPurchase";
import { useMakeSinglePurchase } from "../hooks/useMakeSinglePurchase";
import { convertCash, convertPoints } from "../util/utilFunctions";

export const CheckoutContext = createContext();

const CheckoutHOC = (props) => {
  const userId = useSelector((state) => state.user.id);
  const referralId = useSelector((state) => state.currentState.referralId);
  const makeSinglePurchase = useMakeSinglePurchase();
  const makeCartPurchase = useMakeCartPurchase();
  const { data: cart } = useGetCartItems(userId);
  const { product } = useGetProductDetails(props?.route?.params?.product_id);
  const { data: paymentDetails } = useGetPaymentDetails();
  const { data: points } = useGetRewards("allRewards");
  const [percentOffDiscount, setPercentOffDiscount] = useState();
  const [onChangeEndValue, setOnChangeEndValue] = useState(
    points && points[0].points
  );
  const singlePrice = product?.skus[props?.route?.params?.sku_id]?.retail_price;

  const cartPrice = cart
    ?.filter((p) => p?.product_sku != null)
    .map((k) => k?.product_sku?.retail_price * k?.number)
    .reduce((j, k) => j + k, 0);
  const bundledItemsCount = cart
    ?.filter((k) => k.product_sku !== null && k.product_bundle)
    ?.map((k) => k?.number)
    .reduce((j, k) => j + k, 0);
  let p = points && points[0] && points[0].points ? points[0].points : 0;
  const higherPoints =
    convertCash(
      props?.route?.params?.type === "single" ? singlePrice : cartPrice
    ) < p
      ? convertCash(
          props?.route?.params?.type === "single" ? singlePrice : cartPrice
        )
      : p;
  const [onChangeValue, setOnChangeValue] = useState(higherPoints);
  const [promoCode, setPromoCode] = useState();
  useEffect(() => {
    setOnChangeValue(higherPoints);
    setOnChangeEndValue(higherPoints);
  }, [higherPoints]);

  const [redeemPercent, setRedeemPercent] = useState([
    {
      label: 25,
      pressed: false,
    },
    {
      label: 50,
      pressed: false,
    },
    {
      label: 100,
      pressed: false,
    },
  ]);

  const singlePurchase = () =>
    makeSinglePurchase(
      paymentDetails?.card[0]?.id, //card id
      paymentDetails?.shipping_address[0]?.id, // shipping_address_id,
      paymentDetails?.shipping_address[0]?.id, //  billing_address_id,
      product?.skus[props?.route?.params?.sku_id]?.deal
        ? product?.skus[props?.route?.params?.sku_id]?.deal_price // amount of deal product
        : product?.skus[props?.route?.params?.sku_id].retail_price, // amount of regular product
      props?.route?.params?.sku_id, //sku_id
      onChangeEndValue, //redeemed_points
      convertPoints(onChangeEndValue), //rewards_discount
      percentOffDiscount,
      promoCode,
      {
        productId: props?.route?.params?.product_id,
        productSkuId: props?.route?.params?.sku_id,
        brandName: props?.product?.skus[route?.params?.sku_id]?.brand_name,
        productName: props?.product?.skus[route?.params?.sku_id]?.product_name,
        productRetailPrice:
          product?.skus[props?.route?.params?.sku_id].retail_price,
        promoCode: promoCode ? promoCode : "No Promo Code Applied",
        screen: props?.route?.name,
      },
      props?.route?.params?.source,
      props?.route?.params?.otherUserId,
      referralId
    );
  const multiplePurchase = () =>
    makeCartPurchase(
      paymentDetails && paymentDetails?.card && paymentDetails?.card[0]?.id,
      paymentDetails &&
        paymentDetails?.shipping_address &&
        paymentDetails?.shipping_address[0]?.id,
      paymentDetails &&
        paymentDetails?.shipping_address &&
        paymentDetails?.shipping_address[0]?.id,
      cartPrice,
      bundledItemsCount < 3 ? onChangeEndValue : 0,
      bundledItemsCount < 3 ? convertPoints(onChangeEndValue) : 0,
      percentOffDiscount,
      promoCode,
      cart?.filter((p) => p?.product_sku != null)
    );

  const onCheckout = () => {
    if (props?.route?.params?.type === "single") {
      singlePurchase();
    } else {
      multiplePurchase();
    }
  };

  const isDetailsFilled =
    paymentDetails?.card[0]?.id && paymentDetails?.shipping_address[0]?.id;

  return (
    <CheckoutContext.Provider
      value={{
        onCheckout,
        price:
          props?.route?.params?.type === "single"
            ? singlePrice?.toFixed(2)
            : cartPrice?.toFixed(2),
        shippingAddress: paymentDetails?.shipping_address[0],
        paymentCard: paymentDetails?.card[0],
        rewardsDiscount:
          props?.route?.params?.type === "single" || bundledItemsCount < 3
            ? convertPoints(onChangeEndValue)
            : null,
        percentOffDiscount:
          (props?.route?.params?.type === "single" || bundledItemsCount < 3) &&
          percentOffDiscount,
        setPercentOffDiscount,
        promoCode,
        setPromoCode,
        redeemPercent,
        setRedeemPercent,
        higherPoints,
        onChangeValue,
        setOnChangeValue,
        setOnChangeEndValue,
        product,
        cart,
        applyOtherDiscounts:
          bundledItemsCount < 3 || props?.route?.params?.type === "single",
        bundledItemsCount,
        isDetailsFilled,
      }}
    >
      {props.children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutHOC;
