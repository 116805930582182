import { Image, Keyboard } from "react-native";
import {
  HStack,
  VStack,
  Text,
  Pressable,
  Divider,
  FlatList,
  View,
  Input,
  Stack,
  Box,
  useColorModeValue,
} from "native-base";
import { useState } from "react";
import ChevronLeftArrowIcon from "../../components/icons/chevronLeftArrowIcon";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import {
  ProductType,
  SubproductType1,
  SubproductType2,
  SubproductType1Mapping,
  SubproductType2Mapping,
} from "../../constants/categories";
import { useDispatch } from "react-redux";
import {
  resetProductsFilters,
  setApplyProductsFilter,
} from "../../redux/productsFilter/actions";
import { changeProductsFilter } from "../../redux/productsFilter/actions";
import { reloadProducts, setProducts } from "../../redux/products/actions";
import { Pages } from "../../constants/pages";
import { setAnalytics } from "../../analytics";
import { Brands } from "../../constants/brands";
import { viewBrand } from "../../redux/brand/actions";
import SearchIconOutlined from "../../components/icons/searchIconOutlined";
import { useEffect } from "react";

const CategoriesScreen = ({ navigation, route }) => {
  const [category, setCategory] = useState("0");
  const backgroundColor = useColorModeValue("white", "#111315");
  const dispatch = useDispatch();
  const [brandResults, setBrandResults] = useState(Object.keys(Brands));

  useEffect(() => {
    setBrandResults(Object.keys(Brands));
    dispatch(resetProductsFilters());
    dispatch(setApplyProductsFilter());
    dispatch(reloadProducts(true));
    dispatch(setProducts([]));
  }, []);
  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const changeVal = async (val) => {
    let brResults = Object.keys(Brands).filter((brand) => {
      return Brands[brand].toLowerCase().includes(val.toLowerCase());
    });
    setBrandResults(brResults);
  };

  const brandsKey = (item, index) => {
    return item.id;
  };
  const getItemLayoutBrands = (data, index) => ({
    length: 70,
    offset: 70 * index,
    index,
  });
  const renderBrands = ({ item }) => (
    <VStack rounded={8} bg="primary.card" h="70px" justifyContent={"center"}>
      <Pressable
        onPress={() => {
          setAnalytics("Brand Search", "Brand Search Input Box", {
            brand: item.name,
            screen: route.name,
          });

          dispatch(resetProductsFilters());
          let brand = getKeyByValue(Brands, item.name);
          dispatch(
            viewBrand({
              brand: item.name,
            })
          );
          dispatch(
            changeProductsFilter({
              brands: [brand],
              type: "brand",
            })
          );
          dispatch({
            type: "SET_APPLY_PRODUCTS_FILTER",
          });
          navigation.navigate(Pages.CategoryScreen, {
            defaultValue: item.name,
            type: "brand",
            fetcherType: "categoryProducts",
          });
        }}
      >
        <HStack
          alignItems={"center"}
          justifyContent={"space-between"}
          pr={2}
          pl={4}
        >
          <Text
            fontSize={14}
            lineHeight={21}
            style={{
              fontFamily: "Inter_700Bold",
            }}
            color={"white"}
          >
            {item.name}
          </Text>
          <ChevronLeftArrowIcon />
        </HStack>
      </Pressable>
    </VStack>
  );

  const iconMap = {
    "Bath & Body": require("./../../assets/images/pngs/bathAndBody.png"),
    Fragrance: require("./../../assets/images/pngs/fragrance.png"),
    Skincare: require("./../../assets/images/pngs/skincare.png"),
    Makeup: require("./../../assets/images/pngs/makeupicon.png"),
    Hair: require("./../../assets/images/pngs/hair.png"),
    "Tools & Brushes": require("./../../assets/images/pngs/brush.png"),
    "Value & Gift Sets": require("./../../assets/images/pngs/gift.png"),
    Brands: require("./../../assets/images/pngs/hair.png"),
    Men: require("./../../assets/images/pngs/men.png"),
  };
  return (
    <>
      {SubproductType2 && navigation.isFocused() && (
        <>
          <HStack pt="20px" flex={1}>
            <View w={"80px"} borderWidth={1} borderRightColor={"#2E303C"}>
              <FlatList
                showsVerticalScrollIndicator={false}
                initialNumToRender={6}
                ItemSeparatorComponent={() => (
                  <View style={{ paddingVertical: 4 }}></View>
                )}
                contentContainerStyle={{
                  flexDirection: "column",
                  paddingVertical: 20,
                  alignItems: "center",
                }}
                data={Object.keys(ProductType)}
                renderItem={({ item, index }) => (
                  <Pressable
                    w={"80px"}
                    onPress={() => {
                      setAnalytics("Product type", "Product Type Clicked", {
                        productType: ProductType[item],
                        screen: route.name,
                      });
                      setCategory(item);
                      setBrandResults(Object.keys(Brands));
                    }}
                  >
                    <HStack w={"100%"} alignItems={"center"}>
                      <VStack
                        w={"95%"}
                        space={2}
                        alignItems={"center"}
                        h="63px"
                      >
                        <Image
                          style={{
                            height: 26,
                            width: 26,
                            resizeMode: "contain",
                            resizeMethod: "scale",
                          }}
                          source={iconMap[ProductType[item]]}
                          alt=""
                        />

                        <Text
                          textAlign={"center"}
                          color={
                            item === category || (index === 0 && category === 1)
                              ? "white"
                              : "#94A3B8"
                          }
                          fontSize={10}
                          lineHeight={15}
                          style={{
                            fontFamily: "Inter_700Bold",
                          }}
                        >
                          {ProductType[item]}
                        </Text>
                      </VStack>
                      <Divider
                        orientation="vertical"
                        bg={
                          item === category || (index === 0 && category === 1)
                            ? "#8D49DE"
                            : backgroundColor
                        }
                        thickness="4"
                      />
                    </HStack>
                  </Pressable>
                )}
              />
            </View>
            <VStack flex={1} p={"16px"} space={5}>
              {category === "0" ? (
                <Input
                  h="40px"
                  borderWidth={1}
                  borderColor={"#2E303C"}
                  rounded={8}
                  InputRightElement={
                    <Stack px={4}>
                      <SearchIconOutlined size={4} />
                    </Stack>
                  }
                  color="white"
                  _focus={{
                    borderWidth: "1px",
                    borderColor: "#2E303C",
                    backgroundColor: "black",
                    color: "white",
                  }}
                  onChangeText={(val) => {
                    setAnalytics(
                      "Products Tab Brands Search Bar",
                      "Search Bar Clicked",
                      {
                        text: val,
                      }
                    );

                    changeVal(val);
                  }}
                  autoCapitalize="none"
                  fontSize={16}
                  lineHeight={24}
                  style={{
                    fontFamily: "HK_400Regular",
                  }}
                  placeholder="Find Brands"
                />
              ) : (
                <Pressable
                  onPress={() => {
                    setAnalytics("View all", "View all clicked", {
                      category: category,
                      screen: route.name,
                    });
                    dispatch(resetProductsFilters());
                    let vals = [category];

                    dispatch(
                      changeProductsFilter({ productTypes: vals, type: "all" })
                    );
                    dispatch({
                      type: "SET_APPLY_PRODUCTS_FILTER",
                    });
                    navigation.navigate(Pages.CategoryScreen, {
                      defaultValue: ProductType[category],
                      category: category,

                      dropDownDefaultValue: "All",
                      dropDown: "subproduct_type1",
                      fetcherType: "categoryProducts",
                    });
                  }}
                >
                  <HStack
                    px={2}
                    py={3}
                    borderWidth={1}
                    borderColor={"#2E303C"}
                    rounded={8}
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <Text color={"white"}>View all</Text>

                    <ChevronLeftArrowIcon />
                  </HStack>
                </Pressable>
              )}

              <FlatList
                keyboardShouldPersistTaps="handled"
                ItemSeparatorComponent={() => (
                  <View style={{ paddingVertical: 7 }}></View>
                )}
                ListEmptyComponent={
                  <>
                    <FlatList
                      keyExtractor={brandsKey}
                      keyboardShouldPersistTaps="handled"
                      showsVerticalScrollIndicator={false}
                      ItemSeparatorComponent={() => (
                        <Box style={{ paddingVertical: 7 }}></Box>
                      )}
                      data={brandResults}
                      renderItem={renderBrands}
                      getItemLayout={getItemLayoutBrands}
                    />
                  </>
                }
                data={
                  category === "0"
                    ? brandResults
                    : SubproductType1Mapping[category]
                }
                renderItem={({ item }) => (
                  <VStack
                    rounded={8}
                    bg="primary.card"
                    h="70px"
                    justifyContent={"center"}
                  >
                    <Pressable
                      onPress={() => {
                        Keyboard.dismiss();
                        setAnalytics(
                          "subProductType1",
                          "subProductType1 clicked",
                          {
                            subProductType1Name:
                              category === "0"
                                ? item
                                : SubproductType1[item]?.label,
                            screen: route.name,
                          }
                        );

                        dispatch(resetProductsFilters());
                        if (category === "0") {
                          let brand = getKeyByValue(Brands, Brands[item]);
                          dispatch(viewBrand({ brand: Brands[item] }));
                          dispatch(
                            changeProductsFilter({
                              brands: [brand],
                              type: "brand",
                            })
                          );
                          dispatch({
                            type: "SET_APPLY_PRODUCTS_FILTER",
                          });
                          navigation.navigate(Pages.CategoryScreen, {
                            defaultValue: Brands[item],
                            type: "brand",
                            fetcherType: "categoryProducts",
                          });
                        } else {
                          let vals = [category];
                          let vals2 = [item];

                          dispatch(
                            changeProductsFilter({
                              type: "all",
                              productType: vals,
                              subproductType1: vals2,
                            })
                          );
                          dispatch({
                            type: "SET_APPLY_PRODUCTS_FILTER",
                          });
                          navigation.navigate(Pages.CategoryScreen, {
                            defaultValue: SubproductType1[item]?.label,
                            dropDownDefaultValue: "All",
                            subCategories: SubproductType2Mapping[item],
                            category: category,
                            dropDown: "subproduct_type2",
                            fetcherType: "categoryProducts",
                          });
                        }
                      }}
                    >
                      <HStack
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        pr={2}
                      >
                        <HStack alignItems={"center"} space="16px" h="60px">
                          <View
                            borderLeftRadius={8}
                            style={{
                              height: "100%",
                              overflow: "hidden",
                            }}
                          >
                            {category === "0" ? (
                              <></>
                            ) : (
                              <Image
                                style={{
                                  height: "100%",
                                  width: 65,
                                  resizeMode: "contain",
                                  resizeMethod: "scale",
                                }}
                                source={SubproductType1[item]?.icon}
                                alt=""
                              />
                            )}
                          </View>

                          <Text
                            fontSize={14}
                            lineHeight={21}
                            style={{
                              fontFamily: "Inter_700Bold",
                            }}
                            color={"white"}
                          >
                            {category === "0"
                              ? Brands[item]
                              : SubproductType1[item]?.label}
                          </Text>
                        </HStack>
                        <ChevronLeftArrowIcon />
                      </HStack>
                    </Pressable>
                  </VStack>
                )}
              />
            </VStack>
          </HStack>
          <BottomBar />
        </>
      )}
    </>
  );
};
export default CategoriesScreen;
