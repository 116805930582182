import {
  HStack,
  Text,
  VStack,
  Center,
  Pressable,
  useColorModeValue,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { privateApi } from "../../util/API";
// import ClaimRewardIcon from "./../../assets/images/svgs/claimActiveRewardIcon.svg";
// import InactiveClaimRewardIcon from "./../../assets/images/svgs/claimInActiveRewardIcon.svg";
import { useSWRConfig } from "swr";
import { setAnalytics } from "../../analytics";
import { setDialog } from "../../redux/currentState/actions";
import { Popups } from "../../constants/popups";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const RewardsCard = ({
  buttonText,
  cardHeading,
  cardSubHeading,
  onPress,
  points,
  isClaimable,
  actionType,
  dummy,
}) => {
  const userId = useSelector((state) => state.user.id);
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();
  const claimButtonColor = useColorModeValue("#FAF0FF", "#202427");
  return (
    <>
      <Center
        w="49%"
        h="114px"
        rounded="17px"
        bg={{
          linearGradient: isClaimable && {
            colors: [
              "primary.ctaLinearSecondColor",
              "primary.ctaLinearFirstColor",
            ],
            start: [0, 0],
            end: [1, 0],
          },
        }}
      >
        <VStack
          w="99.5%"
          _dark={{
            bg: "#202427",
          }}
          _light={{
            bg: isClaimable ? "#F5E0FF" : "#FAF0FF",
          }}
          h="112px"
          rounded="17px"
          px={"16px"}
          py={"14px"}
          space={3}
        >
          <HStack justifyContent={"space-between"}>
            <VStack>
              <Text
                _light={{
                  color: "#682481",
                }}
                _dark={{
                  color: "white",
                }}
                fontSize={"16px"}
                style={{
                  fontFamily: "HK_600SemiBold",
                }}
              >
                {cardHeading}
              </Text>
              <Text
                mt={-1}
                _light={{
                  color: "#682481",
                }}
                _dark={{
                  color: "#94A3B8",
                }}
                fontSize={"14px"}
                style={{
                  fontFamily: "HK_500Medium",
                }}
              >
                {cardSubHeading}
              </Text>
            </VStack>
            <Pressable
              onPress={async () => {
                if (isClaimable) {
                  setAnalytics("Claim Button", "Claimed Points", {
                    points: points,
                    action_type: actionType,
                    action_name: cardHeading,
                    isClaimable,
                  });
                  const res = await privateApi(
                    "/api/points/claim_rewards/",
                    {
                      user_id: userId,

                      action_type: actionType,
                    },
                    "put"
                  );

                  mutate(`${apiUrl}/api/points/rewards/?user_id=${userId}`);
                }
              }}
            >
              <Center
                h="44px"
                width={"104px"}
                rounded={"10px"}
                bg={{
                  linearGradient: {
                    colors: [
                      "primary.ctaLinearFirstColor",
                      "primary.ctaLinearSecondColor",
                    ],
                    start: [0, 0],
                    end: [1, 1.2],
                  },
                }}
              >
                <HStack
                  rounded={"10px"}
                  width={"100px"}
                  h="40px"
                  space={1}
                  bg={{
                    linearGradient: isClaimable
                      ? {
                          colors: [
                            "primary.ctaLinearFirstColor",
                            "primary.ctaLinearSecondColor",
                          ],
                          start: [0, 0],
                          end: [1, 1.2],
                        }
                      : {
                          colors: [claimButtonColor, claimButtonColor],
                          start: [0, 0],
                          end: [1, 0],
                        },
                  }}
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <Text
                    fontSize={"14px"}
                    style={{
                      fontFamily: "HK_400Regular",
                    }}
                    _light={{
                      color: isClaimable ? "white" : "#E9B9FF",
                    }}
                    _dark={{
                      color: isClaimable ? "white" : "#58595B",
                    }}
                  >
                    Claim{" "}
                    <Text
                      fontSize={14}
                      style={{
                        fontFamily: "HK_700Bold",
                      }}
                      _light={{
                        color: isClaimable ? "white" : "#E9B9FF",
                      }}
                      _dark={{
                        color: isClaimable ? "white" : "#58595B",
                      }}
                    >
                      {actionType === 4 && isClaimable
                        ? points.toFixed(2)
                        : points}
                    </Text>
                  </Text>
                  {/* {isClaimable ? (
                    <ClaimRewardIcon />
                  ) : (
                    <InactiveClaimRewardIcon />
                  )} */}
                </HStack>
              </Center>
            </Pressable>
          </HStack>

          <Center>
            <Pressable
              onPress={() => {
                if (dummy) {
                  setAnalytics(
                    "Follow",
                    `Guest User Tried To Perform Rewards Action`,
                    {
                      action_name: cardHeading,
                      screen: "Rewards Screen",
                    }
                  );
                  dispatch(setDialog(Popups.LoginPopup));
                }
                if (!isClaimable && !dummy) {
                  onPress();
                }
              }}
            >
              <Center
                w="200px"
                h="32px"
                rounded="full"
                bg={{
                  linearGradient: !dummy
                    ? {
                        colors: [
                          "primary.ctaLinearSecondColor",
                          "primary.ctaLinearFirstColor",
                        ],
                        start: [0, 0],
                        end: [1, 0],
                      }
                    : {
                        colors: [claimButtonColor, claimButtonColor],
                        start: [0, 0],
                        end: [1, 0],
                      },
                }}
              >
                <Center
                  w="198px"
                  h="30px"
                  rounded="full"
                  _dark={{
                    bg: "#202427",
                  }}
                  _light={{
                    bg: isClaimable ? "#F5E0FF" : "#FAF0FF",
                  }}
                >
                  <Text
                    textTransform={"uppercase"}
                    letterSpacing={2}
                    fontSize={"11px"}
                    style={{
                      fontFamily: "HK_600SemiBold",
                    }}
                    _light={{
                      color: "#682481",
                    }}
                    _dark={{
                      color: "white",
                    }}
                  >
                    {buttonText}
                  </Text>
                </Center>
              </Center>
            </Pressable>
          </Center>
        </VStack>
      </Center>
    </>
  );
};

export default RewardsCard;
