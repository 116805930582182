import { FlatList, View, Button } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { HairTextures } from "../../constants/hairTextures";
import { changeFilter } from "../../redux/communityFilter/actions";

const Item = ({ item }) => {
  const hairTextureFilter = useSelector(
    (state) => state.communityFilter.hair_texture
  );
  const dispatch = useDispatch();
  return (
    <Button
      _hover={{
        bg: "#F5F5F5",
      }}
      onPress={() => {
        let vals = [...hairTextureFilter];
        if (vals.includes(item) === true) {
          vals = [...vals.filter((value) => value !== item)];
        } else {
          vals.push(item);
        }
        dispatch(changeFilter({ hair_texture: vals }));
        dispatch({
          type: "SET_APPLY_FILTER",
        });
      }}
      variant={"ghost"}
      _text={{
        fontFamily: "Hero_400Regular",

        letterSpacing: 2,
        color: "#000000",
        fontSize: "12px",
      }}
    >
      {HairTextures[item]}
    </Button>
  );
};

const HairTexture = () => {
  const renderItem = ({ item }) => <Item item={item} />;

  return (
    <FlatList
      bg="white"
      showsVerticalScrollIndicator={false}
      ItemSeparatorComponent={() => (
        <View style={{ paddingVertical: 2 }}></View>
      )}
      contentContainerStyle={{
        height: 200,
      }}
      data={Object.keys(HairTextures)}
      renderItem={renderItem}
    />
  );
};

export default HairTexture;
