import { Icon } from "native-base";
import { Path, G } from "react-native-svg";
const InActiveSearchIcon = () => {
  return (
    <Icon viewBox="0 0 28 28" size={6}>
      <G width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.6667 4.25C7.57056 4.25 4.25 7.57056 4.25 11.6667C4.25 15.7628 7.57056 19.0833 11.6667 19.0833C15.7628 19.0833 19.0833 15.7628 19.0833 11.6667C19.0833 7.57056 15.7628 4.25 11.6667 4.25ZM2.75 11.6667C2.75 6.74213 6.74213 2.75 11.6667 2.75C16.5912 2.75 20.5833 6.74213 20.5833 11.6667C20.5833 16.5912 16.5912 20.5833 11.6667 20.5833C6.74213 20.5833 2.75 16.5912 2.75 11.6667Z"
          fill="#94A3B8"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.9697 16.9697C17.2626 16.6768 17.7374 16.6768 18.0303 16.9697L25.0303 23.9697C25.3232 24.2626 25.3232 24.7374 25.0303 25.0303C24.7374 25.3232 24.2626 25.3232 23.9697 25.0303L16.9697 18.0303C16.6768 17.7374 16.6768 17.2626 16.9697 16.9697Z"
          fill="#94A3B8"
        />
      </G>
    </Icon>
  );
};

export default InActiveSearchIcon;
