import {
  CloseIcon,
  HStack,
  IconButton,
  Pressable,
  Text,
  VStack,
} from "native-base";
import BlueArrowIcon from "../../components/icons/blueArrowIcon";
import WebMenuIcon from "../../components/icons/webIcons/webMenuIcon";
import { Pages } from "../../constants/pages";

const Mainmenu = ({ navigation, route }) => {
  const menuItems = [
    {
      label: "Shop",
      onPress: () => {
        navigation.navigate("shopmenu");
      },
    },
    {
      label: "Beauty Buddies",
      onPress: () => {
        navigation.navigate(Pages.Community);
      },
    },
    {
      label: "Brands",
      onPress: () => {
        navigation.navigate(Pages.Brands);
      },
    },
  ];
  return (
    <>
      <VStack
        bg="#FAF1FF"
        h="full"
        space={8}
        w="full"
        roundedBottomLeft={"70px"}
      >
        <HStack
          pt={4}
          alignItems={"center"}
          justifyContent="space-between"
          w="full"
          px={4}
        >
          <HStack alignItems={"center"} space={2}>
            <WebMenuIcon />
            <Text
              fontSize={"36px"}
              style={{
                fontFamily: "AustinCyr_400",
              }}
              color="#682481"
            >
              Menu
            </Text>
          </HStack>
          <IconButton
            onPress={() => {
              navigation.goBack();
            }}
            size={8}
            _icon={{
              color: "#682481",
            }}
            icon={<CloseIcon />}
          ></IconButton>
        </HStack>
        <VStack px={6} space={6}>
          {menuItems?.map((k, i) => (
            <Pressable
              w="full"
              flexDir={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              onPress={() => {
                k?.onPress();
              }}
              _hover={{
                bg: route?.name === "Home" ? "none" : "#FAF1FF",
              }}
            >
              <Text
                textTransform={"uppercase"}
                fontSize={"36px"}
                style={{
                  fontFamily: "AustinCyr_400",
                }}
                color="#682481"
              >
                {k?.label}
              </Text>
              {(i === 0 || i === 2) && <BlueArrowIcon color="#F3A9FF" />}
            </Pressable>
          ))}
        </VStack>
      </VStack>
    </>
  );
};

export default Mainmenu;
