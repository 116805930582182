import { Icon, Image } from "native-base";

const EducationIcon = () => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Image
        resizeMethod="scale"
        resizeMode="contain"
        source={require("./../../assets/images/pngs/education.png")}
        alt=""
      />
    </>
  );
};

export default EducationIcon;
