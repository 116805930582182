import { Divider, Modal, ScrollView, Slide, Text, VStack } from "native-base";
import { useState } from "react";
import { closeDialog } from "../../redux/currentState/actions";
import { useDispatch } from "react-redux";
import * as RootNavigation from "./../../RootNavigation";
import { useGetKeyboardStatus } from "../../hooks/useGetKeyboardStatus";
import { setAnalytics } from "../../analytics";
import Login from "../../screens/login/login";
import PhoneLoginScreen from "../../screens/login/phoneLoginScreen";

const LoginPopup = () => {
  const dispatch = useDispatch();

  const keyboardStatus = useGetKeyboardStatus();
  const route = RootNavigation.navigationRef.getCurrentRoute();

  const [loginType, setLoginType] = useState([
    {
      pressed: true,

      label: "Email",
    },
    {
      pressed: false,

      label: "Phone",
    },
  ]);
  return (
    <>
      <Modal
        size={"full"}
        isOpen={true}
        bg="black:alpha.80"
        onClose={() => {
          setAnalytics("Close Login Pop Up", "Closed Login Pop Up", {
            popup: "LoginPopup",
            screen: route?.name,
          });
          dispatch(closeDialog());
        }}
      >
        <Slide overlay={false} in={true} placement="bottom">
          <VStack
            h={{
              md: "500px",
              lg: "570px",
              xl: "600px",
            }}
            roundedTop={"40px"}
            marginBottom={"0"}
            marginTop={"auto"}
            bg={"#2B2F33"}
            space={4}
            width="100%"
          >
            <VStack alignItems="center" space={4} w="full">
              <Divider
                mt={6}
                bg="rgba(217, 217, 217, 0.5)"
                h="3px"
                w="150px"
                rounded={"100px"}
              />
              <Text
                letterSpacing={3}
                textAlign={"center"}
                color="#fff"
                fontSize={"16px"}
                textTransform="uppercase"
                style={{
                  fontFamily: "HK_700Bold",
                }}
              >
                Login /sign up
              </Text>
            </VStack>
            <ScrollView
              w="full"
              showsVerticalScrollIndicator={false}
              keyboardShouldPersistTaps="handled"
            >
              <VStack
                w="full"
                pb={
                  keyboardStatus === "Keyboard Hidden" ||
                  keyboardStatus === undefined
                    ? 8
                    : 96
                }
              >
                {loginType[0]?.pressed && (
                  <Login
                    formType="popup"
                    loginType={loginType}
                    setLoginType={(val) => setLoginType(val)}
                  />
                )}
                {loginType[1]?.pressed && (
                  <PhoneLoginScreen
                    formType="popup"
                    loginType={loginType}
                    setLoginType={(val) => setLoginType(val)}
                  />
                )}
              </VStack>
            </ScrollView>
          </VStack>
        </Slide>
      </Modal>
    </>
  );
};

export default LoginPopup;
