import { useDispatch } from "react-redux";
import { getUser } from "../util/utilFunctions";
import { privateApi } from "../util/API";
import { hideLoader, showLoader } from "../redux/currentState/actions";
import { setAnalytics } from "../analytics";

export const useEditUserDetails = () => {
  const dispatch = useDispatch();

  const editUserDetails = async (form, category, event, eventProperties) => {
    dispatch(showLoader());
    const userId = await getUser();
    const res = await privateApi(
      "/api/users/user/",
      {
        id: userId,
        ...form,
      },
      "put"
    );

    console.log("/api/users/user/", res, form);

    dispatch(hideLoader());
    setAnalytics(category, event, { ...eventProperties, status: res.status });
  };

  return editUserDetails;
};
