import { useNavigation, useRoute } from "@react-navigation/native";
import { Box, HStack, IconButton, Progress } from "native-base";
import BackIcon from "../../icons/backIcon";

const SignUpTopBar = ({ progressBarFilled }) => {
  const navigation = useNavigation();
  const route = useRoute();

  return (
    <HStack
      shadow={9}
      zIndex={1}
      pt={{
        md: "50px",
        lg: "54px",
        xl: "56px",
      }}
      alignItems="center"
      px={2}
      justifyContent={
        route?.params?.type !== "forgetPassword" &&
        route?.name !== "ForgetPasswordScreen"
          ? "space-around"
          : "flex-start"
      }
    >
      <IconButton
        onPress={() => {
          navigation.goBack();
        }}
        size={8}
        _pressed={{
          bg: "black",
        }}
        icon={<BackIcon />}
      ></IconButton>
      {route?.name !== "ForgetPasswordScreen" &&
        route?.params?.type !== "forgetPassword" && (
          <Box w="200px">
            <Progress
              bg="#2E303C"
              _filledTrack={{
                bg: "#8D49DE",
              }}
              value={progressBarFilled}
            />
          </Box>
        )}
      <Box></Box>
    </HStack>
  );
};

export default SignUpTopBar;
