import { HStack, Image, Text, VStack, Pressable, Box } from "native-base";
import { Dimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import ChevronBackArrowIcon from "../../icons/chevronBackArrowIcon";
import ShareIcon from "../../icons/shareIcon";
import ExclaimationBlueEllipseIcon from "../../icons/exclaimationBlueEllipseIcon";
import RewardsAnimatedIcon from "../../icons/animatedIcons/rewardsAnimatedCoin";
//import RewardIcon from "./../../../assets/images/svgs/rewardIcon.svg";
import { useDispatch } from "react-redux";
import { Popups } from "../../../constants/popups";
import { setDialog } from "../../../redux/currentState/actions";
import useGetRewards from "../../../hooks/useGetRewards";
import { convertPoints } from "../../../util/utilFunctions";
//import FlockBucks from "./../../../assets/images/svgs/FLockbucks1.svg";
const RewardsScreenTopBar = (props) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { data } = useGetRewards("allRewards");

  return (
    <>
      <VStack
        space={4}
        safeAreaTop
        width={Dimensions.get("window").width}
        h={{
          md: "200px",
          lg: "250px",
          xl: "250px",
        }}
        bg={{
          linearGradient: {
            colors: [
              "primary.ctaLinearSecondColor",
              "primary.ctaLinearFirstColor",
            ],
            start: [0, 0],
            end: [1, 0],
          },
        }}
        // justifyContent={"space-between"}
        zIndex={1}
        px={"28px"}
      >
        <HStack justifyContent={"space-between"} alignItems="center">
          <ChevronBackArrowIcon
            onPress={() => {
              navigation.goBack();
            }}
          />

          {/* <FlockBucks /> */}
          <Box></Box>
          {/* <ShareIcon color={"white"} /> */}
        </HStack>

        <Text
          style={{
            fontFamily: "HK_500Medium",
          }}
          fontSize={16}
          lineHeight={20}
          color="white"
        >
          Earn Flockbucks, redeem for discounts on your next order!
        </Text>
        <HStack alignItems={"center"} justifyContent="space-between">
          <HStack alignItems={"center"} space={2}>
            {/* <RewardIcon /> */}
            <Text
              style={{
                fontFamily: "HK_600SemiBold",
              }}
              fontSize={"36px"}
              color="white"
            >
              {data && data[0].points}
            </Text>
          </HStack>
          <Pressable
            onPress={() => {
              dispatch(setDialog(Popups.RewardsInfoModal));
            }}
          >
            <HStack
              alignItems={"center"}
              space={"4px"}
              bg="#2E303C:alpha.20"
              rounded={"8px"}
              justifyContent="center"
              // w="130px"
              h="30px"
              px={2}
            >
              <ExclaimationBlueEllipseIcon />

              <Text
                style={{
                  fontFamily: "HK_400Regular",
                }}
                fontSize={16}
                lineHeight={20}
                color="white"
              >
                worth{" "}
                <Text
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                  fontSize={16}
                  lineHeight={20}
                  color="white"
                >
                  $
                  {data && data[0].points
                    ? convertPoints(data[0].points).toFixed(2)
                    : 0}
                </Text>
              </Text>
              <RewardsAnimatedIcon />
            </HStack>
          </Pressable>
        </HStack>
      </VStack>
    </>
  );
};

export default RewardsScreenTopBar;
