import {
  Avatar,
  Box,
  Center,
  Divider,
  HStack,
  Image,
  ScrollView,
  Text,
  VStack,
} from "native-base";
import FlockDealOrderInfoCard from "../../components/cards/flockDealOrderInfoCard";
import HowFlockDealsWorkCard from "../../components/cards/howFlockDealsWorkCard";
import LockIcon from "../../components/icons/lockIcon";
import ExitDealButton from "../../components/buttons/exitDealButton";
import InviteFriendsGradientButton from "../../components/buttons/inviteFriendsGradientButton";
import { privateApi } from "../../util/API";
import { getUser } from "../../util/utilFunctions";
import { useDispatch } from "react-redux";
import TimerChip from "../../components/chips/timerChip";
import { setDialog, showLoader } from "../../redux/currentState/actions";
import { hideLoader } from "../../redux/currentState/actions";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Popups } from "../../constants/popups";
import { Pages } from "../../constants/pages";
import { useGetGroupDetails } from "../../hooks/useGetGroupDetails";
import { useSWRConfig } from "swr";
import { setAnalytics } from "../../analytics";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const FlockDealExitGroupScreen = () => {
  const route = useRoute();
  const { data } = useGetGroupDetails(route?.params?.group_id);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { mutate } = useSWRConfig();

  const exitDeal = async () => {
    dispatch(showLoader());
    const userId = await getUser();
    if (userId !== undefined && userId !== null) {
      let res = await privateApi(
        "/api/groups/group_members/",
        { user_id: userId, group_id: route?.params?.group_id },
        "delete"
      );

      if (res.status === 200) {
        mutate(`${apiUrl}/api/groups/group/?page=${1}`);
        dispatch(hideLoader());
        setAnalytics("Exit Deal", "Exited Deal", {
          groupId: route?.params?.group_id,

          screen: route?.name,
        });
        navigation.navigate(Pages.Home);
      }
    } else {
      dispatch(setDialog(Popups.LoginPopup));
    }
    dispatch(hideLoader());
  };

  return (
    <>
      <ScrollView showsVerticalScrollIndicator={false}>
        <VStack px={2} py={4} space={5}>
          <FlockDealOrderInfoCard
            variants={[
              data && data.product && data.product[0] && data.product[0].color,
              data && data.product && data.product[0] && data.product[0].size,
              data && data.product && data.product[0] && data.product[0].scent,
              data && data.product && data.product[0] && data.product[0].type,
            ]}
            groupPrice={
              data &&
              data.product &&
              data.product[0] &&
              data.product[0].group_price
            }
            retailPrice={
              data &&
              data.product &&
              data.product[0] &&
              data.product[0].retail_price
            }
            groupDiscount={
              data &&
              data.product &&
              data.product[0] &&
              data.product[0].group_discount
            }
            image={
              data &&
              data.product &&
              data.product[0] &&
              data.product[0].images[0]
            }
            brandName={
              data &&
              data.product &&
              data.product[0] &&
              data.product[0].brand_name
            }
            productName={
              data &&
              data.product &&
              data.product[0] &&
              data.product[0].product_name
            }
            productId={route?.params?.id}
            productSkuId={
              data &&
              data.product &&
              data.product[0] &&
              data.product[0].product_sku_id
            }
          />
          <Center>
            <Divider width={24} bg={"#58595B"} />
          </Center>
          <HStack alignItems={"center"} justifyContent="space-between">
            <TimerChip
              time={data && data.product && data.product[0].starting_time}
              images={data && data.users}
            />
          </HStack>
          <HStack
            position={"relative"}
            justifyContent={"center"}
            alignItems="center"
            h={"100px"}
          >
            <Avatar
              left={"10%"}
              top={"16%"}
              position={"absolute"}
              size={"20px"}
              borderWidth={0}
              bg="#58595B"
              fallbackSource={{
                uri: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
              }}
              source={{
                uri: data && data.users && data.users[0] && data.users[0].image,
              }}
            ></Avatar>
            <Avatar
              left={"60%"}
              top={"16%"}
              position={"absolute"}
              size={"20px"}
              borderWidth={"1px"}
              borderStyle="dotted"
              borderColor={"#888990"}
              bg="black"
              source={{
                uri: data && data.users && data.users[2] && data.users[2].image,
              }}
            ></Avatar>
            <Box
              bg={{
                linearGradient: {
                  colors: ["#48176F", "#3C3C3C"],
                  start: [0, 0],
                  end: [1, 0],
                },
              }}
              roundedLeft={"xl"}
              width={"282px"}
              height={"8px"}
            >
              <Box
                bg={{
                  linearGradient: {
                    colors: [
                      "primary.ctaLinearFirstColor",
                      "primary.ctaLinearSecondColor",
                    ],
                    start: [0, 0],
                    end: [1, 0],
                  },
                }}
                rounded={"xl"}
                w={
                  data?.users?.length === 1
                    ? "1/3"
                    : data?.users?.length === 2
                    ? "2/3"
                    : "full"
                }
                height={"8px"}
              ></Box>
            </Box>
            {data?.users?.length > 2 ? (
              <Center>
                <Image
                  position={"absolute"}
                  size={24}
                  resizeMethod="scale"
                  resizeMode="cover"
                  source={require("./../../assets/images/gifs/productSplash.gif")}
                  alt=""
                />
                <Center
                  bg={{
                    linearGradient: {
                      colors: [
                        "primary.ctaLinearFirstColor",
                        "primary.ctaLinearSecondColor",
                      ],
                      start: [0, 0],
                      end: [1, 0],
                    },
                  }}
                  rounded="12px"
                  size="50px"
                >
                  <Image
                    size={"47px"}
                    rounded="12px"
                    resizeMethod="scale"
                    resizeMode="contain"
                    source={{
                      uri:
                        data &&
                        data.product &&
                        data.product[0].images &&
                        data.product[0].images[0],
                    }}
                    alt=""
                  ></Image>
                </Center>
              </Center>
            ) : (
              <Center size={"50px"} rounded="12px" bg="#2E303C">
                <LockIcon />
              </Center>
            )}
            <Avatar
              left={"35%"}
              top={"60%"}
              position={"absolute"}
              size={"20px"}
              borderWidth={"1px"}
              borderStyle="dotted"
              borderColor={"#888990"}
              bg="black"
              source={{
                uri: data && data.users && data.users[1] && data.users[1].image,
              }}
            ></Avatar>
          </HStack>
          <Center pt={2}>
            <Text
              letterSpacing={3}
              textAlign={"center"}
              color="#94A3B8"
              fontSize={"14px"}
              textTransform="none"
              style={{
                fontFamily: "HK_700Bold",
              }}
            >
              <Text
                textAlign={"center"}
                color="white"
                fontSize={"14px"}
                textTransform="none"
                style={{
                  fontFamily: "HK_600SemiBold",
                }}
              >
                {3 - data?.users?.length}/3{" "}
              </Text>
              SPOTS OPEN!{"\n"}
            </Text>
            <InviteFriendsGradientButton
              groupId={route?.params?.group_id}
              screen={route?.name}
            />
          </Center>

          <Center>
            <Divider width={24} bg={"#58595B"} />
          </Center>

          <HowFlockDealsWorkCard
            productImage={
              data &&
              data.product &&
              data.product[0] &&
              data.product[0].images[0]
            }
          />
        </VStack>
      </ScrollView>
      <Center bg={"#1A1C1E"} height={"100px"}>
        <ExitDealButton onPress={() => exitDeal()} />
      </Center>
    </>
  );
};

export default FlockDealExitGroupScreen;
