import { createContext } from "react";
import { useSelector } from "react-redux";
import { useAddItemToCart } from "../hooks/useAddItemToCart";
import { useDeleteFromCart } from "../hooks/useDeleteFromCart";
import useGetCartItems from "../hooks/useGetCartItems";
export const AddToCartContext = createContext();
const AddToCartHOC = (props) => {
  const deleteCart = useDeleteFromCart();
  const addItemToCart = useAddItemToCart();
  const userId = useSelector((state) => state.user.id);
  const { data } = useGetCartItems(userId);
  const inCart =
    data?.length > 0 &&
    data?.map((i) => i?.product_sku?.id).find((k) => k === props?.productSkuId);

  const onPress = () => {
    if (inCart) {
      deleteCart(props?.productSkuId);
    } else {
      addItemToCart(
        props?.productSkuId,
        props?.productId,
        props?.bundleId,
        props?.source,
        props?.otherUserId,
        props?.referralId
      );
    }
  };

  return (
    <AddToCartContext.Provider
      value={{
        onPress,
        inCart,
      }}
    >
      {props.children}
    </AddToCartContext.Provider>
  );
};

export default AddToCartHOC;
