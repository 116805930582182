import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const EyeIcon = ({ size = 6 }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={size} viewBox="0 0 24 25">
        <G
          width="24"
          height="25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M21.2565 11.462C21.7305 12.082 21.7305 12.919 21.2565 13.538C19.7635 15.487 16.1815 19.5 11.9995 19.5C7.81752 19.5 4.23552 15.487 2.74252 13.538C2.51191 13.2411 2.38672 12.8759 2.38672 12.5C2.38672 12.1241 2.51191 11.7589 2.74252 11.462C4.23552 9.513 7.81752 5.5 11.9995 5.5C16.1815 5.5 19.7635 9.513 21.2565 11.462V11.462Z"
            stroke="#888990"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z"
            stroke="#888990"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </G>
      </Icon>
    </>
  );
};

export default EyeIcon;
