import {
  HStack,
  VStack,
  Text,
  IconButton,
  Pressable,
  Center,
} from "native-base";

import ChevronLeftArrowIcon from "../icons/chevronLeftArrowIcon";
import LocationIconFilled from "../icons/locationIconFilled";
import { Popups } from "../../constants/popups";
import CheckboxWhiteTickIcon from "../icons/checkboxWhiteTickIcon";
import { useSelector } from "react-redux";
import { useSWRConfig } from "swr";
import Constants from "expo-constants";
import * as RootNavigation from "./../../RootNavigation";
import { Platform } from "react-native";
import { Pages } from "../../constants/pages";
const apiUrl = Constants.expoConfig.extra.apiUrl;

const ROUTEMAP = {
  AddressesScreen: "AddressesScreen",
  savedaddresses: "savedaddresses",
  UserScreen: "UserScreen",
  savedAddressesPopup: "savedAddressesPopup",
};

const ShippingAddressCard = ({ address, isChecked, onCheck }) => {
  const { mutate } = useSWRConfig();
  const userId = useSelector((state) => state.user.id);
  const navigation = RootNavigation;
  const route = RootNavigation.navigationRef.getCurrentRoute();

  return (
    <>
      {address && (
        <Pressable
          w="full"
          _hover={{
            bg: "#FAF1FF",
          }}
          onPress={() => {
            mutate(`${apiUrl}/api/users/get_addresses/?user_id=${userId}`);
            if (Platform.OS === "web") {
              navigation.navigate(Pages.savedaddresses);
            } else {
              route?.name !== ROUTEMAP[route?.name] &&
                navigation.navigate(Popups.SavedAddressesPopup);
            }
          }}
        >
          <HStack
            borderRadius={"8px"}
            _light={{
              bg: "white",
              borderColor: "#E9B9FF",
              borderWidth: "1px",
            }}
            _dark={{
              bg: "primary.card",
            }}
            pt={4}
            pb={8}
            px={4}
            space={4}
          >
            <LocationIconFilled size={5} />
            <VStack flex={1}>
              <Text
                fontSize={14}
                lineHeight={21}
                style={{
                  fontFamily: "HK_400Regular",
                }}
                _light={{
                  color: "#464646",
                }}
                _dark={{
                  color: "#94A3B8",
                }}
              >
                {address.title}
              </Text>
              <Text
                fontSize={16}
                lineHeight={24}
                style={{
                  fontFamily: "HK_600SemiBold",
                }}
                _light={{
                  color: "#464646",
                }}
                _dark={{
                  color: "white",
                }}
              >
                {address.street + "," + address.unit + "," + address.city}
              </Text>
              <Text
                fontSize={12}
                lineHeight={18}
                style={{
                  fontFamily: "HK_400Regular",
                }}
                color="#94A3B8"
              >
                {address.first_name}
              </Text>
            </VStack>

            <HStack alignItems={"center"}>
              {route?.name === ROUTEMAP[route?.name] ? (
                <Pressable
                  _hover={{
                    _light: {
                      bg: "#FAF1FF",
                    },
                  }}
                  onPress={() => {
                    onCheck();
                  }}
                >
                  <HStack space={"10px"} alignItems="center">
                    <Center
                      p={"5px"}
                      rounded={"4px"}
                      borderWidth={"1px"}
                      _dark={{
                        bg: isChecked ? "#682481" : "#2B2F33",
                        borderColor: "#58595B",
                      }}
                      _light={{
                        bg: isChecked ? "#682481" : "white",
                        borderColor: "#E9B9FF",
                      }}
                      size={"20px"}
                    >
                      {isChecked && <CheckboxWhiteTickIcon />}
                    </Center>
                    <Text
                      fontSize={"16px"}
                      style={{
                        fontFamily: "HK_600SemiBold",
                      }}
                      _light={{
                        color: "#464646",
                      }}
                      _dark={{
                        color: isChecked ? "white" : "#94A3B8",
                      }}
                    >
                      Make default address
                    </Text>
                  </HStack>
                </Pressable>
              ) : (
                <IconButton
                  size={6}
                  icon={<ChevronLeftArrowIcon color="#888990" />}
                  _pressed={{
                    bg: "primary.card",
                  }}
                />
              )}
            </HStack>
          </HStack>
        </Pressable>
      )}
    </>
  );
};

export default ShippingAddressCard;
