import {
  Text,
  Modal,
  HStack,
  VStack,
  Slide,
  ScrollView,
  Input,
  Center,
} from "native-base";
import InstagramIcon from "./../../assets/images/svgs/mdi_instagram.svg";
import FacebookIcon from "./../../assets/images/svgs/ic_round-facebook.svg";
import TiktokIcon from "./../../assets/images/svgs/ic_baseline-tiktok.svg";
import ChevronBackArrowIcon from "../icons/chevronBackArrowIcon";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog, goBackDialog } from "../../redux/currentState/actions";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import { useInitialiseForm } from "../../hooks/useInitialiseForm";
import { useGetKeyboardStatus } from "../../hooks/useGetKeyboardStatus";
import GradientRoundedCornerNextButton from "../buttons/gradientRoundedCornerNextButton";
import useGetUser from "../../hooks/useGetUser";
import { useState } from "react";

const SocialLinksPopup = () => {
  const dispatch = useDispatch();
  useInitialiseForm();
  const keyboardStatus = useGetKeyboardStatus();
  const editUserDetails = useEditUserDetails();

  const userId = useSelector((state) => state.user.id);

  const { data, mutate } = useGetUser("profile", userId);

  const openDialog = useSelector((state) => state.currentState.openDialog);

  const [insta, setInsta] = useState(data?.user[0]?.instagram_handle);
  const [fb, setFb] = useState(data?.user[0]?.facebook_username);
  const [tiktok, setTikTok] = useState(data?.user[0]?.tiktok_handle);
  return (
    <>
      <Modal
        size={"full"}
        isOpen={openDialog}
        bg="black:alpha.80"
        onClose={() => dispatch(closeDialog())}
      >
        <Slide overlay={false} in={true} placement="bottom">
          <VStack
            height={"90%"}
            roundedTop={"40px"}
            marginBottom={"0"}
            py={4}
            marginTop={"auto"}
            bg={"#2B2F33"}
            space={4}
            width="100%"
            px={4}
          >
            <HStack
              alignItems={"center"}
              justifyContent={"space-between"}
              pt={2}
              px={2}
            >
              <ChevronBackArrowIcon
                onPress={() => {
                  dispatch(goBackDialog());
                }}
              />
            </HStack>
            <Text
              pt={5}
              fontSize={16}
              style={{
                fontFamily: "HK_700Bold",
              }}
              color="#94A3B8"
            >
              Add Social Links
            </Text>

            <ScrollView
              keyboardShouldPersistTaps="handled"
              showsVerticalScrollIndicator={false}
            >
              <VStack
                space={"20px"}
                w="full"
                pb={
                  keyboardStatus === "Keyboard Hidden" ||
                  keyboardStatus === undefined
                    ? 0
                    : 96
                }
              >
                {[
                  {
                    label: "Instagram Handle",
                    name: "instagram_handle",
                    icon: <InstagramIcon />,
                    value: insta,
                  },
                  {
                    label: "Facebook Username",
                    name: "facebook_username",
                    icon: <FacebookIcon />,
                    value: fb,
                  },
                  {
                    label: "TikTok Handle",
                    name: "tiktok_handle",
                    icon: <TiktokIcon />,
                    value: tiktok,
                  },
                ]?.map((k, i) => (
                  <VStack>
                    <VStack space={"10px"} w="full">
                      <HStack alignItems={"center"} space={1}>
                        {k?.icon}
                        <Text
                          color={"white"}
                          fontSize={"14px"}
                          letterSpacing={"sm"}
                          style={{
                            fontFamily: "HK_500Medium",
                          }}
                        >
                          {k?.label}
                        </Text>
                      </HStack>
                      <Input
                        value={k?.value}
                        onChangeText={(val) => {
                          if (i === 0) {
                            setInsta(val);
                          } else if (i === 1) {
                            setFb(val);
                          } else if (i === 2) {
                            setTikTok(val);
                          }
                        }}
                        fontSize={"16px"}
                        lineHeight={"21px"}
                        autoCapitalize="none"
                        style={{
                          fontFamily: "HK_700Bold",
                        }}
                        placeholderTextColor={"#94A3B8"}
                        borderWidth={"1px"}
                        borderColor={"#58595B"}
                        p={"16px"}
                        rounded={"8px"}
                        color="white"
                        _focus={{
                          borderColor: "white",
                          bg: "#2B2F33",
                          color: "white",
                        }}
                        placeholder="@xxxxxx"
                      ></Input>
                    </VStack>
                  </VStack>
                ))}
                <Center pt="50px">
                  <GradientRoundedCornerNextButton
                    text="Save"
                    onPress={() => {
                      mutate();
                      editUserDetails(
                        {
                          instagram_handle: insta,
                          facebook_username: fb,
                          tiktok_handle: tiktok,
                        },
                        `Save Button Social Link Popup Edit`,
                        `Social Links Edit Popup`,
                        {
                          instagram_handle: insta,
                          facebook_username: fb,
                          tiktok_handle: tiktok,
                        }
                      );
                      dispatch(closeDialog());
                    }}
                  />
                </Center>
              </VStack>
            </ScrollView>
          </VStack>
        </Slide>
      </Modal>
    </>
  );
};

export default SocialLinksPopup;
