import { Center, HStack, Pressable, Text, VStack } from "native-base";
import { ImageBackground } from "react-native";
import LinkIcon from "../icons/outlinedIcons/linkIcon";
import * as Clipboard from "expo-clipboard";
import { createDeepLink, useShare } from "../../hooks/useShare";
import { setAnalytics } from "../../analytics";
import { useEffect, useState } from "react";
import { Platform } from "react-native";

const InviteFriendsCard = ({ userId }) => {
  const [text, setText] = useState("Copy Link");
  const onShare = useShare();
  const [link, setLink] = useState();
  useEffect(() => {
    if (Platform.OS === "web") {
      getWebLink();
    } else {
      getLink();
    }
  }, []);
  const getLink = async () => {
    let k = await createDeepLink(`flock/`, userId);
    setLink(k);
  };
  const getWebLink = async () => {
    let k = "https://www.flockshopping.com";
    setLink(k);
  };

  return (
    <Pressable
      onPress={() => {
        if (Platform.OS === "web") {
        } else {
          onShare("flock/", {
            category: "Invite Friends Button",
            event: `Shared app via Invite Friends Banner`,
            eventProperties: {
              userId: userId,
            },
          });
        }
      }}
    >
      <Center>
        <Center h="75px" w={"95%"}>
          <ImageBackground
            position="relative"
            source={
              Platform.OS === "web"
                ? require("./../../assets/images/pngs/invitefriendsweb.png")
                : require("./../../assets/images/pngs/inviteFriends.png")
            }
            alt=""
            style={{
              borderRadius: 20,
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <HStack h="full" py={4} w="full" justifyContent={"space-around"}>
              <VStack>
                <Text
                  _web={{
                    style: {
                      fontFamily: "AustinCyr_400",
                    },
                  }}
                  color={"white"}
                  fontSize="22px"
                  lineHeight={"24.2px"}
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                >
                  Invite Friends to Flock
                </Text>
                <Text
                  _web={{
                    style: {
                      fontFamily: "Hero_400Regular",
                    },
                  }}
                  color={"white"}
                  fontSize="11px"
                  lineHeight={"13.75px"}
                  style={{
                    fontFamily: "HK_500Medium",
                  }}
                >
                  Get up to $50 in Flockbucks!
                </Text>
              </VStack>
              <Center>
                <Pressable
                  onPress={async () => {
                    let p = await Clipboard.setStringAsync(link);
                    if (p) {
                      setText("Copied");
                    }
                    setAnalytics(
                      "Copied link",
                      "Copied Link For Inviting Friends",
                      {
                        link: link,
                        copyStatus: p,
                      }
                    );
                  }}
                >
                  <HStack alignItems={"center"} space={1}>
                    <LinkIcon color="#ffffff" />
                    <Text
                      _web={{
                        style: {
                          fontFamily: "Hero_700Bold",
                        },
                      }}
                      color={"white"}
                      fontSize="11px"
                      lineHeight={"13.75px"}
                      style={{
                        fontFamily: "HK_500Medium",
                      }}
                    >
                      {text}
                    </Text>
                  </HStack>
                </Pressable>
              </Center>
            </HStack>
          </ImageBackground>
        </Center>
      </Center>
    </Pressable>
  );
};

export default InviteFriendsCard;
