import { useRoute } from "@react-navigation/native";
import { HStack, Text, VStack } from "native-base";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import FlowerIcon from "../../components/icons/webIcons/flowerIcon.js";
import useGetUser from "../../hooks/useGetUser";
import CelebDetails from "./buildingBlocks/celebDetails";
import Collections from "./buildingBlocks/collections";
import ShopTheLook from "./buildingBlocks/shopTheLook.js";

const CelebScreen = () => {
  const route = useRoute();

  const { data } = useGetUser(route?.params?.type, route?.params?.id);

  return (
    <>
      <VStack
        px={{
          base: 2,
          sm: 2,
          md: 2,
          lg: 2,
          xl: "170px",
        }}
        space={"40px"}
        pt={{
          base: "60px",
          sm: "60px",
          md: "60px",
          lg: "60px",
          xl: "40px",
        }}
        w="full"
      >
        <HStack
          alignItems={"center"}
          justifyContent="center"
          space={2}
          bg="#FAF1FF"
          rounded="full"
          h="69px"
          w={"full"}
        >
          <FlowerIcon />
          <Text
            color={"#682481"}
            fontSize={"36px"}
            textAlign="center"
            style={{
              fontFamily: "AustinCyr_400",
            }}
          >
            Celeb
          </Text>
          <FlowerIcon />
        </HStack>
        <CelebDetails data={data} />
        {data?.user[0]?.id !== 398 && data?.user[0]?.id !== 580 ? (
          <Collections celebId={data && data?.user[0]?.id} />
        ) : (
          <ShopTheLook celebid={data?.user[0]?.id} />
        )}
      </VStack>
      <FooterSectionWeb />
    </>
  );
};
export default CelebScreen;
