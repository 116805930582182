import { createContext } from "react";
import { useDispatch } from "react-redux";
import { useCheckSpace } from "../hooks/useCheckSpace";
import { useEmailLogin } from "../hooks/useEmailLogin";
import { storeForm } from "../redux/form/actions";
export const EmailLoginContext = createContext();

const EmailLoginHOC = (props) => {
  const { checkSpace, error: spaceError, label } = useCheckSpace();
  const dispatch = useDispatch();
  const { handleEmailLogin, error, setError } = useEmailLogin();
  const handleInput = (name) => (val) => {
    checkSpace(val, name);
    setError();

    dispatch(storeForm({ [name]: val, error: null }));
  };

  return (
    <EmailLoginContext.Provider
      value={{
        error,
        handleInput,
        handleEmailLogin,
        spaceError,
        label,
      }}
    >
      {props.children}
    </EmailLoginContext.Provider>
  );
};

export default EmailLoginHOC;
