const { Image, useColorMode, Center } = require("native-base");

const ThickHairTypeIcon = () => {
  const { colorMode } = useColorMode();
  return (
    <Center bg="#F0F0F0" borderRadius={"10px"}>
      <Image
        width={62}
        height={70}
        resizeMethod="scale"
        resizeMode="contain"
        source={
          colorMode === "dark"
            ? require("./../../../../assets/images/pngs/grayThickHairType.png")
            : require("./../../../../assets/images/pngs/lightinactivethickhair.png")
        }
        alt=""
      />
    </Center>
  );
};

export default ThickHairTypeIcon;
