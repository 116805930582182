import { HStack, Link } from "native-base";
import { useCopyToClipboard } from "../hooks/useCopyToClipboard";
import GradientFbIcon from "./icons/gradientFbIcon";
import GradientInstaIcon from "./icons/gradientInstaIcon";
import GradientTikTokIcon from "./icons/gradientTiktokIcon";
import CopyLinkPopover from "./popovers/copyLinkPopover";
import * as Clipboard from "expo-clipboard";
import { setAnalytics } from "../analytics";
const ShareIcons = () => {
  const { link, copyStatus, setCopyStatus } = useCopyToClipboard();
  return (
    <HStack space={5} alignItems="center">
      {[
        {
          link: "https://www.instagram.com",
          icon: <GradientInstaIcon />,
          label: "instagram",
        },
        {
          link: "https://www.facebook.com",
          icon: <GradientFbIcon />,
          label: "facebook",
        },
        {
          link: "https://www.tiktok.com",
          icon: <GradientTikTokIcon />,
          label: "tiktok",
        },
      ]?.map((k) => (
        <Link
          href={k?.link}
          isExternal
          onPress={async () => {
            let p = await Clipboard.setStringAsync(link);
            if (p) {
              setCopyStatus(p);
            }
            setAnalytics(
              "Copied link",
              `Copied Link For Sharing Product From Product Screen ${k?.label}`,
              {
                link: link,
                copyStatus: p,
              }
            );
          }}
        >
          {k?.icon}
        </Link>
      ))}

      <CopyLinkPopover
        link={link}
        copyStatus={copyStatus}
        setCopyStatus={(val) => setCopyStatus(val)}
      />
    </HStack>
  );
};

export default ShareIcons;
