import { createContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAddNewAddress } from "../hooks/useAddNewAddress";
import { useInitialiseForm } from "../hooks/useInitialiseForm";
import { storeForm } from "../redux/form/actions";
export const AddNewAddressContext = createContext();

const AddNewAddressHOC = (props) => {
  const form = useSelector((state) => state.form);
  const dispatch = useDispatch();
  function handleInput(val, name, type) {
    const v = form[type];
    dispatch(storeForm({ [type]: { ...v, [name]: val } }));
  }

  useInitialiseForm();

  const state = {
    shipping: {
      title: "",
      name: "",
      street: "",
      unit: "",
      city: "",
      state: "",
      zipcode: "",
      phone_number: "",
    },
  };

  useEffect(() => {
    dispatch(storeForm({ ...state, error: null }));
  }, []);

  const { addNewAddress } = useAddNewAddress();
  return (
    <AddNewAddressContext.Provider
      value={{
        addNewAddress,
        handleInput,
      }}
    >
      {props.children}
    </AddNewAddressContext.Provider>
  );
};

export default AddNewAddressHOC;
