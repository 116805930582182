import { Button, Input, Text, VStack } from "native-base";
import { useContext, useState } from "react";
import { Keyboard } from "react-native";
import { useSWRConfig } from "swr";
import { setAnalytics } from "../analytics";
import { privateApi } from "../util/API";
import { useSelector } from "react-redux";
import useCheckPromoCodeClaimed from "../hooks/useCheckPromoCodeClaimed";
import Constants from "expo-constants";
import { useRoute } from "@react-navigation/native";
import { CheckoutContext } from "../hoc/CheckoutHOC";
const apiUrl = Constants.expoConfig.extra.apiUrl;

const PromoCodeBox = () => {
  const {
    price,
    setPercentOffDiscount,
    promoCode,
    setPromoCode,
    applyOtherDiscounts,
  } = useContext(CheckoutContext);
  const userId = useSelector((state) => state.user.id);
  const route = useRoute();
  const [error, setError] = useState();
  const [buttonText, setButtonText] = useState("Apply");
  const { mutate } = useSWRConfig();
  const { data } = useCheckPromoCodeClaimed(promoCode);

  const getPoints = async () => {
    const res = await privateApi(
      "/api/home/claim_promocode/",
      {
        user_id: userId,
        promocode: promoCode,
      },
      "post"
    );

    if (res.body.status === 201) {
      mutate(`${apiUrl}/api/points/rewards/?user_id=${userId}`);
      setButtonText("Applied!");
      setAnalytics("Promo Code Apply Button", `${promoCode} applied`, {
        screen: route?.name,
        promoCode: promoCode,
        status: "applied",
      });
    } else if (res.body.status === 400) {
      setError(res.body.message);
      setAnalytics("Promo Code Apply Button", `${promoCode} failed`, {
        screen: route?.name,
        promoCode: promoCode,
        status: res.body.message,
      });
      setPromoCode(undefined);
    }
  };

  const getPercentOffDiscount = async () => {
    if (!data?.promocode_applied) {
      setButtonText("Applied!");

      const res = await privateApi(
        "/api/home/get_promocode_discount/",
        {
          amount: price,
          promocode: promoCode,
        },
        "post"
      );
      console.log("res.body.status_code", res);
      if (res.body.status_code === 200) {
        setPercentOffDiscount(res.body.data.discount);
      }
    } else {
      setError("promo code was already applied");
      setAnalytics("Promo Code Apply Button", `${promoCode} failed`, {
        screen: route?.name,
        promoCode: promoCode,
        status: "promo code was already applied",
      });
      setPromoCode(undefined);
    }
  };

  return (
    <>
      {applyOtherDiscounts && (
        <VStack w="full" space={1}>
          <Input
            px="22px"
            py="13px"
            w="full"
            h="40px"
            color="#464646"
            variant="outline"
            focusOutlineColor="#682481"
            _focus={{
              bg: "none",
              color: "#464646",
            }}
            _hover={{
              bg: "none",
              color: "#464646",
              borderColor: "#682481",
            }}
            style={{
              fontFamily: "Hero_400Regular",
            }}
            borderRadius="7px"
            borderColor="#C1C9D2"
            fontSize="14px"
            placeholderTextColor={"#C1C9D2"}
            placeholder={"Enter coupon code"}
            borderWidth={"1px"}
            onChange={() => {
              setButtonText("Apply");
              setError();
              setPercentOffDiscount();
            }}
            onChangeText={(val) => setPromoCode(val)}
            InputRightElement={
              <Button
                onPress={async () => {
                  Keyboard.dismiss();

                  if (promoCode && data?.length !== 0) {
                    if (data?.type === "points") {
                      getPoints();
                    } else if (data?.type === "percentage") {
                      getPercentOffDiscount();
                    }
                  } else {
                    setError("promo code is invalid");
                    setAnalytics(
                      "Promo Code Apply Button",
                      `${promoCode} invalid`,
                      {
                        screen: route?.name,
                        promoCode: promoCode,
                        status: "promo code is invalid",
                      }
                    );
                  }
                }}
                _hover={{
                  bg: "#FAF1FF",
                }}
                variant={"ghost"}
                _text={{
                  color: "#682481",
                  fontFamily: "Hero_700Bold",
                }}
              >
                {buttonText}
              </Button>
            }
          ></Input>

          {error && (
            <Text
              color={"danger.600"}
              fontSize="14px"
              style={{
                fontFamily: "HK_600SemiBold",
              }}
            >
              {error}!
            </Text>
          )}
        </VStack>
      )}
    </>
  );
};

export default PromoCodeBox;
