import { HStack } from "native-base";
import { Dimensions } from "react-native";

const TopBar = ({ content }) => {
  return (
    <>
      <HStack
        width={Dimensions.get("window").width}
        shadow={9}
        bg="#1A1C1E"
        zIndex={1}
        pt={{
          md: "30px",
          lg: "54px",
          xl: "56px",
        }}
        height={{
          md: "90px",
          lg: "128px",
          xl: "130px",
        }}
        alignItems="center"
        px={6}
        justifyContent={"space-between"}
      >
        {content}
      </HStack>
    </>
  );
};

export default TopBar;
