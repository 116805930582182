import { Stack, Text, TextArea, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { storeForm } from "../../redux/form/actions";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import { setAnalytics } from "../../analytics";
import { useState } from "react";
import useGetUser from "../../hooks/useGetUser";
import { useInitialiseForm } from "../../hooks/useInitialiseForm";
import { MaskedTextInput } from "react-native-mask-text";
import SurveyScreenBottomBar from "../../components/bars/bottomBars/surveyScreenBottomBar";
import SurveyIdentityDropDown from "../../components/dropDowns/surveyIdentityDropDown";
import Error from "../../components/error";
const changeDateFormat = (birthDate) => {
  let year =
    birthDate?.charAt(6) +
    birthDate?.charAt(7) +
    birthDate?.charAt(8) +
    birthDate?.charAt(9);
  let month = birthDate?.charAt(0) + birthDate?.charAt(1);
  let date = birthDate?.charAt(3) + birthDate?.charAt(4);
  let formatedDate = `${year}-${month}-${date}`;
  return formatedDate;
};

const getAge = (birthDate) => {
  return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);
};

const findSign = (date) => {
  const days = [21, 20, 21, 21, 22, 22, 23, 24, 24, 24, 23, 22];
  const signs = [
    "Aquarius",
    "Pisces",
    "Aries",
    "Taurus",
    "Gemini",
    "Cancer",
    "Leo",
    "Virgo",
    "Libra",
    "Scorpio",
    "Sagittarius",
    "Capricorn",
  ];
  let month = date.getMonth();
  let day = date.getDate();
  if (month == 0 && day <= 20) {
    month = 11;
  } else if (day < days[month]) {
    month--;
  }
  return signs[month];
};

const AboutYourSelfScreen = ({ navigation, route }) => {
  useInitialiseForm();
  const userId = useSelector((state) => state.user.id);
  const dispatch = useDispatch();
  const { data } = useGetUser("profile", userId);

  const editUserDetails = useEditUserDetails();
  const form = useSelector((state) => state.form);

  const [error, setError] = useState();
  const changeAgeAndStore = (text) => {
    if (text === "Skip") {
      setAnalytics(
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
        }
      );
    } else {
      let newForm = { ...form };
      let date = form["birthday"] ? form["birthday"] : data?.user[0]?.birthday;
      newForm["birthday"] = form["birthday"]
        ? form["birthday"]
        : data?.user[0]?.birthday;
      const formatedDate = changeDateFormat(date);
      newForm["sunsign"] = findSign(new Date(formatedDate));
      newForm["age"] = getAge(formatedDate);

      dispatch(storeForm(newForm));
      editUserDetails(
        newForm,
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
          edited: data?.user[0]?.quiz_completed,
          ...newForm,
        }
      );
    }
    navigation.navigate("GettingToKnowYourSkinScreen");
  };

  return (
    <>
      <VStack space={12} w="full">
        <Stack
          w="full"
          justifyContent="center"
          space={{
            base: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: "50px",
          }}
          px={{
            base: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: "80px",
          }}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
        >
          <VStack
            space={"8px"}
            w={{
              xl: "20%",
            }}
          >
            <Text
              color="#682481"
              fontSize={"21px"}
              style={{
                fontFamily: "AustinCyr_400",
              }}
            >
              About You
            </Text>
            <Text
              color="#464646"
              fontSize={"14px"}
              style={{
                fontFamily: "LeituraNewsRoman",
              }}
            >
              Make your shopping experince just for you! Your age & identity can
              help us find you, your ideal Skincare & Wellness products.
            </Text>
          </VStack>
          <VStack
            space={12}
            w={{
              xl: "50%",
            }}
          >
            <Stack
              flexWrap={"wrap"}
              borderWidth={"1px"}
              borderColor="#FAF1FF"
              borderRadius={"10px"}
              alignItems={"center"}
              space={4}
              px={2}
              pb={4}
              direction={{
                base: "column",
                sm: "column",
                md: "column",
                lg: "column",
                xl: "row",
              }}
            >
              {[
                {
                  label: "Identity",
                  placeholder: "name@email.com",
                  field: "ethnicity",
                },
                {
                  label: "Your Birthday",
                  placeholder: "2002-01-23",
                  field: "birthday",
                },
                {
                  label: "Your profile bio",
                  placeholder: "Hi I'm Alex a professional makeup artist",
                  field: "bio",
                },
              ].map((k, i) => (
                <VStack
                  mt={4}
                  space={"6px"}
                  w={{
                    base: "full",
                    sm: "full",
                    md: "full",
                    lg: "full",
                    xl: "303px",
                  }}
                >
                  <Text
                    letterSpacing={3}
                    textTransform="uppercase"
                    color={"#682481"}
                    fontSize="11px"
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                  >
                    {k?.label}
                  </Text>
                  {i === 1 ? (
                    <VStack>
                      <MaskedTextInput
                        value={
                          form?.birthday || form?.birthday === ""
                            ? form?.birthday
                            : data?.user[0]?.birthday
                        }
                        mask="99/99/9999"
                        options={{
                          dateFormat: "MM/DD/YYYY",
                        }}
                        onChangeText={(text, rawText) => {
                          if (text.charAt(0) > 1 || text.charAt(3) > 3) {
                            setError("Check birthday!");
                          } else {
                            setError();
                            dispatch(storeForm({ birthday: text?.trim() }));
                          }
                        }}
                        placeholder="MM/DD/YYYY"
                        placeholderTextColor="white"
                        editable
                        maxLength={10}
                        keyboardType="numbers-and-punctuation"
                        style={{
                          borderColor: "#C1C9D2",
                          borderWidth: 1,
                          borderRadius: 7,
                          height: 39,
                          width: 303,
                          textAlign: "center",
                          color: "#464646",
                          fontSize: 12,

                          fontFamily: "Hero_400Regular",
                        }}
                      />
                      {error && <Error error={error} />}
                    </VStack>
                  ) : i === 0 ? (
                    <SurveyIdentityDropDown
                      ethnicity={
                        form?.ethnicity
                          ? form?.ethnicity
                          : data?.user[0]?.ethnicity
                      }
                    />
                  ) : (
                    <TextArea
                      value={
                        form?.bio || form?.bio === ""
                          ? form?.bio
                          : data?.user[0]?.bio
                      }
                      px="22px"
                      py="13px"
                      w={{
                        base: "full",
                        sm: "full",
                        md: "full",
                        lg: "full",
                        xl: "303px",
                      }}
                      h="39px"
                      color="#464646"
                      variant="outline"
                      focusOutlineColor="#BE54FF"
                      _focus={{
                        bg: "none",
                        color: "#464646",
                      }}
                      _hover={{
                        bg: "none",
                        color: "#464646",
                        borderColor: "#BE54FF",
                      }}
                      style={{
                        fontFamily: "HK_700Bold",
                      }}
                      borderRadius="7px"
                      borderColor="#C1C9D2"
                      fontSize="14px"
                      placeholderTextColor={"#C1C9D2"}
                      placeholder={k?.placeholder}
                      borderWidth={"1px"}
                      onChangeText={(val) => dispatch(storeForm({ bio: val }))}
                    ></TextArea>
                  )}
                </VStack>
              ))}
            </Stack>
            <SurveyScreenBottomBar
              showNextButton={
                Object.keys(form).length > 0 || data?.user[0]?.quiz_completed
              }
              nextOnPress={() => {
                changeAgeAndStore("Next");
              }}
            />
          </VStack>
        </Stack>
      </VStack>
    </>
  );
};

export default AboutYourSelfScreen;
