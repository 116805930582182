import {
  Text,
  VStack,
  Stack,
  FlatList,
  Image,
  Pressable,
  Center,
  Checkbox,
} from "native-base";
import { useSelector } from "react-redux";
import SurveyScreenBottomBar from "../../components/bars/bottomBars/surveyScreenBottomBar";
import useGetUser from "../../hooks/useGetUser";
import { useState } from "react";
import { setAnalytics } from "../../analytics";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import { Pages } from "../../constants/pages";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import { BrandImages, Brands } from "../../constants/brands";

const FavoriteBrands = ({ navigation, route }) => {
  const userId = useSelector((state) => state.user.id);
  const [brandResults, setBrandResults] = useState(Object.keys(Brands));
  const { data } = useGetUser("profile", userId);

  const [selectedBrands, setSelectedBrands] = useState(
    data?.user[0]?.foundation_brands ? data?.user[0]?.foundation_brands : []
  );

  const editUserDetails = useEditUserDetails();

  const handleRequest = async (text) => {
    if (text === "Skip") {
      setAnalytics(`${text} Button Survey Screen`, `${text} Button Clicked`, {
        screen: route?.name,
      });
    } else {
      if (selectedBrands.length > 4) {
        editUserDetails(
          {
            foundation_brands: selectedBrands,
          },
          `${text} Button Survey Screen`,
          `${text} Button Clicked`,
          {
            screen: route?.name,
            selectedBrands,
            edited: data?.user[0]?.quiz_completed,
          }
        );
        navigation.navigate(Pages.Yay);
      }
    }
  };
  const renderItem = ({ item }) => (
    <Pressable
      onPress={() => {
        if (selectedBrands.includes(item.name)) {
          setSelectedBrands(selectedBrands.filter((x) => x !== item.name));
        } else {
          const temp = [...selectedBrands];
          temp.push(item.name);
          setSelectedBrands(temp);
        }
      }}
    >
      <Center m={1.5}>
        <Image
          opacity={0.4}
          bg="white"
          borderWidth={"1px"}
          borderColor="#68248133"
          borderRadius={"11px"}
          h="80px"
          w="153px"
          resizeMethod="scale"
          resizeMode="contain"
          source={{
            uri: BrandImages[Brands[item]],
          }}
          alt=""
        />
      </Center>
    </Pressable>
  );

  return (
    <>
      <VStack space={12} w="full">
        <Stack
          w="full"
          justifyContent="center"
          space={{
            base: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: "50px",
          }}
          px={{
            base: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: "80px",
          }}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
        >
          <VStack
            space={"8px"}
            w={{
              xl: "20%",
            }}
          >
            <Text
              color="#682481"
              fontSize={"21px"}
              style={{
                fontFamily: "AustinCyr_400",
              }}
            >
              Brands
            </Text>
            <Text
              color="#464646"
              fontSize={"14px"}
              style={{
                fontFamily: "LeituraNewsRoman",
              }}
            >
              Flock has over 120 Brands for you to pick from. Pick at least 5
              from the list.
            </Text>
          </VStack>
          <VStack
            space={12}
            w={{
              xl: "70%",
            }}
          >
            <Stack
              borderWidth={"1px"}
              borderColor="#FAF1FF"
              borderRadius={"10px"}
              justifyContent="center"
              space={4}
              p={4}
              direction={{
                base: "column",
                sm: "column",
                md: "column",
                lg: "column",
                xl: "row",
              }}
            >
              <VStack>
                <Checkbox
                  height={"20px"}
                  borderWidth={"1px"}
                  onChange={(check) => {
                    if (check) {
                      setSelectedBrands(
                        selectedBrands.map((item) => {
                          item.pressed = true;
                          return item;
                        })
                      );
                    } else {
                      setChoices(
                        choices.map((item) => {
                          item.pressed = false;
                          return item;
                        })
                      );
                    }
                  }}
                  _light={{
                    bg: "#F0F0F0",
                    borderColor: "#94A3B8",
                  }}
                  _dark={{
                    bg: "#2B2F33",
                    borderColor: "#58595B",
                  }}
                  borderRadius="2px"
                  _text={{
                    _dark: {
                      color: "#fff",
                    },
                    _light: {
                      color: "#464646",
                    },
                    _web: {
                      fontFamily: "Hero_400Regular",
                      fontSize: "14px",
                    },
                    fontSize: "16px",
                    fontFamily: "HK_500Medium",
                  }}
                >
                  Select all
                </Checkbox>
                <FlatList
                  keyboardShouldPersistTaps="handled"
                  showsVerticalScrollIndicator={false}
                  initialNumToRender={2}
                  columnWrapperStyle={{
                    justifyContent: "center",
                  }}
                  contentContainerStyle={{
                    height: 400,
                  }}
                  data={brandResults}
                  _web={{
                    numColumns: 5,
                  }}
                  renderItem={renderItem}
                />
              </VStack>
            </Stack>
            <SurveyScreenBottomBar
              showNextButton={selectedBrands.length > 4}
              nextOnPress={() => {
                handleRequest("Next");
              }}
            />
          </VStack>
        </Stack>
        <FooterSectionWeb />
      </VStack>
    </>
  );
};

export default FavoriteBrands;
