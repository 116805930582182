import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const FlowerIcon = () => {
  return (
    <Icon viewBox="0 0 24 25">
      <G>
        <Path
          d="M15.35 20.5L13.4 22.425C13.0167 22.8083 12.55 23 12 23C11.45 23 10.9833 22.8083 10.6 22.425L8.65 20.5H6C5.45 20.5 4.979 20.304 4.587 19.912C4.195 19.52 3.99933 19.0493 4 18.5V15.85L2.075 13.9C1.69167 13.5167 1.5 13.05 1.5 12.5C1.5 11.95 1.69167 11.4833 2.075 11.1L4 9.15V6.5C4 5.95 4.196 5.479 4.588 5.087C4.98 4.695 5.45067 4.49933 6 4.5H8.65L10.6 2.575C10.9833 2.19167 11.45 2 12 2C12.55 2 13.0167 2.19167 13.4 2.575L15.35 4.5H18C18.55 4.5 19.021 4.696 19.413 5.088C19.805 5.48 20.0007 5.95067 20 6.5V9.15L21.925 11.1C22.3083 11.4833 22.5 11.95 22.5 12.5C22.5 13.05 22.3083 13.5167 21.925 13.9L20 15.85V18.5C20 19.05 19.804 19.521 19.412 19.913C19.02 20.305 18.5493 20.5007 18 20.5H15.35ZM12 14.975L13.925 16.125C14.1083 16.2417 14.2873 16.2377 14.462 16.113C14.6367 15.9883 14.6993 15.8173 14.65 15.6L14.15 13.425L15.85 11.95C16.0167 11.8 16.0667 11.621 16 11.413C15.9333 11.205 15.7833 11.0923 15.55 11.075L13.325 10.9L12.45 8.825C12.3667 8.625 12.2167 8.525 12 8.525C11.7833 8.525 11.6333 8.625 11.55 8.825L10.675 10.9L8.45 11.075C8.21667 11.0917 8.06667 11.2043 8 11.413C7.93333 11.6217 7.98333 11.8007 8.15 11.95L9.85 13.425L9.35 15.6C9.3 15.8167 9.36267 15.9877 9.538 16.113C9.71333 16.2383 9.89233 16.2423 10.075 16.125L12 14.975Z"
          fill="#FFC826"
        />
      </G>
    </Icon>
  );
};

export default FlowerIcon;
