import { createContext, useState } from "react";
import { useAddDefaultAddress } from "../hooks/useAddDefaultAddress";
import useGetAddressList from "../hooks/useGetAddressList";
export const SavedAddressesContext = createContext();

const SavedAddressesHOC = (props) => {
  const { data } = useGetAddressList();
  const [selectedValue, setSelectedValue] = useState(
    data?.addresses?.length < 2
      ? data?.addresses[0]?.id
      : data?.addresses?.find((a) => a?.shipping_default === true)?.id
  );
  const { addDefaultAddress, error } = useAddDefaultAddress();
  return (
    <SavedAddressesContext.Provider
      value={{
        selectedValue: selectedValue
          ? selectedValue
          : data?.addresses?.length < 2
          ? data?.addresses[0]?.id
          : data?.addresses?.find((a) => a?.shipping_default === true)?.id,
        setSelectedValue,
        addDefaultAddress,
        data,
      }}
    >
      {props.children}
    </SavedAddressesContext.Provider>
  );
};

export default SavedAddressesHOC;
