import { Icon } from "native-base";
import { Path, G } from "react-native-svg";
const WebSearchMobileIcon = () => {
  return (
    <Icon viewBox="0 0 20 20">
      <G>
        <Path
          d="M14.1753 12.5786H13.2794L12.9619 12.2699C14.1116 10.9251 14.7435 9.20834 14.7422 7.43282C14.7422 5.96275 14.31 4.52569 13.5 3.30337C12.6901 2.08105 11.5389 1.12837 10.1921 0.565793C8.84523 0.0032205 7.36319 -0.143974 5.93338 0.142823C4.50357 0.42962 3.19021 1.13753 2.15937 2.17702C1.12854 3.21652 0.426528 4.54092 0.142121 5.98275C-0.142286 7.42457 0.00368196 8.91907 0.561566 10.2772C1.11945 11.6354 2.06419 12.7963 3.27633 13.613C4.48846 14.4297 5.91355 14.8656 7.37137 14.8656C9.19708 14.8656 10.8754 14.191 12.1681 13.0703L12.4743 13.3905V14.2939L18.1442 20L19.8338 18.2962L14.1753 12.5786ZM7.37137 12.5786C4.54776 12.5786 2.26845 10.2802 2.26845 7.43282C2.26845 4.58548 4.54776 2.28702 7.37137 2.28702C10.195 2.28702 12.4743 4.58548 12.4743 7.43282C12.4743 10.2802 10.195 12.5786 7.37137 12.5786Z"
          fill="#682481"
        />
      </G>
    </Icon>
  );
};

export default WebSearchMobileIcon;
