import { Box, FlatList } from "native-base";
import ViewMoreSideBar from "./bars/sideBars/viewMoreSideBar";
import TrendingProductCard from "./cards/product/trendingProductCard";

const TrendingProducts = ({ heading, image }) => {
  return (
    <>
      <FlatList
        showsHorizontalScrollIndicator={false}
        horizontal
        ItemSeparatorComponent={() => (
          <Box style={{ paddingHorizontal: 6 }}></Box>
        )}
        ListHeaderComponent={
          <ViewMoreSideBar heading={heading} image={image} />
        }
        flexGrow={0}
        data={[1, 2, 3, 4]}
        renderItem={({ item }) => (
          <TrendingProductCard
            productImage="https://resized-c-im.s3.amazonaws.com/test2/92977dacd0cc791fbcfab227faed943c/066d9f04180d5087f84818f2b323cc07/1.jpg"
            groupPrice={20}
            retailPrice={30}
            groupDiscount={30}
            variants={[item?.colors, item?.size, item?.scent]}
            productName={
              "Squeaky Clean Liquid Lip Balm Sque Liquid Lip Balm Squeaky Clean Lid Lip Bal..."
            }
            brandName={"Coola"}
            productId={5}
            productSkuId={6}
          />
        )}
      />
    </>
  );
};

export default TrendingProducts;
