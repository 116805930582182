import { createContext } from "react";
import useGetSearchResults from "../hooks/useGetSearchResults";
export const SearchContext = createContext();

const SearchHOC = (props) => {
  const { data: searchResult } = useGetSearchResults(
    props.searchType,
    props.query
  );

  return (
    <SearchContext.Provider value={{ searchResult }}>
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchHOC;
