import { useNavigation } from "@react-navigation/native";

export const useGoBack = () => {
  const navigation = useNavigation();

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("Home");
    }
  };
  return goBack;
};
