import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const FilledWhiteCartIcon = () => {
  return (
    <Icon viewBox="0 0 36 36" size={"36px"}>
      <G width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M14 24C12.9 24 12.01 24.9 12.01 26C12.01 27.1 12.9 28 14 28C15.1 28 16 27.1 16 26C16 24.9 15.1 24 14 24ZM8 8V10H10L13.6 17.59L12.25 20.04C12.09 20.32 12 20.65 12 21C12 22.1 12.9 23 14 23H26V21H14.42C14.28 21 14.17 20.89 14.17 20.75L14.2 20.63L15.1 19H22.55C23.3 19 23.96 18.59 24.3 17.97L27.88 11.48C27.9625 11.3274 28.004 11.156 28.0005 10.9825C27.9969 10.8091 27.9485 10.6395 27.8598 10.4904C27.7711 10.3413 27.6453 10.2177 27.4946 10.1317C27.3439 10.0458 27.1735 10.0004 27 10H12.21L11.27 8H8ZM24 24C22.9 24 22.01 24.9 22.01 26C22.01 27.1 22.9 28 24 28C25.1 28 26 27.1 26 26C26 24.9 25.1 24 24 24Z"
          fill="white"
        />
      </G>
    </Icon>
  );
};

export default FilledWhiteCartIcon;
