import { extendTheme } from "native-base";
import { Platform } from "react-native";

const webBreakPoints = {
  base: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};
const nativeBreakPoints = {
  base: 0,
  sm: 320,
  md: 360, //iPhone SE (3rd generation), iPhone 8, iPhone 13 mini
  lg: 390, //iPhone 13 Pro
  xl: 428, //iPhone 13 Pro max
};
export const theme = extendTheme({
  colors: {
    primary: {
      layout: "#1A1C1E",
      card: "#202427",
      cardButtonBorder: "#58595B",
      cardButtonText: "#94A3B8",
      ctaLinearFirstColor: "#691DE3",
      ctaLinearSecondColor: "#FD6699",
      topBarTab: "#2B2F33",
      activeTopBarTab: "#101213",
      topBarIcon: "#888990",
    },
    info: {
      trending: "#F34444",
      whatsThis: "#4B86DF",
    },
    success: {
      tick: "#0F9737",
    },
  },
  config: {
    initialColorMode: "dark",
  },
  breakpoints: Platform.OS === "web" ? webBreakPoints : nativeBreakPoints,
});
