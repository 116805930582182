import { useState } from "react";

export const useCheckSpace = () => {
  const [error, setError] = useState();
  const [label, setlabel] = useState();
  const checkSpace = async (text, label) => {
    if (/\s/g.test(text)) {
      setError(`can't contain space`);
      setlabel(label);
    } else {
      setError();
      setlabel();
    }
  };

  return { checkSpace, error, setError, label };
};
