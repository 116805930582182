import { Text, HStack, IconButton, Checkbox, Pressable } from "native-base";
import ChevronLeftArrowIcon from "../icons/chevronLeftArrowIcon";
import * as RootNavigation from "./../../RootNavigation";
const PaymentCard = ({ card, isPaymentPopUp, onCheck, isChecked, userId }) => {
  const navigation = RootNavigation;
  const route = RootNavigation.navigationRef.getCurrentRoute();

  return (
    <>
      <Pressable
        w="full"
        _hover={{
          bg: "#FAF1FF",
        }}
        onPress={() => {
          if (route?.name !== "UserScreen") {
            navigation.navigate("savedcards");
          }
        }}
      >
        <HStack
          alignItems={"center"}
          borderRadius={"8px"}
          justifyContent="space-between"
          _light={{
            bg: "white",
            borderColor: "#E9B9FF",
            borderWidth: "1px",
          }}
          _dark={{
            bg: "primary.card",
          }}
          py={"8px"}
          px={4}
          height="78px"
        >
          <Text
            fontSize={"14px"}
            lineHeight={21}
            style={{
              fontFamily: "HK_700Bold",
            }}
            _light={{
              color: "#464646",
            }}
            _dark={{
              color: "#94A3B8",
            }}
          >
            xxxx-xxxx-xxxx-{card?.card}
          </Text>
          <HStack alignItems={"center"}>
            {isPaymentPopUp ? (
              <Checkbox
                _text={{
                  _light: {
                    color: "#464646",
                  },
                  _dark: {
                    color: "white",
                  },
                  fontFamily: "HK_600SemiBold",
                  fontSize: 14,
                }}
                isChecked={isChecked}
                onChange={() => onCheck()}
              >
                Make default card
              </Checkbox>
            ) : (
              <IconButton
                size={6}
                icon={<ChevronLeftArrowIcon color="#888990" />}
                _pressed={{
                  bg: "primary.card",
                }}
              />
            )}
          </HStack>
        </HStack>
      </Pressable>
    </>
  );
};

export default PaymentCard;
