import { RESET_FILTERS, CHANGE_FILTER, SET_PRICE, SET_APPLY_FILTER, RESET_CACHE_FILTERS, SET_CHIPS, DELETE_CHIPS, DELETE_CHIPS_VALUE } from "./constants";



export function resetFilters(filters) {
  return {
    type: RESET_FILTERS,
    filters: filters,
  };
}

export function changeFilter(filter) {
  return {
    type: CHANGE_FILTER,
    filter: filter,
  };
}

export function setPrice(low, high) {
  return {
    type: SET_PRICE,
    low: low,
    high: high
  };
}

export function setApplyFilter() {
  return {
    type: SET_APPLY_FILTER
  };
}

export function resetCacheFilter() {
  return {
    type: RESET_CACHE_FILTERS
  };
}

export function setChips(chip) {
  return {
    type: SET_CHIPS,
    chip: chip,
  };
}

export function deleteChips(itemId) {
  return {
    type: DELETE_CHIPS,
    itemId: itemId,
  };
}
export function deleteChipsValue(value) {
  return {
    type: DELETE_CHIPS_VALUE,
    value: value,
  };
}
