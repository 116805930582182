import {
  Modal,
  VStack,
  Slide,
  Text,
  IconButton,
  CloseIcon,
  HStack,
} from "native-base";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeDialog, hideLoader } from "./../../redux/currentState/actions";
import * as RootNavigation from "../../RootNavigation";
import { setAnalytics } from "../../analytics";

const FillDetailsPopUp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideLoader());
  }, []);
  const route = RootNavigation.navigationRef.getCurrentRoute();
  return (
    <>
      <Modal
        size={"full"}
        onClose={() => dispatch(closeDialog())}
        isOpen={true}
        bg="black:alpha.80"
      >
        <Slide opacity={100} overlay={false} in={true} placement="bottom">
          <VStack
            height={"50%"}
            roundedTop={"40px"}
            marginBottom={"0"}
            space={8}
            marginTop={"auto"}
            bg={"#2B2F33"}
            width="100%"
            alignItems={"center"}
          >
            <HStack justifyContent={"flex-end"} w="full">
              <IconButton
                pr={6}
                pt={6}
                _icon={{
                  size: 4,
                  color: "#94A3B8",
                }}
                onPress={() => {
                  setAnalytics(
                    "Close Fill Details Pop up",
                    "Closed Fill Details Pop-up for address and payment",
                    {
                      popup: "FillDetailsPopUp",
                      screen: route?.name,
                    }
                  );
                  dispatch(closeDialog());
                }}
                icon={<CloseIcon />}
              />
            </HStack>
            <Text
              textAlign={"center"}
              color="white"
              fontSize={"28px"}
              lineHeight="34px"
              style={{
                fontFamily: "HK_700Bold",
              }}
            >
              Your details are not filled. Please add to continue.
            </Text>
          </VStack>
        </Slide>
      </Modal>
    </>
  );
};

export default FillDetailsPopUp;
