import { Text, VStack } from "native-base";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import useGetUser from "../../hooks/useGetUser";
import CelebDetails from "../celebScreen/buildingBlocks/celebDetails.js";
import Collections from "../userScreen/buildingBlocks/collections";
import Favorites from "../userScreen/buildingBlocks/favorites.js";

const Profile = ({ route }) => {
  const { data } = useGetUser(route?.params?.type, route?.params?.id);
  return (
    <>
      <VStack
        px={{
          base: 2,
          sm: 2,
          md: 2,
          lg: 2,
          xl: "170px",
        }}
        space={"40px"}
        pt={"60px"}
        w="full"
      >
        <CelebDetails data={data} />
        <Collections />
        <Text
          _web={{
            style: {
              fontFamily: "AustinCyr_400",
            },
          }}
          _light={{
            color: "#682481",
          }}
          color={"#94A3B8"}
          fontSize="22px"
          lineHeight={"30px"}
          style={{
            fontFamily: "HK_600SemiBold",
          }}
        >
          Favorites
        </Text>

        <Favorites userId={route?.params?.id} />
      </VStack>
      <FooterSectionWeb />
    </>
  );
};

export default Profile;
