import { Center, HStack, Link, Popover, Pressable } from "native-base";

import LinkIcon from "../icons/outlinedIcons/linkIcon";
import { useDispatch } from "react-redux";
import { Popups } from "../../constants/popups";
import { setDialog } from "../../redux/currentState/actions";
import * as RootNavigation from "./../../RootNavigation";
import WebInstaIcon from "../icons/webIcons/webInstaIcon";
import WebtiktokIcon from "../icons/webIcons/webtiktokIcon";
import GrayFbIcon from "../icons/grayFbIcon";
import { Platform } from "react-native";
const SocialLinksPopover = ({ userData }) => {
  const dispatch = useDispatch();

  const route = RootNavigation.navigationRef.getCurrentRoute();

  return (
    <>
      {route?.params?.type === "profile" && Platform.OS !== "web" ? (
        <Pressable onPress={() => dispatch(setDialog(Popups.SocialLinksPopup))}>
          <Center h="full">
            <LinkIcon />
          </Center>
        </Pressable>
      ) : (
        <>
          {(userData?.instagram_handle ||
            userData?.facebook_username ||
            userData?.tiktok_handle) && (
            <Popover
              placement="bottom"
              trigger={(triggerProps) => {
                return (
                  <Pressable {...triggerProps} size={"36px"}>
                    <Center h="full">
                      <LinkIcon color="#682481" />
                    </Center>
                  </Pressable>
                );
              }}
            >
              <Popover.Content
                accessibilityLabel="Delete Customerd"
                borderWidth={"0"}
                right={"20%"}
              >
                <HStack
                  _dark={{
                    bg: "#2B2F33",
                  }}
                  _light={{
                    bg: "white",
                    borderColor: "#E9B9FF",
                    borderWidth: "1px",
                  }}
                  space={10}
                  px={"16px"}
                  py={"10px"}
                  rounded="10px"
                  style={{
                    zIndex: 4,
                  }}
                >
                  {[
                    {
                      link: userData?.instagram_handle,
                      icon: <WebInstaIcon />,
                    },
                    {
                      link: userData?.facebook_username,
                      icon: <GrayFbIcon color="#D274FF" />,
                    },
                    { link: userData?.tiktok_handle, icon: <WebtiktokIcon /> },
                  ]
                    .filter((g) => g?.link !== null)
                    .map((k, i) => {
                      if (
                        k?.link?.indexOf("http://") == 0 ||
                        k?.link?.indexOf("https://") == 0
                      ) {
                        return (
                          <Link href={k?.link} isExternal>
                            {k?.icon}
                          </Link>
                        );
                      }
                    })}
                </HStack>
              </Popover.Content>
            </Popover>
          )}
        </>
      )}
    </>
  );
};

export default SocialLinksPopover;
