import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const WebAppleIcon = () => {
  return (
    <Icon viewBox="0 0 18 22">
      <G>
        <Path
          d="M17.8072 15.3039C16.6845 18.5073 14.4055 21.3658 12.3406 21.3658C11.5106 21.3658 11.2056 20.5446 9.46149 20.5446C7.71737 20.5446 7.00175 21.3658 6.17101 21.3658C3.29157 21.3658 0 15.8167 0 11.5089C0 7.20109 2.87944 5.34717 5.34712 5.34717C6.67588 5.34717 8.10712 6.16837 9.46113 6.16837C10.8151 6.16837 11.8346 5.34717 13.163 5.34717C13.9201 5.34861 14.6675 5.51596 15.3527 5.83742C16.0379 6.15888 16.644 6.62661 17.1283 7.20758C15.7379 7.99599 14.8083 9.44164 14.8083 11.097C14.8093 13.0122 16.0515 14.6467 17.8072 15.3039Z"
          fill="black"
        />
        <Path
          d="M12.3283 3.28468C12.6203 2.9206 12.8483 2.50978 13.0028 2.06964C13.232 1.41707 13.32 0.68812 13.1641 0.00961173C13.1533 -0.037592 12.5095 0.105816 12.451 0.122031C12.0987 0.220678 11.7566 0.352676 11.4294 0.516234C10.0064 1.22753 8.69718 2.6105 8.59938 4.26551C8.58639 4.48531 8.58567 4.72242 8.63944 4.9379C8.6481 4.97177 9.11508 4.93285 9.14756 4.93068C10.4023 4.84096 11.564 4.23957 12.3283 3.28468Z"
          fill="black"
        />
      </G>
    </Icon>
  );
};

export default WebAppleIcon;
