import { HStack, VStack, Text } from "native-base";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { gradientTextWeb } from "../../constants/gradient";
import { CheckoutContext } from "../../hoc/CheckoutHOC";

const BillTable = () => {
  const { price, rewardsDiscount, percentOffDiscount, bundledItemsCount } =
    useContext(CheckoutContext);
  let [discount, setDiscount] = useState(rewardsDiscount);
  let [flockBucks, setFlockBucks] = useState(rewardsDiscount);
  const userId = useSelector((state) => state.user.id);

  useEffect(() => {
    if (percentOffDiscount && !isNaN(percentOffDiscount)) {
      if (Number(rewardsDiscount) + Number(percentOffDiscount) > price) {
        setDiscount(price);
      } else {
        setDiscount(Number(rewardsDiscount) + Number(percentOffDiscount));
      }
      if (rewardsDiscount > price) {
        setFlockBucks(price);
      } else {
        setFlockBucks(rewardsDiscount);
      }
    } else {
      if (rewardsDiscount > price) {
        setDiscount(price);
        setFlockBucks(price);
      } else {
        setDiscount(rewardsDiscount);
        setFlockBucks(rewardsDiscount);
      }
    }
  }, [rewardsDiscount, percentOffDiscount]);

  return (
    <>
      <VStack space={4} w="full">
        {[
          { label: "Shipping", value: "Free" },
          percentOffDiscount
            ? {
                label: "Promo code",
                value: percentOffDiscount
                  ? `-${percentOffDiscount.toFixed(2)}$`
                  : "-",
              }
            : null,

          userId && rewardsDiscount !== null && rewardsDiscount !== undefined
            ? {
                label: "FlockBucks Discount",
                value: isNaN(flockBucks) ? `-0$` : `-${flockBucks}$`,
              }
            : null,
          userId && bundledItemsCount > 2
            ? {
                label: "Bundle Discount",
                value: `-${(price * 30) / 100}$`,
              }
            : null,
          {
            label: "Total amount",
            value: userId
              ? bundledItemsCount > 2
                ? `$${price - (price * 30) / 100}`
                : isNaN(discount)
                ? `$${price}`
                : `$${(price - discount).toFixed(2)}`
              : "$",
          },
        ]
          .filter((f, g) => f !== null)
          .map((k, i) => (
            <HStack
              alignItems={"center"}
              justifyContent="space-between"
              w="full"
            >
              <Text
                fontSize={k?.label === "Total amount" ? "20px" : "16px"}
                style={{
                  fontFamily: "Hero_400Regular",
                }}
                _dark={{
                  color:
                    k?.label === "FlockBucks Discount" ? "#8D49DE" : "#94A3B8",
                }}
                _light={{
                  color: "#232323",
                }}
              >
                {k.label}
              </Text>
              <Text
                _web={{
                  style:
                    k?.label === "Total amount"
                      ? {
                          bg: "#232323",

                          fontFamily: "Hero_700Bold",
                        }
                      : (i === 1 || i === 2) && {
                          ...gradientTextWeb(1),

                          fontFamily: "Hero_700Bold",
                        },
                }}
                fontSize={
                  k?.label === "Total amount"
                    ? "24px"
                    : i === 1 || i === 2
                    ? "20px"
                    : "16px"
                }
                _dark={{
                  color: "white",
                }}
              >
                {k.value}
              </Text>
            </HStack>
          ))}
      </VStack>
    </>
  );
};

export default BillTable;
