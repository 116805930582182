const { Image } = require("native-base");

const RedHairColorIcon = () => {
  return (
    <Image
      size={"45px"}
      resizeMethod="scale"
      resizeMode="contain"
      source={require("./../../../assets/images/pngs/redHairColor.png")}
      alt=""
    />
  );
};

export default RedHairColorIcon;
