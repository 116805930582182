import { Button, Center, Image, Stack, Text, VStack } from "native-base";
import ChevronLeftArrowIcon from "../../icons/chevronLeftArrowIcon";

const ViewMoreSideBar = ({ heading, image }) => {
  return (
    <Center px={4}>
      <VStack w="146px" alignItems={"center"} space={"16px"}>
        <Text
          textTransform={"capitalize"}
          textAlign={"center"}
          color={"white"}
          fontSize={"18px"}
          style={{
            fontFamily: "HK_700Bold",
          }}
          noOfLines={3}
        >
          {heading}
        </Text>
        <Image
          bg="white"
          borderRadius={"20px"}
          h="200px"
          resizeMethod="scale"
          resizeMode="contain"
          source={image}
          alt=""
        />
        <Button
          variant={"ghost"}
          _text={{
            color: "#94A3B8",
            fontSize: 14,
            lineHeight: 21,
            fontFamily: "HK_600SemiBold",
          }}
          endIcon={
            <Stack pl={2}>
              <ChevronLeftArrowIcon color={"#94A3B8"} />
            </Stack>
          }
        >
          View all
        </Button>
      </VStack>
    </Center>
  );
};

export default ViewMoreSideBar;
