import { Icon } from "native-base";
import { G, Path } from "react-native-svg";
const CircleCloseIcon = () => {
  return (
    <Icon viewBox="0 0 25 25">
      <G fill="none">
        <Path
          d="M12.5 22.5C18 22.5 22.5 18 22.5 12.5C22.5 7 18 2.5 12.5 2.5C7 2.5 2.5 7 2.5 12.5C2.5 18 7 22.5 12.5 22.5Z"
          stroke="#8F8F8F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          d="M9.66992 15.33L15.3299 9.66998"
          stroke="#8F8F8F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          d="M15.3299 15.33L9.66992 9.66998"
          stroke="#8F8F8F"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </G>
    </Icon>
  );
};

export default CircleCloseIcon;
