import {
  Box,
  Center,
  HStack,
  IconButton,
  SectionList,
  Text,
} from "native-base";
import { useEffect, useState } from "react";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import InviteFriendsCard from "../../components/cards/inviteFriendsCard";
import { useSyncContacts } from "../../hooks/useSyncContacts";
import * as Contacts from "expo-contacts";
import FriendsOnFlock from "./buildingBlocks/friendsOnFlock";
import InviteFriendsOnFlock from "./buildingBlocks/inviteFriendsOnFlock";
import ChevronLeftIcon from "../../components/icons/outlinedIcons/chevronLeftIcon";
import GradientBorderButton from "../../components/buttons/gradientBorderButton";
import PhonebookIcon from "../../components/icons/filledIcons/phonebookIcon";

const FindFriendsSearchScreen = ({ navigation }) => {
  const syncContacts = useSyncContacts();

  const [showSyncButton, setShowSyncButton] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    check();
  }, [showSyncButton]);

  const check = async () => {
    const { status } = await Contacts.getPermissionsAsync();

    if (status === "granted") {
      setShowSyncButton(false);
      const k = await syncContacts();

      setData(k);
    } else {
      setShowSyncButton(true);
    }
  };

  return (
    <>
      <SectionList
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          paddingBottom: 14,
        }}
        ItemSeparatorComponent={() => (
          <Box style={{ paddingVertical: 10 }}></Box>
        )}
        sections={[
          {
            data: [
              <HStack
                pt={"65px"}
                px={"20px"}
                w="100%"
                alignItems="center"
                space={4}
              >
                <IconButton
                  onPress={() => {
                    navigation.goBack();
                  }}
                  size={6}
                  _icon={{
                    color: "white",
                  }}
                  icon={<ChevronLeftIcon />}
                />
              </HStack>,

              <InviteFriendsCard />,
              showSyncButton && (
                <Center>
                  <Center h="38px" w="164px">
                    <GradientBorderButton
                      onPress={async () => {
                        const { status } =
                          await Contacts.requestPermissionsAsync();

                        if (status === "granted") {
                          setShowSyncButton(false);
                        }
                      }}
                      gradientType="1"
                    >
                      <HStack>
                        <PhonebookIcon />
                        <Text
                          fontSize={"13px"}
                          color="white"
                          style={{
                            fontFamily: "HK_600SemiBold",
                          }}
                        >
                          Sync Phone Contacts
                        </Text>
                      </HStack>
                    </GradientBorderButton>
                  </Center>
                </Center>
              ),
              <FriendsOnFlock
                data={data
                  ?.filter((k) => Object.values(k)[0])
                  ?.map((k) => Object.values(k)[0])}
              />,
              <InviteFriendsOnFlock
                data={data
                  ?.filter((k) => Object.values(k)[0] === null)
                  ?.map((k) => Object.keys(k)[0])}
              />,
            ],
          },
        ]}
        showsVerticalScrollIndicator={false}
        stickySectionHeadersEnabled
        renderSectionHeader={(item) => <>{item.section?.header}</>}
        renderItem={({ item }) => item}
      ></SectionList>

      <BottomBar />
    </>
  );
};
export default FindFriendsSearchScreen;
