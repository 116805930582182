import { Link, ScrollView, Text, VStack } from "native-base";
import BottomBar from "../../components/bars/navigationBars/bottomBar";

const Returns = () => {
  return (
    <>
      <ScrollView zIndex={0} width="100%" showsVerticalScrollIndicator={false}>
        <VStack space={8} pb={4} px={4} pt={2}>
          <Text
            style={{
              fontFamily: "HK_500Medium",
            }}
            fontSize={16}
            lineHeight={22}
            color="white"
          >
            If you are not completely satisfied with a Flock purchase or gift,
            please review our return, refund, and exchange policies below for
            purchases. Products must be returned within 30 days of the order
            purchase in new or gently used condition. Flock monitors return
            activity for abuse and reserves the right to limit returns or
            exchanges at Flock in all instances. All returns are subject to
            validation and approval at Flock’s discretion. We may ask you for a
            driver’s license or government ID to verify your identity.
            {"\n\n"}
            Click{" "}
            <Link
              href="https://docs.google.com/forms/d/e/1FAIpQLSfwCAogoQio9esULZ3cVDq1J-ayF2jA5xgUNvyxka86kccyhQ/viewform?usp=sf_link"
              isExternal
              _text={{
                color: "#1A94FF",
                fontWeight: 500,
                fontSize: 16,
                lineHeight: 18,
                fontFamily: "HK_500Medium",
              }}
              mt={-0.5}
              _web={{
                mb: -2,
              }}
            >
              here{" "}
            </Link>
            to start or track a return Our customer care team will reach out to
            you and provide the address to send the return You are responsible
            for the cost of return shipping. Flock is not responsible for any
            damaged or lost packages in return shipping so we suggest you
            receive tracking on the return shipment. Package your return item in
            the manufacturer's product box and packaging (if available).
            Otherwise, pack your return in a well-padded envelope or box to
            prevent damage in transit. Please ensure that all returns include
            the original packing slip You will receive an email confirmation
            when your return is processed. Reach out to hi@flockshopping.com
            with any questions It takes up to 30 days to process returns and
            credit your account or to issue an online store credit. Please note
            that your financial institution may take longer to reflect the
            transaction
          </Text>
        </VStack>
      </ScrollView>
      <BottomBar />
    </>
  );
};

export default Returns;
