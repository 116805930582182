import axios from "axios";
import useSWR from "swr";
import { getHeaders, parse } from "../util/utilFunctions";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const fetcher = async (url) =>
  axios
    .get(url, {
      crossDomain: true,
    })
    .then((res) => {
      return parse(res.data);
    });

export const useGetProductReviews = (id) => {
  const { data, mutate, error, isValidating } = useSWR(
    `${apiUrl}/api/products/get_product_details/?product_id=${id}`,
    fetcher
  );
  useCheckNetwork(error);
  useLoader(isValidating);
  return {
    data,
    mutate,
  };
};
