import { useNavigation, useRoute } from "@react-navigation/native";
import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Image,
  Pressable,
  ScrollView,
  Stack,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  VStack,
} from "native-base";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pages } from "../../../constants/pages";
import WebBagIcon from "../../icons/webIcons/webBagIcon";
import WebMenuIcon from "../../icons/webIcons/webMenuIcon";
import WebSearchIcon from "../../icons/webIcons/webSearchIcon";
import WebSearchMobileIcon from "../../icons/webIcons/webSearchMobileIcon";
import WebUserIcon from "../../icons/webIcons/webUserIcon";
import RewardsAnimatedIcon from "../../icons/animatedIcons/rewardsAnimatedCoin";
import useGetCartItems from "../../../hooks/useGetCartItems";
import { ImageBackground } from "react-native";
import { changeFilter } from "../../../redux/filter/actions";
import BlueArrowIcon from "../../icons/blueArrowIcon";

const TopBarWeb = () => {
  const { data: cartItem } = useGetCartItems();
  const navigation = useNavigation();
  const route = useRoute();
  const { toggleColorMode } = useColorMode();
  const backgroundColor = useColorModeValue("white", "#111315");
  const textColor = useColorModeValue("#464646", "white");
  const dispatch = useDispatch();
  const screen = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: "xl",
  });

  const [shopMenu, setShopMenu] = useState();
  const userId = useSelector((state) => state.user.id);
  const leftSideTabs = [
    {
      label: "Shop",
    },
    {
      label: "Beauty Buddies",
      onPress: () => {
        navigation.navigate(Pages.Community);
      },
    },
    {
      label: "Brands",
      onPress: () => {
        navigation.navigate(Pages.Brands);
      },
    },
    userId
      ? {
          label: "Curated",
          onPress: () => {
            navigation.navigate("curated");
          },
        }
      : null,
    {
      label: "Search",
      onPress: () => {
        navigation.navigate(Pages.Search);
      },
    },
  ];
  const rightSideTabs = [
    {
      label: "Rewards",
      onPress: () => {
        navigation.navigate(Pages.RewardsScreen);
      },
    },
    {
      label: "Login / Sign Up",
      onPress: () => {
        if (userId) {
          navigation.navigate(Pages.UserScreen, {
            id: userId,
            type: "profile",
          });
        } else {
          navigation.navigate(Pages.Login);
        }
      },
    },
  ];
  const productTypes = useSelector((state) => state.filter.productTypes);

  return (
    <>
      {screen !== "xl" ? (
        <>
          <HStack
            h="70px"
            w="full"
            position={route?.name === "Home" ? "absolute" : "static"}
            alignItems="center"
            justifyContent={"space-around"}
          >
            <HStack alignItems="center" space={4}>
              <IconButton
                onPress={() => {
                  if (userId) {
                    navigation.navigate(Pages.UserScreen, {
                      id: userId,
                      type: "profile",
                    });
                  } else {
                    navigation.navigate("Login");
                  }
                }}
                icon={<WebUserIcon />}
              ></IconButton>
              <Pressable
                _hover={{
                  bg: route?.name === "Home" ? "none" : "#FAF1FF",
                }}
                onPress={() => navigation.navigate(Pages.Search)}
              >
                <WebSearchMobileIcon />
              </Pressable>
            </HStack>

            <Image
              alt=""
              w="73px"
              h="24px"
              resizeMethod="scale"
              fallbackSource={require("./../../../assets/images/pngs/webMobileLogo.png")}
              source={require("./../../../assets/images/pngs/webMobileLogo.png")}
            ></Image>
            <HStack alignItems="center" space={4}>
              <Pressable
                _hover={{
                  bg: route?.name === "Home" ? "none" : "#FAF1FF",
                }}
                onPress={() => navigation.navigate(Pages.CheckoutScreen)}
              >
                <VStack mb={1}>
                  <WebBagIcon />
                  {cartItem?.map((k) => k?.number).reduce((k, i) => k + i, 0) >
                    0 && (
                    <Center
                      position={"absolute"}
                      w="4"
                      h="4"
                      bottom={"-50%"}
                      bg="#682481"
                      rounded={"full"}
                    >
                      <Text
                        color={"white"}
                        fontSize={"8px"}
                        style={{
                          fontFamily: "HK_600SemiBold",
                        }}
                      >
                        {cartItem
                          ?.map((k) => k?.number)
                          .reduce((k, i) => k + i, 0) < 99
                          ? cartItem
                              ?.map((k) => k?.number)
                              .reduce((k, i) => k + i, 0)
                          : "99+"}
                      </Text>
                    </Center>
                  )}
                </VStack>
              </Pressable>
              <Pressable
                _hover={{
                  bg: route?.name === "Home" ? "none" : "#FAF1FF",
                }}
                onPress={() => navigation.navigate("menu")}
              >
                <WebMenuIcon />
              </Pressable>
            </HStack>
          </HStack>
        </>
      ) : (
        <VStack alignItems="center" w="full">
          <Center
            w="full"
            h="50px"
            bg={{
              linearGradient: {
                colors: ["#691DE3", "#FF5A92"],
                start: [0, 0],
                end: [1, 0],
              },
            }}
          >
            <Text
              letterSpacing={2}
              textTransform="uppercase"
              color="white"
              fontSize={"11px"}
              style={{
                fontFamily: "HK_700Bold",
              }}
            >
              Buy more Save more! // Spend $100 to get $20 Off // FREE SHIPpinG
              on all orders!
            </Text>
          </Center>
          <Stack
            bg={route?.name === "Home" ? "transparent" : backgroundColor}
            direction="row"
            h="60px"
            w="full"
            pt={route?.name === "Home" ? "60px" : "0"}
            position={route?.name === "Home" ? "absolute" : "static"}
            justifyContent="space-around"
            alignItems="center"
            flexWrap={"wrap"}
          >
            <Stack direction="row" alignItems="center" space={6}>
              <Pressable
                _hover={{
                  bg: route?.name === "Home" ? "transparent" : "#FAF1FF",
                }}
                onPress={() => navigation.navigate(Pages.Home)}
              >
                <Image
                  alt=""
                  w="73px"
                  h="24px"
                  resizeMethod="scale"
                  fallbackSource={require("./../../../assets/images/pngs/webLogo.png")}
                  source={require("./../../../assets/images/pngs/webLogo.png")}
                ></Image>
              </Pressable>
              {leftSideTabs
                .filter((k) => k)
                .map((k, i) => (
                  <Button
                    onPress={() => {
                      if (i === 0) {
                        setShopMenu(!shopMenu);
                      } else {
                        k?.onPress();
                        setShopMenu(false);
                      }
                    }}
                    _hover={{
                      bg: route?.name === "Home" ? "none" : "#FAF1FF",
                    }}
                    variant="ghost"
                    startIcon={i === 4 && <WebSearchIcon color="#464646" />}
                    _text={{
                      fontFamily: "HK_700Bold",
                      color: textColor,
                      fontSize: "14px",
                      textTransform: "uppercase",
                      letterSpacing: 4,
                    }}
                  >
                    {k?.label}
                  </Button>
                ))}
            </Stack>
            <Stack direction="row" alignItems="center" space={"10px"}>
              {rightSideTabs.map((k, i) => {
                if (i === 1 && userId) {
                  return (
                    <IconButton
                      _hover={{
                        bg: route?.name === "Home" ? "none" : "#FAF1FF",
                      }}
                      onPress={k?.onPress}
                      icon={<WebUserIcon />}
                    />
                  );
                } else {
                  return (
                    <Button
                      _hover={{
                        bg: route?.name === "Home" ? "none" : "#FAF1FF",
                      }}
                      leftIcon={i === 0 && <RewardsAnimatedIcon size={4} />}
                      onPress={k?.onPress}
                      variant="ghost"
                      _text={{
                        fontFamily: "HK_700Bold",
                        color: textColor,
                        fontSize: "14px",
                        textTransform: "uppercase",
                        letterSpacing: 4,
                      }}
                    >
                      {k?.label}
                    </Button>
                  );
                }
              })}
              <Pressable
                _hover={{
                  bg: route?.name === "Home" ? "none" : "#FAF1FF",
                }}
                onPress={() => navigation.navigate(Pages.CheckoutScreen)}
              >
                <VStack mb={1}>
                  <WebBagIcon />
                  {cartItem?.map((k) => k?.number).reduce((k, i) => k + i, 0) >
                    0 && (
                    <Center
                      position={"absolute"}
                      w="4"
                      h="4"
                      bottom={"-50%"}
                      bg="#682481"
                      rounded={"full"}
                    >
                      <Text
                        color={"white"}
                        fontSize={"8px"}
                        style={{
                          fontFamily: "HK_600SemiBold",
                        }}
                      >
                        {cartItem
                          ?.map((k) => k?.number)
                          .reduce((k, i) => k + i, 0) < 99
                          ? cartItem
                              ?.map((k) => k?.number)
                              .reduce((k, i) => k + i, 0)
                          : "99+"}
                      </Text>
                    </Center>
                  )}
                </VStack>
              </Pressable>
              {/* <Button
                _hover={{
                  bg: "#FAF1FF",
                }}
                onPress={toggleColorMode}
                variant="ghost"
                _text={{
                  fontFamily: "HK_700Bold",
                  color: textColor,
                  fontSize: "14px",
                  textTransform: "uppercase",
                  letterSpacing: 4,
                }}
              >
                Change theme
              </Button> */}
            </Stack>
          </Stack>
          {shopMenu && (
            <ScrollView horizontal w="full" mt={"110px"} position={"absolute"}>
              <HStack
                px={4}
                pb={4}
                justifyContent={"center"}
                w="full"
                bg={route?.name === "Home" ? "none" : "white"}
                space={4}
                alignItems="center"
              >
                {[
                  {
                    src: require("./../../../assets/images/pngs/makeupweb.png"),
                    label: "Makeup",
                    index: "4",
                  },
                  {
                    src: require("./../../../assets/images/pngs/skincareweb.png"),
                    label: "Skin care",
                    index: "6",
                  },
                  {
                    src: require("./../../../assets/images/pngs/hairecareweb.png"),
                    label: "Hair care",
                    index: "3",
                  },
                  {
                    src: require("./../../../assets/images/pngs/toolsweb.png"),
                    label: "Tools",
                    index: "7",
                  },
                ]?.map((k, i) => (
                  <Pressable
                    onPress={() => {
                      let vals = [...productTypes];
                      if (vals.includes(k.index) === true) {
                        vals = [...vals.filter((value) => value !== k.index)];
                      } else {
                        vals.push(k.index);
                      }
                      dispatch(changeFilter({ productTypes: vals }));
                      dispatch({
                        type: "SET_APPLY_FILTER",
                      });
                      setShopMenu(false);
                      navigation.navigate(Pages.ShopScreen);
                    }}
                  >
                    <ImageBackground
                      alt=""
                      style={{
                        height: 200,
                        width: 325,
                        resizeMethod: "scale",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      source={k?.src}
                    >
                      <Text
                        textAlign={"center"}
                        textTransform={"uppercase"}
                        fontSize={"46px"}
                        color="white"
                        style={{
                          fontFamily: "AustinCyr_400",
                        }}
                      >
                        {k?.label}
                      </Text>
                    </ImageBackground>
                  </Pressable>
                ))}
                <Pressable
                  flexDir={"row"}
                  alignItems="center"
                  onPress={() => {
                    setShopMenu(!shopMenu);
                    navigation.navigate(Pages.ShopScreen);
                  }}
                >
                  <Text
                    textAlign={"center"}
                    textTransform={"uppercase"}
                    fontSize={"14px"}
                    color="#464646"
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                  >
                    Shop all
                  </Text>
                  <BlueArrowIcon color="#464646" />
                </Pressable>
              </HStack>
            </ScrollView>
          )}
        </VStack>
      )}
    </>
  );
};

export default TopBarWeb;
