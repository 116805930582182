import { Center, Text, View, VStack } from "native-base";
import LetsRollButton from "../../components/buttons/letsRollButton";
import DoItLaterButton from "../../components/buttons/doItLaterButton";
import { Pages } from "../../constants/pages";
import { setAnalytics } from "../../analytics";
import { ImageBackground } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useSelector } from "react-redux";
import useGetUser from "../../hooks/useGetUser";

const BeautyQuizScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const userId = useSelector((state) => state.user.id);
  useGetUser("profile", userId);

  return (
    <>
      <View h="full" w="full">
        <ImageBackground
          resizeMethod="scale"
          resizeMode="cover"
          style={{
            height: "100%",
            width: "100%",
          }}
          source={require("./../../assets/images/pngs/cover_screen.png")}
          alt=""
        >
          <VStack space={"720px"}>
            <VStack top={"109"} alignItems="center">
              <Text
                textAlign={"center"}
                color={"white"}
                fontSize={18}
                style={{
                  fontFamily: "Poppins_500Medium",
                }}
                lineHeight={27}
              >
                Take your
              </Text>

              <Text
                textAlign={"center"}
                color={"white"}
                fontSize={22}
                style={{
                  fontFamily: "Poppins_500Medium",
                  textShadowColor: "#FFFFFF",
                  textShadowOffset: { width: -1, height: -1 },
                  textShadowRadius: 3,
                }}
                letterSpacing={2}
                lineHeight={27}
              >
                BEAUTY QUIZ
              </Text>
              <Text
                position={"absolute"}
                top={"70"}
                textAlign={"center"}
                color={"white"}
                fontSize={"36px"}
                lineHeight={"40px"}
                style={{
                  fontFamily: "Poppins_500Medium",
                }}
              >
                Get personalized recommendations {"\n"} in 3 mins!
              </Text>
            </VStack>
            <VStack space={"25px"}>
              <Center>
                <LetsRollButton
                  onPress={() => {
                    setAnalytics(
                      "LetsRollButton Survey Screen",
                      "LetsRollButton Clicked",
                      {
                        screen: route?.name,
                      }
                    );
                    navigation.navigate(Pages.AboutYourSelfScreen);
                  }}
                />
              </Center>
            </VStack>
          </VStack>
          <Center pt="30px">
            <DoItLaterButton
              onPress={() => {
                setAnalytics(
                  "DoItLaterButton Survey Screen",
                  "DoItLaterButton Clicked",
                  {
                    screen: route?.name,
                  }
                );
                navigation.navigate(Pages.Home);
              }}
            />
          </Center>
        </ImageBackground>
      </View>
    </>
  );
};

export default BeautyQuizScreen;
