export const Brands = {
  1: "Anastasia Beverly Hills",
  2: "Aquis",
  3: "BURBERRY",
  4: "BeautyBio",
  5: "Benefit Cosmetics",
  6: "Better Not Younger",
  7: "Bio Ionic",
  8: "Bobbi Brown",
  9: "Briogeo",
  10: "Bumble and bumble",
  11: "Buxom",
  12: "CHANEL",
  13: "CLINIQUE",
  14: "COACH",
  15: "COLOR WOW",
  16: "COOLA",
  17: "Calvin Klein",
  18: "Carolina Herrera",
  19: "Chloé",
  20: "Clarins",
  21: "DERMAFLASH",
  22: "DOLCE&GABBANA",
  23: "Dermalogica",
  24: "Dior",
  25: "Donna Karan",
  26: "Drunk Elephant",
  27: "Drybar",
  28: "Dyson",
  29: "Ellis Brooklyn",
  30: "Estée Lauder",
  31: "FOREO",
  32: "Fenty Beauty by Rihanna",
  33: "First Aid Beauty",
  34: "GLAMGLOW",
  35: "GUERLAIN",
  36: "Givenchy",
  37: "Glamnetic",
  38: "Grace Eleyae",
  39: "Grande Cosmetics",
  40: "Gucci",
  41: "Hourglass",
  42: "IGK",
  43: "IT Cosmetics",
  44: "Iconic London",
  45: "Indie Lee",
  46: "JIMMY CHOO",
  47: "Jack Black",
  48: "John Varvatos",
  49: "Josie Maran",
  50: "Juicy Couture",
  51: "KORRES",
  52: "KVD Beauty",
  53: "Kate Somerville",
  54: "Kiehl's Since 1851",
  55: "Kitsch",
  56: "L'Occitane",
  57: "Lancôme",
  58: "Laura Mercier",
  59: "Lilly Lashes",
  60: "Living Proof",
  61: "Mario Badescu",
  62: "Melanin Haircare",
  63: "Montblanc",
  64: "Mugler",
  65: "Murad",
  66: "NARS",
  67: "NUDESTIX",
  68: "NuFACE",
  69: "Nurse Jamie",
  70: "OUAI",
  71: "Olaplex",
  72: "Origins",
  73: "PMD",
  74: "Paco Rabanne",
  75: "Peter Thomas Roth",
  76: "Prada",
  77: "Pureology",
  78: "Qhemet Biologics",
  79: "Ralph Lauren",
  80: "Rosebud Perfume Co.",
  81: "Shiseido",
  82: "Slip",
  83: "Smashbox",
  84: "St. Tropez",
  85: "StriVectin",
  86: "Sunday Riley",
  87: "Supergoop!",
  88: "T3",
  89: "TAN-LUXE",
  90: "TOM FORD",
  91: "TWEEZERMAN",
  92: "The Original MakeUp Eraser",
  93: "Too Faced",
  94: "Touchland",
  95: "Valentino",
  96: "Velour Lashes",
  97: "Verb",
  98: "Versace",
  99: "Viktor&Rolf",
  100: "Virtue",
  101: "Yves Saint Laurent",
  102: "bareMinerals",
  103: "beautyblender",
  104: "belif",
  105: "boscia",
  106: "dpHUE",
  107: "fresh",
  108: "ghd",
  109: "philosophy",
  110: "stila",
  111: "tarte",
  112: "The Ordinary",
  113: "The INKEY List",
  114: "Tatcha",
  115: "Armani Beauty",
  116: "Charlotte Tilbury",
  117: "HUDA BEAUTY",
  118: "MAKE UP FOR EVER",
  119: "MAKEUP BY MARIO",
  120: "Rare Beauty by Selena Gomez",
  121: "Kérastase",
};

export const BrandImages = {
  "Anastasia Beverly Hills":
    "https://f-products.s3.amazonaws.com/Brand_logos/Anastasia+Beverly+Hills/Anastasia_Beverly_Hills1.jpeg",
  "Anastasia Beverly Hills":
    "https://f-products.s3.amazonaws.com/Brand_logos/Anastasia+Beverly+Hills/Anastasia_Beverly_Hills2.png",
  "Anastasia Beverly Hills":
    "https://f-products.s3.amazonaws.com/Brand_logos/Anastasia+Beverly+Hills/Anastasia_Beverly_Hills3.png",
  "Anastasia Beverly Hills":
    "https://f-products.s3.amazonaws.com/Brand_logos/Anastasia+Beverly+Hills/Anastasia_Beverly_Hills4.jpg",
  "Anastasia Beverly Hills":
    "https://f-products.s3.amazonaws.com/Brand_logos/Anastasia+Beverly+Hills/Anastasia_Beverly_Hills5.png",
  "Anastasia Beverly Hills":
    "https://f-products.s3.amazonaws.com/Brand_logos/Anastasia+Beverly+Hills/Anastasia_Beverly_Hills6.jpg",
  "Anastasia Beverly Hills":
    "https://f-products.s3.amazonaws.com/Brand_logos/Anastasia+Beverly+Hills/Anastasia_Beverly_Hills7.png",
  Aquis: "https://f-products.s3.amazonaws.com/Brand_logos/Aquis/Aquis_1.png",
  Aquis: "https://f-products.s3.amazonaws.com/Brand_logos/Aquis/Aquis_2.png",
  Aquis: "https://f-products.s3.amazonaws.com/Brand_logos/Aquis/Aquis_3.png",
  Aquis: "https://f-products.s3.amazonaws.com/Brand_logos/Aquis/Aquis_4.png",
  Aquis: "https://f-products.s3.amazonaws.com/Brand_logos/Aquis/Aquis_5.png",
  Aquis: "https://f-products.s3.amazonaws.com/Brand_logos/Aquis/Aquis_6.jpg",
  Aquis: "https://f-products.s3.amazonaws.com/Brand_logos/Aquis/Aquis_7.png",
  bareMinerals:
    "https://f-products.s3.amazonaws.com/Brand_logos/bareMinerals/bareMinerals_1.png",
  bareMinerals:
    "https://f-products.s3.amazonaws.com/Brand_logos/bareMinerals/bareMinerals_2.png",
  bareMinerals:
    "https://f-products.s3.amazonaws.com/Brand_logos/bareMinerals/bareMinerals_3.jpeg",
  bareMinerals:
    "https://f-products.s3.amazonaws.com/Brand_logos/bareMinerals/bareMinerals_4.jpg",
  BeautyBio:
    "https://f-products.s3.amazonaws.com/Brand_logos/BeautyBio/BeautyBio_1.png",
  BeautyBio:
    "https://f-products.s3.amazonaws.com/Brand_logos/BeautyBio/BeautyBio_2.jpeg",
  beautyblender:
    "https://f-products.s3.amazonaws.com/Brand_logos/beautyblender/beautyblender_1.jpg",
  beautyblender:
    "https://f-products.s3.amazonaws.com/Brand_logos/beautyblender/beautyblender_2.jpg",
  belif: "https://f-products.s3.amazonaws.com/Brand_logos/belif/belif_1.png",
  belif: "https://f-products.s3.amazonaws.com/Brand_logos/belif/belif_2.png",
  belif: "https://f-products.s3.amazonaws.com/Brand_logos/belif/belif_3.jpg",
  "Benefit Cosmetics":
    "https://f-products.s3.amazonaws.com/Brand_logos/Benefit+Cosmetics/Benefit_Cosmetics_1.jpg",
  "Benefit Cosmetics":
    "https://f-products.s3.amazonaws.com/Brand_logos/Benefit+Cosmetics/Benefit_Cosmetics_2.png",
  "Benefit Cosmetics":
    "https://f-products.s3.amazonaws.com/Brand_logos/Benefit+Cosmetics/Benefit_Cosmetics_3.png",
  "Benefit Cosmetics":
    "https://f-products.s3.amazonaws.com/Brand_logos/Benefit+Cosmetics/Benefit_Cosmetics_4.png",
  "Benefit Cosmetics":
    "https://f-products.s3.amazonaws.com/Brand_logos/Benefit+Cosmetics/Benefit_Cosmetics_5.png",
  "Better Not Younger":
    "https://f-products.s3.amazonaws.com/Brand_logos/Better+Not+Younger/Better_Not_Younger_1.png",
  "Better Not Younger":
    "https://f-products.s3.amazonaws.com/Brand_logos/Better+Not+Younger/Better_Not_Younger_2.png",
  "Better Not Younger":
    "https://f-products.s3.amazonaws.com/Brand_logos/Better+Not+Younger/Better_Not_Younger_3.png",
  "Better Not Younger":
    "https://f-products.s3.amazonaws.com/Brand_logos/Better+Not+Younger/Better_Not_Younger_4.png",
  "Bio Ionic":
    "https://f-products.s3.amazonaws.com/Brand_logos/Bio+Ionic/Bio_Ionic_1.jpg",
  "Bio Ionic":
    "https://f-products.s3.amazonaws.com/Brand_logos/Bio+Ionic/Bio_Ionic_2.png",
  "Bio Ionic":
    "https://f-products.s3.amazonaws.com/Brand_logos/Bio+Ionic/Bio_Ionic_3.jpeg",
  "Bobbi Brown":
    "https://f-products.s3.amazonaws.com/Brand_logos/Bobbi+Brown/Bobbi_Brown_1.jpeg",
  "Bobbi Brown":
    "https://f-products.s3.amazonaws.com/Brand_logos/Bobbi+Brown/Bobbi_Brown_2.jpg",
  boscia: "https://f-products.s3.amazonaws.com/Brand_logos/boscia/boscia_1.jpg",
  boscia: "https://f-products.s3.amazonaws.com/Brand_logos/boscia/boscia_2.jpg",
  boscia:
    "https://f-products.s3.amazonaws.com/Brand_logos/boscia/boscia_3.jpeg",
  boscia: "https://f-products.s3.amazonaws.com/Brand_logos/boscia/boscia_4.png",
  Briogeo:
    "https://f-products.s3.amazonaws.com/Brand_logos/Briogeo/Briogeo_1.jpg",
  Briogeo:
    "https://f-products.s3.amazonaws.com/Brand_logos/Briogeo/Briogeo_2.png",
  Briogeo:
    "https://f-products.s3.amazonaws.com/Brand_logos/Briogeo/Briogeo_3.png",
  Briogeo:
    "https://f-products.s3.amazonaws.com/Brand_logos/Briogeo/Briogeo_4.jpeg",
  "Bumble and bumble":
    "https://f-products.s3.amazonaws.com/Brand_logos/Bumble+and+bumble/Bumble_and_bumble_1.png",
  "Bumble and bumble":
    "https://f-products.s3.amazonaws.com/Brand_logos/Bumble+and+bumble/Bumble_and_bumble_2.png",
  "Bumble and bumble":
    "https://f-products.s3.amazonaws.com/Brand_logos/Bumble+and+bumble/Bumble_and_bumble_3.jpg",
  BURBERRY:
    "https://f-products.s3.amazonaws.com/Brand_logos/BURBERRY/BURBERRY_1.jpg",
  BURBERRY:
    "https://f-products.s3.amazonaws.com/Brand_logos/BURBERRY/BURBERRY_2.jpg",
  BURBERRY:
    "https://f-products.s3.amazonaws.com/Brand_logos/BURBERRY/BURBERRY_3.png",
  BURBERRY:
    "https://f-products.s3.amazonaws.com/Brand_logos/BURBERRY/BURBERRY_4.jpg",
  BURBERRY:
    "https://f-products.s3.amazonaws.com/Brand_logos/BURBERRY/BURBERRY_5.jpg",
  BURBERRY:
    "https://f-products.s3.amazonaws.com/Brand_logos/BURBERRY/BURBERRY_6.png",
  Buxom: "https://f-products.s3.amazonaws.com/Brand_logos/Buxom/Buxom_1.jpeg",
  Buxom: "https://f-products.s3.amazonaws.com/Brand_logos/Buxom/Buxom_2.jpg",
  Buxom: "https://f-products.s3.amazonaws.com/Brand_logos/Buxom/Buxom_3.png",
  Buxom: "https://f-products.s3.amazonaws.com/Brand_logos/Buxom/Buxom_4.jpg",
  Buxom: "https://f-products.s3.amazonaws.com/Brand_logos/Buxom/Buxom_5.jpg",
  "Calvin Klein":
    "https://f-products.s3.amazonaws.com/Brand_logos/Calvin+Klein/Calvin_Klein_1.png",
  "Calvin Klein":
    "https://f-products.s3.amazonaws.com/Brand_logos/Calvin+Klein/Calvin_Klein_2.jpg",
  "Calvin Klein":
    "https://f-products.s3.amazonaws.com/Brand_logos/Calvin+Klein/Calvin_Klein_3.png",
  "Calvin Klein":
    "https://f-products.s3.amazonaws.com/Brand_logos/Calvin+Klein/Calvin_Klein_4.jpg",
  "Calvin Klein":
    "https://f-products.s3.amazonaws.com/Brand_logos/Calvin+Klein/Calvin_Klein_5.png",
  "Calvin Klein":
    "https://f-products.s3.amazonaws.com/Brand_logos/Calvin+Klein/Calvin_Klein_6.jpg",
  "Carolina Herrera":
    "https://f-products.s3.amazonaws.com/Brand_logos/Carolina+Herrera/Carolina_Herrera_1.jpeg",
  "Carolina Herrera":
    "https://f-products.s3.amazonaws.com/Brand_logos/Carolina+Herrera/Carolina_Herrera_2.jpg",
  "Carolina Herrera":
    "https://f-products.s3.amazonaws.com/Brand_logos/Carolina+Herrera/Carolina_Herrera_3.jpg",
  "Carolina Herrera":
    "https://f-products.s3.amazonaws.com/Brand_logos/Carolina+Herrera/Carolina_Herrera_4.jpeg",
  CHANEL: "https://f-products.s3.amazonaws.com/Brand_logos/CHANEL/CHANEL_1.jpg",
  CHANEL: "https://f-products.s3.amazonaws.com/Brand_logos/CHANEL/CHANEL_2.png",
  CHANEL: "https://f-products.s3.amazonaws.com/Brand_logos/CHANEL/CHANEL_3.png",
  CHANEL: "https://f-products.s3.amazonaws.com/Brand_logos/CHANEL/CHANEL_4.png",
  CHANEL: "https://f-products.s3.amazonaws.com/Brand_logos/CHANEL/CHANEL_5.jpg",
  Chloé: "https://f-products.s3.amazonaws.com/Brand_logos/Chloé/Chlo_1.png",
  Chloé: "https://f-products.s3.amazonaws.com/Brand_logos/Chloé/Chlo_2.png",
  Clarins:
    "https://f-products.s3.amazonaws.com/Brand_logos/Clarins/Clarins_1.jpg",
  Clarins:
    "https://f-products.s3.amazonaws.com/Brand_logos/Clarins/Clarins_2.png",
  Clarins:
    "https://f-products.s3.amazonaws.com/Brand_logos/Clarins/Clarins_3.jpg",
  Clarins:
    "https://f-products.s3.amazonaws.com/Brand_logos/Clarins/Clarins_4.png",
  Clarins:
    "https://f-products.s3.amazonaws.com/Brand_logos/Clarins/Clarins_5.png",
  Clarins:
    "https://f-products.s3.amazonaws.com/Brand_logos/Clarins/Clarins_6.png",
  CLINIQUE:
    "https://f-products.s3.amazonaws.com/Brand_logos/CLINIQUE/CLINIQUE_1.png",
  CLINIQUE:
    "https://f-products.s3.amazonaws.com/Brand_logos/CLINIQUE/CLINIQUE_2.png",
  CLINIQUE:
    "https://f-products.s3.amazonaws.com/Brand_logos/CLINIQUE/CLINIQUE_4.png",
  CLINIQUE:
    "https://f-products.s3.amazonaws.com/Brand_logos/CLINIQUE/CLINIQUE_5.png",
  CLINIQUE:
    "https://f-products.s3.amazonaws.com/Brand_logos/CLINIQUE/CLINIQUE_6.png",
  COACH: "https://f-products.s3.amazonaws.com/Brand_logos/COACH/COACH_1.png",
  COACH: "https://f-products.s3.amazonaws.com/Brand_logos/COACH/COACH_2.png",
  "COLOR WOW":
    "https://f-products.s3.amazonaws.com/Brand_logos/COLOR+WOW/COLOR_WOW_1.png",
  "COLOR WOW":
    "https://f-products.s3.amazonaws.com/Brand_logos/COLOR+WOW/COLOR_WOW_2.jpeg",
  WOWproduct:
    "https://f-products.s3.amazonaws.com/Brand_logos/COLOR+WOW/WOWproduct.jpeg",
  COOLA: "https://f-products.s3.amazonaws.com/Brand_logos/COOLA/COOLA_1.png",
  COOLA: "https://f-products.s3.amazonaws.com/Brand_logos/COOLA/COOLA.jpg",
  DERMAFLASH:
    "https://f-products.s3.amazonaws.com/Brand_logos/DERMAFLASH/DERMAFLASH_1.png",
  Dermalogica:
    "https://f-products.s3.amazonaws.com/Brand_logos/Dermalogica/Dermalogica_1.png",
  Dermalogica:
    "https://f-products.s3.amazonaws.com/Brand_logos/Dermalogica/Dermalogica_2.png",
  Dermalogica:
    "https://f-products.s3.amazonaws.com/Brand_logos/Dermalogica/Dermalogica_3.png",
  Dior: "https://f-products.s3.amazonaws.com/Brand_logos/Dior/Dior_1.jpg",
  Dior: "https://f-products.s3.amazonaws.com/Brand_logos/Dior/Dior_2.png",
  Dior: "https://f-products.s3.amazonaws.com/Brand_logos/Dior/Dior_3.jpeg",
  Dior: "https://f-products.s3.amazonaws.com/Brand_logos/Dior/Dior_4.jpg",
  "DOLCE&GABBANA":
    "https://f-products.s3.amazonaws.com/Brand_logos/DOLCE&GABBANA/DOLCEGABBANA_1.jpg",
  "DOLCE&GABBANA":
    "https://f-products.s3.amazonaws.com/Brand_logos/DOLCE&GABBANA/DOLCEGABBANA_2.png",
  "DOLCE&GABBANA":
    "https://f-products.s3.amazonaws.com/Brand_logos/DOLCE&GABBANA/DOLCEGABBANA_3.png",
  "DOLCE&GABBANA":
    "https://f-products.s3.amazonaws.com/Brand_logos/DOLCE&GABBANA/DOLCEGABBANA_4.png",
  "Donna Karan":
    "https://f-products.s3.amazonaws.com/Brand_logos/Donna+Karan/Donna_Karan_1.png",
  "Donna Karan":
    "https://f-products.s3.amazonaws.com/Brand_logos/Donna+Karan/Donna_Karan_2.png",
  "Donna Karan":
    "https://f-products.s3.amazonaws.com/Brand_logos/Donna+Karan/Donna_Karan_3.png",
  "Donna Karan":
    "https://f-products.s3.amazonaws.com/Brand_logos/Donna+Karan/Donna_Karan_4.png",
  dpHUE: "https://f-products.s3.amazonaws.com/Brand_logos/dpHUE/dpHUE_1.png",
  dpHUE: "https://f-products.s3.amazonaws.com/Brand_logos/dpHUE/dpHUE_2.png",
  "Drunk Elephant":
    "https://f-products.s3.amazonaws.com/Brand_logos/Drunk+Elephant/Drunk_Elephant_1.jpg",
  "Drunk Elephant":
    "https://f-products.s3.amazonaws.com/Brand_logos/Drunk+Elephant/Drunk_Elephant_2.jpg",
  "Drunk Elephant":
    "https://f-products.s3.amazonaws.com/Brand_logos/Drunk+Elephant/Drunk_Elephant_3.jpg",
  "Drunk Elephant":
    "https://f-products.s3.amazonaws.com/Brand_logos/Drunk+Elephant/Drunk_Elephant_4.png",
  Drybar:
    "https://f-products.s3.amazonaws.com/Brand_logos/Drybar/Drybar_1.jpeg",
  Drybar: "https://f-products.s3.amazonaws.com/Brand_logos/Drybar/Drybar_2.png",
  Drybar: "https://f-products.s3.amazonaws.com/Brand_logos/Drybar/Drybar_3.png",
  Drybar: "https://f-products.s3.amazonaws.com/Brand_logos/Drybar/Drybar_4.png",
  Drybar: "https://f-products.s3.amazonaws.com/Brand_logos/Drybar/Drybar_5.jpg",
  Drybar: "https://f-products.s3.amazonaws.com/Brand_logos/Drybar/Drybar_6.png",
  Drybar: "https://f-products.s3.amazonaws.com/Brand_logos/Drybar/Drybar_7.png",
  Dyson: "https://f-products.s3.amazonaws.com/Brand_logos/Dyson/Dyson_1.png",
  "Ellis Brooklyn":
    "https://f-products.s3.amazonaws.com/Brand_logos/Ellis+Brooklyn/Ellis_Brooklyn_1.jpeg",
  "Estée Lauder":
    "https://f-products.s3.amazonaws.com/Brand_logos/Estée+Lauder/Este_Lauder_1.jpg",
  "Estée Lauder":
    "https://f-products.s3.amazonaws.com/Brand_logos/Estée+Lauder/Este_Lauder_2.jpg",
  "Estée Lauder":
    "https://f-products.s3.amazonaws.com/Brand_logos/Estée+Lauder/Este_Lauder_3.jpg",
  "Fenty Beauty by Rihanna":
    "https://f-products.s3.amazonaws.com/Brand_logos/Fenty+Beauty+by+Rihanna/Fenty_Beauty_by_Rihanna_1.jpeg",
  "Fenty Beauty by Rihanna":
    "https://f-products.s3.amazonaws.com/Brand_logos/Fenty+Beauty+by+Rihanna/Fenty_Beauty_by_Rihanna_2.jpg",
  "Fenty Beauty by Rihanna":
    "https://f-products.s3.amazonaws.com/Brand_logos/Fenty+Beauty+by+Rihanna/Fenty_Beauty_by_Rihanna_3.jpg",
  "Fenty Beauty by Rihanna":
    "https://f-products.s3.amazonaws.com/Brand_logos/Fenty+Beauty+by+Rihanna/Fenty_Beauty_by_Rihanna_4.jpg",
  "First Aid Beauty":
    "https://f-products.s3.amazonaws.com/Brand_logos/First+Aid+Beauty/First_Aid_Beauty_1.png",
  FOREO: "https://f-products.s3.amazonaws.com/Brand_logos/FOREO/FOREO_1.png",
  FOREO: "https://f-products.s3.amazonaws.com/Brand_logos/FOREO/FOREO_2.png",
  fresh: "https://f-products.s3.amazonaws.com/Brand_logos/fresh/fresh_1.png",
  ghd: "https://f-products.s3.amazonaws.com/Brand_logos/ghd/ghd_1.png",
  Givenchy:
    "https://f-products.s3.amazonaws.com/Brand_logos/Givenchy/Givenchy_1.png",
  Givenchy:
    "https://f-products.s3.amazonaws.com/Brand_logos/Givenchy/Givenchy_2.png",
  Givenchy:
    "https://f-products.s3.amazonaws.com/Brand_logos/Givenchy/Givenchy_3.png",
  Givenchy:
    "https://f-products.s3.amazonaws.com/Brand_logos/Givenchy/Givenchy_4.png",
  GLAMGLOW:
    "https://f-products.s3.amazonaws.com/Brand_logos/GLAMGLOW/GLAMGLOW_1.png",
  GLAMGLOW:
    "https://f-products.s3.amazonaws.com/Brand_logos/GLAMGLOW/GLAMGLOW_2.png",
  GLAMGLOW:
    "https://f-products.s3.amazonaws.com/Brand_logos/GLAMGLOW/GLAMGLOW_3.png",
  GLAMGLOW:
    "https://f-products.s3.amazonaws.com/Brand_logos/GLAMGLOW/GLAMGLOW_4.jpg",
  Glamnetic:
    "https://f-products.s3.amazonaws.com/Brand_logos/Glamnetic/Glamnetic_1.jpg",
  "Grace Eleyae":
    "https://f-products.s3.amazonaws.com/Brand_logos/Grace+Eleyae/Grace_Eleyae_1.png",
  "Grace Eleyae":
    "https://f-products.s3.amazonaws.com/Brand_logos/Grace+Eleyae/Grace_Eleyae_2.jpg",
  "Grande Cosmetics":
    "https://f-products.s3.amazonaws.com/Brand_logos/Grande+Cosmetics/Grande_Cosmetics_1.jpg",
  Gucci: "https://f-products.s3.amazonaws.com/Brand_logos/Gucci/Gucci_1.png",
  Gucci: "https://f-products.s3.amazonaws.com/Brand_logos/Gucci/Gucci_2.png",
  Gucci: "https://f-products.s3.amazonaws.com/Brand_logos/Gucci/Gucci_3.jpg",
  Gucci: "https://f-products.s3.amazonaws.com/Brand_logos/Gucci/Gucci_4.png",
  Gucci: "https://f-products.s3.amazonaws.com/Brand_logos/Gucci/Gucci_5.jpg",
  GUERLAIN:
    "https://f-products.s3.amazonaws.com/Brand_logos/GUERLAIN/GUERLAIN_1.png",
  GUERLAIN:
    "https://f-products.s3.amazonaws.com/Brand_logos/GUERLAIN/GUERLAIN_2.png",
  GUERLAIN:
    "https://f-products.s3.amazonaws.com/Brand_logos/GUERLAIN/GUERLAIN_3.png",
  GUERLAIN:
    "https://f-products.s3.amazonaws.com/Brand_logos/GUERLAIN/GUERLAIN_4.jpg",
  GUERLAIN:
    "https://f-products.s3.amazonaws.com/Brand_logos/GUERLAIN/GUERLAIN_5.jpg",
  Hourglass:
    "https://f-products.s3.amazonaws.com/Brand_logos/Hourglass/Hourglass_1.png",
  Hourglass:
    "https://f-products.s3.amazonaws.com/Brand_logos/Hourglass/Hourglass_2.jpeg",
  "Iconic London":
    "https://f-products.s3.amazonaws.com/Brand_logos/Iconic+London/Iconic_London_1.jpg",
  "Iconic London":
    "https://f-products.s3.amazonaws.com/Brand_logos/Iconic+London/Iconic_London_2.jpeg",
  "Iconic London":
    "https://f-products.s3.amazonaws.com/Brand_logos/Iconic+London/Iconic_London_3.png",
  "Iconic London":
    "https://f-products.s3.amazonaws.com/Brand_logos/Iconic+London/Iconic_London_4.png",
  IGK: "https://f-products.s3.amazonaws.com/Brand_logos/IGK/IGK_1.png",
  "Indie Lee":
    "https://f-products.s3.amazonaws.com/Brand_logos/Indie+Lee/Indie_Lee_1.png",
  "Indie Lee":
    "https://f-products.s3.amazonaws.com/Brand_logos/Indie+Lee/Indie_Lee_2.png",
  "Indie Lee":
    "https://f-products.s3.amazonaws.com/Brand_logos/Indie+Lee/Indie_Lee_3.png",
  "IT Cosmetics":
    "https://f-products.s3.amazonaws.com/Brand_logos/IT+Cosmetics/IT_Cosmetics_1.png",
  "IT Cosmetics":
    "https://f-products.s3.amazonaws.com/Brand_logos/IT+Cosmetics/IT_Cosmetics_2.png",
  "IT Cosmetics":
    "https://f-products.s3.amazonaws.com/Brand_logos/IT+Cosmetics/IT_Cosmetics_3.jpg",
  "IT Cosmetics":
    "https://f-products.s3.amazonaws.com/Brand_logos/IT+Cosmetics/IT_Cosmetics_4.jpg",
  "Jack Black":
    "https://f-products.s3.amazonaws.com/Brand_logos/Jack+Black/Jack_Black_1.jpg",
  "JIMMY CHOO":
    "https://f-products.s3.amazonaws.com/Brand_logos/JIMMY+CHOO/JIMMY_CHOO_1.jpg",
  "JIMMY CHOO":
    "https://f-products.s3.amazonaws.com/Brand_logos/JIMMY+CHOO/JIMMY_CHOO_2.jpg",
  "JIMMY CHOO":
    "https://f-products.s3.amazonaws.com/Brand_logos/JIMMY+CHOO/JIMMY_CHOO_4.png",
  "JIMMY CHOO":
    "https://f-products.s3.amazonaws.com/Brand_logos/JIMMY+CHOO/JIMMY_CHOO_5.png",
  "JIMMY CHOO":
    "https://f-products.s3.amazonaws.com/Brand_logos/JIMMY+CHOO/JIMMY_CHOO_6.png",
  "JIMMY CHOO":
    "https://f-products.s3.amazonaws.com/Brand_logos/JIMMY+CHOO/JIMMY_CHOO_7.jpg",
  "John Varvatos":
    "https://f-products.s3.amazonaws.com/Brand_logos/John+Varvatos/John_Varvatos_1.png",
  "John Varvatos":
    "https://f-products.s3.amazonaws.com/Brand_logos/John+Varvatos/John_Varvatos_2.jpg",
  "Josie Maran":
    "https://f-products.s3.amazonaws.com/Brand_logos/Josie+Maran/Josie_Maran_1.png",
  "Josie Maran":
    "https://f-products.s3.amazonaws.com/Brand_logos/Josie+Maran/Josie_Maran_2.jpeg",
  "Josie Maran":
    "https://f-products.s3.amazonaws.com/Brand_logos/Josie+Maran/Josie_Maran_3.png",
  "Juicy Couture":
    "https://f-products.s3.amazonaws.com/Brand_logos/Juicy+Couture/Juicy_Couture_1.jpg",
  "Juicy Couture":
    "https://f-products.s3.amazonaws.com/Brand_logos/Juicy+Couture/Juicy_Couture_2.jpg",
  "Juicy Couture":
    "https://f-products.s3.amazonaws.com/Brand_logos/Juicy+Couture/Juicy_Couture_3.png",
  "Juicy Couture":
    "https://f-products.s3.amazonaws.com/Brand_logos/Juicy+Couture/Juicy_Couture_4.jpg",
  "Juicy Couture":
    "https://f-products.s3.amazonaws.com/Brand_logos/Juicy+Couture/Juicy_Couture_5.jpg",
  "Juicy Couture":
    "https://f-products.s3.amazonaws.com/Brand_logos/Juicy+Couture/Juicy_Couture_6.png",
  "Juicy Couture":
    "https://f-products.s3.amazonaws.com/Brand_logos/Juicy+Couture/Juicy_Couture_8.png",
  "Kate Somerville":
    "https://f-products.s3.amazonaws.com/Brand_logos/Kate+Somerville/Kate_Somerville_1.png",
  "Kate Somerville":
    "https://f-products.s3.amazonaws.com/Brand_logos/Kate+Somerville/Kate_Somerville_2.png",
  "Kate Somerville":
    "https://f-products.s3.amazonaws.com/Brand_logos/Kate+Somerville/Kate_Somerville_3.png",
  "Kiehl's Since 1851":
    "https://f-products.s3.amazonaws.com/Brand_logos/Kiehl's+Since+1851/Kiehls_Since_1851_1.png",
  "Kiehl's Since 1851":
    "https://f-products.s3.amazonaws.com/Brand_logos/Kiehl's+Since+1851/Kiehls_Since_1851_2.png",
  "Kiehl's Since 1851":
    "https://f-products.s3.amazonaws.com/Brand_logos/Kiehl's+Since+1851/Kiehls_Since_1851_3.png",
  "Kiehl's Since 1851":
    "https://f-products.s3.amazonaws.com/Brand_logos/Kiehl's+Since+1851/Kiehls_Since_1851_4.png",
  "Kiehl's Since 1851":
    "https://f-products.s3.amazonaws.com/Brand_logos/Kiehl's+Since+1851/Kiehls_Since_1851_5.png",
  "Kiehl's Since 1851":
    "https://f-products.s3.amazonaws.com/Brand_logos/Kiehl's+Since+1851/Kiehls_Since_1851_6.jpeg",
  Kitsch: "https://f-products.s3.amazonaws.com/Brand_logos/Kitsch/Kitsch_1.png",
  Kitsch: "https://f-products.s3.amazonaws.com/Brand_logos/Kitsch/Kitsch_2.png",
  Kitsch:
    "https://f-products.s3.amazonaws.com/Brand_logos/Kitsch/Kitsch_3.jpeg",
  KORRES: "https://f-products.s3.amazonaws.com/Brand_logos/KORRES/KORRES_1.png",
  KORRES: "https://f-products.s3.amazonaws.com/Brand_logos/KORRES/KORRES_2.png",
  KORRES: "https://f-products.s3.amazonaws.com/Brand_logos/KORRES/KORRES_3.png",
  "KVD Beauty":
    "https://f-products.s3.amazonaws.com/Brand_logos/KVD+Beauty/KVD_Beauty_1.jpg",
  "KVD Beauty":
    "https://f-products.s3.amazonaws.com/Brand_logos/KVD+Beauty/KVD_Beauty_2.JPG",
  "KVD Beauty":
    "https://f-products.s3.amazonaws.com/Brand_logos/KVD+Beauty/KVD_Beauty_3.jpg",
  "KVD Beauty":
    "https://f-products.s3.amazonaws.com/Brand_logos/KVD+Beauty/KVD_Beauty_4.jpg",
  "L'Occitane":
    "https://f-products.s3.amazonaws.com/Brand_logos/L'Occitane/LOccitane_1.png",
  "L'Occitane":
    "https://f-products.s3.amazonaws.com/Brand_logos/L'Occitane/LOccitane_2.jpg",
  "L'Occitane":
    "https://f-products.s3.amazonaws.com/Brand_logos/L'Occitane/LOccitane_3.png",
  "L'Occitane":
    "https://f-products.s3.amazonaws.com/Brand_logos/L'Occitane/LOccitane_4.png",
  Lancôme:
    "https://f-products.s3.amazonaws.com/Brand_logos/Lancôme/Lancme_1.png",
  Lancôme:
    "https://f-products.s3.amazonaws.com/Brand_logos/Lancôme/Lancme_2.png",
  Lancôme:
    "https://f-products.s3.amazonaws.com/Brand_logos/Lancôme/Lancme_3.png",
  "Laura Mercier":
    "https://f-products.s3.amazonaws.com/Brand_logos/Laura+Mercier/Laura_Mercier_1.jpeg",
  "Laura Mercier":
    "https://f-products.s3.amazonaws.com/Brand_logos/Laura+Mercier/Laura_Mercier_2.jpg",
  "Laura Mercier":
    "https://f-products.s3.amazonaws.com/Brand_logos/Laura+Mercier/Laura_Mercier_3.jpg",
  "Lilly Lashes":
    "https://f-products.s3.amazonaws.com/Brand_logos/Lilly+Lashes/Lilly_Lashes_1.png",
  "Living Proof":
    "https://f-products.s3.amazonaws.com/Brand_logos/Living+Proof/Living_Proof_1.png",
  "Living Proof":
    "https://f-products.s3.amazonaws.com/Brand_logos/Living+Proof/Living_Proof_2.jpeg",
  "Mario Badescu":
    "https://f-products.s3.amazonaws.com/Brand_logos/Mario+Badescu/Mario_Badescu_1.png",
  "Mario Badescu":
    "https://f-products.s3.amazonaws.com/Brand_logos/Mario+Badescu/Mario_Badescu_2.jpeg",
  "Mario Badescu":
    "https://f-products.s3.amazonaws.com/Brand_logos/Mario+Badescu/Mario_Badescu_3.jpeg",
  "Melanin Haircare":
    "https://f-products.s3.amazonaws.com/Brand_logos/Melanin+Haircare/Melanin_Haircare_1.png",
  Montblanc:
    "https://f-products.s3.amazonaws.com/Brand_logos/Montblanc/Montblanc_1.png",
  Montblanc:
    "https://f-products.s3.amazonaws.com/Brand_logos/Montblanc/Montblanc_2.png",
  Mugler: "https://f-products.s3.amazonaws.com/Brand_logos/Mugler/Mugler_1.jpg",
  Mugler: "https://f-products.s3.amazonaws.com/Brand_logos/Mugler/Mugler_2.png",
  Mugler: "https://f-products.s3.amazonaws.com/Brand_logos/Mugler/Mugler_3.jpg",
  Murad: "https://f-products.s3.amazonaws.com/Brand_logos/Murad/Murad_1.jpg",
  NARS: "https://f-products.s3.amazonaws.com/Brand_logos/NARS/Nars_1.png",
  NARS: "https://f-products.s3.amazonaws.com/Brand_logos/NARS/Nars_2.jpg",
  NARS: "https://f-products.s3.amazonaws.com/Brand_logos/NARS/Nars_3.jpg",
  NUDESTIX:
    "https://f-products.s3.amazonaws.com/Brand_logos/NUDESTIX/NUDESTIX_1.png",
  NuFACE: "https://f-products.s3.amazonaws.com/Brand_logos/NuFACE/NuFACE_1.png",
  NuFACE: "https://f-products.s3.amazonaws.com/Brand_logos/NuFACE/NuFACE_2.jpg",
  NuFACE: "https://f-products.s3.amazonaws.com/Brand_logos/NuFACE/NuFACE_3.png",
  "Nurse Jamie":
    "https://f-products.s3.amazonaws.com/Brand_logos/Nurse+Jamie/Nurse_Jamie_1.jpg",
  "Nurse Jamie":
    "https://f-products.s3.amazonaws.com/Brand_logos/Nurse+Jamie/Nurse_Jamie_2.png",
  Olaplex:
    "https://f-products.s3.amazonaws.com/Brand_logos/Olaplex/Olaplex_1.png",
  Olaplex:
    "https://f-products.s3.amazonaws.com/Brand_logos/Olaplex/Olaplex_2.jpg",
  Origins:
    "https://f-products.s3.amazonaws.com/Brand_logos/Origins/Origins_1.png",
  Origins:
    "https://f-products.s3.amazonaws.com/Brand_logos/Origins/Origins_2.jpg",
  OUAI: "https://f-products.s3.amazonaws.com/Brand_logos/OUAI/OUAI_1.png",
  OUAI: "https://f-products.s3.amazonaws.com/Brand_logos/OUAI/OUAI_2.png",
  "Paco Rabanne":
    "https://f-products.s3.amazonaws.com/Brand_logos/Paco+Rabanne/Paco_Rabanne_1.png",
  "Paco Rabanne":
    "https://f-products.s3.amazonaws.com/Brand_logos/Paco+Rabanne/Paco_Rabanne_2.png",
  "Peter Thomas Roth":
    "https://f-products.s3.amazonaws.com/Brand_logos/Peter+Thomas+Roth/Peter_Thomas_Roth_1.png",
  "Peter Thomas Roth":
    "https://f-products.s3.amazonaws.com/Brand_logos/Peter+Thomas+Roth/Peter_Thomas_Roth_2.png",
  philosophy:
    "https://f-products.s3.amazonaws.com/Brand_logos/philosophy/philosophy_1.png",
  PMD: "https://f-products.s3.amazonaws.com/Brand_logos/PMD/PMD_1.jpg",
  PMD: "https://f-products.s3.amazonaws.com/Brand_logos/PMD/PMD_2.png",
  Prada: "https://f-products.s3.amazonaws.com/Brand_logos/Prada/Prada_1.png",
  Prada: "https://f-products.s3.amazonaws.com/Brand_logos/Prada/Prada_2.png",
  Pureology:
    "https://f-products.s3.amazonaws.com/Brand_logos/Pureology/Pureology_1.png",
  Pureology:
    "https://f-products.s3.amazonaws.com/Brand_logos/Pureology/Pureology_2.png",
  "Qhemet Biologics":
    "https://f-products.s3.amazonaws.com/Brand_logos/Qhemet+Biologics/Qhemet_Biologics_1.png",
  "Qhemet Biologics":
    "https://f-products.s3.amazonaws.com/Brand_logos/Qhemet+Biologics/Qhemet_Biologics_2.jpg",
  "Ralph Lauren":
    "https://f-products.s3.amazonaws.com/Brand_logos/Ralph+Lauren/Ralph_Lauren_1.png",
  "Ralph Lauren":
    "https://f-products.s3.amazonaws.com/Brand_logos/Ralph+Lauren/Ralph_Lauren_2.png",
  "Rosebud Perfume Co.":
    "https://f-products.s3.amazonaws.com/Brand_logos/Rosebud+Perfume+Co/Rosebud_Perfume_Co._1.png",
  "Rosebud Perfume Co.":
    "https://f-products.s3.amazonaws.com/Brand_logos/Rosebud+Perfume+Co/Rosebud_Perfume_Co._2.jpg",
  Shiseido:
    "https://f-products.s3.amazonaws.com/Brand_logos/Shiseido/Shiseido_1.png",
  Shiseido:
    "https://f-products.s3.amazonaws.com/Brand_logos/Shiseido/Shiseido_2.png",
  Slip: "https://f-products.s3.amazonaws.com/Brand_logos/Slip/Slip_1.png",
  Smashbox:
    "https://f-products.s3.amazonaws.com/Brand_logos/Smashbox/Smashbox_1.png",
  "St. Tropez":
    "https://f-products.s3.amazonaws.com/Brand_logos/St.+Tropez/St._Tropez_1.png",
  "St. Tropez":
    "https://f-products.s3.amazonaws.com/Brand_logos/St.+Tropez/St._Tropez_2.png",
  stila: "https://f-products.s3.amazonaws.com/Brand_logos/stila/stila_1.png",
  stila: "https://f-products.s3.amazonaws.com/Brand_logos/stila/stila_2.png",
  stila: "https://f-products.s3.amazonaws.com/Brand_logos/stila/stila_3.jpg",
  stila: "https://f-products.s3.amazonaws.com/Brand_logos/stila/stila_4.jpg",
  StriVectin:
    "https://f-products.s3.amazonaws.com/Brand_logos/StriVectin/StriVectin_1.jpg",
  StriVectin:
    "https://f-products.s3.amazonaws.com/Brand_logos/StriVectin/StriVectin_2.png",
  "Sunday Riley":
    "https://f-products.s3.amazonaws.com/Brand_logos/Sunday+Riley/Sunday_Riley_1.png",
  "Supergoop!":
    "https://f-products.s3.amazonaws.com/Brand_logos/Supergoop!/Supergoop_1.png",
  "Supergoop!":
    "https://f-products.s3.amazonaws.com/Brand_logos/Supergoop!/Supergoop_2.png",
  T3: "https://f-products.s3.amazonaws.com/Brand_logos/T3/T3_1.png",
  T3: "https://f-products.s3.amazonaws.com/Brand_logos/T3/T3_2.png",
  "TAN-LUXE":
    "https://f-products.s3.amazonaws.com/Brand_logos/TAN-LUXE/TANLUXE_1.png",
  tarte: "https://f-products.s3.amazonaws.com/Brand_logos/tarte/tarte_1.jpg",
  tarte: "https://f-products.s3.amazonaws.com/Brand_logos/tarte/tarte_2.png",
  tarte: "https://f-products.s3.amazonaws.com/Brand_logos/tarte/tarte_4.jpeg",
  "The Ordinary":
    "https://f-products.s3.amazonaws.com/Brand_logos/The+Ordinary/The_Ordinary_1.png",
  "The Ordinary":
    "https://f-products.s3.amazonaws.com/Brand_logos/The+Ordinary/The_Ordinary_2.jpg",
  "The Original MakeUp Eraser":
    "https://f-products.s3.amazonaws.com/Brand_logos/The+Original+MakeUp+Eraser/The_Original_MakeUp_Eraser_1.png",
  "The Original MakeUp Eraser":
    "https://f-products.s3.amazonaws.com/Brand_logos/The+Original+MakeUp+Eraser/The_Original_MakeUp_Eraser_2.png",
  "TOM FORD":
    "https://f-products.s3.amazonaws.com/Brand_logos/TOM+FORD/TOM_FORD_1.png",
  "Too Faced":
    "https://f-products.s3.amazonaws.com/Brand_logos/Too+Faced/Too_Faced_1.png",
  "Too Faced":
    "https://f-products.s3.amazonaws.com/Brand_logos/Too+Faced/Too_Faced_2.png",
  Touchland:
    "https://f-products.s3.amazonaws.com/Brand_logos/Touchland/Touchland_1.png",
  Touchland:
    "https://f-products.s3.amazonaws.com/Brand_logos/Touchland/Touchland_2.jpg",
  TWEEZERMAN:
    "https://f-products.s3.amazonaws.com/Brand_logos/TWEEZERMAN/TWEEZERMAN_1.png",
  TWEEZERMAN:
    "https://f-products.s3.amazonaws.com/Brand_logos/TWEEZERMAN/TWEEZERMAN_2.jpg",
  Valentino:
    "https://f-products.s3.amazonaws.com/Brand_logos/Valentino/Valentino_1.png",
  Valentino:
    "https://f-products.s3.amazonaws.com/Brand_logos/Valentino/Valentino_2.png",
  Valentino:
    "https://f-products.s3.amazonaws.com/Brand_logos/Valentino/Valentino_3.png",
  Valentino:
    "https://f-products.s3.amazonaws.com/Brand_logos/Valentino/Valentino_4.png",
  "Velour Lashes":
    "https://f-products.s3.amazonaws.com/Brand_logos/Velour+Lashes/Velour_Lashes_1.png",
  Verb: "https://f-products.s3.amazonaws.com/Brand_logos/Verb/Verb_1.png",
  Versace:
    "https://f-products.s3.amazonaws.com/Brand_logos/Versace/Versace_1.png",
  Versace:
    "https://f-products.s3.amazonaws.com/Brand_logos/Versace/Versace_2.jpg",
  "Viktor&Rolf":
    "https://f-products.s3.amazonaws.com/Brand_logos/Viktor&Rolf/ViktorRolf_1.png",
  "Viktor&Rolf":
    "https://f-products.s3.amazonaws.com/Brand_logos/Viktor&Rolf/ViktorRolf_2.png",
  Virtue: "https://f-products.s3.amazonaws.com/Brand_logos/Virtue/Virtue_1.jpg",
  Virtue: "https://f-products.s3.amazonaws.com/Brand_logos/Virtue/Virtue_2.png",
  "Yves Saint Laurent":
    "https://f-products.s3.amazonaws.com/Brand_logos/Yves+Saint+Laurent/Yves_Saint_Laurent_1.jpeg",
  "Yves Saint Laurent":
    "https://f-products.s3.amazonaws.com/Brand_logos/Yves+Saint+Laurent/Yves_Saint_Laurent_2.png",
  "The INKEY List":
    "https://f-products.s3.amazonaws.com/Brand_logos/The+Inkey+List/The+Inkey+List.jpeg",
  Tatcha: "https://f-products.s3.amazonaws.com/Brand_logos/TATCHA/TATCHA.jpeg",
  "Armani Beauty":
    "https://f-products.s3.amazonaws.com/Brand_logos/Armani+Beauty/Armani+Beauty.jpeg",
  "Charlotte Tilbury":
    "https://f-products.s3.amazonaws.com/Brand_logos/Sharlotte+Tilbury/Charlotte+Tilbury.jpeg",
  "HUDA BEAUTY":
    "https://f-products.s3.amazonaws.com/Brand_logos/Huda+Beauty/Huda+Beauty.jpeg",
  "MAKE UP FOR EVER":
    "https://f-products.s3.amazonaws.com/Brand_logos/Make+Up+For+Ever/Make+Up+For+Ever.jpeg",
  "MAKEUP BY MARIO":
    "https://f-products.s3.amazonaws.com/Brand_logos/Makeup+by+Mario/Makeup+by+Mario.jpeg",
  "Rare Beauty by Selena Gomez":
    "https://f-products.s3.amazonaws.com/Brand_logos/Rare+Beauty/Rare+Beauty.jpeg",
  Kérastase:
    "https://f-products.s3.amazonaws.com/Brand_logos/K%C3%A9rastase/K%C3%A9rastase.jpeg",
};
