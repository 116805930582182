import axios from "axios";
import useSWRInfinite from "swr/infinite";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
import { getHeaders } from "../util/utilFunctions";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const allGroupsFetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });

const activeGroupsOfAProductFetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return JSON.parse(res.data.groups);
    });

const getAllGroupsKey = (pageIndex, previousPageData) => {
  return `${apiUrl}/api/groups/group/?page=${pageIndex + 1}`;
};

export default function useGetGroups(type, productId) {
  const getActiveGroupsOfAProductKey = (pageIndex, previousPageData) => {
    return `${apiUrl}/api/groups/get_active_groups_product/?product_id=${productId}`;
  };
  const { data, size, setSize, isValidating, mutate, error } = useSWRInfinite(
    type === "activeGroupsForAProduct"
      ? getActiveGroupsOfAProductKey
      : getAllGroupsKey,
    type === "activeGroupsForAProduct"
      ? activeGroupsOfAProductFetcher
      : allGroupsFetcher
  );
  useCheckNetwork(error);
  useLoader(isValidating);
  return {
    error,
    data: data?.flatMap((data) => data),
    mutate,
    isValidating,
    setSize: (val) => {
      setSize(val);
    },
    size,
  };
}
