import { Center, Input, Text, VStack, useColorModeValue } from "native-base";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { SignupContext } from "../../hoc/SignupHOC";
import PrimaryCTAButton from "../buttons/primaryCTAButton";
import Error from "../error";
import GreenTickIcon from "../icons/greenTickIcon";
import CTAButtonText from "../texts/ctaButtonText";
//import { useGetKeyboardStatus } from "../../hooks/useGetKeyboardStatus";
const EmailSignupForm = () => {
  //   const keyboardStatus = useGetKeyboardStatus();
  const {
    username,
    email,
    sendEmailRegistrationOtp,
    handleInput,
    spaceError,
    label,
    emailRegistrationOtpError,
  } = useContext(SignupContext);

  const form = useSelector((state) => state.form);
  const cursorColor = useColorModeValue("black", "white");
  return (
    <VStack
      // pb={
      //   keyboardStatus === "Keyboard Hidden" || keyboardStatus === undefined
      //     ? 0
      //     : 96
      // }
      alignItems={"center"}
      px={{
        md: "10px",
        lg: "28px",
        xl: "28px",
      }}
      pt={12}
      space={"149px"}
    >
      <VStack w="full" space={"30px"}>
        {[
          {
            label: "What's your email address?",
            placeholder: "name@email.com",
            field: "email",
            error: (email?.status === 400 ||
              (spaceError && label === "email")) && (
              <Error
                error={(email?.status === 400 && email?.message) || spaceError}
              />
            ),
            verified:
              form?.email && !/\s/g.test(form?.email) && email?.status === 200,
          },
          {
            label: "Username",
            placeholder: "Alex page",
            field: "username",
            error: (username?.status === 400 ||
              (spaceError && label === "username")) && (
              <Error
                error={
                  (username?.status === 400 && username?.message) || spaceError
                }
              />
            ),
            verified:
              form?.username &&
              !/\s/g.test(form?.username) &&
              username?.status === 200,
          },
          {
            label: "Referral Code(Optional)",
            placeholder: "",
            field: "refferal_code",
            error: spaceError && label === "refferal_code" && (
              <Error error={spaceError} />
            ),
          },
        ].map((k) => (
          <VStack space={"10px"} w="full">
            <Text
              color={"#94A3B8"}
              fontSize={"16px"}
              letterSpacing={"sm"}
              style={{
                fontFamily: "HK_500Medium",
              }}
            >
              {k?.label}
            </Text>
            <Input
              rightElement={
                k?.verified && (
                  <Center px={3}>
                    <GreenTickIcon />
                  </Center>
                )
              }
              _input={{
                selectionColor: cursorColor,
              }}
              onChangeText={(val) => handleInput(k?.field)(val)}
              fontSize={"16px"}
              lineHeight={"21px"}
              autoCapitalize="none"
              style={{
                fontFamily: "HK_700Bold",
              }}
              borderWidth={"1px"}
              borderColor={"#58595B"}
              p={"16px"}
              rounded={"8px"}
              placeholder={k?.placeholder}
              placeholderTextColor={"#58595B"}
              color="white"
              _focus={{
                borderColor: "#58595B",
                backgroundColor: "black",
                color: "white",
              }}
            ></Input>

            {k?.error}
          </VStack>
        ))}
        {emailRegistrationOtpError &&
          !(
            username?.status === 400 &&
            username?.message &&
            spaceError &&
            email?.status === 400 &&
            email?.message
          ) && <Error error={emailRegistrationOtpError} />}
      </VStack>

      <Center h="50px" w="173.35px">
        <PrimaryCTAButton
          onPress={() => {
            if (username.status === 200 && email.status === 200) {
              sendEmailRegistrationOtp();
            }
          }}
          gradientType="2"
        >
          <CTAButtonText text="Next" />
        </PrimaryCTAButton>
      </Center>
    </VStack>
  );
};

export default EmailSignupForm;
