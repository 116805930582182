import {
  HStack,
  VStack,
  Text,
  Center,
  Stack,
  Divider,
  Hidden,
  IconButton,
} from "native-base";
import { RNS3 } from "react-native-aws3";
import { useState } from "react";
import * as ImagePicker from "expo-image-picker";
import { useSWRConfig } from "swr";
import { manipulateAsync } from "expo-image-manipulator";
import { privateApi } from "../../../util/API";
import { getUser } from "../../../util/utilFunctions";
import { setAnalytics } from "../../../analytics";

import Constants from "expo-constants";
import UserDetailCard from "../../../components/cards/userDetailCard";
import RewardsFollowersFollowing from "./rewardsFollowersFollowing";
import InviteFriendsCard from "../../../components/cards/inviteFriendsCard";
import EditIconOutlined from "../../../components/icons/editIconOutlined";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Pages } from "../../../constants/pages";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const UserDetails = ({ userData, points }) => {
  const { mutate } = useSWRConfig();
  const [image, setImage] = useState(null);
  const uploadImageToServer = async (im) => {
    const userId = await getUser();
    let img = await fetch(im.uri).then((r) => r.blob());
    let fetchResponse = await fetch(im.uri);
    let buffResponse = await fetchResponse.arrayBuffer();
    let file = new File([buffResponse], "profilepic.png", {
      type: "image/png",
    });

    const options = {
      keyPrefix: "users/" + userId + "/",
      bucket: "flock",
      region: "us-east-1",
      accessKey: "AKIASTL3JHOXKBXW4A3O",
      secretKey: "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",
      successActionStatus: 201,
    };

    const response = await RNS3.put(file, options);

    let res = await privateApi(
      "/api/users/user/",
      {
        id: userId,
        image:
          "https://flock.s3.amazonaws.com/users/" + userId + "/profilepic.png",
      },
      "put"
    );

    mutate(`${apiUrl}/api/users/get_user_details/?user_id=${userId}`);
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      base64: false,
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      const manipResult = await manipulateAsync(
        result.assets[0].uri,
        [
          {
            resize: {
              width: 300,
            },
          },
        ],
        { compress: 1 }
      );

      setImage(result.assets[0].uri);

      uploadImageToServer(manipResult);
    }
    setAnalytics("Change profile picture", "Edited Profile Picture", {
      userId: userData?.id,
    });
  };

  const navigation = useNavigation();
  const route = useRoute();
  console.log("user details", userData);
  return (
    <>
      <VStack space={"80px"}>
        <Stack
          space={{
            base: 4,
            sm: 4,
            md: 4,
            lg: 4,
          }}
          justifyContent={"space-evenly"}
          alignItems={{
            xl: "center",
          }}
          pt={"80px"}
          px={{
            base: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: "120px",
          }}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
        >
          <VStack>
            <HStack alignItems={"center"}>
              <Text
                color={"#682481"}
                fontSize={"24px"}
                style={{
                  fontFamily: "AustinCyr_400",
                }}
              >
                Profile
              </Text>
              {route?.params?.type === "profile" && (
                <IconButton
                  onPress={() => {
                    setAnalytics(
                      "Profile Edit Icon",
                      `Profile Edit Icon Clicked`,
                      {
                        screen: route?.name,
                      }
                    );
                    navigation.navigate(Pages.AboutYourSelfScreen);
                  }}
                  icon={<EditIconOutlined color="#682481" />}
                />
              )}
            </HStack>
            <VStack space={"60px"}>
              <UserDetailCard
                image={image}
                pickImage={pickImage}
                userData={userData}
              />
              <Hidden only="xl">
                <Divider bg="#E9B9FF" />
              </Hidden>
            </VStack>
          </VStack>
          <Hidden from="base" till={"xl"}>
            <Divider bg="#E9B9FF" orientation="vertical" h="250px" />
          </Hidden>

          <VStack space={4}>
            <HStack
              w={{
                xl: "500px",
              }}
              alignItems={"center"}
              justifyContent="center"
              space={{
                xl: "24px",
              }}
            >
              <RewardsFollowersFollowing
                userId={userData?.id}
                points={points}
                followers={userData?.followers_count}
                following={userData?.following_count}
              />
            </HStack>
            {route?.params?.type === "profile" && <InviteFriendsCard />}
          </VStack>
        </Stack>
        <Center>
          <Divider bg="#E9B9FF" w="80%" />
        </Center>
      </VStack>
    </>
  );
};

export default UserDetails;
