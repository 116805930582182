import { useNavigation } from "@react-navigation/native";
import { HStack, Image, Text, Pressable } from "native-base";
import { setAnalytics } from "../../analytics";

import { Pages } from "../../constants/pages";
import useGetUser from "../../hooks/useGetUser";

const UserNotificationCard = ({ notification }) => {
  const { data } = useGetUser("", notification?.item_id);

  const navigation = useNavigation();

  return (
    <>
      <Pressable
        onPress={async () => {
          setAnalytics("In app notification", "In app notification clicked", {
            id: notification?.item_id,
            userId: data?.user[0].id,
            username: data?.user[0].username,
            seen: notification?.seen,
            type: notification?.type,
          });
          navigation.navigate(Pages.UserScreen, {
            id: data?.user[0].id,
            username: data?.user[0].username,
          });
        }}
      >
        <HStack
          space={3}
          px={2}
          py={4}
          bg={notification?.seen ? "black" : "#202427"}
        >
          <Image
            alt=""
            size={"32px"}
            rounded="full"
            resizeMethod="scale"
            resizeMode="cover"
            fallbackSource={require("./../../assets/images/pngs/profile-pic.png")}
            source={{
              uri: data?.user[0]?.image,
            }}
          ></Image>

          <Text
            flex={1}
            color={"white"}
            opacity={notification?.seen ? 0.6 : 1}
            fontSize={"14px"}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
          >
            {notification?.message}
          </Text>
        </HStack>
      </Pressable>
    </>
  );
};

export default UserNotificationCard;
