import {
  Center,
  FlatList,
  HStack,
  Image,
  Input,
  Pressable,
  Stack,
  Text,
  VStack,
} from "native-base";
import { useContext, useEffect, useState } from "react";
import FullWidthAccordion from "../../../components/accordions/fullWidthAccordion";
import PrimaryCTAButton from "../../../components/buttons/primaryCTAButton";
import ProductCard from "../../../components/cards/product/productCard";
import WebSearchIcon from "../../../components/icons/webIcons/webSearchIcon";
import ColumnLayout from "../../../components/layouts/columnLayout";
import { BrandImages } from "../../../constants/brands";
import { gradientTextWeb } from "../../../constants/gradient";
import SearchHOC, { SearchContext } from "../../../hoc/SearchHOC";
import { useCreateCollection } from "../../../hooks/useCreateCollection";
import useDeleteCollection from "../../../hooks/useDeleteCollection";
import { useGetProductDetails } from "../../../hooks/useGetProductDetails";
import useGetProducts from "../../../hooks/useGetProducts";

const CollectionModal = ({ navigation, route }) => {
  const [collectionName, setCollectionName] = useState();
  const [query, setQuery] = useState();
  const { createCollection } = useCreateCollection();

  const { data } = useGetProducts(
    "specificCollectionProducts",
    {},
    undefined,
    route?.params?.collectionId
  );
  let addedData = data?.[0]?.product_skus?.map((k) => ({
    product: k?.product,
    sku: k?.id,
  }));

  const [selectedSkus, setSelectedSkus] = useState([]);
  useEffect(() => {
    if (route?.name === "editcollection") {
      setSelectedSkus(addedData);
    }
  }, [data?.[0]?.product_skus]);

  const deleteCollection = useDeleteCollection();
  const renderItem = ({ item }) => {
    return (
      <CollectionProduct
        item={item}
        selectedSkus={selectedSkus}
        setSelectedSkus={(val) => setSelectedSkus(val)}
      />
    );
  };
  const renderThumbnailItem = ({ item }) => {
    return <CollectionProductThumbNails item={item} />;
  };
  return (
    <>
      <Pressable
        size={"full"}
        position="relative"
        bg="black:alpha.40"
        onPress={() => navigation.goBack()}
      ></Pressable>

      <VStack
        position="absolute"
        left={0}
        top={0}
        bottom={0}
        right={0}
        margin={"auto"}
        alignItems={"center"}
        rounded={"20px"}
        w={{
          base: "full",
          sm: "full",
          md: "full",
          lg: "full",
          xl: "60%",
          xxl: "60%",
        }}
        _dark={{
          bg: "#2B2F33",
        }}
        _light={{
          bg: "white",
        }}
        p={{
          base: 4,
          sm: 4,
          md: 4,
          lg: 4,
          xl: "30px",
          xxl: "30px",
        }}
        h={{
          base: "full",
          sm: "full",
          md: "full",
          lg: "full",
          xl: "90%",
          xxl: "90%",
        }}
        space={6}
        justifyContent={"space-between"}
      >
        <Text
          textAlign={"center"}
          letterSpacing={2}
          _web={{
            style: {
              ...gradientTextWeb(1),
              fontFamily: "AustinCyr_400",
            },
          }}
          fontSize="36px"
        >
          {route?.name === "editcollection" && (
            <Text
              textAlign={"center"}
              letterSpacing={2}
              _web={{
                style: {
                  color: "#464646",
                  fontFamily: "AustinCyr_400",
                },
              }}
              fontSize="36px"
            >
              Edit
            </Text>
          )}

          {route?.name === "editcollection"
            ? ` ${data && data[0]?.name}`
            : "Add a collection"}
        </Text>

        <VStack alignItems={"center"} space={6} w="full">
          <Stack
            direction={{
              base: "column",
              sm: "column",
              md: "column",
              lg: "column",
              xl: "row",
              xxl: "row",
            }}
            justifyContent={"center"}
            space={6}
            alignItems="center"
            w="full"
          >
            {[
              { label: "Collection Name*", value: data && data[0]?.name },
              { label: "Search for products", placeholder: "Search" },
            ]?.map((k, i) => (
              <VStack
                space={"6px"}
                w={{
                  base: "full",
                  sm: "full",
                  md: "full",
                  lg: "full",
                  xl: "303px",
                  xxl: "303px",
                }}
              >
                <Text
                  letterSpacing={3}
                  textTransform="uppercase"
                  color={"#682481"}
                  fontSize="11px"
                  style={{
                    fontFamily: "Hero_700Bold",
                  }}
                >
                  {k?.label}
                </Text>
                {i === 0 ? (
                  <Input
                    px="22px"
                    py="13px"
                    w={{
                      base: "full",
                      sm: "full",
                      md: "full",
                      lg: "full",
                      xl: "303px",
                      xxl: "303px",
                    }}
                    h="39px"
                    color="#464646"
                    variant="outline"
                    focusOutlineColor="#682481"
                    _focus={{
                      bg: "none",
                      color: "#464646",
                    }}
                    _hover={{
                      bg: "none",
                      color: "#464646",
                      borderColor: "#682481",
                    }}
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                    borderRadius="7px"
                    borderColor="#C1C9D2"
                    fontSize="14px"
                    placeholderTextColor={"#C1C9D2"}
                    placeholder={k?.placeholder}
                    borderWidth={"1px"}
                    value={collectionName ? collectionName : k?.value}
                    onChangeText={(val) => {
                      setCollectionName(val);
                    }}
                  ></Input>
                ) : (
                  <SearchHOC searchType="allSearch" query={query}>
                    <Input
                      px="22px"
                      py="13px"
                      w={{
                        base: "full",
                        sm: "full",
                        md: "full",
                        lg: "full",
                        xl: "303px",
                        xxl: "303px",
                      }}
                      h="39px"
                      color="#464646"
                      variant="outline"
                      focusOutlineColor="#682481"
                      _focus={{
                        bg: "none",
                        color: "#464646",
                      }}
                      _hover={{
                        bg: "none",
                        color: "#464646",
                        borderColor: "#682481",
                      }}
                      style={{
                        fontFamily: "Hero_700Bold",
                      }}
                      borderRadius="7px"
                      borderColor="#C1C9D2"
                      fontSize="14px"
                      placeholderTextColor={"#C1C9D2"}
                      placeholder={k?.placeholder}
                      borderWidth={"1px"}
                      value={k?.value}
                      onChangeText={(val) => setQuery(val)}
                      InputLeftElement={
                        i === 1 && (
                          <Center pl={2}>
                            <WebSearchIcon color="#C1C9D2" />
                          </Center>
                        )
                      }
                    ></Input>
                    {query && (
                      <SearchResults
                        selectedSkus={selectedSkus}
                        setSelectedSkus={(val) => setSelectedSkus(val)}
                      />
                    )}
                  </SearchHOC>
                )}
              </VStack>
            ))}
          </Stack>
          <VStack alignItems={"center"}>
            <Text
              textTransform={"uppercase"}
              _light={{
                color: "#682481",
              }}
              _dark={{
                color: "white",
              }}
              letterSpacing={2}
              fontSize={"12px"}
              style={{
                fontFamily: "Hero_700Bold",
              }}
            >
              In the Collection {`(${selectedSkus?.length})`}
            </Text>
            {route?.name === "editcollection" && (
              <Pressable
                onPress={() => {
                  deleteCollection(route?.params?.collectionId);
                }}
              >
                <Text
                  textTransform={"uppercase"}
                  _light={{
                    color: "red.500",
                  }}
                  _dark={{
                    color: "red.500",
                  }}
                  letterSpacing={2}
                  fontSize={"12px"}
                  style={{
                    fontFamily: "Hero_700Bold",
                  }}
                >
                  Delete
                </Text>
              </Pressable>
            )}
          </VStack>
          <Center
            h={{
              base: "200px",
              sm: "200px",
              md: "200px",
              lg: "200px",
              xl: "200px",
              xxl: "300px",
            }}
          >
            <ColumnLayout
              defaultNumberOfColumns={3}
              data={selectedSkus}
              renderItem={renderItem}
            />
          </Center>
          <Stack
            zIndex={"5"}
            p={4}
            direction={{
              base: "column",
              sm: "column",
              md: "column",
              lg: "column",
              xl: "row",
              xxl: "row",
            }}
            borderRadius={"20px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            w="full"
            space={4}
            bg="#FAF1FF"
          >
            <HStack space={2} alignItems="center">
              <FlatList
                horizontal
                _contentContainerStyle={{
                  flexDir: "row",
                  gap: "2",
                }}
                scrollEnabled={false}
                showsHorizontalScrollIndicator={false}
                initialNumToRender={2}
                keyExtractor={(item) => item?.id}
                data={selectedSkus?.filter((k, i) => i < 5)}
                renderItem={renderThumbnailItem}
              />
              {selectedSkus?.length > 5 && (
                <Center rounded={"full"} size={"36px"} bg="#E9B9FF">
                  <Text
                    textTransform={"uppercase"}
                    _light={{
                      color: "#682481",
                    }}
                    _dark={{
                      color: "white",
                    }}
                    fontSize={"14px"}
                    style={{
                      fontFamily: "HK_500Medium",
                    }}
                  >
                    +{selectedSkus?.length - 5}
                  </Text>
                </Center>
              )}
            </HStack>
            <Center h="50px" w="250">
              <PrimaryCTAButton
                gradientType="1"
                onPress={() => {
                  if (route?.name === "editcollection") {
                    createCollection(
                      collectionName ? collectionName : data && data[0]?.name,
                      selectedSkus?.map((k) => k.sku),
                      route?.params?.collectionId,
                      "put"
                    );
                  } else if (route?.name === "addcollection") {
                    createCollection(
                      collectionName,
                      selectedSkus?.map((k) => k.sku),
                      "",
                      "post"
                    );
                  }
                }}
              >
                <Text
                  textTransform={"uppercase"}
                  _light={{
                    color: "white",
                  }}
                  _dark={{
                    color: "white",
                  }}
                  _web={{
                    style: {
                      fontFamily: "Hero_700Bold",
                    },
                  }}
                  letterSpacing={2}
                  fontSize={"16px"}
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                >
                  Confirm
                </Text>
              </PrimaryCTAButton>
            </Center>
          </Stack>
        </VStack>
      </VStack>
    </>
  );
};

const CollectionProduct = ({ item, selectedSkus, setSelectedSkus }) => {
  const { product } = useGetProductDetails(item?.product?.id);

  const isAdded = selectedSkus.find((f) => f.sku === item?.sku);
  return (
    <>
      <Center m={1.5}>
        <ProductCard
          isAdded={isAdded}
          onPress={() => {
            let copy = [...selectedSkus];

            if (copy.find((f) => f.sku === item?.sku)) {
              copy = [...copy.filter((f) => f.sku !== item?.sku)];
            } else {
              copy = [
                ...copy,
                {
                  product: item,
                  sku: item?.sku ? item?.sku : item?.product?.default_sku,
                },
              ];
            }

            setSelectedSkus(copy);
          }}
          variantLength={
            [product?.sizes, product?.colors, product?.scents, product?.types]
              ?.filter((k) => k)
              .flatMap((f) => f).length
          }
          selectedSkus={selectedSkus}
          setSelectedSkus={(val) => setSelectedSkus(val)}
          productImage={
            product?.skus[item?.sku ? item?.sku : item?.product?.default_sku]
              ?.images[0]
          }
          retailPrice={
            product?.skus[item?.sku ? item?.sku : item?.product?.default_sku]
              ?.retail_price
          }
          productName={item?.product?.name}
          brandName={item?.product?.brand_name}
          productId={item?.product?.id}
          productSkuId={item?.sku ? item?.sku : item?.product?.default_sku}
        />
      </Center>
    </>
  );
};

const CollectionProductThumbNails = ({ item }) => {
  const { product } = useGetProductDetails(item?.product?.id);

  return (
    <Image
      borderRadius={"4px"}
      borderWidth="1px"
      borderColor={"#FAF1FF"}
      size={"40px"}
      resizeMethod="scale"
      bg="white"
      resizeMode="contain"
      source={{
        uri: product?.skus[item?.sku ? item?.sku : item?.product?.default_sku]
          ?.images[0],
      }}
      alt=""
    />
  );
};

//search results related

const Products = ({ selectedSkus, setSelectedSkus, item }) => {
  const { product } = useGetProductDetails(item?.id);
  const [selectedSku, setSelectedSku] = useState(item?.default_sku);
  const variantsMap = {
    Color: product?.colors,
    Size: product?.sizes,
    Fragrance: product?.scents,
    Type: product?.types,
  };
  const defaultValuesMap = {
    Color: product?.skus[selectedSku ? selectedSku : item?.default_sku]?.color,
    Size: product?.skus[selectedSku ? selectedSku : item?.default_sku]?.size,
    Fragrance:
      product?.skus[selectedSku ? selectedSku : item?.default_sku]?.scent,
    Type: product?.skus[selectedSku ? selectedSku : item?.default_sku]?.type,
  };

  const isAdded = selectedSkus.find((f) => f.sku === selectedSku);

  return (
    <>
      <Center m={1.5}>
        <Pressable
          onPress={() => {
            let copy = [...selectedSkus];

            if (copy.find((f) => f.sku === selectedSku)) {
              copy = [...copy.filter((f) => f.sku !== selectedSku)];
            } else {
              copy = [
                ...copy,
                {
                  product: item,
                  sku: selectedSku ? selectedSku : item?.default_sku,
                },
              ];
            }

            setSelectedSkus(copy);
          }}
        >
          <HStack
            borderColor={isAdded ? "green.500" : "#C1C9D2"}
            borderWidth="1px"
            alignItems={"center"}
            w="326px"
            rounded="10px"
            h="150px"
            space={4}
            _light={{
              bg: "white",
            }}
            _dark={{
              bg: "#202427",
            }}
            px={4}
            py={5}
          >
            <Image
              shadow={"7"}
              resizeMethod="scale"
              resizeMode="stretch"
              w={"63px"}
              h="60px"
              rounded="6px"
              source={{
                uri: product?.skus[
                  selectedSku ? selectedSku : item?.default_sku
                ]?.images[0],
              }}
              alt=""
            />

            <VStack flex={1} space={3} justifyContent={"center"}>
              <VStack space={2} w="80%">
                <Text
                  noOfLines={2}
                  _light={{
                    color: "#464646",
                  }}
                  _dark={{
                    color: "white",
                  }}
                  _web={{
                    style: {
                      fontFamily: "Hero_400Regular",
                    },
                  }}
                  fontSize={"14px"}
                  style={{
                    fontFamily: "HK_400Regular",
                  }}
                  lineHeight={18}
                >
                  {item?.name}
                </Text>
                <HStack space={2}>
                  <Image
                    size={4}
                    rounded={"full"}
                    resizeMethod="scale"
                    resizeMode="contain"
                    source={{
                      uri: BrandImages[item?.brand_name],
                    }}
                    alt=""
                  />

                  <Text
                    noOfLines={1}
                    textTransform={"uppercase"}
                    _light={{
                      color: "#464646",
                    }}
                    _dark={{
                      color: "#94A3B8",
                    }}
                    _web={{
                      style: {
                        fontFamily: "Hero_700Bold",
                      },
                    }}
                    letterSpacing={2}
                    fontSize={"10px"}
                    lineHeight={16}
                    style={{
                      fontFamily: "HK_600SemiBold",
                    }}
                  >
                    {item?.brand_name}
                  </Text>
                </HStack>
              </VStack>
              <HStack space={"4px"} flexWrap={"wrap"} w="full">
                {Object.keys(variantsMap)
                  ?.filter((f) => variantsMap[f] && variantsMap[f]?.length > 0)
                  .map((k) => (
                    <FullWidthAccordion
                      id={item?.id}
                      type="collectioncard"
                      setSelectedSku={(val) => setSelectedSku(val)}
                      label={k}
                      items={variantsMap[k]}
                      val={defaultValuesMap[k]}
                    />
                  ))}
              </HStack>
            </VStack>

            <Text
              _web={{
                style: {
                  fontFamily: "Hero_700Bold",
                },
                fontSize: "16px",
              }}
              _light={{
                color: "#682481",
              }}
              _dark={{
                color: "white",
              }}
              style={{
                fontFamily: "HK_700Bold",
              }}
              letterSpacing={1}
              fontSize={"16px"}
            >
              $
              {product?.skus[
                selectedSku ? selectedSku : item?.default_sku
              ]?.retail_price?.toFixed(2)}
            </Text>
          </HStack>
        </Pressable>
      </Center>
    </>
  );
};

const SearchResults = ({ setSelectedSkus, selectedSkus }) => {
  const { searchResult } = useContext(SearchContext);
  return (
    <>
      {searchResult &&
        searchResult?.products &&
        searchResult?.products?.length > 0 && (
          <FlatList
            bg="white"
            rounded={"10px"}
            mt={"45px"}
            left={"100%"}
            position={"absolute"}
            w="360px"
            h={"300px"}
            initialNumToRender={2}
            ListEmptyComponent={
              <Text
                color="#464646"
                fontSize="16px"
                style={{
                  fontFamily: "HK_700Bold",
                }}
              >
                {searchResult?.products?.length === 0
                  ? "No search result"
                  : "loading..."}
              </Text>
            }
            data={searchResult?.products}
            renderItem={({ item }) => {
              return (
                <Products
                  item={item}
                  selectedSkus={selectedSkus}
                  setSelectedSkus={(val) => setSelectedSkus(val)}
                />
              );
            }}
          />
        )}
    </>
  );
};

export default CollectionModal;
