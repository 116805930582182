import { Center, HStack } from "native-base";
import { Dimensions } from "react-native";
import { useDispatch } from "react-redux";
import { goBackDialog } from "../../redux/currentState/actions";
import { resetProductsFiltersPopup } from "../../redux/productsFilter/actions";
import { setAnalytics } from "../../analytics";
import * as RootNavigation from "./../../RootNavigation";
import PrimaryCTAButton from "../buttons/primaryCTAButton";
import CTAButtonText from "../texts/ctaButtonText";
import GrayBorderButton from "../buttons/grayBorderButton";
const ProductsFilterPopUpBottomBar = ({ onPressApplyFilter }) => {
  const dispatch = useDispatch();
  const route = RootNavigation.navigationRef.getCurrentRoute();
  const onApply = () => {
    setAnalytics("filter apply button", "Products Filter Applied", {
      screen: route.name,
    });
    onPressApplyFilter();
    dispatch({
      type: "SET_APPLY_PRODUCTS_FILTER",
    });
    dispatch(goBackDialog());
  };

  const onReset = () => {
    setAnalytics("filter reset button", "Products Filter Reset", {
      screen: route.name,
    });
    dispatch(resetProductsFiltersPopup());
    dispatch({
      type: "SET_APPLY_PRODUCTS_FILTER",
    });
    dispatch(goBackDialog());
  };
  return (
    <HStack
      bg={"#2B2F33"}
      justifyContent="center"
      space={2}
      height={Dimensions.get("window").height / 10}
      safeAreaBottom
      alignItems="center"
      px={2}
      position={"absolute"}
      bottom={0}
      top={"auto"}
      width={"100%"}
    >
      <Center w="48%" h="50px">
        <GrayBorderButton text="Reset" onPress={onReset} />
      </Center>
      <Center h="50px" w="48%">
        <PrimaryCTAButton onPress={onApply} gradientType="1">
          <CTAButtonText text="Apply" />
        </PrimaryCTAButton>
      </Center>
    </HStack>
  );
};

export default ProductsFilterPopUpBottomBar;
