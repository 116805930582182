import { useDispatch } from "react-redux";
import { getUser } from "../util/utilFunctions";
import { privateApi } from "../util/API";
import * as RootNavigation from "../RootNavigation";
import { useSWRConfig } from "swr";
import {
  showLoader,
  hideLoader,
  setDialog,
} from "../redux/currentState/actions";
import { Popups } from "../constants/popups";
import { Pages } from "../constants/pages";

import { setAnalytics } from "../analytics";
import Constants from "expo-constants";
import { Platform } from "react-native";
const apiUrl = Constants.expoConfig.extra.apiUrl;
export const useMakeCartPurchase = () => {
  const navigation = RootNavigation;
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const makeCartPurchase = async (
    card_id,
    shipping_address_id,
    billing_address_id,
    amount,
    redeemed_points,
    rewards_discount,
    percentOffDiscount,
    promoCode,

    items
  ) => {
    dispatch(showLoader());
    const userId = await getUser();
    if (userId !== null && userId !== undefined) {
      let discount = percentOffDiscount
        ? rewards_discount + Number(percentOffDiscount) < amount
          ? rewards_discount + Number(percentOffDiscount)
          : amount
        : rewards_discount < amount
        ? rewards_discount
        : amount;

      const values = {
        user_id: userId,
        payment_id: card_id,
        shipping_address_id: shipping_address_id,
        billing_address_id: billing_address_id,
        amount: amount,
        redeemed_points: redeemed_points,
        rewards_discount: discount,
        test: true,
      };

      let res = await privateApi(
        "/api/payments/make_bundles_payments/",
        values,
        "post"
      );
      console.log("useMakeCartPurchase", values, res);
      if (promoCode !== "" && promoCode) {
        const promoRes = await privateApi(
          "/api/home/claim_promocode/",
          {
            user_id: userId,
            promocode: promoCode,
          },
          "post"
        );
      }
      if (res.status === 201 || res.status === 200 || res.status === 204) {
        mutate(`${apiUrl}/api/products/get_cart/?user_id=${userId}`);
        setAnalytics("Confirm Button", "Cart Items Purchased", {
          productIds: items
            ?.filter((p) => p?.product_sku != null)
            .map((k) => k?.product_sku.id),
          brandNames: items
            ?.filter((p) => p?.product_sku != null)
            .map((k) => k?.product_sku?.brand_name),
          productNames: items
            ?.filter((p) => p?.product_sku != null)
            .map((k) => k?.product_sku?.product_name),
          productRetailPrices: items
            ?.filter((p) => p?.product_sku != null)
            .map((k) => k?.product_sku?.retail_price),

          promoCode: promoCode ? promoCode : "No Promo Code Applied",
          redeemedPoints: redeemed_points,
          rewardsDiscount: discount,
          total: amount,
        });

        RootNavigation.navigate(Pages.OrderSuccessfulScreen);
      } else {
        setAnalytics("Confirm Button", "Cart Items Purchase Failed", {
          popUp: "Oops you broke it, error pop up",
          productIds: items
            ?.filter((p) => p?.product_sku != null)
            .map((k) => k?.product_sku.id),
          brandNames: items
            ?.filter((p) => p?.product_sku != null)
            .map((k) => k?.product_sku?.brand_name),
          productNames: items
            ?.filter((p) => p?.product_sku != null)
            .map((k) => k?.product_sku?.product_name),
          productRetailPrices: items
            ?.filter((p) => p?.product_sku != null)
            .map((k) => k?.product_sku?.retail_price),

          promoCode: promoCode ? promoCode : "No Promo Code Applied",
          redeemedPoints: redeemed_points,
          rewardsDiscount: discount,
          total: amount,
        });
        dispatch(setDialog(Popups.Error));
      }
    } else {
      setAnalytics(
        "Confirm Button",
        "Guest User Tried To Make A Cart Purchase",
        {
          popUp: "Login Pop Up",
          productIds: items
            ?.filter((p) => p?.product_sku != null)
            .map((k) => k?.product_sku.id),
          brandNames: items
            ?.filter((p) => p?.product_sku != null)
            .map((k) => k?.product_sku?.brand_name),
          productNames: items
            ?.filter((p) => p?.product_sku != null)
            .map((k) => k?.product_sku?.product_name),
          productRetailPrices: items
            ?.filter((p) => p?.product_sku != null)
            .map((k) => k?.product_sku?.retail_price),
          promoCode: promoCode ? promoCode : "No Promo Code Applied",
          redeemedPoints: redeemed_points,
          rewardsDiscount: discount,
          total: amount,
        }
      );
      if (Platform.OS === "web") {
        navigation.navigate(Pages.Login);
      } else {
        dispatch(setDialog(Popups.LoginPopup));
      }
    }
    dispatch(hideLoader());
  };

  return makeCartPurchase;
};
