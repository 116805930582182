import axios from "axios";
import useSWR from "swr";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
import { Platform } from "react-native";

const apiUrl = Constants.expoConfig.extra.apiUrl;
const fetcher = async (url) =>
  axios
    .get(url, {
      crossDomain: true,
    })
    .then((res) => {
      return res.data?.data;
    });

export default function useGetVersionOfApp() {
  const { data, isValidating, error } = useSWR(
    Platform.OS !== "web" ? `${apiUrl}/api/home/app_version/` : null,

    fetcher
  );
  useCheckNetwork(error);
  useLoader(isValidating);
  return {
    data,
    isValidating,
  };
}
