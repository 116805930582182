import { privateApi } from "../util/API";
import { useDispatch } from "react-redux";
import { getUser } from "../util/utilFunctions";
import { showLoader, hideLoader } from "../redux/currentState/actions";
import { useSWRConfig } from "swr";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
export const useChangeCartVariant = () => {
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const changeCartVariant = async (skuId, previousSkuId, previousNum) => {
    dispatch(showLoader());

    const userId = await getUser();
    try {
      if (userId !== null && userId !== undefined) {
        const res = await privateApi(
          "/api/products/cart/",
          {
            user_id: userId,
            update_sku_id: skuId,
            product_sku_id: previousSkuId,
            number: previousNum,
          },
          "put"
        );
        console.log("changevari", res, {
          user_id: userId,
          update_sku_id: skuId,
          product_sku_id: previousSkuId,
          number: previousNum,
        });
        if (res.status === 200) {
          mutate(`${apiUrl}/api/products/get_cart/?user_id=${userId}`);
          mutate(
            `${apiUrl}/api/products/get_valentines_discount/?user_id=${userId}`
          );
        } else {
        }
      }
      dispatch(hideLoader());
    } catch (error) {}
    dispatch(hideLoader());
  };

  return changeCartVariant;
};
