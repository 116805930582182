import { Center, Pressable, useColorModeValue } from "native-base";
import { GRADIENTMAP } from "../../constants/gradient";

const PillGradientBorderButton = (props) => {
  const backgroundColor = useColorModeValue(
    "white",
    props.bg ? props.bg : "#111315"
  );
  return (
    <Pressable onPress={props.onPress} w="full" h="full" rounded="full">
      <Center
        alignItems="center"
        flexDirection="row"
        justifyContent="space-around"
        w="full"
        h="full"
        alignSelf={"center"}
        bg={{
          linearGradient: {
            colors: GRADIENTMAP[props.gradientType],
            start: [0, 0],
            end: [1, 0],
          },
        }}
        rounded="full"
        _text={{
          color: "#ffffff",
        }}
      >
        <Center
          _light={{
            bg: "white",
          }}
          _dark={{
            bg: { backgroundColor },
          }}
          bg={backgroundColor}
          alignItems="center"
          flexDirection="row"
          justifyContent="space-around"
          w="99%"
          h="95%"
          alignSelf={"center"}
          rounded="full"
          _text={{
            color: "#ffffff",
          }}
        >
          {props.children}
        </Center>
      </Center>
    </Pressable>
  );
};

export default PillGradientBorderButton;
