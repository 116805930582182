import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SET_DIALOG,
  SET_ACTIVE_CARD,
  SET_LOGGED_IN,
  SET_LOGGED_OUT,
  SET_TYPE_OF_ADDRESS,
  GO_BACK_DIALOG,
  SHARE_LINK,
  SET_ERROR,
  HIDE_LOADER,
  SET_BOTTOM_ICON,
  SHOW_LOADER,
  SET_PRODUCT_ORDER,
  SET_PAGE,
  SET_FILTER_PAGE,
  LOADED_PAGE,
  SET_APPLY_FILTER,
  SET_APPLY_PRODUCTS_FILTER,
  SET_REFRESH,
  UNSET_REFRESH,
  SET_NOTIFICATION_LENGTH,
  SHOW_NETWORK,
  HIDE_NETWORK,
  REFERRAL_ID,
} from "./constants";

export function openDialog() {
  return {
    type: OPEN_DIALOG,
  };
}

export function goBackDialog() {
  return {
    type: GO_BACK_DIALOG,
  };
}
export function closeDialog() {
  return {
    type: CLOSE_DIALOG,
  };
}

export function setDialog(dialog, params) {
  return {
    type: SET_DIALOG,
    dialog: dialog,
    params: params,
  };
}

export function setShareLink(link) {
  return {
    type: SHARE_LINK,
    link: link,
  };
}

export function setActiveCard(id, activeCardType) {
  return {
    type: SET_ACTIVE_CARD,
    id: id,
    activeCardType: activeCardType,
  };
}

export function setLoggedIn() {
  return {
    type: SET_LOGGED_IN,
  };
}

export function setError(error) {
  return {
    type: SET_ERROR,
    error: error,
  };
}
export function showLoader(error) {
  return {
    type: SHOW_LOADER,
  };
}
export function hideLoader(error) {
  return {
    type: HIDE_LOADER,
  };
}
export function showNetwork(error) {
  return {
    type: SHOW_NETWORK,
  };
}
export function hideNetwork(error) {
  return {
    type: HIDE_NETWORK,
  };
}
export function setReferralID(referralId) {
  return {
    type: REFERRAL_ID,
    referralId: referralId,
  };
}

export function setLoggedOut() {
  return {
    type: SET_LOGGED_OUT,
  };
}

export function setTypeOfAddress(type) {
  return {
    type: SET_TYPE_OF_ADDRESS,
    typeOfAddress: type,
  };
}

export function setBottomIcon(bottomIcon) {
  return {
    type: SET_BOTTOM_ICON,
    bottomIcon: bottomIcon,
  };
}

export function setProductOrder(products) {
  return {
    type: SET_PRODUCT_ORDER,
    products: products,
  };
}

export function setPage(page) {
  return {
    type: SET_PAGE,
    page: page,
  };
}

export function setFilterPage(filterPage) {
  return {
    type: SET_FILTER_PAGE,
    filterPage: filterPage,
  };
}

export function loadedPage() {
  return {
    type: LOADED_PAGE,
  };
}
export function setApplyFilter() {
  return {
    type: SET_APPLY_FILTER,
  };
}

export function setApplyProductsFilter() {
  return {
    type: SET_APPLY_PRODUCTS_FILTER,
  };
}

export function setRefresh() {
  return {
    type: SET_REFRESH,
  };
}

export function unsetRefresh() {
  return {
    type: UNSET_REFRESH,
  };
}

export function setNotificationsLength(length) {
  return {
    type: SET_NOTIFICATION_LENGTH,
    length: length,
  };
}
