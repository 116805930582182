import { ChevronLeftIcon, HStack, IconButton, Stack } from "native-base";
import { useNavigation } from "@react-navigation/native";
import SearchTextBar from "./searchTextBar";

const SearchTopBar = ({ search, setSearch }) => {
  const navigation = useNavigation();
  return (
    <HStack pt={"65px"} px={"20px"} alignItems="center" space={4}>
      <IconButton
        onPress={() => {
          navigation.goBack();
        }}
        size={6}
        _icon={{
          color: "white",
        }}
        icon={<ChevronLeftIcon />}
      />

      <Stack
        w={{
          md: "290px",
          lg: "290px",
          xl: "340px",
        }}
      >
        <SearchTextBar
          setSearch={(val) => setSearch(val)}
          search={search}
          placeholder={`Search "${"Eau De Parfum"}"`}
        />
      </Stack>
    </HStack>
  );
};

export default SearchTopBar;
