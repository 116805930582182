import { Center, Divider, FlatList, View, VStack } from "native-base";
import AffiliateOrderProductCard from "../../components/cards/product/affiliateOrderProductCard";

const Orders = ({ data }) => {
  const renderItem = ({ item, index }) => (
    <VStack space={4}>
      <AffiliateOrderProductCard data={item} />
      {index !== data?.length - 1 && (
        <Center>
          <Divider bg={"#94A3B8"} w="20%" />
        </Center>
      )}
    </VStack>
  );

  return (
    <>
      <FlatList
        pt={"16px"}
        h="full"
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 15 }}></View>
        )}
        contentContainerStyle={{
          paddingBottom: 30,
        }}
        data={data ? data : []}
        initialNumToRender={5}
        renderItem={renderItem}
      />
    </>
  );
};

export default Orders;
