import UsersHOC from "../../hoc/UsersHOC";
import CommunityScreen from "./communityScreen";

const CommunityScreenWrapper = () => {
  return (
    <UsersHOC fetcherType="allUsersFetcher">
      <CommunityScreen />
    </UsersHOC>
  );
};

export default CommunityScreenWrapper;
