import { HStack, Text, Pressable } from "native-base";
import FlockAvatar from "./../../assets/images/svgs/flockAvatar.svg";
import { Linking, Platform } from "react-native";

const UpdateNotificationCard = ({ notification }) => {
  return (
    <>
      <Pressable
        onPress={async () => {
          if (notification.type === "New Update") {
            if (Platform.OS === "android") {
              Linking.openURL(
                "https://play.google.com/store/apps/details?id=com.flock.shopping"
              );
            }
          }
        }}
      >
        <HStack
          space={3}
          px={2}
          py={4}
          bg={notification?.seen ? "black" : "#202427"}
        >
          <FlockAvatar />
          <Text
            flex={1}
            color={"white"}
            opacity={notification?.seen ? 0.6 : 1}
            fontSize={"14px"}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
          >
            {notification?.message}
          </Text>
        </HStack>
      </Pressable>
    </>
  );
};

export default UpdateNotificationCard;
