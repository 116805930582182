import {
  Text,
  Modal,
  HStack,
  VStack,
  Slide,
  Pressable,
  Box,
  Divider,
  Center,
  FlatList,
  View,
} from "native-base";
import { useEffect, useState } from "react";
import Price from "./buildingBlocks/price";
// import Ratings from "./buildingBlocks/ratings";
import Brand from "./buildingBlocks/brand";
// import SkinProfile from "./buildingBlocks/skinProfile";
// import HairProfile from "./buildingBlocks/hairProfile";

import ProductsFilterPopUpBottomBar from "../../bars/productsFilterPopUpBottomBar";
import FilterPopUpTopBar from "../../bars/popupTopBars/filterPopUpTopBar";
import { useDispatch, useSelector } from "react-redux";
import { goBackDialog } from "../../../redux/currentState/actions";
import Category from "./buildingBlocks/category";
import {
  changeProductsFilter,
  resetProductsCacheFilters,
} from "../../../redux/productsFilter/actions";
import HairProfile from "./buildingBlocks/hairProfile";
//import { useRoute } from "@react-navigation/native";

const ProductsFilterPopUp = () => {
  const dispatch = useDispatch();
  const low = useSelector(
    (state) => state.productsFilter.cachedFilters.priceRangeFrom
  );
  const high = useSelector(
    (state) => state.productsFilter.cachedFilters.priceRangeTo
  );
  const [priceFilter, setPriceFilter] = useState([]);
  const brandFilter = useSelector(
    (state) => state.productsFilter.cachedFilters.brands
  );
  const concernFilter = useSelector(
    (state) => state.productsFilter.cachedFilters.concerns
  );
  const productTypes = useSelector(
    (state) => state.productsFilter.cachedFilters.productTypes
  );
  //const route = useRoute();
  const openDialog = useSelector((state) => state.currentState.openDialog);
  const type = useSelector((state) => state.productsFilter.type);
  // const filter = useSelector((state)=>state.filter.filter)

  const [selectedBrands, setSelectedBrands] = useState(brandFilter);
  const [selectedCategory, setSelectedCategory] = useState(productTypes);

  useEffect(() => {
    if (low !== 0 || high !== 700) {
      setPriceFilter([0]);
    } else {
      setPriceFilter([]);
    }
  }, [low, high]);

  useEffect(() => {
    if (type === "brand") {
      resetFilterBy([
        {
          label: "Price",
          component: <Price />,
          pressed: true,
          filter: priceFilter,
        },
        {
          label: "Category",
          component: <Category />,
          pressed: false,
          filter: selectedCategory,
        },
      ]);
    }
  }, [type]);

  const [filterBy, resetFilterBy] = useState([
    {
      label: "Price",
      component: <Price />,
      pressed: true,
      filter: priceFilter,
    },

    {
      label: "Brand",
      component: <Brand />,
      pressed: false,
      filter: selectedBrands,
    },
    // {
    //   label: "Hair Profile",
    //   component: <HairProfile />,
    //   pressed: false,
    //   filter: concernFilter,
    // },
  ]);

  const checkForFilter = async () => {
    dispatch(resetProductsCacheFilters());
    dispatch(goBackDialog());
  };

  const onPressApplyFilter = () => {
    if (type === "all") {
      dispatch(
        changeProductsFilter({
          brands: selectedBrands,
          type,
        })
      );
    } else {
      dispatch(
        changeProductsFilter({
          productTypes: selectedCategory,
          type,
        })
      );
    }
  };

  return (
    <>
      <Modal
        size={"full"}
        isOpen={openDialog}
        onClose={() => checkForFilter()}
        bg="black:alpha.80"
      >
        <Slide overlay={false} in={openDialog} placement="bottom">
          <VStack
            height={"90%"}
            roundedTop={"40px"}
            marginBottom={"0"}
            marginTop={"auto"}
            bg={"#2B2F33"}
            width="100%"
          >
            <FilterPopUpTopBar onPress={() => checkForFilter()} />

            <HStack height={"75%"} pt={1}>
              <View width={"35%"} bg={"#202427"}>
                <FlatList
                  showsVerticalScrollIndicator={false}
                  data={filterBy}
                  renderItem={({ item, index }) => (
                    <Pressable
                      key={index}
                      onPress={() => {
                        let copy = [...filterBy];
                        copy.map((k) => {
                          if (k.pressed) {
                            return (k.pressed = false);
                          }
                        });
                        copy[index] = { ...copy[index], pressed: true };
                        resetFilterBy(copy);
                      }}
                    >
                      <HStack
                        height={16}
                        justifyContent={"space-between"}
                        alignItems="center"
                        pl={2}
                      >
                        <Text
                          fontSize={16}
                          style={{
                            fontFamily: "HK_600SemiBold",
                          }}
                          textAlign={"center"}
                          color={item.pressed ? "white" : "#94A3B8"}
                        >
                          {item.label}

                          {"   "}
                          {filterBy.map(
                            (i, k) =>
                              i.filter.length > 0 &&
                              index === k && (
                                <Center>
                                  <Box
                                    bg={"#8D49DE"}
                                    height={1.5}
                                    width={1.5}
                                    rounded="full"
                                  ></Box>
                                </Center>
                              )
                          )}
                        </Text>

                        <Divider
                          orientation="vertical"
                          bg={item.pressed ? "#8D49DE" : "#202427"}
                          thickness="3"
                        />
                      </HStack>
                    </Pressable>
                  )}
                />
              </View>
              <View w={"65%"} bg={"#2B2F33"}>
                <FlatList
                  showsVerticalScrollIndicator={false}
                  contentContainerStyle={{
                    flexDirection: "row",
                  }}
                  data={filterBy}
                  renderItem={({ item }) => {
                    return item?.label === "Price" ? (
                      <HStack justifyContent={"center"}>
                        {item?.pressed && <Price />}
                      </HStack>
                    ) : item?.label === "Brand" ? (
                      <HStack justifyContent={"center"}>
                        {item?.pressed && (
                          <Brand
                            selectedBrands={selectedBrands}
                            setSelectedBrands={setSelectedBrands}
                          />
                        )}
                      </HStack>
                    ) : item.label === "Category" ? (
                      <HStack justifyContent={"center"}>
                        {item?.pressed && (
                          <Category
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                          />
                        )}
                      </HStack>
                    ) : null;
                  }}
                />
              </View>
            </HStack>
          </VStack>

          <ProductsFilterPopUpBottomBar
            onPressApplyFilter={onPressApplyFilter}
          />
        </Slide>
      </Modal>
    </>
  );
};

export default ProductsFilterPopUp;
