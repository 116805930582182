import { useRoute } from "@react-navigation/native";
import {
  Center,
  Divider,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
} from "native-base";
import { setAnalytics } from "../../../analytics";
import QuantityButtonHOC from "../../../hoc/QuantityButtonHOC";
import { useDeleteFromCart } from "../../../hooks/useDeleteFromCart";
import { useGetProductDetails } from "../../../hooks/useGetProductDetails";
import FullWidthAccordion from "../../accordions/fullWidthAccordion";
import QuantityButton from "../../buttons/quantityButton";
import CircleCloseIcon from "../../icons/webIcons/circleCloseIcon";

const CartProductCard = ({ item, variants }) => {
  const route = useRoute();
  const deleteFromCart = useDeleteFromCart();
  const { product } = useGetProductDetails(item?.product_sku?.product?.id);

  const map = {
    Color: product?.colors,
    Size: product?.sizes,
    Scent: product?.scents,
    Type: product?.types,
  };

  return (
    <VStack space={4}>
      <HStack pl={4} justifyContent={"space-between"} alignItems="center">
        <HStack space={3} w="40%">
          <Image
            borderWidth={"1px"}
            borderColor="#EDEDED"
            size={"120px"}
            rounded="20px"
            resizeMethod="scale"
            resizeMode="contain"
            source={{
              uri:
                route?.params?.type === "single"
                  ? item?.images[0]
                  : item?.product_sku?.images[0],
            }}
            alt=""
          />
          <VStack w="70%">
            <Text
              numberOfLines={2}
              color="#682481"
              fontSize={"20px"}
              style={{
                fontFamily: "AustinCyr_400",
              }}
            >
              {route?.params?.type === "single"
                ? item?.product_name
                : item?.product_sku?.product_name}
            </Text>
            {variants
              ?.filter((f) => f)
              .map((k, i) => (
                <HStack alignItems={"center"} space="3">
                  <Text
                    numberOfLines={2}
                    color="#464646"
                    fontSize={"14px"}
                    style={{
                      fontFamily: "Hero_400Regular",
                    }}
                  >
                    {`${k?.label}: ${k?.variant}`}
                  </Text>
                  <VStack>
                    {map[k?.label] && (
                      <FullWidthAccordion
                        previousSkuId={item?.product_sku?.id}
                        previousNum={item?.number}
                        id={item?.product_sku?.product?.id}
                        type="change"
                        label={k?.label}
                        items={map[k?.label]}
                        val={k?.variant}
                      />
                    )}
                  </VStack>
                </HStack>
              ))}
          </VStack>
        </HStack>
        <HStack
          alignItems="center"
          justifyContent={"space-between"}
          w={{
            base: route?.params?.type !== "single" && "50%",
            sm: route?.params?.type !== "single" && "50%",
            md: route?.params?.type !== "single" && "50%",
            lg: route?.params?.type !== "single" && "50%",
            xl: route?.params?.type !== "single" && "50%",
            xxl: route?.params?.type !== "single" && "45%",
          }}
        >
          {[
            <Text
              letterSpacing={2}
              textTransform={"uppercase"}
              color="#2F2F2F"
              fontSize={"18px"}
              style={{
                fontFamily: "Hero_700Bold",
              }}
            >
              $
              {route?.params?.type === "single"
                ? item?.retail_price?.toFixed(2)
                : item?.product_sku?.retail_price?.toFixed(2)}
            </Text>,
            route?.params?.type === "single" ? null : (
              <Center h="40px" w="150px">
                <QuantityButtonHOC>
                  <QuantityButton skuId={item?.product_sku?.id} />
                </QuantityButtonHOC>
              </Center>
            ),
            route?.params?.type === "single" ? null : (
              <Text
                letterSpacing={2}
                textTransform={"uppercase"}
                color="#682481"
                fontSize={"24px"}
                style={{
                  fontFamily: "Hero_700Bold",
                }}
              >
                $
                {route?.params?.type === "single"
                  ? item?.retail_price?.toFixed(2)
                  : (item?.product_sku?.retail_price * item?.number)?.toFixed(
                      2
                    )}
              </Text>
            ),
          ]
            ?.filter((f) => f)
            ?.map((k) => k)}
          <IconButton
            onPress={() => {
              deleteFromCart(item?.product_sku?.id);
              setAnalytics("Remove From cart", "Removed from Cart", {
                productId: item?.id,
              });
            }}
            _pressed={{
              bg: "#3A1F1F",
            }}
            icon={<CircleCloseIcon />}
          ></IconButton>
        </HStack>
      </HStack>
      <Divider bg="#FAF1FF" w="full" />
    </VStack>
  );
};

export default CartProductCard;
