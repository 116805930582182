import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

const PlusEllipseIconOutlined = ({ onPress }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon onPress={onPress} viewBox='0 0 18 18'>
        <G width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <Path
            d='M8.99935 0.666992C13.6018 0.666992 17.3327 4.39783 17.3327 9.00033C17.3327 13.6028 13.6018 17.3337 8.99935 17.3337C4.39685 17.3337 0.666016 13.6028 0.666016 9.00033C0.666016 4.39783 4.39685 0.666992 8.99935 0.666992ZM8.99935 1.91699C7.12073 1.91699 5.31906 2.66327 3.99068 3.99165C2.66229 5.32004 1.91602 7.12171 1.91602 9.00033C1.91602 10.8789 2.66229 12.6806 3.99068 14.009C5.31906 15.3374 7.12073 16.0837 8.99935 16.0837C10.878 16.0837 12.6796 15.3374 14.008 14.009C15.3364 12.6806 16.0827 10.8789 16.0827 9.00033C16.0827 7.12171 15.3364 5.32004 14.008 3.99165C12.6796 2.66327 10.878 1.91699 8.99935 1.91699ZM8.99935 4.83366C9.16511 4.83366 9.32408 4.89951 9.44129 5.01672C9.5585 5.13393 9.62435 5.2929 9.62435 5.45866V8.37533H12.541C12.7068 8.37533 12.8657 8.44117 12.983 8.55838C13.1002 8.67559 13.166 8.83457 13.166 9.00033C13.166 9.16609 13.1002 9.32506 12.983 9.44227C12.8657 9.55948 12.7068 9.62533 12.541 9.62533H9.62435V12.542C9.62435 12.7078 9.5585 12.8667 9.44129 12.9839C9.32408 13.1011 9.16511 13.167 8.99935 13.167C8.83359 13.167 8.67462 13.1011 8.55741 12.9839C8.4402 12.8667 8.37435 12.7078 8.37435 12.542V9.62533H5.45768C5.29192 9.62533 5.13295 9.55948 5.01574 9.44227C4.89853 9.32506 4.83268 9.16609 4.83268 9.00033C4.83268 8.83457 4.89853 8.67559 5.01574 8.55838C5.13295 8.44117 5.29192 8.37533 5.45768 8.37533H8.37435V5.45866C8.37435 5.2929 8.4402 5.13393 8.55741 5.01672C8.67462 4.89951 8.83359 4.83366 8.99935 4.83366Z'
            fill='#94A3B8'
          />
        </G>
      </Icon>
    </>
  );
};

export default PlusEllipseIconOutlined;
