import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const CheckboxWhiteTickIcon = () => {
  return (
    <>
      <Icon size={3} viewBox="0 0 12 10">
        <G
          width="12"
          height="10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M4.02484 7.30857L1.45169 4.82114L0.572266 5.66229L4.02484 9L11.4294 1.84114L10.5591 1L4.02484 7.30857Z"
            fill="white"
            stroke="white"
            stroke-width="0.5"
          />
        </G>
      </Icon>
    </>
  );
};

export default CheckboxWhiteTickIcon;
