import { Center, Text, VStack } from "native-base";
import { useContext } from "react";
import ProductCard from "../../../components/cards/product/productCard";
import ColumnLayout from "../../../components/layouts/columnLayout";
import { gradientTextWeb } from "../../../constants/gradient";
import { ProductsContext } from "../../../hoc/ProductsHOC";

const CuratedProducts = () => {
  const { data, setSize } = useContext(ProductsContext);
  const renderCuratedProducts = ({ item }) => {
    return (
      <Center m={1.5}>
        <ProductCard
          variantLength={
            [item?.size, item?.scent, item?.colors, item?.type]?.filter(
              (k) => k
            ).length
          }
          productImage={item && item?.images && item?.images[0]}
          retailPrice={item?.retail_price}
          productName={item?.name}
          brandName={item?.brand_name}
          productId={item?.id}
          productSkuId={item?.default_sku}
        />
      </Center>
    );
  };
  return (
    <>
      {data && (
        <VStack
          w={{
            base: "full",
            sm: "full",
            md: "full",
            lg: "full",
            xl: "80%",
            xxl: "75%",
          }}
          space={6}
          alignItems={"center"}
        >
          <Text
            w="full"
            fontSize={"36px"}
            _web={{
              style: {
                ...gradientTextWeb(1),
                fontFamily: "AustinCyr_400",
              },
            }}
          >
            Products
          </Text>
          <ColumnLayout
            defaultNumberOfColumns={5}
            data={data}
            renderItem={renderCuratedProducts}
            setSize={setSize}
          />
        </VStack>
      )}
    </>
  );
};

export default CuratedProducts;
