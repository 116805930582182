import {
  Button,
  Center,
  HStack,
  Image,
  ScrollView,
  Text,
  useColorMode,
  VStack,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { setAnalytics } from "../../analytics";
import FullWidthFilledAccordion from "../../components/accordions/fullWidthFilledAccordion";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import AccountDeleteButton from "../../components/buttons/accountDeleteButton";
import GrayBorderButton from "../../components/buttons/grayBorderButton";
import PrimaryCTAButton from "../../components/buttons/primaryCTAButton";
import CTAButtonText from "../../components/texts/ctaButtonText";
import { Pages } from "../../constants/pages";
import { handleLogout } from "../../util/utilFunctions";

const SettingScreen = ({ navigation, route }) => {
  const userId = useSelector((state) => state.user.id);
  const dispatch = useDispatch();
  const onPress = async () => {
    await setAnalytics("Logout Button", "Logged Out", {
      screen: route?.name,
    });

    handleLogout();
    dispatch({
      type: "REFRESH",
    });

    navigation.navigate(Pages.Login);
  };
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <>
      <ScrollView zIndex={0} width="100%" showsVerticalScrollIndicator={false}>
        <VStack p={4} space={4}>
          <VStack space={1}>
            <Button
              _hover={{
                bg: "#FAF1FF",
              }}
              onPress={toggleColorMode}
              variant="ghost"
              _text={{
                fontFamily: "HK_700Bold",
                color: "#464646",
                fontSize: "14px",
                textTransform: "uppercase",
                letterSpacing: 4,
              }}
            >
              Change theme
            </Button>
            <VStack space={"12px"}>
              {userId
                ? [
                    {
                      label: "Your orders",
                      onPress: () => navigation.navigate(Pages.Orders),
                    },

                    {
                      label: "Addresses",
                      onPress: () => navigation.navigate(Pages.AddressesScreen),
                    },
                    {
                      label: "Payment Methods",
                      onPress: () =>
                        navigation.navigate(Pages.PaymentMethodsScreen),
                    },
                    {
                      label: (
                        <HStack
                          space={2}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text
                            textAlign={"center"}
                            fontSize={16}
                            style={{
                              fontFamily: "HK_400Regular",
                            }}
                            color={"white"}
                          >
                            About
                          </Text>
                          <Image
                            resizeMethod="scale"
                            resizeMode="contain"
                            source={require("./../../assets/images/pngs/smallFlockWhiteLogo.png")}
                            alt=""
                          />
                        </HStack>
                      ),

                      onPress: () =>
                        navigation.navigate(Pages.AboutFlockScreen),
                    },
                    {
                      label: "F.A.Q",
                      onPress: () => navigation.navigate(Pages.FAQScreen),
                    },
                    {
                      label: "Privacy Policy",
                      onPress: () =>
                        navigation.navigate(Pages.PrivacyPolicyScreen),
                    },
                    {
                      label: "Returns Policy",
                      onPress: () => navigation.navigate(Pages.ReturnsPolicy),
                    },
                    {
                      label: "Shipping Policy",
                      onPress: () => navigation.navigate(Pages.ShippingPolicy),
                    },
                    {
                      label: "Help & Support",
                      onPress: () =>
                        navigation.navigate("HelpAndSupportScreen"),
                    },
                  ].map((item, index) => (
                    <FullWidthFilledAccordion
                      key={index}
                      label={item?.label}
                      onPress={item?.onPress}
                    />
                  ))
                : [
                    {
                      label: (
                        <HStack
                          space={2}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text
                            textAlign={"center"}
                            fontSize={16}
                            style={{
                              fontFamily: "HK_400Regular",
                            }}
                            color={"white"}
                          >
                            About
                          </Text>
                          <Image
                            resizeMethod="scale"
                            resizeMode="contain"
                            source={require("./../../assets/images/pngs/smallFlockWhiteLogo.png")}
                            alt=""
                          />
                        </HStack>
                      ),

                      onPress: () =>
                        navigation.navigate(Pages.AboutFlockScreen),
                    },
                    {
                      label: "F.A.Q",
                      onPress: () => navigation.navigate(Pages.FAQScreen),
                    },
                    {
                      label: "Privacy Policy",
                      onPress: () =>
                        navigation.navigate(Pages.PrivacyPolicyScreen),
                    },
                    {
                      label: "Returns Policy",
                      onPress: () => navigation.navigate(Pages.ReturnsPolicy),
                    },
                    {
                      label: "Shipping Policy",
                      onPress: () => navigation.navigate(Pages.ShippingPolicy),
                    },
                    {
                      label: "Help & Support",
                      onPress: () =>
                        navigation.navigate("HelpAndSupportScreen"),
                    },
                  ].map((item, index) => (
                    <FullWidthFilledAccordion
                      key={index}
                      label={item?.label}
                      onPress={item?.onPress}
                    />
                  ))}
            </VStack>
          </VStack>

          <Center>
            <VStack space={2} alignItems="center" w="full">
              {userId ? (
                <>
                  <Center w="100%" h="50px">
                    <GrayBorderButton text="Logout" onPress={onPress} />
                  </Center>
                  <AccountDeleteButton />
                </>
              ) : (
                <Center h="50px" w="173.35px">
                  <PrimaryCTAButton
                    onPress={() => {
                      setAnalytics(
                        "Login/Sign up Button",
                        "Login/Sign up Button Clicked",
                        {
                          screen: route?.name,
                        }
                      );
                      navigation.navigate(Pages.Login);
                    }}
                    gradientType="2"
                  >
                    <CTAButtonText text="Login / Sign up" />
                  </PrimaryCTAButton>
                </Center>
              )}
              <Button
                onPress={() => navigation.navigate(Pages.Terms)}
                p={0}
                variant="ghost"
                isExternal
                _text={{
                  color: "#1A94FF",
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: 18,
                  fontFamily: "HK_500Medium",
                }}
                mt={-0.5}
              >
                Terms and Conditions
              </Button>

              <Text
                style={{
                  fontFamily: "HK_400Regular",
                }}
                fontSize={10}
                lineHeight={16}
                color="#94A3B8"
              >
                version: 1.0.0
              </Text>
            </VStack>
          </Center>
        </VStack>
      </ScrollView>
      <BottomBar />
    </>
  );
};

export default SettingScreen;
