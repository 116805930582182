import { setAnalytics } from "../../analytics";
import { Pages } from "../../constants/pages";
import { AntDesign } from "@expo/vector-icons";
const {
  VStack,
  HStack,
  Text,
  Center,
  Image,
  Box,
  Pressable,
} = require("native-base");

const SignupOptionsScreen = ({ navigation, route }) => {
  return (
    <>
      <VStack space={4} px={2} flex={1}>
        <Center>
          <Text
            color={"white"}
            fontSize={"24px"}
            style={{
              fontFamily: "HK_700Bold",
            }}
          >
            Sign up for Flock
          </Text>
        </Center>
        {[
          {
            label: "Use Phone or Email",
            icon: <AntDesign name="user" size={24} color="white" />,
            navigateTo: Pages.PhoneEmailSignupScreen,
          },
          {
            label: "Continue with Facebook",
            icon: require("./../../assets/images/pngs/facebook.png"),
            navigateTo: Pages.PhoneEmailSignupScreen,
          },

          {
            label: "Continue with Google",
            icon: require("./../../assets/images/pngs/gmail.png"),
            navigateTo: Pages.PhoneEmailSignupScreen,
          },
        ].map((k, i) => (
          <Pressable onPress={() => navigation.navigate(k.navigateTo)}>
            <HStack
              w="full"
              alignItems={"center"}
              h="60px"
              px={5}
              borderWidth={"1px"}
              borderColor={"#58595B"}
              rounded="full"
              justifyContent={"space-between"}
            >
              {i === 0 ? (
                k.icon
              ) : (
                <Image
                  resizeMethod="scale"
                  resizeMode="contain"
                  source={k?.icon}
                  alt=""
                />
              )}
              <Text
                textAlign={"center"}
                color={"#94A3B8"}
                fontSize={"16px"}
                letterSpacing={"sm"}
                style={{
                  fontFamily: "HK_500Medium",
                }}
              >
                {k.label}
              </Text>
              <Box size={4}></Box>
            </HStack>
          </Pressable>
        ))}
      </VStack>
      <VStack
        alignContent={"center"}
        justifyContent="center"
        space={1.5}
        pb={6}
      >
        <Text
          style={{
            fontFamily: "HK_500Medium",
          }}
          fontSize={"16px"}
          textAlign={"center"}
          color="white"
        >
          Already have an account?
        </Text>
        <Pressable
          onPress={() => {
            setAnalytics(
              "Login/Sign up Button",
              "Login/Sign up Button Clicked",
              {
                screen: route?.name,
              }
            );
            navigation.navigate(Pages.Login);
          }}
        >
          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="center"
            w="173.35px"
            h="50px"
            alignSelf={"center"}
            bg={{
              linearGradient: {
                colors: [
                  "primary.ctaLinearFirstColor",
                  "primary.ctaLinearSecondColor",
                ],
                start: [0, 0],
                end: [1, 0],
              },
            }}
            rounded="10px"
            _text={{
              color: "#ffffff",
            }}
          >
            <Text
              color="white"
              style={{
                fontFamily: "HK_600SemiBold",
              }}
              fontSize="14px"
            >
              Login
            </Text>
          </Box>
        </Pressable>
      </VStack>

      <Center safeAreaBottom>
        <Text
          fontSize={14}
          style={{
            fontFamily: "HK_500Medium",
          }}
          color="#fff"
          pb={4}
        >
          By creating an account, you are agreeing to Flock's{"\n"}
          <Pressable onPress={() => navigation.navigate(Pages.Terms)}>
            <Text
              fontSize={14}
              style={{
                fontFamily: "HK_500Medium",
              }}
              color="info.400"
            >
              Terms and Conditions.
            </Text>
          </Pressable>
        </Text>
      </Center>
    </>
  );
};

export default SignupOptionsScreen;
