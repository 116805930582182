import {
  ADD_PRODUCT_DETAILS,
  ADD_ORDER_DETAILS,
  COMPLETE_PURCHASE,
  REFRESH_DETAILS,
  UNREFRESH_DETAILS,
} from "./constants";

const initialState = {
  products: [],
  id: null,
  quantity: 1,
  public: true,
  billingAddress: null,
  shippingAddress: null,
  card: null,
  amount: 0,
  type: "group", // single,cart
  detailsSet: false,
  refreshDetails: true,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REFRESH":
      return {
        products: [],
        id: null,
        quantity: 1,
        public: true,
        billingAddress: null,
        shippingAddress: null,
        card: null,
        amount: 0,
        type: "group", // single,cart
        detailsSet: false,
        refreshDetails: false,
      };
    case ADD_PRODUCT_DETAILS:
      return {
        ...state,
        products: [...state.products, action.product],
        id: action.product.id,
        amount: findAmount({
          ...state,
          products: [...state.products, action.product],
          id: action.product.id,
        }),
      };
    case ADD_ORDER_DETAILS:
      return {
        ...state,
        ...action.details,
        refreshDetails: false,
        amount: findAmount({ ...state, ...action.details }),
        detailsSet: checkForDetails({ ...state, ...action.details }),
      };
    case REFRESH_DETAILS:
      return {
        ...state,
        refreshDetails: true,
      };
    case UNREFRESH_DETAILS:
      return {
        ...state,
        refreshDetails: false,
      };
    case COMPLETE_PURCHASE:
      return {
        products: [],
        id: null,
        quantity: 1,
        refreshDetails: false,
        paymentInformation: {},
        addressInformation: {},
        public: true,
        type: "group",
        amount: 0,
        detailsSet: false,
      };

    default:
      return state;
  }
};

const checkForDetails = (state) => {
  if (
    state.shippingAddress !== undefined &&
    state.shippingAddress !== null &&
    state.card !== null &&
    state.card !== undefined
  ) {
    return true;
  } else {
    return false;
  }
};

const findAmount = (state) => {
  let amount = 0;
  if (state?.type === "cart") {
    state?.products?.forEach((pro) => {
      amount = amount + pro.product_sku.retail_price * pro.number;
    });
  } else if (state?.type === "single") {
    amount = state?.product?.retail_price * state?.quantity;
  } else if (state?.product) {
    amount = state?.product.details ? state?.product.details.group_price : 0;
    if (amount === 0) {
      amount = state?.product.group_price ? state?.product.group_price : 1;
    }
  } else {
    amount = 1;
  }
  return amount;
};

export default orderReducer;
