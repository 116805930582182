import { Input, Stack } from "native-base";
import SearchIconOutlined from "../../../icons/searchIconOutlined";

const SearchBox = ({ onChange }) => {
  return (
    <Input
      w="100%"
      h="100%"
      borderWidth={"1px"}
      borderColor={"#94A3B8"}
      style={{
        fontFamily: "HK_400Regular",
      }}
      autoCapitalize="none"
      placeholderTextColor="#94A3B8"
      fontSize={14}
      onChangeText={onChange}
      rounded={8}
      InputLeftElement={
        <Stack pl={4}>
          <SearchIconOutlined size={4} />
        </Stack>
      }
      _light={{
        bg: "white",
        _focus: {
          backgroundColor: "white",
          color: "#464646",
        },
        _hover: {
          borderColor: "#682481",
        },
        focusOutlineColor: "#682481",
      }}
      color="white"
      _focus={{
        borderColor: "#94A3B8",
        backgroundColor: "#1A1C1E",
        color: "white",
      }}
      placeholder="Find"
    />
  );
};

export default SearchBox;
