import { Center, HStack, Pressable, Text, VStack } from "native-base";
import { Image } from "react-native";
import GradientText from "./gradientText";

const TopCategories = () => {
  return (
    <>
      <VStack
        alignItems="center"
        w="full"
        h="187px"
        justifyContent={"center"}
        space={"20px"}
      >
        <Center w="full">
          <GradientText text="Top categories" />
        </Center>
        <HStack
          justifyContent={"space-between"}
          alignItems="center"
          w="full"
          px={6}
        >
          {[
            {
              label: "Hair Sprays",
              image: require("./../assets/images/pngs/hairSpray.png"),
            },
            {
              label: "Treatments",
              image: require("./../assets/images/pngs/treatments2.png"),
            },
            {
              label: "Tools",
              image: require("./../assets/images/pngs/tools2.png"),
            },
            {
              label: "Conditioners",
              image: require("./../assets/images/pngs/conditioners2.png"),
            },
          ].map((k, i) => (
            <Pressable key={i}>
              <VStack alignItems={"center"} space={2}>
                <Image
                  style={{
                    width: 70,
                    height: 70,
                  }}
                  resizeMethod="scale"
                  resizeMode="contain"
                  source={k?.image}
                  alt=""
                />
                <Text
                  lineHeight={"16.25px"}
                  fontSize={"13px"}
                  color="white"
                  style={{
                    fontFamily: "HK_500Medium",
                  }}
                >
                  {k.label}
                </Text>
              </VStack>
            </Pressable>
          ))}
        </HStack>
      </VStack>
    </>
  );
};

export default TopCategories;
