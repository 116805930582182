import { useNavigation, useRoute } from "@react-navigation/native";
import {
  Center,
  HStack,
  Image,
  Pressable,
  Text,
  View,
  VStack,
  ScrollView,
  useColorModeValue,
} from "native-base";
import { Keyboard, TouchableWithoutFeedback } from "react-native";
import { setAnalytics } from "../../analytics";
import { Pages } from "../../constants/pages";
import useGetFavorites from "../../hooks/useGetFavorites";
import FollowButton from "../buttons/followButton";
import DashIcon from "../icons/dashIcon";
import EllipseIcon from "../icons/ellipseIcon";

const BeautyBuddiesCard = ({
  item,
  cardWidth = "350px",
  imageWidth = "183px",
}) => {
  const navigation = useNavigation();
  const route = useRoute();

  const { data: favorites } = useGetFavorites(item?.id);
  const backgroundColor = useColorModeValue("#FAF1FF", "#2B2F33");
  return (
    <Pressable
      onPress={() => {
        Keyboard.dismiss();
        setAnalytics("beauty buddies card", `beauty buddy card clicked`, {
          beautyBuddyId: item?.id,
          screen: route?.name,
        });
        if (item?.celeb) {
          navigation.navigate(Pages.CelebScreen, {
            id: item?.id,
            username: item?.username,
          });
        } else {
          navigation.navigate("profile", {
            id: item?.id,
            username: item?.username,
          });
        }
      }}
    >
      <VStack
        w={cardWidth}
        h={"353px"}
        borderRadius={"15px"}
        p={"10px"}
        _light={{
          bg: "#FAF1FF",
          borderColor: "#E9B9FF",
        }}
        _dark={{
          bg: "#2B2F33",
        }}
        justifyContent={"space-between"}
      >
        <VStack space={"8px"}>
          <View>
            <Image
              rounded={"8px"}
              alt=""
              resizeMethod="scale"
              resizeMode={
                item?.image?.indexOf("http://") == 0 ||
                item?.image?.indexOf("https://") == 0
                  ? "cover"
                  : "contain"
              }
              h="120px"
              w={imageWidth}
              fallbackSource={require("./../../assets/images/pngs/profile-pic.png")}
              source={{
                uri: item?.image,
              }}
            />

            <Center
              borderBottomRadius={"8px"}
              w="full"
              bg="#202427:alpha.80"
              position={"absolute"}
              bottom={0}
              h="31px"
            >
              <Text
                _web={{
                  style: {
                    fontFamily: "AustinCyr_400",
                  },
                }}
                noOfLines={1}
                textAlign={"center"}
                color="white"
                fontSize={"16px"}
                style={{
                  fontFamily: "HK_500Medium",
                }}
              >
                {item?.username}
              </Text>
            </Center>
          </View>
          <HStack
            alignItems={"center"}
            h="20px"
            justifyContent={"space-between"}
          >
            {[item?.age, item?.ethnicity, item?.sunsign]?.map((k, i) => (
              <>
                {k ? (
                  <Text
                    _dark={{
                      color: "#94A3B8",
                    }}
                    _light={{
                      color: "#464646",
                    }}
                    noOfLines={1}
                    textAlign={"center"}
                    textTransform={"capitalize"}
                    fontSize={"10px"}
                    style={{
                      fontFamily: "HK_600SemiBold",
                    }}
                  >
                    {k}
                    {i === 0 && " Y / O"}
                  </Text>
                ) : (
                  <DashIcon />
                )}
                {i < 2 && <EllipseIcon />}
              </>
            ))}
          </HStack>
          <Center>
            <Image
              style={{ alignSelf: "center" }}
              source={require("../../assets/images/pngs/lineBorder.png")}
            />
          </Center>
        </VStack>

        {(item?.skin_type === undefined ||
          item?.skin_type === null ||
          item?.skin_type === "NA") &&
        (item?.hair_type === undefined ||
          item?.hair_type === null ||
          item?.hair_type === "NA" ||
          item?.hair_type.length <= 0) &&
        (item?.hair_texture === undefined ||
          item?.hair_texture === null ||
          item?.hair_texture === "NA") ? (
          <Text
            textAlign={"center"}
            textTransform={"capitalize"}
            color="#94A3B8"
            fontSize={"10px"}
            style={{
              fontFamily: "HK_400Regular",
            }}
          >
            No Beauty Buddy information{"\n"}available yet!
          </Text>
        ) : (
          <HStack
            space={"2"}
            justifyContent={"center"}
            alignItems="center"
            flexWrap={"wrap"}
          >
            {[
              item.skin_type === "Combo"
                ? "Combo Skin"
                : item?.skin_type?.replace(/\n|\r/g, " "),

              item?.hair_type?.filter((k, i) => k !== null).length > 0 &&
                `${item?.hair_type?.join(", ")} hair`,
              item?.hair_texture !== null && `${item?.hair_texture} hair`,
            ].map((k, i) => (
              <>
                {k && (
                  <Text
                    _web={{
                      style: {
                        fontFamily: "AustinCyr_400",
                      },
                    }}
                    textAlign={"center"}
                    textTransform={"capitalize"}
                    _light={{
                      color: "#682481",
                    }}
                    _dark={{
                      color: "white",
                    }}
                    fontSize={"11px"}
                    style={{
                      fontFamily: "HK_500Medium",
                    }}
                  >
                    {k}
                    {k && i < 2 && ","}
                  </Text>
                )}
              </>
            ))}
          </HStack>
        )}

        <Center>
          <Image
            style={{ alignSelf: "center" }}
            source={require("../../assets/images/pngs/lineBorder.png")}
          />
        </Center>
        <VStack space={1}>
          <Text
            _web={{
              style: {
                fontFamily: "Hero_700Bold",
              },
            }}
            letterSpacing={5}
            _light={{
              color: "#464646",
            }}
            _dark={{
              color: "#94A3B8",
            }}
            textAlign={"center"}
            style={{
              fontFamily: "HK_500Medium",
            }}
          >
            FAVOURITES
          </Text>
          {favorites?.length < 1 ? (
            <Text
              textAlign={"center"}
              textTransform={"capitalize"}
              color="#94A3B8"
              fontSize={"10px"}
              style={{
                fontFamily: "HK_400Regular",
              }}
            >
              No Favourites added yet!
            </Text>
          ) : (
            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
              <HStack space={2}>
                {favorites
                  ?.filter((f) => f !== null)
                  .map((k) => (
                    <TouchableWithoutFeedback
                      onPress={() => {
                        setAnalytics("Product Card", "Product Card Clicked", {
                          productId: k?.product?.id,
                          productSkuId: k?.id,
                          productName: k?.product_name,
                          brandName: k?.brand_name,
                          screen: route?.name,
                          type: "Favourites card",
                        });

                        navigation.navigate(Pages.ProductScreen, {
                          id: k?.product?.id,
                          sku_id: k?.id,
                        });
                      }}
                    >
                      <Image
                        alt=""
                        size={"50px"}
                        rounded="8px"
                        source={{
                          uri: k?.images[0],
                        }}
                      />
                    </TouchableWithoutFeedback>
                  ))}
              </HStack>
            </ScrollView>
          )}
        </VStack>
        <Center w="100%">
          <FollowButton followingId={item?.id} buttonBg={backgroundColor} />
        </Center>
      </VStack>
    </Pressable>
  );
};

export default BeautyBuddiesCard;
