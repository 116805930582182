import { Hidden, Stack, Text, VStack } from "native-base";
import { useSelector } from "react-redux";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import HeadingBar from "../../components/bars/headingBar";
import CartTotal from "./cartTotal";
import CartProducts from "./cartProducts";
import CartCard from "../../components/cards/cart/cartCard";
import CartScreenBottomBar from "../../components/bars/bottomBars/cartScreenBottomBar";
import { gradientTextWeb } from "../../constants/gradient";
import { CheckoutContext } from "../../hoc/CheckoutHOC";
import { useContext } from "react";
const CheckoutScreen = () => {
  const userId = useSelector((state) => state.user.id);
  const { product, cart } = useContext(CheckoutContext);
  return (
    <>
      <VStack
        space={{
          xl: "50px",
        }}
      >
        <HeadingBar>
          <Text
            fontSize={"36px"}
            _web={{
              style: {
                ...gradientTextWeb(1),
                fontFamily: "AustinCyr_400",
              },
            }}
          >
            Cart
          </Text>
        </HeadingBar>
        <Stack
          pt={{
            base: "40px",
            sm: "40px",
            md: "40px",
            lg: "40px",
            xl: "0px",
            xxl: "0px",
          }}
          justifyContent="center"
          space={{
            base: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 10,
            xxl: 10,
          }}
          px={{
            base: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: "80px",
            xxl: "80px",
          }}
          direction={{
            base: "column-reverse",
            sm: "column-reverse",
            md: "column-reverse",
            lg: "column-reverse",
            xl: "row",
            xxl: "row",
          }}
        >
          <Hidden from="base" till={"xl"}>
            <VStack
              w={{
                base: "full",
                sm: "full",
                md: "full",
                lg: "full",
                xl: "70%",
                xxl: "70%",
              }}
            >
              <CartProducts product={product} cart={cart} />
            </VStack>
          </Hidden>
          <Hidden only={["xl", "xxl"]}>
            <VStack space={4}>
              {cart
                ?.filter((k) => k.product_sku !== null)
                .map((item, index) => (
                  <CartCard
                    key={index}
                    variants={[
                      item?.product_sku?.product?.colors
                        ? {
                            label: "Color",
                            variant: item?.product_sku?.product?.colors,
                          }
                        : null,
                      item?.product_sku?.product?.size
                        ? {
                            label: "Size",
                            variant: item?.product_sku?.product?.size,
                          }
                        : null,
                      item?.product_sku?.product?.scent
                        ? {
                            label: "Scent",
                            variant: item?.product_sku?.product?.scent,
                          }
                        : null,
                      item?.product_sku?.product?.type
                        ? {
                            label: "Type",
                            variant: item?.product_sku?.product?.type,
                          }
                        : null,
                    ]}
                    previousNum={item?.number}
                    product={item?.product_sku}
                  />
                ))}
            </VStack>
          </Hidden>
          <VStack
            w={{
              base: "full",
              sm: "full",
              md: "full",
              lg: "full",
              xl: "30%",
              xxl: "30%",
            }}
            borderRadius={"10px"}
            borderWidth="1px"
            borderColor={"#FAF1FF"}
          >
            <CartTotal />
          </VStack>
        </Stack>
        <Hidden only={["xl", "xxl"]}>
          <CartScreenBottomBar />
        </Hidden>
        <Hidden from="base" till={"xl"}>
          <FooterSectionWeb />
        </Hidden>
      </VStack>
    </>
  );
};

export default CheckoutScreen;
