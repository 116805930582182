import {
  Center,
  HStack,
  Image,
  Input,
  PresenceTransition,
  Text,
  VStack,
  ScrollView,
  FlatList,
  Pressable,
} from "native-base";
import { useState } from "react";
import NopeButton from "../../components/buttons/nopeButton";
import SkipButton from "../../components/buttons/skipButton";
import YupButton from "../../components/buttons/yupButton";
import { useSelector } from "react-redux";
import { Pages } from "../../constants/pages";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import { useGetKeyboardStatus } from "../../hooks/useGetKeyboardStatus";
import { setAnalytics } from "../../analytics";
import InterestCard from "../../components/cards/interestCard";
import PressedPowder from "./../../assets/images/svgs/pressedPowder.svg";
import LoosePowder from "./../../assets/images/svgs/loosePowder.svg";
import CreamFoundation from "./../../assets/images/svgs/creamFoundation.svg";
import LiquidFoundation from "./../../assets/images/svgs/liquidFoundation.svg";
import CheckboxWhiteTickIcon from "../../components/icons/checkboxWhiteTickIcon";
import useGetUser from "../../hooks/useGetUser";
import SurveyScreenBottomBar from "../../components/bars/bottomBars/surveyScreenBottomBar";

const FoundationScreen = ({ navigation, route }) => {
  const userId = useSelector((state) => state.user.id);

  const { data } = useGetUser("profile", userId);

  const [foundationShade, setFoundationShade] = useState([
    {
      label: "Very Fair",
      isChecked: data?.user[0]?.foundation_shade === "Very Fair" ? true : false,
      color: "#FFEBDE",
      isPressed: data?.user[0]?.foundation_shade === "Very Fair" ? true : false,
      color: "#FFEBDE",
    },
    {
      label: "Fair",
      isChecked: data?.user[0]?.foundation_shade === "Fair" ? true : false,
      color: "#ECC9AB",
      isPressed: data?.user[0]?.foundation_shade === "Fair" ? true : false,
    },
    {
      label: "Medium",
      isChecked: data?.user[0]?.foundation_shade === "Medium" ? true : false,
      isPressed: data?.user[0]?.foundation_shade === "Medium" ? true : false,
      color: "#D8A387",
    },
    {
      label: "Tan",
      isChecked: data?.user[0]?.foundation_shade === "Tan" ? true : false,
      isPressed: data?.user[0]?.foundation_shade === "Tan" ? true : false,
      color: "#C08556",
    },
    {
      label: "Deep",
      isChecked: data?.user[0]?.foundation_shade === "Deep" ? true : false,

      isPressed: data?.user[0]?.foundation_shade === "Deep" ? true : false,
      color: "#895432",
    },
    {
      label: "Dark",
      isChecked: data?.user[0]?.foundation_shade === "Dark" ? true : false,
      isPressed: data?.user[0]?.foundation_shade === "Dark" ? true : false,

      color: "#44302D",
    },
  ]);

  const [nopeButtonPressed, setNopeButtonPressed] = useState(
    !data?.user[0]?.foundation_brand &&
      !data?.user[0]?.foundation_shade &&
      data?.user[0]?.foundation_coverage?.length === 0 &&
      data?.user[0]?.foundation_formulations?.length === 0
  );
  const [yupButtonPressed, setYupButtonPressed] = useState(
    data?.user[0]?.foundation_brand ||
      data?.user[0]?.foundation_shade ||
      data?.user[0]?.foundation_coverage?.length > 0 ||
      data?.user[0]?.foundation_formulations?.length > 0
  );
  const [brand, setBrand] = useState(data?.user[0]?.foundation_brand);
  const [shade, setShade] = useState(data?.user[0]?.foundation_shade);
  const editUserDetails = useEditUserDetails();

  const keyboardStatus = useGetKeyboardStatus();

  const [foundationCoverage, setFoundationCoverage] = useState([
    {
      label: "Light",
      clicked: data?.user[0]?.foundation_coverage?.find((k) => k === "Light")
        ? true
        : false,
    },
    {
      label: "Medium",
      clicked: data?.user[0]?.foundation_coverage?.find((k) => k === "Medium")
        ? true
        : false,
      isPressed: data?.user[0]?.foundation_coverage?.find((k) => k === "Medium")
        ? true
        : false,
    },
    {
      label: "Full",
      clicked: data?.user[0]?.foundation_coverage?.find((k) => k === "Full")
        ? true
        : false,
      isPressed: data?.user[0]?.foundation_coverage?.find((k) => k === "Full")
        ? true
        : false,
    },
  ]);

  const formulation = [
    {
      key: 0,
      icon: <LiquidFoundation />,
      label: "Liquid",
      clicked: data?.user[0]?.foundation_formulations?.find(
        (k) => k === "Liquid"
      )
        ? true
        : false,
    },
    {
      key: 1,
      icon: <CreamFoundation />,
      label: "Cream",
      clicked: data?.user[0]?.foundation_formulations?.find(
        (k) => k === "Cream"
      )
        ? true
        : false,
    },
    {
      key: 2,
      icon: <PressedPowder />,
      label: "Pressed \n Powder",
      clicked: data?.user[0]?.foundation_formulations?.find(
        (k) => k === "Pressed \n Powder"
      )
        ? true
        : false,
    },
    {
      key: 3,
      icon: <LoosePowder />,
      label: "Loose \n Powder",
      clicked: data?.user[0]?.foundation_formulations?.find(
        (k) => k === "Loose \n Powder"
      )
        ? true
        : false,
    },
  ];

  const [foundationFormulations, setFoundationFormulations] =
    useState(formulation);

  const handleRequest = async (text) => {
    if (text === "Skip") {
      setAnalytics(
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
        }
      );
    } else {
      const foundation_formulations = foundationFormulations
        .filter((el) => el.clicked)
        .map((el2) => el2.label);

      const foundation_coverage = foundationCoverage
        ?.filter((el) => el?.clicked)
        ?.map((el2) => el2?.label);

      const foundation_shade = foundationShade
        ?.filter((el) => el?.isPressed)
        ?.map((el2) => el2?.label);
      console.log(foundation_shade, foundationShade, "173");
      editUserDetails(
        {
          foundation_brand: brand ? brand.trim() : null,
          foundation_shade: foundation_shade ? foundation_shade[0] : null,
          foundation_formulations,
          foundation_coverage,
        },
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
          foundation_brand: brand,
          foundation_shade: shade,
          foundation_formulations,
          foundation_coverage,
          edited: data?.user[0]?.quiz_completed,
        }
      );
    }

    navigation.navigate("SpecificIngredientsScreen");
  };
  return (
    <>
      <ScrollView pt={4} px="2" showsVerticalScrollIndicator={false}>
        <VStack
          pb={"70px"}
          bg="#1C212E"
          rounded={16}
          pt={"56px"}
          w="full"
          space={"30px"}
          alignItems={"center"}
        >
          <Image
            height={{
              md: "80px",
              lg: "100px",
              xl: "130px",
            }}
            resizeMethod="scale"
            resizeMode="contain"
            source={require("./../../assets/images/pngs/foundationShade.png")}
            alt=""
          />

          <Text
            textAlign={"center"}
            color={"white"}
            fontSize={30}
            style={{
              fontFamily: "Poppins_500Medium",
            }}
            lineHeight={44}
          >
            Do you use a{"\n"} Foundation?
            <Image
              resizeMethod="scale"
              resizeMode="contain"
              source={require("./../../assets/images/pngs/thinkingFaceEmoticon.png")}
              alt=""
            />
          </Text>
          <HStack justifyContent={"center"} space={4}>
            <NopeButton
              onPress={() => {
                setNopeButtonPressed(true);
                setYupButtonPressed(false);
              }}
              nopeButtonPressed={nopeButtonPressed}
            />
            <YupButton
              onPress={() => {
                setYupButtonPressed(true);
                setNopeButtonPressed(false);
              }}
              yupButtonPressed={yupButtonPressed}
            />
          </HStack>
          {yupButtonPressed && (
            <PresenceTransition
              w="full"
              visible={yupButtonPressed}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 500,
                },
              }}
            >
              <VStack
                space={4}
                pb={
                  keyboardStatus === "Keyboard Hidden" ||
                  keyboardStatus === undefined
                    ? 0
                    : "72"
                }
              >
                {/* <VStack width={"full"} px={6} space={2}>
                  <Text
                    width={"full"}
                    fontSize={14}
                    style={{
                      fontFamily: "Poppins_400Regular",
                    }}
                    color="#94A3B8"
                    lineHeight={17}
                  >
                    What Brand?
                  </Text>
                  <Input
                    value={brand}
                    placeholderTextColor="#94A3B8"
                    width={"full"}
                    borderWidth={"2px"}
                    placeholder="Enter the brand name"
                    borderColor={"#575757"}
                    rounded={12}
                    autoCapitalize="none"
                    fontSize={16}
                    onChangeText={(val) => {
                      setBrand(val);
                    }}
                    style={{
                      fontFamily: "Poppins_400Regular",
                    }}
                    color="white"
                    _focus={{
                      borderColor: "#575757",
                      backgroundColor: "#1A1C1E",
                      color: "white",
                    }}
                  />
                </VStack> */}

                {/* Foundation Shade */}
                <VStack px={6} rounded="10px" space={"20px"} py={"20px"}>
                  <Text
                    // pl={3}
                    color={"#94A3B8"}
                    style={{
                      fontFamily: "HK_500Medium",
                    }}
                    fontSize={14}
                  >
                    Foundation Shade
                  </Text>
                  <FlatList
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    data={foundationShade}
                    numColumns={3}
                    renderItem={({ item: p, index: i }) => (
                      // <Center mx={5}>
                      <Pressable
                        mx={5}
                        mt={5}
                        onPress={() => {
                          let copy = foundationShade.map((i, j) => {
                            return {
                              ...foundationShade[j],
                              isPressed: false,
                            };
                          });
                          console.log(p, i);
                          copy[i] = { ...copy[i], isPressed: true };

                          setFoundationShade(copy);
                        }}
                      >
                        <Center
                          h="60px"
                          w={"60px"}
                          bg={{
                            linearGradient: p.isPressed
                              ? {
                                  colors: [
                                    "primary.ctaLinearSecondColor",
                                    "primary.ctaLinearFirstColor",
                                  ],
                                  start: [0, 0],
                                  end: [1, 0],
                                }
                              : {
                                  colors: ["#3B3B3B", "#3B3B3B"],
                                  start: [0, 0],
                                  end: [1, 0],
                                },
                          }}
                          rounded={"20px"}
                        >
                          <Center
                            h="57px"
                            w="57px"
                            // mx={"2px"}
                            // m={2}
                            mx={2}
                            // bg="#2B2F33"
                            bg={p.color}
                            rounded={"20px"}
                            // px={2}
                            // py={2}
                            // w={"95%"}
                          >
                            {p.isPressed ? (
                              <Image
                                source={require("../../assets/images/pngs/tick.png")}
                              />
                            ) : null}
                          </Center>
                        </Center>

                        <Center>
                          <Text
                            noOfLines={2}
                            textAlign={"center"}
                            color={p.isPressed ? "white" : "#94A3B8"}
                            style={{
                              fontFamily: "Poppins_500Medium",
                            }}
                            fontSize={14}
                          >
                            {p.label}
                          </Text>
                        </Center>
                      </Pressable>
                      // </Center>
                    )}
                  />
                  {/* <HStack
                    justifyContent="space-around"
                    flexWrap="wrap"
                    px="12px"
                    // space={"2px"}
                  >
                    {foundationShade.map((p, i) => (
                      <Pressable
                        onPress={() => {
                          let copy = foundationShade.map((i, j) => {
                            return {
                              ...foundationShade[j],
                              isPressed: false,
                            };
                          });

                          copy[i] = { ...copy[i], isPressed: true };

                          setFoundationShade(copy);
                        }}
                      >
                        <Center
                          h="80px"
                          w={"80px"}
                          bg={{
                            linearGradient: p.isPressed
                              ? {
                                  colors: [
                                    "primary.ctaLinearSecondColor",
                                    "primary.ctaLinearFirstColor",
                                  ],
                                  start: [0, 0],
                                  end: [1, 0],
                                }
                              : {
                                  colors: ["#3B3B3B", "#3B3B3B"],
                                  start: [0, 0],
                                  end: [1, 0],
                                },
                          }}
                          rounded={"20px"}
                        >
                          <Center
                            h="77px"
                            w="77px"
                            // mx={"2px"}
                            // m={2}
                            mx={2}
                            // bg="#2B2F33"
                            bg={p.color}
                            rounded={"20px"}
                            // px={2}
                            // py={2}
                            // w={"95%"}
                          >
                            {p.isPressed ? (
                              <Image
                                source={require("../../assets/images/pngs/tick.png")}
                              />
                            ) : null}
                          </Center>
                        </Center>
                   
                        <Center>
                          <Text
                            noOfLines={2}
                            textAlign={"center"}
                            color={p.isPressed ? "white" : "#94A3B8"}
                            style={{
                              fontFamily: "Poppins_500Medium",
                            }}
                            fontSize={14}
                          >
                            {p.label}
                          </Text>
                        </Center>
    
                      </Pressable>
                    ))}
                  </HStack> */}
                </VStack>

                {/* Foundation Shade End */}
                {/* 
                <VStack width={"full"} px={6} space={2}>
                  <Text
                    width={"full"}
                    fontSize={14}
                    style={{
                      fontFamily: "Poppins_400Regular",
                    }}
                    color="#94A3B8"
                    lineHeight={17}
                  >
                    What Shade?
                  </Text>
                  <Input
                    value={shade}
                    width={"full"}
                    borderWidth={"2px"}
                    placeholder="Enter the shade number"
                    borderColor={"#575757"}
                    placeholderTextColor="#94A3B8"
                    rounded={12}
                    autoCapitalize="none"
                    fontSize={16}
                    onChangeText={(val) => {
                      setShade(val);
                    }}
                    style={{
                      fontFamily: "Poppins_400Regular",
                    }}
                    color="white"
                    _focus={{
                      borderColor: "#575757",
                      backgroundColor: "#1A1C1E",
                      color: "white",
                    }}
                  />
                </VStack> */}

                <VStack width={"full"} px={6} space={2}>
                  <Text
                    width={"full"}
                    fontSize={14}
                    style={{
                      fontFamily: "Poppins_400Regular",
                    }}
                    color="#94A3B8"
                    lineHeight={17}
                  >
                    What kinda coverage/s do you use?
                  </Text>
                  <HStack alignItems={"center"} justifyContent="space-between">
                    {foundationCoverage.map((label, index) => (
                      <Pressable
                        onPress={() => {
                          let copy = [...foundationCoverage];
                          copy[index] = {
                            ...copy[index],
                            clicked: !label.clicked,
                          };

                          setFoundationCoverage(copy);
                        }}
                      >
                        <HStack space={"10px"} alignItems="center">
                          <Center
                            p={"5px"}
                            borderRadius={"2px"}
                            borderWidth={"1px"}
                            bg={label.clicked ? "info.600" : "#2B2F33"}
                            borderColor="#58595B"
                            size={"20px"}
                          >
                            {label.clicked && <CheckboxWhiteTickIcon />}
                          </Center>
                          <Text
                            fontSize={"16px"}
                            style={{
                              fontFamily: "HK_600SemiBold",
                            }}
                            color={label.clicked ? "white" : "#94A3B8"}
                          >
                            {label.label}
                          </Text>
                        </HStack>
                      </Pressable>
                    ))}
                  </HStack>
                </VStack>
                <VStack width={"full"} px={6} space={2}>
                  <Text
                    width={"full"}
                    fontSize={14}
                    style={{
                      fontFamily: "Poppins_400Regular",
                    }}
                    color="#94A3B8"
                    lineHeight={17}
                  >
                    And the formulation/s?
                  </Text>
                  <FlatList
                    showsVerticalScrollIndicator={false}
                    scrollEnabled={false}
                    data={foundationFormulations}
                    numColumns={2}
                    renderItem={({ item, index }) => (
                      <Center m={2}>
                        <InterestCard
                          interestName={item.label}
                          icon={item.icon}
                          onPress={() => {
                            let copy = [...foundationFormulations];
                            copy[index] = {
                              ...copy[index],
                              clicked: !item.clicked,
                            };
                            setFoundationFormulations(copy);
                          }}
                          cardPressed={item.clicked}
                        />
                      </Center>
                    )}
                  />
                </VStack>
              </VStack>
            </PresenceTransition>
          )}
        </VStack>
      </ScrollView>
      {
        // yupButtonPressed || nopeButtonPressed ? (
        <SurveyScreenBottomBar
          showSkipButton={true}
          // showSkipButton={!data?.user[0]?.quiz_completed}
          showNextButton={
            brand ||
            shade ||
            foundationFormulations.find((el) => el.clicked) ||
            foundationCoverage?.find((k) => k?.clicked) ||
            nopeButtonPressed
          }
          skipOnPress={() => {
            handleRequest("Skip");
          }}
          doLater={true}
          nextOnPress={() => {
            handleRequest("Next");
          }}
        />
        // ) : (
        //   <Center py={10}>
        //     <SkipButton
        //       onPress={() => {
        //         handleRequest("Skip");
        //       }}
        //     />
        //   </Center>
        // )
      }
    </>
  );
};

export default FoundationScreen;
