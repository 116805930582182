import { Button, Center, FlatList, VStack } from "native-base";
import { useDispatch } from "react-redux";
import useGetNumberOfColumns from "../../hooks/useGetNumberOfColumns";
import { showLoader } from "../../redux/currentState/actions";

const ColumnLayout = ({
  defaultNumberOfColumns,
  data,
  renderItem,
  setSize,
}) => {
  const numberOfColumns = useGetNumberOfColumns(defaultNumberOfColumns);
  const dispatch = useDispatch();
  return (
    <VStack h="full" w="full">
      <FlatList
        h="full"
        w="full"
        ListFooterComponent={
          <Center>
            {setSize && data && (
              <Button
                w="20%"
                _pressed={{
                  bg: "#FAF1FF",
                }}
                _hover={{
                  bg: "#FAF1FF",
                }}
                onPress={() => {
                  dispatch(showLoader());
                  setSize();
                }}
                variant={"ghost"}
                _text={{
                  color: "#464646",
                  fontFamily: "Hero_700Bold",
                  fontSize: "18px",
                }}
              >
                Load more
              </Button>
            )}
          </Center>
        }
        key={numberOfColumns}
        columnWrapperStyle={{
          justifyContent: numberOfColumns === 2 ? "center" : "flex-start",
        }}
        showsVerticalScrollIndicator={false}
        initialNumToRender={2}
        data={data}
        numColumns={numberOfColumns}
        renderItem={renderItem}
      />
    </VStack>
  );
};

export default ColumnLayout;
