export const Pages = {
  Login: "Login",
  BeautyBuddiesScreen: "BeautyBuddiesScreen",
  Home: "Home",
  FlockDeals: "Flock Deals", //add space b/w flock deals so that it appears on topbar
  Products: "Shop",
  Search: "Search",
  Cart: "Cart",
  Community: "Community",
  Profile: "Profile",
  JoinGroupScreen: "JoinGroupScreen",
  CreateGroupScreen: "CreateGroupScreen",
  FlockDealExitGroupScreen: "FlockDealExitGroupScreen",
  LetsRoll: "LetsRoll",
  AboutYourSelfScreen: "AboutYourSelfScreen",
  Foundation: "Foundation",
  SkinType: "SkinType",
  UnderstandingHair: "UnderstandingHair",
  Skills: "Skills",
  Scents: "Scents",
  Interests: "Interests",
  Yay: "Yay",
  SettingScreen: "SettingScreen",
  AboutFlockScreen: "AboutFlockScreen",
  PrivacyPolicyScreen: "PrivacyPolicyScreen",
  ReturnsPolicy: "ReturnsPolicy",
  Authenticity: "Authenticity",
  ShippingPolicy: "ShippingPolicy",
  Terms: "Terms",
  FAQScreen: "FAQScreen",
  HelpAndSupportScreen: "HelpAndSupportScreen",
  RewardsScreen: "RewardsScreen",
  NotificationsScreen: "NotificationsScreen",
  CategoryScreen: "CategoryScreen",
  OrderSuccessfulScreen: "ordersuccessful",
  CongratsScreen: "CongratsScreen",
  FlockDealPaymentInfo: "FlockDealPaymentInfo",
  PaymentInfoScreen: "PaymentInfoScreen",
  CartOrder: "CartOrder",
  FlockDealOrderInfo: "FlockDealOrderInfo",
  FlockDealAddress: "FlockDealAddress",
  ProductScreen: "product",
  Orders: "Orders",
  PaymentMethodsScreen: "PaymentMethodsScreen",
  AddressesScreen: "AddressesScreen",
  ProductsScreen: "ProductsScreen",
  AboutBrandScreen: "AboutBrandScreen",
  RegisterNumber: "RegisterNumber",
  RegisterEmail: "RegisterEmail",
  RegisterPassword: "RegisterPassword",
  RegisterName: "RegisterName",
  Survey: "Survey",
  UploadPictureScreen: "UploadPictureScreen",
  VerifyScreen: "VerifyScreen",
  ForgetPasswordScreen: "ForgetPasswordScreen",
  PhoneEmailSignupScreen: "PhoneEmailSignupScreen",
  CuratedForYouProfile: "CuratedForYouProfile",
  CelebScreen: "CelebScreen",
  CurlyHairFeedScreen: "CurlyHairFeedScreen",
  AllFlockDeals: "AllFlockDeals",
  FindFriendsSearchScreen: "FindFriendsSearchScreen",
  CheckoutScreen: "Checkout",
  UserScreen: "UserScreen",
  SocialLinksScreen: "SocialLinksScreen",
  SignupOptionsScreen: "SignupOptionsScreen",
  AffiliateScreen: "AffiliateScreen",
  FavoriteBrands: "FavoriteBrands",
  ShopScreen: "ShopScreen",
  Brands: "Brands",
  savedaddresses: "savedaddresses",
};
