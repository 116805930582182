import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../redux/currentState/actions";
import { publicApi } from "../util/API";
import * as RootNavigation from "../RootNavigation";
import { Pages } from "../constants/pages";
import { setAnalytics } from "../analytics";
import { useState } from "react";
import { Platform } from "react-native";

export const useVerifyOtpEmailSignup = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [shouldProceed, setShouldProceed] = useState();
  const verifyOtpEmailSignup = async (email, code, type) => {
    dispatch(showLoader());
    let res = await publicApi(
      "/api/users/verify_otp_registration/",
      {
        email: email,
        code: code,
      },
      "post"
    );
    console.log("useVerifyOtpEmailSignup", res, email, code);
    if (res.body.status === 200) {
      if (Platform.OS === "web") {
        setShouldProceed(true);
      } else {
        RootNavigation.navigate(Pages.RegisterPassword, {
          type: type,
        });
      }
    } else if (res.body.status === 400) {
      setError(res.body.message);
    }
    setAnalytics(
      "Verify Email Otp While Signup",
      `Email Otp While Signup ${
        res.body.status === 200 ? "Verified" : "Verification Failed"
      }`,
      {
        email: email,
        status: res.body.status,
        message: res.body.message,
      }
    );
    dispatch(hideLoader());
  };

  return { verifyOtpEmailSignup, error, shouldProceed, setShouldProceed };
};
