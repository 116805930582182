export const Popups = {
  FlockDeal: "FlockDeal",
  Filter: "Filter",
  ProductsFilter: "ProductsFilter",
  ClaimFlockDeal: "ClaimFlockDeal",
  FlockDealExit: "FlockDealExit",
  AddAddress: "AddAddress",
  WhatIsFlockDeal: "WhatIsFlockDeal",
  AddCard: "AddCard",
  ListAddress: "ListAddress",
  ListCards: "ListCards",
  JoinFlockDeal: "JoinFlockDeal",
  LoginPopup: "Login",
  LearnMoreModal: "LearnMoreModal",
  Error: "Error",
  FillDetails: "FillDetails",
  DeleteAccountModal: "DeleteAccountModal",
  RewardsInfoModal: "RewardsInfoModal",
  EditCollectionScreenProductCard: "EditCollectionScreenProductCard",
  CommunityFilterPopUp: "CommunityFilterPopUp",
  BuyMoreSaveMoreModal: "BuyMoreSaveMoreModal",
  SocialLinksPopup: "SocialLinksPopup",
  NetworkPopUp: "NetworkPopUp",
  RefferalModal: "RefferalModal",
  RedeemEarningsModal: "RedeemEarningsModal",
  LoginSignUpModal: "LoginSignUpModal",
  ShippingAddressModal: "ShippingAddressModal",
  SavedAddressesPopup: "savedAddressesPopup",
};
