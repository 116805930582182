import { useSelector } from "react-redux";
import ShippingAddressPopUp from "./shippingAddressPopUp";
import WhatIsAFlockDeal from "../modals/whatIsAFlockDeal";
import FilterPopUp from "./../popUps/filterPopUp/filterPopUp";
import ProductsFilterPopUp from "./productsFilterPopUp/filterPopUp";
import AddressPopUp from "./addressPopUp";
import CardsPopUp from "./cardsPopUp";
import LoginPopup from "./loginPopup";
import LearnMoreModal from "../modals/learnMoreModal";
import ErrorPopUp from "./errorPopUp";
import FillDetailsPopUp from "./fillDetailsPopup";
import DeleteAccountModal from "../modals/deleteAccountModal";
import RewardsInfoModal from "../modals/rewardsInfoModal";
import EditCollectionScreenProductCard from "./editCollectionScreenProductCard";
import CommunityFilterPopUp from "./communityFilterPopUp/communityFilterPopUp";
import BuyMoreSaveMoreModal from "../modals/buyMoreSaveMoreModal";
import SocialLinksPopup from "./socialLinksPopup";
import NetworkPopUp from "./networkPopUp";
import RefferalModal from "../modals/refferalModal";
import PaymentMethodPopUp from "./paymentMethodPopUp";
import RedeemEarningsModal from "../modals/redeemEarningsModal";
import LoginSignUpModal from "../modals/loginSignup/loginSignUpModal";
import ShippingAddressModal from "../modals/shippingAddressModal";
import { Platform } from "react-native";
import Login from "../../screens/login/login";
import * as RootNavigation from "../../RootNavigation";
import { Pages } from "../../constants/pages";

const Popup = () => {
  const openDialog = useSelector((state) => state.currentState.openDialog);
  const dialog = useSelector((state) => state.currentState.dialog);
  const userId = useSelector((state) => state.user.id);
  const navigation = RootNavigation;
  const LoggedInMapping = {
    FlockDeal: false,
    JoinFlockDeal: false,
    Filter: false,
    ProductsFilter: false,
    ClaimFlockDeal: false,
    FlockDealExit: true,
    AddAddress: true,
    WhatIsFlockDeal: false,
    AddCard: true,
    ListAddress: true,
    ListCards: true,
    Login: false,
    LearnMoreModal: false,
    Error: false,
    SocialLinksPopup: false,
    FillDetails: true,
    EditCollectionScreenProductCard: false,
    RewardsInfoModal: false,
    CommunityFilterPopUp: false,
    BuyMoreSaveMoreModal: false,
    NetworkPopUp: false,
    RefferalModal: true,
    RedeemEarningsModal: true,
    LoginSignUpModal: false,
    ShippingAddressModal: true,
  };

  const Mapping = {
    Filter: <FilterPopUp />,
    ProductsFilter: <ProductsFilterPopUp />,
    AddAddress: <ShippingAddressPopUp />,
    WhatIsFlockDeal: <WhatIsAFlockDeal />,
    AddCard: <PaymentMethodPopUp />,
    ListAddress: <AddressPopUp userId={userId} />, //have to pass id pop ups are not part of navigator or screen
    ListCards: <CardsPopUp userId={userId} />, //have to pass id pop ups are not part of navigator or screen
    Login: <LoginPopup />,
    LearnMoreModal: <LearnMoreModal />,
    FillDetails: <FillDetailsPopUp />,
    Error: <ErrorPopUp />,
    SocialLinksPopup: <SocialLinksPopup />,
    DeleteAccountModal: <DeleteAccountModal />,
    RewardsInfoModal: <RewardsInfoModal />,
    EditCollectionScreenProductCard: <EditCollectionScreenProductCard />,
    CommunityFilterPopUp: <CommunityFilterPopUp />,
    BuyMoreSaveMoreModal: <BuyMoreSaveMoreModal />,
    NetworkPopUp: <NetworkPopUp />,
    RefferalModal: <RefferalModal />,
    RedeemEarningsModal: <RedeemEarningsModal />,
    LoginSignUpModal: <LoginSignUpModal />,
    ShippingAddressModal: <ShippingAddressModal />,
  };

  return (
    <>
      {openDialog === true && userId && userId !== null ? (
        Mapping[dialog] ? (
          Mapping[dialog]
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {openDialog === true &&
      (userId === undefined || userId === null) &&
      LoggedInMapping[dialog] === true ? (
        Platform.OS === "web" ? (
          navigation.navigate(Pages.Login)
        ) : (
          <LoginPopup />
        )
      ) : (
        <></>
      )}
      {openDialog === true &&
      (userId === undefined || userId === null) &&
      LoggedInMapping[dialog] === false ? (
        Mapping[dialog] ? (
          Mapping[dialog]
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
};
export default Popup;
