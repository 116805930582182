import { useContext } from "react";
import { Center, Input, Stack, Text, VStack } from "native-base";
import { PhoneLoginContext } from "../../hoc/PhoneLoginHOC";
import PrimaryCTAButton from "../buttons/primaryCTAButton";
import Error from "../error";
import OTP from "../otp";
const PhoneLoginForm = () => {
  const {
    error,
    handleInput,
    handlePhoneLogin,
    countryCode,
    sendOtp,
    otpError,
    shouldMoveToPhoneLogin,
    first,
    second,
    third,
    fourth,
    fifth,
    sixth,
    setFirst,
    setSecond,
    setThird,
    setFourth,
    setFifth,
    setSixth,
  } = useContext(PhoneLoginContext);
  return (
    <VStack space={"20px"}>
      <VStack space={"6px"}>
        <Text
          letterSpacing={3}
          textTransform="uppercase"
          color={"#682481"}
          fontSize="11px"
          style={{
            fontFamily: "Hero_700Bold",
          }}
        >
          Mobile Number*
        </Text>
        <Input
          maxLength={10}
          leftElement={
            <Stack direction={"row"} pl={4}>
              +{countryCode}
            </Stack>
          }
          px="22px"
          py="13px"
          w={{
            md: "full",
            lg: "full",
            xl: "303px",
          }}
          h="39px"
          color="#464646"
          variant="outline"
          focusOutlineColor="#682481"
          _focus={{
            bg: "none",
            color: "#464646",
          }}
          _hover={{
            bg: "none",
            color: "#464646",
            borderColor: "#682481",
          }}
          style={{
            fontFamily: "Hero_700Bold",
          }}
          borderRadius="7px"
          borderColor="#C1C9D2"
          fontSize="14px"
          placeholderTextColor={"#C1C9D2"}
          placeholder={"98765432100"}
          borderWidth={"1px"}
          onChangeText={(val) => {
            handleInput("phone_number")(val);
          }}
        ></Input>
      </VStack>
      {!otpError && shouldMoveToPhoneLogin && (
        <VStack space={"6px"}>
          <Text
            letterSpacing={3}
            textTransform="uppercase"
            color={"#682481"}
            fontSize="11px"
            style={{
              fontFamily: "Hero_700Bold",
            }}
          >
            Enter Code
          </Text>
          <OTP
            textColor="#464646"
            h="38px"
            w="38px"
            borderColor="#682481"
            first={first}
            second={second}
            third={third}
            fourth={fourth}
            fifth={fifth}
            sixth={sixth}
            setFirst={(val) => setFirst(val)}
            setSecond={(val) => setSecond(val)}
            setThird={(val) => setThird(val)}
            setFourth={(val) => setFourth(val)}
            setFifth={(val) => setFifth(val)}
            setSixth={(val) => setSixth(val)}
          />
        </VStack>
      )}
      {(error || otpError) && <Error error={error || otpError} />}
      <Center h="36px">
        <PrimaryCTAButton
          onPress={() => {
            if (shouldMoveToPhoneLogin) {
              handlePhoneLogin(first + second + third + fourth + fifth + sixth);
            } else {
              sendOtp(true);
            }
          }}
          gradientType="2"
        >
          <Text
            letterSpacing={2}
            textTransform={"uppercase"}
            fontSize={"11px"}
            style={{
              fontFamily: "Hero_700Bold",
            }}
            color="white"
          >
            {shouldMoveToPhoneLogin ? "Login" : "Next"}
          </Text>
        </PrimaryCTAButton>
      </Center>
    </VStack>
  );
};

export default PhoneLoginForm;
