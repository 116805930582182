import Icon from "./../../assets/images/svgs/gradientPercentOff.svg";
import { Image } from "native-base";
import { Platform } from "react-native";
const GradientPercentOff = () => {
  return (
    <>
      {Platform.OS === "web" ? (
        <Image
          size={"12"}
          resizeMethod="scale"
          resizeMode="contain"
          source={require("./../../assets/images/svgs/gradientPercentOff.svg")}
          alt=""
        />
      ) : (
        <Icon />
      )}
    </>
  );
};

export default GradientPercentOff;
