import { Center, PresenceTransition } from "native-base";
import { useState } from "react";
import FlockDealCard from "../../screens/homeScreen/buildingBlocks/flockDealCard";
import FlockDealSteps from "../../screens/homeScreen/buildingBlocks/flockDealSteps";

const WhatIsAFlockDealDropDown = ({ bg, modal }) => {
  const [showDealSteps, setShowDealSteps] = useState(modal);
  return (
    <>
      <Center>
        <Center
          borderRadius={"20px"}
          bg={bg}
          w={{
            md: "348px",
            lg: "368px",
            xl: "398px",
          }}
        >
          <FlockDealCard
            showDealSteps={showDealSteps}
            setShowDealSteps={(val) => setShowDealSteps(val)}
          />
          {showDealSteps && (
            <PresenceTransition
              visible={showDealSteps}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 500,
                },
              }}
            >
              <FlockDealSteps />
            </PresenceTransition>
          )}
        </Center>
      </Center>
    </>
  );
};

export default WhatIsAFlockDealDropDown;
