import { Icon } from "native-base";
import { Path, G, Defs, LinearGradient, Stop } from "react-native-svg";

const WebGradientOutlineWishlistIcon = ({ color = "#FAF1FF" }) => {
  return (
    <Icon viewBox="0 0 28 26" size={8}>
      <Path
        d="M14.775 23.9148C14.35 24.0648 13.65 24.0648 13.225 23.9148C9.6 22.6773 1.5 17.5148 1.5 8.76484C1.5 4.90234 4.6125 1.77734 8.45 1.77734C10.725 1.77734 12.7375 2.87734 14 4.57734C15.2625 2.87734 17.2875 1.77734 19.55 1.77734C23.3875 1.77734 26.5 4.90234 26.5 8.76484C26.5 17.5148 18.4 22.6773 14.775 23.9148Z"
        stroke="url(#paint0_linear_218_483)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill={color}
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_218_483"
          x1="1.5"
          y1="1.77734"
          x2="32.8782"
          y2="14.8545"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stop-color="#691DE3" />
          <Stop offset="0.0001" stop-color="#691DE3" />
          <Stop offset="1" stop-color="#FF5A92" />
        </LinearGradient>
      </Defs>
    </Icon>
  );
};

export default WebGradientOutlineWishlistIcon;
