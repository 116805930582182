import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const LockIcon = () => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}

      <Icon viewBox="0 0 16 20">
        <G
          width="16"
          height="20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M8 0C5.243 0 3 2.243 3 5V8H2C0.897 8 0 8.897 0 10V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V10C16 8.897 15.103 8 14 8H13V5C13 2.243 10.757 0 8 0ZM14 10L14.002 18H2V10H14ZM5 8V5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5Z"
            fill="#888990"
          />
        </G>
      </Icon>
    </>
  );
};

export default LockIcon;
