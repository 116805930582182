import { Image, useColorMode } from "native-base";

const WavyHairTextureIcon = () => {
  const { colorMode } = useColorMode();

  return (
    <Image
      width={62}
      height={70}
      resizeMethod="scale"
      resizeMode="contain"
      source={
        colorMode === "dark"
          ? require("./../../../../assets/images/pngs/grayWavyHairTexture.png")
          : require("./../../../../assets/images/pngs/lightinactivewavy.png")
      }
      alt=""
    />
  );
};

export default WavyHairTextureIcon;
