import { useRoute } from "@react-navigation/native";
import { Divider, Image, Stack, Text, VStack } from "native-base";
import { BrandImages } from "../../../constants/brands";

const Description = ({ product }) => {
  const route = useRoute();
  return (
    <>
      <VStack
        w="80%"
        borderWidth={"1px"}
        borderColor="#E9B9FF"
        borderRadius="20px"
        p={"90px"}
        space={4}
      >
        <Stack
          space={6}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
          alignItems={{
            base: "center",
            sm: "center",
            md: "center",
            lg: "center",
            xl: "flex-start",
          }}
          justifyContent="center"
        >
          <Image
            size={"250px"}
            rounded="full"
            resizeMethod="scale"
            resizeMode="contain"
            source={{
              uri: BrandImages[product?.variant?.brand_name],
            }}
            alt=""
          />
          <Text
            color="#464646"
            fontSize={"14px"}
            lineHeight={"20px"}
            style={{
              fontFamily: "LeituraNewsRoman",
            }}
          >
            {product?.details?.description}
          </Text>
          <Image
            borderRadius={"10px"}
            h="250px"
            w="237px"
            resizeMethod="scale"
            resizeMode="contain"
            source={{
              uri: product?.skus[route?.params?.sku_id]?.images[0],
            }}
            alt=""
          />
        </Stack>
        <Divider bg="#E9B9FF" />
        <Stack
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
          alignItems={{
            base: "center",
            sm: "center",
            md: "center",
            lg: "center",
            xl: "flex-start",
          }}
          justifyContent="center"
          space={4}
        >
          {[
            { label: "how to use", content: product?.details?.instructions },
            { label: "ingredients", content: product?.variant?.ingredients },
          ]?.map((k, i) => (
            <VStack w="50%" space={4}>
              <Text
                textTransform={"uppercase"}
                letterSpacing={2}
                color="#464646"
                fontSize={"14px"}
                lineHeight={"20px"}
                style={{
                  fontFamily: "Hero_700Bold",
                }}
              >
                {k?.label}
              </Text>
              <Text
                color="#464646"
                fontSize={"14px"}
                lineHeight={"20px"}
                style={{
                  fontFamily: "LeituraNewsRoman",
                }}
              >
                {k?.content}
              </Text>
            </VStack>
          ))}
        </Stack>
      </VStack>
    </>
  );
};

export default Description;
