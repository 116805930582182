export const GRADIENTMAP = {
  1: ["primary.ctaLinearSecondColor", "primary.ctaLinearFirstColor"],
  2: ["primary.ctaLinearFirstColor", "primary.ctaLinearSecondColor"],
};

export const gradientTextWeb = (gradientType) => {
  return {
    backgroundImage:
      gradientType === 1
        ? "linear-gradient(#691DE3, #FD6699)"
        : gradientType === 2
        ? "linear-gradient(to right, #FD6699, #691DE3)"
        : gradientType === 3 && "linear-gradient(to right, #8F49FF, #FF4483)",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    color: "transparent",
  };
};
