import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const FilledWhiteBellIcon = () => {
  return (
    <Icon viewBox="0 0 36 36" size={"36px"}>
      <G width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M18.0028 27C19.0182 27 19.849 26.1692 19.849 25.1538H16.1567C16.1567 25.6435 16.3512 26.1131 16.6974 26.4593C17.0436 26.8055 17.5132 27 18.0028 27ZM23.5413 21.4615V16.8462C23.5413 14.0123 22.0274 11.64 19.3874 11.0123V10.3846C19.3874 9.61846 18.769 9 18.0028 9C17.2367 9 16.6182 9.61846 16.6182 10.3846V11.0123C13.969 11.64 12.4643 14.0031 12.4643 16.8462V21.4615L11.2736 22.6523C10.692 23.2338 11.0982 24.2308 11.9197 24.2308H24.0767C24.8982 24.2308 25.3136 23.2338 24.732 22.6523L23.5413 21.4615Z"
          fill="white"
        />
      </G>
    </Icon>
  );
};

export default FilledWhiteBellIcon;
