import { Center, FlatList, Text, VStack } from "native-base";
import { useState } from "react";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import SearchBox from "../../components/popUps/filterPopUp/buildingBlocks/searchBox.js";
import { gradientTextWeb } from "../../constants/gradient.js";
import SearchHOC from "../../hoc/SearchHOC.js";
import useResetFilter from "../../hooks/useResetFilter.js";
import SearchResults from "./buildingBlocks/searchResults.js";
import TopSearches from "./buildingBlocks/topSearches.js";
const SearchScreen = ({ navigation }) => {
  useResetFilter();
  const [query, setQuery] = useState();
  return (
    <>
      <VStack bg="#FAF1FF" py={12} w="full" space={"30px"} alignItems="center">
        <Text
          fontSize={"36px"}
          _web={{
            style: {
              ...gradientTextWeb(1),
              fontFamily: "AustinCyr_400",
            },
          }}
        >
          Search
        </Text>
        <Center w="250px" h="40px">
          <SearchBox
            onChange={async (val) => {
              setQuery(val);
            }}
          />
        </Center>
      </VStack>

      <SearchHOC searchType="allSearch" query={query}>
        <SearchResults />
      </SearchHOC>
      <FooterSectionWeb />
    </>
  );
};

export default SearchScreen;
