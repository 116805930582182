export const SkinConcerns = {
  1: "Oily Skin",
  2: "Combination Skin",
  3: "Normal Skin",
  4: "Dry Skin",
  5: "Dullness/Uneven Texture",
  6: "Dark Circles",
  7: "Anti Aging",
  8: "Loss of firmness",
  9: "Plumping",
  10: "Redness",
  11: "Acne/Blemishes",
  12: "Pores",
  13: "Dark Spots",
  14: "UV Protection",
};
