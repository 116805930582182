import { Center, Image } from "native-base";

const RewardsAnimatedIcon = ({ size = 4 }) => {
  return (
    <Image
      size={size}
      resizeMethod="scale"
      resizeMode="contain"
      source={require("./../../../assets/images/gifs/rewardsCoin.gif")}
      alt=""
    />
  );
};

export default RewardsAnimatedIcon;
