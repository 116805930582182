import { HStack, IconButton, Pressable, VStack } from "native-base";
import { Pages } from "../../../constants/pages";
//import FlockLightLogo from "./../../../assets/images/svgs/FlockLight1.svg";
import { useNavigation, useRoute } from "@react-navigation/native";
import BellAndCartIcon from "../../bellAndCartIcon";
import { setAnalytics } from "../../../analytics";
import ChevronLeftIcon from "../../icons/outlinedIcons/chevronLeftIcon";
const TopBar = () => {
  const navigation = useNavigation();
  const route = useRoute();
  return (
    <>
      <VStack
        zIndex={4}
        w="full"
        justifyContent={"flex-end"}
        pt={{
          md: "50px",
          lg: "54px",
          xl: "56px",
        }}
        pb={"16px"}
      >
        <HStack
          px={4}
          h={"24px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {route?.name === "AffiliateScreen" ? (
            <>
              <IconButton
                rounded={"lg"}
                bg="white:alpha.20"
                size={"36px"}
                onPress={async () => {
                  setAnalytics("Back Button Top Bar", "Back Button Clicked", {
                    screen: route?.name,
                  });
                  navigation.goBack();
                }}
                icon={<ChevronLeftIcon />}
              />
            </>
          ) : (
            <>
              <Pressable onPress={() => navigation.navigate(Pages.Home)}>
                {/* <FlockLightLogo /> */}
              </Pressable>
              <BellAndCartIcon />
            </>
          )}
        </HStack>
      </VStack>
    </>
  );
};

export default TopBar;
