import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useSWR from "swr";
import { getHeaders, parse } from "../util/utilFunctions";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const fetcher = async ([url, type]) =>
  axios
    .get(url, {
      headers: type === "profile" ? await getHeaders() : {},
      crossDomain: true,
    })
    .then((res) => {
      return parse(res.data);
    });

export default function useGetUser(type, id) {
  const getKey = () => {
    return [
      id
        ? type === "profile"
          ? `${apiUrl}/api/users/get_user_details/?user_id=${id}`
          : `${apiUrl}/api/users/get_person_details/?user_id=${id}`
        : null,
      type,
    ];
  };
  const { data, mutate, isValidating, error } = useSWR(getKey, fetcher, {
    revalidateFirstPage: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  useCheckNetwork(error);
  useLoader(isValidating);
  return { data, mutate, isValidating, error };
}
