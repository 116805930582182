import { publicApi } from "../util/API";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../redux/currentState/actions";
import { useState } from "react";
import { Keyboard, Platform } from "react-native";
import * as RootNavigation from "./../RootNavigation";
import { Pages } from "../constants/pages";

export const useSendEmailRegistrationOtp = () => {
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const [shouldProceed, setShouldProceed] = useState();
  const form = useSelector((state) => state.form);
  const navigation = RootNavigation;
  const sendEmailRegistrationOtp = async () => {
    Keyboard.dismiss();
    console.log("sendEmailRegistrationOtp");
    if (Platform.OS !== "web") {
      if (!form?.email && !form?.username) {
        setError("Fill credentials");
        return;
      } else if (!form?.email) {
        setError("Fill email address");
        return;
      } else if (!form?.username) {
        setError("Fill username");
        return;
      }
    }

    if (form?.email) {
      if (form?.email && !/\s/g.test(form?.email)) {
        dispatch(showLoader());

        try {
          let res = await publicApi(
            "/api/users/send_otp_registration/",
            {
              email: form?.email,
            },
            "post"
          );
          console.log("useSendEmailRegistrationOtp", res);
          if (res.body.status === 200) {
            if (Platform.OS !== "web") {
              navigation.navigate(Pages.VerifyScreen);
            }
            setShouldProceed(true);
          } else if (res.body.status_code === 404) {
            setError(res.body.message);
          }
        } catch (error) {
          console.log("catch", error);
        }
        dispatch(hideLoader());
      }
    } else if (/\s/g.test(form?.email)) {
      setError("Please check email and remove spaces, if any");
    } else {
      setError("Please enter email");
    }
  };

  return { sendEmailRegistrationOtp, error, setError, shouldProceed };
};
