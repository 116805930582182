import { HStack, Image, Text, Pressable } from "native-base";
import { useGetProductDetails } from "../../hooks/useGetProductDetails";
import { useNavigation } from "@react-navigation/native";
import { Pages } from "../../constants/pages";
import { setAnalytics } from "../../analytics";

const ProductNotificationCard = ({ notification }) => {
  const navigation = useNavigation();

  const { product } = useGetProductDetails(notification?.item_id);

  return (
    <>
      <Pressable
        onPress={async () => {
          setAnalytics("In app notification", "In app notification clicked", {
            id: notification?.item_id,
            productId: product?.details?.id,
            productSkuId: product?.details?.default_sku,
            seen: notification?.seen,
            type: notification?.type,
          });
          navigation.navigate(Pages.ProductScreen, {
            id: product?.details?.id,
            sku_id: product?.details?.default_sku,
          });
        }}
      >
        <HStack
          space={3}
          px={2}
          py={4}
          bg={notification?.seen ? "black" : "#202427"}
        >
          <Image
            alt=""
            size={"32px"}
            rounded="full"
            resizeMethod="scale"
            resizeMode="contain"
            fallbackSource={require("./../../assets/images/pngs/flockAvatar.png")}
            source={{
              uri:
                product?.details?.images?.length > 0
                  ? product?.details?.images[0]
                  : "./../../assets/images/pngs/flockAvatar.png",
            }}
          ></Image>

          <Text
            flex={1}
            color={"white"}
            opacity={notification?.seen ? 0.6 : 1}
            fontSize={"14px"}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
          >
            {notification.message}
          </Text>
        </HStack>
      </Pressable>
    </>
  );
};

export default ProductNotificationCard;
