import {
  RESET_FILTERS,
  CHANGE_FILTER,
  SET_AGE,
  SET_APPLY_FILTER,
  RESET_CACHE_FILTERS,
} from "./constants";

const initialState = {
  cachedFilters: {
    // ageRangeFrom: 20,
    // ageRangeTo: 60,
    hair_type: [],
    hair_texture: [],
    ethnicity: [],
    skin_type: [],
  },
  // ageRangeFrom: 20,
  // ageRangeTo: 60,
  hair_type: [],
  hair_texture: [],
  ethnicity: [],
  skin_type: [],
};

const communityFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REFRESH":
      return {
        cachedFilters: {
          // ageRangeFrom: 20,
          // ageRangeTo: 60,
          hair_type: [],
          hair_texture: [],
          ethnicity: [],
          skin_type: [],
        },
        // ageRangeFrom: 20,
        // ageRangeTo: 60,
        hair_type: [],
        hair_texture: [],
        ethnicity: [],
        skin_type: [],
      };
    case RESET_FILTERS:
      return {
        cachedFilters: {
          // ageRangeFrom: 20,
          // ageRangeTo: 60,
          hair_type: [],
          hair_texture: [],
          ethnicity: [],
          skin_type: [],
        },
        // ageRangeFrom: 20,
        // ageRangeTo: 60,
        hair_type: [],
        hair_texture: [],
        ethnicity: [],
        skin_type: [],
      };
    case SET_AGE:
      return {
        ...state,
        cachedFilters: {
          ...state.cachedFilters,
          // ageRangeFrom: action.low,
          // ageRangeTo: action.high,
        },
      };

    case SET_APPLY_FILTER:
      return {
        ...state.cachedFilters,
        cachedFilters: {
          ...state.cachedFilters,
        },
      };

    case RESET_CACHE_FILTERS:
      return {
        ...state,
        cachedFilters: {
          // ageRangeFrom: state.ageRangeFrom,
          // ageRangeTo: state.ageRangeTo,
          hair_texture: state.hair_texture,
          hair_type: state.hair_type,
          skin_type: state.skin_type,
          ethnicity: state.ethnicity,
        },
      };

    case CHANGE_FILTER:
      return {
        ...state,
        cachedFilters: {
          ...state.cachedFilters,
          ...action.filter,
        },
      };

    default:
      return state;
  }
};

export default communityFilterReducer;
