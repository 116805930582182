import { useGetProductDetails } from "../../hooks/useGetProductDetails";

import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import {
  Center,
  HStack,
  Image,
  Pressable,
  ScrollView,
  Stack,
  Text,
  VStack,
} from "native-base";
import { BrandImages } from "../../constants/brands";
import QuantityButton from "../../components/buttons/quantityButton";
import QuantityButtonHOC from "../../hoc/QuantityButtonHOC";
import PillGradientButton from "../../components/buttons/pillGradientButton";
import AddToCartHOC from "../../hoc/AddToCartHOC";
import AddToCartButton from "../../components/buttons/addToCartButton";
import WishlistHOC from "../../hoc/WishlistHOC";
import ProductScreenWishlistButton from "../../components/buttons/productScreenWishlistButton";
import FullWidthAccordion from "../../components/accordions/fullWidthAccordion";
import Description from "./buildingBlocks/description";
import { useState } from "react";
import { GRADIENTMAP } from "../../constants/gradient";
import Reviews from "./buildingBlocks/reviews";
import { setAnalytics } from "../../analytics";
import { useSelector } from "react-redux";
import { Pages } from "../../constants/pages";
import useGetCartItems from "../../hooks/useGetCartItems";
import CopyLinkPopover from "../../components/popovers/copyLinkPopover";
import ShareButton from "../../components/buttons/shareButton";
import GradientInstaIcon from "../../components/icons/gradientInstaIcon";
import ShareIcons from "../../components/shareIcons";

const ProductScreen = ({ route, navigation }) => {
  const referralId = useSelector((state) => state.currentState.referralId);
  const { product } = useGetProductDetails(route?.params?.id);
  const { data } = useGetCartItems(userId);
  const userId = useSelector((state) => state.user.id);
  const inCart =
    data?.length > 0 &&
    data
      ?.map((i) => i?.product_sku?.id)
      .find((k) => k === route?.params?.sku_id);
  const [image, setImage] = useState(
    product?.skus[route?.params?.sku_id]?.images[0]
  );
  const [buttonType, setButtonType] = useState("reviews");
  console.log("productscreen", product);
  const labels = [
    inCart
      ? {
          label: "Quantity",
          component: (
            <Center h="40px" w="150px">
              <QuantityButtonHOC>
                <QuantityButton skuId={route?.params?.sku_id} />
              </QuantityButtonHOC>
            </Center>
          ),
        }
      : null,
    {
      label: "Sku",
      component: (
        <Text
          _light={{
            color: "#464646",
          }}
          _dark={{
            color: "#94A3B8",
          }}
          fontSize={"16px"}
          style={{
            fontFamily: "Hero_700Bold",
          }}
        >
          {route?.params?.sku_id}
        </Text>
      ),
    },
    product?.sizes && product?.sizes?.length > 1
      ? {
          label: "Size",
          component: (
            <FullWidthAccordion
              label={"Size"}
              items={product?.sizes}
              val={product?.skus[route?.params?.sku_id]?.size}
            />
          ),
        }
      : null,
    product?.colors && product?.colors?.length > 1
      ? {
          label: "Color",
          component: (
            <FullWidthAccordion
              label={"Color"}
              items={product?.colors}
              val={product?.skus[route?.params?.sku_id]?.color}
            />
          ),
        }
      : null,
    {
      label: "Share",
      component: <ShareIcons />,
    },
  ];

  return (
    <VStack space={"100px"}>
      {/* <ShareButton /> */}
      <Stack
        direction={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "column",
          xl: "row",
        }}
        alignItems={{
          base: "center",
          sm: "center",
          md: "center",
          lg: "center",
          xl: "flex-start",
        }}
        justifyContent="center"
        space={6}
      >
        <VStack
          space={2}
          alignItems={"center"}
          w={{
            xl: "35%",
          }}
        >
          <Image
            borderRadius={"20px"}
            borderWidth={"1px"}
            borderColor="#EDEDED"
            size={"500px"}
            resizeMethod="scale"
            resizeMode="contain"
            source={{
              uri: image
                ? image
                : product?.skus[route?.params?.sku_id]?.images[0],
            }}
            alt=""
          />
          <ScrollView horizontal w="full">
            <HStack space={"24px"} alignItems="center">
              {product?.skus[route?.params?.sku_id]?.images?.map((k, i) => (
                <Pressable
                  onPress={() =>
                    setImage(product?.skus[route?.params?.sku_id]?.images[i])
                  }
                >
                  <Image
                    borderRadius={"20px"}
                    borderWidth={"1px"}
                    borderColor="#EDEDED"
                    size={"100px"}
                    resizeMethod="scale"
                    resizeMode="contain"
                    source={{
                      uri: k,
                    }}
                    alt=""
                  />
                </Pressable>
              ))}
            </HStack>
          </ScrollView>
        </VStack>
        <VStack
          w={{
            xl: "40%",
          }}
          space={"26px"}
        >
          <VStack space={"11px"}>
            <HStack space={2} alignItems={"center"}>
              <Image
                size={4}
                rounded="full"
                resizeMethod="scale"
                resizeMode="contain"
                source={{
                  uri: BrandImages[product?.variant?.brand_name],
                }}
                alt=""
              />

              <Text
                color="#94A3B8"
                fontSize={"14px"}
                lineHeight={16}
                style={{
                  fontFamily: "HK_700Bold",
                }}
                letterSpacing={2}
                textTransform={"uppercase"}
              >
                {product?.variant?.brand_name}
              </Text>
            </HStack>
            <Text
              w="70%"
              _light={{
                color: "#682481",
              }}
              _dark={{
                color: "#94A3B8",
              }}
              fontSize={"36px"}
              noOfLines={3}
              style={{
                fontFamily: "AustinCyr_400",
              }}
              lineHeight="43px"
              textTransform={"uppercase"}
            >
              {product?.variant?.product_name}
            </Text>
            <Text
              w="70%"
              _light={{
                color: "#4A4A4A",
              }}
              _dark={{
                color: "#94A3B8",
              }}
              fontSize={"32px"}
              style={{
                fontFamily: "Hero_700Bold",
              }}
            >
              ${product?.variant?.retail_price.toFixed(2)}
            </Text>
          </VStack>
          {labels
            ?.filter((g) => g)
            .map((k, i) => (
              <HStack alignItems={"center"} space={2}>
                <Text
                  _light={{
                    color: "#464646",
                  }}
                  _dark={{
                    color: "#94A3B8",
                  }}
                  fontSize={"16px"}
                  style={{
                    fontFamily: "Hero_700Bold",
                  }}
                >
                  {k?.component && k?.label}:
                </Text>
                {k?.component}
              </HStack>
            ))}
          <HStack space={"24px"} alignItems="center">
            <AddToCartHOC
              productSkuId={route?.params?.sku_id}
              productId={route?.params?.id}
              source={route?.params?.source}
              otherUserId={route?.params?.otherUserId}
              bundleId={route?.params?.bundle_id}
            >
              <AddToCartButton />
            </AddToCartHOC>
            <Center w="241px" h="60px">
              <PillGradientButton
                gradientType="2"
                onPress={() => {
                  setAnalytics(
                    "Buy Now Button",
                    "Buy Now Button Clicked In Product Screen",
                    {
                      productId: route?.params?.id,
                      productSkuId: route?.params?.sku_id,
                      retailPrice: product?.variant?.retail_price.toFixed(2),
                      source: referralId
                        ? "referral"
                        : route?.params?.source
                        ? route?.params?.source
                        : "regular",
                      otherUserId: route?.params?.otherUserId,
                    }
                  );
                  if (userId) {
                    navigation.navigate(Pages.CheckoutScreen, {
                      product_id: route?.params?.id,
                      sku_id: route?.params?.sku_id,
                      type: "single",
                      source: referralId
                        ? "referral"
                        : route?.params?.source
                        ? route?.params?.source
                        : "regular",
                      otherUserId: route?.params?.otherUserId,
                    });
                  } else {
                    navigation.navigate(Pages.Login);
                  }
                }}
              >
                <Center>
                  <Text
                    letterSpacing={"2"}
                    textTransform={"uppercase"}
                    color="white"
                    fontSize={"18px"}
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                  >
                    Buy now
                  </Text>
                </Center>
              </PillGradientButton>
            </Center>

            <WishlistHOC productSkuId={route?.params?.sku_id}>
              <ProductScreenWishlistButton />
            </WishlistHOC>
          </HStack>
        </VStack>
      </Stack>
      <VStack alignItems={"center"} space={12}>
        <HStack bg="#FAF1FF" borderRadius={"full"}>
          <Pressable
            onPress={() => {
              setButtonType("description");
            }}
          >
            <Center
              px={"16px"}
              py={"12px"}
              w="212px"
              h="50px"
              borderRadius={"full"}
              bg={
                buttonType === "description"
                  ? {
                      linearGradient: {
                        colors: GRADIENTMAP["2"],
                        start: [0, 0],
                        end: [1, 0],
                      },
                    }
                  : "#FAF1FF"
              }
            >
              <Text
                letterSpacing={"2"}
                textTransform={"uppercase"}
                color={buttonType === "description" ? "white" : "#464646"}
                fontSize={"18px"}
                style={{
                  fontFamily: "Hero_700Bold",
                }}
              >
                Description
              </Text>
            </Center>
          </Pressable>
          <Pressable
            onPress={() => {
              setButtonType("reviews");
            }}
          >
            <Center
              px={"16px"}
              py={"12px"}
              w="212px"
              h="50px"
              borderRadius={"full"}
              bg={
                buttonType === "reviews"
                  ? {
                      linearGradient: {
                        colors: GRADIENTMAP["2"],
                        start: [0, 0],
                        end: [1, 0],
                      },
                    }
                  : "#FAF1FF"
              }
            >
              <Text
                letterSpacing={"2"}
                textTransform={"uppercase"}
                color={buttonType === "reviews" ? "white" : "#464646"}
                fontSize={"18px"}
                style={{
                  fontFamily: "Hero_700Bold",
                }}
              >
                reviews
              </Text>
            </Center>
          </Pressable>
        </HStack>
        {buttonType === "reviews" ? (
          <Reviews />
        ) : (
          <Description product={product} />
        )}
      </VStack>
      <FooterSectionWeb />
    </VStack>
  );
};

export default ProductScreen;
