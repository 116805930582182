import * as Contacts from "expo-contacts";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../redux/currentState/actions";

import { privateApi } from "../util/API";

export const useSyncContacts = () => {
  const dispatch = useDispatch();

  const syncContacts = async () => {
    const { status } = await Contacts.getPermissionsAsync();
    if (status === "granted") {
      dispatch(showLoader());
      const { data } = await Contacts.getContactsAsync({
        fields: [Contacts.Fields.PhoneNumbers],
      });

      if (data.length > 0) {
        const contact = data?.flatMap((k) =>
          k?.phoneNumbers?.map((f) => f.number)
        );

        if (contact) {
          let phone_numbers = [
            ...new Set(
              contact?.map((f) => f?.replace(/\s/g, "").split("-").join(""))
            ),
          ].toString();

          const res = await privateApi(
            "/api/users/find_account_by_phone/",
            {
              phone_numbers: phone_numbers,
            },
            "post"
          );

          if (res?.status === 200) {
            dispatch(hideLoader());
            return res.body.data;
          }
        }
      }
    }
  };

  return syncContacts;
};
