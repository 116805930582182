import axios from "axios";
import useSWR from "swr";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
import { getHeaders } from "../util/utilFunctions";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const fetcher = async (url) =>
  axios
    .get(url, {
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });

export default function useGetFavorites(userId) {
  const { data, mutate, isValidating, error } = useSWR(
    userId ? `${apiUrl}/api/products/get_wishlist/?user_id=${userId}` : null,
    fetcher
  );
  useCheckNetwork(error);
  useLoader(isValidating);
  return {
    data: data?.flatMap((data) => data?.product_sku).filter((k) => k !== null),
    mutate,
    isValidating,
  };
}
