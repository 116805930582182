import { SET_PRODUCTS, ADD_PRODUCTS, RELOAD_PRODUCTS } from "./constants";

export function setProducts(products) {
  return {
    type: SET_PRODUCTS,
    products: products,
  };
}

export function addProducts(products, page) {
  return {
    type: ADD_PRODUCTS,
    products: products,
    page: page,
  };
}

export function reloadProducts(reloadProducts) {
  return {
    type: RELOAD_PRODUCTS,
    reloadProducts: reloadProducts,
  };
}
