import { Center, Text, VStack } from "native-base";
import { useContext } from "react";
import BeautyBuddiesCard from "../../../components/cards/beautyBuddiesCard";
import ColumnLayout from "../../../components/layouts/columnLayout";
import { gradientTextWeb } from "../../../constants/gradient";
import { UsersContext } from "../../../hoc/UsersHOC";

const CuratedUsers = () => {
  const { data, setSize } = useContext(UsersContext);
  const renderCuratedUsers = ({ item }) => (
    <Center m={1.5}>
      <BeautyBuddiesCard
        cardWidth={{
          base: "170px",
          sm: "170px",
          md: "200px",
          lg: "200px",
          xl: "200px",
          xxl: "200px",
        }}
        imageWidth={{
          base: "175px",
          sm: "175px",
          md: "175px",
          lg: "175px",
          xl: "175px",
          xxl: "175px",
        }}
        item={item}
      />
    </Center>
  );
  return (
    <>
      {data && (
        <VStack
          w={{
            base: "full",
            sm: "full",
            md: "full",
            lg: "full",
            xl: "80%",
            xxl: "75%",
          }}
          space={"24px"}
        >
          <Text
            w="full"
            fontSize={"36px"}
            _web={{
              style: {
                ...gradientTextWeb(1),
                fontFamily: "AustinCyr_400",
              },
            }}
          >
            Beauty Buddies similar to you
          </Text>
          <ColumnLayout
            defaultNumberOfColumns={5}
            data={data}
            renderItem={renderCuratedUsers}
            setSize={setSize}
          />
        </VStack>
      )}
    </>
  );
};

export default CuratedUsers;
