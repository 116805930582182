import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const ChevronLeftIcon = () => {
  return (
    <Icon viewBox="0 0 36 36" size={"8"}>
      <G width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M20.3963 12.4207C20.5857 12.2298 20.6919 11.9718 20.6919 11.703C20.6919 11.4341 20.5857 11.1761 20.3963 10.9852C20.3035 10.8909 20.1928 10.816 20.0707 10.7649C19.9487 10.7138 19.8177 10.6875 19.6853 10.6875C19.553 10.6875 19.422 10.7138 19.2999 10.7649C19.1778 10.816 19.0672 10.8909 18.9743 10.9852L13.3493 16.7227C13.1605 16.9141 13.0546 17.1721 13.0546 17.441C13.0546 17.7099 13.1605 17.968 13.3493 18.1593L18.9743 23.8968C19.0672 23.9911 19.1778 24.066 19.2999 24.1171C19.422 24.1682 19.553 24.1945 19.6853 24.1945C19.8177 24.1945 19.9487 24.1682 20.0707 24.1171C20.1928 24.066 20.3035 23.9911 20.3963 23.8968C20.5852 23.7055 20.6911 23.4474 20.6911 23.1785C20.6911 22.9096 20.5852 22.6516 20.3963 22.4602L15.7872 17.4382L20.3963 12.4207Z"
          fill="white"
        />
      </G>
    </Icon>
  );
};

export default ChevronLeftIcon;
