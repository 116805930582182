import { Center, HStack, Image, Pressable, Text, VStack } from "native-base";
import { BrandImages, Brands } from "../../../constants/brands";
import { gradientTextWeb } from "../../../constants/gradient";

const ThirdSectionWeb = () => {
  return (
    <>
      <VStack px="20px" space={4} alignItems={"center"} justifyContent="center">
        <Text
          fontSize={"36px"}
          textAlign="center"
          _web={{
            style: {
              ...gradientTextWeb(1),
              fontFamily: "AustinCyr_400",
            },
          }}
        >
          Some Iconic Brands at Flock
        </Text>
        <HStack
          w="full"
          alignItems={"center"}
          justifyContent="space-evenly"
          flexWrap={"wrap"}
        >
          {Object.values(Brands)
            ?.filter((k, i) => i < 8)
            .map((k) => (
              <Pressable>
                <Image
                  size={"120px"}
                  resizeMethod="scale"
                  resizeMode="contain"
                  source={{
                    uri: BrandImages[k],
                  }}
                  alt=""
                />
              </Pressable>
            ))}
        </HStack>
        <Center
          mt="50px"
          w="50%"
          h={"1px"}
          bg={{
            linearGradient: {
              colors: ["#FFFFFF", "#BE54FF", "#FFFFFF"],
              start: [0, 0],
              end: [1, 0],
            },
          }}
        ></Center>
      </VStack>
    </>
  );
};

export default ThirdSectionWeb;
