import axios from "axios";
import useSWR from "swr";
import { getHeaders, parse } from "../util/utilFunctions";
import { useSelector } from "react-redux";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const fetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return parse(res.data);
    });

export default function useGetPaymentDetails() {
  const userId = useSelector((state) => state.user.id);

  const { data, mutate, isValidating, error } = useSWR(
    userId
      ? `${apiUrl}/api/users/get_payment_details/?user_id=${userId}`
      : null,
    fetcher
  );
  useCheckNetwork(error);
  useLoader(isValidating);
  return { data, mutate, isValidating };
}
