import {
  NativeBaseProvider,
  Flex,
  useColorModeValue,
  ColorMode,
  StorageManager,
  Button,
  Box,
} from "native-base";
import { LinearGradient } from "expo-linear-gradient";
import { theme } from "./../../theme";
import { store } from "../../redux/store";
import { Provider } from "react-redux";
import Navigation from "../../navigation";
import { StatusBar } from "expo-status-bar";
import { enableScreens } from "react-native-screens";
import * as NavigationBar from "expo-navigation-bar";
import Loader from "../popUps/loader";
import NetworkPopUp from "../popUps/networkPopUp";
import Popup from "../popUps/popup";
import AsyncStorage from "@react-native-async-storage/async-storage";

const config = {
  dependencies: {
    "linear-gradient": LinearGradient,
  },
};
const colorModeManager: StorageManager = {
  get: async () => {
    try {
      let val = await AsyncStorage.getItem("@color-mode");
      return val === "dark" ? "dark" : "light";
    } catch (e) {
      return "light";
    }
  },
  set: async (value: ColorMode) => {
    try {
      await AsyncStorage.setItem("@color-mode", value);
    } catch (e) {
      console.log(e);
    }
  },
};
export default function AppLayout() {
  enableScreens(true);
  NavigationBar.setButtonStyleAsync("dark");
  NavigationBar.setBackgroundColorAsync("#1A1C1E");
  NavigationBar.setPositionAsync("absolute");
  const backgroundColor = useColorModeValue("white", "#111315");

  return (
    <Provider store={store}>
      <NativeBaseProvider
        theme={theme}
        config={config}
        colorModeManager={colorModeManager}
      >
        <Flex bg={backgroundColor} minWidth="100%" minHeight={"full"}>
          <StatusBar style="light" />
          <NetworkPopUp />

          <Loader />
          <Popup />
          <Navigation />
        </Flex>
      </NativeBaseProvider>
    </Provider>
  );
}
