import { Center, Image } from "native-base";

const ClockAnimatedIcon = () => {
  return (
    <Center size={"36px"} rounded="8px" bg="#8D49DE">
      <Image
        size={6}
        resizeMethod="scale"
        resizeMode="contain"
        source={require("./../../../assets/images/gifs/clock.gif")}
        alt=""
      />
    </Center>
  );
};

export default ClockAnimatedIcon;
