import {
  HStack,
  Text,
  VStack,
  IconButton,
  useColorModeValue,
} from "native-base";
import { setAnalytics } from "../../../analytics";
import { useNavigation, useRoute } from "@react-navigation/native";
import ChevronLeftIcon from "../../icons/outlinedIcons/chevronLeftIcon";
import BellAndCartIcon from "../../bellAndCartIcon";
import GradientBorderTabsBar from "../topBars/gradientBorderTabsBar";

const BackButtonTopBar = ({ navTabs, setNavTabs, text }) => {
  const navigation = useNavigation();
  const route = useRoute();
  const backgroundColor = useColorModeValue("white", "#111315");
  return (
    <>
      <VStack
        w="full"
        pt={{
          md: "50px",
          lg: "54px",
          xl: "56px",
        }}
        space={3}
      >
        <HStack px={4} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            _pressed={{
              bg: backgroundColor,
            }}
            onPress={async () => {
              setAnalytics("Back Button Top Bar", "Back Button Clicked", {
                screen: route?.name,
              });
              navigation.goBack();
            }}
            icon={<ChevronLeftIcon />}
          />
          <Text
            textAlign={"center"}
            textTransform={"uppercase"}
            letterSpacing={3}
            color="white"
            fontSize={"18px"}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
          >
            {text}
          </Text>
          <BellAndCartIcon />
        </HStack>
        <GradientBorderTabsBar
          navTabs={navTabs}
          setNavTabs={(val) => setNavTabs(val)}
        />
      </VStack>
    </>
  );
};

export default BackButtonTopBar;
