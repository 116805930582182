import { Icon } from "native-base";
import { Path, G, ClipPath, Rect, Defs, Circle } from "react-native-svg";

const BlueHeadPhoneIcon = ({ color }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={"12"} viewBox="0 0 40 41">
        <G
          width="40"
          height="41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Circle cx="20" cy="20.5" r="20" fill="#2E303C" />
          <G clip-path="url(#clip0_2156_49426)">
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 8C13.3326 8 7.70482 12.8505 7.50546 19.1323C7.50185 19.1711 7.5 19.2103 7.5 19.25V25.2024C7.5 27.1406 9.00084 28.8333 11 28.8333H14.375C15.0654 28.8333 15.625 28.2737 15.625 27.5833V19.25C15.625 18.5596 15.0654 18 14.375 18H10.1365C10.9158 13.8189 14.9452 10.5 20 10.5C25.0548 10.5 29.0842 13.8189 29.8635 18H25.625C24.9346 18 24.375 18.5596 24.375 19.25V27.5833C24.375 28.2737 24.9346 28.8333 25.625 28.8333H29C29.3384 28.8333 29.6626 28.7848 29.9676 28.6948C29.7922 29.7974 28.9339 30.5 28.0859 30.5H22.8125C22.1221 30.5 21.5625 31.0597 21.5625 31.75C21.5625 32.4404 22.1221 33 22.8125 33H28.0859C30.6307 33 32.5 30.7741 32.5 28.2778V19.25C32.5 19.2103 32.4982 19.1711 32.4945 19.1323C32.2952 12.8505 26.6674 8 20 8ZM30 20.5H26.875V26.3333H29C29.4871 26.3333 30 25.8951 30 25.2024V20.5ZM10 20.5V25.2024C10 25.8951 10.5129 26.3333 11 26.3333H13.125V20.5H10Z"
              fill="#1A94FF"
            />
          </G>
          <Defs>
            <ClipPath id="clip0_2156_49426">
              <Rect
                width="25"
                height="25"
                fill="white"
                transform="translate(7.5 8)"
              />
            </ClipPath>
          </Defs>
        </G>
      </Icon>
    </>
  );
};

export default BlueHeadPhoneIcon;
