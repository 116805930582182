import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const TrashRedIcon = () => {
  return (
    <>
      <Icon size={"24px"} viewBox="0 0 24 24">
        <G
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
            stroke="#FB4747"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M17.8996 9.04875C15.7196 8.82875 13.5196 8.71875 11.3296 8.71875C10.0296 8.71875 8.72961 8.78875 7.43961 8.91875L6.09961 9.04875"
            stroke="#FB4747"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M9.70898 8.38945L9.84898 7.52945C9.94898 6.90945 10.029 6.43945 11.139 6.43945H12.859C13.969 6.43945 14.049 6.92945 14.149 7.52945L14.289 8.37946"
            stroke="#FB4747"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <Path
            d="M16.49 9.12891L16.06 15.7289C15.99 16.7589 15.93 17.5589 14.1 17.5589H9.89C8.06 17.5589 7.99999 16.7589 7.92999 15.7289L7.5 9.12891"
            stroke="#FB4747"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </G>
      </Icon>
    </>
  );
};

export default TrashRedIcon;
