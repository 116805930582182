import { Text, Pressable, HStack, View, VStack } from "native-base";
import DiscountLeftBookmarkIconFilled from "./../../components/icons/discountLeftBookmarkIconFilled";
const JoinFlockDealButton = ({
  onPress,
  groupPrice,
  retailPrice,
  groupDiscount,
  text,
}) => {
  return (
    <>
      {/* use Pressable component from native base to make custom buttons */}
      <View position="relative">
        <View position="absolute" top={"-6%"} left={"-32%"}>
          <View position={"relative"}>
            <DiscountLeftBookmarkIconFilled />
            <Text
              position={"absolute"}
              top="30%"
              left={"30%"}
              color="white"
              fontSize={14}
              lineHeight={18}
              style={{
                fontFamily: "HK_700Bold",
              }}
            >
              {groupDiscount}%
            </Text>
          </View>
        </View>
        <Pressable onPress={onPress}>
          <HStack
            justifyContent={"space-around"}
            alignItems="center"
            px={2}
            w={{
              md: "143px",
              lg: "143px",
              xl: "150px",
            }}
            height={"40px"}
            bg={{
              linearGradient: {
                colors: [
                  "primary.ctaLinearSecondColor",
                  "primary.ctaLinearFirstColor",
                ],
                start: [0, 0],
                end: [1, 2],
              },
            }}
            rounded={"10px"}
            _text={{
              color: "#ffffff",
            }}
            space={4}
          >
            <VStack>
              <Text
                color="white"
                style={{
                  fontFamily: "HK_700Bold",
                }}
                fontSize={"12px"}
                letterSpacing={2}
              >
                {text}
              </Text>
            </VStack>
            <HStack alignItems={"center"} space={1}>
              <Text
                opacity={40}
                strikeThrough
                color="#fff"
                fontSize="10px"
                style={{
                  fontFamily: "HK_700Bold",
                }}
              >
                ${retailPrice}
              </Text>
              <Text
                color="white"
                style={{
                  fontFamily: "HK_700Bold",
                }}
                fontSize={"16px"}
              >
                ${groupPrice?.toFixed(2)}
              </Text>
            </HStack>
          </HStack>
        </Pressable>
      </View>
    </>
  );
};

export default JoinFlockDealButton;
