import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../redux/currentState/actions";

export const useLoader = (isValidating) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isValidating) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [isValidating]);
};
