import { privateApi } from "../util/API";
import { getUser } from "../util/utilFunctions";
import * as RootNavigation from "../RootNavigation";
import { Pages } from "../constants/pages";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  setDialog,
  showLoader,
} from "../redux/currentState/actions";
import { Popups } from "../constants/popups";

import { setAnalytics } from "../analytics";
import { Platform } from "react-native";

export const useMakeSinglePurchase = () => {
  const dispatch = useDispatch();
  const navigation = RootNavigation;
  const makeSinglePurchase = async (
    card_id,
    shipping_address_id,
    billing_address_id,
    amount,
    sku_id,
    redeemed_points,
    rewards_discount,
    percentOffDiscount,
    promoCode,
    eventProperties,
    source = "regular",
    otherUserId,
    referralId
  ) => {
    dispatch(showLoader());
    const userId = await getUser();

    if (userId !== null && userId !== undefined) {
      let discount = percentOffDiscount
        ? rewards_discount + Number(percentOffDiscount) < amount
          ? rewards_discount + Number(percentOffDiscount)
          : amount
        : rewards_discount < amount
        ? rewards_discount
        : amount;

      const sourceValueMapping = {
        wishlist: {
          user_id: userId,
          payment_id: card_id,
          shipping_address_id: shipping_address_id,
          billing_address_id: billing_address_id,
          amount: amount,
          product_sku_id: sku_id,
          redeemed_points: redeemed_points,
          rewards_discount: discount,

          source: source,
          other_user_id: otherUserId,
          test: true,
        },
        referral: {
          user_id: userId,
          payment_id: card_id,
          shipping_address_id: shipping_address_id,
          billing_address_id: billing_address_id,
          amount: amount,
          product_sku_id: sku_id,
          redeemed_points: redeemed_points,
          rewards_discount: discount,

          source: source,
          other_user_id: referralId,
          refferal_id: referralId,
          test: true,
        },
        regular: {
          user_id: userId,
          payment_id: card_id,
          shipping_address_id: shipping_address_id,
          billing_address_id: billing_address_id,
          amount: amount,
          product_sku_id: sku_id,
          redeemed_points: redeemed_points,
          rewards_discount: discount,
          test: true,
        },
      };
      let res = await privateApi(
        "/api/payments/make_payment/",
        sourceValueMapping[source],
        "post"
      );
      console.log(
        "useMakeSinglePurchase",
        sourceValueMapping[source],
        source,
        res
      );
      try {
        if (promoCode) {
          const promoRes = await privateApi(
            "/api/home/claim_promocode/",
            {
              user_id: userId,
              promocode: promoCode,
            },
            "post"
          );
        }
      } catch (e) {}

      dispatch(hideLoader());
      if (res.status === 201 || res.status === 200 || res.status === 204) {
        setAnalytics("Confirm Button", "Cart Item Purchased", {
          redeemedPoints: redeemed_points,
          rewardsDiscount: discount,
          ...eventProperties,
        });

        RootNavigation.navigate(Pages.OrderSuccessfulScreen);
      }
    } else {
      setAnalytics("Confirm Button", "Cart Item Purchase Failed", {
        popUp: "Oops you broke it, error pop up",
        redeemedPoints: redeemed_points,
        rewardsDiscount: discount,
        ...eventProperties,
      });
      if (Platform.OS === "web") {
        navigation.navigate(Pages.Login);
      } else {
        dispatch(setDialog(Popups.LoginPopup));
      }
      dispatch(setDialog(Popups.Error));
    }
  };

  return makeSinglePurchase;
};
