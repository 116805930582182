import { useNavigation } from "@react-navigation/native";
import { Button, HStack } from "native-base";
import { useSelector } from "react-redux";
import { Pages } from "../../../constants/pages";
import NextButton from "../../buttons/nextButton";

const SurveyScreenBottomBar = ({ showNextButton, nextOnPress }) => {
  const navigation = useNavigation();
  const userId = useSelector((state) => state.user.id);

  return (
    <HStack
      space={4}
      alignItems={"center"}
      width="full"
      justifyContent={"flex-end"}
      pr={8}
    >
      <Button
        _text={{
          color: "#4F566B",
          fontFamily: "Inter_400Regular",
          fontSize: "14px",
        }}
        variant={"ghost"}
        h="40px"
        w="100px"
        onPress={() => {
          navigation.navigate(Pages.UserScreen, {
            type: "profile",
            id: userId,
          });
        }}
      >
        Cancel
      </Button>
      <Button
        _text={{
          color: "#4F566B",
          fontFamily: "Inter_400Regular",
          fontSize: "14px",
        }}
        variant={"outline"}
        border="1px"
        borderColor={"#C1C9D2"}
        h="40px"
        w="100px"
        onPress={() => navigation.goBack()}
      >
        Previous
      </Button>
      {showNextButton && <NextButton onPress={nextOnPress} />}
    </HStack>
  );
};

export default SurveyScreenBottomBar;
