import { View, SectionList } from "native-base";
import React, { useState } from "react";
import BackButtonTopBar from "../../../components/bars/navigationBars/backButtonTopBar";
import BottomBar from "../../../components/bars/navigationBars/bottomBar";
import BeautyBuddiesScreen from "../../beautyBuddiesScreens/beautyBuddiesScreen";
import CuratedProducts from "./curatedProducts";

const ProfileCuratedForYou = () => {
  const [navTabs, setNavTabs] = useState([
    {
      label: "Products",
      pressed: true,

      component: <CuratedProducts />,
    },
    {
      label: "Beauty Buddies",
      pressed: false,
      component: <BeautyBuddiesScreen type="curatedUsersfetcher" />,
    },
  ]);
  const components = [
    {
      data: [navTabs.map((item, index) => item?.pressed && item?.component)],
    },
  ];

  const renderItem = ({ item }) => item;
  return (
    <>
      <BackButtonTopBar
        text="curated"
        navTabs={navTabs}
        setNavTabs={(val) => setNavTabs(val)}
      />
      <SectionList
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 11 }}></View>
        )}
        flex={1}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          paddingBottom: 14,
        }}
        sections={components}
        showsVerticalScrollIndicator={false}
        stickySectionHeadersEnabled
        renderSectionHeader={(item) => <>{item.section?.header}</>}
        renderItem={renderItem}
      ></SectionList>

      <BottomBar />
    </>
  );
};

export default ProfileCuratedForYou;
