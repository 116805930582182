import { publicApi } from "../util/API";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../redux/currentState/actions";
import { useState } from "react";
import { Keyboard, Platform } from "react-native";
import * as RootNavigation from "./../RootNavigation";
import { Pages } from "../constants/pages";

export const useSendForgetPasswordOtp = () => {
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const [shouldProceed, setShouldProceed] = useState();
  const form = useSelector((state) => state.form);
  const navigation = RootNavigation;
  const sendForgetPasswordOtp = async () => {
    Keyboard.dismiss();
    console.log("sendEmailRegistrationOtp");
    if (Platform.OS !== "web") {
      if (!form?.email) {
        setError("Fill email address");
        return;
      }
    }

    if (form?.email && !/\s/g.test(form?.email)) {
      dispatch(showLoader());

      try {
        let res = await publicApi(
          "/api/users/send_otp_email/",
          {
            email: form?.email,
          },
          "post"
        );

        if (res.body.status === 200) {
          if (Platform.OS !== "web") {
            navigation.navigate(Pages.VerifyScreen, {
              type: "forgetPassword",
            });
          }
          setShouldProceed(true);
        } else {
          setError(res.body.message);
        }
      } catch (error) {}
      dispatch(hideLoader());
    }
  };

  return { sendForgetPasswordOtp, error, setError, shouldProceed };
};
