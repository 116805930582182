import { FlatList, View, VStack } from "native-base";
import ProductCard from "../../../components/cards/product/productCard";
import { storeCartWishlist } from "../../../redux/cart/actions";
import { storeWishlist } from "../../../redux/wishlist/actions";
import { useDispatch, useSelector } from "react-redux";
import { privateApi } from "../../../util/API";
import { getUser } from "../../../util/utilFunctions";
import { useEffect } from "react";
import { reloadWishlist } from "../../../redux/cart/actions";
import { setDialog } from "../../../redux/currentState/actions";
import { Popups } from "../../../constants/popups";
import { setAnalytics } from "./../../../analytics";
const Favorites = () => {
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.cart.wishlist);
  const reload = useSelector((state) => state.cart.reloadWishlist);

  useEffect(() => {
    if (reload === true) {
      handleRequest();
    }
  }, [reload]);

  const handleRequest = async () => {
    dispatch(reloadWishlist(false));
    const userId = await getUser();
    if (userId !== null && userId !== undefined) {
      let res = await privateApi(
        "/api/products/get_wishlist/",
        { user_id: userId },
        "get"
      );
      if (res.status === 200) {
        let data = res.body;

        dispatch(storeCartWishlist(data));
        dispatch(storeWishlist(data));
      }
    } else {
      setAnalytics("Add To Favorites", `Guest User Tried Adding To Favorites`);
      dispatch(setDialog(Popups.LoginPopup));
    }
  };

  return (
    <VStack space={"16px"}>
      <FlatList
        pt={"16px"}
        h="full"
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 6 }}></View>
        )}
        contentContainerStyle={{
          paddingBottom: 12,
        }}
        data={wishlist}
        initialNumToRender={5}
        renderItem={({ item }) => (
          <ProductCard
            productImage={item?.product_sku?.images[0]}
            groupPrice={item?.product_sku?.group_price}
            retailPrice={item?.product_sku?.retail_price}
            groupDiscount={item?.product_sku?.group_discount}
            variants={[
              item?.product_sku?.color,
              item?.product_sku?.size,
              item?.product_sku?.scent,
            ]}
            productName={item?.product_sku?.product_name}
            brandName={item?.product_sku?.brand_name}
            productId={item?.product_sku?.product?.id}
            productSkuId={item?.product_sku?.id}
          />
        )}
      />
    </VStack>
  );
};

export default Favorites;
