import { Modal, useColorModeValue } from "native-base";

import { useDispatch } from "react-redux";
import { goBackDialog } from "../../redux/currentState/actions";
import WhatIsAFlockDealDropDown from "../dropDowns/whatIsAFlockDealDropDown";

const WhatIsAFlockDeal = () => {
  const dispatch = useDispatch();
  const backgroundColor = useColorModeValue("white", "#111315");
  return (
    <Modal
      size="full"
      bg="black:alpha.80"
      isOpen={true}
      onClose={() => dispatch(goBackDialog())}
    >
      <WhatIsAFlockDealDropDown bg={backgroundColor} modal={true} />
    </Modal>
  );
};

export default WhatIsAFlockDeal;
