import { Button } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { setAnalytics } from "../../analytics";
import { Pages } from "../../constants/pages";
import { Popups } from "../../constants/popups";
import { closeDialog, setDialog } from "../../redux/currentState/actions";
import { privateApi } from "../../util/API";
import { handleLogout } from "../../util/utilFunctions";
import * as RootNavigation from "../../RootNavigation";
const AccountDeleteButton = ({ isModal }) => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.user.id);
  const onPress = async () => {
    if (!isModal) {
      dispatch(setDialog(Popups.DeleteAccountModal));
    } else {
      const res = await privateApi(
        "/api/home/delete_user/",
        { user_id: userId },
        "delete"
      );

      if (res.status === 200) {
        handleLogout();
        dispatch(closeDialog());
        RootNavigation.navigate(Pages.Login);
      }
      setAnalytics("AccountDeleteButton", "Account deleted", {
        screen: "SettingsScreen",
        status: res.status,
      });
    }
  };

  return (
    <Button
      px={8}
      rounded={10}
      variant={"ghost"}
      onPress={onPress}
      _text={{
        fontSize: 16,
        fontFamily: "Poppins_500Medium",

        color: "danger.600",
      }}
    >
      Delete Account
    </Button>
  );
};

export default AccountDeleteButton;
