import {
  Center,
  Divider,
  Hidden,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from "native-base";
import FollowButton from "../../../components/buttons/followButton";
import EllipseIcon from "../../../components/icons/ellipseIcon";
import ShareIcons from "../../../components/shareIcons";
import { SKINSHADEMAP } from "../../../constants/skinShade";

const CelebDetails = ({ data }) => {
  return (
    <VStack space={4}>
      <Stack
        direction={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "column",
          xl: "row",
        }}
        justifyContent={"center"}
        space={6}
        w={{
          base: "full",
          sm: "full",
          md: "full",
          lg: "full",
          xl: "90%",
        }}
        px={2}
      >
        <Center
          w={{
            base: "full",
            sm: "full",
            md: "full",
            lg: "full",
            xl: "50%",
          }}
        >
          <Image
            alt=""
            w="266px"
            h="200px"
            resizeMethod="scale"
            resizeMode="contain"
            fallbackSource={require("./../../../assets/images/pngs/fallbackuser.png")}
            source={{
              uri: data?.user[0]?.image,
            }}
          ></Image>
          <Center bg="#FAF1FF" rounded="full" h="77px" w="372px">
            <Text
              textAlign={"center"}
              _light={{
                color: "#682481",
              }}
              fontSize="28px"
              lineHeight={"65.2px"}
              style={{
                fontFamily: "Tomatoes_400Regular",
              }}
            >
              {data?.user[0]?.username}
            </Text>
          </Center>

          <HStack
            alignItems={"center"}
            justifyContent="center"
            space={2}
            pt={6}
          >
            <Text
              _light={{
                color: "#464646",
              }}
              _dark={{
                color: "#94A3B8",
              }}
              fontSize={"16px"}
              style={{
                fontFamily: "Hero_700Bold",
              }}
            >
              Share:
            </Text>
            <ShareIcons />
          </HStack>
        </Center>

        <Hidden from={"base"} till="xl">
          <Divider bg="#E9B9FF" orientation="vertical" />
        </Hidden>

        <VStack
          alignItems={"center"}
          w={{
            base: "full",
            sm: "full",
            md: "full",
            lg: "full",
            xl: "50%",
          }}
          space={8}
        >
          <Text
            _light={{
              color: "#464646",
            }}
            _dark={{
              color: "white",
            }}
            _web={{
              fontSize: "12px",
              style: {
                fontFamily: "LeituraNewsRoman",
              },
            }}
            fontSize="14px"
            lineHeight={"17.5px"}
            style={{
              fontFamily: "HK_500Medium",
            }}
          >
            {data?.user[0]?.bio}
          </Text>
          <HStack
            alignItems={"center"}
            w={{
              base: "50%",
              sm: "50%",
              md: "50%",
              lg: "50%",
              xl: "30%",
            }}
            justifyContent={"space-between"}
          >
            {[
              data?.user[0]?.age,
              data?.user[0]?.ethnicity,
              data?.user[0]?.sunsign,
            ]
              .filter((k) => k)
              .map((text, i) => (
                <>
                  <Text
                    noOfLines={1}
                    textAlign={"center"}
                    textTransform={"capitalize"}
                    _light={{
                      color: "#682481",
                    }}
                    _dark={{
                      color: "white",
                    }}
                    _web={{
                      fontSize: "12px",
                      style: {
                        fontFamily: "Hero_700Bold",
                      },
                    }}
                    fontSize={"15px"}
                    style={{
                      fontFamily: "HK_500Medium",
                    }}
                  >
                    {text}
                  </Text>
                  {i < 2 && <EllipseIcon />}
                </>
              ))}
          </HStack>
          <HStack flexWrap={"wrap"} justifyContent="center">
            {[
              data?.user[0]?.skin_tone
                ? data?.user[0]?.skin_tone && {
                    color: SKINSHADEMAP[data?.user[0]?.skin_tone],

                    label: `Skin Tone:${data?.user[0]?.skin_tone}`,
                  }
                : null,
              data?.user[0]?.skin_type
                ? data?.user[0]?.skin_type && {
                    color: "#B2E9AA",

                    label: `${data?.user[0]?.skin_type}`,
                  }
                : null,
              data?.user[0]?.hair_texture
                ? data?.user[0]?.hair_texture && {
                    color: "#B2AAE9",

                    label: `${data?.user[0]?.hair_texture} hair`,
                  }
                : null,
              data?.user[0]?.hair_type && data?.user[0]?.hair_type?.length > 0
                ? {
                    color: "#E7AAE9",

                    label: `${data?.user[0]?.hair_type} hair`,
                  }
                : null,
            ]
              ?.filter((g) => g)
              .map((item) => (
                <Center m={1}>
                  <Center
                    px={"10px"}
                    bg={item?.color}
                    h="20px"
                    rounded={"6px"}
                    mt={1}
                  >
                    <Text
                      style={{
                        textTransform: "capitalize",
                        color: "black",
                        fontFamily: "HK_500Medium",
                        fontSize: 11,
                      }}
                    >
                      {item?.label?.replace(/[\n\r\s]/g, " ")}
                    </Text>
                  </Center>
                </Center>
              ))}
          </HStack>
          <VStack>
            <Text
              textAlign={"center"}
              letterSpacing={"1"}
              _light={{
                color: "#464646",
              }}
              _web={{
                style: {
                  fontFamily: "Hero_700Bold",
                },
              }}
              color={"#94A3B8"}
              fontSize="14px"
              lineHeight={"17.5px"}
              style={{
                fontFamily: "HK_500Medium",
              }}
            >
              {data?.user[0]?.followers_count}
            </Text>
            <Text
              _light={{
                color: "#682481",
              }}
              _web={{
                style: {
                  fontFamily: "Hero_700Bold",
                },
              }}
              textTransform={"capitalize"}
              fontSize="14px"
              lineHeight={"17.5px"}
              style={{
                fontFamily: "HK_500Medium",
              }}
            >
              {data?.user[0]?.followers_count > 1 ? "followers" : "follower"}
            </Text>
          </VStack>
          <Center w="70%" h="60px">
            <FollowButton followingId={data && data?.user[0]?.id} />
          </Center>
        </VStack>
      </Stack>
      <Divider bg="#E9B9FF" />
    </VStack>
  );
};

export default CelebDetails;
