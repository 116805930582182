//login

import AsyncStorage from "@react-native-async-storage/async-storage";
import { DiscountsMapping } from "../constants/discounts";
import { ProductType } from "../constants/categories";
import { SubproductType1, SubproductType2 } from "../constants/categories";
import { Brands } from "../constants/brands";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
import { privateApi } from "./API";

export function loggedIn() {
  if (AsyncStorage.getItem("user")) {
    return true;
  }
  return false;
}

export const imageUrl = (url) => {
  const baseLink = "https://flock.s3.amazonaws.com/";
  const link = baseLink + url;
};
export const createTagValue = (tag, type) => {
  if (tag !== undefined && tag !== null) {
    return tag + " " + type;
  } else {
    return null;
  }
};

export const checkForNull = (array) => {
  let ar = array.filter((x) => x !== null);
  return ar;
};

export const returnArrayForNull = (val) => {
  if (val === null || val === undefined) {
    return [];
  } else return val;
};
export const returnObjectForNull = (val) => {
  if (val === null || val === undefined) {
    return {};
  } else return val;
};
export const returnStringForNull = (val) => {
  if (val === null || val === undefined) {
    return "";
  } else return val;
};

export async function handleLogout() {
  try {
    await AsyncStorage.removeItem("user");
    await AsyncStorage.removeItem("token");
    return true;
  } catch (exception) {
    return false;
  }
}

export function convertUnixToDate(unix_timestamp) {
  var a = new Date(unix_timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = date + " " + month + " " + year;
  return time;
}

export function getDate(date) {
  let d = new Date(date);

  return d.toLocaleString();
}

export async function setUser(user, token, username, authtoken) {
  console.log("setUser", authtoken);
  await AsyncStorage.setItem("username", username);
  await AsyncStorage.setItem("user", JSON.stringify(user));
  await AsyncStorage.setItem("token", token);
  await AsyncStorage.setItem("authtoken", authtoken);
  return null;
}

export async function testPushToken() {
  const userId = await getUser();

  if (userId !== null && userId !== undefined) {
    const res = await privateApi(
      "/api/users/push_token/",
      { token: 5, date: "23135134", active: true, user: 2 },
      "put"
    );
  }
}
export async function setPushToken(pushToken) {
  if (pushToken !== null && pushToken !== undefined) {
    let token = pushToken;
    await AsyncStorage.setItem("pushtoken", token);
    const userId = await getUser();
    if (userId !== null && userId !== undefined) {
      const res = await privateApi(
        "/api/users/push_token/",
        { token: token, date: "23135134", active: true, user: userId },
        "put"
      );
    } else {
      const res = await privateApi(
        "/api/users/push_token/",
        { token: token, date: "23135134", active: true },
        "put"
      );
    }
  }

  return null;
}

export async function getPushToken() {
  if (AsyncStorage && AsyncStorage !== null) {
    const K = await AsyncStorage.getItem("pushtoken");

    return K;
  } else {
    return null;
  }
}

export async function getUser() {
  if (AsyncStorage && AsyncStorage !== null) {
    const K = await AsyncStorage.getItem("user");
    const data = JSON.parse(K);
    return data;
  } else {
    return null;
  }
}

export function getUserId() {
  if (AsyncStorage.getItem("user")) {
    return AsyncStorage.getItem("user");
  }
  return null;
}

export const parse = (val) => {
  let newOb = {};
  for (const key in val) {
    if (val[key].length === 0 || val[key] === undefined) {
      newOb[key] = val[key];
    } else if (val[key] === "[]") {
      newOb[key] = [];
    } else if (val[key] === "{}") {
      newOb[key] = {};
    } else if (val[key] === "" || val[key] === "") {
      newOb[key] = "";
    } else {
      newOb[key] = JSON.parse(val[key]);
    }
  }
  return newOb;
};

export const parseItem = (val) => {
  if ((val && val.length === 0) || val === undefined) {
    return val;
  } else if (val === "[]") {
    return [];
  } else if (val === "{}") {
    return {};
  } else {
    return JSON.parse(val);
  }
};

export const createFilters = (filter) => {
  let newVal = {};
  newVal.minprice = filter.priceRangeFrom;
  newVal.maxprice = filter.priceRangeTo;
  newVal.price_sort = filter.price_sort;

  filter.discounts.forEach((item) => {
    newVal.mindiscount = DiscountsMapping[item].mindiscount;
    newVal.maxdiscount = DiscountsMapping[item].maxdiscount;
  });

  filter.brands.forEach((item) => {
    newVal.brand = newVal.brand
      ? newVal.brand + "," + Brands[item]
      : Brands[item];
  });

  filter.concerns.forEach((item) => {
    newVal.concerns = newVal.concerns
      ? newVal.concerns + "," + HairConcerns[item]
      : HairConcerns[item];
  });

  filter.skinConcerns.forEach((item) => {
    newVal.concerns = newVal.concerns
      ? newVal.concerns + "," + SkinConcerns[item]
      : SkinConcerns[item];
  });

  filter.productTypes.forEach((item) => {
    newVal.product_type = newVal.product_type
      ? newVal.product_type + "," + ProductType[item]
      : ProductType[item];
  });

  filter.subproductType1.forEach((item) => {
    newVal.subproduct_type1 = newVal.subproduct_type1
      ? newVal.subproduct_type1 + "," + SubproductType1[item].label
      : SubproductType1[item].label;
  });

  filter.subproductType2.forEach((item) => {
    newVal.subproduct_type2 = newVal.subproduct_type2
      ? newVal.subproduct_type2 + "," + SubproductType2[item]
      : SubproductType2[item];
  });
  return newVal;
};

export const createCommunityFilters = (filter) => {
  let newVal = {};

  newVal.minage = filter.ageRangeFrom;
  newVal.maxage = filter.ageRangeTo;

  filter.hair_type.forEach((item) => {
    newVal.hair_type = newVal.hair_type
      ? newVal.hair_type + "," + HairTypes[item]
      : HairTypes[item];
  });
  filter.hair_texture.forEach((item) => {
    newVal.hair_texture = newVal.texture
      ? newVal.hair_texture + "," + HairTextures[item]
      : HairTextures[item];
  });
  filter.skin_type.forEach((item) => {
    newVal.skin_type = newVal.skin_type
      ? newVal.skin_type + "," + SkinTypes[item]
      : SkinTypes[item];
  });

  filter.ethnicity.forEach((item) => {
    newVal.ethnicity = newVal.ethnicity
      ? newVal.ethnicity + "," + Ethnicities[item]
      : Ethnicities[item];
  });

  return newVal;
};

export const sortProducts = (p) => {
  let tempArr1 = [];
  let tempArr2 = [];
  _.forOwn(p, (product, key) => {
    if (TopBrands.includes(product.brand_name)) {
      tempArr1.push(product.id);
    } else {
      tempArr2.push(product.id);
    }
  });
  tempArr1 = shuffle(tempArr1);
  tempArr2 = shuffle(tempArr2);
  return [...tempArr1, ...tempArr2];
};

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function shuffleArray(products, groups, people, collections, reviews) {
  let it1 = 0,
    it2 = 0,
    it3 = 0,
    it4 = 0,
    it5 = 0;
  let it = 0;
  const total =
    products.length +
    groups.length +
    people.length +
    collections.length +
    reviews.length;
  let k = Math.floor(Math.random() * 20);
  let arr = [];
  while (it < total) {
    if (it % 3 === 0 && it2 < groups.length) {
      arr.push(groups[it2]);
      it2 = it2 + 1;
    } else if (it % 5 === 0 && it3 < people.length) {
      arr.push(people[it3]);
      it3 = it3 + 1;
    } else if (it % 7 && it4 < reviews.length) {
      arr.push(reviews[it4]);
      it4 = it4 + 1;
    } else if (it1 < products.length) {
      arr.push(products[it1]);
      it1 = it1 + 1;
    } else {
      break;
    }
    it = it + 1;
  }
  while (it2 < groups.length) {
    arr.push(groups[it2]);
    it2 = it2 + 1;
  }
  while (it3 < people.length) {
    arr.push(people[it3]);
    it3 = it3 + 1;
  }
  while (it4 < reviews.length) {
    arr.push(reviews[it4]);
    it4 = it4 + 1;
  }
  return arr;
}

import _ from "lodash";
import { RNS3 } from "react-native-aws3";
import { HairConcerns } from "../constants/hairConcerns";
import { SkinConcerns } from "../constants/skinConcerns";
import { HairTypes } from "../constants/hairTypes";
import { SkinTypes } from "../constants/skinTypes";
import { Ethnicities } from "../constants/ethinicity";
import { HairTextures } from "../constants/hairTextures";
import { Platform } from "react-native";

export const createObjects = (element) => {
  let gfg = _.reduce(
    element,
    function (result, value) {
      result[value.id] = value;
      return result;
    },
    {}
  );
  return gfg;
};

export const createObject = (element) => {
  let id = element.id;
  let gfg = {};
  gfg[id] = { ...element };
  return gfg;
};

// api functions
export const addToWishlist = async (props) => {
  let res = await privateApi(
    "/api/products/wishlist",
    {
      user: user.user_id,
      product_saved: props.id,
      selling_price: props.price,
      offer: "0",
    },
    "post"
  );
  if (res.status === 201) {
    //let data = {}
    let data = res.body;
  }
};

export const handleSharing = async () => {
  const canonical = document.querySelector("link[rel=canonical]");
  let url = canonical ? canonical.href : document.location.href;
  const shareDetails = { url, title, text };
};

//redux
export function reduxCardMapping(type) {
  if (type === "product") {
    return "products";
  } else if (type === "review") {
    return "reviews";
  } else if (type === "person") {
    return "people";
  } else if (type === "group") {
    return "groups";
  } else {
    return "collections";
  }
}

export function convertPoints(points) {
  let pointsToCash = 0.0625; // 12525100
  if (points < 250) {
    pointsToCash = 0.03;
  } else if (points < 500) {
    //8
    pointsToCash = 0.032;
  } else if (points < 1000) {
    //20
    pointsToCash = 0.04;
  } else if (points < 2000) {
    //50
    pointsToCash = 0.05;
  }
  let cash = points * pointsToCash;
  return Math.round(cash * 100) / 100;
}

// 100=3, 250=8, 500=20, 1000=50, 2000=125
export function convertCash(cash) {
  let pointsToCash = 0.0625;
  if (cash < 8) {
    pointsToCash = 0.03;
  } else if (cash < 20) {
    pointsToCash = 0.032;
  } else if (cash < 50) {
    pointsToCash = 0.04;
  } else if (cash < 125) {
    pointsToCash = 0.05;
  }
  let points = Math.ceil(cash / pointsToCash);
  return points;
}

export function getPercentOffDiscount(price, promoCode) {
  if (promoCode === "FLOCKSTAR30") {
    return ((price * 30) / 100).toFixed(2);
  } else if (promoCode === "FLOCKSTAR20") {
    return ((price * 20) / 100).toFixed(2);
  } else if (promoCode === "CMU30") {
    return ((price * 30) / 100).toFixed(2);
  } else if (promoCode === "UGA30") {
    return ((price * 30) / 100).toFixed(2);
  } else if (promoCode === "UPENN30") {
    return ((price * 30) / 100).toFixed(2);
  } else if (promoCode === "USCBCC30") {
    return ((price * 30) / 100).toFixed(2);
  }
}

const authenticatedPaths = [
  "/api/products/cart/",
  "/api/products/delete_cart_items/",
  "/api/users/add_address/",
  "/api/users/add_default_address/",
  "/api/home/loggedin_home/",
  "/api/followers/follower/",
  "/api/home/home/",
  "/api/users/user/",
  "/api/payments/add_default_card/",
  "/api/payments/save_details/",
  "/api/payments/make_bundles_payments/",
  "/api/payments/make_payment/",
  "/api/payments/make_payment/",
  "/api/home/claim_promocode/",
  "/api/home/get_promocode_discount/",
  "/api/users/get_affilate_rewards/",
  "/api/products/collection/",
];
export async function getHeaders() {
  if (Platform.OS === "web") {
    return {
      Authorization: `Token ${await AsyncStorage.getItem("authtoken")}`,
    };
  } else {
    return {
      Authorization: `Token ${await AsyncStorage.getItem("authtoken")}`,
      "Content-Type": "application/json",
      "X-CSRFToken": AsyncStorage.getItem("csrftoken"),
      csrftoken: AsyncStorage.getItem("csrftoken"),
    };
  }
}
export async function getPostHeaders(path) {
  if (Platform.OS === "web") {
    if (authenticatedPaths.includes(path)) {
      return {
        Authorization: `Token ${await AsyncStorage.getItem("authtoken")}`,
      };
    } else {
      return {};
    }
  } else {
    return {
      "Content-Type": "application/json",
      "X-CSRFToken": AsyncStorage.getItem("csrftoken"),
      csrftoken: AsyncStorage.getItem("csrftoken"),
    };
  }
}

export const uploadImageToServer = async (im, id) => {
  const file = {
    uri: im.uri,
    name: "profilepic.png",
    type: "image/png",
  };
  let fetchResponse = await fetch(im.uri);
  let buffResponse = await fetchResponse.arrayBuffer();
  let webFile = new File([buffResponse], "profilepic.png", {
    type: "image/png",
  });

  const options = {
    keyPrefix: "users/" + id + "/",
    bucket: "flock",
    region: "us-east-1",
    accessKey: "AKIASTL3JHOXKBXW4A3O",
    secretKey: "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",
    successActionStatus: 201,
  };
  const response = await RNS3.put(
    Platform.OS === "web" ? webFile : file,
    options
  );

  let res = await privateApi(
    "/api/users/user/",
    {
      id: id,
      image: "https://flock.s3.amazonaws.com/users/" + id + "/profilepic.png",
    },
    "put"
  );
  let image = "https://flock.s3.amazonaws.com/users/" + id + "/profilepic.png";
  return { uploadStatusCode: res?.status, image: image };
};
