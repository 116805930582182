import { useNavigation } from "@react-navigation/native";
import {
  Box,
  Center,
  FlatList,
  Image,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import BeautyBuddiesCard from "../../../components/cards/beautyBuddiesCard";
import ProductCard from "../../../components/cards/product/productCard";
import { BrandImages } from "../../../constants/brands";
import { Pages } from "../../../constants/pages";
import { SearchContext } from "../../../hoc/SearchHOC";
import { changeFilter } from "../../../redux/filter/actions";
import { parseItem } from "../../../util/utilFunctions";
import { Brands as BrandList } from "../../../constants/brands";
import { Dimensions } from "react-native";
import useGetNumberOfColumns from "../../../hooks/useGetNumberOfColumns";
const SearchResults = () => {
  const navigation = useNavigation();
  const { searchResult } = useContext(SearchContext);
  const brandFilter = useSelector((state) => state.filter.brands);
  const dispatch = useDispatch();
  const numberOfColumns = useGetNumberOfColumns(6);
  const renderProducts = ({ item }) => {
    return (
      <Center m={1.5}>
        <ProductCard
          variantLength={
            [item?.size, item?.scent, item?.colors, item?.type]?.filter(
              (k) => k
            ).length
          }
          productImage={item && item?.images && item?.images[0]}
          groupPrice={item?.group_price}
          retailPrice={item?.retail_price}
          groupDiscount={item?.group_discount}
          productName={item?.name}
          brandName={item?.brand_name}
          productId={item?.id}
          productSkuId={item?.default_sku}
        />
      </Center>
    );
  };
  const renderPeople = ({ item }) => {
    return (
      <Center m={1.5}>
        <BeautyBuddiesCard
          cardWidth={{
            base: "170px",
            sm: "170px",
            md: "200px",
            lg: "200px",
            xl: "200px",
            xxl: "200px",
          }}
          imageWidth={{
            base: "175px",
            sm: "175px",
            md: "175px",
            lg: "175px",
            xl: "175px",
            xxl: "175px",
          }}
          item={item}
        />
      </Center>
    );
  };
  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const renderBrands = ({ item }) => {
    return (
      <Pressable
        onPress={() => {
          let brand = getKeyByValue(BrandList, item?.name);

          let vals = [...brandFilter];
          if (vals.includes(brand) === true) {
            vals = [...vals.filter((value) => value !== brand)];
          } else {
            vals.push(brand);
          }
          dispatch(changeFilter({ brands: vals }));
          dispatch({
            type: "SET_APPLY_FILTER",
          });
          navigation.navigate(Pages.ShopScreen);
        }}
      >
        <Center
          m={1.5}
          w="695px"
          h="137px"
          rounded={"11px"}
          borderWidth="1px"
          borderColor={"#68248133"}
        >
          <Image
            size={"full"}
            rounded={"full"}
            resizeMethod="scale"
            resizeMode="contain"
            alt=""
            source={{
              uri: BrandImages[item.name],
            }}
          ></Image>
        </Center>
      </Pressable>
    );
  };
  return (
    <>
      {searchResult ? (
        <VStack
          px={{
            xl: "20px",
          }}
        >
          {searchResult &&
            parseItem(searchResult?.brands) &&
            parseItem(searchResult?.brands)?.length > 0 && (
              <VStack>
                <Text
                  color="#682481"
                  fontSize={"36px"}
                  style={{
                    fontFamily: "AustinCyr_400",
                  }}
                >
                  Brands
                </Text>
                <FlatList
                  showsHorizontalScrollIndicator={false}
                  horizontal
                  initialNumToRender={2}
                  ListEmptyComponent={
                    <Text
                      color="#464646"
                      fontSize="16px"
                      style={{
                        fontFamily: "HK_700Bold",
                      }}
                    >
                      {parseItem(searchResult?.brands)?.length === 0
                        ? "No Brands"
                        : "loading..."}
                    </Text>
                  }
                  data={parseItem(searchResult?.brands)}
                  renderItem={renderBrands}
                />
              </VStack>
            )}
          {searchResult &&
            searchResult?.products &&
            searchResult?.products?.length > 0 && (
              <VStack>
                <Text
                  color="#682481"
                  fontSize={"36px"}
                  style={{
                    fontFamily: "AustinCyr_400",
                  }}
                >
                  Products
                </Text>
                <FlatList
                  key={numberOfColumns}
                  columnWrapperStyle={{
                    justifyContent: "center",
                  }}
                  showsVerticalScrollIndicator={false}
                  initialNumToRender={2}
                  ListEmptyComponent={
                    <Text
                      color="#464646"
                      fontSize="16px"
                      style={{
                        fontFamily: "HK_700Bold",
                      }}
                    >
                      {searchResult?.products?.length === 0
                        ? "No search result"
                        : "loading..."}
                    </Text>
                  }
                  data={searchResult?.products}
                  numColumns={numberOfColumns}
                  renderItem={renderProducts}
                />
              </VStack>
            )}
          {searchResult &&
            parseItem(searchResult?.people) &&
            parseItem(searchResult?.people)?.length > 0 && (
              <VStack>
                <Text
                  color="#682481"
                  fontSize={"36px"}
                  style={{
                    fontFamily: "AustinCyr_400",
                  }}
                >
                  People
                </Text>
                <FlatList
                  columnWrapperStyle={{
                    justifyContent: "center",
                  }}
                  showsVerticalScrollIndicator={false}
                  initialNumToRender={2}
                  ListEmptyComponent={
                    <Text
                      color="#464646"
                      fontSize="16px"
                      style={{
                        fontFamily: "HK_700Bold",
                      }}
                    >
                      {parseItem(searchResult?.people)?.length === 0
                        ? "No search result"
                        : "loading..."}
                    </Text>
                  }
                  data={parseItem(searchResult?.people)}
                  numColumns={6}
                  renderItem={renderPeople}
                />
              </VStack>
            )}
        </VStack>
      ) : (
        <VStack h="40%"></VStack>
      )}
    </>
  );
};

export default SearchResults;
