import { useRoute } from "@react-navigation/native";
import {
  HStack,
  Text,
  Center,
  ScrollView,
  Pressable,
  useColorModeValue,
} from "native-base";
import { setAnalytics } from "../../../analytics";

const GradientBorderTabsBar = ({ navTabs, setNavTabs }) => {
  const route = useRoute();
  const backgroundColor = useColorModeValue("white", "#111315");
  return (
    <>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        w="100%"
        bg={backgroundColor}
      >
        <HStack
          pl={"10px"}
          pr={"30px"}
          h={"60px"}
          space={"10px"}
          w="100%"
          alignItems={"center"}
        >
          {navTabs?.map((tab, index) => (
            <Center
              h="32px"
              key={index}
              borderRadius={8}
              bg={{
                linearGradient: tab.pressed
                  ? {
                      colors: [
                        "primary.ctaLinearSecondColor",
                        "primary.ctaLinearFirstColor",
                      ],
                      start: [0, 0],
                      end: [1, 0],
                    }
                  : {
                      colors: ["#2B2F33", "#2B2F33"],
                      start: [0, 0],
                      end: [1, 0],
                    },
              }}
            >
              <Pressable
                h="30px"
                onPress={() => {
                  setAnalytics(
                    "Profile screen tabs",
                    `Profile ${tab.label} Tab Clicked`,

                    {
                      tab: tab.label,
                      screen: route?.name,
                    }
                  );
                  let copy = [...navTabs];
                  copy.map((k) => {
                    if (k.pressed) {
                      return (k.pressed = false);
                    }
                  });
                  copy[index] = { ...copy[index], pressed: true };
                  setNavTabs(copy);
                }}
                borderRadius={8}
                px={4}
                py={1}
                mx={"1px"}
                bg="#2B2F33"
              >
                <HStack alignItems={"center"} space={1}>
                  {tab.pressed ? tab?.activeIcon : tab?.icon}

                  <Text
                    textAlign={"center"}
                    color={tab.pressed ? "white" : "#94A3B8"}
                    fontSize={"14px"}
                    style={{
                      fontFamily: "Inter_400Regular",
                    }}
                  >
                    {tab.label}
                  </Text>
                </HStack>
              </Pressable>
            </Center>
          ))}
        </HStack>
      </ScrollView>
    </>
  );
};

export default GradientBorderTabsBar;
