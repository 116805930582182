import axios from "axios";
import useSWR from "swr";
import { getHeaders, parse } from "../util/utilFunctions";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const fetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return parse(res.data);
    });
export const useGetGroupDetails = (group_id) => {
  const { data, mutate, isValidating, error } = useSWR(
    `${apiUrl}/api/groups/get_group_details/?id=${group_id}`,
    fetcher
  );
  useCheckNetwork(error);
  useLoader(isValidating);
  return {
    data,
    isValidating,
  };
};
