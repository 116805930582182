import { FlatList, View, Button } from "native-base";

import { useDispatch, useSelector } from "react-redux";
import { Ethnicities } from "../../constants/ethinicity";

import { changeFilter } from "../../redux/communityFilter/actions";

const Item = ({ item }) => {
  const ethnicity = useSelector((state) => state.communityFilter.ethnicity);
  const dispatch = useDispatch();
  return (
    <Button
      _hover={{
        bg: "#F5F5F5",
      }}
      onPress={() => {
        let vals = [...ethnicity];
        if (vals.includes(item) === true) {
          vals = [...vals.filter((value) => value !== item)];
        } else {
          vals.push(item);
        }
        dispatch(changeFilter({ ethnicity: vals }));
        dispatch({
          type: "SET_APPLY_FILTER",
        });
      }}
      variant={"ghost"}
      _text={{
        fontFamily: "Hero_400Regular",

        letterSpacing: 2,
        color: "#000000",
        fontSize: "12px",
      }}
    >
      {Ethnicities[item]}
    </Button>
  );
};

const Ethnicity = () => {
  const renderItem = ({ item }) => <Item item={item} />;

  return (
    <FlatList
      bg="white"
      showsVerticalScrollIndicator={false}
      ItemSeparatorComponent={() => (
        <View style={{ paddingVertical: 2 }}></View>
      )}
      contentContainerStyle={{
        height: 200,
      }}
      data={Object.keys(Ethnicities)}
      renderItem={renderItem}
    />
  );
};

export default Ethnicity;
