import {
    RECIEVED_USER,
    ADD_DETAILS,
    ADD_INTEREST, 
    DELETE_INTEREST,
    SET_TOKEN
  } from './constants'
  
  
  export function recievedUser(user){
    return {
      type: RECIEVED_USER,
      user: user
    }
  }

  export function addDetails(details){
    return {
      type: ADD_DETAILS,
      details
    }
  }

  export function addInterests(interests){
    return {
      type: ADD_INTEREST,
      interests
    }
  }

  export function setToken(token){
    return {
      type: setToken,
      token
    }
  }

  export function deleteInterest(interest){
    return {
      type: DELETE_INTEREST,
      interest
    }
  }
