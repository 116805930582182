import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const SwipeLeftIcon = () => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}

      <Icon viewBox="0 0 18 18">
        <G
          width="18"
          height="18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            opacity="0.3"
            d="M12.8105 15.75H7.89805L5.12305 12.915L8.25055 13.5825V5.625C8.25055 5.415 8.41555 5.25 8.62555 5.25C8.83555 5.25 9.00055 5.415 9.00055 5.625V10.26H10.3205L13.5005 11.67L12.8105 15.75Z"
            fill="#888990"
          />
          <Path
            d="M14.1675 10.3275L11.3175 9.075C11.22 9.03 11.1075 9 10.9875 9H10.5V5.625C10.5 5.12772 10.3025 4.65081 9.95082 4.29917C9.59919 3.94754 9.12228 3.75 8.625 3.75C8.12772 3.75 7.65081 3.94754 7.29917 4.29917C6.94754 4.65081 6.75 5.12772 6.75 5.625V11.7375L5.3475 11.4375C5.205 11.415 4.5825 11.325 4.05 11.8575L3 12.915L6.84 16.8075C7.1175 17.0925 7.5 17.25 7.8975 17.25H12.81C13.545 17.25 14.1675 16.725 14.2875 15.9975L14.9775 11.9175C15.0305 11.6007 14.9805 11.2752 14.8346 10.989C14.6888 10.7027 14.455 10.4709 14.1675 10.3275ZM12.81 15.75H7.8975L5.1225 12.915L8.25 13.5825V5.625C8.25 5.415 8.415 5.25 8.625 5.25C8.835 5.25 9 5.415 9 5.625V10.26H10.32L13.5 11.67L12.81 15.75ZM3.0675 4.125H5.25V5.25H1.5V1.5H2.625V3.015C4.365 1.5975 6.585 0.75 9 0.75C13.1175 0.75 15.9525 3.09 16.5 5.25H15.3225C14.7525 3.765 12.555 1.875 9 1.875C6.7275 1.875 4.6575 2.73 3.0675 4.125Z"
            fill="#888990"
          />
        </G>
      </Icon>
    </>
  );
};

export default SwipeLeftIcon;
