import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const LinkIcon = ({ color = "#888990" }) => {
  return (
    <Icon viewBox="0 0 24 24" size={5}>
      <G width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M11.9999 17.0002L10.4999 18.5002C9.83684 19.1631 8.93758 19.5355 7.99994 19.5355C7.0623 19.5355 6.16304 19.1631 5.49994 18.5002V18.5002C4.83701 17.8371 4.4646 16.9378 4.4646 16.0002C4.4646 15.0625 4.83701 14.1633 5.49994 13.5002L8.49994 10.5002C9.16304 9.83726 10.0623 9.46484 10.9999 9.46484C11.9376 9.46484 12.8368 9.83726 13.4999 10.5002V10.5002"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
        />
        <Path
          d="M12 7.00018L13.5 5.50018C14.1631 4.83726 15.0624 4.46484 16 4.46484C16.9376 4.46484 17.8369 4.83726 18.5 5.50018V5.50018C19.1629 6.16329 19.5353 7.06254 19.5353 8.00018C19.5353 8.93783 19.1629 9.83708 18.5 10.5002L15.5 13.5002C14.8369 14.1631 13.9376 14.5355 13 14.5355C12.0624 14.5355 11.1631 14.1631 10.5 13.5002"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
        />
      </G>
    </Icon>
  );
};

export default LinkIcon;
