import { Center, HStack, Text } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { Pages } from "../../../constants/pages";
import { useDispatch, useSelector } from "react-redux";
import PrimaryCTAButton from "../../buttons/primaryCTAButton";
import { Popups } from "../../../constants/popups";
import { setDialog } from "../../../redux/currentState/actions";
import GradientBorderButton from "../../buttons/gradientBorderButton";
const CartScreenBottomBar = ({ shouldProceed }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.id);

  return (
    <HStack
      shadow={9}
      _light={{
        bg: "#FAF1FF",
      }}
      _dark={{
        bg: "#2B2F33",
      }}
      height={"70px"}
      justifyContent={"center"}
      space={2}
      alignItems="center"
    >
      <Center w="45%" h="50px">
        <GradientBorderButton
          bg="#FAF1FF"
          gradientType="2"
          onPress={() => navigation.navigate(Pages.Products)}
        >
          <Text
            _light={{
              color: "#682481",
            }}
            _dark={{
              color: "white",
            }}
            _web={{
              style: {
                fontFamily: "Hero_400Regular",
              },
            }}
            fontSize={"12px"}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
          >
            + Add more products
          </Text>
        </GradientBorderButton>
      </Center>
      <Center w="48%" h="50px">
        <PrimaryCTAButton
          gradientType="2"
          onPress={() => {
            if (userId === null || userId === undefined) {
              dispatch(setDialog(Popups.LoginPopup));
            } else {
              if (shouldProceed) {
                navigation.navigate(Pages.CheckoutScreen);
              }
            }
          }}
        >
          <Text
            textTransform={"uppercase"}
            _light={{
              color: "white",
            }}
            _dark={{
              color: "white",
            }}
            _web={{
              style: {
                fontFamily: "Hero_700Bold",
              },
            }}
            letterSpacing={2}
            fontSize={"12px"}
            lineHeight={16}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
          >
            checkout
          </Text>
        </PrimaryCTAButton>
      </Center>
    </HStack>
  );
};
export default CartScreenBottomBar;
