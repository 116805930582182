import { useNavigation, useRoute } from "@react-navigation/native";
import { HStack, VStack, Text, Pressable } from "native-base";
import { setAnalytics } from "../../../analytics";
import ChevronLeftArrowIcon from "../../../components/icons/chevronLeftArrowIcon";
import RewardsIcon from "../../../components/icons/rewardsIcon";
import { Pages } from "../../../constants/pages";

const RewardsFollowersFollowing = ({
  points,
  following,
  followers,
  userId,
}) => {
  const navigation = useNavigation();
  const route = useRoute();
  return (
    <HStack justifyContent={"center"} space={"24px"} w="full">
      {[
        route?.params?.type === "profile"
          ? {
              label: "FlockBucks",
              count: points,
              onPress: () => {
                if (route?.params?.type === "profile") {
                  setAnalytics("Rewards Button", "Rewards Button Clicked");
                  navigation.navigate(Pages.RewardsScreen);
                }
              },

              icon: <RewardsIcon size={4} />,
            }
          : undefined,
        {
          label: "Followers",
          count: followers,
          onPress: () => {
            if (followers > 0) {
              setAnalytics("Followers Button", "Followers Button Clicked");
              navigation.navigate(Pages.BeautyBuddiesScreen, {
                type: "followers",
                userId: userId,
              });
            }
          },
        },
        {
          label: "Following",
          count: following,
          onPress: () => {
            if (following > 0) {
              setAnalytics("Following Button", "Following Button Clicked");
              navigation.navigate(Pages.BeautyBuddiesScreen, {
                type: "following",
                userId: userId,
              });
            }
          },
        },
      ]
        .filter((k) => k)
        ?.map((item, index) => (
          <Pressable key={index} onPress={item?.onPress}>
            <VStack
              justifyContent={"center"}
              alignItems={"center"}
              bg="#FAF1FF"
              rounded={"20px"}
              w={{
                base: "100px",
                sm: "100px",

                md: "100px",
                lg: "100px",
                xl: "150px",
              }}
              h={"125px"}
            >
              <HStack alignItems={"center"} space={1}>
                {item.icon && item.icon}
                <Text
                  fontSize={"24px"}
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                  color={"#464646"}
                >
                  {item.count}
                </Text>
              </HStack>
              <HStack alignItems={"center"} space={1}>
                <Text
                  fontSize={14}
                  color="#682481"
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                >
                  {item.label}
                </Text>
                {index === 0 && (
                  <ChevronLeftArrowIcon color="#682481" size={3} />
                )}
              </HStack>
            </VStack>
          </Pressable>
        ))}
    </HStack>
  );
};
export default RewardsFollowersFollowing;
