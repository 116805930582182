import axios from "axios";
import useSWR from "swr";
import { useSelector } from "react-redux";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
import { getHeaders } from "../util/utilFunctions";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const fetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });

export default function useGetCartItems() {
  const userId = useSelector((state) => state.user.id);

  const { data, mutate, isValidating, error } = useSWR(
    userId ? `${apiUrl}/api/products/get_cart/?user_id=${userId}` : null,
    fetcher,
    {
      revalidateFirstPage: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  useCheckNetwork(error);
  useLoader(isValidating);
  return { data, mutate, isValidating, error };
}
