import { Text, Modal, HStack, VStack, Slide, ScrollView } from "native-base";
import ChevronBackArrowIcon from "../icons/chevronBackArrowIcon";
import { useGetKeyboardStatus } from "../../hooks/useGetKeyboardStatus";
import ShippingAddressForm from "../forms/shippingAddressForm";
import AddNewAddressHOC from "../../hoc/AddNewAddressHOC";

const ShippingAddressPopUp = ({ navigation }) => {
  const keyBoardStatus = useGetKeyboardStatus();

  return (
    <>
      <Modal
        size={"full"}
        isOpen={true}
        bg="black:alpha.80"
        onClose={() => navigation.goBack()}
      >
        <Slide overlay={false} in={true} placement="bottom">
          <VStack
            height={"90%"}
            roundedTop={"40px"}
            marginBottom={"0"}
            py={4}
            marginTop={"auto"}
            bg={"#2B2F33"}
            space={4}
            width="100%"
            px={4}
          >
            <HStack
              alignItems={"center"}
              justifyContent={"space-between"}
              pt={2}
              px={2}
            >
              <ChevronBackArrowIcon onPress={() => navigation.goBack()} />
            </HStack>

            <ScrollView showsVerticalScrollIndicator={false}>
              <VStack
                space={4}
                pb={
                  keyBoardStatus === "Keyboard Hidden" ||
                  keyBoardStatus === undefined
                    ? 0
                    : 72
                }
              >
                <Text
                  fontSize={16}
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                  color="#94A3B8"
                >
                  Shipping Address
                </Text>

                <AddNewAddressHOC>
                  <ShippingAddressForm />
                </AddNewAddressHOC>
              </VStack>
            </ScrollView>
          </VStack>
        </Slide>
      </Modal>
    </>
  );
};

export default ShippingAddressPopUp;
