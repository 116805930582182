import React from "react";

import { Box, Modal, Pressable, Text, VStack } from "native-base";

const VersionModal = ({ open, setOpen }) => {
  return (
    <Modal size="full" bg="black:alpha.80" isOpen={open}>
      <Modal.Content
        bg={"#2B2F33"}
        rounded={"20px"}
        width={{
          md: "300px",
          lg: "350px",
          xl: "400px",
        }}
      >
        <VStack py={6} space={6} px={6} w="full">
          <VStack space={2} w="full">
            <Text
              textAlign={"center"}
              fontSize={"28px"}
              style={{
                fontFamily: "HK_600SemiBold",
              }}
              color="#ffffff"
            >
              New Version Available
            </Text>
            <Text
              textAlign={"center"}
              px={"20px"}
              fontSize={"16px"}
              style={{
                fontFamily: "HK_500Medium",
              }}
              color="#ffffff"
            >
              Update to continue
            </Text>
          </VStack>
          <Pressable onPress={setOpen}>
            <Box
              alignItems="center"
              flexDirection="row"
              justifyContent="center"
              w="173.35px"
              h="50px"
              alignSelf={"center"}
              bg={{
                linearGradient: {
                  colors: [
                    "primary.ctaLinearFirstColor",
                    "primary.ctaLinearSecondColor",
                  ],
                  start: [0, 0],
                  end: [1, 0],
                },
              }}
              rounded="10px"
              _text={{
                color: "#ffffff",
              }}
            >
              <Text
                color="white"
                style={{
                  fontFamily: "HK_600SemiBold",
                }}
                fontSize="14px"
              >
                Update
              </Text>
            </Box>
          </Pressable>
        </VStack>
      </Modal.Content>
    </Modal>
  );
};

export default VersionModal;
