import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";
import { getPostHeaders } from "./utilFunctions";
//import * as Sentry from "sentry-expo";
const apiUrl = Constants.expoConfig.extra.apiUrl;

console.log(apiUrl, "77777");
export const publicApi = async (path, data, method) => {
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";

  const K = await AsyncStorage.getItem("csrftoken");
  const csrftoken = JSON.parse(K);

  let d = {};
  if (method === "get" || method === "delete") {
    d["params"] = data;
  } else {
    d["data"] = data;
  }

  return axios({
    method: method,
    url: apiUrl + path,
    //url: "http://172.20.10.7:8000" + path,
    headers: await getPostHeaders(path),
    crossDomain: true,
    ...d,
  })
    .then((response) => {
      // Sentry.Native.captureException(response);
      return { body: response.data, status: response.status };
    })
    .catch((error) => {
      // Sentry.Native.captureException(error);
      return { body: error.response.data, status: error.response.status };
    });
};

export const setCsrf = async () => {
  return axios({
    method: "get",

    url: apiUrl + "/api/users/csrf/",
    //url: "http://172.20.10.7:8000" + "/api/users/csrf/",
    crossDomain: true,
  })
    .then((response) => {
      const csrftoken = response.data.csrftoken;
      setVal(JSON.stringify(csrftoken));

      return response;
    })
    .catch((error) => {
      // Sentry.Native.captureException(error);
      return { body: error, status: error.status };
    });
};

export const setVal = async (csrftoken) => {
  await AsyncStorage.setItem("csrftoken", csrftoken);
};

export const privateApi = async (path, data, method) => {
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";
  const K = await AsyncStorage.getItem("csrftoken");
  const csrftoken = JSON.parse(K);

  let d = {};
  if (method === "get" || method === "delete") {
    d["params"] = data;
  } else {
    d["data"] = data;
  }
  let k = await getPostHeaders(path);
  console.log("privateApi", path, k);
  return axios({
    method: method,
    url: apiUrl + path,
    //url: "http://172.20.10.7:8000" + path,
    headers: await getPostHeaders(path),
    crossDomain: true,
    ...d,
  })
    .then((response) => {
      if (
        response.status &&
        response.status !== 401 &&
        response.status !== 402 &&
        response.status !== 403 &&
        response.status !== 404 &&
        response.status !== 500
      ) {
        // Sentry.Native.captureException(response);
        return { body: response.data, status: response.status };
      } else {
        // Sentry.Native.captureException(response);
        return { body: error.response?.data, status: error.response?.status };
      }
    })
    .catch((error) => {
      // Sentry.Native.captureException(error);
      return { body: error.response?.data, status: error.response?.status };
    });
};
