export const HairConcerns = {
  1: "Heat Protection",
  2: "Frizz",
  3: "Hair Thinning",
  4: "Volume",
  5: "Oily Scalp",
  6: "Dry Scalp",
  7: "Damage",
  8: "Color Care",
  9: "Color Fading",
  10: "Curling",
  11: "Curl Enhancing",
  12: "Lengthening",
  13: "High Shine Finish",
  14: "Reduces Brassiness",
};
