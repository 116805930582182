const { Image, useColorMode } = require("native-base");

const ComboSkinTypeIcon = () => {
  const { colorMode } = useColorMode();
  return (
    <Image
      width={62}
      height={70}
      resizeMethod="scale"
      resizeMode="contain"
      source={
        colorMode === "dark"
          ? require("./../../../../assets/images/pngs/grayComboSkin.png")
          : require("./../../../../assets/images/pngs/lightinactivecomboskin.png")
      }
      alt=""
    />
  );
};

export default ComboSkinTypeIcon;
