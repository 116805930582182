import {
  VStack,
  Divider,
  Hidden,
  Text,
  ScrollView,
  HStack,
  Button,
} from "native-base";
import { Dimensions } from "react-native";
import { useDispatch } from "react-redux";
import { changeFilter } from "../../redux/filter/actions";
import Brand from "./brands";
import Categories from "./categories";
import Haircare from "./haircare";
import Skincare from "./skincare";

const FilterSection = () => {
  const dispatch = useDispatch();
  const filters = [
    {
      label: "Categories",
      component: <Categories />,
    },
    {
      label: "Skin Concerns",
      component: <Skincare />,
    },
    {
      label: "Hair Concerns",
      component: <Haircare />,
    },
    {
      label: "Brands",
      component: <Brand />,
    },
  ];

  return (
    <>
      <Hidden from="base" till={"xl"}>
        <ScrollView
          borderRightWidth={"1px"}
          borderRightColor="#E9B9FF"
          w={"full"}
        >
          <VStack
            pr={4}
            w={"full"}
            h={Dimensions.get("screen").height}
            alignItems="center"
          >
            {filters?.map((k, i) => {
              return (
                <VStack w={"full"}>
                  <HStack
                    w={"full"}
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Text
                      color="#682481"
                      fontSize={"24px"}
                      style={{
                        fontFamily: "AustinCyr_400",
                      }}
                    >
                      {k?.label}
                    </Text>
                    <Button
                      onPress={() => {
                        if (i === 0) {
                          dispatch(changeFilter({ productTypes: [] }));
                          dispatch({
                            type: "SET_APPLY_FILTER",
                          });
                        } else if (i === 1) {
                          dispatch(changeFilter({ skinConcerns: [] }));
                          dispatch({
                            type: "SET_APPLY_FILTER",
                          });
                        } else if (i === 2) {
                          dispatch(changeFilter({ concerns: [] }));
                          dispatch({
                            type: "SET_APPLY_FILTER",
                          });
                        } else if (i === 3) {
                          dispatch(changeFilter({ brands: [] }));
                          dispatch({
                            type: "SET_APPLY_FILTER",
                          });
                        }
                      }}
                      _hover={{
                        bg: "#FAF1FF",
                      }}
                      variant={"ghost"}
                      _text={{
                        fontFamily: "Hero_700Bold",
                        fontSize: "16px",
                        color: "#8F8F8F",
                      }}
                    >
                      Reset
                    </Button>
                  </HStack>
                  {k?.component}
                  <Divider bg="#E9B9FF" w="full" />
                </VStack>
              );
            })}
          </VStack>
        </ScrollView>
      </Hidden>
    </>
  );
};

export default FilterSection;
