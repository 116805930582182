import { Center, FlatList, Modal, Stack, Text, View } from "native-base";
import { useContext } from "react";
import AddNewAddressButton from "../../../components/buttons/addNewAddressButton";
import PrimaryCTAButton from "../../../components/buttons/primaryCTAButton";
import ShippingAddressCard from "../../../components/cards/shippingAddressCard";
import { gradientTextWeb } from "../../../constants/gradient";
import { SavedAddressesContext } from "../../../hoc/SavedAddressesHOC";

const SavedAddresses = ({ navigation }) => {
  const { selectedValue, setSelectedValue, addDefaultAddress, data } =
    useContext(SavedAddressesContext);

  return (
    <Modal
      size="full"
      bg="black:alpha.70"
      isOpen={true}
      onClose={() => navigation.goBack()}
    >
      <Modal.Content
        rounded={"20px"}
        w={{
          base: "full",
          sm: "full",
          md: "full",
          lg: "full",
          xl: "45%",
        }}
        bg="white"
        p={{
          base: 4,
          sm: 4,
          md: 4,
          lg: 4,
          xl: "30px",
        }}
      >
        <Stack
          w="full"
          alignItems={"center"}
          justifyContent="center"
          direction={"column"}
          space={6}
        >
          <Text
            letterSpacing={2}
            _web={{
              style: {
                ...gradientTextWeb(1),
                fontFamily: "AustinCyr_400",
              },
            }}
            fontSize="36px"
          >
            Saved Addresses
          </Text>
          <AddNewAddressButton />
          <FlatList
            w="full"
            keyboardShouldPersistTaps="handled"
            showsVerticalScrollIndicator={false}
            ItemSeparatorComponent={() => (
              <View style={{ paddingVertical: 6 }}></View>
            )}
            contentContainerStyle={{
              paddingBottom: 12,
              height: 200,
            }}
            data={data?.addresses}
            initialNumToRender={5}
            renderItem={({ item }) => (
              <ShippingAddressCard
                address={item}
                isAddressPopUp={true}
                onCheck={() => {
                  setSelectedValue(item.id);
                }}
                isChecked={selectedValue === item.id}
              />
            )}
          />
          <Center h="50px" w="200px">
            <PrimaryCTAButton
              gradientType="2"
              onPress={() => addDefaultAddress(selectedValue)}
            >
              <Text
                letterSpacing={2}
                textTransform={"uppercase"}
                fontSize={"16px"}
                style={{
                  fontFamily: "Hero_700Bold",
                }}
                color="white"
              >
                Confirm
              </Text>
            </PrimaryCTAButton>
          </Center>
        </Stack>
      </Modal.Content>
    </Modal>
  );
};

export default SavedAddresses;
