import { useRoute } from "@react-navigation/native";
import { createContext } from "react";
import { useSelector } from "react-redux";
import useGetBuddies from "../hooks/useGetBuddies";
import useGetSearchResults from "../hooks/useGetSearchResults";
import { createCommunityFilters } from "../util/utilFunctions";
export const UsersContext = createContext();

const UsersHOC = (props) => {
  const filter = useSelector((state) => state.communityFilter);
  const filterValues = createCommunityFilters(filter);
  const userId = useSelector((state) => state.user.id);
  const route = useRoute();

  const { data, setSize, size } = useGetBuddies(
    props.fetcherType,
    route?.params?.userId ? route?.params?.userId : userId,
    props?.search,
    filterValues
  );
  const { data: searchResult } = useGetSearchResults(
    "buddySearch",
    props?.search
  );
  return (
    <UsersContext.Provider
      value={{
        data: data ? data : searchResult ? searchResult : [],
        size,
        setSize,
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
};

export default UsersHOC;
