import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const GrayStarIconFilled = ({ size, color = "#58595B" }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={size} viewBox="0 0 30 28">
        <G
          width="30"
          height="28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M5.1103 26.6737C5.5377 24.0737 5.92237 21.491 6.40961 18.9343C6.55492 18.1482 6.34977 17.6472 5.81124 17.1376C4.10162 15.5224 2.44329 13.8725 0.767867 12.2314C0.30627 11.7822 -0.180972 11.3158 0.0669232 10.5729C0.289174 9.89917 0.88754 9.77824 1.49445 9.69186C3.67422 9.37227 5.84543 9.00084 8.03374 8.73307C9.145 8.59487 9.86304 8.17162 10.3332 7.07462C11.1623 5.1484 12.1454 3.29992 13.0771 1.42552C13.3934 0.786329 13.6071 0.0175679 14.5303 0.000292423C15.4535 -0.0169831 15.6928 0.734502 16.0091 1.3737C16.975 3.32583 17.9837 5.26069 18.8727 7.25601C19.2745 8.1457 19.8387 8.56895 20.7704 8.68988C22.7878 8.94038 24.7966 9.2427 26.8054 9.52775C27.1387 9.57957 27.4807 9.6314 27.8055 9.70914C29.2672 10.0806 29.5407 11.0048 28.4637 12.1018C27.002 13.5875 25.5573 15.1164 23.9674 16.4552C22.745 17.4745 22.5313 18.5542 22.8647 20.0313C23.3006 21.992 23.54 23.996 23.8905 25.974C24.0101 26.6305 24.1384 27.2783 23.54 27.7448C22.8818 28.2631 22.2492 27.883 21.6509 27.5634C19.7019 26.5269 17.7358 25.5162 15.8211 24.4106C14.9577 23.9096 14.2397 23.8837 13.3592 24.3847C11.3333 25.5422 9.25612 26.6305 7.17894 27.693C5.97365 28.3062 5.1103 27.8312 5.1103 26.6737Z"
            fill={color}
          />
        </G>
      </Icon>
    </>
  );
};

export default GrayStarIconFilled;
