import { VStack, Text, HStack, Image, Center, Box } from "native-base";
import { useEffect, useRef, useState } from "react";
import BagAnimatedIcon from "./../../../components/icons/animatedIcons/bagAnimatedIcon";
import ConfettiAnimatedIcon from "./../../../components/icons/animatedIcons/confettiAnimatedIcon";
import * as Animatable from "react-native-animatable";
import CreditCardAnimatedIcon from "./../../../components/icons/animatedIcons/creditCardAnimatedIcon";
import ClockAnimatedIcon from "./../../../components/icons/animatedIcons/clockAnimatedIcon";
import EmailAnimatedIcon from "./../../../components/icons/animatedIcons/emailAnimatedIcon";

const FlockDealSteps = ({ isLearnMore }) => {
  const AnimationRef = useRef([]);
  const [count, setCount] = useState(0);

  const [steps, setSteps] = useState([
    {
      icon: (
        <Center size={"36px"} rounded="8px" bg="#58595B">
          <Image
            size={6}
            resizeMethod="scale"
            resizeMode="contain"
            source={require("./../../../assets/images/pngs/cardIcon.png")}
            alt=""
          />
        </Center>
      ),
      activeIcon: <BagAnimatedIcon />,
      description: "Create or join a Flock Deal Group",
      fillCircle: true,
      fillStroke: true,
    },
    {
      icon: (
        <Center size={"36px"} rounded="8px" bg="#58595B">
          <Image
            size={6}
            resizeMethod="scale"
            resizeMode="contain"
            source={require("./../../../assets/images/pngs/cardIcon.png")}
            alt=""
          />
        </Center>
      ),
      activeIcon: <CreditCardAnimatedIcon />,

      description: "Enter your address & payment method",
      fillCircle: false,
      fillStroke: false,
    },
    {
      icon: (
        <Center rounded={"8px"} size={"36px"} bg="#58595B">
          <Image
            size={6}
            resizeMethod="scale"
            resizeMode="contain"
            source={require("./../../../assets/images/pngs/clockIcon.png")}
            alt=""
          />
        </Center>
      ),
      activeIcon: <ClockAnimatedIcon />,
      description: "Invite friends or wait for spots to fill",
      fillCircle: false,
      fillStroke: false,
    },
    {
      icon: (
        <Center size={"36px"} rounded="8px" bg="#58595B">
          <Image
            size={6}
            resizeMethod="scale"
            resizeMode="contain"
            source={require("./../../../assets/images/pngs/confettiIcon.png")}
            alt=""
          />
        </Center>
      ),
      activeIcon: <ConfettiAnimatedIcon />,
      description: " Tada! The order auto completes once the group is filled.",
      fillCircle: false,
      fillStroke: false,
    },
    {
      icon: (
        <Center size={"36px"} rounded="8px" bg="#58595B">
          <Image
            size={6}
            resizeMethod="scale"
            resizeMode="contain"
            source={require("./../../../assets/images/pngs/atIcon.png")}
            alt=""
          />
        </Center>
      ),
      activeIcon: <EmailAnimatedIcon />,
      description: `The product will ship to each group member.`,
      fillCircle: false,
      fillStroke: false,
    },
  ]);

  useEffect(() => {
    AnimationRef.current = AnimationRef.current.slice(0, steps.length);
  }, [steps]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (AnimationRef) {
        AnimationRef.current[count]?.slideInDown();
      }

      if (isLearnMore) {
        if (count === steps.length) {
          clearInterval(interval);
        } else {
          setCount(count + 1);
          let copy = [...steps];
          copy.map((k) => {
            if (k.fillStroke) {
              return (k.fillStroke = false);
            }
          });
          copy[count] = { ...copy[count], fillCircle: true, fillStroke: true };
          setSteps(copy);
        }
      } else {
        if (count === steps.length) {
          clearInterval(interval);
        } else {
          setCount(count + 1);
          let copy = [...steps];
          copy.map((k) => {
            if (k.fillStroke) {
              return (k.fillStroke = false);
            }
          });
          copy[count] = { ...copy[count], fillCircle: true, fillStroke: true };
          setSteps(copy);
        }
      }
    }, 1500);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Center w="80%" pt={4}>
      {steps.map((item, index) => (
        <HStack w="full" key={index} space={4}>
          <HStack space={4}>
            <VStack alignItems={"center"}>
              {index === 0 ? (
                <Box width={4} height={4} rounded={"full"} bg="#8D49DE"></Box>
              ) : (
                <Box
                  width={4}
                  height={4}
                  rounded={"full"}
                  bg={
                    item.fillStroke
                      ? "white"
                      : item.fillCircle
                      ? "#8D49DE"
                      : "#2B2F33"
                  }
                ></Box>
              )}
              <Box width={1.5} height={"80px"} bg={"#1C212E"}></Box>
            </VStack>
          </HStack>
          <VStack flex={1} space={2.5}>
            <HStack>{item.fillCircle ? item.activeIcon : item.icon}</HStack>

            <Text
              fontSize={14}
              lineHeight={14}
              style={{
                fontFamily: "HK_500Medium",
              }}
              color={item.fillCircle ? "white" : "#58595B"}
            >
              {item.description}
            </Text>
          </VStack>
        </HStack>
      ))}
    </Center>
  );
};
export default FlockDealSteps;
