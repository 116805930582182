import { HStack, Text, Pressable } from "native-base";
import { Platform } from "react-native";
import { Popups } from "../../constants/popups";
import PlusIcon from "../icons/plusIcon";
import * as RootNavigation from "./../../RootNavigation";
const AddNewAddressButton = () => {
  const navigation = RootNavigation;
  return (
    <Pressable
      w="full"
      onPress={() => {
        if (Platform.OS === "web") {
          navigation.navigate("address");
        } else {
          navigation.navigate(Popups.AddAddress);
        }
      }}
    >
      <HStack
        _hover={{
          bg: "#FAF1FF",
        }}
        alignItems={"center"}
        borderRadius={"8px"}
        justifyContent="space-between"
        _light={{
          bg: "white",
          borderColor: "#E9B9FF",
          borderWidth: "1px",
        }}
        _dark={{
          bg: "primary.card",
        }}
        p={"16px"}
      >
        <Text
          fontSize={16}
          style={{
            fontFamily: "HK_600SemiBold",
          }}
          _light={{
            color: "#464646",
          }}
          _dark={{
            color: "#94A3B8",
          }}
        >
          Add New Address
        </Text>
        <PlusIcon size={"2.5"} />
      </HStack>
    </Pressable>
  );
};

export default AddNewAddressButton;
