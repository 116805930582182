import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const CloseIcon = ({ size, onPress }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={size} onPress={onPress} viewBox="0 0 31 30">
        <G
          width="31"
          height="30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M20.7854 10.875L19.8915 10L15.6778 14.125L11.4641 10L10.5703 10.875L14.784 15L10.5703 19.125L11.4641 20L15.6778 15.875L19.8915 20L20.7854 19.125L16.5717 15L20.7854 10.875Z"
            fill="#94A3B8"
          />
        </G>
      </Icon>
    </>
  );
};

export default CloseIcon;
