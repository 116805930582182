import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideNetwork, showNetwork } from "../redux/currentState/actions";
export const useCheckNetwork = (error) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (error?.code === "ERR_NETWORK") {
      dispatch(showNetwork());
    } else {
      dispatch(hideNetwork());
    }
  }, [error]);
};
