import { useNavigation, useRoute } from "@react-navigation/native";
import { Center, HStack, Image, Pressable, Text, VStack } from "native-base";
import { ImageBackground } from "react-native";
import { setAnalytics } from "../../analytics";
import { BrandImages } from "../../constants/brands";
import { Pages } from "../../constants/pages";
import { useCreateParams } from "../../hooks/useCreateParams";

const CollectionProductCard = ({ item, bundleId }) => {
  const navigation = useNavigation();
  const route = useRoute();

  const paramsmap = {
    id:
      route?.name === "UserScreen" || route?.name === "profile"
        ? item?.product?.id
        : item?.id,
    sku_id:
      route?.name === "UserScreen" || route?.name === "profile"
        ? item?.id
        : item?.default_sku,
    bundle_id: bundleId,
    source: route?.params?.type !== "profile" && "wishlist",
    otherUserId: route?.params?.type !== "profile" && route?.params?.id,
  };

  const params = useCreateParams(paramsmap);
  return (
    <>
      <Pressable
        onPress={() => {
          setAnalytics("Product Card", "Product Card Clicked", {
            productId:
              route?.name === "UserScreen" || route?.name === "profile"
                ? item?.product?.id
                : item?.id,
            productSkuId:
              route?.name === "UserScreen" || route?.name === "profile"
                ? item?.id
                : item?.default_sku,
            productName:
              route?.name === "UserScreen" || route?.name === "profile"
                ? item?.product_name
                : item?.name,
            brandName: item?.brand_name,
            screen: route?.name,
            type: "Collection Product Card",
          });

          navigation.navigate(Pages.ProductScreen, params);
        }}
      >
        <VStack space={"10px"}>
          <HStack alignItems={"center"} space={"8px"}>
            <Image
              rounded={"full"}
              h={"16px"}
              w={{
                md: "16px",
                lg: "16px",
                xl: "16px",
              }}
              resizeMethod="scale"
              resizeMode="contain"
              source={{
                uri: BrandImages[item?.brand_name],
              }}
              alt=""
            />
            <Text
              w="120px"
              noOfLines={1}
              letterSpacing={2}
              textTransform={"uppercase"}
              _dark={{
                color: "white",
              }}
              _light={{
                color: "#464646",
              }}
              style={{
                fontSize: 10,
                lineHeight: 16,
                fontFamily: "HK_600SemiBold",
              }}
            >
              {item?.brand_name}
            </Text>
          </HStack>
          <Center
            rounded={"20px"}
            h={"150px"}
            w={{
              md: "150px",
              lg: "150px",
              xl: "150px",
            }}
          >
            <ImageBackground
              style={{
                height: "100%",
                width: "100%",

                borderRadius: 20,

                overflow: "hidden",
              }}
              position="relative"
              resizeMethod="scale"
              resizeMode="contain"
              source={{
                uri: item?.images[0],
              }}
              alt=""
            >
              <Center
                roundedBottom={"20px"}
                h={"50px"}
                bottom={0}
                w={{
                  md: "150px",
                  lg: "150px",
                  xl: "150px",
                }}
                bg={{
                  linearGradient: {
                    colors: ["white", "black:alpha.75"],
                  },
                }}
                position={"absolute"}
              >
                <Text
                  w="full"
                  px={3}
                  numberOfLines={2}
                  color="white"
                  style={{
                    fontSize: 14,
                    lineHeight: 17.5,
                    fontFamily: "HK_500Medium",
                  }}
                >
                  {route?.name === "UserScreen" || route?.name === "profile"
                    ? item?.product_name
                    : item?.name}
                </Text>
              </Center>
            </ImageBackground>
          </Center>
        </VStack>
      </Pressable>
    </>
  );
};

export default CollectionProductCard;
