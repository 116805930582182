import { Center, Text } from "native-base";
import { Platform } from "react-native";
import HeadingBar from "../../components/bars/headingBar";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import BuddiesTwoColumnLayout from "../../components/layouts/buddiesTwoColumnLayout";
import UsersHOC from "../../hoc/UsersHOC";

const BeautyBuddiesScreen = ({ route, type }) => {
  return (
    <>
      <HeadingBar>
        <Center>
          <Text
            textTransform={"capitalize"}
            color="#682481"
            fontSize={"36px"}
            style={{
              fontFamily: "AustinCyr_400",
            }}
          >
            {route?.params?.type ? route?.params?.type : type}
          </Text>
        </Center>
      </HeadingBar>
      <UsersHOC fetcherType={route?.params?.type ? route?.params?.type : type}>
        <BuddiesTwoColumnLayout />
      </UsersHOC>

      {route?.name === "BeautyBuddiesScreen" && Platform.OS !== "web" && (
        <BottomBar />
      )}
      {Platform.OS === "web" && <FooterSectionWeb />}
    </>
  );
};

export default BeautyBuddiesScreen;
