import { Text, VStack, ScrollView, Center } from "native-base";
import { useContext } from "react";
import ShippingAddressCard from "../../../components/cards/shippingAddressCard";
import AddNewAddressButton from "../../../components/buttons/addNewAddressButton";
import PrimaryCTAButton from "../../../components/buttons/primaryCTAButton";
import CTAButtonText from "../../../components/texts/ctaButtonText";
import { SavedAddressesContext } from "../../../hoc/SavedAddressesHOC";

const AddressesScreen = () => {
  const { selectedValue, setSelectedValue, addDefaultAddress, data } =
    useContext(SavedAddressesContext);
  return (
    <>
      <VStack px={"10px"} py={4} space={2}>
        <Text
          fontSize={16}
          style={{
            fontFamily: "HK_700Bold",
          }}
          color="#94A3B8"
        >
          Shipping details
        </Text>
        <AddNewAddressButton />
      </VStack>
      <ScrollView px={"10px"} showsVerticalScrollIndicator={false}>
        <VStack pb={2} space={"16px"}>
          {data?.addresses?.map((item) => {
            return (
              <ShippingAddressCard
                address={item}
                isAddressPopUp={true}
                onCheck={() => {
                  setSelectedValue(item.id);
                }}
                isChecked={selectedValue === item.id}
              />
            );
          })}
        </VStack>
      </ScrollView>
      <Center safeAreaBottom>
        <Center w="150px" h="50px">
          <PrimaryCTAButton
            gradientType="1"
            onPress={() => addDefaultAddress(selectedValue)}
          >
            <CTAButtonText text="confirm" />
          </PrimaryCTAButton>
        </Center>
      </Center>
    </>
  );
};

export default AddressesScreen;
