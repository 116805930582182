import FullWidthFilledAccordion from "../../../components/accordions/fullWidthFilledAccordion";
import {
  Button,
  Hidden,
  PresenceTransition,
  ScrollView,
  VStack,
} from "native-base";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Pages } from "../../../constants/pages";
import { setAnalytics } from "../../../analytics";
import useGetRewards from "../../../hooks/useGetRewards";
import AffiliateIcon from "../../../components/icons/affiliateIcon";
import { Platform } from "react-native";
import YourOrders from "./yourOrders";
import AddressesScreen from "./addressesScreen";
import { useState } from "react";
import PaymentMethodsScreen from "./paymentMethodsScreen";
import Reviews from "./reviews";
import AffiliateScreen from "../../affiliateScreens/index";
import { handleLogout } from "../../../util/utilFunctions";
import { useDispatch } from "react-redux";
import Collections from "./collections";
import BeautyQuiz from "./beautyQuiz";
import SavedAddressesHOC from "../../../hoc/SavedAddressesHOC";
import Favorites from "./favorites";
const ExtraInfoAccordions = ({ setComponent, data, component }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();
  const { data: rewards } = useGetRewards("affiliateRewards");

  const logout = async () => {
    await setAnalytics("Logout Button", "Logged Out", {
      screen: route?.name,
    });

    handleLogout();
    dispatch({
      type: "REFRESH",
    });

    navigation.navigate(Pages.Home);
  };
  const [tabs, setTabs] = useState([
    {
      pressed: true,
      label: "Collections",

      onPress: () => {
        if (Platform.OS === "web") {
          setComponent(<Collections />);
        } else {
          navigation.navigate(Pages.AffiliateScreen);
        }
      },
    },
    {
      pressed: false,
      label: "Beauty Quiz",
      onPress: (user) => {
        setComponent(<BeautyQuiz user={user} />);
      },
    },
    {
      pressed: false,
      label: "Favorites",
      onPress: (userId) => {
        setComponent(<Favorites userId={userId} />);
      },
    },
    {
      pressed: false,
      label: "My Affiliate Earnings",
      startIcon: <AffiliateIcon />,
      endText: rewards?.reward_stats?.total_earnings
        ? rewards?.reward_stats?.total_earnings
        : 0,
      onPress: () => {
        if (Platform.OS === "web") {
          setComponent(<AffiliateScreen />);
        } else {
          navigation.navigate(Pages.AffiliateScreen);
        }
      },
    },
    {
      pressed: false,
      label: "Your orders",
      onPress: () => {
        setComponent(<YourOrders />);
      },
    },
    {
      pressed: false,
      label: "Rating and reviews",
      onPress: (reviews, user) => {
        setComponent(<Reviews reviews={reviews} user={user} />);
      },
    },
    {
      pressed: false,
      label: "Addresses",
      onPress: () => {
        setComponent(
          <SavedAddressesHOC>
            <AddressesScreen />
          </SavedAddressesHOC>
        );
      },
    },
    {
      pressed: false,
      label: "Payment Methods",
      onPress: () => {
        setComponent(<PaymentMethodsScreen />);
      },
    },
  ]);
  return (
    <VStack
      px={
        Platform.OS !== "web" && {
          md: "12px",
          lg: "12px",
          xl: "28px",
          xxl: "28px",
        }
      }
      space={"12px"}
      py={Platform.OS !== "web" && "20px"}
    >
      {tabs
        .filter((k) => k !== null)
        .map((item, index) => (
          <>
            {route?.params?.type === "profile" ? (
              <VStack>
                <FullWidthFilledAccordion
                  pressed={item?.pressed}
                  key={index}
                  label={item?.label}
                  onPress={() => {
                    setAnalytics(item?.clicked, `${item?.label} Clicked`);
                    if (index === 5) {
                      item?.onPress(data?.reviews, data?.user[0]);
                    } else if (index === 1) {
                      item?.onPress(data?.user[0]);
                    } else if (index === 2) {
                      item?.onPress(data?.user[0]?.id);
                    } else {
                      item?.onPress();
                    }

                    let copy = tabs.map((i, j) => {
                      return {
                        ...tabs[j],
                        pressed: false,
                      };
                    });

                    copy[index] = { ...copy[index], pressed: !item?.pressed };
                    setTabs(copy);
                  }}
                  startIcon={item?.startIcon}
                  endText={item?.endText}
                />
                {item?.pressed && (
                  <Hidden only={["xl", "xxl"]}>
                    <PresenceTransition
                      visible={item?.pressed}
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                        transition: {
                          duration: 500,
                        },
                      }}
                    >
                      <VStack
                        h="full"
                        w={{
                          base: "column",
                          sm: "column",
                          md: "full",
                          lg: "full",
                          xl: "60%",
                          xxl: "60%",
                        }}
                      >
                        <ScrollView h="full">{component}</ScrollView>
                      </VStack>
                    </PresenceTransition>
                  </Hidden>
                )}
              </VStack>
            ) : (
              <>
                {(index === 0 || index === 1 || index === 2 || index === 5) && (
                  <VStack>
                    <FullWidthFilledAccordion
                      pressed={item?.pressed}
                      key={index}
                      label={item?.label}
                      onPress={() => {
                        setAnalytics(item?.clicked, `${item?.label} Clicked`);
                        if (index === 5) {
                          item?.onPress(data?.reviews, data?.user[0]);
                        } else if (index === 1) {
                          item?.onPress(data?.user[0]);
                        } else if (index === 2) {
                          item?.onPress(data?.user[0]?.id);
                        } else {
                          item?.onPress();
                        }

                        // let copy = [...tabs];
                        let copy = tabs.map((i, j) => {
                          return {
                            ...tabs[j],
                            pressed: false,
                          };
                        });

                        copy[index] = {
                          ...copy[index],
                          pressed: !item?.pressed,
                        };
                        setTabs(copy);
                      }}
                      startIcon={item?.startIcon}
                      endText={item?.endText}
                    />

                    <Hidden only={["xl", "xxl"]}>
                      <PresenceTransition
                        visible={item?.pressed}
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                          transition: {
                            duration: 500,
                          },
                        }}
                      >
                        <VStack
                          h="full"
                          w={{
                            base: "column",
                            sm: "column",
                            md: "full",
                            lg: "full",
                            xl: "60%",
                            xxl: "60%",
                          }}
                        >
                          <ScrollView h="full">{component}</ScrollView>
                        </VStack>
                      </PresenceTransition>
                    </Hidden>
                  </VStack>
                )}
              </>
            )}
          </>
        ))}

      {route?.params?.type === "profile" && (
        <Button
          onPress={logout}
          _hover={{
            bg: "#FAF1FF",
          }}
          variant={"ghost"}
          _text={{
            color: "#B22334",
            fontFamily: "HK_700Bold",

            fontSize: "14px",
            textTransform: "uppercase",
            letterSpacing: 4,
          }}
        >
          Logout
        </Button>
      )}
    </VStack>
  );
};

export default ExtraInfoAccordions;
