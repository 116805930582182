import { useDispatch } from "react-redux";
import { privateApi } from "../util/API";
import { deleteFromFollowing } from "../redux/following/actions";
import {
  hideLoader,
  setDialog,
  showLoader,
} from "../redux/currentState/actions";
import { Popups } from "../constants/popups";
import { useSWRConfig } from "swr";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
export const useUnfollowUser = () => {
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const unfollowUser = async (userId, followingId) => {
    // change to delete api

    dispatch(showLoader());
    if (userId && userId !== null) {
      let res = await privateApi(
        "/api/followers/follower/",
        { follower_id: userId, following_id: followingId },
        "delete"
      );

      if (res.status === 200) {
        //let data = {}
        let followers = "followers";
        let following = "following";

        mutate(
          `${apiUrl}/api/followers/get_followers_list/?user_id=${userId}&query=${followers}`
        );
        mutate(
          `${apiUrl}/api/followers/get_followers_list/?user_id=${userId}&query=${following}`
        );
        mutate(`${apiUrl}/api/users/get_user_details/?user_id=${userId}`);
        mutate(
          `${apiUrl}/api/users/get_person_details/?user_id=${followingId}`
        );

        dispatch(deleteFromFollowing(followingId));
      }
      dispatch(hideLoader());
    } else {
      dispatch(hideLoader());
      dispatch(setDialog(Popups.LoginPopup));
    }
  };

  return unfollowUser;
};
