import { SectionList, View } from "native-base";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import Posts from "../../components/cards/posts";
import TopCategories from "../../components/topCategories";
import TrendingGroups from "../../components/trendingGroups";
import TrendingProducts from "../../components/trendingProducts";
import BeautyBuddies from "../../components/beautyBuddies";
import FlockSuggestsProduct from "../../components/flockSuggestsProduct";
import Reviews from "../../components/reviews";

const CurlyHairFeedScreen = () => {
  const components = [
    {
      data: [
        <TopCategories />,
        <FlockSuggestsProduct />,
        <Reviews />,
        <Posts />,
        <TrendingProducts
          heading="Trending Products For curly Hair"
          image={require("./../../assets/images/pngs/curlyHair.png")}
        />,
        <TrendingGroups
          heading="Trending 
       FlockDeal Groups"
          image={require("./../../assets/images/pngs/fdGroups.png")}
        />,
        <BeautyBuddies
          heading="Beauty Buddies
       like you"
          image={require("./../../assets/images/pngs/bb.png")}
        />,
      ],
    },
  ];
  return (
    <>
      <SectionList
        flex={1}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          paddingBottom: 14,
        }}
        sections={components}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 20 }}></View>
        )}
        showsVerticalScrollIndicator={false}
        stickySectionHeadersEnabled
        renderSectionHeader={(item) => <>{item.section?.header}</>}
        renderItem={({ item }) => item}
      ></SectionList>

      <BottomBar />
    </>
  );
};

export default CurlyHairFeedScreen;
