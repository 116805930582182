import { VStack, FlatList, View } from "native-base";

import { useDispatch, useSelector } from "react-redux";

import { useState, useEffect } from "react";

import SearchBox from "../filterPopUp/buildingBlocks/searchBox";
import CheckBox from "../filterPopUp/buildingBlocks/checkbox";
import { SkinTypes } from "../../../constants/skinTypes";
import { changeFilter } from "../../../redux/communityFilter/actions";

const Item = ({ item, setSelectedSkinType, selectedSkinType }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selected) {
      if (selectedSkinType.includes(item)) {
        setSelected(true);
      }
    }
  }, [selectedSkinType]);

  const onPress = () => {
    if (selectedSkinType.includes(item)) {
      setSelected(false);
      const filtered = selectedSkinType.filter((value) => value !== item);
      setSelectedSkinType(filtered);
    } else {
      setSelected(true);
      setSelectedSkinType([...selectedSkinType, item]);
    }
  };

  return (
    <CheckBox
      onPress={onPress}
      checked={selected}
      label={
        SkinTypes[item] === "Combo"
          ? "Combo Skin"
          : SkinTypes[item]?.replace(/\n|\r/g, " ")
      }
      item={item}
    />
  );
};

const SkinType = ({ setSelectedSkinType, selectedSkinType }) => {
  const [skinTypeResults, setSkinTypeResults] = useState(
    Object.keys(SkinTypes)
  );

  const renderItem = ({ item }) => (
    <Item
      item={item}
      selectedSkinType={selectedSkinType}
      setSelectedSkinType={setSelectedSkinType}
    />
  );

  return (
    <VStack width={"100%"} pb={8} px={4} space={6}>
      <SearchBox
        onChange={async (val) => {
          let skinTypes = Object.keys(SkinTypes);
          let skinTypeResult = skinTypes.filter((skinType) => {
            return SkinTypes[skinType]
              .toLowerCase()
              .includes(val.toLowerCase());
          });
          setSkinTypeResults(skinTypeResult);
        }}
      />

      <FlatList
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 11 }}></View>
        )}
        contentContainerStyle={{
          paddingBottom: 12,
        }}
        data={skinTypeResults}
        initialNumToRender={12}
        renderItem={renderItem}
      />
    </VStack>
  );
};

export default SkinType;
