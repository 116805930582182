import { Modal, Stack } from "native-base";
import AddNewAddressHOC from "../../../hoc/AddNewAddressHOC";
import ShippingAddressForm from "../../forms/shippingAddressForm";
const ShippingAddressModal = ({ navigation }) => {
  return (
    <Modal
      size="full"
      bg="black:alpha.20"
      isOpen={true}
      onClose={() => navigation.goBack()}
    >
      <Modal.Content
        rounded={"20px"}
        w={{
          md: "full",
          lg: "full",
          xl: "45%",
        }}
        bg="white"
        p={{
          md: 4,
          lg: 4,
          xl: "30px",
        }}
      >
        <Stack
          alignItems={"center"}
          justifyContent="space-evenly"
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
        >
          <AddNewAddressHOC>
            <ShippingAddressForm />
          </AddNewAddressHOC>
        </Stack>
      </Modal.Content>
    </Modal>
  );
};

export default ShippingAddressModal;
