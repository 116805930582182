import { Text } from "native-base";
import { useContext } from "react";
import { TimerContext } from "../../hoc/TimerHOC";

const GroupCardTimerText = () => {
  const timer = useContext(TimerContext);

  return (
    <Text
      fontSize={12}
      style={{
        fontFamily: "HK_400Regular",
      }}
      color="#ffffff"
    >
      Expires in:{"  "}
      <Text
        fontSize={14}
        style={{
          fontFamily: "HK_500Medium",
        }}
        color="#ffffff"
      >
        {timer}
      </Text>
    </Text>
  );
};

export default GroupCardTimerText;
