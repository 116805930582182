import { useRoute } from "@react-navigation/native";
import { Center, Input, Text, VStack } from "native-base";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { SignupContext } from "../../hoc/SignupHOC";
import PrimaryCTAButton from "../buttons/primaryCTAButton";
import Error from "../error";
import OTP from "../otp";
import CTAButtonText from "./../../components/texts/ctaButtonText";

const VerifyOtpForm = () => {
  const route = useRoute();
  const {
    countryCode,
    first,
    second,
    third,
    fourth,
    fifth,
    sixth,
    setFirst,
    setSecond,
    setThird,
    setFourth,
    setFifth,
    setSixth,
    emailOtpVerificationError,
    phoneOtpVerificationError,
    verifyOtpPhone,
    verifyOtpEmailSignup,
  } = useContext(SignupContext);
  const form = useSelector((state) => state.form);

  return (
    <VStack
      space={"20px"}
      pt={12}
      px={{
        md: "10px",
        lg: "28px",
        xl: "28px",
      }}
      h="full"
    >
      <Input
        isReadOnly={true}
        value={
          route?.params?.type === "phone" ? form?.phone_number : form?.email
        }
        fontSize={"16px"}
        lineHeight={"21px"}
        autoCapitalize="none"
        style={{
          fontFamily: "HK_700Bold",
        }}
        placeholderTextColor={"white"}
        borderWidth={"1px"}
        borderColor={"#58595B"}
        p={"16px"}
        rounded={"8px"}
        color="white"
        _focus={{
          borderColor: "#58595B",
          backgroundColor: "black",
          color: "white",
        }}
      ></Input>
      <VStack space={"6px"}>
        <Text
          color={"#94A3B8"}
          fontSize={"16px"}
          letterSpacing={"sm"}
          style={{
            fontFamily: "HK_500Medium",
          }}
        >
          Enter Code
        </Text>

        <OTP
          first={first}
          second={second}
          third={third}
          fourth={fourth}
          fifth={fifth}
          sixth={sixth}
          setFirst={(val) => setFirst(val)}
          setSecond={(val) => setSecond(val)}
          setThird={(val) => setThird(val)}
          setFourth={(val) => setFourth(val)}
          setFifth={(val) => setFifth(val)}
          setSixth={(val) => setSixth(val)}
        />
        {(emailOtpVerificationError || phoneOtpVerificationError) && (
          <Error
            error={emailOtpVerificationError || phoneOtpVerificationError}
          />
        )}
      </VStack>

      <Center>
        <Center h="50px" w="173.35px">
          <PrimaryCTAButton
            onPress={() => {
              let otp = first + second + third + fourth + fifth + sixth;
              if (form?.email && !form?.phone_number) {
                verifyOtpEmailSignup(form?.email, otp, route?.params?.type);
              } else if (form?.phone_number && !form?.email) {
                verifyOtpPhone(`+${countryCode}${form?.phone_number}`, otp);
              }
            }}
            gradientType="2"
          >
            <CTAButtonText text="Next" />
          </PrimaryCTAButton>
        </Center>
      </Center>
    </VStack>
  );
};

export default VerifyOtpForm;
