import React from "react";

import { Box, Text } from "native-base";
import { useSelector } from "react-redux";

const NetworkPopUp = () => {
  const network = useSelector((state) => state.currentState.network);
  return (
    <>
      {network && (
        <Box bg="red.800" w="full" safeAreaTop>
          <Text
            textAlign={"center"}
            fontSize={"14px"}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
            color="#ffffff"
          >
            Check your internet connection!
          </Text>
        </Box>
      )}
    </>
  );
};

export default NetworkPopUp;
