import { State } from "react-native-gesture-handler";
import {
  RECIEVED_USER,
  ADD_DETAILS,
  ADD_INTEREST,
  DELETE_INTEREST,
  SET_TOKEN,
} from "./constants";

const initialState = {
  id: undefined,
  username: "",
  email: "",
  push_token: "",
  image: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REFRESH":
      return {
        id: undefined,
        username: "",
        email: "",
        push_token: "",
        image: "",
      };
    case SET_TOKEN:
      return {
        ...state,
        push_token: action.token,
      };
    case RECIEVED_USER:
      return {
        ...state,
        ...action.user,
      };

    case ADD_DETAILS:
      return {
        ...state,
        ...action.details,
      };

    case ADD_INTEREST:
      let it = [];
      if (state.interests === null || state.interests === undefined) {
        it = [];
      } else if (state.interests.length > 0) {
        it = state.interests;
      }
      return {
        ...state,
        interests: [...it, action.interest],
      };

    case DELETE_INTEREST:
      return {
        ...state,
        interests: [
          ...state.interests.filter((interest) => interest !== action.interest),
        ],
      };

    default:
      return state;
  }
};

export default userReducer;
