import { useDispatch } from "react-redux";
import { getUser } from "../util/utilFunctions";
import { privateApi } from "../util/API";
import { hideLoader, showLoader } from "../redux/currentState/actions";
import { setAnalytics } from "../analytics";
import { useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
import { useState } from "react";
import { useSWRConfig } from "swr";

export const useCreateCollection = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [error, setError] = useState(null);
  const { mutate } = useSWRConfig();
  const createCollection = async (
    collectionName,
    productIds,
    collectionId,
    method
  ) => {
    dispatch(showLoader());
    const userId = await getUser();
    if (userId !== null && userId !== undefined) {
      const values3 = {
        user_id: userId,
        name: collectionName,
        product_skus: productIds?.join(),
      };
      if (method === "put") {
        values3["collection_id"] = collectionId;
      }

      let res = await privateApi("/api/products/collection/", values3, method);

      if (res?.body?.status === 400) {
        setError(res.body?.message);
        setAnalytics("Create Collection", "Collection Creation Failed", {
          status: res?.body?.status,
          message: res.body?.message,
        });
        navigation.goBack();
      }
      if (res?.body?.status === 200) {
        setAnalytics("Create Collection", "Collection Created", {
          status: res?.body?.status,
        });
        mutate(`${apiUrl}/api/users/get_user_details/?user_id=${userId}`);
        navigation.goBack();
      }
      dispatch(hideLoader());
    } else {
    }
    dispatch(hideLoader());
  };

  return { createCollection };
};
