import { Icon } from "native-base";
import {
  ClipPath,
  Defs,
  G,
  LinearGradient,
  Path,
  Stop,
} from "react-native-svg";
const BagHeartIcon = () => {
  return (
    <Icon viewBox="0 0 16 17">
      <G clip-path="url(#clip0_5729_12543)">
        <Path
          d="M11.5 4.57617V4.07617C11.5 3.14791 11.1313 2.25768 10.4749 1.6013C9.8185 0.944921 8.92826 0.576172 8 0.576172C7.07174 0.576172 6.1815 0.944921 5.52513 1.6013C4.86875 2.25768 4.5 3.14791 4.5 4.07617V4.57617H1V14.5762C1 15.1066 1.21071 15.6153 1.58579 15.9904C1.96086 16.3655 2.46957 16.5762 3 16.5762H13C13.5304 16.5762 14.0391 16.3655 14.4142 15.9904C14.7893 15.6153 15 15.1066 15 14.5762V4.57617H11.5ZM8 1.57617C8.66304 1.57617 9.29893 1.83956 9.76777 2.3084C10.2366 2.77725 10.5 3.41313 10.5 4.07617V4.57617H5.5V4.07617C5.5 3.41313 5.76339 2.77725 6.23223 2.3084C6.70107 1.83956 7.33696 1.57617 8 1.57617ZM8 8.56917C9.664 6.85817 13.825 9.85217 8 13.7012C2.175 9.85117 6.336 6.85817 8 8.56917Z"
          fill="url(#paint0_linear_5729_12543)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_5729_12543"
          x1="1"
          y1="0.576172"
          x2="19.6584"
          y2="6.63181"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stop-color="#691DE3" />
          <Stop offset="0.0001" stop-color="#FD6699" />
          <Stop offset="1" stop-color="#691DE3" />
        </LinearGradient>
        <ClipPath id="clip0_5729_12543">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.576172)"
          />
        </ClipPath>
      </Defs>
    </Icon>
  );
};

export default BagHeartIcon;
