import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
import { useSWRConfig } from "swr";
import { useState, useEffect } from "react";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { privateApi, publicApi } from "../../util/API";
import { getUser } from "../../util/utilFunctions";
import { setAnalytics } from "../../analytics";
import { Popups } from "../../constants/popups";
import {
  goBackDialog,
  hideLoader,
  setDialog,
} from "../../redux/currentState/actions";
import { useDispatch } from "react-redux";
import { Center, Pressable, ScrollView, Text } from "native-base";
import PrimaryCTAButton from "../buttons/primaryCTAButton";
import { useNavigation } from "@react-navigation/native";
import { gradientTextWeb } from "../../constants/gradient";
const stripePromise = loadStripe(
  "pk_live_51KOVbBK8eSX7WQZrY6dhvviiP7raMNKfUMSdWDmXy0yi9RZqUFl6cTev2iNe39ZLsTf5wrjGBlrFRZzz7LL3AQOq00FPb2MoAt"
);
const PaymentMethodPopUp = () => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    createSetupIntentOnBackend();
  }, []);
  const createSetupIntentOnBackend = async () => {
    let clientSecret = "";
    let customerId = "";
    try {
      let p = await publicApi("/api/payments/secret/", {}, "get").then(
        (response) => {
          clientSecret = response.body.client_secret;
          customerId = response.body.customer_id;
          setClientSecret(clientSecret);
          options.clientSecret = clientSecret;
        }
      );
    } catch (ex) {
      console.warn(ex);
    }
    return { clientSecret, customerId };
  };

  let options = {
    clientSecret: clientSecret,
    appearance: {
      labels: "floating",
    },
  };

  return (
    <>
      {clientSecret !== "" ? (
        <Elements stripe={stripePromise} options={options}>
          <Setup />
        </Elements>
      ) : null}
    </>
  );
};

const Setup = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState("");
  const navigation = useNavigation();

  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();
  let options = {
    clientSecret: clientSecret,
    appearance: {
      labels: "floating",
    },
  };

  const createSetupIntentOnBackend = async () => {
    let clientSecret = "";
    let customerId = "";
    try {
      let p = await publicApi("/api/payments/secret/", {}, "get").then(
        (response) => {
          clientSecret = response.body.client_secret;
          customerId = response.body.customer_id;
          setClientSecret(clientSecret);
          options.clientSecret = clientSecret;
        }
      );
    } catch (ex) {
      console.warn(ex);
    }
    return { clientSecret, customerId };
  };
  const handleRequest = async () => {
    let userId = await getUser();

    try {
      if (!stripe || !elements) {
        return;
      }
      if (userId !== null && userId !== undefined) {
        let { customerId } = await createSetupIntentOnBackend();
        const result = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: "http://localhost:19006/Checkout",
          },
          redirect: "if_required",
        });

        if (result.error) {
          console.log("add loader", result);
        } else {
          const r = await privateApi(
            "/api/payments/save_details/",
            {
              setup_intent_id: result.setupIntent.id,
              payment_method_id: result.setupIntent.payment_method,
              user_id: userId,
              customer_id: customerId,
            },
            "post"
          );
          console.log("/api/payments/save_details/", r, {
            setup_intent_id: result.setupIntent.id,
            payment_method_id: result.setupIntent.payment_method,
            user_id: userId,
            customer_id: customerId,
          });
        }

        mutate(`${apiUrl}/api/users/get_payment_details/?user_id=${userId}`);
        mutate(`${apiUrl}/api/payments/get_cards/?user_id=${userId}`);
      } else {
        setAnalytics(
          "Add payment details button",
          `Guest User Tried Adding Payment Details`,
          {
            screen: route?.name,
          }
        );
        dispatch(setDialog(Popups.LoginPopup));
      }
    } catch (ex) {
      console.warn(ex);
    }

    dispatch(hideLoader());
    dispatch(goBackDialog());
    navigation.goBack();
  };

  return (
    <>
      <Pressable
        size={"full"}
        bg="black:alpha.40"
        onPress={() => navigation.goBack()}
      ></Pressable>
      <Center
        position="absolute"
        left={0}
        top={0}
        bottom={0}
        right={0}
        margin={"auto"}
        rounded={"20px"}
        w={{
          base: "full",
          sm: "full",
          md: "full",
          lg: "full",
          xl: "50%",
        }}
        _dark={{
          bg: "#2B2F33",
        }}
        _light={{
          bg: "white",
        }}
        p={{
          base: 4,
          sm: 4,
          md: 4,
          lg: 4,
          xl: "30px",
        }}
        h="393px"
      >
        <ScrollView
          w="full"
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
        >
          <Text
            textAlign={"center"}
            letterSpacing={2}
            _web={{
              style: {
                ...gradientTextWeb(1),
                fontFamily: "AustinCyr_400",
              },
            }}
            fontSize="36px"
          >
            Add Payment Method
          </Text>

          <PaymentElement />

          <Center>
            <Center h="50px" w="200px">
              <PrimaryCTAButton gradientType="2" onPress={handleRequest}>
                <Text
                  letterSpacing={2}
                  textTransform={"uppercase"}
                  fontSize={"16px"}
                  style={{
                    fontFamily: "Hero_700Bold",
                  }}
                  color="white"
                >
                  Confirm
                </Text>
              </PrimaryCTAButton>
            </Center>
          </Center>
        </ScrollView>
      </Center>
    </>
  );
};

export default PaymentMethodPopUp;
