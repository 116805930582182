import {
  Button,
  Hidden,
  HStack,
  Image,
  Modal,
  ScrollView,
  Stack,
  Text,
  VStack,
} from "native-base";
import { useState } from "react";
import LoginElement from "../../components/modals/loginSignup/loginElement";
import SignupElement from "../../components/modals/loginSignup/signupElement";
import { useInitialiseForm } from "../../hooks/useInitialiseForm";
const Login = ({ navigation }) => {
  const [action, setAction] = useState("login");
  useInitialiseForm();

  return (
    <Modal
      size="full"
      bg="black:alpha.70"
      isOpen={true}
      onClose={() => navigation.goBack()}
    >
      <Modal.Content
        rounded={"20px"}
        py={{
          md: 4,
          lg: 4,
          xl: 16,
        }}
        w={{
          base: "full",
          sm: "full",
          md: "full",
          lg: "full",
          xl: "60%",
        }}
        bg="white"
        h={action === "login" ? "646px" : "1035px"}
        justifyContent="center"
      >
        <Stack
          h="full"
          alignItems={"center"}
          justifyContent="space-evenly"
          direction={{
            base: "column-reverse",
            sm: "column-reverse",
            md: "column-reverse",
            lg: "column-reverse",
            xl: "row",
          }}
        >
          <VStack space={"25px"}>
            <VStack>
              <Text
                color={"#682481"}
                fontSize="46px"
                style={{
                  fontFamily: "AustinCyr_400",
                }}
              >
                {action === "login" ? "Welcome!" : "Signup"}
              </Text>
              <Text
                color={"#464646"}
                fontSize="14px"
                style={{
                  fontFamily: "Hero_400Regular",
                }}
              >
                {action === "login"
                  ? "Enter your details to Login"
                  : "Become a member of Flock"}
              </Text>
            </VStack>
            <ScrollView w="full" showsVerticalScrollIndicator={false}>
              <VStack space={"20px"} p={2}>
                {action === "login" ? <LoginElement /> : <SignupElement />}
                <HStack alignItems="center">
                  <Text
                    textTransform={"none"}
                    fontSize={"11px"}
                    style={{
                      fontFamily: "Hero_400Regular",
                    }}
                    color="#2B2B2B"
                  >
                    {action === "login"
                      ? "Don’t have an account ?"
                      : "Already have an account ?"}
                  </Text>
                  <Button
                    onPress={() => {
                      if (action === "login") {
                        setAction("signup");
                      } else {
                        setAction("login");
                      }
                    }}
                    h="17px"
                    variant="ghost"
                    _text={{
                      fontFamily: "Hero_700Bold",
                      color: "#2B2B2B",
                      fontSize: "11px",
                    }}
                  >
                    {action === "login" ? "Sign up" : "Log in"}
                  </Button>
                </HStack>
              </VStack>
            </ScrollView>
          </VStack>
          <Hidden from="base" till={"xl"}>
            <Image
              alt=""
              w={{
                xl: "45%",
              }}
              h={"full"}
              resizeMode="contain"
              resizeMethod="scale"
              fallbackSource={require("./../../assets/images/pngs/login.png")}
              source={require("./../../assets/images/pngs/login.png")}
            />
          </Hidden>
        </Stack>
      </Modal.Content>
    </Modal>
  );
};

export default Login;
