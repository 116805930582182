import { Text } from "native-base";

const GradientText = ({ text }) => {
  return (
    <Text
      textTransform={"uppercase"}
      lineHeight={"21px"}
      fontSize={"14px"}
      style={{
        color: "white",
        fontFamily: "HK_700Bold",
        backgroundColor: "transparent",
        fontWeight: "bold",
      }}
      letterSpacing={3}
    >
      {text}
    </Text>
  );
};

export default GradientText;
