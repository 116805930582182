import { useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";

export const useCreatePath = () => {
  const route = useRoute();
  const [path, setPath] = useState(`/${route?.name}/`);
  useEffect(() => {
    Object.keys(route?.params)?.map((k, i) => {
      setPath(
        (prev) =>
          `${prev}${route?.params[k]}${
            i < Object.keys(route?.params).length - 1 ? "/" : ""
          }`
      );
    });
  }, []);

  return path;
};
