import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const WebPlaystoreIcon = () => {
  return (
    <Icon viewBox="0 0 20 22">
      <G>
        <Path
          d="M1.58867 0.13249L14.3511 7.10783L10.7832 10.5703L0.312134 0.316981C0.476045 0.15186 0.689252 0.0445513 0.919542 0.0112686C1.14983 -0.022014 1.3847 0.0205371 1.58867 0.13249Z"
          fill="#0AE577"
        />
        <Path
          d="M10.7832 10.5718L0.19248 20.8503C0.0661266 20.6697 -0.0011119 20.4545 2.80525e-05 20.2341V1.07588C-0.00100019 0.934836 0.0262483 0.79502 0.0801632 0.664682C0.134078 0.534345 0.213565 0.416129 0.313934 0.317017L10.7832 10.5718Z"
          fill="#40C3FF"
        />
        <Path
          d="M19.1185 9.7123C19.8653 10.1198 19.8653 11.1897 19.1185 11.5983L14.4423 14.1531L10.7832 10.5717L14.3512 7.10925L19.1185 9.7123Z"
          fill="#FFC826"
        />
        <Path
          d="M14.4423 14.1531L1.58866 21.1775C1.35629 21.3049 1.08504 21.3419 0.827008 21.2815C0.568974 21.2212 0.34235 21.0676 0.190674 20.8503L10.7832 10.5718L14.4423 14.1531Z"
          fill="#FF3945"
        />
      </G>
    </Icon>
  );
};

export default WebPlaystoreIcon;
