import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAddToWishlist } from "../hooks/useAddToWishlist";
import { useRemoveFromWishlist } from "../hooks/useRemoveFromWishlist";
export const WishlistContext = createContext();
const WishlistHOC = (props) => {
  const wishlist = useSelector((state) => state.wishlist[props?.productSkuId]);
  const [filled, setFilled] = useState(false);
  const removeFromWishlist = useRemoveFromWishlist();
  const addToWishlist = useAddToWishlist();

  useEffect(() => {
    if (wishlist !== undefined && wishlist !== null) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [props?.productSkuId, wishlist]);

  const onPress = () => {
    if (wishlist === undefined || wishlist === null) {
      addToWishlist(props?.productSkuId);
    } else {
      removeFromWishlist(props?.productSkuId);
    }
  };

  return (
    <WishlistContext.Provider
      value={{
        onPress,
        filled,
      }}
    >
      {props.children}
    </WishlistContext.Provider>
  );
};

export default WishlistHOC;
