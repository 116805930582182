import { HStack, Pressable } from "native-base";

import { BackIcon } from "./buildingBlocks/backIcon";
import TopBar from "./templates/topBar";
import { Popups } from "../../../constants/popups";
import { setDialog } from "../../../redux/currentState/actions";
import { useDispatch } from "react-redux";
import BlueSendIcon from "../../icons/blueSendIcon";
import AffiliateIcon from "../../icons/affiliateIcon";

const ProductScreenTopBar = () => {
  const dispatch = useDispatch();
  return (
    <TopBar
      content={
        <>
          <BackIcon />
          <Pressable onPress={() => dispatch(setDialog(Popups.RefferalModal))}>
            <HStack
              alignItems="center"
              space={1}
              bg="#2B2F33"
              h="30px"
              borderRadius={"6px"}
              px="6px"
              py="5px"
            >
              <AffiliateIcon />

              <BlueSendIcon />
            </HStack>
          </Pressable>
        </>
      }
    />
  );
};
export default ProductScreenTopBar;
