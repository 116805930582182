import BeautyQuizScreen from "./beautyQuizCover";
import { useDispatch } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import { useCallback } from "react";
import { hideLoader } from "../../redux/currentState/actions";
import { initialiseForm } from "../../redux/form/actions";
const LetsRollScreen = ({ navigation, route }) => {
  const dispatch = useDispatch();
  useFocusEffect(
    useCallback(() => {
      dispatch(hideLoader());
      dispatch(initialiseForm());
    }, [])
  );
  return (
    <>
      {/* <VStack safeArea alignItems={"center"}>
        <Center pb={"9px"}>
          <Image
            size={{
              md: "270px",
              lg: "350px",
              xl: "450px",
            }}
            resizeMethod="scale"
            resizeMode="contain"
            source={require("./../../assets/images/pngs/flockNeonLogo.png")}
            alt=""
          />
        </Center>
        <VStack space={"24px"}>
          <VStack space={"4px"}>
            <Text
              textAlign={"center"}
              color={"white"}
              fontSize={"36px"}
              lineHeight={"45px"}
              style={{
                fontFamily: "Poppins_500Medium",
              }}
            >
              Customize your experience!
            </Text>
            <MaskedView
              maskElement={
                <Text
                  fontSize={"30px"}
                  lineHeight={"37.5px"}
                  textAlign="center"
                  style={{
                    fontFamily: "Poppins_500Medium",
                    backgroundColor: "transparent",
                    fontWeight: "bold",
                  }}
                >
                  More of you, less of the rest.
                </Text>
              }
            >
              <LinearGradient
                start={{ x: 0, y: 3 }}
                end={{ x: 1, y: 1 }}
                colors={["#691DE3", "#FD6699", "#691DE3"]}
              >
                <Text
                  textAlign="center"
                  lineHeight={"37.5px"}
                  fontSize={"30px"}
                  style={{
                    opacity: 0,
                    fontFamily: "Poppins_500Medium",
                    backgroundColor: "transparent",
                    fontWeight: "bold",
                  }}
                  letterSpacing={2}
                >
                  More of you, less of the rest.
                </Text>
              </LinearGradient>
            </MaskedView>
          </VStack>
          <VStack space={"20px"}>
            <Text
              textAlign={"center"}
              color={"white"}
              fontSize={18}
              style={{
                fontFamily: "Poppins_500Medium",
              }}
              lineHeight={27}
            >
              Take your Beauty Quiz!
            </Text>
            <Center>
              <LetsRollButton
                onPress={() => {
                  setAnalytics(
                    "LetsRollButton survey screen",
                    "LetsRollButton clicked",
                    {
                      screen: route.name,
                    }
                  );
                  navigation.navigate(Pages.AboutYourSelfScreen);
                }}
              />
            </Center>
          </VStack>
        </VStack>
        <Center pt="30px">
          <DoItLaterButton
            onPress={() => {
              setAnalytics(
                "DoItLaterButton survey screen",
                "DoItLaterButton clicked",
                {
                  screen: route.name,
                }
              );
              navigation.navigate(Pages.Home);
            }}
          />
        </Center>
      </VStack> */}
      <BeautyQuizScreen />
    </>
  );
};

export default LetsRollScreen;
