import { useRoute } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { createFilters } from "../util/utilFunctions";

export const useFilters = (fetcherType) => {
  const route = useRoute();
  const filter = useSelector((state) => state.filter);
  const filterValues = createFilters(filter);
  const productsFilter = useSelector((state) => state.productsFilter);
  const productsFilterValues = createFilters(productsFilter);

  const homeFilters = {
    ...filterValues,
  };

  const productsFilters = {
    ...productsFilterValues,
    price_sort:
      route?.params?.sort === "Low to High"
        ? "ascprice"
        : route?.params?.sort === "High to Low"
        ? "descprice"
        : null,
  };

  const fetcherTypeFiltersMap = {
    categoryProducts: productsFilters,
    homeProducts: homeFilters,
  };

  return fetcherTypeFiltersMap[fetcherType];
};
