import { privateApi, publicApi } from "../util/API";
import { Pages } from "../constants/pages";
import { useDispatch, useSelector } from "react-redux";
import {
  closeDialog,
  hideLoader,
  showLoader,
} from "../redux/currentState/actions";
import { setAnalytics } from "../analytics";
import { Keyboard } from "react-native";
import { storeFormError } from "../redux/form/actions";
import { getPushToken, setUser } from "../util/utilFunctions";
import { recievedUser } from "../redux/user/actions";

import * as RootNavigation from "../RootNavigation";
import { useGetCountryCodeWeb } from "./useGetCountryCodeWeb";
import { useState } from "react";

export const usePhoneLogin = () => {
  const [error, setError] = useState();
  const countryCode = useGetCountryCodeWeb();
  const route = RootNavigation.navigationRef.getCurrentRoute();
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const handlePhoneLogin = async (code) => {
    Keyboard.dismiss();
    console.log("code", code, form?.phone_number);
    if (!code || isNaN(code)) {
      setError("Fill code");
      return;
    }

    if (
      form?.phone_number &&
      code &&
      !/\s/g.test(form?.phone_number) &&
      !/\s/g.test(code)
    ) {
      dispatch(showLoader());
      try {
        let res = await publicApi(
          "/api/users/login/",

          {
            phone: `+${countryCode}${form?.phone_number}`,
            code: code,
            login_type: "7",
          },

          "post"
        );

        if (res.status === 200) {
          let res2 = await publicApi(
            "/api/users/get_user/",
            {
              id: JSON.parse(res.body.user)?.user_id,
              login_type: "7",
            },
            "get"
          );

          setUser(
            JSON.parse(res2.body.id)[0],
            JSON.parse(res.body.token),
            JSON.parse(res.body.user)?.username,
            JSON.parse(res.body.auth_token)
          );
          dispatch(recievedUser({ id: JSON.parse(res2.body.id)[0] }));
          const push_token = await getPushToken();
          if (push_token !== null && push_token !== undefined) {
            const res3 = await privateApi(
              "/api/users/push_token/",
              {
                token: push_token,
                date: "23135134",
                active: true,
                user: JSON.parse(res2.body.id)[0],
              },
              "put"
            );
          }
          let stringUserid = JSON.parse(res2.body.id)[0];

          setAnalytics("Login Button", "Logged In Via Phone", {
            screen: route?.name,
          });
          dispatch(closeDialog());
          RootNavigation.navigate(Pages.Home);
        } else {
          setError(res.body);
          dispatch(storeFormError(res.body));
        }
      } catch (error) {
        dispatch(storeFormError("Something went wrong!"));
      }

      dispatch(hideLoader());
    }
  };

  return { handlePhoneLogin, error, setError };
};
