import { HStack, Text, Pressable } from "native-base";
import { useNavigation } from "@react-navigation/native";
import ChevronLeftArrowIcon from "../../icons/chevronLeftArrowIcon";
import { Dimensions } from "react-native";
import { Pages } from "../../../constants/pages";
const OrderSuccessfulScreenTopBar = () => {
  const navigation = useNavigation();
  return (
    <>
      <HStack
        safeAreaTop
        width={Dimensions.get("window").width}
        shadow={9}
        bg="#1A1C1E"
        zIndex={1}
        height={Dimensions.get("window").height / 7}
        alignItems="center"
        px={6}
        justifyContent={"flex-end"}
      >
        <Pressable onPress={() => navigation.navigate(Pages.Orders)}>
          <HStack alignItems={"center"} space={2}>
            <Text
              color={"#1A94FF"}
              fontSize={14}
              style={{
                fontFamily: "HK_600SemiBold",
              }}
            >
              All Orders
            </Text>
            <ChevronLeftArrowIcon color={"#1A94FF"} />
          </HStack>
        </Pressable>
      </HStack>
    </>
  );
};

export default OrderSuccessfulScreenTopBar;
