export const HAIRCOLOREMAP = {
  Blonde: "#CBC879",
  Black: "#303030",
  Brown: "#673E27",
  Red: "#BB3737",
  Multi: {
    linearGradient: {
      colors: ["#894722", "#691DE3", "#0F9737"],
      start: [0, 0],
      end: [1, 0],
    },
  },
};
