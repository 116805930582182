import { Center, Image, Text, VStack } from "native-base";
import { setAnalytics } from "../../analytics";
import ExploreFlockButton from "../../components/buttons/exploreFlockButton";
import { Pages } from "../../constants/pages";
import { useSWRConfig } from "swr";
import Constants from "expo-constants";
import { useSelector } from "react-redux";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import { Platform } from "react-native";
import PrimaryCTAButton from "../../components/buttons/primaryCTAButton";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const YayScreen = ({ navigation, route }) => {
  const userId = useSelector((state) => state.user.id);
  const { mutate } = useSWRConfig();
  return (
    <>
      <VStack alignItems={"center"} space={"33px"} px="35px" h="full">
        <Center pt={"80px"}>
          <Image
            w="358px"
            h="237px"
            resizeMethod="scale"
            resizeMode="contain"
            source={require("./../../assets/images/pngs/yayNeonLogo.png")}
            alt=""
          />
        </Center>

        <Text
          _light={{
            color: "#682481",
            style: {
              fontFamily: "AustinCyr_400",
            },
          }}
          _dark={{
            color: "#E5E5E5",
          }}
          textAlign={"center"}
          fontSize={"21px"}
          style={{
            fontFamily: "HK_600SemiBold",
          }}
          lineHeight={"26.06px"}
        >
          Your results are in! Now see what the hype’s about.
          {Platform.OS !== "web" && (
            <Image
              mt={-4}
              size={12}
              resizeMethod="scale"
              resizeMode="contain"
              source={require("./../../assets/images/gifs/crossedFinger.gif")}
              alt=""
            />
          )}
        </Text>
        {Platform.OS === "web" ? (
          <Center h="40px" w="234px">
            <PrimaryCTAButton
              gradientType="2"
              onPress={() => {
                mutate(
                  `${apiUrl}/api/users/get_user_details/?user_id=${userId}`
                );
                setAnalytics("Explore flock", "Explore Flock Button Clicked", {
                  screen: route.name,
                });
                navigation.navigate("curated");
              }}
            >
              <Text
                letterSpacing={2}
                textTransform={"uppercase"}
                fontSize={"16px"}
                style={{
                  fontFamily: "Hero_700Bold",
                }}
                color="white"
              >
                Products for you
              </Text>
            </PrimaryCTAButton>
          </Center>
        ) : (
          <Center>
            <ExploreFlockButton
              onPress={() => {
                mutate(
                  `${apiUrl}/api/users/get_user_details/?user_id=${userId}`
                );
                setAnalytics("Explore flock", "Explore Flock Button Clicked", {
                  screen: route.name,
                });
                navigation.navigate(Pages.Home);
              }}
            />
          </Center>
        )}
      </VStack>
      {Platform.OS === "web" && <FooterSectionWeb />}
    </>
  );
};

export default YayScreen;
