import { HStack, Pressable, Text, View } from "native-base";
import { useDispatch } from "react-redux";
import ChevronBackArrowIcon from "../../icons/chevronBackArrowIcon";
import { goBackDialog } from "../../../redux/currentState/actions";
import { useNavigation } from "@react-navigation/native";

const EditCollectionPopUpTopBar = ({ title }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  return (
    <HStack
      pt={2}
      // px={6}
      flexDir={"row"}
      // justifyContent={"space-between"}
      bg={"#2B2F33"}
      width={"100%"}
    >
      <Pressable
        onPress={() => {
          // dispatch(goBackDialog());
          navigation.goBack();
        }}
      >
        {/* */}
        <ChevronBackArrowIcon />
      </Pressable>
      <View ml="20">
        <Text
          textAlign={"center"}
          fontSize={17}
          style={{
            fontFamily: "Poppins_500Medium",
            color: "white",
            // marginRight: 60,
          }}
        >
          {title ? title : "Create a Collection"}
        </Text>
      </View>
    </HStack>
  );
};

export default EditCollectionPopUpTopBar;
