import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const ChevronUpArrowIcon = ({ size }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={size} viewBox="0 0 10 6">
        <G width="10" height="6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <Path
            d="M9 5L5 1L1 5"
            stroke="#888990"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </G>
      </Icon>
    </>
  );
};

export default ChevronUpArrowIcon;
