import {
  HStack,
  Image,
  Pressable,
  View,
  Text,
  VStack,
  Center,
  ScrollView,
} from "native-base";
import { useState } from "react";
import ComboSkinTypeIcon from "../../components/icons/skinTypeIcons/inactiveIcons/comboSkinTypeIcon";
import NormalSkinTypeIcon from "./../../components/icons/skinTypeIcons/inactiveIcons/normalSkinTypeIcon";
import OilySkinTypeIcon from "./../../components/icons/skinTypeIcons/inactiveIcons/oilySkinTypeIcon";
import DrySkinTypeIcon from "./../../components/icons/skinTypeIcons/inactiveIcons/drySkinTypeIcon";
import ActiveComboSkinTypeIcon from "../../components/icons/skinTypeIcons/activeIcons/activeComboSkinType";
import ActiveDrySkinTypeIcon from "../../components/icons/skinTypeIcons/activeIcons/activeDrySkinType";
import ActiveNormalSkinTypeIcon from "../../components/icons/skinTypeIcons/activeIcons/activeNormalSkinType";
import ActiveOilySkinTypeIcon from "../../components/icons/skinTypeIcons/activeIcons/activeOilySkinType";
import { Dimensions } from "react-native";
import { Pages } from "../../constants/pages";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import { useSelector } from "react-redux";
import { setAnalytics } from "../../analytics";
import useGetUser from "../../hooks/useGetUser";
import SurveyScreenBottomBar from "../../components/bars/bottomBars/surveyScreenBottomBar";

const SkinTypeScreen = ({ navigation, route }) => {
  const userId = useSelector((state) => state.user.id);

  const { data } = useGetUser("profile", userId);

  const editUserDetails = useEditUserDetails();

  const [skinType, setSkinType] = useState([
    {
      label: `Normal`,
      icon: <NormalSkinTypeIcon />,
      activeIcon: <ActiveNormalSkinTypeIcon />,
      pressed:
        data?.user[0].skin_type !== null &&
        (data?.user[0]?.skin_type
          .split("\u21b5")
          .join("")
          .replace(/[\n\r\s]/g, "") === `Normal` ||
          data?.user[0]?.skin_type
            .split("\u21b5")
            .join("")
            .replace(/[\n\r\s]/g, "") ===
            `Normal${"\n"}Skin`.replace(/[\n\r]/g, ""))
          ? true
          : false,
    },
    {
      label: `Oily`,
      icon: <OilySkinTypeIcon />,
      activeIcon: <ActiveOilySkinTypeIcon />,
      pressed:
        data?.user[0].skin_type !== null &&
        (data?.user[0]?.skin_type
          .split("\u21b5")
          .join("")
          .replace(/[\n\r\s]/g, "") === `Oily` ||
          data?.user[0]?.skin_type
            .split("\u21b5")
            .join("")
            .replace(/[\n\r\s]/g, "") ===
            `Oily${"\n"}Skin`.replace(/[\n\r]/g, ""))
          ? true
          : false,
    },
    {
      label: `Dry`,
      icon: <DrySkinTypeIcon />,
      activeIcon: <ActiveDrySkinTypeIcon />,
      pressed:
        data?.user[0].skin_type !== null &&
        (data?.user[0]?.skin_type
          .split("\u21b5")
          .join("")
          .replace(/[\n\r\s]/g, "") === `Dry` ||
          data?.user[0]?.skin_type
            .split("\u21b5")
            .join("")
            .replace(/[\n\r\s]/g, "") ===
            `Dry${"\n"}Skin`.replace(/[\n\r]/g, ""))
          ? true
          : false,
    },
    {
      label: "Combo",
      icon: <ComboSkinTypeIcon />,
      activeIcon: <ActiveComboSkinTypeIcon />,
      pressed: data?.user[0]?.skin_type === `Combo` ? true : false,
    },
  ]);
  const [skinConcerns, setSkinConcerns] = useState([
    {
      label: "Dullness/Uneven Texture",
      clicked: data?.user[0]?.skin_concerns?.find(
        (k) => k === "Dullness/Uneven Texture"
      )
        ? true
        : false,
    },
    {
      label: "Hydration",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Hydration")
        ? true
        : false,
    },

    {
      label: "Dark Circles",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Dark Circles")
        ? true
        : false,
    },

    {
      label: "UV Damage",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "UV Damage")
        ? true
        : false,
    },
    {
      label: "Ageing",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Ageing")
        ? true
        : false,
    },

    {
      label: "Loss of firmness",
      clicked: data?.user[0]?.skin_concerns?.find(
        (k) => k === "Loss of firmness"
      )
        ? true
        : false,
    },
    {
      label: "Plumping",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Plumping")
        ? true
        : false,
    },
    {
      label: "Acne/Blemishes",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Acne/Blemishes")
        ? true
        : false,
    },

    {
      label: "Redness",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Redness")
        ? true
        : false,
    },
    {
      label: "Pores",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Pores")
        ? true
        : false,
    },
    {
      label: "Dark Spots",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Dark Spots")
        ? true
        : false,
    },
  ]);

  const handleRequest = async (text) => {
    if (text === "Skip") {
      setAnalytics(
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
        }
      );
    } else {
      const skin_concerns = skinConcerns
        .filter((el) => el.clicked)
        .map((el2) => el2.label);
      const skin_type = skinType.find((k) => k.pressed)?.label;

      editUserDetails(
        { skin_type: skin_type, skin_concerns: skin_concerns },
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
          skin_type: skin_type,
          skin_concerns,
          edited: data?.user[0]?.quiz_completed,
        }
      );
    }
    navigation.navigate(Pages.UnderstandingHair);
  };
  return (
    <>
      <ScrollView px={"0.5px"} showsVerticalScrollIndicator={false}>
        <View px={4} pt={4}>
          <VStack
            bg="#1C212E"
            rounded={16}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <VStack p={4} space={4}>
              <Text
                textAlign={"center"}
                color={"white"}
                fontSize={30}
                style={{
                  fontFamily: "Poppins_500Medium",
                }}
                lineHeight={44}
              >
                What is your{"\n"}
                Skin type?
              </Text>
              <Text
                textAlign={"center"}
                color={"#94A3B8"}
                fontSize={14}
                style={{
                  fontFamily: "Poppins_400Regular",
                }}
                lineHeight={17}
              >
                Pick one
              </Text>
              <HStack justifyContent={"center"} space={4}>
                {skinType.map((item, index) => (
                  <VStack key={index} alignItems={"center"} space={2}>
                    <Pressable
                      onPress={() => {
                        let copy = [...skinType];
                        copy.map((k) => {
                          if (k.pressed) {
                            return (k.pressed = false);
                          }
                        });
                        copy[index] = { ...copy[index], pressed: true };
                        setSkinType(copy);
                        // dispatch(
                        //   storeForm({
                        //     skin_type: copy[index].pressed && copy[index].label,
                        //   })
                        // );
                      }}
                    >
                      {item.pressed ? item.activeIcon : item.icon}
                    </Pressable>
                    <Text
                      textAlign={"center"}
                      color={item.pressed ? "white" : "#58595B"}
                      fontSize={16}
                      style={{
                        fontFamily: "Poppins_500Medium",
                      }}
                      lineHeight={20}
                    >
                      {item.label}
                      {"\n"}Skin
                    </Text>
                  </VStack>
                ))}
              </HStack>
            </VStack>

            <VStack px={"22px"} pt={"15px"} width={"full"} space={2}>
              <Text
                color={"#94A3B8"}
                style={{
                  fontFamily: "Poppins_400Regular",
                }}
                fontSize={14}
                lineHeight={17}
              >
                Any Skin concerns?
              </Text>
              <HStack alignItems={"center"} flexWrap="wrap" space={"10px"}>
                {skinConcerns.map((label, index) => (
                  <Pressable
                    onPress={() => {
                      const copy = [...skinConcerns];
                      copy[index] = { ...copy[index], clicked: !label.clicked };
                      setSkinConcerns(copy);
                    }}
                  >
                    <Center
                      mt={"10px"}
                      h="30px"
                      w="100%"
                      rounded={"full"}
                      bg={{
                        linearGradient: label.clicked
                          ? {
                              colors: [
                                "primary.ctaLinearSecondColor",
                                "primary.ctaLinearFirstColor",
                              ],
                              start: [0, 0],
                              end: [1, 0],
                            }
                          : {
                              colors: ["#2B2F33", "#2B2F33"],
                              start: [0, 0],
                              end: [1, 0],
                            },
                      }}
                    >
                      <Center
                        h="25px"
                        rounded={"full"}
                        bg="#2B2F33"
                        px={"20px"}
                        mx={"2px"}
                      >
                        <Text
                          color={label.clicked ? "white" : "#94A3B8"}
                          fontSize={"16px"}
                          style={{
                            fontFamily: "HK_500Medium",
                          }}
                        >
                          {label.label}
                        </Text>
                      </Center>
                    </Center>
                  </Pressable>
                ))}
              </HStack>
            </VStack>

            <Image
              roundedBottom={16}
              height={Dimensions.get("window").height / 4 + 20}
              resizeMethod="scale"
              resizeMode="cover"
              source={require("./../../assets/images/pngs/grass.png")}
              alt=""
            />
          </VStack>
        </View>
      </ScrollView>
      <SurveyScreenBottomBar
        showSkipButton={!data?.user[0]?.quiz_completed}
        showNextButton={
          skinConcerns?.find((k) => k?.clicked) ||
          skinType?.find((k) => k?.pressed)
        }
        skipOnPress={() => {
          handleRequest("Skip");
        }}
        nextOnPress={() => {
          handleRequest("Next");
        }}
      />
    </>
  );
};

export default SkinTypeScreen;
