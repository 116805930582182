import {
  STORE_FORM,
  DELETE_FORM,
  STORE_FORM_ERROR,
  INITIALISE_FORM
} from './constants'

export function initialiseForm(values){
  return {
    type: INITIALISE_FORM,
    values: values
  }
}

export function storeForm(values) {
  return {
    type: STORE_FORM,
    values: values
  }
}

export function deleteForm() {
  return {
    type: DELETE_FORM
  }
}

export function storeFormError(error) {
  return {
    type: STORE_FORM_ERROR,
    error:error
  }
}

