import { Icon } from "native-base";
import { Path, G } from "react-native-svg";
const InActiveHomeIcon = () => {
  return (
    <Icon viewBox="0 0 28 28" size={6}>
      <G width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.859 3.36004C14.0991 3.21332 14.4011 3.21332 14.6412 3.36004L25.8912 10.235C26.2446 10.451 26.3561 10.9126 26.1401 11.2661C25.9241 11.6195 25.4625 11.731 25.109 11.515L14.2501 4.87896L3.3912 11.515C3.03775 11.731 2.57614 11.6195 2.36015 11.2661C2.14415 10.9126 2.25558 10.451 2.60902 10.235L13.859 3.36004ZM5.50011 14.5C5.91432 14.5 6.25011 14.8358 6.25011 15.25V23.25H22.2501V15.25C22.2501 14.8358 22.5859 14.5 23.0001 14.5C23.4143 14.5 23.7501 14.8358 23.7501 15.25V23.25C23.7501 23.6478 23.5921 24.0294 23.3108 24.3107C23.0295 24.592 22.6479 24.75 22.2501 24.75H6.25011C5.85228 24.75 5.47075 24.592 5.18945 24.3107C4.90814 24.0294 4.75011 23.6478 4.75011 23.25V15.25C4.75011 14.8358 5.08589 14.5 5.50011 14.5ZM11.0001 19C11.0001 18.5858 11.3359 18.25 11.7501 18.25H16.7501C17.1643 18.25 17.5001 18.5858 17.5001 19C17.5001 19.4142 17.1643 19.75 16.7501 19.75H11.7501C11.3359 19.75 11.0001 19.4142 11.0001 19Z"
          fill="white"
        />
      </G>
    </Icon>
  );
};

export default InActiveHomeIcon;
