import {
  STORE_GROUPS,
  ADD_GROUP,
  DELETE_GROUP,
  STORE_CART,
  ADD_CART,
  DELETE_CART,
  CLEAR_CART,
  STORE_WISHLIST,
  ADD_WISHLIST,
  DELETE_WISHLIST,
  STORE_VALUES,
  RELOAD_CART,
  RELOAD_WISHLIST,
  RELOAD_GROUPS,
} from "./constants";

export function storeValues(cartItems, wishlistItems, groupItems) {
  return {
    type: STORE_VALUES,
    cartItems: cartItems,
    wishlistItems: wishlistItems,
    groupItems: groupItems,
  };
}

export function reloadCart(reloadCart) {
  return {
    type: RELOAD_CART,
    reloadCart: reloadCart,
  };
}

export function reloadWishlist(reloadWishlist) {
  return {
    type: RELOAD_WISHLIST,
    reloadWishlist: reloadWishlist,
  };
}

export function reloadGroups(reloadGroups) {
  return {
    type: RELOAD_GROUPS,
    reloadGroups: reloadGroups,
  };
}

export function storeCart(cartItems) {
  return {
    type: STORE_CART,
    cartItems: cartItems,
  };
}

export function addToCart(product) {
  return {
    type: ADD_CART,
    product: product,
  };
}

export function deleteFromCart(itemId) {
  return {
    type: DELETE_CART,
    itemId: itemId,
  };
}

export function clearCart() {
  return {
    type: CLEAR_CART,
  };
}

export function storeGroups(groups) {
  return {
    type: STORE_GROUPS,
    groups: groups,
  };
}

export function addGroup(group) {
  return {
    type: ADD_GROUP,
    group: group,
  };
}

export function deleteGroup(itemId) {
  return {
    type: DELETE_GROUP,
    itemId: itemId,
  };
}

export function storeCartWishlist(wishlistItems) {
  return {
    type: STORE_WISHLIST,
    wishlistItems: wishlistItems,
  };
}

export function addToCartWishlist(product) {
  return {
    type: ADD_WISHLIST,
    product: product,
  };
}

export function deleteFromCartWishlist(itemId) {
  return {
    type: DELETE_WISHLIST,
    itemId: itemId,
  };
}
