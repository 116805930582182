import { Center, HStack, Image, Pressable, Text, VStack } from "native-base";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Pages } from "../../../constants/pages";
import { setAnalytics } from "../../../analytics";
import { BrandImages } from "../../../constants/brands";
import CartIconButton from "../../buttons/cartIconButton";
import AddToCartHOC from "../../../hoc/AddToCartHOC";
import { useCreateParams } from "../../../hooks/useCreateParams";
const ProductCard = (props) => {
  const navigation = useNavigation();
  const route = useRoute();
  const paramsmap = {
    id: props?.productId,
    sku_id: props?.productSkuId,
    bundle_id: props?.bundleId,
    source: props?.source,
    otherUserId: props?.otherUserId,
  };
  const params = useCreateParams(paramsmap);
  return (
    <>
      <Pressable
        onPress={() => {
          setAnalytics("Product Card", "Product Card Clicked", {
            productId: props?.productId,
            productSkuId: props?.productSkuId,
            productName: props?.productName,
            brandName: props?.brandName,
            screen: route?.name,
            bundleId: props?.bundleId,
            source: props?.source,
            otherUserId: props?.otherUserId,
          });
          if (props?.onPress) {
            props.onPress();
          } else {
            navigation.navigate(Pages.ProductScreen, params);
          }
        }}
      >
        <VStack
          _light={{
            bg: "white",
          }}
          _dark={{
            bg: "#202427",
          }}
          borderBottomRadius={"8px"}
        >
          <Center
            size={{
              base: "180px",
              sm: "180px",
              md: "200px",
              lg: "200px",
              xl: "200px",
            }}
          >
            <Image
              borderRadius={"10px"}
              borderWidth="1px"
              borderColor={props?.isAdded ? "#682481" : "#FAF1FF"}
              size={"full"}
              resizeMethod="scale"
              bg="white"
              resizeMode="contain"
              source={{
                uri: props?.productImage,
              }}
              alt=""
            />
            {props?.isAdded && (
              <Image
                top={0}
                right={0}
                position={"absolute"}
                size={5}
                alt=""
                source={require("./../../../assets/images/pngs/selectedTick.png")}
              />
            )}
          </Center>

          <VStack
            px={2}
            _light={{
              bg: "white",
            }}
            _dark={{
              bg: "#202427",
            }}
            h="150px"
            pb={2}
            w={{
              base: "180px",
              sm: "180px",
              md: "200px",
              lg: "200px",
              xl: "200px",
            }}
            justifyContent="space-between"
          >
            <VStack space={2}>
              <HStack alignItems={"center"} space={2} pt={2}>
                <Image
                  bg="white"
                  borderRadius={"20px"}
                  size={"20px"}
                  resizeMethod="scale"
                  resizeMode="contain"
                  source={{
                    uri: BrandImages[props?.brandName],
                  }}
                  alt=""
                />
                <Text
                  noOfLines={2}
                  w={"80%"}
                  _light={{
                    color: "#464646",
                  }}
                  _dark={{
                    color: "#94A3B8",
                  }}
                  letterSpacing={2}
                  textTransform={"uppercase"}
                  fontSize={"10px"}
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                >
                  {props?.brandName}
                </Text>
              </HStack>
              <Text
                w={"80%"}
                _light={{
                  color: "#682481",
                }}
                _dark={{
                  color: "white",
                }}
                fontSize={"13px"}
                style={{
                  fontFamily: "LeituraNewsRoman",
                }}
                noOfLines={3}
              >
                {props?.productName}
              </Text>
            </VStack>
            <HStack justifyContent="space-between" alignItems={"center"}>
              {/* {route?.name === "editcollection" ||
              route?.name === "addcollection" ? (
                <HStack space={"4px"} flexWrap={"wrap"} w={"120px"}>
                  {Object.keys(map)
                    ?.filter(
                      (item, index) =>
                        map[item] !== undefined && map[item] !== null
                    )
                    .map((k, i) => (
                      <>
                        {map[k] && (
                          <FullWidthAccordion
                            selectedSkus={props?.selectedSkus}
                            setSelectedSkus={props?.setSelectedSkus}
                            id={props?.productId}
                            type="collectioncard"
                            label={k}
                            items={map[k]}
                            val={
                              k === "Size"
                                ? product?.skus[props?.productSkuId]?.size
                                : k === "Color"
                                ? product?.skus[props?.productSkuId]?.color
                                : k === "Scent"
                                ? product?.skus[props?.productSkuId]?.scent
                                : k === "Type" &&
                                  product?.skus[props?.productSkuId]?.type
                            }
                          />
                        )}
                      </>
                    ))}
                </HStack>
              ) : (
                
              )} */}
              <Text
                _light={{
                  color: "#464646",
                }}
                _dark={{
                  color: "#94A3B8",
                }}
                fontSize={"11px"}
                style={{
                  fontFamily: "Hero_400Regular",
                }}
              >
                {props?.variantLength}{" "}
                {props?.variantLength > 1 ? "Variants" : "Variant"} available
              </Text>

              <AddToCartHOC
                source={props?.source}
                otherUserId={props?.otherUserId}
                productId={props?.productId}
                productSkuId={props?.productSkuId}
                bundleId={props?.bundleId}
              >
                <CartIconButton />
              </AddToCartHOC>
            </HStack>
          </VStack>
          <Center
            h="40px"
            borderRadius={"10px"}
            bg={{
              linearGradient: {
                colors: [
                  "primary.ctaLinearFirstColor",
                  "primary.ctaLinearSecondColor",
                ],
                start: [0, 0],
                end: [1, 0],
              },
            }}
          >
            <Pressable
              onPress={() => {
                setAnalytics(
                  "Buy Now Button",
                  "Buy Now Button Clicked On Product Card",
                  {
                    productId: props?.productId,
                    productSkuId: props?.productSkuId,
                    retailPrice: props?.retailPrice,
                    source: props?.source,
                    otherUserId: props?.otherUserId,
                  }
                );
                navigation.navigate(Pages.CheckoutScreen, {
                  product_id: props?.productId,
                  sku_id: props?.productSkuId,
                  type: "single",
                  source: props?.source,
                  otherUserId: props?.otherUserId,
                });
              }}
              h="40px"
              borderRadius={"10px"}
              w={{
                base: "180px",
                sm: "180px",
                md: "200px",
                lg: "200px",
                xl: "200px",
              }}
              p={2}
            >
              <HStack alignItems={"center"} justifyContent="space-around">
                <Text
                  color="white"
                  style={{
                    fontFamily: "Hero_700Bold",
                  }}
                  textTransform="uppercase"
                  fontSize={{
                    md: "11px",
                    lg: "11px",
                    xl: "11px",
                  }}
                >
                  Buy now
                </Text>
                <Text
                  color="white"
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                  fontSize={{
                    md: "14px",
                    lg: "14px",
                    xl: "14px",
                  }}
                >
                  ${props?.retailPrice?.toFixed(2)}
                </Text>
              </HStack>
            </Pressable>
          </Center>
        </VStack>
      </Pressable>
    </>
  );
};

export default ProductCard;
