import { useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCreatePath } from "./useCreatePath";
import { createDeepLink } from "./useShare";

export const useCopyToClipboard = () => {
  const path = useCreatePath();

  const [copyStatus, setCopyStatus] = useState();
  const [link, setLink] = useState();
  const userId = useSelector((state) => state.user.id);
  useEffect(() => {
    getLink();
  }, [path]);
  const getLink = async () => {
    let k = await createDeepLink(path, userId);
    setLink(k);
  };

  return { link, copyStatus, setCopyStatus };
};
