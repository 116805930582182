import { Image } from "native-base";

const WebPhoneIcon = () => {
  return (
    <Image
      alt=""
      size={4}
      resizeMode="contain"
      resizeMethod="scale"
      source={require("./../../../assets/images/pngs/phone.png")}
    />
  );
};

export default WebPhoneIcon;
