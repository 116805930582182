import { Center, HStack, Image, Pressable, Text } from "native-base";
import AffiliateIcon from "../../icons/affiliateIcon";

const HighestEarnerProductCard = ({ data }) => {
  return (
    <>
      <Center
        w="100%"
        _light={{
          borderColor: "#E9B9FF",
        }}
        _dark={{
          borderColor: "#2B2F33",
        }}
        borderTopWidth={0}
        borderWidth={"1px"}
        borderRadius={"20px"}
      >
        <Center
          w="100%"
          height={"88px"}
          borderRadius={20}
          bg={{
            linearGradient: false
              ? {
                  colors: ["#202427", "#202427"],
                  start: [0, 0],
                  end: [1, 0],
                }
              : {
                  colors: [
                    "primary.ctaLinearSecondColor",
                    "primary.ctaLinearFirstColor",
                  ],
                  start: [0, 0],
                  end: [1, 0],
                },
          }}
        >
          <HStack
            w="99%"
            alignItems="center"
            justifyContent="space-between"
            px={4}
            height={"86px"}
            borderRadius={20}
            _light={{
              bg: "#FAF1FF",
            }}
            _dark={{
              bg: "#202427",
            }}
            _text={{
              color: "#ffffff",
            }}
          >
            <Image
              size={"200px"}
              resizeMethod="scale"
              resizeMode="contain"
              source={require("./../../../assets/images/pngs/YOURHIGHESTEARNER.png")}
              alt=""
            />
            <Center>
              <HStack
                space={2}
                alignItems={"center"}
                bg="#2B2F33"
                justifyContent={"center"}
                w="66px"
                h="29px"
                rounded="6px"
                right="65%"
                position={"absolute"}
                zIndex="4"
              >
                <AffiliateIcon />
                <Text
                  color={"white"}
                  fontSize="13px"
                  style={{
                    fontFamily: "HK_500Medium",
                  }}
                >
                  ${data?.earning}
                </Text>
              </HStack>

              <Image
                size={"68px"}
                rounded="10px"
                resizeMethod="scale"
                resizeMode="contain"
                source={{
                  uri: data?.product_obj?.images[0],
                }}
                alt=""
              />
            </Center>
          </HStack>
        </Center>
        <HStack w="full" h="50px" alignItems="center" justifyContent="center">
          <Text
            numberOfLines={1}
            _light={{
              color: "#464646",
            }}
            _dark={{
              color: "white",
            }}
            fontSize="13px"
            style={{
              fontFamily: "HK_500Medium",
            }}
          >
            {data?.product_obj?.name}
          </Text>
        </HStack>
      </Center>
    </>
  );
};

export default HighestEarnerProductCard;
