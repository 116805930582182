import { FlatList, View, Button } from "native-base";

import { useDispatch, useSelector } from "react-redux";

import { SkinTypes } from "../../constants/skinTypes";
import { changeFilter } from "../../redux/communityFilter/actions";

const Item = ({ item }) => {
  const skinTypeFilter = useSelector(
    (state) => state.communityFilter.skin_type
  );
  const dispatch = useDispatch();
  return (
    <Button
      _hover={{
        bg: "#F5F5F5",
      }}
      onPress={() => {
        let vals = [...skinTypeFilter];
        if (vals.includes(item) === true) {
          vals = [...vals.filter((value) => value !== item)];
        } else {
          vals.push(item);
        }
        dispatch(changeFilter({ skin_type: vals }));
        dispatch({
          type: "SET_APPLY_FILTER",
        });
      }}
      variant={"ghost"}
      _text={{
        fontFamily: "Hero_400Regular",

        letterSpacing: 2,
        color: "#000000",
        fontSize: "12px",
      }}
    >
      {SkinTypes[item]
        .split("\u21b5")
        .join("")
        .replace(/[\n\r\s]/g, " ")}
    </Button>
  );
};

const SkinType = () => {
  const renderItem = ({ item }) => <Item item={item} />;

  return (
    <FlatList
      bg="white"
      showsVerticalScrollIndicator={false}
      ItemSeparatorComponent={() => (
        <View style={{ paddingVertical: 2 }}></View>
      )}
      contentContainerStyle={{
        height: 200,
      }}
      data={Object.keys(SkinTypes)}
      renderItem={renderItem}
    />
  );
};

export default SkinType;
