import { useDispatch, useSelector } from "react-redux";
import {
  closeDialog,
  hideLoader,
  showLoader,
} from "../redux/currentState/actions";
import { publicApi, privateApi } from "../util/API";
import * as RootNavigation from "../RootNavigation";
import { Pages } from "../constants/pages";
import { setAnalytics } from "../analytics";
import { useState } from "react";

import {
  getPushToken,
  setUser,
  uploadImageToServer,
} from "../util/utilFunctions";
import { recievedUser } from "../redux/user/actions";
import { storeForm } from "../redux/form/actions";
import { Keyboard, Platform } from "react-native";
import { useGetCountryCodeWeb } from "./useGetCountryCodeWeb";
import { useEditUserDetails } from "./useEditUserDetails";

export const useSignup = () => {
  const form = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const route = RootNavigation.navigationRef.getCurrentRoute();
  const [error, setError] = useState();
  const countryCode = useGetCountryCodeWeb();
  const editUserDetails = useEditUserDetails();
  const signup = async (login_type) => {
    const handleInput = (name) => (val) => {
      dispatch(storeForm({ [name]: val, error: null }));
    };

    Keyboard.dismiss();
    dispatch(showLoader());
    console.log("useSignup", form);

    try {
      let res = await publicApi(
        "/api/users/registeruser/",
        route?.params?.type === "phone" || login_type === "7"
          ? {
              login_type: "7",
              username: form?.username?.toLowerCase(),
              phone: `+${countryCode}${
                login_type === "7" ? form?.phone_number : form.phone
              }`,
              first_name: form.first_name,
              last_name: form.last_name,
              image: form?.image?.uri,
              refferal_code: form?.refferal_code,
            }
          : {
              login_type: "6",
              username: form?.username?.toLowerCase(),
              email: form.email,
              password: form.password,
              first_name: form.first_name,
              last_name: form.last_name,
              image: form?.image?.uri,
              refferal_code: form?.refferal_code,
            },
        "post"
      );
      console.log(
        "/api/users/registeruser/",
        res,
        login_type,
        route?.params?.type,
        {
          login_type: "6",
          username: form?.username?.toLowerCase(),
          email: form.email,
          password: form.password,
          first_name: form.first_name,
          last_name: form.last_name,
          image: form?.image?.uri,
          refferal_code: form?.refferal_code,
        }
      );
      if (res.status === 200) {
        let res2 = await publicApi(
          "/api/users/get_user/",
          {
            id: JSON.parse(res.body.user).user_id,
            login_type:
              Platform.OS === "web"
                ? login_type
                : route?.params?.type === "phone"
                ? "7"
                : "6",
          },
          "get"
        );
        console.log("/api/users/get_user/", res2, login_type);
        setUser(
          JSON.parse(res2.body.id)[0],
          JSON.parse(res.body.token),
          JSON.parse(res.body.user).username,
          JSON.parse(res.body.auth_token)
        );

        if (form?.image === "" || form?.image === undefined) {
          const push_token = await getPushToken();
          if (push_token !== null && push_token !== undefined) {
            const res3 = await privateApi(
              "/api/users/push_token/",
              {
                token: push_token,
                date: "23135134",
                active: true,
                user: JSON.parse(res2.body.id)[0],
              },
              "put"
            );
          }
          let stringUserid = JSON.parse(res2.body.id)[0];

          if (route?.params?.type === "phone" || login_type === "7") {
            setAnalytics("Sign Up Button", "Signed Up Via Phone", {
              screen: route?.name,
            });
          } else {
            setAnalytics("Sign Up Button", "Signed Up Via Email", {
              screen: route?.name,
            });
          }
          dispatch(recievedUser({ id: JSON.parse(res2.body.id)[0] }));
          editUserDetails(
            {
              instagram_handle: form?.instagram_handle,
              facebook_username: form?.facebook_username,
              tiktok_handle: form?.tiktok_handle,
            },
            `Add social links while signup`,
            `Added Social Links While Signup`,
            {
              screen: route?.name,
              instagram_handle: form?.instagram_handle,
              facebook_username: form?.facebook_username,
              tiktok_handle: form?.tiktok_handle,
            }
          );
          if (Platform.OS === "web") {
            console.log("Platform.OS === web", form?.image);
            dispatch(closeDialog());
            dispatch(hideLoader());
            RootNavigation.navigate(Pages.Home);
          } else {
            dispatch(hideLoader());
            RootNavigation.navigate(Pages.SocialLinksScreen);
          }
        } else {
          const { uploadStatusCode, image } = await uploadImageToServer(
            form?.image,
            JSON.parse(res2.body.id)[0]
          );

          if (uploadStatusCode === 200) {
            const push_token = await getPushToken();
            if (push_token !== null && push_token !== undefined) {
              const res3 = await privateApi(
                "/api/users/push_token/",
                {
                  token: push_token,
                  date: "23135134",
                  active: true,
                  user: JSON.parse(res2.body.id)[0],
                },
                "put"
              );
            }
            let stringUserid = JSON.parse(res2.body.id)[0];

            if (route?.params?.type === "phone" || login_type === "7") {
              setAnalytics("Sign Up Button", "Signed Up Via Phone", {
                screen: route?.name,
                login_type: "7",
                username: form?.username?.toLowerCase(),
                phone: `+${countryCode}${
                  login_type === "7" ? form?.phone_number : form.phone
                }`,
                first_name: form.first_name,
                last_name: form.last_name,
                image: form?.image?.uri,
                refferal_code: form?.refferal_code,
              });
            } else {
              setAnalytics("Sign Up Button", "Signed Up Via Email", {
                screen: route?.name,
                login_type: "6",
                username: form?.username?.toLowerCase(),
                email: form.email,
                password: form.password,
                first_name: form.first_name,
                last_name: form.last_name,
                image: form?.image?.uri,
                refferal_code: form?.refferal_code,
              });
            }
            handleInput("image")(image);

            dispatch(recievedUser({ id: JSON.parse(res2.body.id)[0] }));
            editUserDetails(
              {
                instagram_handle: form?.instagram_handle,
                facebook_username: form?.facebook_username,
                tiktok_handle: form?.tiktok_handle,
              },
              `Add social links while signup`,
              `Added Social Links While Signup`,
              {
                screen: route?.name,
                instagram_handle: form?.instagram_handle,
                facebook_username: form?.facebook_username,
                tiktok_handle: form?.tiktok_handle,
              }
            );

            if (Platform.OS === "web") {
              console.log("Platform.OS === web else", form?.image);
              dispatch(closeDialog());
              dispatch(hideLoader());
              RootNavigation.navigate(Pages.Home);
            } else {
              dispatch(hideLoader());
              RootNavigation.navigate(Pages.SocialLinksScreen);
            }
          }
        }
      } else if (res.status === 400) {
        setError(res.body);
      }
    } catch (error) {
      console.log("catchsignuperror", error);
    }
  };
  return { signup, error };
};
