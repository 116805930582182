import { SET_PRODUCTS, ADD_PRODUCTS, RELOAD_PRODUCTS } from "./constants";

const initialState = {
  groups: [],
  products: [],
  page: 1,
  filters: {},
  reloadProducts: false,
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REFRESH':
      return {
        groups: [],
        products: [],
        page: 1,
        filters: {},
        reloadProducts: false,
      }
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.products,
        page: 1,
      };
    case ADD_PRODUCTS:
      return {
        ...state,
        products: [...state.products, ...action.products],
        page: action.page,
      };
    case RELOAD_PRODUCTS:
      return {
        ...state,
        reloadProducts: action.reloadProducts,
      };
    default:
      return state;
  }
};

export default productsReducer;
