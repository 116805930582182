import { Icon, HStack, Pressable } from "native-base";
import { Path, G } from "react-native-svg";

const MinusIcon = ({ color = "#F8F8F8" }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon viewBox="0 0 10 2">
        <G width="10" height="2" fill="none" xmlns="http://www.w3.org/2000/svg">
          <Path d="M10 0H0V1.5H10V0Z" fill={color} />
        </G>
      </Icon>
    </>
  );
};

export default MinusIcon;
