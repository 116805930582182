import {
  RESET_FILTERS,
  CHANGE_FILTER,
  SET_PRICE,
  SET_APPLY_FILTER,
  RESET_CACHE_FILTERS,
  SET_CHIPS,
  DELETE_CHIPS,
  DELETE_CHIPS_VALUE,
} from "./constants";

const initialState = {
  cachedFilters: {
    priceRangeFrom: 0,
    priceRangeTo: 700,
    discounts: [],
    brands: [],
    concerns: [],
    skinConcerns: [],
    productTypes: [],
    subproductType1: [],
    subproductType2: [],
    chips: [],
    price_sort: "",
  },
  priceRangeFrom: 0,
  priceRangeTo: 700,
  discounts: [],
  brands: [],
  concerns: [],
  skinConcerns: [],
  productTypes: [],
  subproductType1: [],
  subproductType2: [],
  chips: [],
  type: "all",
  price_sort: "",
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REFRESH":
      return {
        cachedFilters: {
          priceRangeFrom: 0,
          priceRangeTo: 700,
          discounts: [],
          brands: [],
          concerns: [],
          skinConcerns: [],
          productTypes: [],
          subproductType1: [],
          subproductType2: [],
          chips: [],
          price_sort: "",
        },
        priceRangeFrom: 0,
        priceRangeTo: 700,
        discounts: [],
        brands: [],
        concerns: [],
        skinConcerns: [],
        productTypes: [],
        subproductType1: [],
        subproductType2: [],
        chips: [],
        price_sort: "",
      };
    case RESET_FILTERS:
      return {
        cachedFilters: {
          priceRangeFrom: 0,
          priceRangeTo: 700,
          discounts: [],
          brands: [],
          concerns: [],
          skinConcerns: [],
          productTypes: [],
          subproductType1: [],
          subproductType2: [],
          chips: [],
          price_sort: "",
        },
        priceRangeFrom: 0,
        priceRangeTo: 700,
        discounts: [],
        brands: [],
        concerns: [],
        skinConcerns: [],
        productTypes: [],
        subproductType1: [],
        subproductType2: [],
        chips: [],
        price_sort: "",
      };
    case SET_PRICE:
      return {
        ...state,
        cachedFilters: {
          ...state.cachedFilters,
          priceRangeFrom: action.low,
          priceRangeTo: action.high,
        },
      };

    case SET_APPLY_FILTER:
      return {
        ...state.cachedFilters,
        cachedFilters: {
          ...state.cachedFilters,
        },
      };

    case RESET_CACHE_FILTERS:
      return {
        ...state,
        cachedFilters: {
          priceRangeFrom: state.priceRangeFrom,
          priceRangeTo: state.priceRangeTo,
          discounts: state.discounts,
          brands: state.brands,
          concerns: state.concerns,
          skinConcerns: state.skinConcerns,
          productTypes: state.productTypes,
          subproductType1: state.subproductType1,
          subproductType2: state.subproductType2,
          chips: state.chips,
          price_sort: state.price_sort,
        },
      };

    case CHANGE_FILTER:
      return {
        ...state,
        cachedFilters: {
          ...state.cachedFilters,
          ...action.filter,
        },
      };

    case SET_CHIPS:
      return {
        ...state,
        cachedFilters: {
          ...state.cachedFilters,
          chips: [...state.cachedFilters.chips, action.chip],
        },
      };
    case DELETE_CHIPS:
      return {
        ...state,
        chips: [
          ...state.chips.slice(0, action.itemId),
          ...state.chips.slice(action.itemId + 1),
        ],
        cachedFilters: {
          ...state.cachedFilters,
          chips: [
            ...state.chips.slice(0, action.itemId),
            ...state.chips.slice(action.itemId + 1),
          ],
        },
      };

    case DELETE_CHIPS_VALUE:
      return {
        ...state,
        chips: [...state.chips.filter((value) => value.value !== action.value)],
        cachedFilters: {
          ...state.cachedFilters,
          chips: [
            ...state.chips.filter((value) => value.value !== action.value),
          ],
        },
      };
    default:
      return state;
  }
};

export default filterReducer;
