import { Box, FlatList } from "native-base";
import useGetGroups from "../hooks/useGetGroups";
import ViewMoreSideBar from "./bars/sideBars/viewMoreSideBar";
import GroupCard from "./cards/group/groupCard";

const TrendingGroups = ({ heading, image }) => {
  const { data, size, setSize, isValidating } = useGetGroups();
  return (
    <FlatList
      showsHorizontalScrollIndicator={false}
      horizontal
      ItemSeparatorComponent={() => (
        <Box style={{ paddingHorizontal: 6 }}></Box>
      )}
      contentContainerStyle={{
        flexDirection: "row",
        alignItems: "center",
      }}
      ListHeaderComponent={<ViewMoreSideBar heading={heading} image={image} />}
      flexGrow={0}
      data={data?.filter((group) => group?.public)}
      renderItem={({ item }) => <GroupCard group={item} />}
    />
  );
};

export default TrendingGroups;
