import { HStack, Input, ScrollView, Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import { useInitialiseForm } from "../../hooks/useInitialiseForm";
import { storeForm } from "../../redux/form/actions";
import InstagramIcon from "./../../assets/images/svgs/mdi_instagram.svg";
import FacebookIcon from "./../../assets/images/svgs/ic_round-facebook.svg";
import TiktokIcon from "./../../assets/images/svgs/ic_baseline-tiktok.svg";
import { Pages } from "../../constants/pages";
import GradientRoundedCornerNextButton from "../../components/buttons/gradientRoundedCornerNextButton";
import { useGetKeyboardStatus } from "../../hooks/useGetKeyboardStatus";

const SocialLinksScreen = ({ navigation, route }) => {
  useInitialiseForm();
  const keyboardStatus = useGetKeyboardStatus();
  const form = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const editUserDetails = useEditUserDetails();
  const handleInput = (name) => (val) => {
    dispatch(storeForm({ [name]: val }));
  };
  return (
    <ScrollView
      h="full"
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps="handled"
    >
      <VStack
        pb={
          keyboardStatus === "Keyboard Hidden" || keyboardStatus === undefined
            ? 0
            : 96
        }
        space={20}
        px="28px"
      >
        <VStack alignItems={"center"} pt="50px" space={"30px"}>
          <VStack alignItems={"center"}>
            <Text
              color={"white"}
              fontSize={"30px"}
              letterSpacing={"sm"}
              style={{
                fontFamily: "Poppins_500Medium",
              }}
            >
              Your Socials?
            </Text>
            <Text
              color={"#94A3B8"}
              fontSize={"16px"}
              letterSpacing={"sm"}
              style={{
                fontFamily: "HK_500Medium",
              }}
            >
              (optional)
            </Text>
          </VStack>

          <VStack space={"20px"} w="full">
            {[
              {
                label: "Instagram Handle",
                name: "instagram_handle",
                icon: <InstagramIcon />,
              },
              {
                label: "Facebook Username",
                name: "facebook_username",
                icon: <FacebookIcon />,
              },
              {
                label: "TikTok Handle",
                name: "tiktok_handle",
                icon: <TiktokIcon />,
              },
            ].map((k, i) => (
              <VStack>
                <VStack space={"10px"} w="full">
                  <HStack alignItems={"center"} space={1}>
                    {k?.icon}
                    <Text
                      color={"white"}
                      fontSize={"14px"}
                      letterSpacing={"sm"}
                      style={{
                        fontFamily: "HK_500Medium",
                      }}
                    >
                      {k?.label}
                    </Text>
                  </HStack>
                  <Input
                    onChangeText={(val) => handleInput(k?.name)(val)}
                    fontSize={"16px"}
                    lineHeight={"21px"}
                    autoCapitalize="none"
                    style={{
                      fontFamily: "HK_700Bold",
                    }}
                    placeholderTextColor={"#94A3B8"}
                    borderWidth={"1px"}
                    borderColor={"#58595B"}
                    p={"16px"}
                    rounded={"8px"}
                    color="white"
                    _focus={{
                      borderColor: "white",

                      color: "white",
                    }}
                    placeholder="@xxxxxx"
                  ></Input>
                </VStack>
              </VStack>
            ))}
          </VStack>
        </VStack>
        <GradientRoundedCornerNextButton
          onPress={() => {
            editUserDetails(
              form,
              `Next Button Registration Screen`,
              `Social Links Next Button Clicked`,
              {
                screen: route?.name,
                ...form,
              }
            );
            navigation.navigate(Pages.LetsRoll);
          }}
        />
      </VStack>
    </ScrollView>
  );
};

export default SocialLinksScreen;
