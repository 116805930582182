import {
  Center,
  Text,
  VStack,
  HStack,
  ScrollView,
  PresenceTransition,
  Pressable,
  Stack,
} from "native-base";
import { useState } from "react";
import { useSelector } from "react-redux";
import HeadingBar from "../../components/bars/headingBar";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import ChevronDownArrowIcon from "../../components/icons/chevronDownArrowIcon";
import UsersColumnLayout from "../../components/layouts/usersColumnLayout";
import { Ethnicities } from "../../constants/ethinicity";
import { gradientTextWeb } from "../../constants/gradient";
import { HairTextures } from "../../constants/hairTextures";
import { HairTypes } from "../../constants/hairTypes";
import { SkinTypes } from "../../constants/skinTypes";
import UsersHOC from "../../hoc/UsersHOC";
import useResetFilter from "../../hooks/useResetFilter";
import CelebHorizontalStack from "./celebHorizontalStack";
import Ethnicity from "./ethinicity";
import HairTexture from "./hairTexture";
import HairType from "./hairType";
import SkinType from "./skinType";
const CommunityScreen = () => {
  useResetFilter();
  const skinTypeFilter = useSelector(
    (state) => state.communityFilter.skin_type
  );
  const ethnicity = useSelector((state) => state.communityFilter.ethnicity);
  const hairTypeFilter = useSelector(
    (state) => state.communityFilter.hair_type
  );
  const hairTextureFilter = useSelector(
    (state) => state.communityFilter.hair_type
  );
  const [filters, setFilters] = useState([
    {
      label: "Identity",
      component: <Ethnicity />,
      pressed: false,
    },
    {
      label: "Skin type",
      component: <SkinType />,

      pressed: false,
    },
    {
      label: "Hair type",
      component: <HairType />,

      pressed: false,
    },
    {
      label: "Hair texture",
      component: <HairTexture />,

      pressed: false,
    },
  ]);

  const filterMap = {
    0: {
      filterName: ethnicity,
      values: Ethnicities,
    },
    1: {
      filterName: skinTypeFilter,
      values: SkinTypes,
    },
    2: {
      filterName: hairTypeFilter,
      values: HairTypes,
    },
    3: {
      filterName: hairTextureFilter,
      values: HairTextures,
    },
  };

  return (
    <>
      <VStack space={10} w="full" alignItems={"center"}>
        <HeadingBar>
          <VStack
            alignItems={"center"}
            w="full"
            h="full"
            justifyContent={"center"}
          >
            <Text
              fontSize={"36px"}
              _web={{
                style: {
                  ...gradientTextWeb(1),
                  fontFamily: "AustinCyr_400",
                },
              }}
            >
              Beauty Buddies
            </Text>
            <Stack
              direction={{
                base: "column",
                sm: "column",
                md: "column",
                lg: "column",
                xl: "row",
              }}
              alignItems="center"
              justifyContent={"center"}
              space={6}
              w="full"
            >
              {filters.map((k, i) => (
                <VStack space={1}>
                  <Text
                    textTransform={"uppercase"}
                    letterSpacing={2}
                    color="#682481"
                    fontSize={"11px"}
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                  >
                    {k?.label}
                  </Text>
                  <Pressable
                    onPress={() => {
                      let copy = filters.map((f, j) => {
                        return {
                          ...filters[j],
                          pressed: false,
                        };
                      });

                      copy[i] = {
                        ...copy[i],
                        pressed: !k.pressed,
                      };

                      setFilters(copy);
                    }}
                  >
                    <HStack
                      _focus={{
                        backgroundColor: "white",
                      }}
                      focusOutlineColor="#BE54FF"
                      _hover={{
                        borderColor: "#BE54FF",
                      }}
                      bg="white"
                      alignItems={"center"}
                      justifyContent="space-between"
                      w="210px"
                      px={4}
                      rounded={"2px"}
                      h="40px"
                    >
                      <ScrollView horizontal>
                        <HStack space={2}>
                          {filterMap[i]?.filterName?.length > 0 ? (
                            filterMap[i]?.filterName?.map((k) => (
                              <Center
                                rounded={"full"}
                                bg="#E9B9FF"
                                px={2}
                                h="24px"
                              >
                                <Text
                                  letterSpacing={2}
                                  color="#000000"
                                  fontSize={"11px"}
                                  style={{
                                    fontFamily: "Hero_400Regular",
                                  }}
                                >
                                  {filterMap[i]?.values[k]
                                    .split("\u21b5")
                                    .join("")
                                    .replace(/[\n\r\s]/g, " ")}
                                </Text>
                              </Center>
                            ))
                          ) : (
                            <Text
                              letterSpacing={2}
                              color="#000000"
                              fontSize={"11px"}
                              style={{
                                fontFamily: "Hero_400Regular",
                              }}
                            >
                              {filterMap[i]?.values[1]
                                .split("\u21b5")
                                .join("")
                                .replace(/[\n\r\s]/g, " ")}
                            </Text>
                          )}
                        </HStack>
                      </ScrollView>
                      <ChevronDownArrowIcon />
                    </HStack>
                  </Pressable>
                  {k?.pressed && k?.component && (
                    <PresenceTransition
                      visible={k?.pressed && k?.component}
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                        transition: {
                          duration: 500,
                        },
                      }}
                    >
                      {k?.component}
                    </PresenceTransition>
                  )}
                </VStack>
              ))}
            </Stack>
          </VStack>
        </HeadingBar>
        <UsersHOC fetcherType="celebUsers">
          <CelebHorizontalStack />
        </UsersHOC>

        <UsersColumnLayout />
      </VStack>
      <FooterSectionWeb />
    </>
  );
};

export default CommunityScreen;
