import axios from "axios";
import useSWR from "swr";
import { useSelector } from "react-redux";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
import { getHeaders } from "../util/utilFunctions";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const fetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return res.data.data;
    });

export default function useGetNotifications() {
  const userId = useSelector((state) => state.user.id);
  const { data, mutate, error, isValidating } = useSWR(
    userId
      ? `${apiUrl}/api/users/get_user_notifications/?user_id=${userId}`
      : null,
    fetcher,
    {
      refreshInterval: 300000,
    }
  );
  useCheckNetwork(error);
  useLoader(isValidating);
  return { data, mutate };
}
