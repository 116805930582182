import { VStack, Text, Box, Pressable, ScrollView, HStack } from "native-base";
import { useState } from "react";
import { TextInput } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Pages } from "../../constants/pages";
import { useGetKeyboardStatus } from "../../hooks/useGetKeyboardStatus";
import { storeForm } from "../../redux/form/actions";
import { publicApi } from "../../util/API";
import GrayCheckmark from "./../../assets/images/svgs/GrayCheckmark.svg";
const RegisterPasswordScreen = ({ navigation, route }) => {
  const keyboardStatus = useGetKeyboardStatus();
  const [confirmPwd, setConfirmPwd] = useState();
  const form = useSelector((state) => state.form);

  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [nextPressed, setNextPressed] = useState();

  const handleInput = (name) => (val) => {
    setError();
    setNextPressed();
    dispatch(storeForm({ [name]: val, error: null }));
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps="handled"
    >
      <VStack
        pb={
          keyboardStatus === "Keyboard Hidden" || keyboardStatus === undefined
            ? 0
            : 96
        }
        alignItems={"center"}
        px={{
          md: "10px",
          lg: "28px",
          xl: "28px",
        }}
        pt={12}
        h="full"
        space={8}
      >
        <VStack space={"30px"} w="full">
          <VStack w="full" space={"10px"}>
            <Text
              color={"#94A3B8"}
              fontSize={"16px"}
              style={{
                fontFamily: "HK_500Medium",
              }}
            >
              Set your Account Password
            </Text>

            <TextInput
              secureTextEntry={true}
              onChangeText={(val) => handleInput("password")(val)}
              editable
              style={{
                borderColor: "#58595B",
                borderWidth: 1,
                borderRadius: 8,
                height: 52,
                flex: 1,
                textAlign: "center",
                color: "white",
                fontSize: 16,
                fontFamily: "HK_700Bold",
              }}
            />
          </VStack>
          <VStack w="full" space={"10px"}>
            <Text
              color={"#94A3B8"}
              fontSize={"16px"}
              style={{
                fontFamily: "HK_500Medium",
              }}
            >
              Confirm Password
            </Text>
            <TextInput
              onChangeText={(val) => {
                setError();
                setNextPressed();
                setConfirmPwd(val);
              }}
              editable
              style={{
                borderColor: "#58595B",
                borderWidth: 1,
                borderRadius: 8,
                height: 52,
                flex: 1,
                textAlign: "center",
                color: "white",
                fontSize: 16,
                fontFamily: "HK_700Bold",
              }}
            />
          </VStack>
          {(/\s/g.test(form?.password) || /\s/g.test(confirmPwd)) && (
            <Text
              fontSize={14}
              style={{
                fontFamily: "HK_400Regular",
              }}
              color="#ff0000"
            >
              Password can't contain spaces.
            </Text>
          )}
          {(!form?.password || !confirmPwd) && nextPressed && (
            <Text
              fontSize={14}
              style={{
                fontFamily: "HK_400Regular",
              }}
              color="#ff0000"
            >
              Fill password.
            </Text>
          )}
        </VStack>
        <VStack w="full" space={"12px"}>
          <HStack space={2}>
            {form?.password &&
              confirmPwd &&
              form?.password === confirmPwd &&
              form?.password !== "" && <GrayCheckmark />}

            <Text
              color={"#94A3B8"}
              fontSize={"16px"}
              style={{
                fontFamily: "HK_400Regular",
              }}
            >
              Passwords Match
            </Text>
          </HStack>

          <HStack space={2}>
            {form?.password?.length >= 8 && <GrayCheckmark />}

            <Text
              color={"#94A3B8"}
              fontSize={"16px"}
              style={{
                fontFamily: "HK_400Regular",
              }}
            >
              8 Characters Long
            </Text>
          </HStack>

          <HStack space={2}>
            {form?.password && /[a-z]/.test(form?.password) && (
              <GrayCheckmark />
            )}

            <Text
              color={"#94A3B8"}
              fontSize={"16px"}
              style={{
                fontFamily: "HK_400Regular",
              }}
            >
              Lowercase Letter
            </Text>
          </HStack>

          <HStack space={2}>
            {form?.password && /[A-Z]/.test(form?.password) && (
              <GrayCheckmark />
            )}

            <Text
              color={"#94A3B8"}
              fontSize={"16px"}
              style={{
                fontFamily: "HK_400Regular",
              }}
            >
              Uppercase Letter
            </Text>
          </HStack>
          {error !== null && error !== undefined && (
            <Text
              fontSize={14}
              style={{
                fontFamily: "HK_400Regular",
              }}
              color="#ff0000"
            >
              {error}
            </Text>
          )}
        </VStack>

        <Pressable
          onPress={async () => {
            setNextPressed(true);

            if (
              form?.password &&
              confirmPwd &&
              !/\s/g.test(form?.password) &&
              !/\s/g.test(confirmPwd) &&
              form?.password === confirmPwd &&
              form?.password !== "" &&
              confirmPwd !== ""
            ) {
              if (route?.params?.type === "forgetPassword") {
                let res = await publicApi(
                  "/api/users/forgot_password/",
                  {
                    email: form?.email,
                    new_password: form?.password,
                  },
                  "post"
                );
                if (res.body.status === 201) {
                  navigation.navigate(Pages.Login);
                }
              } else {
                navigation.navigate(Pages.UploadPictureScreen, {
                  type: route?.params?.type === "phone" ? "phone" : undefined,
                });
              }
            } else {
              setError("Passwords are not matching.");
            }
          }}
        >
          <Box
            alignItems="center"
            flexDirection="row"
            justifyContent="center"
            w="173.35px"
            h="50px"
            alignSelf={"center"}
            bg={{
              linearGradient: {
                colors: [
                  "primary.ctaLinearFirstColor",
                  "primary.ctaLinearSecondColor",
                ],
                start: [0, 0],
                end: [1, 0],
              },
            }}
            rounded="10px"
            _text={{
              color: "#ffffff",
            }}
          >
            <Text
              color="white"
              style={{
                fontFamily: "HK_600SemiBold",
              }}
              fontSize="14px"
            >
              Next
            </Text>
          </Box>
        </Pressable>
      </VStack>
    </ScrollView>
  );
};

export default RegisterPasswordScreen;
