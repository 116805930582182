import {
  Text,
  VStack,
  Stack,
  HStack,
  PresenceTransition,
  Checkbox,
  Center,
  View,
  Image,
  Pressable,
  Divider,
  useColorModeValue,
} from "native-base";
import ComboSkinTypeIcon from "../../components/icons/skinTypeIcons/inactiveIcons/comboSkinTypeIcon";
import NormalSkinTypeIcon from "./../../components/icons/skinTypeIcons/inactiveIcons/normalSkinTypeIcon";
import OilySkinTypeIcon from "./../../components/icons/skinTypeIcons/inactiveIcons/oilySkinTypeIcon";
import DrySkinTypeIcon from "./../../components/icons/skinTypeIcons/inactiveIcons/drySkinTypeIcon";
import ActiveComboSkinTypeIcon from "../../components/icons/skinTypeIcons/activeIcons/activeComboSkinType";
import ActiveDrySkinTypeIcon from "../../components/icons/skinTypeIcons/activeIcons/activeDrySkinType";
import ActiveNormalSkinTypeIcon from "../../components/icons/skinTypeIcons/activeIcons/activeNormalSkinType";
import ActiveOilySkinTypeIcon from "../../components/icons/skinTypeIcons/activeIcons/activeOilySkinType";
import { Pages } from "../../constants/pages";
import { setAnalytics } from "../../analytics";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import useGetUser from "../../hooks/useGetUser";
import SurveyScreenBottomBar from "../../components/bars/bottomBars/surveyScreenBottomBar";
import ChevronDownArrowIcon from "../../components/icons/chevronDownArrowIcon";
import ChevronLeftArrowIcon from "../../components/icons/chevronLeftArrowIcon";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";

const GettingToKnowYourSkinScreen = ({ navigation, route }) => {
  const userId = useSelector((state) => state.user.id);
  const borderColor = useColorModeValue("#F0F0F0", "#3B3B3B");
  const { data } = useGetUser("profile", userId);

  const [skinTone, setSkinTone] = useState([
    {
      label: "Very light",
      isChecked: data?.user[0]?.skin_tone === "Very light" ? true : false,
      color: "#FFEBDE",
    },
    {
      label: "Light",
      isChecked: data?.user[0]?.skin_tone === "Light" ? true : false,
      color: "#ECC9AB",
    },
    {
      label: "Medium",
      isChecked: data?.user[0]?.skin_tone === "Medium" ? true : false,
      color: "#D8A387",
    },
    {
      label: "Tan",
      isChecked: data?.user[0]?.skin_tone === "Tan" ? true : false,
      color: "#C08556",
    },
    {
      label: "Deep",
      isChecked: data?.user[0]?.skin_tone === "Deep" ? true : false,
      color: "#895432",
    },
    {
      label: "Very Deep",
      isChecked: data?.user[0]?.skin_tone === "Very Deep" ? true : false,
      color: "#44302D",
    },
  ]);

  const [skinType, setSkinType] = useState([
    {
      label: `Normal`,
      icon: <NormalSkinTypeIcon />,
      activeIcon: <ActiveNormalSkinTypeIcon />,
      pressed:
        data?.user[0].skin_type !== null &&
        (data?.user[0]?.skin_type
          .split("\u21b5")
          .join("")
          .replace(/[\n\r\s]/g, "") === `Normal` ||
          data?.user[0]?.skin_type
            .split("\u21b5")
            .join("")
            .replace(/[\n\r\s]/g, "") ===
            `Normal${"\n"}Skin`.replace(/[\n\r]/g, ""))
          ? true
          : false,
    },
    {
      label: `Oily`,
      icon: <OilySkinTypeIcon />,
      activeIcon: <ActiveOilySkinTypeIcon />,
      pressed:
        data?.user[0].skin_type !== null &&
        (data?.user[0]?.skin_type
          .split("\u21b5")
          .join("")
          .replace(/[\n\r\s]/g, "") === `Oily` ||
          data?.user[0]?.skin_type
            .split("\u21b5")
            .join("")
            .replace(/[\n\r\s]/g, "") ===
            `Oily${"\n"}Skin`.replace(/[\n\r]/g, ""))
          ? true
          : false,
    },
    {
      label: `Dry`,
      icon: <DrySkinTypeIcon />,
      activeIcon: <ActiveDrySkinTypeIcon />,
      pressed:
        data?.user[0].skin_type !== null &&
        (data?.user[0]?.skin_type
          .split("\u21b5")
          .join("")
          .replace(/[\n\r\s]/g, "") === `Dry` ||
          data?.user[0]?.skin_type
            .split("\u21b5")
            .join("")
            .replace(/[\n\r\s]/g, "") ===
            `Dry${"\n"}Skin`.replace(/[\n\r]/g, ""))
          ? true
          : false,
    },
    {
      label: "Combo",
      icon: <ComboSkinTypeIcon />,
      activeIcon: <ActiveComboSkinTypeIcon />,
      pressed: data?.user[0]?.skin_type === `Combo` ? true : false,
    },
  ]);

  const [underTones, setUnderTones] = useState([
    {
      label: "Warm",
      isPressed: data?.user[0]?.skin_undertone === "Warm" ? true : false,
    },
    {
      label: "Cool",
      isPressed: data?.user[0]?.skin_undertone === "Cool" ? true : false,
    },
    {
      label: "Neutral",
      isPressed: data?.user[0]?.skin_undertone === "Neutral" ? true : false,
    },
  ]);

  const [skinConcerns, setSkinConcerns] = useState([
    {
      label: "Dullness/Uneven Texture",
      clicked: data?.user[0]?.skin_concerns?.find(
        (k) => k === "Dullness/Uneven Texture"
      )
        ? true
        : false,
    },
    {
      label: "Hydration",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Hydration")
        ? true
        : false,
    },

    {
      label: "Dark Circles",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Dark Circles")
        ? true
        : false,
    },

    {
      label: "UV Damage",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "UV Damage")
        ? true
        : false,
    },
    {
      label: "Ageing",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Ageing")
        ? true
        : false,
    },

    {
      label: "Loss of firmness",
      clicked: data?.user[0]?.skin_concerns?.find(
        (k) => k === "Loss of firmness"
      )
        ? true
        : false,
    },
    {
      label: "Plumping",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Plumping")
        ? true
        : false,
    },
    {
      label: "Acne/Blemishes",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Acne/Blemishes")
        ? true
        : false,
    },

    {
      label: "Redness",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Redness")
        ? true
        : false,
    },
    {
      label: "Pores",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Pores")
        ? true
        : false,
    },
    {
      label: "Dark Spots",
      clicked: data?.user[0]?.skin_concerns?.find((k) => k === "Dark Spots")
        ? true
        : false,
    },
  ]);

  const editUserDetails = useEditUserDetails();

  const handleRequest = async (text) => {
    if (text === "Skip") {
      setAnalytics(
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
        }
      );
    } else {
      const skin_tone = skinTone?.find((k) => k?.isChecked)?.label;
      const skin_undertone = underTones?.find((k) => k?.isPressed)?.label;

      const skin_concerns = skinConcerns
        .filter((el) => el.clicked)
        .map((el2) => el2.label);
      const skin_type = skinType.find((k) => k.pressed)?.label;

      editUserDetails(
        {
          skin_tone,
          skin_undertone,
          skin_type: skin_type,
          skin_concerns: skin_concerns,
        },
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
          skin_tone,
          skin_undertone,
          edited: data?.user[0]?.quiz_completed,
        }
      );
    }

    navigation.navigate(Pages.UnderstandingHair);
  };

  return (
    <>
      <VStack space={12} w="full">
        <Stack
          w="full"
          justifyContent="center"
          space={{
            base: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: "50px",
          }}
          px={{
            base: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: "80px",
          }}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
        >
          <VStack
            space={"8px"}
            w={{
              xl: "20%",
            }}
          >
            <Text
              color="#682481"
              fontSize={"21px"}
              style={{
                fontFamily: "AustinCyr_400",
              }}
            >
              Your Skin
            </Text>
            <Text
              color="#464646"
              fontSize={"14px"}
              style={{
                fontFamily: "LeituraNewsRoman",
              }}
            >
              This info ensures that you get the most effective and suitable
              products for your skin that cater to a specific skin type,
              concerns, and needs
            </Text>
          </VStack>
          <VStack
            space={12}
            w={{
              xl: "50%",
            }}
          >
            <Stack
              borderWidth={"1px"}
              borderColor="#FAF1FF"
              borderRadius={"10px"}
              justifyContent="center"
              space={4}
              p={4}
              direction={{
                base: "column",
                sm: "column",
                md: "column",
                lg: "column",
                xl: "row",
              }}
            >
              <VStack w="45%" space={"10px"}>
                <Text
                  letterSpacing={3}
                  textTransform="uppercase"
                  color={"#682481"}
                  fontSize="11px"
                  style={{
                    fontFamily: "Hero_700Bold",
                  }}
                  _light={{
                    color: "#682481",
                  }}
                  _dark={{
                    color: "white",
                  }}
                >
                  Your Skintone
                </Text>
                {skinTone.map((k, index) => (
                  <VStack space={"10px"}>
                    <HStack
                      borderRadius={"8px"}
                      w="full"
                      pl={2}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      _light={{
                        bg: "#F0F0F0",
                      }}
                      _dark={{
                        bg: "#2B2F33",
                      }}
                      h="40px"
                    >
                      <Checkbox
                        isChecked={k.isChecked}
                        onChange={() => {
                          let undertoneCopy = underTones.map((i, j) => {
                            return {
                              ...underTones[j],
                              isPressed: false,
                            };
                          });
                          setUnderTones(undertoneCopy);
                          let copy = skinTone.map((i, j) => {
                            return {
                              ...skinTone[j],
                              isChecked: false,
                            };
                          });

                          copy[index] = {
                            ...copy[index],
                            isChecked: !k.isChecked,
                          };

                          setSkinTone(copy);
                        }}
                        borderWidth={"1px"}
                        _light={{
                          bg: "#F0F0F0",
                          borderColor: "#94A3B8",
                        }}
                        _dark={{
                          bg: "#2B2F33",
                          borderColor: "#58595B",
                        }}
                        borderRadius="2px"
                        _text={{
                          _dark: {
                            color: k.isChecked ? "#fff" : "#94A3B8",
                          },
                          _light: {
                            color: k.isChecked ? "#8D49DE" : "#475569",
                          },
                          _web: {
                            fontFamily: "Hero_400Regular",
                            fontSize: "14px",
                          },
                          fontSize: "16px",
                          fontFamily: "HK_500Medium",
                        }}
                      >
                        {k.label}
                      </Checkbox>

                      <Center
                        borderRightRadius={"8px"}
                        bg={k.color}
                        borderWidth={"1px"}
                        borderColor={k.color}
                        h="full"
                        w="50px"
                      >
                        {k.isChecked ? (
                          <View justifyContent={"center"}>
                            <ChevronDownArrowIcon />
                          </View>
                        ) : (
                          <View justifyContent={"center"} ml={"3"}>
                            <ChevronLeftArrowIcon />
                          </View>
                        )}
                      </Center>
                    </HStack>

                    {k.isChecked && (
                      <PresenceTransition
                        visible={k.isChecked}
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                          transition: {
                            duration: 100,
                          },
                        }}
                      >
                        <VStack
                          _light={{
                            bg: "#F0F0F0",
                          }}
                          _dark={{
                            bg: "#2B2F33",
                          }}
                          rounded="10px"
                          space={"20px"}
                          py={"20px"}
                        >
                          <Text
                            pl={3}
                            _light={{
                              color: "#475569",
                            }}
                            _dark={{
                              color: "#94A3B8",
                            }}
                            style={{
                              fontFamily: "HK_500Medium",
                            }}
                            _web={{
                              style: {
                                fontFamily: "AustinCyr_400",
                              },
                              textAlign: "center",
                              fontSize: "21px",
                            }}
                            fontSize={14}
                          >
                            And the complexion/undertone?
                          </Text>
                          <HStack justifyContent="space-evenly" px="12px">
                            {underTones.map((p, i) => (
                              <Pressable
                                onPress={() => {
                                  let copy = underTones.map((i, j) => {
                                    return {
                                      ...underTones[j],
                                      isPressed: false,
                                    };
                                  });

                                  copy[i] = { ...copy[i], isPressed: true };

                                  setUnderTones(copy);
                                }}
                              >
                                <Center
                                  size={"60px"}
                                  bg={{
                                    linearGradient: p.isPressed
                                      ? {
                                          colors: [
                                            "primary.ctaLinearSecondColor",
                                            "primary.ctaLinearFirstColor",
                                          ],
                                          start: [0, 0],
                                          end: [1, 0],
                                        }
                                      : {
                                          colors: [borderColor, borderColor],
                                          start: [0, 0],
                                          end: [1, 0],
                                        },
                                  }}
                                  rounded={"20px"}
                                >
                                  <Center
                                    size={"57px"}
                                    bg={k.color}
                                    rounded={"20px"}
                                  >
                                    {p.isPressed ? (
                                      <Image
                                        resizeMethod="scale"
                                        resizeMode="contain"
                                        size={8}
                                        source={require("../../assets/images/pngs/tick.png")}
                                      />
                                    ) : null}
                                  </Center>
                                </Center>
                                <HStack space={1} alignItems="center">
                                  <Text
                                    noOfLines={2}
                                    textAlign={"center"}
                                    _light={{
                                      color: p.isPressed
                                        ? "#8D49DE"
                                        : "#475569",
                                    }}
                                    _dark={{
                                      color: p.isPressed ? "white" : "#94A3B8",
                                    }}
                                    _web={{
                                      style: {
                                        fontFamily: "Hero_400Regular",
                                      },

                                      fontSize: "11px",
                                    }}
                                    style={{
                                      fontFamily: "Poppins_500Medium",
                                    }}
                                    fontSize={14}
                                  >
                                    {p.label}
                                    {"\n"}Undertone
                                  </Text>
                                </HStack>
                              </Pressable>
                            ))}
                          </HStack>
                        </VStack>
                      </PresenceTransition>
                    )}
                  </VStack>
                ))}
              </VStack>
              <Divider bg="#FAF1FF" orientation="vertical" h={"full"} />
              <VStack w="50%" space={8}>
                <VStack>
                  <Text
                    letterSpacing={3}
                    textTransform="uppercase"
                    color={"#682481"}
                    fontSize="11px"
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                    _light={{
                      color: "#682481",
                    }}
                    _dark={{
                      color: "white",
                    }}
                  >
                    Skin type
                  </Text>
                  <HStack justifyContent={"center"} space={4}>
                    {skinType.map((item, index) => (
                      <VStack key={index} alignItems={"center"} space={2}>
                        <Pressable
                          onPress={() => {
                            let copy = [...skinType];
                            copy.map((k) => {
                              if (k.pressed) {
                                return (k.pressed = false);
                              }
                            });
                            copy[index] = { ...copy[index], pressed: true };
                            setSkinType(copy);
                          }}
                        >
                          {item.pressed ? item.activeIcon : item.icon}
                        </Pressable>
                        <Text
                          textAlign={"center"}
                          _light={{
                            color: item?.pressed ? "#682481" : "#464646",
                          }}
                          _dark={{
                            color: item?.pressed ? "white" : "#58595B",
                          }}
                          fontSize={14}
                          style={{
                            fontFamily: "Hero_700Bold",
                          }}
                          lineHeight={20}
                        >
                          {item.label} Skin
                        </Text>
                      </VStack>
                    ))}
                  </HStack>
                </VStack>
                <Divider bg="#FAF1FF" />
                <VStack>
                  <Text
                    letterSpacing={3}
                    textTransform="uppercase"
                    color={"#682481"}
                    fontSize="11px"
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                    _light={{
                      color: "#682481",
                    }}
                    _dark={{
                      color: "white",
                    }}
                  >
                    Any skin concerns?
                  </Text>
                  <HStack alignItems={"center"} flexWrap="wrap" space={"10px"}>
                    {skinConcerns.map((label, index) => (
                      <Pressable
                        onPress={() => {
                          const copy = [...skinConcerns];
                          copy[index] = {
                            ...copy[index],
                            clicked: !label.clicked,
                          };
                          setSkinConcerns(copy);
                        }}
                      >
                        <Center
                          mt="10px"
                          h="25px"
                          rounded={"full"}
                          _light={{
                            bg: label.clicked ? "#E9B9FF" : "#F0F0F0",
                          }}
                          _dark={{
                            bg: "#2B2F33",
                          }}
                          px={"20px"}
                          mx={"2px"}
                        >
                          <Text
                            _dark={{
                              color: label.clicked ? "white" : "#94A3B8",
                            }}
                            _light={{
                              color: label.clicked ? "#682481" : "#464646",
                            }}
                            fontSize={"16px"}
                            _web={{
                              fontFamily: label.clicked
                                ? "Hero_700Bold"
                                : "Hero_400Regular",
                              fontSize: "14px",
                            }}
                            style={{
                              fontFamily: "HK_500Medium",
                            }}
                          >
                            {label.label}
                          </Text>
                        </Center>
                      </Pressable>
                    ))}
                  </HStack>
                </VStack>
              </VStack>
            </Stack>
            <SurveyScreenBottomBar
              showNextButton={skinTone?.find((k) => k?.isChecked)}
              nextOnPress={() => {
                handleRequest("Next");
              }}
            />
          </VStack>
        </Stack>
        <FooterSectionWeb />
      </VStack>
    </>
  );
};

export default GettingToKnowYourSkinScreen;
