import { Text, Box, Pressable } from "native-base";

const ExploreFlockButton = ({ onPress }) => {
  return (
    <>
      {/* use Pressable component from native base to make custom buttons */}
      <Pressable onPress={onPress}>
        <Box
          width={"200px"}
          height={"61px"}
          alignItems="center"
          flexDirection="row"
          justifyContent="space-around"
          bg={{
            linearGradient: {
              colors: [
                "primary.ctaLinearSecondColor",
                "primary.ctaLinearFirstColor",
              ],
              start: [0, 0],
              end: [1, 0],
            },
          }}
          rounded={16}
          _text={{
            color: "#ffffff",
          }}
        >
          <Text
            color="white"
            style={{
              fontFamily: "Poppins_500Medium",
            }}
            fontSize={14}
          >
            Explore Flock
          </Text>
        </Box>
      </Pressable>
    </>
  );
};

export default ExploreFlockButton;
