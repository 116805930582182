import {
  Avatar,
  Button,
  Center,
  HStack,
  ScrollView,
  Text,
  VStack,
} from "native-base";
import FlockDealOrderInfoCard from "../../components/cards/flockDealOrderInfoCard";
import SpotsLeftCard from "../../components/cards/spotsLeftCard";
import TimerChip from "../../components/chips/timerChip";
import StarStruckAnimatedIcon from "../../components/icons/animatedIcons/starStruckAnimatedIcon";
import FlockDealSteps from "../../components/modals/whatIsAFlockDeal/flockDealSteps";
import ConfettiCannon from "react-native-confetti-cannon";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import { useGetGroupDetails } from "../../hooks/useGetGroupDetails";
import ChevronLeftArrowIcon from "../../components/icons/chevronLeftArrowIcon";
import { Pages } from "../../constants/pages";
import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { BackHandler } from "react-native";

const CongratsScreen = ({ route }) => {
  const { data } = useGetGroupDetails(route?.params?.group_id); //passing id got from route from both the hooks usecreate and usejoin
  const navigation = useNavigation();
  useEffect(() => {
    function handleBackButton() {
      navigation.navigate(Pages.Home);
      return true;
    }

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      handleBackButton
    );

    return () => backHandler.remove();
  }, [navigation]);
  return (
    <>
      {data && (
        <>
          <ScrollView showsVerticalScrollIndicator={false}>
            <VStack
              pt="57px"
              alignItems={"center"}
              px={{
                md: "16px",
                lg: "16px",
                xl: "28px",
              }}
            >
              <StarStruckAnimatedIcon size={"130px"} />

              <VStack space={"12px"}>
                <Text
                  textAlign={"center"}
                  color="white"
                  fontSize={"36px"}
                  lineHeight="38px"
                  textTransform="none"
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                >
                  {data && data.users && data.users.length > 2
                    ? `Your Order is${"\n"}placed!`
                    : "Your spot in this Flock deal is confirmed!"}
                </Text>

                <VStack space={"20px"}>
                  {data && data.users && data.users.length > 2 ? (
                    <Text
                      lineHeight={"17.5px"}
                      textAlign={"center"}
                      color="white"
                      fontSize={"16px"}
                      textTransform="none"
                      style={{
                        fontFamily: "HK_600SemiBold",
                      }}
                    >
                      Yay! You have successfully filled the last spot of the
                      Flock Deal group. No more waiting!
                    </Text>
                  ) : (
                    <Text
                      lineHeight={"17.5px"}
                      textAlign={"center"}
                      color="white"
                      fontSize={"14px"}
                      textTransform="none"
                      style={{
                        fontFamily: "HK_400Regular",
                      }}
                    >
                      P.S: Your order will only be placed when all group spots
                      are filled. Speed things up! Invite Friends to fill the
                      group.
                    </Text>
                  )}

                  {data && data.users && data.users.length > 2 && (
                    <Text
                      lineHeight={"17.5px"}
                      letterSpacing="3"
                      textAlign={"center"}
                      color="white"
                      fontSize={"14px"}
                      textTransform="uppercase"
                      style={{
                        fontFamily: "HK_700Bold",
                      }}
                    >
                      ALL SPOTS FILLED!
                    </Text>
                  )}

                  {data && data.users && data.users.length < 3 && (
                    <TimerChip
                      time={
                        data && data.product && data.product[0].starting_time
                      }
                      images={data && data.users}
                    />
                  )}

                  <Center>
                    {data && data.users && data.users.length < 3 ? (
                      <SpotsLeftCard
                        images={data && data.users}
                        id={route?.params?.group_id}
                      />
                    ) : (
                      <HStack alignItems={"center"}>
                        <Avatar
                          left={"12%"}
                          bg="#5E17EB"
                          h="80px"
                          w="80px"
                          fallbackSource={require("./../../assets/images/pngs/fallbackuser.png")}
                          source={{
                            uri:
                              data &&
                              data.users &&
                              data.users[1] &&
                              data.users[1].image,
                          }}
                        ></Avatar>

                        <Avatar
                          bg="#5E17EB"
                          zIndex={9}
                          h="100px"
                          w="100px"
                          rounded={"full"}
                          fallbackSource={require("./../../assets/images/pngs/fallbackuser.png")}
                          source={{
                            uri:
                              data &&
                              data.users &&
                              data.users[0] &&
                              data.users[0].image,
                          }}
                        ></Avatar>

                        <Avatar
                          zIndex={0}
                          right={"12%"}
                          bg="#5E17EB"
                          h="80px"
                          w="80px"
                          fallbackSource={require("./../../assets/images/pngs/fallbackuser.png")}
                          source={{
                            uri:
                              data &&
                              data.users &&
                              data.users[2] &&
                              data.users[2].image,
                          }}
                        ></Avatar>
                      </HStack>
                    )}
                  </Center>

                  <FlockDealOrderInfoCard
                    brandName={
                      data && data.product && data.product[0].brand_name
                    }
                    image={
                      data &&
                      data.product &&
                      data.product[0].images &&
                      data.product[0].images[0]
                    }
                    productId={
                      data && data.product && data.product[0].product_id
                    }
                    retailPrice={
                      data && data.product && data.product[0].retail_price
                    }
                    groupPrice={
                      data && data.product && data.product[0].group_price
                    }
                    productName={
                      data && data.product && data.product[0].product_name
                    }
                    groupDiscount={
                      data && data.product && data.product[0].group_discount
                    }
                    variants={[
                      data &&
                        data.product &&
                        data.product[0] &&
                        data.product[0].color,
                      data &&
                        data.product &&
                        data.product[0] &&
                        data.product[0].size,
                      data &&
                        data.product &&
                        data.product[0] &&
                        data.product[0].type,
                      data &&
                        data.product &&
                        data.product[0] &&
                        data.product[0].scent,
                    ]}
                    productSkuId={
                      data &&
                      data.product &&
                      data.product[0] &&
                      data.product[0].product_sku_id
                    }
                  />
                  <Button
                    onPress={() => navigation.navigate(Pages.Orders)}
                    variant={"ghost"}
                    _text={{
                      color: "#1A94FF",
                      fontSize: 14,
                      lineHeight: 21,
                      fontFamily: "HK_600SemiBold",
                    }}
                    endIcon={<ChevronLeftArrowIcon color={"#1A94FF"} />}
                  >
                    Your Orders
                  </Button>
                  {data && data.users && data.users.length < 3 && (
                    <>
                      <Text
                        lineHeight={"17.5px"}
                        color="#94A3B8"
                        fontSize={"16px"}
                        textTransform="none"
                        style={{
                          fontFamily: "HK_700Bold",
                        }}
                      >
                        Next Steps:
                      </Text>
                      <FlockDealSteps />
                    </>
                  )}
                </VStack>
              </VStack>
            </VStack>
          </ScrollView>
          <BottomBar />
          <ConfettiCannon
            count={150}
            origin={{
              x: -10,
              y: 0,
            }}
            fadeOut={true}
          />
        </>
      )}
    </>
  );
};

export default CongratsScreen;
