import { Box } from "native-base";
import { BackIcon } from "./buildingBlocks/backIcon";
import { CenterText } from "./buildingBlocks/centerText";
import TopBar from "./templates/topBar";

const BackButtonAndCenterTextTopBar = ({ centerText }) => {
  return (
    <>
      <TopBar
        content={
          <>
            <BackIcon />
            <CenterText text={centerText} />
            <Box w="8"></Box>
          </>
        }
      />
    </>
  );
};

export default BackButtonAndCenterTextTopBar;
