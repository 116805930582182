import { Box, Center, FlatList, HStack, Pressable, Text } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import CheckboxWhiteTickIcon from "../../components/icons/checkboxWhiteTickIcon";
import { GRADIENTMAP } from "../../constants/gradient";
import { SkinConcerns } from "../../constants/skinConcerns";
import { changeFilter } from "../../redux/filter/actions";

const Skincare = () => {
  const skinConcernFilter = useSelector((state) => state.filter.skinConcerns);
  const dispatch = useDispatch();
  const renderItem = ({ item, index }) => (
    <Pressable
      _hover={{
        bg: "#FAF1FF",
      }}
    >
      <HStack space={2}>
        <Center
          rounded={"4px"}
          size={"24px"}
          bg={
            skinConcernFilter?.includes(item)
              ? {
                  linearGradient: {
                    colors: GRADIENTMAP["2"],
                    start: [0, 0],
                    end: [1, 0],
                  },
                }
              : "#EDEDED"
          }
        >
          <Pressable
            flexDir={"row"}
            justifyContent="center"
            alignItems={"center"}
            onPress={() => {
              let vals = [...skinConcernFilter];
              if (vals.includes(item) === true) {
                vals = [...vals.filter((value) => value !== item)];
              } else {
                vals.push(item);
              }
              dispatch(changeFilter({ skinConcerns: vals }));
              dispatch({
                type: "SET_APPLY_FILTER",
              });
            }}
            _hover={{
              bg: skinConcernFilter?.includes(item) ? "none" : "#FAF1FF",
              rounded: "4px",
            }}
            size={"full"}
            bg={
              skinConcernFilter?.includes(item)
                ? {
                    linearGradient: {
                      colors: GRADIENTMAP["2"],
                      start: [0, 0],
                      end: [1, 0],
                    },
                  }
                : "#EDEDED"
            }
            rounded={"4px"}
          >
            {skinConcernFilter?.includes(item) && <CheckboxWhiteTickIcon />}
          </Pressable>
        </Center>

        <Text
          color="#464646"
          fontSize={"16px"}
          style={{
            fontFamily: "Hero_700Bold",
          }}
        >
          {SkinConcerns[item]}
        </Text>
      </HStack>
    </Pressable>
  );
  return (
    <FlatList
      py={6}
      ItemSeparatorComponent={() => <Box h={4}></Box>}
      keyExtractor={(item) => item}
      data={Object.keys(SkinConcerns)}
      renderItem={renderItem}
    />
  );
};

export default Skincare;
