import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const QuantPlusIcon = ({ onPress, color = "#F8F8F8" }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}

      <Icon onPress={onPress} size={2} viewBox="0 0 8 9">
        <G width="8" height="9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <Path
            d="M8 3.83333H4.66667V0.5H3.33333V3.83333H0V5.16667H3.33333V8.5H4.66667V5.16667H8V3.83333Z"
            fill={color}
          />
        </G>
      </Icon>
    </>
  );
};

export default QuantPlusIcon;
