import { Box, HStack, Text, VStack, Image, Center } from "native-base";
import { useEffect, useState } from "react";
import PurpleUnderLineTabsBar from "../../../components/bars/purpleUnderlineTabsBar";
import FullWidthAccordion from "../../../components/accordions/fullWidthAccordion";
import WishListIcon from "../../../components/icons/wishListIcon";
import Ingredients from "./ingredients";
import { useNavigation, useRoute } from "@react-navigation/native";
import Usage from "./usage";
import Description from "./description";
import CartIcon from "../../../components/icons/cartIcon";
import { Pressable } from "react-native";
import { BrandImages } from "../../../constants/brands";
import RatingAndReview from "./ratingAndReview";
import ReturnIcon from "../../../components/icons/returnIcon";
import { useGetProductDetails } from "../../../hooks/useGetProductDetails";
import QuantityButton from "../../../components/buttons/quantityButton";
import { Pages } from "../../../constants/pages";
import { useDispatch, useSelector } from "react-redux";
import { Brands } from "../../../constants/brands";
import { resetProductsFilters } from "../../../redux/productsFilter/actions";
import { viewBrand } from "../../../redux/brand/actions";
import { changeProductsFilter } from "../../../redux/productsFilter/actions";
import TruckIcon from "../../../components/icons/truckIcon";

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const ProductDetails = ({ product }) => {
  const route = useRoute();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const userId = useSelector((state) => state.user.id);
  const referralId = useSelector((state) => state.currentState.referralId);
  const [numberOfLines, setNumberOfLines] = useState(1);
  const [navTabs, setNavTabs] = useState([
    {
      label: "Description",

      component: (
        <Description
          description={product.details ? product.details.description : ""}
        />
      ),
      pressed: true,
    },
    {
      label: "Ingredients",
      component: <Ingredients ingredients={product?.variant?.ingredients} />,
      pressed: false,
    },
    {
      label: "How to use",
      component: (
        <Usage usage={product.details ? product.details.instructions : ""} />
      ),

      pressed: false,
    },
  ]);

  useEffect(() => {
    if (route.params?.sku_id === undefined || route.params?.sku_id == null) {
      navigation.setParams({
        sku_id: product.skuId,
      });
    }
  }, []);

  return (
    <>
      {product?.variant && (
        <VStack>
          <VStack
            px={{
              md: "12px",
              lg: "18px",
              xl: "28px",
            }}
          >
            <HStack py={2} alignItems={"center"} justifyContent="space-between">
              <Pressable
                onPress={() => {
                  dispatch(resetProductsFilters());
                  let brandd = getKeyByValue(
                    Brands,
                    product.variant.brand_name
                  );
                  dispatch(viewBrand({ brand: product.variant.brand_name }));
                  dispatch(
                    changeProductsFilter({ brands: [brandd], type: "brand" })
                  );
                  dispatch({
                    type: "SET_APPLY_PRODUCTS_FILTER",
                  });
                  navigation.navigate(Pages.CategoryScreen, {
                    defaultValue: product?.variant?.brand_name,
                    fetcherType: "categoryProducts",
                  });
                }}
              >
                <HStack space={2} alignItems={"center"}>
                  <Image
                    size={4}
                    rounded="full"
                    resizeMethod="scale"
                    resizeMode="contain"
                    source={{
                      uri: BrandImages[product?.variant?.brand_name],
                    }}
                    alt=""
                  />

                  <Text
                    color="#94A3B8"
                    fontSize={"14px"}
                    lineHeight={16}
                    style={{
                      fontFamily: "HK_700Bold",
                    }}
                    letterSpacing={2}
                    textTransform={"uppercase"}
                  >
                    {product?.variant?.brand_name}
                  </Text>
                </HStack>
              </Pressable>
            </HStack>
            <VStack>
              <HStack alignItems={"center"} justifyContent="space-between">
                <Pressable
                  width={"75%"}
                  onPress={() => {
                    if (numberOfLines === 0) {
                      setNumberOfLines(1);
                    } else {
                      setNumberOfLines(0);
                    }
                  }}
                >
                  <Text
                    noOfLines={numberOfLines}
                    color="white"
                    fontSize={"20px"}
                    lineHeight={"25px"}
                    style={{
                      fontFamily: "HK_500Medium",
                    }}
                  >
                    {product?.variant?.product_name}
                  </Text>
                </Pressable>
                <HStack alignItems="center">
                  <WishListIcon
                    productSkuId={route?.params?.sku_id}
                    type="pinkFilled"
                    size={6}
                  />
                  <Text
                    color="#94A3B8"
                    fontSize={"14px"}
                    lineHeight={"17.5px"}
                    style={{
                      fontFamily: "HK_500Medium",
                    }}
                  ></Text>
                </HStack>
              </HStack>
            </VStack>
            <VStack>
              <HStack
                py={4}
                alignItems={"center"}
                justifyContent="space-between"
              >
                <HStack alignItems={"center"}>
                  <Center h="40px" w="150px">
                    <QuantityButton
                      userId={route?.params?.userId}
                      skuId={route?.params?.sku_id}
                    />
                  </Center>

                  <CartIcon
                    source={referralId ? "referral" : "regular"}
                    otherUserId={referralId}
                    referralId={referralId}
                    productId={product?.details?.id}
                    productSkuId={route?.params?.sku_id}
                    type="borderCart"
                    bundleId={route?.params?.bundle_id}
                  />
                </HStack>
              </HStack>

              <ReturnVariants />
            </VStack>
            <Center>
              <PurpleUnderLineTabsBar
                items={navTabs}
                setItems={(val) => setNavTabs(val)}
              />
              {navTabs.map((tab, index) => {
                if (tab.pressed) {
                  return (
                    <Box pt={3} key={index}>
                      {tab.component}
                    </Box>
                  );
                }
              })}
            </Center>

            <HStack
              pb="30px"
              justifyContent={"space-between"}
              alignItems="center"
              w="full"
            >
              {[
                {
                  label: `AUTHENTICITY${"\n"}GUARANTEED`,
                  navigate: Pages.Authenticity,
                  icon: (
                    <Image
                      size="12px"
                      resizeMethod="scale"
                      resizeMode="contain"
                      source={require("./../../../assets/images/pngs/Star3.png")}
                      alt=""
                    ></Image>
                  ),
                },
                {
                  label: `EASY${"\n"}RETURNS`,
                  navigate: Pages.ReturnsPolicy,
                  icon: <ReturnIcon />,
                },
                {
                  label: `3-5 Days Shipping${"\n"}within the U.S.`,

                  icon: <TruckIcon />,
                },
              ].map((item, i) => (
                <>
                  <Pressable
                    onPress={() => i < 2 && navigation.navigate(item?.navigate)}
                  >
                    <VStack
                      alignItems="center"
                      space={2}
                      justifyContent={"center"}
                    >
                      {item?.icon}
                      <Text
                        textAlign={"center"}
                        color={"#94A3B8"}
                        letterSpacing={3}
                        fontSize={"10px"}
                        style={{
                          fontFamily: "HK_700Bold",
                        }}
                      >
                        {item.label}
                      </Text>
                    </VStack>
                  </Pressable>
                  {i < 2 && (
                    <Center>
                      <Center
                        h="39px"
                        w="1px"
                        bg={{
                          linearGradient: {
                            colors: [
                              "primary.ctaLinearSecondColor",
                              "primary.ctaLinearFirstColor",
                            ],
                            start: [1, 0],
                            end: [0, 1],
                          },
                        }}
                      ></Center>
                    </Center>
                  )}
                </>
              ))}
            </HStack>
            <RatingAndReview />
          </VStack>
        </VStack>
      )}
    </>
  );
};

export const ReturnVariants = ({ type }) => {
  const route = useRoute();

  const { product } = useGetProductDetails(route?.params?.id);

  return (
    <>
      {product?.variant &&
        (type === "card" ? (
          <HStack space={"4px"} flexWrap="wrap">
            {product?.variant?.color !== null && (
              <FullWidthAccordion
                type={type}
                label={"Color"}
                items={product?.colors}
                val={product?.skus[route?.params?.sku_id]?.color}
              />
            )}
            {product?.variant?.scent !== null && (
              <FullWidthAccordion
                type={type}
                label={"Fragrance"}
                items={product?.scents}
                val={product?.skus[route?.params?.sku_id]?.scent}
              />
            )}
            {product?.variant?.size !== null && (
              <FullWidthAccordion
                type={type}
                label={"Size"}
                items={product?.sizes}
                val={product?.skus[route?.params?.sku_id]?.size}
              />
            )}
            {product?.variant?.type !== null && (
              <FullWidthAccordion
                type={type}
                label={"Type"}
                items={product?.types}
                val={product?.skus[route?.params?.sku_id]?.type}
              />
            )}
          </HStack>
        ) : (
          <VStack width={"100%"} space={1}>
            {product?.variant?.color !== null && (
              <FullWidthAccordion
                type={type}
                label={"Color"}
                items={product?.colors}
                val={product?.skus[route?.params?.sku_id]?.color}
              />
            )}
            {product?.variant?.scent !== null && (
              <FullWidthAccordion
                type={type}
                label={"Fragrance"}
                items={product?.scents}
                val={product?.skus[route?.params?.sku_id]?.scent}
              />
            )}
            {product?.variant?.size !== null && (
              <FullWidthAccordion
                type={type}
                label={"Size"}
                items={product?.sizes}
                val={product?.skus[route?.params?.sku_id]?.size}
              />
            )}
            {product?.variant?.type !== null && (
              <FullWidthAccordion
                type={type}
                label={"Type"}
                items={product?.types}
                val={product?.skus[route?.params?.sku_id]?.type}
              />
            )}
          </VStack>
        ))}
    </>
  );
};

export default ProductDetails;
