import axios from "axios";
import useSWR from "swr";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
import { getHeaders } from "../util/utilFunctions";
import { getUser } from "../util/utilFunctions";
const apiUrl = Constants.expoConfig.extra.apiUrl;

const fetcher = async (url) =>
  axios
    .get(url, {
      crossDomain: true,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data.data;
      } else {
        return null;
      }
    });
export default function useGetCollections(userId) {
  // const userId = await getUser();
  const { data, mutate, error, isValidating } = useSWR(
    `${apiUrl}/api/products/collections_of_user/?user_id=${userId}`,
    fetcher
  );

  useCheckNetwork(error);
  useLoader(isValidating);
  return {
    // data: data
    //   ?.map((item) => item?.product_skus,)
    //   .filter((item) => item?.length > 0),
    data: data
      ?.sort((a, b) => b.id - a.id)
      ?.map((item) => {
        return {
          name: item.name,
          product_skus: item.product_skus,
          id: item.id,
        };
      })
      ?.filter((item) => item.product_skus.length > 0),
    // .flatMap((item) => item),
    mutate,
    error,
    isValidating,
  };
}
