import { Box, FlatList } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import ViewMoreSideBar from "./bars/sideBars/viewMoreSideBar";
import BeautyBuddiesCard from "./cards/beautyBuddiesCard";
import { showLoader } from "../redux/currentState/actions";

const BeautyBuddies = ({ heading, image }) => {
  const userId = useSelector((state) => state.user.id);
  const dispatch = useDispatch();
  const renderItem = ({ item }) => <BeautyBuddiesCard item={item} />;
  const { data, size, setSize } = useGetBuddies("allUsersFetcher", userId);

  return (
    <FlatList
      showsHorizontalScrollIndicator={false}
      horizontal
      ItemSeparatorComponent={() => (
        <Box style={{ paddingHorizontal: 6 }}></Box>
      )}
      contentContainerStyle={{
        flexDirection: "row",
        alignItems: "center",
      }}
      ListHeaderComponent={<ViewMoreSideBar heading={heading} image={image} />}
      flexGrow={0}
      data={data ? data?.filter((k) => k?.id !== userId) : []}
      renderItem={renderItem}
      onEndReached={() => {
        dispatch(showLoader());
        setSize(size + 1);
      }}
    />
  );
};

export default BeautyBuddies;
