import { Icon } from "native-base";
import { Path, G } from "react-native-svg";
const WebUserIcon = () => {
  return (
    <Icon viewBox="0 0 24 24">
      <G>
        <Path
          d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM6.42 19.8C7.992 18.672 9.912 18 12 18C14.088 18 16.008 18.672 17.58 19.8C16.008 20.928 14.088 21.6 12 21.6C9.912 21.6 7.992 20.928 6.42 19.8ZM19.368 18.144C17.2661 16.4949 14.6717 15.5986 12 15.5986C9.32834 15.5986 6.73393 16.4949 4.632 18.144C3.18831 16.4224 2.39796 14.2468 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 14.34 20.76 16.476 19.368 18.144Z"
          fill="#682481"
        />
        <Path
          d="M11.9988 4.80029C9.68283 4.80029 7.79883 6.68429 7.79883 9.00029C7.79883 11.3163 9.68283 13.2003 11.9988 13.2003C14.3148 13.2003 16.1988 11.3163 16.1988 9.00029C16.1988 6.68429 14.3148 4.80029 11.9988 4.80029ZM11.9988 10.8003C11.0028 10.8003 10.1988 9.99629 10.1988 9.00029C10.1988 8.00429 11.0028 7.20029 11.9988 7.20029C12.9948 7.20029 13.7988 8.00429 13.7988 9.00029C13.7988 9.99629 12.9948 10.8003 11.9988 10.8003Z"
          fill="#682481"
        />
      </G>
    </Icon>
  );
};

export default WebUserIcon;
