import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const QuantMinusIcon = ({ onPress, color = "#F8F8F8" }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={2} onPress={onPress} viewBox="0 0 8 3">
        <G width="8" height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
          <Path d="M8 0.833252H0V2.16659H8V0.833252Z" fill={color} />
        </G>
      </Icon>
    </>
  );
};

export default QuantMinusIcon;
