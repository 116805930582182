import {
  Center,
  FlatList,
  Image,
  Pressable,
  Text,
  useBreakpointValue,
  VStack,
} from "native-base";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import SearchBox from "../../components/popUps/filterPopUp/buildingBlocks/searchBox.js";
import { BrandImages } from "../../constants/brands";
import { gradientTextWeb } from "../../constants/gradient.js";
import { Pages } from "../../constants/pages";
import useResetFilter from "../../hooks/useResetFilter.js";
import { changeFilter } from "../../redux/filter/actions";
import { Brands as BrandList } from "./../../constants/brands";
const Brands = ({ navigation }) => {
  useResetFilter();
  const brandFilter = useSelector((state) => state.filter.brands);
  const [brandResults, setBrandResults] = useState(Object.keys(BrandList));
  const dispatch = useDispatch();
  const [columnNumber, setColumnNumber] = useState(8);

  const breakpoint = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: "xl",
  });
  const map = {
    base: 3,
    sm: 3,
    md: 3,
    lg: 3,
    xl: 8,
  };

  useEffect(() => {
    setColumnNumber(map[breakpoint]);
  }, [breakpoint, columnNumber]);
  const renderItem = ({ item }) => (
    <Pressable
      onPress={() => {
        let vals = [...brandFilter];
        if (vals.includes(item) === true) {
          vals = [...vals.filter((value) => value !== item)];
        } else {
          vals.push(item);
        }
        dispatch(changeFilter({ brands: vals }));
        dispatch({
          type: "SET_APPLY_FILTER",
        });
        navigation.navigate(Pages.ShopScreen);
      }}
    >
      <Center m={1.5}>
        <Center
          bg="white"
          borderWidth={"1px"}
          borderColor="#68248133"
          borderRadius={"11px"}
          h={{
            base: "54px",
            sm: "54px",
            md: "54px",
            lg: "54px",
            xl: "80px",
          }}
          w={{
            base: "105px",
            sm: "105px",
            md: "105px",
            lg: "105px",
            xl: "153px",
          }}
        >
          <Text
            _web={{
              style: {
                ...gradientTextWeb(1),
                fontFamily: "AustinCyr_400",
                textAlign: "center",
              },
            }}
            fontSize={"16px"}
          >
            {BrandList[item]}
          </Text>
        </Center>
      </Center>
    </Pressable>
  );

  return (
    <>
      <VStack py={12} w="full" space={"30px"} alignItems="center">
        <Text
          _web={{
            style: {
              ...gradientTextWeb(1),
              fontFamily: "AustinCyr_400",
            },
          }}
          fontSize={"36px"}
        >
          Brands
        </Text>
        <Center w="250px" h="40px">
          <SearchBox
            onChange={async (val) => {
              let brandNames = Object.keys(BrandList);
              let brResults = brandNames.filter((brand) => {
                return BrandList[brand]
                  .toLowerCase()
                  .includes(val.toLowerCase());
              });
              setBrandResults(brResults);
            }}
          />
        </Center>
      </VStack>

      <FlatList
        key={columnNumber}
        flex={1}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        initialNumToRender={2}
        columnWrapperStyle={{
          justifyContent: "center",
        }}
        data={brandResults}
        _web={{
          numColumns: columnNumber,
        }}
        renderItem={renderItem}
      />
      <FooterSectionWeb />
    </>
  );
};

export default Brands;
