import {
  HStack,
  Text,
  Box,
  VStack,
  Pressable,
  useColorModeValue,
} from "native-base";
import { useEffect, useRef, useState } from "react";
import * as Animatable from "react-native-animatable";
import { useSelector } from "react-redux";
import useGetCartItems from "../../hooks/useGetCartItems";
const PurpleUnderLineTabsBar = ({ items, setItems, spacing }) => {
  const AnimationRef = useRef([]);
  const [previousIndex, setPreviousIndex] = useState(0);
  useEffect(() => {
    AnimationRef.current = AnimationRef.current.slice(0, items.length);
  }, [items]);
  const userId = useSelector((state) => state.user.id);
  const { data: bagItems } = useGetCartItems(userId);

  const wishlist = useSelector((state) => state.wishlist);
  const backgroundColor = useColorModeValue("white", "#111315");
  return (
    <>
      <HStack
        width={"100%"}
        pt={3}
        justifyContent={"space-evenly"}
        alignItems={"center"}
      >
        {items.map((tab, index) => (
          <VStack key={index} space={spacing && spacing} alignItems={"center"}>
            <Pressable
              onPress={() => {
                setPreviousIndex(index);
                if (AnimationRef && index !== previousIndex) {
                  {
                    index > previousIndex
                      ? AnimationRef.current[index]?.slideInLeft()
                      : AnimationRef.current[index]?.slideInRight();
                  }
                }
                let copy = [...items];
                copy.map((k) => {
                  if (k.pressed) {
                    return (k.pressed = false);
                  }
                });
                copy[index] = { ...copy[index], pressed: true };
                setItems(copy);
              }}
            >
              <HStack space={1} alignItems={"center"}>
                {tab.pressed ? tab.activeIcon : tab.icon}

                <Text
                  color={tab.pressed ? "#fff" : "#58595B"}
                  fontSize={16}
                  lineHeight={40}
                  style={{
                    fontFamily: tab.pressed ? "HK_600SemiBold" : "HK_700Bold",
                  }}
                >
                  {tab.label}{" "}
                  <Text
                    color={tab.pressed ? "#fff" : "#58595B"}
                    fontSize={16}
                    lineHeight={40}
                    style={{
                      fontFamily: "HK_600SemiBold",
                    }}
                  >
                    {tab?.label === "Bag" &&
                      bagItems &&
                      `(${bagItems
                        ?.map((k) => k?.number)
                        .reduce((k, i) => k + i, 0)})`}

                    {tab?.label === "Favorites" &&
                      wishlist &&
                      `(${Object.keys(wishlist)?.length})`}
                  </Text>
                </Text>
                <Animatable.View
                  animation="pulse"
                  easing="ease-out"
                  iterationCount="infinite"
                >
                  {tab.animatedIcon && tab.animatedIcon}
                </Animatable.View>
              </HStack>
            </Pressable>
            <Animatable.View ref={(el) => (AnimationRef.current[index] = el)}>
              <Box
                width={6}
                rounded={"lg"}
                borderBottomColor={tab.pressed ? "#7B61FF" : backgroundColor}
                borderBottomWidth={5}
              ></Box>
            </Animatable.View>
          </VStack>
        ))}
      </HStack>
    </>
  );
};

export default PurpleUnderLineTabsBar;
