import { STORE_WISHLIST, ADD_WISHLIST, DELETE_WISHLIST } from "./constants";

export function storeWishlist(wishlistItems) {
  return {
    type: STORE_WISHLIST,
    wishlistItems: wishlistItems,
  };
}

export function addToWishlist(productId) {
  return {
    type: ADD_WISHLIST,
    productId: productId,
  };
}

export function deleteFromWishlist(productId) {
  return {
    type: DELETE_WISHLIST,
    productId: productId,
  };
}
