import { Pressable, ScrollView, Text, VStack } from "native-base";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import { Linking } from "react-native";
const Authenticity = () => {
  return (
    <>
      <ScrollView zIndex={0} width="100%" showsVerticalScrollIndicator={false}>
        <VStack px={4} pt={6}>
          <Text
            style={{
              fontFamily: "HK_500Medium",
            }}
            fontSize={16}
            lineHeight={22}
            color="white"
          >
            {`At Flock, we bring the best in beauty and skincare to our customers. We bring you only authentic and verified products working only with authorized distributors.

We value our customers and take pride in providing you with an excellent selection of the best
quality merchandise at competitive prices.

We implement all the necessary measurements to have an enjoyable shopping experience with us. 

Thank you for shopping at Flock. For any questions, please contact  `}
            <Pressable
              onPress={() =>
                Linking.openURL(
                  "mailto:hi@flockshopping.com?subject=SendMail&body=Description"
                )
              }
            >
              <Text mb={-0.5} color={"#1A94FF"}>
                hi@flockshopping.com
              </Text>
            </Pressable>
          </Text>
        </VStack>
      </ScrollView>
      <BottomBar />
    </>
  );
};

export default Authenticity;
