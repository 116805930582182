import {
  Text,
  Modal,
  HStack,
  VStack,
  Slide,
  Center,
  FlatList,
  View,
} from "native-base";
import ChevronBackArrowIcon from "../icons/chevronBackArrowIcon";
import { useContext } from "react";
import ShippingAddressCard from "../cards/shippingAddressCard";
import AddNewAddressButton from "../buttons/addNewAddressButton";
import PrimaryCTAButton from "../buttons/primaryCTAButton";
import CTAButtonText from "../texts/ctaButtonText";
import { SavedAddressesContext } from "../../hoc/SavedAddressesHOC";

const AddressPopUp = ({ navigation }) => {
  const { selectedValue, setSelectedValue, addDefaultAddress, data } =
    useContext(SavedAddressesContext);
  return (
    <>
      <Modal
        size={"full"}
        bg="black:alpha.80"
        isOpen={true}
        onClose={() => navigation.goBack()}
      >
        <Slide overlay={false} in={true} w="full" placement="bottom">
          <VStack
            height={"90%"}
            roundedTop={"40px"}
            marginBottom={"0"}
            py={4}
            marginTop={"auto"}
            bg={"#2B2F33"}
            space={4}
            width="100%"
            px={4}
          >
            <HStack
              w="full"
              alignItems={"center"}
              justifyContent={"space-between"}
              pt={2}
              px={2}
            >
              <ChevronBackArrowIcon
                onPress={() => {
                  navigation.goBack();
                }}
              />
            </HStack>
            <HStack alignItems={"center"} justifyContent="space-between">
              <Text
                fontSize={16}
                style={{
                  fontFamily: "HK_700Bold",
                }}
                color="#94A3B8"
              >
                Shipping details
              </Text>
            </HStack>
            <AddNewAddressButton />
            <FlatList
              keyboardShouldPersistTaps="handled"
              showsVerticalScrollIndicator={false}
              ItemSeparatorComponent={() => (
                <View style={{ paddingVertical: 6 }}></View>
              )}
              contentContainerStyle={{
                paddingBottom: 12,
              }}
              data={data?.addresses}
              initialNumToRender={5}
              renderItem={({ item }) => (
                <ShippingAddressCard
                  address={item}
                  isAddressPopUp={true}
                  onCheck={() => {
                    setSelectedValue(item.id);
                  }}
                  isChecked={selectedValue === item.id}
                />
              )}
            />
            <Center safeAreaBottom>
              <Center w="150px" h="50px">
                <PrimaryCTAButton
                  gradientType="1"
                  onPress={() => addDefaultAddress(selectedValue)}
                >
                  <CTAButtonText text="confirm" />
                </PrimaryCTAButton>
              </Center>
            </Center>
          </VStack>
        </Slide>
      </Modal>
    </>
  );
};

export default AddressPopUp;
