import { STORE_WISHLIST, ADD_WISHLIST, DELETE_WISHLIST } from "./constants";

const initialState = {};

const wishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REFRESH":
      return {};
    case STORE_WISHLIST:
      return {
        ...createWishlist(action.wishlistItems),
      };
    case ADD_WISHLIST:
      return {
        ...state,
        ...add(action.productId),
      };
    case DELETE_WISHLIST:
      return {
        ...deleteItem(state, action.productId),
      };

    default:
      return state;
  }
};

const createWishlist = (wishlistItems) => {
  const el = {};
  let item;
  for (let it in wishlistItems) {
    if (wishlistItems[it]["product_sku_id"]) {
      item = wishlistItems[it]["product_sku_id"];
      el[item] = item;
    } else if (wishlistItems[it].id && wishlistItems[it].product_sku?.id) {
      item = wishlistItems[it].product_sku?.id;
      el[item] = item;
    }
  }
  return el;
};

const add = (productId) => {
  const el = {};
  el[productId] = productId;
  return el;
};

const deleteItem = (wishlist, id) => {
  const s = { ...wishlist };
  delete s[id];
  return { ...s };
};

export default wishlistReducer;
