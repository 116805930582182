import { VStack, FlatList, View } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import SearchBox from "../filterPopUp/buildingBlocks/searchBox";
import CheckBox from "../filterPopUp/buildingBlocks/checkbox";
import { HairTypes } from "../../../constants/hairTypes";

const Item = ({ item, setSelectedHairType, selectedHairType }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selected) {
      if (selectedHairType.includes(item)) {
        setSelected(true);
      }
    }
  }, [selectedHairType]);

  const onPress = () => {
    if (selectedHairType.includes(item)) {
      setSelected(false);
      const filtered = selectedHairType.filter((value) => value !== item);
      setSelectedHairType(filtered);
    } else {
      setSelected(true);
      setSelectedHairType([...selectedHairType, item]);
    }
  };

  return (
    <CheckBox
      onPress={onPress}
      checked={selected}
      label={`${HairTypes[item]} Hair`}
      item={item}
    />
  );
};

const HairType = ({ selectedHairType, setSelectedHairType }) => {
  const hairTypeFilter = useSelector(
    (state) => state.communityFilter.cachedFilters.hair_type
  );

  const [hairTypeResults, setHairTypeResults] = useState(
    Object.keys(HairTypes)
  );

  const renderItem = ({ item }) => (
    <Item
      item={item}
      selectedHairType={selectedHairType}
      setSelectedHairType={setSelectedHairType}
    />
  );

  return (
    <VStack width={"100%"} pb={8} px={4} space={6}>
      <SearchBox
        onChange={async (val) => {
          let hairTypes = Object.keys(HairTypes);
          let hairTypeResult = hairTypes.filter((hairType) => {
            return HairTypes[hairType]
              .toLowerCase()
              .includes(val.toLowerCase());
          });
          setHairTypeResults(hairTypeResult);
        }}
      />

      <FlatList
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 11 }}></View>
        )}
        contentContainerStyle={{
          paddingBottom: 12,
        }}
        data={hairTypeResults}
        initialNumToRender={12}
        renderItem={renderItem}
      />
    </VStack>
  );
};

export default HairType;
