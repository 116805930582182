import {
  Center,
  HStack,
  Modal,
  Pressable,
  Text,
  TextArea,
  VStack,
} from "native-base";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAnalytics } from "../../../analytics";
import PrimaryCTAButton from "../../../components/buttons/primaryCTAButton";
import Error from "../../../components/error";
import GrayStarIconFilled from "../../../components/icons/grayStarIconFilled";
import RewardsIcon from "../../../components/icons/rewardsIcon";
import YellowStarIconFilled from "../../../components/icons/yellowStarIconFilled";
import { gradientTextWeb } from "../../../constants/gradient";
import { Pages } from "../../../constants/pages";
import { useGetProductReviews } from "../../../hooks/useGetProductReviews";
import { hideLoader, showLoader } from "../../../redux/currentState/actions";
import { privateApi } from "../../../util/API";
import { getUser } from "../../../util/utilFunctions";
const RateAndReview = ({ navigation, route }) => {
  const [rate, setRate] = useState(
    new Array(5).fill({
      icon: <GrayStarIconFilled size={"28px"} color="#C1C9D2" />,
      activeIcon: <YellowStarIconFilled size={"28px"} />,
      pressed: false,
    })
  );

  const userId = useSelector((state) => state.user.id);

  const dispatch = useDispatch();
  const [review, setReview] = useState("");
  const [error, setError] = useState();
  const [rating, setRating] = useState(null);

  const { mutate } = useGetProductReviews(route?.params?.id);
  console.log("route", route?.params);
  const rateProduct = async () => {
    dispatch(showLoader());
    const userId = await getUser();

    let res = await privateApi(
      "/api/products/ratings/",
      {
        product: route.params.id,
        rating: rating,
        user: userId,
        date: 124335,
      },
      "post"
    );

    console.log("/api/products/ratings/", res);
    mutate();
    dispatch(hideLoader());
  };

  const leaveReview = async () => {
    dispatch(showLoader());
    const userId = await getUser();

    let res = await privateApi(
      "/api/products/review/",
      {
        product: route.params.id,
        review: review,
        user: userId,
        date: 124335,
      },
      "post"
    );
    console.log("/api/products/review/", res);
    if (res.status === 201) {
      mutate();
    }
    dispatch(hideLoader());
  };

  const onConfirm = () => {
    if (!userId) {
      setAnalytics(
        "Review And Rate Product",
        `Guest User Tried Reviewing And Rating Product`,
        {
          productId: route?.params?.id,
          screen: route?.name,
        }
      );
      navigation.navigate(Pages.Login);

      return;
    }

    if (
      rating === null ||
      rating === undefined ||
      review === null ||
      review === undefined ||
      review === ""
    ) {
      setError("Please rate the product and leave a review");
    }
    if (
      review !== "" &&
      review !== undefined &&
      rating !== null &&
      rating !== undefined &&
      review !== ""
    ) {
      setAnalytics("Review And Rating", `Reviewed And Rated`, {
        review: review,
        rating: rate,
        screen: route?.name,
      });
      leaveReview();
      rateProduct();
      navigation.goBack();
    }
  };
  return (
    <Modal
      size="full"
      bg="black:alpha.70"
      isOpen={true}
      onClose={() => navigation.goBack()}
    >
      <Modal.Content
        rounded={"20px"}
        w={{
          md: "full",
          lg: "full",
          xl: "60%",
        }}
        bg="white"
        p={{
          md: 4,
          lg: 4,
          xl: 16,
        }}
      >
        <VStack alignItems={"center"} space={"25px"}>
          <Text
            fontSize={"36px"}
            _web={{
              style: {
                ...gradientTextWeb(1),
                fontFamily: "AustinCyr_400",
              },
            }}
          >
            Write a review
          </Text>
          {true && (
            <VStack alignItems={"center"} space={"25px"} w="full">
              <Text
                fontSize={"14px"}
                style={{
                  fontFamily: "Hero_400Regular",
                }}
                _dark={{
                  color: "#94A3B8",
                }}
                _light={{
                  color: "#464646",
                }}
              >
                Rate this product
              </Text>
              <HStack space={1}>
                {rate.map((item, index) => (
                  <Pressable
                    key={index}
                    onPress={() => {
                      setError();
                      if (!userId) {
                        setAnalytics("Rate Product", `Tried Rating Product`, {
                          productId: route?.params?.id,
                          screen: route?.name,
                        });
                        navigation.navigate(Pages.Login);
                        return;
                      }
                      let copy = [...rate];

                      copy.map((k) => {
                        if (k.pressed) {
                          return (k.pressed = false);
                        }
                      });

                      for (let j = index; j >= 0; j--) {
                        copy[j] = { ...copy[j], pressed: true };
                      }

                      setRate(copy);
                      setRating(copy.filter((item) => item.pressed).length);
                    }}
                  >
                    {rate[index].pressed ? item.activeIcon : item.icon}
                  </Pressable>
                ))}
              </HStack>
              <TextArea
                style={{
                  fontFamily: "HK_400Regular",
                }}
                color="white"
                fontSize={16}
                lineHeight={24}
                borderWidth={2}
                _light={{
                  borderColor: "#C1C9D2",
                  focusOutlineColor: "#682481",
                  _hover: {
                    borderColor: "#C1C9D2",
                  },
                  color: "#464646",
                  _focus: {
                    backgroundColor: "white",
                  },
                }}
                _dark={{
                  borderColor: "#C1C9D2",
                  focusOutlineColor: "#58595B",
                  _hover: {
                    borderColor: "#58595B",
                  },
                  _focus: {
                    borderWidth: 2,
                    borderColor: "#58595B",
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                borderColor="#58595B"
                rounded={"8px"}
                h={"100px"}
                w="full"
                placeholderTextColor="#94A3B8"
                placeholder="Leave your Review"
                isDisabled={!userId}
                onChangeText={(val) => {
                  setError();
                  setReview(val);
                }}
              />
              {error !== null && error !== undefined && <Error error={error} />}
            </VStack>
          )}

          <Center h="50px" w="250px">
            <PrimaryCTAButton onPress={onConfirm} gradientType="1">
              <Text
                letterSpacing={2}
                textTransform={"uppercase"}
                fontSize={"16px"}
                style={{
                  fontFamily: "Hero_700Bold",
                }}
                _dark={{
                  color: "#FFFFFF",
                }}
                _light={{
                  color: "#FFFFFF",
                }}
              >
                Confirm
              </Text>
            </PrimaryCTAButton>
          </Center>
          <HStack alignItems={"center"} space={1}>
            <Text
              fontSize={"14px"}
              style={{
                fontFamily: "Hero_700Bold",
              }}
              _dark={{
                color: "#94A3B8",
              }}
              _light={{
                color: "#94A3B8",
              }}
            >
              Get
            </Text>
            <Text
              fontSize={"14px"}
              style={{
                fontFamily: "HK_600SemiBold",
              }}
              _dark={{
                color: "#94A3B8",
              }}
              _light={{
                color: "#682481",
              }}
            >
              30
            </Text>
            <RewardsIcon size={4} />
            <Text
              fontSize={"14px"}
              style={{
                fontFamily: "Hero_700Bold",
              }}
              _dark={{
                color: "#94A3B8",
              }}
              _light={{
                color: "#94A3B8",
              }}
            >
              by leaving a Review
            </Text>
          </HStack>
        </VStack>
      </Modal.Content>
    </Modal>
  );
};

export default RateAndReview;
