import {
  View,
  Text,
  VStack,
  FlatList,
  Center,
  HStack,
  Stack,
  Checkbox,
} from "native-base";
import { useState } from "react";
import { Dimensions } from "react-native";
import { Pages } from "../../constants/pages";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import { useSelector } from "react-redux";
import { setAnalytics } from "../../analytics";
import InterestCard from "../../components/cards/interestCard";
// import CrueltyFreeIcon from "./../../components/icons/crueltyFreeIcon";
// import SustainableIcon from "../../components/icons/sustainableIcon";
// import VeganIcon from "../../components/icons/veganIcon";
// import CleanIcon from "../../components/icons/cleanIcon";
import useGetUser from "../../hooks/useGetUser";
import SurveyScreenBottomBar from "../../components/bars/bottomBars/surveyScreenBottomBar";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";

const PreferencesScreen = ({ route, navigation }) => {
  const userId = useSelector((state) => state.user.id);
  const { data } = useGetUser("profile", userId);

  const editUserDetails = useEditUserDetails();
  const [choices, setChoices] = useState([
    {
      label: "Cruelty-free",
      // icon: <CrueltyFreeIcon />,
      pressed: data?.user[0]?.conscious_beauty_characteristics?.find(
        (k) => k === "Cruelty-free"
      )
        ? true
        : false,
    },
    {
      label: "Sustainable/Recyclable",
      // icon: <SustainableIcon />,
      pressed: data?.user[0]?.conscious_beauty_characteristics?.find(
        (k) => k === "Sustainable/Recyclable"
      )
        ? true
        : false,
    },
    {
      label: "Vegan",
      // icon: <VeganIcon />,
      pressed: data?.user[0]?.conscious_beauty_characteristics?.find(
        (k) => k === "Vegan"
      )
        ? true
        : false,
    },
    {
      label: "Clean",
      // icon: <CleanIcon />,
      pressed: data?.user[0]?.conscious_beauty_characteristics?.find(
        (k) => k === "Clean"
      )
        ? true
        : false,
    },
  ]);

  const handleRequest = async (text) => {
    if (text === "Skip") {
      setAnalytics(`${text} Button Survey Screen`, `${text} Button Clicked`, {
        screen: route?.name,
      });
      editUserDetails(
        {
          quiz_completed: true,
        },
        `${text} Button Survey Screen`,
        `${text} Button Clicked`,
        {
          screen: route?.name,
          conscious_beauty_characteristics,
          edited: data?.user[0]?.quiz_completed,
        }
      );
    } else {
      const conscious_beauty_characteristics = choices
        .filter((el) => el?.pressed)
        .map((el2) => el2?.label);

      editUserDetails(
        {
          quiz_completed: true,
          conscious_beauty_characteristics,
        },
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
          conscious_beauty_characteristics,
          edited: data?.user[0]?.quiz_completed,
        }
      );
    }

    navigation.navigate(Pages.Yay);
  };

  return (
    <>
      <VStack space={12} w="full">
        <Stack
          w="full"
          justifyContent="center"
          space={{
            base: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: "50px",
          }}
          px={{
            base: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: "80px",
          }}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
        >
          <VStack
            space={"8px"}
            w={{
              base: "10%",
              sm: "10%",
              md: "10%",
              lg: "10%",
              xl: "20%",
            }}
          >
            <Text
              color="#682481"
              fontSize={"21px"}
              style={{
                fontFamily: "AustinCyr_400",
              }}
            >
              Preferences
            </Text>
            <Text
              color="#464646"
              fontSize={"14px"}
              style={{
                fontFamily: "LeituraNewsRoman",
              }}
            >
              Some personal choice in product types
            </Text>
          </VStack>
          <VStack space={12}>
            <Stack
              flex={1}
              alignItems={"center"}
              borderWidth={"1px"}
              borderColor="#FAF1FF"
              borderRadius={"10px"}
              justifyContent="center"
              space={4}
              p={4}
              direction={{
                base: "column",
                sm: "column",
                md: "column",
                lg: "column",
                xl: "row",
              }}
            >
              <VStack w="full">
                <Checkbox
                  height={"20px"}
                  borderWidth={"1px"}
                  onChange={(check) => {
                    if (check) {
                      setChoices(
                        choices.map((item) => {
                          item.pressed = true;
                          return item;
                        })
                      );
                    } else {
                      setChoices(
                        choices.map((item) => {
                          item.pressed = false;
                          return item;
                        })
                      );
                    }
                  }}
                  _light={{
                    bg: "#F0F0F0",
                    borderColor: "#94A3B8",
                  }}
                  _dark={{
                    bg: "#2B2F33",
                    borderColor: "#58595B",
                  }}
                  borderRadius="2px"
                  _text={{
                    _dark: {
                      color: "#fff",
                    },
                    _light: {
                      color: "#464646",
                    },
                    _web: {
                      fontFamily: "Hero_400Regular",
                      fontSize: "14px",
                    },
                    fontSize: "16px",
                    fontFamily: "HK_500Medium",
                  }}
                >
                  Select all
                </Checkbox>
                <FlatList
                  showsVerticalScrollIndicator={false}
                  scrollEnabled={false}
                  data={choices}
                  numColumns={2}
                  key={1}
                  renderItem={({ item, index }) => (
                    <Center m={2}>
                      <InterestCard
                        widthCenterMain={"296px"}
                        widhtCenterSub={"293px"}
                        icon={item.icon}
                        interestName={item.label}
                        onPress={() => {
                          let copy = [...choices];
                          copy[index] = {
                            ...copy[index],
                            pressed: !item.pressed,
                          };
                          setChoices(copy);
                        }}
                        cardPressed={item.pressed}
                      />
                    </Center>
                  )}
                />
              </VStack>
            </Stack>
            <SurveyScreenBottomBar
              showNextButton={choices.find((el) => el?.pressed)}
              nextOnPress={() => {
                handleRequest("Next");
              }}
            />
          </VStack>
        </Stack>
        <FooterSectionWeb />
      </VStack>
    </>
  );
};

export default PreferencesScreen;
