import { useNavigation, useRoute } from "@react-navigation/native";
import { FlatList, HStack, IconButton } from "native-base";
import { Pages } from "../../../constants/pages";
import ActiveCartIcon from "../../icons/bottomBarIcons/activeIcons/activeCartIcon";
import ActiveHomeIcon from "../../icons/bottomBarIcons/activeIcons/activeHomeIcon";
import ActiveProfileIcon from "../../icons/bottomBarIcons/activeIcons/activeProfileIcon";
import ActiveSearchIcon from "../../icons/bottomBarIcons/activeIcons/activeSearchIcon";
import InActiveCartIcon from "../../icons/bottomBarIcons/inactiveIcons/inactiveCartIcon";
import InActiveHomeIcon from "../../icons/bottomBarIcons/inactiveIcons/inactiveHomeIcon";
import InActiveSearchIcon from "../../icons/bottomBarIcons/inactiveIcons/inactiveSearchIcon";
import { useSelector } from "react-redux";
import { setAnalytics } from "../../../analytics";
import ActiveCommunityIcon from "../../icons/bottomBarIcons/activeIcons/activeCommunityIcon";
import InActiveCommunityIcon from "../../icons/bottomBarIcons/inactiveIcons/inactiveCommunityIcon";
import { Dimensions } from "react-native";
import InActiveProfileIcon from "../../icons/bottomBarIcons/inactiveIcons/inactiveProfileIcon";

const BottomBar = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const userId = useSelector((state) => state.user.id);

  const icons = [
    {
      label: "Home",
      activeIcon: <ActiveHomeIcon />,
      inactiveIcon: <InActiveHomeIcon />,
      onPress: () => {
        navigation.navigate(Pages.Home);
      },
    },

    {
      label: "Search",
      activeIcon: <ActiveSearchIcon />,
      inactiveIcon: <InActiveSearchIcon />,
      onPress: () => {
        navigation.navigate(Pages.Search);
      },
    },
    {
      label: "Shop",
      activeIcon: <ActiveCartIcon />,
      inactiveIcon: <InActiveCartIcon />,
      onPress: () => {
        navigation.navigate(Pages.Products);
      },
    },
    {
      label: "Community",
      activeIcon: <ActiveCommunityIcon />,
      inactiveIcon: <InActiveCommunityIcon />,
      onPress: () => {
        navigation.navigate(Pages.Community);
      },
    },
    {
      label: "UserScreen",
      activeIcon: <ActiveProfileIcon />,
      inactiveIcon: <InActiveProfileIcon />,
      onPress: () => {
        if (userId === null || userId === undefined) {
          navigation.navigate(Pages.SettingScreen);
        } else {
          navigation.navigate(Pages.UserScreen, {
            id: userId,
            type: "profile",
          });
        }
      },
    },
  ];

  return (
    <HStack
      zIndex={2}
      bg="#1A1C1E"
      h={Dimensions.get("screen").height / 9}
      width="100%"
    >
      <FlatList
        scrollEnabled={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
        }}
        horizontal
        data={icons}
        renderItem={({ item }) => (
          <IconButton
            size={"60px"}
            onPress={() => {
              setAnalytics(
                "Bottombar Navigation",
                `${
                  item.label === "UserScreen" ? "Profile" : item?.label
                } Icon Clicked On Bottom Bar`,
                {
                  screen: item?.label,
                }
              );

              item.onPress();
            }}
            _pressed={{
              bg: "#1A1C1E",
            }}
            icon={
              item.label === route.name ? item.activeIcon : item.inactiveIcon
            }
          ></IconButton>
        )}
      />
    </HStack>
  );
};

export default BottomBar;
