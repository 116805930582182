import { useRoute } from "@react-navigation/native";
import { createContext } from "react";
import { useFilters } from "../hooks/useFilters";
import useGetProducts from "../hooks/useGetProducts";
export const ProductsContext = createContext();

const ProductsHOC = (props) => {
  const route = useRoute();
  const filters = useFilters(props.fetcherType);
  const { data, setSize } = useGetProducts(
    props.fetcherType,
    filters,
    route?.params?.celebId
  );

  return (
    <ProductsContext.Provider
      value={{
        data,
        setSize,
      }}
    >
      {props.children}
    </ProductsContext.Provider>
  );
};

export default ProductsHOC;
