import { useNavigation, useRoute } from "@react-navigation/native";
import {
  Divider,
  FlatList,
  HStack,
  Image,
  Text,
  VStack,
  View,
  Box,
  Pressable,
  Center,
} from "native-base";
import { Platform } from "react-native";
import { BrandImages } from "../../../constants/brands";
import { Pages } from "../../../constants/pages";

const Reviews = ({ reviews, user }) => {
  const navigation = useNavigation();
  const route = useRoute();

  return (
    <>
      {Platform.OS === "web" ? (
        <VStack space={4}>
          <Text
            textTransform={"capitalize"}
            _dark={{
              color: "white",
            }}
            _light={{
              color: "#682481",
            }}
            _web={{
              style: {
                fontFamily: "AustinCyr_400",
              },
              fontSize: "21px",
            }}
            fontSize={14}
            lineHeight={20}
          >
            {route?.params?.type === "profile"
              ? "Your reviews "
              : `${user?.username}'s reviews `}
            ({reviews?.length})
          </Text>
          {reviews?.map((item, index) => (
            <>
              <VStack space={4}>
                <Pressable
                  onPress={() =>
                    navigation.navigate(Pages.ProductScreen, {
                      id: item?.id,
                      sku_id: item?.default_sku,
                    })
                  }
                >
                  <HStack space={3}>
                    <Image
                      alt=""
                      _web={{
                        size: "100px",
                        borderWidth: "1px",
                        borderColor: "#E9B9FF",
                      }}
                      size={"60px"}
                      rounded="8px"
                      resizeMethod="scale"
                      resizeMode="contain"
                      source={{
                        uri: item?.images[0],
                      }}
                    ></Image>
                    <VStack flex={1}>
                      <Text
                        w="90%"
                        noOfLines={1}
                        fontSize={"14px"}
                        _dark={{
                          color: "white",
                        }}
                        _light={{
                          color: "#464646",
                        }}
                        _web={{
                          style: {
                            fontFamily: "AustinCyr_400",
                          },
                          fontSize: "21px",
                        }}
                        style={{
                          fontFamily: "HK_500Medium",
                        }}
                      >
                        {item?.name}
                      </Text>
                      <HStack alignItems={"center"} space={2}>
                        <Image
                          alt=""
                          size={"4"}
                          rounded="full"
                          resizeMethod="scale"
                          resizeMode="contain"
                          source={{
                            uri: BrandImages[item?.brand_name],
                          }}
                        ></Image>
                        <Text
                          w="90%"
                          fontSize={"14px"}
                          _dark={{
                            color: "white",
                          }}
                          _light={{
                            color: "#464646",
                          }}
                          _web={{
                            style: {
                              fontFamily: "Hero_400Regular",
                            },
                            fontSize: "12px",
                          }}
                          style={{
                            fontFamily: "HK_500Medium",
                          }}
                        >
                          {item?.brand_name}
                        </Text>
                      </HStack>
                    </VStack>
                    <VStack justifyContent={"space-between"}>
                      {[
                        item?.colors
                          ? {
                              label: "Shade",
                              variant: item?.colors,
                            }
                          : null,
                        item?.size
                          ? {
                              label: "Size",
                              variant: item?.size,
                            }
                          : null,
                        item?.scent
                          ? {
                              label: "Scent",
                              variant: item?.scent,
                            }
                          : null,
                        item?.type
                          ? {
                              label: "Type",
                              variant: item?.type,
                            }
                          : null,
                      ]
                        ?.filter((f) => f)
                        .map((h, i) => {
                          return (
                            <VStack>
                              <Text
                                _dark={{
                                  color: "white",
                                }}
                                _light={{
                                  color: "#8F8F8F",
                                }}
                                _web={{
                                  style: {
                                    fontFamily: "Hero_400Regular",
                                  },
                                  fontSize: "10px",
                                }}
                              >
                                {h?.label}
                              </Text>
                              <Text
                                w="90%"
                                _dark={{
                                  color: "white",
                                }}
                                _light={{
                                  color: "#464646",
                                }}
                                _web={{
                                  style: {
                                    fontFamily: "Hero_400Regular",
                                  },
                                  fontSize: "12px",
                                }}
                              >
                                {h?.variant}
                              </Text>
                            </VStack>
                          );
                        })}
                    </VStack>
                  </HStack>
                </Pressable>
                <HStack space={2} alignItems="center">
                  {[
                    user?.skin_type,
                    ...user?.hair_type,
                    user?.hair_texture,
                  ]?.map((k, i) => (
                    <Center bg="#FAF1FF" h="30px" px={2} rounded={"20px"}>
                      <Text
                        _dark={{
                          color: "white",
                        }}
                        _light={{
                          color: "#682481",
                        }}
                        style={{
                          fontFamily: "Hero_400Regular",
                        }}
                      >
                        {i === 0
                          ? k
                              ?.split("\u21b5")
                              ?.join("")
                              ?.replace(/[\n\r\s]/g, " ")
                          : `${k} hair`}
                      </Text>
                    </Center>
                  ))}
                </HStack>
                <Text
                  _dark={{
                    color: "white",
                  }}
                  _light={{
                    color: "#682481",
                  }}
                  _web={{
                    style: {
                      fontFamily: "Hero_400Regular",
                    },
                    fontSize: "16px",
                  }}
                  fontSize={14}
                  lineHeight={20}
                  opacity={0.6}
                  style={{
                    fontFamily: "HK_400Regular",
                  }}
                >
                  {item?.review}
                </Text>

                <Divider
                  _dark={{
                    bg: "#94A3B8",
                  }}
                  _light={{
                    bg: "#E9B9FF",
                  }}
                />
              </VStack>
            </>
          ))}
        </VStack>
      ) : (
        <>
          <Box h="47px"></Box>
          <FlatList
            showsVerticalScrollIndicator={false}
            px={"28px"}
            alignItems={"center"}
            data={reviews && reviews}
            ItemSeparatorComponent={() => (
              <View style={{ paddingVertical: 8 }}></View>
            )}
            renderItem={(item) => (
              <>
                <VStack space={4} w="372px">
                  <Pressable
                    onPress={() =>
                      navigation.navigate(Pages.ProductScreen, {
                        id: item?.item?.id,
                      })
                    }
                  >
                    <HStack space={3}>
                      <Image
                        alt=""
                        size={"60px"}
                        rounded="8px"
                        resizeMethod="scale"
                        resizeMode="cover"
                        source={{
                          uri: item?.item?.images[0],
                        }}
                      ></Image>
                      <VStack flex={1} justifyContent="space-between">
                        <Text
                          w="90%"
                          noOfLines={1}
                          fontSize={"14px"}
                          color="white"
                          style={{
                            fontFamily: "HK_500Medium",
                          }}
                        >
                          {item.item.name}
                        </Text>
                        <HStack alignItems={"center"} space={2}>
                          <Image
                            alt=""
                            size={"4"}
                            rounded="full"
                            resizeMethod="scale"
                            resizeMode="contain"
                            source={{
                              uri: BrandImages[item.item.brand_name],
                            }}
                          ></Image>
                          <Text
                            w="90%"
                            fontSize={"14px"}
                            color="white"
                            style={{
                              fontFamily: "HK_500Medium",
                            }}
                          >
                            {item.item.brand_name}
                          </Text>
                        </HStack>
                      </VStack>
                    </HStack>
                  </Pressable>
                  {/* if the product have variants */}
                  {/* <HStack justifyContent={"space-between"} alignItems="center">
                <HStack space={4}>
                  {[1, 2].map((item, i) => (
                    <Text
                      w="30%"
                      noOfLines={2}
                      key={i}
                      textAlign={"center"}
                      fontSize={"10px"}
                      color="#94A3B8"
                      style={{
                        fontFamily: "HK_500Medium",
                      }}
                    >
                      Shade{"\n"}
                      <Text
                        fontSize={"12px"}
                        color="white"
                        style={{
                          fontFamily: "HK_600SemiBold",
                        }}
                      >
                        Light Peach Light Peach Light Peach Light Peach Light
                        Peach
                      </Text>
                    </Text>
                  ))}
                </HStack>
              </HStack> */}
                  {/* if we have survey data uncomment below code */}
                  {/* <HStack
                py={1}
                alignItems={"center"}
                justifyContent="space-between"
              >
                {[
                  {
                    label: "Dry Skin",
                    icon: require("./../../assets/images/pngs/gray_dry_skin_icon.png"),
                  },
                  {
                    label: "Treated hair",
                    icon: require("./../../assets/images/pngs/gray_treated_hair_icon.png"),
                  },
                  {
                    label: "Straight hair",
                    icon: require("./../../assets/images/pngs/gray_straight_hair_icon.png"),
                  },
                ].map((tab, index) => (
                  <HStack
                    key={index}
                    bg="#202427"
                    borderRadius="full"
                    justifyContent={"center"}
                    h="30px"
                    w="115px"
                    alignItems="center"
                  >
                    <Image
                      resizeMode="contain"
                      source={tab.icon}
                      alt=""
                    />
                    <Text
                      fontSize={14}
                      lineHeight={20}
                      style={{
                        fontFamily: "HK_500Medium",
                      }}
                      color="white"
                    >
                      {tab.label}
                    </Text>
                  </HStack>
                ))}
              </HStack> */}
                  <Text
                    color="#ffffff"
                    fontSize={14}
                    lineHeight={20}
                    opacity={0.6}
                    style={{
                      fontFamily: "HK_400Regular",
                    }}
                  >
                    {item.item.review}
                  </Text>
                  {/* if user uploaded review pictures uncomment below code */}
                  {/* <HStack alignItems={"center"} space={2}>
                <Image
                  alt=""
                  w="54px"
                  h={"50px"}
                  rounded="6px"
                  resizeMethod="scale"
                  resizeMode="cover"
                  source={{
                    uri: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
                  }}
                ></Image>
                <Image
                  alt=""
                  w="54px"
                  h={"50px"}
                  rounded="6px"
                  resizeMethod="scale"
                  resizeMode="cover"
                  source={{
                    uri: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
                  }}
                ></Image>
              </HStack> */}
                  {/* date of review */}
                  {/* <HStack justifyContent={"space-between"}>
                <Button variant={"ghost"}></Button>
                <Text
                  color="#ffffff"
                  fontSize={14}
                  lineHeight={20}
                  opacity={0.6}
                  style={{
                    fontFamily: "HK_400Regular",
                  }}
                >
                  Yesterday at 11:42 PM
                </Text>
              </HStack> */}
                  <Divider bg="#94A3B8" />
                </VStack>
              </>
            )}
          ></FlatList>
        </>
      )}
    </>
  );
};

export default Reviews;
