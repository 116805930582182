import { Icon } from "native-base";
import { Path, G } from "react-native-svg";
import { createIcon } from "native-base";
import { useSelector } from "react-redux";
import { IconButton } from "native-base";
import { useEffect, useState } from "react";
import { useRemoveFromWishlist } from "../../hooks/useRemoveFromWishlist";
import { useAddToWishlist } from "../../hooks/useAddToWishlist";
import WebGradientOutlineWishlistIcon from "./webIcons/webGradientOutlineWishlistIcon";

const WishListIcon = ({ type, color = "#94A3B8", size = 6, productSkuId }) => {
  const wishlist = useSelector((state) => state.wishlist[productSkuId]);
  const [filled, setFilled] = useState(false);
  const removeFromWishlist = useRemoveFromWishlist();
  const addToWishlist = useAddToWishlist();

  useEffect(() => {
    if (wishlist !== undefined && wishlist !== null) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [productSkuId, type, color, size, wishlist]);

  const onPress = () => {
    if (wishlist === undefined || wishlist === null) {
      addToWishlist(productSkuId);
    } else {
      removeFromWishlist(productSkuId);
    }
  };

  const IconType = () => {
    if (type === "pinkFilled") {
      if (filled === true) {
        return <WishListIconFilledPink />;
      } else {
        return <WishListIconOutlinedWhite />;
      }
    } else if (type === "blackOutline") {
      if (filled === true) {
        return <WishListIconPinkFilledBlackOutline />;
      } else {
        return <WishListIconOutlinedWhite />;
      }
    } else if (type === "outlined") {
      if (filled === true) {
        return <WishListIconFilledPink />;
      } else {
        return <WishlistOutlinedIcon />;
      }
    } else if (type === "gradient") {
      if (filled === true) {
        return <WishListIconFilledPink />;
      } else {
        return <WishlistGradientIcon />;
      }
    } else {
      if (filled === true) {
        return <WishListIconFilledPink />;
      } else {
        return <WishListIconOutlined color={color} />;
      }
    }
  };

  return (
    <IconButton
      _hover={
        type === "gradient" && { bg: "#FAF1FF", rounded: "full", size: 16 }
      }
      icon={<IconType />}
      onPress={() => {
        onPress();
      }}
    />
  );
};

const WishListIconOutlinedWhite = () => {
  return (
    <>
      <Icon viewBox="0 0 21 19">
        <G
          width="21"
          height="19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M19.9665 3.89367C19.649 3.13173 19.1912 2.44126 18.6188 1.86092C18.0459 1.27885 17.3705 0.816288 16.6293 0.498386C15.8606 0.167432 15.0362 -0.0019684 14.2039 1.7256e-05C13.0363 1.7256e-05 11.897 0.331444 10.907 0.957474C10.6701 1.10723 10.4451 1.27172 10.2319 1.45093C10.0188 1.27172 9.79377 1.10723 9.55692 0.957474C8.56688 0.331444 7.42763 1.7256e-05 6.25996 1.7256e-05C5.41914 1.7256e-05 4.60437 0.166958 3.83461 0.498386C3.0909 0.817538 2.42061 1.27663 1.84506 1.86092C1.27188 2.4406 0.814003 3.13124 0.497386 3.89367C0.168164 4.68664 0 5.52871 0 6.39533C0 7.21285 0.161058 8.06474 0.480807 8.93137C0.748448 9.65559 1.13215 10.4068 1.62243 11.1654C2.3993 12.3659 3.46749 13.618 4.79385 14.8872C6.99183 16.9912 9.16848 18.4446 9.26085 18.5035L9.82219 18.8766C10.0709 19.0411 10.3906 19.0411 10.6393 18.8766L11.2007 18.5035C11.293 18.4421 13.4673 16.9912 15.6677 14.8872C16.994 13.618 18.0622 12.3659 18.8391 11.1654C19.3294 10.4068 19.7154 9.65559 19.9807 8.93137C20.3005 8.06474 20.4615 7.21285 20.4615 6.39533C20.4639 5.52871 20.2957 4.68664 19.9665 3.89367Z"
            fill="#FFFFFF"
            strokeWidth={"1px"}
            stroke="#2B2F33"
          />
        </G>
      </Icon>
    </>
  );
};

export const WishListIconFilledPink = () => {
  return (
    <>
      <Icon viewBox="0 0 21 19">
        <G
          width="21"
          height="19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M19.9665 3.89367C19.649 3.13173 19.1912 2.44126 18.6188 1.86092C18.0459 1.27885 17.3705 0.816288 16.6293 0.498386C15.8606 0.167432 15.0362 -0.0019684 14.2039 1.7256e-05C13.0363 1.7256e-05 11.897 0.331444 10.907 0.957474C10.6701 1.10723 10.4451 1.27172 10.2319 1.45093C10.0188 1.27172 9.79377 1.10723 9.55692 0.957474C8.56688 0.331444 7.42763 1.7256e-05 6.25996 1.7256e-05C5.41914 1.7256e-05 4.60437 0.166958 3.83461 0.498386C3.0909 0.817538 2.42061 1.27663 1.84506 1.86092C1.27188 2.4406 0.814003 3.13124 0.497386 3.89367C0.168164 4.68664 0 5.52871 0 6.39533C0 7.21285 0.161058 8.06474 0.480807 8.93137C0.748448 9.65559 1.13215 10.4068 1.62243 11.1654C2.3993 12.3659 3.46749 13.618 4.79385 14.8872C6.99183 16.9912 9.16848 18.4446 9.26085 18.5035L9.82219 18.8766C10.0709 19.0411 10.3906 19.0411 10.6393 18.8766L11.2007 18.5035C11.293 18.4421 13.4673 16.9912 15.6677 14.8872C16.994 13.618 18.0622 12.3659 18.8391 11.1654C19.3294 10.4068 19.7154 9.65559 19.9807 8.93137C20.3005 8.06474 20.4615 7.21285 20.4615 6.39533C20.4639 5.52871 20.2957 4.68664 19.9665 3.89367Z"
            fill="#FD6699"
          />
        </G>
      </Icon>
    </>
  );
};

const WishListIconPinkFilledBlackOutline = () => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}

      <Icon viewBox="0 0 21 19">
        <G
          width="21"
          height="19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M0.949896 8.75829C1.2029 9.44284 1.56875 10.1612 2.0422 10.8938C2.79282 12.0537 3.83354 13.2762 5.13954 14.526L5.1396 14.526C6.22149 15.5617 7.29999 16.4388 8.12027 17.0647C8.53004 17.3774 8.87442 17.6267 9.1213 17.8009C9.24473 17.888 9.34365 17.9563 9.41403 18.0042C9.44923 18.0282 9.47716 18.047 9.49731 18.0605L9.52122 18.0764C9.52761 18.0806 9.53005 18.0821 9.52974 18.0819L9.53771 18.087L9.53766 18.0871L10.098 18.4596C10.0981 18.4597 10.0982 18.4597 10.0983 18.4598C10.1797 18.5134 10.282 18.5134 10.3633 18.4597C10.3634 18.4597 10.3634 18.4596 10.3635 18.4596L10.9239 18.0871L10.924 18.087C11.0063 18.0323 13.1527 16.6002 15.3221 14.5259C16.628 13.2762 17.6686 12.0539 18.4192 10.894C18.8933 10.1604 19.261 9.44241 19.5112 8.7594L19.5116 8.75829C19.8135 7.94021 19.9615 7.14688 19.9615 6.39533V6.39397C19.9637 5.59394 19.8087 4.81755 19.5048 4.08556C19.2117 3.38224 18.7895 2.74601 18.2628 2.21204L18.2625 2.21164C17.7345 1.67524 17.113 1.2499 16.4322 0.957908L16.4315 0.957625C15.7254 0.653569 14.9686 0.498194 14.2051 0.500016L14.2039 0.500019C13.1314 0.500017 12.0847 0.804299 11.1742 1.38007L11.1742 1.38008C10.9563 1.51786 10.7495 1.66903 10.5537 1.83365L10.2319 2.10416L9.91018 1.83365C9.71438 1.66903 9.50761 1.51786 9.28971 1.38008L9.28969 1.38007C8.37913 0.804299 7.33246 0.500017 6.25996 0.500017C5.48661 0.500017 4.73917 0.653295 4.03234 0.957627L4.03179 0.957865C3.34846 1.2511 2.73199 1.67301 2.20127 2.2118L2.20061 2.21248C1.67341 2.74565 1.25135 3.38182 0.959168 4.08539L0.949896 8.75829ZM0.949896 8.75829C0.648058 7.94021 0.5 7.14688 0.5 6.39533C0.5 5.59433 0.655245 4.81744 0.959153 4.08543L0.949896 8.75829Z"
            fill="#FD6699"
            strokeWidth={"1px"}
            stroke="#2B2F33"
          />
        </G>
      </Icon>
    </>
  );
};

export const WishListIconOutlined = ({ color = "#94A3B8" }) => {
  const CustomIcon = createIcon({
    width: "18",
    height: "16",
    viewBox: "0 0 18 16",

    xmlns: "http://www.w3.org/2000/svg",
    d: "M16.8139 3.27888C16.5465 2.63724 16.1611 2.0558 15.679 1.56709C15.1966 1.07693 14.6278 0.6874 14.0036 0.419693C13.3563 0.140996 12.6621 -0.0016576 11.9612 1.45314e-05C10.9779 1.45314e-05 10.0185 0.279111 9.18481 0.806293C8.98536 0.932404 8.79588 1.07092 8.61637 1.22184C8.43686 1.07092 8.24738 0.932404 8.04793 0.806293C7.21422 0.279111 6.25485 1.45314e-05 5.27154 1.45314e-05C4.56349 1.45314e-05 3.87737 0.140597 3.22914 0.419693C2.60286 0.688453 2.03841 1.07505 1.55374 1.56709C1.07106 2.05524 0.685476 2.63683 0.418851 3.27888C0.141612 3.94665 0 4.65576 0 5.38554C0 6.07398 0.135628 6.79136 0.40489 7.52115C0.630272 8.13103 0.953385 8.76365 1.36625 9.40247C2.02046 10.4134 2.91999 11.4678 4.03693 12.5366C5.88785 14.3084 7.72083 15.5323 7.79861 15.5819L8.27132 15.8961C8.48074 16.0346 8.75 16.0346 8.95943 15.8961L9.43213 15.5819C9.50992 15.5302 11.3409 14.3084 13.1938 12.5366C14.3108 11.4678 15.2103 10.4134 15.8645 9.40247C16.2774 8.76365 16.6025 8.13103 16.8259 7.52115C17.0951 6.79136 17.2307 6.07398 17.2307 5.38554C17.2327 4.65576 17.0911 3.94665 16.8139 3.27888ZM8.61637 14.2608C8.61637 14.2608 1.51584 9.54512 1.51584 5.38554C1.51584 3.27888 3.19723 1.57122 5.27154 1.57122C6.72955 1.57122 7.99408 2.41472 8.61637 3.64688C9.23866 2.41472 10.5032 1.57122 11.9612 1.57122C14.0355 1.57122 15.7169 3.27888 15.7169 5.38554C15.7169 9.54512 8.61637 14.2608 8.61637 14.2608Z",
    fill: "#94A3B8",
  });
  return <CustomIcon color={color} />;
};

export default WishListIcon;

const WishlistOutlinedIcon = () => {
  return (
    <Icon viewBox="0 0 22 20">
      <G width="22" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M2.57382 11.6263L11 19.7513L19.4262 11.6263C20.8887 10.5251 21.8333 8.7793 21.8333 6.81143C21.8333 3.48939 19.1369 0.792969 15.8149 0.792969C13.847 0.792969 12.0952 1.74414 11 3.20664C9.90474 1.74414 8.15299 0.792969 6.18511 0.792969C2.86307 0.792969 0.166656 3.48939 0.166656 6.81143C0.166656 8.7793 1.11132 10.5251 2.57382 11.6263ZM6.18511 2.95964C7.40711 2.95964 8.52999 3.52297 9.26557 4.50501L11 6.82064L12.7344 4.50501C13.47 3.52297 14.5929 2.95964 15.8149 2.95964C17.9387 2.95964 19.6667 4.68755 19.6667 6.81143C19.6667 8.03234 19.1039 9.1563 18.1224 9.89568L18.0167 9.9753L17.9214 10.0668L11 16.7413L4.07803 10.0668L3.9827 9.9753L3.87707 9.89568C2.89611 9.1563 2.33332 8.03234 2.33332 6.81143C2.33332 4.68755 4.06124 2.95964 6.18511 2.95964Z"
          fill="#94A3B8"
          // fill="#FD6699"
        />
      </G>
    </Icon>
  );
};

const WishlistGradientIcon = () => {
  return <WebGradientOutlineWishlistIcon />;
};
