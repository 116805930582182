// import { useNavigation } from "@react-navigation/native";
// import {
//   VStack,
//   Text,
//   Box,
//   Input,
//   ScrollView,
//   Pressable,
//   HStack,
//   Center,
// } from "native-base";
// import { useEffect, useState } from "react";
// import { Keyboard, TextInput } from "react-native";
// import { useDispatch, useSelector } from "react-redux";
// import { Pages } from "../../constants/pages";
// import useCheckUserExists from "../../hooks/useCheckUserExists";
// import { useGetKeyboardStatus } from "../../hooks/useGetKeyboardStatus";
// import { hideLoader, showLoader } from "../../redux/currentState/actions";
// import { initialiseForm, storeForm } from "../../redux/form/actions";
// import { publicApi } from "../../util/API";
// import GreenCheckmark from "./../../assets/images/svgs/GreenCheckmark.svg";
// import * as Cellular from "expo-cellular";
// const RegisterNumberScreen = () => {
//   const [countryCode, setCountryCode] = useState();
//   const getCountryCode = async () => {
//     let k = await Cellular.getIsoCountryCodeAsync();
//     if (k === "in") {
//       setCountryCode("91");
//       handleInput("country_code")("91");
//     } else if (k === "pk") {
//       setCountryCode("92");
//       handleInput("country_code")("92");
//     } else {
//       setCountryCode("1");
//       handleInput("country_code")("1");
//     }
//   };
//   const keyboardStatus = useGetKeyboardStatus();
//   const state = {
//     country_code: "+",
//     phone: "",
//     password: "",
//     username: "",
//     image: "",
//     first_name: "",
//     last_name: "",
//     refferal_code: "",
//   };

//   const form = useSelector((state) => state.form);
//   const dispatch = useDispatch();
//   const navigation = useNavigation();
//   useEffect(() => {
//     getCountryCode();
//     dispatch(initialiseForm());
//     dispatch(storeForm({ ...state, error: null }));
//   }, []);

//   const handleInput = (name) => (val) => {
//     setNextPressed();
//     dispatch(storeForm({ [name]: val, error: null }));
//   };

//   const { data: username } = useCheckUserExists("username", form?.username);
//   const { data: phone } = useCheckUserExists(
//     "phone",
//     `+${form.country_code}${form?.phone}`
//   );

//   const [nextPressed, setNextPressed] = useState();

//   return (
//     <ScrollView
//       showsVerticalScrollIndicator={false}
//       keyboardShouldPersistTaps="handled"
//     >
//       <VStack
//         pb={
//           keyboardStatus === "Keyboard Hidden" || keyboardStatus === undefined
//             ? 0
//             : 96
//         }
//         alignItems={"center"}
//         px={{
//           md: "10px",
//           lg: "28px",
//           xl: "28px",
//         }}
//         pt={12}
//         space={"149px"}
//       >
//         <VStack w="full" space={"30px"}>
//           <VStack space={"10px"} w="full">
//             <Text
//               color={"#94A3B8"}
//               fontSize={"16px"}
//               letterSpacing={"sm"}
//               style={{
//                 fontFamily: "HK_500Medium",
//               }}
//             >
//               What's your number?
//             </Text>
//             <HStack w="full" space={3}>
//               <HStack
//                 justifyContent={"center"}
//                 alignItems="center"
//                 borderRadius={"8px"}
//                 borderWidth={"1px"}
//                 borderColor={"#58595B"}
//                 h="52px"
//                 w="62px"
//                 pl="2"
//               >
//                 <Text
//                   color={"#fff"}
//                   fontSize="16px"
//                   textAlign={"center"}
//                   style={{
//                     fontFamily: "HK_700Bold",
//                   }}
//                 >
//                   +{countryCode}
//                 </Text>
//               </HStack>

//               <TextInput
//                 onChangeText={(val) => handleInput("phone")(val)}
//                 editable
//                 maxLength={10}
//                 keyboardType="number-pad"
//                 style={{
//                   borderColor: "#58595B",
//                   borderWidth: 1,
//                   borderRadius: 8,
//                   height: 52,
//                   flex: 1,
//                   textAlign: "center",
//                   color: "white",
//                   fontSize: 16,
//                   fontFamily: "HK_700Bold",
//                 }}
//               />
//             </HStack>
//             {(/\s/g.test(form?.phone) || /\s/g.test(form?.country_code)) && (
//               <Text
//                 fontSize={14}
//                 style={{
//                   fontFamily: "HK_400Regular",
//                 }}
//                 color="#ff0000"
//               >
//                 Phone number can't contain spaces.
//               </Text>
//             )}

//             {phone?.status === 400 && (
//               <Text
//                 fontSize={14}
//                 style={{
//                   fontFamily: "HK_400Regular",
//                 }}
//                 color="#ff0000"
//               >
//                 {phone?.message}.
//               </Text>
//             )}
//           </VStack>
//           <VStack space={"10px"} w="full">
//             <Text
//               color={"#94A3B8"}
//               fontSize={"16px"}
//               letterSpacing={"sm"}
//               style={{
//                 fontFamily: "HK_500Medium",
//               }}
//             >
//               Username
//             </Text>
//             <Input
//               rightElement={
//                 username?.status === 200 &&
//                 form?.username && (
//                   <Center px={3}>
//                     <GreenCheckmark />
//                   </Center>
//                 )
//               }
//               onChangeText={(val) => handleInput("username")(val)}
//               fontSize={"16px"}
//               lineHeight={"21px"}
//               autoCapitalize="none"
//               style={{
//                 fontFamily: "HK_700Bold",
//               }}
//               placeholderTextColor={"white"}
//               borderWidth={"1px"}
//               borderColor={"#58595B"}
//               p={"16px"}
//               rounded={"8px"}
//               color="white"
//               _focus={{
//                 borderColor: "#58595B",
//                 backgroundColor: "black",
//                 color: "white",
//               }}
//             ></Input>
//             {/\s/g.test(form?.username) && (
//               <Text
//                 fontSize={14}
//                 style={{
//                   fontFamily: "HK_400Regular",
//                 }}
//                 color="#ff0000"
//               >
//                 Username can't contain spaces.
//               </Text>
//             )}
//             {username?.status === 400 && (
//               <Text
//                 fontSize={14}
//                 style={{
//                   fontFamily: "HK_400Regular",
//                 }}
//                 color="#ff0000"
//               >
//                 {username?.message}.
//               </Text>
//             )}
//             {!(form?.phone || form?.country_code || form?.username) &&
//               nextPressed && (
//                 <Text
//                   fontSize={14}
//                   style={{
//                     fontFamily: "HK_400Regular",
//                   }}
//                   color="#ff0000"
//                 >
//                   Fill Credentials.
//                 </Text>
//               )}
//           </VStack>
//           <VStack space={"10px"} w="full">
//             <Text
//               color={"#94A3B8"}
//               fontSize={"16px"}
//               letterSpacing={"sm"}
//               style={{
//                 fontFamily: "HK_500Medium",
//               }}
//             >
//               Referral Code (Optional)
//             </Text>
//             <Input
//               onChangeText={async (val) => handleInput("refferal_code")(val)}
//               fontSize={"16px"}
//               lineHeight={"21px"}
//               autoCapitalize="none"
//               style={{
//                 fontFamily: "HK_700Bold",
//               }}
//               placeholderTextColor={"white"}
//               borderWidth={"1px"}
//               borderColor={"#58595B"}
//               p={"16px"}
//               rounded={"8px"}
//               color="white"
//               _focus={{
//                 borderColor: "#58595B",
//                 backgroundColor: "black",
//                 color: "white",
//               }}
//             ></Input>
//           </VStack>
//         </VStack>
//         <Pressable
//           onPress={async () => {
//             Keyboard.dismiss();
//             setNextPressed(true);
//             if (
//               form?.username &&
//               form?.phone &&
//               form?.country_code &&
//               !/\s/g.test(form?.username) &&
//               !/\s/g.test(form?.phone) &&
//               username?.status === 200 &&
//               phone.status === 200
//             ) {
//               dispatch(showLoader());

//               try {
//                 let res = await publicApi(
//                   "/api/users/send_login_otp/",
//                   {
//                     phone: `+${form.country_code}${form?.phone}`,
//                     login: false,
//                   },
//                   "post"
//                 );
//                 if (res.body.status === 200) {
//                   navigation.navigate(Pages.VerifyScreen, {
//                     type: "phone",
//                   });
//                 }
//               } catch (error) {}
//               dispatch(hideLoader());
//             }
//           }}
//         >
//           <Box
//             alignItems="center"
//             flexDirection="row"
//             justifyContent="center"
//             w="173.35px"
//             h="50px"
//             alignSelf={"center"}
//             bg={{
//               linearGradient: {
//                 colors: [
//                   "primary.ctaLinearFirstColor",
//                   "primary.ctaLinearSecondColor",
//                 ],
//                 start: [0, 0],
//                 end: [1, 0],
//               },
//             }}
//             rounded="10px"
//             _text={{
//               color: "#ffffff",
//             }}
//           >
//             <Text
//               color="white"
//               style={{
//                 fontFamily: "HK_600SemiBold",
//               }}
//               fontSize="14px"
//             >
//               Next
//             </Text>
//           </Box>
//         </Pressable>
//       </VStack>
//     </ScrollView>
//   );
// };

// export default RegisterNumberScreen;

import { ScrollView } from "native-base";
import SignupHOC from "../../hoc/SignupHOC";
import PhoneSignupForm from "../../components/forms/phoneSignupForm.native";
const RegisterNumberScreen = () => {
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps="handled"
    >
      <SignupHOC>
        <PhoneSignupForm />
      </SignupHOC>
    </ScrollView>
  );
};

export default RegisterNumberScreen;
