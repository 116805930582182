import { useEffect, useState } from "react";

export const useTimer = (time) => {
  var now = Date.now();

  function secondsToString(seconds) {
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var days = Math.floor(numhours / 24);
    numhours = numhours - days * 24;
    numminutes = Math.abs(numminutes - days * 1440);

    return isNaN(numhours) || isNaN(numminutes)
      ? `-- : -- : --`
      : days +
          `d:` +
          numhours +
          "h" +
          ":" +
          String(numminutes).padStart(2, "0") +
          "m";
  }

  const [timer, setTimer] = useState();
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    now = Date.now() / 1000;
    setTimer(secondsToString(86400 + time - now));
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 60000);
    return () => clearInterval(interval);
  }, [seconds]);
  return timer;
};
