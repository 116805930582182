import { HStack, Popover, Pressable, Center, Text, Button } from "native-base";
import GradientLinkIcon from "../icons/gradientLinkIcon";
import * as Clipboard from "expo-clipboard";
import { setAnalytics } from "../../analytics";
const CopyLinkPopover = ({ link, copyStatus, setCopyStatus }) => {
  return (
    <>
      <Popover
        placement="bottom"
        trigger={(triggerProps) => {
          return (
            <Pressable {...triggerProps}>
              <Center h="full">
                <GradientLinkIcon />
              </Center>
            </Pressable>
          );
        }}
      >
        <Popover.Content borderWidth={"0"} left={"40%"}>
          <Popover.Arrow bg="#D9D9D9" borderColor={"#D9D9D9"} ml={2} />
          <HStack
            _dark={{
              bg: "#2B2F33",
            }}
            _light={{
              bg: "#D9D9D9",
              borderColor: "#D9D9D9",
              borderWidth: "1px",
            }}
            alignItems="center"
            space={2}
            p={2}
            rounded="8px"
            style={{
              zIndex: 4,
            }}
          >
            <Center h="30px" rounded="4px" bg="white" py={3} px={2} mt={1}>
              <Text
                color={"#434343"}
                fontSize="14px"
                style={{
                  fontFamily: "Hero_400Regular",
                }}
              >
                {link}
              </Text>
            </Center>
            <Button
              _hover={{
                bg: "#E9B9FF",
              }}
              onPress={async () => {
                let p = await Clipboard.setStringAsync(link);
                if (p) {
                  setCopyStatus(p);
                }
                setAnalytics(
                  "Copied link",
                  "Copied Link For Sharing Product From Product Screen",
                  {
                    link: link,
                    copyStatus: p,
                  }
                );
              }}
              bg="#E9B9FF"
              h="30px"
              w="81px"
              _text={{
                fontFamily: "Hero_400Regular",
                fontSize: "14px",
                color: "#682481",
              }}
            >
              {copyStatus ? "Copied!" : "Copy"}
            </Button>
          </HStack>
        </Popover.Content>
      </Popover>
    </>
  );
};

export default CopyLinkPopover;
