import axios from "axios";
import useSWR from "swr";
import { useSelector } from "react-redux";
import Constants from "expo-constants";
import { getHeaders } from "../util/utilFunctions";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const fetcher = async ([url, userId, promoCode]) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
      params: {
        user_id: userId,
        promocode: promoCode,
        v2: true,
      },
    })
    .then((res) => {
      return res.data.data;
    });

export default function useCheckPromoCodeClaimed(promoCode) {
  const userId = useSelector((state) => state.user.id);
  const { data, isValidating } = useSWR(
    [
      userId && promoCode ? `${apiUrl}/api/home/claim_promocode/` : null,

      userId,
      promoCode,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      },
    ],
    fetcher
  );
  return { data, isValidating };
}
