import { Image } from "native-base";

const DailyLoginIcon = () => {
  return (
    <>
      <Image
        resizeMethod="scale"
        resizeMode="contain"
        source={require("./../../assets/images/pngs/badgeIcon.png")}
        alt=""
      />
    </>
  );
};

export default DailyLoginIcon;
