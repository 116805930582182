import { Icon } from "native-base";
import {
  Path,
  G,
  ClipPath,
  Rect,
  Defs,
  LinearGradient,
  Stop,
} from "react-native-svg";

const AffiliateIcon = () => {
  return (
    <Icon size={5} viewBox="0 0 25 25">
      <G clip-path="url(#clip0_6516_124570)">
        <Path
          d="M11.5 8.35156C11.5 10.5616 9.71 12.3516 7.5 12.3516C5.29 12.3516 3.5 10.5616 3.5 8.35156C3.5 6.14156 5.29 4.35156 7.5 4.35156C9.71 4.35156 11.5 6.14156 11.5 8.35156ZM11.5 15.0716V20.3516H0.5V18.3516C0.5 16.1416 3.63 14.3516 7.5 14.3516C9 14.3516 10.37 14.6216 11.5 15.0716ZM24.5 20.3516H13.5V3.35156H24.5V20.3516ZM16.5 11.8516C16.5 11.1885 16.7634 10.5526 17.2322 10.0838C17.7011 9.61495 18.337 9.35156 19 9.35156C19.663 9.35156 20.2989 9.61495 20.7678 10.0838C21.2366 10.5526 21.5 11.1885 21.5 11.8516C21.5 12.5146 21.2366 13.1505 20.7678 13.6193C20.2989 14.0882 19.663 14.3516 19 14.3516C18.337 14.3516 17.7011 14.0882 17.2322 13.6193C16.7634 13.1505 16.5 12.5146 16.5 11.8516ZM22.5 7.35156C21.9696 7.35156 21.4609 7.14085 21.0858 6.76578C20.7107 6.3907 20.5 5.882 20.5 5.35156H17.5C17.5 6.46156 16.61 7.35156 15.5 7.35156V16.3516C16.0304 16.3516 16.5391 16.5623 16.9142 16.9373C17.2893 17.3124 17.5 17.8211 17.5 18.3516H20.5C20.5 17.2516 21.4 16.3516 22.5 16.3516V7.35156Z"
          fill="url(#paint0_linear_6516_124570)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_6516_124570"
          x1="0.5"
          y1="3.35156"
          x2="26.7113"
          y2="8.07148"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stop-color="#86D24A" />
          <Stop offset="1" stop-color="#0EA765" />
        </LinearGradient>
        <ClipPath id="clip0_6516_124570">
          <Rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5 0.351562)"
          />
        </ClipPath>
      </Defs>
    </Icon>
  );
};

export default AffiliateIcon;
