import { Center, HStack, Pressable, Text } from "native-base";
import React from "react";
import CheckboxWhiteTickIcon from "../../../icons/checkboxWhiteTickIcon";
const CheckBox = ({ label, onPress, checked }) => {
  return (
    <Pressable onPress={onPress}>
      <HStack space={"10px"} alignItems="center">
        <Center
          p={"5px"}
          rounded={"4px"}
          borderWidth={"1px"}
          bg={checked ? "purple.700" : "#2B2F33"}
          borderColor="#58595B"
          size={"20px"}
        >
          {checked && <CheckboxWhiteTickIcon />}
        </Center>
        <Text
          fontSize={"16px"}
          style={{
            fontFamily: "HK_600SemiBold",
          }}
          color={checked ? "white" : "#94A3B8"}
        >
          {label}
        </Text>
      </HStack>
    </Pressable>
  );
};

export default React.memo(CheckBox);
