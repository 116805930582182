import {
  Center,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  PresenceTransition,
  Pressable,
  Slider,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import {
  closeDialog,
  goBackDialog,
  hideLoader,
  showLoader,
} from "../../redux/currentState/actions";
import * as RootNavigation from "./../../RootNavigation";
import { useState } from "react";

import CloseIcon from "../icons/closeIcon";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import useGetRewards from "../../hooks/useGetRewards";
import { setAnalytics } from "../../analytics";
import { privateApi } from "../../util/API";
import { useSWRConfig } from "swr";
import Constants from "expo-constants";
import AffiliateIcon from "../icons/affiliateIcon";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const RedeemEarningsModal = () => {
  const { data: rewards } = useGetRewards("affiliateRewards");
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();
  const openDialog = useSelector((state) => state.currentState.openDialog);
  const route = RootNavigation.navigationRef.getCurrentRoute();
  const userId = useSelector((state) => state.user.id);
  const [email, setEmail] = useState();
  const [onChangeValue, setOnChangeValue] = useState(
    rewards?.reward_stats?.redeamable_earnings
  );
  const borderColor = useColorModeValue("#FAF1FF", "#202427");
  const [redeemPercent, setRedeemPercent] = useState([
    {
      label: 25,
      pressed: false,
    },
    {
      label: 50,
      pressed: false,
    },
    {
      label: 100,
      pressed: false,
    },
  ]);

  const [checkboxes, setCheckBoxes] = useState([
    {
      label: "Redeem as FlockBucks (extra 15%)",
      pressed: false,
      preference: "point",
    },
    {
      label: "Redeem to a card",
      pressed: false,
      preference: "cash",
    },
  ]);
  const editUserDetails = useEditUserDetails();
  return (
    <Modal
      size="full"
      bg="black:alpha.80"
      isOpen={openDialog}
      onClose={() => dispatch(goBackDialog())}
    >
      <Modal.Content
        _light={{
          bg: "white",
        }}
        _dark={{
          bg: "#2B2F33",
        }}
        rounded={"20px"}
        width={{
          md: "300px",
          lg: "380px",
          xl: "400px",
        }}
      >
        <VStack py={6} space={6} px={4} w="full" alignItems={"center"}>
          <IconButton
            onPress={() => dispatch(closeDialog())}
            size={10}
            position={"absolute"}
            right={"4%"}
            _pressed={{
              bg: "black:alpha.90",
            }}
            icon={<CloseIcon size={8} />}
          />
          <VStack w="full" space={6}>
            <Text
              _light={{
                color: "#682481",
              }}
              _dark={{
                color: "white",
              }}
              _web={{
                style: {
                  fontFamily: "AustinCyr_400",
                },
              }}
              letterSpacing={4}
              textAlign={"center"}
              textTransform={"uppercase"}
              fontSize={"16px"}
              style={{
                fontFamily: "HK_700Bold",
              }}
              color="#ffffff"
            >
              REDeEM earnings
            </Text>
            <VStack space={3}>
              <Text
                _light={{
                  color: "#464646",
                }}
                _dark={{
                  color: "white",
                }}
                _web={{
                  style: {
                    fontFamily: "Hero_400Regular",
                  },
                }}
                textAlign={"center"}
                textTransform={"none"}
                fontSize={"14px"}
                style={{
                  fontFamily: "HK_500Medium",
                }}
                color="#ffffff"
              >
                Use FlockBucks for Additional Discounts
              </Text>
              <Pressable>
                <Center
                  w="100%"
                  height={"40px"}
                  borderRadius={10}
                  bg={{
                    linearGradient: false
                      ? {
                          colors: ["#202427", "#202427"],
                          start: [0, 0],
                          end: [1, 0],
                        }
                      : {
                          colors: [
                            "primary.ctaLinearSecondColor",
                            "primary.ctaLinearFirstColor",
                          ],
                          start: [0, 0],
                          end: [1, 0],
                        },
                  }}
                >
                  <HStack
                    w="99%"
                    alignItems="center"
                    justifyContent="space-between"
                    px={4}
                    height={"37px"}
                    borderRadius={10}
                    _light={{
                      bg: "#E9B9FF",
                    }}
                    _dark={{
                      bg: "#211C2C",
                    }}
                  >
                    <HStack space={2} alignItems="center">
                      <AffiliateIcon />
                      <Text
                        _light={{
                          color: "#464646",
                        }}
                        _dark={{
                          color: "white",
                        }}
                        _web={{
                          style: {
                            fontFamily: "Hero_700Bold",
                          },
                        }}
                        textTransform={"uppercase"}
                        color="white"
                        fontSize={12}
                        style={{
                          fontFamily: "HK_700Bold",
                        }}
                      >
                        Affiliate Earnings
                      </Text>
                    </HStack>
                    <HStack alignItems={"center"}>
                      <Text
                        _light={{
                          color: "#464646",
                        }}
                        _dark={{
                          color: "white",
                        }}
                        fontSize={12}
                        _web={{
                          style: {
                            fontFamily: "Hero_400Regular",
                          },
                        }}
                        style={{
                          fontFamily: "HK_400Regular",
                        }}
                      >
                        Balance:
                      </Text>
                      <Text
                        _light={{
                          color: "#464646",
                        }}
                        _dark={{
                          color: "white",
                        }}
                        _web={{
                          style: {
                            fontFamily: "Hero_700Bold",
                          },
                        }}
                        fontSize={16}
                        style={{
                          fontFamily: "HK_600SemiBold",
                        }}
                      >
                        {rewards?.reward_stats?.redeamable_earnings}
                      </Text>
                    </HStack>
                  </HStack>
                </Center>
              </Pressable>
              <HStack
                justifyContent={"space-around"}
                w="full"
                alignItems="center"
              >
                <Slider
                  minValue={0}
                  maxValue={rewards?.reward_stats?.redeamable_earnings}
                  defaultValue={0}
                  value={onChangeValue}
                  onChange={(v) => {
                    setOnChangeValue(Math.floor(v));
                    setRedeemPercent([
                      {
                        label: 25,
                        pressed: false,
                      },
                      {
                        label: 50,
                        pressed: false,
                      },
                      {
                        label: 100,
                        pressed: false,
                      },
                    ]);
                  }}
                  h="6px"
                  colorScheme="#8D49DE"
                  w="256px"
                >
                  <Slider.Track bg="#58595B">
                    <Slider.FilledTrack bg="#8D49DE" />
                  </Slider.Track>
                  <Slider.Thumb borderWidth="0" bg="transparent">
                    <Icon
                      as={
                        <Center
                          bg="white"
                          borderColor="#8D49DE"
                          borderWidth={"3px"}
                          rounded={"full"}
                        ></Center>
                      }
                      name="park"
                      color="white"
                      size="6"
                    />
                  </Slider.Thumb>
                </Slider>
                <Text
                  color={"white"}
                  fontSize={{
                    md: "16px",
                    lg: "22px",
                    xl: "22px",
                  }}
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                >
                  {isNaN(onChangeValue) ? 0 : onChangeValue}
                </Text>
              </HStack>
              <HStack w="full" justifyContent={"space-evenly"}>
                {redeemPercent.map((k, index) => (
                  <Pressable
                    onPress={() => {
                      let copy = [...redeemPercent];
                      copy.map((k) => {
                        if (k.pressed) {
                          return (k.pressed = false);
                        }
                      });
                      copy[index] = { ...copy[index], pressed: true };
                      setRedeemPercent(copy);
                      let redeemedPoints =
                        (k.label * rewards?.reward_stats?.redeamable_earnings) /
                        100;
                      setOnChangeValue(redeemedPoints);

                      setAnalytics(
                        "Discount Button",
                        `${k.label}% of ${rewards?.reward_stats?.redeamable_earnings} selected`,
                        {
                          screen: route?.name,
                          discountPercent: k.label,
                          usablePoints:
                            rewards?.reward_stats?.redeamable_earnings,
                          redeemedPoints: redeemedPoints,
                        }
                      );
                    }}
                  >
                    <Center
                      w="100px"
                      h="32px"
                      rounded={"8px"}
                      bg={{
                        linearGradient: k.pressed
                          ? {
                              colors: [
                                "primary.ctaLinearSecondColor",
                                "primary.ctaLinearFirstColor",
                              ],
                              start: [0, 0],
                              end: [1, 0],
                            }
                          : {
                              colors: [borderColor, borderColor],
                              start: [0, 0],
                              end: [1, 0],
                            },
                      }}
                    >
                      <Center
                        w="98px"
                        h="30px"
                        rounded={"8px"}
                        _light={{
                          bg: "#FAF1FF",
                        }}
                        _dark={{
                          bg: "#202427",
                        }}
                        alignItems="center"
                      >
                        <Text
                          _light={{
                            color: k?.pressed ? "#464646" : "#8F8F8F",
                          }}
                          _dark={{
                            color: k?.pressed ? "#fff" : "#94A3B8",
                          }}
                          _web={{
                            style: {
                              fontFamily: "Hero_700Bold",
                            },
                          }}
                          fontSize="14px"
                          style={{
                            fontFamily: "HK_400Regular",
                          }}
                        >
                          {k.label}%
                        </Text>
                      </Center>
                    </Center>
                  </Pressable>
                ))}
              </HStack>
              <VStack space={2}>
                {checkboxes?.map((k, index) => (
                  <HStack
                    borderWidth={"1px"}
                    justifyContent={"space-between"}
                    px={4}
                    _light={{
                      bg: "#FAF1FF",
                      borderColor: "#C1C9D2",
                    }}
                    _dark={{
                      bg: "#202427",
                      borderColor: "#58595B",
                    }}
                    w={"full"}
                    h="43px"
                    borderRadius={"10px"}
                    alignItems="center"
                  >
                    <HStack alignItems="center" space={1}>
                      <Checkbox
                        isChecked={k?.pressed}
                        onChange={() => {
                          let copy = [...checkboxes];

                          copy?.map((k, i) => {
                            if (k?.pressed) {
                              copy[i] = { ...copy[i], pressed: false };
                            }
                          });
                          copy[index] = { ...copy[index], pressed: !k.pressed };
                          setCheckBoxes(copy);
                        }}
                        _light={{
                          bg: "#FAF1FF",
                        }}
                        _dark={{
                          bg: "#202427",
                        }}
                        borderWidth={"1px"}
                        colorScheme="blue"
                      ></Checkbox>
                      <Text
                        _light={{
                          color: k?.pressed ? "#464646" : "#8F8F8F",
                        }}
                        _dark={{
                          color: k?.pressed ? "#fff" : "#94A3B8",
                        }}
                        fontSize="14px"
                        style={{
                          fontFamily: "HK_400Regular",
                        }}
                      >
                        {k?.label}
                      </Text>
                    </HStack>
                    <Text
                      _light={{
                        color: k?.pressed ? "#464646" : "#8F8F8F",
                      }}
                      _dark={{
                        color: k?.pressed ? "#fff" : "#94A3B8",
                      }}
                      fontSize="14px"
                      style={{
                        fontFamily: "HK_400Regular",
                      }}
                    >
                      {onChangeValue}
                    </Text>
                  </HStack>
                ))}
                {checkboxes?.find((k) => k?.pressed)?.preference === "cash" && (
                  <PresenceTransition
                    visible={
                      checkboxes?.find((k) => k?.pressed)?.preference === "cash"
                    }
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                      transition: {
                        duration: 500,
                      },
                    }}
                  >
                    <Input
                      h="43px"
                      onChangeText={(val) => setEmail(val)}
                      fontSize={"14px"}
                      autoCapitalize="none"
                      style={{
                        fontFamily: "HK_500Medium",
                      }}
                      placeholderTextColor={"#94A3B8"}
                      value={email}
                      borderWidth={"1px"}
                      borderColor={"#58595B"}
                      rounded={"8px"}
                      placeholder={"Enter id"}
                      bg="#202427"
                      color="white"
                      _focus={{
                        borderColor: "#58595B",
                        backgroundColor: "#202427",
                        color: "white",
                      }}
                    ></Input>
                  </PresenceTransition>
                )}
              </VStack>
            </VStack>
          </VStack>
          <Center pt={"40px"}>
            <Pressable
              onPress={async () => {
                editUserDetails({
                  reward_preference: checkboxes?.find((k) => k?.pressed)
                    ?.preference,
                });
                dispatch(showLoader());
                try {
                  let res = await privateApi(
                    "/api/points/redeem_affiliate_reward/",
                    checkboxes?.find((k) => k?.pressed)?.preference === "point"
                      ? {
                          user_id: userId,
                          reward_type: checkboxes?.find((k) => k?.pressed)
                            ?.preference,
                          reward_amount: onChangeValue,
                        }
                      : {
                          user_id: userId,
                          reward_type: checkboxes?.find((k) => k?.pressed)
                            ?.preference,
                          reward_amount: onChangeValue,
                          email: email,
                        },
                    "post"
                  );

                  setAnalytics(
                    "Redeem Earning Modal",
                    `Redeemed ${onChangeValue} in ${
                      checkboxes?.find((k) => k?.pressed)?.preference
                    }`,
                    {
                      status: res.status,
                      message: res.body.message,
                    }
                  );
                  dispatch(hideLoader());
                } catch (e) {}

                mutate(
                  `${apiUrl}/api/points/get_affiliate_earnings_page/?user_id=${userId}`
                );
                dispatch(closeDialog());
              }}
            >
              <Center
                h={"50px"}
                w={"200px"}
                borderRadius={"10px"}
                bg={{
                  linearGradient: true
                    ? {
                        colors: [
                          "primary.ctaLinearSecondColor",
                          "primary.ctaLinearFirstColor",
                        ],
                        start: [0, 0],
                        end: [1, 0],
                      }
                    : {
                        colors: ["#58595B", "#58595B"],
                        start: [0, 0],
                        end: [1, 0],
                      },
                }}
              >
                <Text
                  fontSize={14}
                  style={{
                    fontFamily: "HK_500Medium",
                  }}
                  color={"white"}
                >
                  Confirm
                </Text>
              </Center>
            </Pressable>
          </Center>
        </VStack>
      </Modal.Content>
    </Modal>
  );
};

export default RedeemEarningsModal;
