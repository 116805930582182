import { HStack, Text } from "native-base";

const DeliveredChip = () => {
  return (
    <HStack
      borderRadius="full"
      px={"12px"}
      py={"4px"}
      height="29px"
      width={"91px"}
      alignItems="center"
      bg="#D7FAE0"
      space={2}
      justifyContent="center"
    >
      <Text
        fontSize={14}
        style={{ fontFamily: "HK_600SemiBold" }}
        color="#007D3A"
      >
        Delivered
      </Text>
    </HStack>
  );
};

export default DeliveredChip;
