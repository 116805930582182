import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initialiseForm } from "../redux/form/actions";

export const useInitialiseForm = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initialiseForm());
  }, []);
};
