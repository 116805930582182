const { Image, useColorMode } = require("native-base");

const CurlyHairTextureIcon = () => {
  const { colorMode } = useColorMode();

  return (
    <Image
      width={62}
      height={70}
      resizeMethod="scale"
      resizeMode="contain"
      source={
        colorMode === "dark"
          ? require("./../../../../assets/images/pngs/grayCurlyHairTexture.png")
          : require("./../../../../assets/images/pngs/lightinactivecurlyhairtexture.png")
      }
      alt=""
    />
  );
};

export default CurlyHairTextureIcon;
