import { useState } from "react";
import { setAnalytics } from "../analytics";
import { manipulateAsync } from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import { storeForm } from "../redux/form/actions";
import { useDispatch } from "react-redux";
export const useUploadImage = () => {
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();

  const handleInput = (name) => (val) => {
    dispatch(storeForm({ [name]: val, error: null }));
  };

  const pickImage = async () => {
    setAnalytics("Upload Image Survey Screen", "Upload Image Clicked", {
      screen: "AboutYourSelfScreen",
    });
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      const manipResult = await manipulateAsync(
        result.assets[0].uri,
        [
          {
            resize: {
              width: 300,
            },
          },
        ],
        { compress: 1 }
      );

      setImage(result.assets[0].uri);

      handleInput("image")(manipResult);
    }
  };
  return [pickImage, image, setImage];
};
