import { Center, HStack, Image, Pressable, Text, VStack } from "native-base";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Pages } from "../../../constants/pages";
import { setAnalytics } from "../../../analytics";
import WishListIcon from "../../icons/wishListIcon";
import CartIcon from "../../icons/cartIcon";
import ShareIcon from "../../icons/shareIcon";
import { BrandImages } from "../../../constants/brands";

const TrendingProductCard = (props) => {
  const navigation = useNavigation();
  const route = useRoute();

  return (
    <>
      <Pressable
        onPress={() => {
          setAnalytics("Product Card", "Product Card Clicked", {
            productId: props?.productId,
            productSkuId: props?.productSkuId,
            productName: props?.productName,
            brandName: props?.brandName,
            screen: route?.name,
            bundleId: props?.bundleId,
            source: props?.source,
            otherUserId: props?.otherUserId,
          });

          navigation.navigate(Pages.ProductScreen, {
            id: props?.productId,
            sku_id: props?.productSkuId,
            bundle_id: props?.bundleId,
            source: props?.source,
            otherUserId: props?.otherUserId,
          });
        }}
      >
        <VStack bg="#202427" borderBottomRadius={"8px"}>
          <Center
            h={"150px"}
            w={{
              md: "180px",
              lg: "180px",
              xl: "190px",
            }}
          >
            <Image
              borderRadius={"10px"}
              size={"full"}
              resizeMethod="scale"
              bg="white"
              resizeMode="contain"
              source={{
                uri: props?.productImage,
              }}
              alt=""
            />
          </Center>

          <VStack
            h="145px"
            px={2}
            bg="#202427"
            w={{
              md: "180px",
              lg: "180px",
              xl: "190px",
            }}
            justifyContent="space-between"
          >
            <VStack space={2}>
              <HStack alignItems={"center"} space={2} pt={2}>
                <Image
                  bg="white"
                  borderRadius={"20px"}
                  size={"20px"}
                  resizeMethod="scale"
                  resizeMode="contain"
                  source={{
                    uri: BrandImages[props?.brandName],
                  }}
                  alt=""
                />
                <Text
                  noOfLines={2}
                  w={"80%"}
                  color={"#94A3B8"}
                  letterSpacing={2}
                  textTransform={"uppercase"}
                  fontSize={"10px"}
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                >
                  {props?.brandName}
                </Text>
              </HStack>
              <Text
                color={"white"}
                fontSize={"13px"}
                style={{
                  fontFamily: "HK_400Regular",
                }}
                noOfLines={3}
              >
                {props?.productName}
              </Text>
            </VStack>
            <HStack justifyContent="space-between">
              <WishListIcon
                productSkuId={props?.productSkuId}
                type="outlined"
              />
              <CartIcon
                source={props?.source}
                otherUserId={props?.otherUserId}
                productId={props?.productId}
                productSkuId={props?.productSkuId}
                type="cart"
                bundleId={props?.bundleId}
              />
              <ShareIcon
                type={"arrowShareIcon"}
                path={`product/${props?.productId}`}
                analytics={{
                  category: "Share Icon",
                  event: `Shared Product From Product Card`,
                  eventProperties: {
                    productId: props?.productId,
                    productSkuId: props?.productSkuId,
                    screen: route?.name,
                  },
                }}
              />
            </HStack>
          </VStack>
          <Center
            h="40px"
            borderBottomRadius={"8px"}
            bg={{
              linearGradient: {
                colors: [
                  "primary.ctaLinearSecondColor",
                  "primary.ctaLinearFirstColor",
                ],
                start: [0, 0],
                end: [1, 0],
              },
            }}
          >
            <Pressable
              h="40px"
              borderBottomRadius={"8px"}
              w={{
                md: "180px",
                lg: "180px",
                xl: "190px",
              }}
              mt={"3px"}
              bg="#2E303C"
              p={2}
            >
              <HStack alignItems={"center"}>
                <Text
                  color="white"
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                  fontSize={{
                    md: "14px",
                    lg: "14px",
                    xl: "14px",
                  }}
                >
                  ${props?.retailPrice?.toFixed(2)}
                </Text>
              </HStack>
            </Pressable>
          </Center>
        </VStack>
      </Pressable>
    </>
  );
};

export default TrendingProductCard;
