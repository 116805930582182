import { Box, Center, FlatList, useBreakpointValue } from "native-base";
import { useContext, useEffect, useState } from "react";
import { Dimensions } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { UsersContext } from "../../hoc/UsersHOC";
import { showLoader } from "../../redux/currentState/actions";
import BeautyBuddiesCard from "../cards/beautyBuddiesCard";

const BuddiesTwoColumnLayout = () => {
  const userId = useSelector((state) => state.user.id);
  const key = (item) => item?.id;
  const dispatch = useDispatch();
  const { data, setSize } = useContext(UsersContext);
  const [columnNumber, setColumnNumber] = useState(5);

  const breakpoint = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: "xl",
  });
  const map = {
    base: 2,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 5,
  };

  useEffect(() => {
    setColumnNumber(map[breakpoint]);
  }, [breakpoint, columnNumber]);
  const renderItem = ({ item }) => (
    <Center m={1.5}>
      {item === "extra" ? (
        <Box
          w={{
            base: "full",
            sm: "full",
            md: "full",
            lg: "full",
            xl: "200px",
          }}
        ></Box>
      ) : (
        <BeautyBuddiesCard
          cardWidth={{
            base: "full",
            sm: "full",
            md: "full",
            lg: "full",
            xl: "200px",
          }}
          imageWidth={{
            base: "full",
            sm: "full",
            md: "full",
            lg: "full",
            xl: "187px",
          }}
          item={item}
        />
      )}
    </Center>
  );
  return (
    <FlatList
      key={columnNumber}
      flex={1}
      keyExtractor={key}
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
      initialNumToRender={2}
      columnWrapperStyle={{
        justifyContent: "center",
      }}
      data={
        data &&
        data[0] &&
        Object.keys(data[0])?.length > 0 &&
        (data?.length % 2 !== 0
          ? data?.filter((k) => k?.id !== userId)?.concat(["extra"])
          : data?.filter((k) => k?.id !== userId))
      }
      _web={{
        numColumns: columnNumber,
      }}
      numColumns={2}
      renderItem={renderItem}
      onEndReached={() => {
        dispatch(showLoader());
        setSize();
      }}
    />
  );
};

export default BuddiesTwoColumnLayout;
