import { VStack, FlatList, View } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import SearchBox from "../filterPopUp/buildingBlocks/searchBox";
import CheckBox from "../filterPopUp/buildingBlocks/checkbox";
import { Ethnicities } from "../../../constants/ethinicity";
import { changeFilter } from "../../../redux/communityFilter/actions";

const Item = ({ item, selectedEthnicity, setSelectedEthnicity }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selected) {
      if (selectedEthnicity.includes(item)) {
        setSelected(true);
      }
    }
  }, [selectedEthnicity]);

  const onPress = () => {
    if (selectedEthnicity.includes(item)) {
      setSelected(false);
      const filtered = selectedEthnicity.filter((value) => value !== item);
      setSelectedEthnicity(filtered);
    } else {
      setSelected(true);
      setSelectedEthnicity([...selectedEthnicity, item]);
      // setSelectedEthnicity([322]);
    }
  };

  return (
    <CheckBox
      onPress={onPress}
      checked={selected}
      label={Ethnicities[item]}
      item={item}
    />
  );
};

const Ethnicity = ({ selectedEthnicity, setSelectedEthnicity }) => {
  const ethnicity = useSelector(
    (state) => state.communityFilter.cachedFilters.ethnicity
  );

  const [ethnicityResults, setEthnicityResults] = useState(
    Object.keys(Ethnicities)
  );
  const dispatch = useDispatch();

  const renderItem = ({ item }) => {
    return (
      <Item
        item={item}
        selectedEthnicity={selectedEthnicity}
        setSelectedEthnicity={setSelectedEthnicity}
      />
    );
  };

  return (
    <VStack width={"100%"} pb={8} px={4} space={6}>
      <SearchBox
        onChange={async (val) => {
          let hairTypes = Object.keys(Ethnicities);
          let hairTypeResult = hairTypes.filter((hairType) => {
            return Ethnicities[hairType]
              .toLowerCase()
              .includes(val.toLowerCase());
          });
          setEthnicityResults(hairTypeResult);
        }}
      />

      <FlatList
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 11 }}></View>
        )}
        contentContainerStyle={{
          paddingBottom: 12,
        }}
        data={ethnicityResults}
        initialNumToRender={12}
        renderItem={renderItem}
      />
    </VStack>
  );
};

export default Ethnicity;
