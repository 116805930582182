import { Icon } from "native-base";
import { Path, G, Defs, LinearGradient, Stop } from "react-native-svg";

const WebGradientActiveCartIcon = () => {
  return (
    <Icon viewBox="0 0 24 21">
      <G>
        <Path
          d="M7.19693 12.6667H14.2591C14.4369 12.6662 14.6104 12.615 14.7569 12.5198C14.9034 12.4247 15.0161 12.29 15.0801 12.1333L17.5783 6.00001H15.6892L13.65 11H7.78838L3.82475 2.02501C3.69036 1.7209 3.46349 1.46121 3.17281 1.27877C2.88213 1.09633 2.54068 0.999328 2.19162 1.00001H0.134766V2.66668H2.19162L6.38478 12.15C6.45101 12.3022 6.56347 12.4325 6.708 12.5244C6.85252 12.6164 7.02265 12.6659 7.19693 12.6667Z"
          fill="url(#paint0_linear_45_14708)"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.9338 0.752601C17.3444 1.11952 17.3813 1.75159 17.0164 2.16437L11.7116 8.16437C11.5268 8.37339 11.2634 8.49506 10.9852 8.49986C10.7071 8.50467 10.4397 8.39217 10.2479 8.18966L7.59549 5.38967C7.21664 4.98973 7.23199 4.35676 7.62979 3.97587C8.02758 3.59499 8.65717 3.61042 9.03602 4.01036L10.9429 6.02336L15.5296 0.835647C15.8945 0.422864 16.5232 0.385683 16.9338 0.752601Z"
          fill="url(#paint0_linear_45_14708)"
        />
        <Path
          d="M7.6506 16C8.38192 16 8.97476 15.4404 8.97476 14.75C8.97476 14.0597 8.38192 13.5 7.6506 13.5C6.91929 13.5 6.32645 14.0597 6.32645 14.75C6.32645 15.4404 6.91929 16 7.6506 16Z"
          fill="url(#paint0_linear_45_14708)"
        />
        <Path
          d="M13.83 16C14.5613 16 15.1541 15.4404 15.1541 14.75C15.1541 14.0597 14.5613 13.5 13.83 13.5C13.0987 13.5 12.5058 14.0597 12.5058 14.75C12.5058 15.4404 13.0987 16 13.83 16Z"
          fill="url(#paint0_linear_45_14708)"
        />
        <Defs>
          <LinearGradient
            id="paint0_linear_45_14708"
            x1="9.8623"
            y1="3.09924"
            x2="21.6094"
            y2="7.74558"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#691DE3" />
            <Stop offset="0.0001" stop-color="#691DE3" />
            <Stop offset="1" stop-color="#FF5A92" />
          </LinearGradient>
          <LinearGradient
            id="paint1_linear_45_14708"
            x1="0.606445"
            y1="0.902344"
            x2="26.2678"
            y2="15.2101"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#691DE3" />
            <Stop offset="0.0001" stop-color="#691DE3" />
            <Stop offset="1" stop-color="#FF5A92" />
          </LinearGradient>
          <LinearGradient
            id="paint2_linear_45_14708"
            x1="8.71045"
            y1="17.1785"
            x2="13.1282"
            y2="18.9236"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#691DE3" />
            <Stop offset="0.0001" stop-color="#691DE3" />
            <Stop offset="1" stop-color="#FF5A92" />
          </LinearGradient>
          <LinearGradient
            id="paint3_linear_45_14708"
            x1="16.6484"
            y1="17.6472"
            x2="21.0661"
            y2="19.3924"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#691DE3" />
            <Stop offset="0.0001" stop-color="#691DE3" />
            <Stop offset="1" stop-color="#FF5A92" />
          </LinearGradient>
        </Defs>
      </G>
    </Icon>
  );
};

export default WebGradientActiveCartIcon;
