import { VStack, Stack, Center, Hidden } from "native-base";
import { useContext } from "react";
import ProductCard from "../../components/cards/product/productCard";
import ColumnLayout from "../../components/layouts/columnLayout";
import { ProductsContext } from "../../hoc/ProductsHOC";
import FilterSection from "./filterSection";

const FilterAndProductsSection = () => {
  const { data, setSize } = useContext(ProductsContext);
  const renderItem = ({ item }) => {
    return (
      <Center m={1.5}>
        <ProductCard
          variantLength={
            [item?.size, item?.scent, item?.colors, item?.type]?.filter(
              (k) => k
            ).length
          }
          productImage={item && item?.images && item?.images[0]}
          retailPrice={item?.retail_price}
          productName={item?.name}
          brandName={item?.brand_name}
          productId={item?.id}
          productSkuId={item?.default_sku}
        />
      </Center>
    );
  };

  return (
    <>
      <VStack
        space={{
          xl: "80px",
        }}
      >
        <Stack
          px={{
            base: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: "70px",
            xxl: "120px",
          }}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
            xxl: "row",
          }}
          space={8}
          justifyContent="center"
        >
          <Hidden only={["base", "sm", "md", "lg"]}>
            <Center
              h="full"
              w={{
                base: "full",
                sm: "full",
                md: "full",
                lg: "full",
                xl: "30%",
                xxl: "30%",
              }}
            >
              <FilterSection />
            </Center>
          </Hidden>
          <Center
            h="full"
            w={{
              base: "full",
              sm: "full",
              md: "full",
              lg: "full",
              xl: "70%",
              xxl: "70%",
            }}
          >
            <ColumnLayout
              defaultNumberOfColumns={4}
              data={data}
              renderItem={renderItem}
              setSize={setSize}
            />
          </Center>
        </Stack>
      </VStack>
    </>
  );
};

export default FilterAndProductsSection;
