import { CloseIcon, HStack, IconButton } from "native-base";
import { TextInput } from "react-native";
import { setAnalytics } from "../../analytics";
import SearchIconOutlined from "../icons/searchIconOutlined";

const SearchTextBar = ({ search, setSearch, placeholder }) => {
  return (
    <>
      <HStack
        alignItems={"center"}
        h="50px"
        borderWidth={"2px"}
        borderColor={"#2B2F33"}
        rounded={"8px"}
        w="full"
        justifyContent={"center"}
      >
        <TextInput
          value={search}
          onChangeText={(val) => {
            setAnalytics("search bar", "Search Bar Clicked", {
              text: val,
            });
            setSearch(val);
          }}
          placeholderTextColor="#888990"
          placeholder={placeholder}
          selectionColor={"white"}
          style={{
            height: 50,
            width: "90%",

            paddingLeft: 10,
            color: "white",
            fontSize: 16,
            fontFamily: "HK_400Regular",
          }}
        />
        {search === "" || search === undefined ? (
          <SearchIconOutlined size={4} />
        ) : (
          <IconButton
            onPress={() => {
              setAnalytics("search bar", "Cleared Search Text", {
                text: search,
              });
              setSearch();
            }}
            size={5}
            _icon={{
              color: "#94A3B8",
            }}
            icon={<CloseIcon size={3} />}
          ></IconButton>
        )}
      </HStack>
    </>
  );
};

export default SearchTextBar;
