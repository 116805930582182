import { ScrollView, Text, VStack } from "native-base";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import { useSelector } from "react-redux";
import { Platform } from "react-native";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
const Terms = () => {
  const userId = useSelector((state) => state.user.id);

  return (
    <>
      {Platform.OS === "web" ? (
        <>
          <VStack space={8} pb={4} px={4} pt={2}>
            <Text
              _dark={{
                color: "white",
              }}
              _light={{
                color: "#464646",
              }}
              style={{
                fontFamily: "Hero_400Regular",
              }}
              fontSize={16}
              lineHeight={22}
            >
              {`Flock Terms and Conditions
Last updated: March 28, 2022
The website located at [flockshop.co] (the “Site”) and the Flock mobile application (“App”) are copyrighted works belonging to Flock Shopping LLC, its affiliates and other related companies (collectively, “Flock,” “us,” “our,” and/or “we”). The Site and App provide beauty, wellness, and skincare products (including cosmetics, haircare products, skincare products, vitamins, and supplements, health aids), reviews and ratings provided by Flock’s users (“you” and/or “your”), information regarding the description and ingredients of listed products, and related content (collectively, with all other services provided through the Site and App, the “Services”). Certain features of the Services may be subject to additional guidelines, terms, or rules, which will be posted on the Services in connection with such features. All such additional terms, guidelines, and rules are incorporated by reference into this Agreement. 
These terms and conditions (“agreement”) set forth the legally binding terms and conditions for your use of the services. By accessing or using the services, you are accepting this agreement and you represent and warrant that you have the right, authority, and capacity to enter into this agreement. You may not access or use the services or accept the agreement if you do not have the capacity to enter into this agreement. If you do not agree with all of the provisions of this agreement, do not access and/or use the services. If you are using the services on behalf of a company, entity, or organization, you represent and warrant that you are an authorized representative of such company, entity, or organization with the authority to bind it to this agreement.
PLEASE READ THIS DOCUMENT CAREFULLY, AS IT CONTAINS AN AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (A) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST FLOCK ON AN INDIVIDUAL BASIS, NOT AS A CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND (B) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS.
Your acceptance of the Agreement shall also be deemed to include your acceptance of the Privacy Policy available at Privacy Policy. 

1.IMPORTANT DISCLAIMERS.
THE STATEMENTS MADE ON THE SERVICES HAVE NOT BEEN EVALUATED BY THE FOOD AND DRUG ADMINISTRATION.  THESE PRODUCTS ARE NOT INTENDED TO DIAGNOSE, TREAT, CURE, OR PREVENT ANY DISEASE.  FLOCK DOES NOT OFFER MEDICAL ADVICE. ANY INFORMATION ACCESSED THROUGH THE SERVICES, OR WITHIN ANY OF FLOCK’S SOCIAL MEDIA PAGES OR CHANNELS IS FOR INFORMATIONAL AND EDUCATIONAL PURPOSES ONLY, IS NOT INTENDED TO BE A SUBSTITUTE FOR MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT, AND IS NOT INTENDED TO COVER ALL POSSIBLE USES, DIRECTIONS, PRECAUTIONS, OR ADVERSE EFFECTS. SUCH INFORMATION INCLUDES, WITHOUT LIMITATION, THIRD-PARTY MATERIALS, USER CONTENT, AND FLOCK-GENERATED CONTENT DERIVED FROM USER CONTENT. THE INFORMATION ON THE SERVICES AND PROVIDED VIA FLOCK’S SOCIAL MEDIA PAGES AND CHANNELS SHOULD NOT BE USED FOR THE DIAGNOSIS OR TREATMENT OF ANY MEDICAL CONDITION. ALWAYS CONSULT YOUR DOCTOR OR OTHER QUALIFIED HEALTH CARE PROVIDER IF YOU HAVE ANY QUESTIONS ABOUT A MEDICAL CONDITION. NEVER DISREGARD PROFESSIONAL OR MEDICAL ADVICE OR DELAY IN SEEKING IT BECAUSE OF SOMETHING YOU HAVE READ ON THE SERVICES OR ON FLOCK’S SOCIAL MEDIA PAGES AND CHANNELS.
THE FOREGOING DISCLAIMERS SHALL NOT LIMIT THE MORE GENERAL DISCLAIMERS IN SECTION 9 OR ELSEWHERE IN THIS AGREEMENT.

2.ELIGIBILITY AND ACCOUNTS.

2.1 Eligibility. You must be at least 16 years of age to use the Services within the United States. If you are under 16 years of age, please do not use or attempt to register to use the Services.

2.2 Account Creation. In order to use certain features of the Services, you must register for an account with Flock (“Flock Account”) and provide certain information about yourself as prompted by the registration form. You represent and warrant that: (a) all required registration information you submit is truthful and accurate; and (b) you will maintain the accuracy of such information at all times. You may also create a Flock Account by logging into your account with certain third-party sites, including, but not limited to, Facebook or Google (each such account, a "Third-Party Account"), as described below. As part of the functionality of the Services, you may link your Flock Account with Third-Party Accounts, by either: (a) providing your Third-Party Account login information to Flock through the Site or App; or (b) allowing Flock to access your Third-Party Account, as permitted under the applicable terms and conditions that govern your use of each Third-Party Account. By registering your Flock Account through a third-party site, you represent that you are entitled to disclose your Third-Party Account login information to Flock and/or grant Flock access to your Third-Party Account (including, but not limited to, for use for the purposes described herein), without breach by you of any of the terms and conditions that govern your use of the applicable Third-Party Account and without obligating Flock to pay any fees or making Flock subject to any usage limitations imposed by such third-party sites. You may not create more than one Flock Account. Flock may suspend or terminate your Flock Account in accordance with Sections 6.4 and 11.  

2.3 Third-Party Account Information. By granting Flock access to any Third-Party Accounts, you understand that Flock will access, make available and store (if applicable) certain content that you have provided to and stored in your Third-Party Account (“Third-Party Account Information”), as further detailed in our Privacy Policy, so that it is available on and through the Site or App via your Flock Account. Depending on the Third-Party Account you choose, and subject to the privacy settings that you have set in such Third-Party Accounts, personally identifiable information that you post to your Third-Party Accounts will be available on and through your Flock Account on the Services. Please note that, if a Third-Party Account or associated service becomes unavailable or Flock’s access to such Third-Party Account is terminated by the third-party site, then Third-Party Account Information will no longer be available on and through the Services.  You have the ability to disable the connection between your Flock account and your Third-Party Accounts, at any time.  Platform Flock makes no effort to review any Third-Party Account Information for any purpose, including but not limited to for accuracy, legality or non-infringement. Flock is not responsible for any Third-Party Account Information.  

2.4 Account Responsibilities. You must safeguard and not disclose your Flock Account username and password and supervise the use of your Flock Account. You agree to keep your Flock Account information up to date and accurate, including your shipping address. You are responsible for maintaining the confidentiality of your Flock Account login information and are fully responsible for all activities that occur under your Flock Account. You agree to immediately notify Flock upon discovery of any unauthorized use, or suspected unauthorized use, of your Flock Account or any other breach of security by e-mailing hi@flockshopping.com

2.5 Account Restrictions. Your access to the Services and your Flock Account is solely for your own personal use.  You shall not authorize or enable others to use your Flock Account.  You are solely responsible for all activities that occur under your Flock Account.  You shall not use the user name or password of any other user at any time, or attempt to impersonate another user, or any person who is not a user. Flock is not, cannot, and will not be liable for any loss or damage arising from your failure to comply with the above requirements.  

3. SITE AND MOBILE APP

3.1 Personal, Non-Commercial Limited License. Subject to the terms of this Agreement, Flock grants you a limited, revocable, non-transferable, non-exclusive license to use the Services for your personal, noncommercial use. Subject to the terms of this Agreement, Flock grants you a limited, revocable, non-transferable, non-exclusive license to install and use the App, in executable object code format only, solely on your own handheld mobile device and for your personal, noncommercial use. 

3.2 Certain Restrictions. The rights granted to you in this Agreement are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Services; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Services; (c) you shall not access the Services in order to build a similar or competitive service; (d) except as expressly stated herein, no part of the Services may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means; and (e) your use of the Services shall at all times comply with this Agreement and all applicable laws and regulations. Commercial use of any content on the Services or any goods purchased through the Services is strictly prohibited.  Any future release, update, or other addition to functionality of the Services shall be subject to the terms of this Agreement. All copyright and other proprietary notices on any Services content must be retained on all copies thereof.

3.3 Modification. Flock reserves the right, at any time, to modify, suspend, or discontinue the Services or any part thereof with or without notice. You agree that Flock will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Services or any part thereof.

3.4 Ownership. Excluding User Content, you acknowledge that all the intellectual property rights in the Services are owned by Flock. The provision of the Services does not transfer to you or any third party any rights, title, or interest in or to such intellectual property rights. Flock and its distributors reserve all rights not granted in this Agreement.

3.5 App Platforms. You acknowledge and agree that the availability of the App is dependent on the third-party app platform from which you received the App, e.g., the Apple App Store® or the Google Play® Store (“App Platform”). You acknowledge that this Agreement is between you and Flock and not with the App Platform. Flock, not the App Platform, is solely responsible for the App, the content thereof, maintenance, support services, and warranty therefor, and addressing any claims relating thereto (e.g., product liability, legal compliance, or intellectual property infringement). You agree to pay all fees charged by the App Platform in connection with your use of the App. Each App Platform may have its own terms and conditions to which you must agree before downloading the App from it. You agree to comply with, and your license to use the App is conditioned upon your compliance with, all applicable agreements, terms, and conditions of use/service, and other policies of the applicable App Platform. You acknowledge that the App Platform (and its subsidiaries) is a third-party beneficiary of this Agreement and will have the right to enforce this Agreement.  For additional terms and conditions if you access or download the App from the Apple App Store, go to Accessing and Downloading the Application from Apple® in Section 15.6 below.

4. COMMUNICATIONS

4.1 Push Notifications. When you install our App on your mobile device you agree to receive push notifications, which are messages a mobile application sends you on your mobile device even when the App is not on. You can turn off notifications by visiting your mobile device’s “settings” page.

4.2 Email and Opt-Out. You agree that we may send you emails concerning our products and services, as well as those of third parties. You may opt-out of promotional emails by following the unsubscribe instructions in a promotional email. If you opt-out of receiving all promotional messages from Flock, you may continue to receive promotional messages for a short period while Flock processes your request, and you may also receive communications from Flock confirming the receipt of your opt-out request. This opt out will not apply to operational or informational emails related to your account.  

5. USER CONTENT

5.1 User Content. “User Content” means any and all information and content that a user submits to, or uses with, the Services, including without limitation, content in the user’s profile, user reviews (e.g. photographs, videos) and/or postings. You acknowledge and agree that Flock is not responsible for any User Content, including its accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Flock does not assume and will not have any liability or responsibility to you or any other person or user for your use or misuse of any User Content. 

5.2 User Content Restrictions. You agree not to use the Services, or any of Flock’s social media pages or channels to collect, upload, transmit, display, or distribute any User Content that (a) violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (b) is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive (e.g., material that promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual) or otherwise objectionable material of any kind or nature; or (c) in violation of any law, regulation, or obligations or restrictions imposed by any third-party.

5.3 User Content Responsibilities. You are solely responsible for your User Content. You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that makes you or any third-party personally identifiable. You hereby represent and warrant that your User Content does not violate the Acceptable Use Policy (defined below). You may not state or imply that your User Content is in any way provided, sponsored, or endorsed by Flock. You acknowledge and agree that Flock is not responsible for any User Content, including its accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Flock does not assume and will not have any liability or responsibility to you or any other person or user for your use or misuse of any User Content. Because you alone are responsible for your User Content (and not Flock), you may expose yourself to liability if, for example, your User Content violates the Acceptable Use Policy or other applicable laws. Flock is not obligated to backup any User Content and you are solely responsible for creating backup copies of your User Content, if you desire.

5.4 Right to Remove Content.  You agree that Flock has the right to remove any content, including User Content, from the Services at any time, with or without cause and/or notice.  Posting and sharing content on the Services is a privilege and not a right.  Flock also has the right to refuse, move, or block access to any material submitted on or through the Services, and to establish general practices and limits concerning use of the Services.  The decision of whether or not to remove content from the Services is within Flock’s sole and complete discretion.  Flock has no obligations, contractual or otherwise, to take or refrain from taking any action.  Under no circumstances will Flock be liable for removing or failing to remove any User Content.

5.5 License. You hereby grant, and you represent and warrant that you have the right to grant, to Flock an irrevocable, nonexclusive, royalty-free and fully-paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use your User Content, and to grant sublicenses of the foregoing, solely for the purposes of including your User Content in the Services. You agree to irrevocably waive (and cause to be waived) any claims and assertions of moral rights or attribution with respect to your User Content.

5.6 Feedback. If you provide Flock any feedback or suggestions regarding the Services (“Feedback”), you hereby assign to Flock all rights in the Feedback and agree that Flock shall have the right to use such Feedback and related information in any manner it deems appropriate. Flock will treat any Feedback you provide to Flock as non-confidential and non-proprietary. You agree that you will not submit to Flock any information or ideas that you consider to be confidential or proprietary. 

5.7 Other Users.  Each user of the Services is solely responsible for any and all of its User Content. Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content and we make no guarantees regarding the accuracy, currency, suitability, or quality of any User Content and we assume no responsibility for any User Content. Your interactions with other users are solely between you and such user. You agree that Flock will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any other user, we are under no obligation to become involved.

6. ACCEPTABLE USE POLICY. The following sets forth Flock’s “Acceptable Use Policy”:

6.1 Reviews. You must have a valid Flock Account to leave a review on Flock. Prior to posting a review, you will need to create an account and finish Flock’s verification procedure. You agree not to post reviews on the Services, any of Flock’s social media pages, or other third-party channels that are not based upon your personal experience or are otherwise designed for any purpose other than providing other users on the Services with an accurate description of your personal experience. Reviews based on secondhand, non-personal experiences are not allowed. In addition to the other restrictions contained in the Acceptable Use Policy, reviews posted on the Services, any of Flock’s social media pages, or other third party channels must not: (a) be written exclusively in capital letters; (b) be plagiarized; (c) contain spam, advertisements, and/or links to external websites; (d) contain disparaging information about Flock, its employees, or any other person, (e) contain overly detailed or sexual descriptions of an individual’s physical appearance, or lewd personal attacks against a specific individual or group of individuals affiliated with Flock, (f) contain references to Flock or its competitors other than the product being reviewed, or (g) contain unrelated personal grievances. If you are an owner, volunteer, or employee of a competitor you may not post reviews about your own services or products.

6.2 Photos and Videos. Photos and videos (“media files”) must exclusively feature the user and/or the products the image files illustrate. Media files must not include messy or cluttered backgrounds, product wrapping, currency, paraphernalia, brand names other than that of the product being reviewed, or other any objects other than the product and the user. Media files must be clear and must not be blurry, fuzzy, or contain any flash reflections.  Media files must contain an accurate depiction of the product they illustrate and must be limited to the user. Image files cannot contain pornography or other graphic images and must otherwise abide by the guidelines set forth in this Section 6.

6.3 Technological Restrictions. In addition, you agree not to use the Services to: (a) upload, transmit, or distribute any computer viruses, worms, or any software intended to damage or alter a computer system or data; (b) send unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (c) harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent; (d) interfere with, disrupt, or create an undue burden on servers or networks connected to the Services or violate the regulations, policies or procedures of such networks; (e) attempt to or impersonate another user or Flock or gain unauthorized access to the Services, other computer systems or networks connected to or used together with the Services, through password mining or other means; (f) harass or interfere with another user’s use and enjoyment of the Services; or (g) introduce software or automated agents or scripts to the Services so as to produce multiple accounts, generate automated searches, requests and queries, or to strip, scrape, or mine data from the Services.

6.4 Monitoring, Suspension, and Termination. We reserve the right (but have no obligation) to review any User Content (including any media files or reviews), investigate, and/or take appropriate action against you in our sole discretion (including removing or modifying your User Content, terminating your Flock Account in accordance with Section 11, and/or reporting you to law enforcement authorities) if we in our sole discretion suspect that you have violated the Acceptable Use Policy or any other provision of this Agreement or otherwise create liability for us or any other person.

7. SALES TERMS

7.1 Terms of Sale and Shipping.  Unless otherwise stated or required by law, the following terms shall also apply to all goods purchased through the Services.  Flock will ship or deliver the goods purchased to the destination provided by you, as soon as reasonably practicable.  Flock reserves the right to fill orders on a pro rata basis if inventory shortages arise.  We also reserve the right to hold your shipment because of inclement weather or any other shipping related delays.  All delivery dates provided are best estimates based on prevailing conditions when given and Flock will not liable if it fails to meet any estimated delivery dates.  Risk of loss and title all goods passes to you upon our delivery to our contracted shipping agent.  Goods purchased from the Services are for your personal use and not subject to commercial resale.  We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer.

7.2 Returns and Refunds.  Items purchased through the Services can be returned within 30 days of order placement of shipment in most cases. Items should be unused or gently used. Some products have different policies or requirements associated with them, as may be disclosed at the point of sale.  Flock does not take title to returned items until the item arrives at our warehouse. Please allow 30 days for your return to be processed. You understand and agree that: (a) We have the authority to make the final decision on the refund amount and when it is generated; (b) refunds will happen through the same mode by which the payment was made, however, you may have the option to request for a change to credit/reward points, which will be approved at Flock’s sole discretion; and (c) if your refund doesn’t appear in your Flock Account, and the processing time for your refund method has passed, you may contact us for further assistance at hi@flockshopping.com. Flock monitors return activity for abuse and reserves the right to limit return or exchange at Flock in all instances. We may ask you for a driver’s license or government ID to verify your identity. 

7.3 Product Descriptions. Flock does not warrant that product descriptions are accurate, complete, reliable, current, or error-free. If a product offered through the Services is not as described, your sole remedy is to return it in unused condition for a refund. 

7.4 Pricing. We aim to ensure that prices provided on our Services are correct and up-to-date. From time to time, however, prices for certain products may not be current or may be inaccurate on account of technical issues, typographical errors or incorrect product information provided to us by a third party. In the event of such a case, notwithstanding anything to the contrary, we reserve the right to cancel the order without any further liability or notice to you at our sole discretion, which will be conclusive and binding. Subject to the foregoing, the price displayed at the time of ordering a product through our Services shall be the price charged at the time of delivery. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.

7.5 Sweepstakes and Promotions.  From time to time Flock may provide opportunities to participate in special promotions, contests, games and/or sweepstakes.  Terms for special promotions such as group deals shall be provided on the website.  Coupons and special promotions cannot be combined unless specifically authorized.  The rules, regulations and procedures governing any contests, games and/or sweepstakes shall be accessible through a hypertext link displayed on the web page where the contest or game may be located.  By entering or participating in any of them, you agree to be subject to those rules, regulations and procedures.  Please remember to read the rules carefully before participating.  

7.6 Loyalty Program. From time to time Flock may offer a loyalty program, where you can earn reward credits for participating in certain activities on the Site or App.  Reward credits can be applied toward future purchases from Flock.  Credits will be applied at checkout and can be redeemed for merchandise only.  Credits cannot be applied towards taxes, shipping, handling, or previous purchases.  Your loyalty points and rewards credits are registered to your personal account and may not be sold, transferred, assigned to, or otherwise shared with, family, friends, or others.  Redemption of rewards credits cannot be combined with any other coupons, credits, offers, or discounts, unless otherwise expressly indicated by Flock.  There are no participation or membership fees associated with the Program.  Reward credits accrued in connection with this program are promotional, have no cash value and cannot be redeemed for cash.  Flock reserves the right to change, modify, discontinue, or cancel the loyalty program or any part of thereof, at any time and in its sole discretion, without notice to you.  

8. INDEMNITY
You agree to indemnify and hold Flock (and its officers, employees, and agents) harmless from any and all losses, damages, liabilities, claims, actions, judgments, awards, penalties, fines, costs and/or expenses (including reasonably attorneys’ fees) arising from or relating to any claim or demand made by any third party due to or arising out of (a) your use or misuse of the Services, (b) your User Content, (c) your violation of this Agreement; or (d) your violation of applicable laws or regulations. Flock reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Flock. Flock will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.

9. DISCLAIMERS
THE SITE, APP, AND SERVICES ARE PROVIDED “AS-IS” AND “AS AVAILABLE” AND EXPRESSLY DISCLAIM, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT THE SITE OR SERVICES: (A) WILL MEET YOUR REQUIREMENTS; (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (C) WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE.
SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.

10. LIMITATION ON LIABILITY
TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL FLOCK BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT OR YOUR USE OF, OR INABILITY TO USE, THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICES ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, FLOCK’S LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO ONE HUNDRED US DOLLARS ($100). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.
SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.

11. TERM AND TERMINATION.
Subject to this Section, this Agreement will remain in full force and effect while you maintain a Flock Account and/or use the Services. We may (a) suspend your rights to use the Services (including your Flock Account) or (b) terminate this Agreement, at any time and for any reason whatsoever, at our sole and complete discretion. Upon termination of this Agreement, your Flock Account and right to access and use the Services will terminate immediately. You understand that any termination of your Flock Account involves deletion of your User Content associated therewith from our Services and databases. Flock will not have any liability whatsoever to you for any termination of this Agreement, including for termination of your Flock Account or deletion of your User Content. Sections 8, 9, 10 and 14 of this Agreement will survive termination.

12. FLOCK TRADEMARKS, COPYRIGHT AND PROPRIETARY RIGHTS
The Flock name and logo are trademarks of Flock.  All other company names, logos, and trademarks mentioned herein are the property of their respective owners.  Nothing herein shall be construed as granting, by implication, estoppel, or otherwise, any license or right to use the Flock or Flock name, logos, or trademarks without obtaining the written permission of Flock or such other third party owner, as applicable. 
All content on the Services, including but not limited to designs, data and databases, text, graphics, images, photographs, illustrations, audio and video material, artwork, proprietary information, client-side code (e.g. HTML, JavaScript, etc.) and server-side code (e.g. active server pages, VBScript, databases, etc.) and all copyrightable elements of the Services, and their selection and arrangement (excluding any of User Content) (the “Site Content”) individually and/or collectively, are the proprietary property of Flock and is protected by U.S. copyright law, international treaties and other intellectual property rights. 
Except as otherwise stated therein, Site Content may not be copied, transmitted, displayed, performed, distributed (for compensation or otherwise), licensed, altered, framed, stored for subsequent use or otherwise used in whole or in part in any manner without prior written consent, except to the extent permitted by the Copyright Act of 1976 (17 U.S.C. §107), as amended, and then, only with notices of Flock’s proprietary rights, provided however, that you may download the information and print out hard copies for your own personal, noncommercial use, so long as you do not remove any copyright or other notice as may be contained in the information as downloaded.  
You acknowledge that Flock will aggressively enforce its intellectual property rights to the fullest extent of the law, including, without limitation, the seeking of criminal prosecution.

13. COPYRIGHT POLICY.
Flock respects the intellectual property of others and asks that users of our Services do the same. In connection with our Services, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination, in appropriate circumstances, of users of our Services who are repeat infringers of intellectual property rights, including copyrights. If you believe that one of our users is, through the use of our Services, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright Agent:
Your physical or electronic signature;
Description of the copyrighted work(s) that you claim to have been infringed;
Description of the material on our services that you claim is infringing and that you request us to remove;
Sufficient information to permit us to locate such material;
Your address, telephone number, and e-mail address;
A statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and
A statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner. 
Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact (falsities) in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement.
The Designated Copyright Agent for Flock is:
Flock Shopping LLC. 651 N BROAD ST, STE 205 #7774 MIDDLETOWN, DE 19709 

14. ARBITRATION AGREEMENT AND JURY TRIAL WAIVER, CLASS ACTION WAIVER, AND FORUM SELECTION CLAUSE.

14.1 Arbitration Agreement and Waivers.  Except for disputes brought in small claims court, all disputes between you and Flock arising out of, relating to or in connection with the Services shall be exclusively settled through binding arbitration pursuant to the then-current rules of the American Arbitration Association (“AAA”) for consumer arbitration. There is no judge or jury in arbitration. The arbitration will be conducted by a single, neutral arbitrator. Arbitration procedures are simpler and more limited than rules applicable in court and review by a court is limited.  The arbitration will be conducted by telephone, online and/or be solely based on written submissions, the specific manner to be chosen by the party initiating the arbitration. The arbitration will not require any personal appearance by the parties or witnesses unless otherwise mutually agreed in writing by the parties.
YOU AND FLOCK AGREE THAT ANY SUCH ARBITRATION SHALL BE CONDUCTED ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION.
Notwithstanding any provision in this Agreement to the contrary, if the class-action waiver in the prior sentence is deemed invalid or unenforceable, however, neither you nor we are entitled to arbitration. This arbitration agreement is subject to the Federal Arbitration Act. The arbitrator’s award may be entered in any court of competent jurisdiction. Notwithstanding any provision in this Agreement to the contrary, we agree that if Flock makes any future change to this dispute resolution provision, it will not apply to any individual claim(s) that you had already provided notice of to Flock. Information on AAA and how to start arbitration can be found at www.adr.org or by calling 800-778-7879. 

14.2 Governing Law and Jurisdiction. If the arbitration in this Section provision is found unenforceable or to not apply for a given dispute, then the proceeding must be brought exclusively in a court of competent jurisdiction in Philadelphia, Pennsylvania. You hereby accept the exclusive jurisdiction of such court for this purpose. This Agreement is governed by the laws of the Commonwealth of Pennsylvania, without regard to conflict of law provisions. You and Flock agree that the United Nations Convention on Contracts for the International Sale of Goods will not apply.

14.3 Pre-Arbitration Dispute Resolution. We are always interested in resolving disputes amicably and efficiently, and most customer concerns can be resolved quickly and to the customer’s satisfaction by emailing customer support at hi@flockshopping.com. If such efforts prove unsuccessful, a party who intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute. Such notice should be sent to the address identified in Section 16 below (“Notice Address”).

14.4 Confidentiality. All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator, will be strictly confidential for the benefit of all parties. 
Future Changes to Arbitration Agreement. Notwithstanding any provision in this Agreement to the contrary, we agree that if it makes any future change to this Arbitration Agreement (other than a change to the Notice Address) while you are a user of the Service, you may reject any such change by sending us written notice within thirty (30) calendar days of the change to the Notice Address. By rejecting any future change, you are agreeing that you will arbitrate any dispute between us in accordance with the language of this Arbitration Agreement as of the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement). 

15. CONSENT TO RECEIVE NOTICES ELECTRONICALLY
You consent to receive electronically any agreements, notices, disclosures, and other communications (collectively, “Notices”) to which this Agreement refers including, without limitation, by e-mail, posting information and materials online at flockshop.co a push notice through the App, or other electronic communication technology that may hereafter be adopted or developed.  You agree that all Notices that we provide to you electronically satisfy any legal requirements that such communications be in writing.  Also, please note that your consent to receive notices is separate from any election that you make with respect to receipt of marketing communications, which is discussed under the Privacy Policy. 

16. GENERAL
16.1 Website Availability.  Flock will make reasonable efforts to keep the Services operational.  However, certain technical difficulties, routine site maintenance/upgrades and other events outside the control of Flock may, from time to time, result in temporary interruptions to the Services.  In addition, Flock reserves the right at any time and from time to time to modify or discontinue (on a temporary or permanent basis) certain functions of the Services or the entirety of the Services, with or without notice.  

16.2 No Support or Maintenance. You acknowledge and agree that Flock will have no obligation to provide you with any support or maintenance in connection with the Services. 

16.3 Changes to Terms of Use.  You agree that Flock may modify this Agreement at any time by adding new terms or by deleting or amending existing terms.  Such modifications will be effective as of the date that the updated Terms of Service are posted on the Site and/or App (“Effective Date”).  Your continued access and use of the Services after the Effective Date constitutes your acknowledgment of such modifications and your agreement to abide, and be bound, by the Agreement as modified.  If you do not accept such modification(s), then you must stop using the Services.

16.4 Links to Affiliate Websites.  The Services may contain links to websites controlled or offered by our parent, subsidiaries, related companies, and affiliates, which may contain terms of service that are different from this Agreement.  You should read the terms of services and privacy policies on each such website, as your accessing such website constitutes your agreement to be bound by the terms and conditions and privacy policies contained in such website.

16.5 Accessing and Downloading the Application from Apple. The following additional terms apply when you access or download the App through or from iTunes® or the App Store® (“App Store Sourced Mobile App”):
You acknowledge and agree that (a) this Agreement is concluded between you and Flock only, and not Apple, and (b) that Flock, not Apple, is solely responsible for the App Store Sourced Mobile App and content thereof. The license granted to you in the Agreement to use the App Store Sourced Mobile App and Services is a non-transferable right to use the App Store Sourced Mobile App on any Apple Device that you own or control, and only as permitted by the Usage Rules set forth in the App Store Terms of Service.
You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App Store Sourced Mobile App.
In the event of any failure of the App Store Sourced Mobile App to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the App Store Sourced Mobile App to you.  To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App Store Sourced Mobile App. As between Flock and Apple, any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Flock.
You and Flock acknowledge that, as between Flock and Apple, Apple is not responsible for addressing any claims you have or any claims of any third-party relating to the App Store Sourced Mobile App or your possession and use of the App Store Sourced Mobile App, including, but not limited to: (a) product liability claims; (b) any claim that the App Store Sourced Mobile App fails to conform to any applicable legal or regulatory requirement; and (c) claims arising under consumer protection or similar legislation.
You and Flock acknowledge that, in the event of any third-party claim that the App Store Sourced Mobile App or your possession and use of that App Store Sourced Mobile App infringes that third-party’s intellectual property rights, as between Flock and Apple, Flock, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by this Agreement.
You and Flock acknowledge and agree that Apple, and Apple’s subsidiaries, are third-party beneficiaries of the Agreement as related to your license of the App Store Sourced Mobile App, and that, upon your acceptance of the terms and conditions of the Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce the Agreement as related to your license of the App Store Sourced Mobile App against you as a third-party beneficiary thereof.
You represent and warrant that (a) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (b) you are not listed on any U.S. Government list of prohibited or restricted parties.
Without limiting any other terms of this Agreement, you must comply with all applicable third-party terms of agreement when using the App Store Sourced Mobile App.

16.6 Miscellaneous. This Agreement, and any documents incorporated herein by reference, constitutes the entire agreement between you and Flock regarding the use of the Services. Our failure to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision, nor shall any single or partial exercise by Flock of any right or power hereunder preclude further exercise of that or any other right hereunder. The section titles in this Agreement are for convenience only and have no legal or contractual effect. The word “including” means “including without limitation.” If any provision of this Agreement is, for any reason, held to be invalid or unenforceable, the other provisions of this Agreement will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. Nothing contained herein shall be construed to establish an employment, partnership, or joint venture relationship between you and Flock. This Agreement, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Flock’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. The terms of this Agreement shall be binding upon assignees.
FLOCK CONTACT INFORMATION: hi@flockshopping.com`}
            </Text>
          </VStack>
          <FooterSectionWeb />
        </>
      ) : (
        <>
          <ScrollView
            zIndex={0}
            width="100%"
            showsVerticalScrollIndicator={false}
          >
            <VStack space={8} pb={4} px={4} pt={2}>
              <Text
                style={{
                  fontFamily: "HK_500Medium",
                }}
                fontSize={16}
                lineHeight={22}
                color="white"
              >
                {`Flock Terms and Conditions
Last updated: March 28, 2022
The website located at [flockshop.co] (the “Site”) and the Flock mobile application (“App”) are copyrighted works belonging to Flock Shopping LLC, its affiliates and other related companies (collectively, “Flock,” “us,” “our,” and/or “we”). The Site and App provide beauty, wellness, and skincare products (including cosmetics, haircare products, skincare products, vitamins, and supplements, health aids), reviews and ratings provided by Flock’s users (“you” and/or “your”), information regarding the description and ingredients of listed products, and related content (collectively, with all other services provided through the Site and App, the “Services”). Certain features of the Services may be subject to additional guidelines, terms, or rules, which will be posted on the Services in connection with such features. All such additional terms, guidelines, and rules are incorporated by reference into this Agreement. 
These terms and conditions (“agreement”) set forth the legally binding terms and conditions for your use of the services. By accessing or using the services, you are accepting this agreement and you represent and warrant that you have the right, authority, and capacity to enter into this agreement. You may not access or use the services or accept the agreement if you do not have the capacity to enter into this agreement. If you do not agree with all of the provisions of this agreement, do not access and/or use the services. If you are using the services on behalf of a company, entity, or organization, you represent and warrant that you are an authorized representative of such company, entity, or organization with the authority to bind it to this agreement.
PLEASE READ THIS DOCUMENT CAREFULLY, AS IT CONTAINS AN AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (A) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST FLOCK ON AN INDIVIDUAL BASIS, NOT AS A CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND (B) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS.
Your acceptance of the Agreement shall also be deemed to include your acceptance of the Privacy Policy available at Privacy Policy. 

1.IMPORTANT DISCLAIMERS.
THE STATEMENTS MADE ON THE SERVICES HAVE NOT BEEN EVALUATED BY THE FOOD AND DRUG ADMINISTRATION.  THESE PRODUCTS ARE NOT INTENDED TO DIAGNOSE, TREAT, CURE, OR PREVENT ANY DISEASE.  FLOCK DOES NOT OFFER MEDICAL ADVICE. ANY INFORMATION ACCESSED THROUGH THE SERVICES, OR WITHIN ANY OF FLOCK’S SOCIAL MEDIA PAGES OR CHANNELS IS FOR INFORMATIONAL AND EDUCATIONAL PURPOSES ONLY, IS NOT INTENDED TO BE A SUBSTITUTE FOR MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT, AND IS NOT INTENDED TO COVER ALL POSSIBLE USES, DIRECTIONS, PRECAUTIONS, OR ADVERSE EFFECTS. SUCH INFORMATION INCLUDES, WITHOUT LIMITATION, THIRD-PARTY MATERIALS, USER CONTENT, AND FLOCK-GENERATED CONTENT DERIVED FROM USER CONTENT. THE INFORMATION ON THE SERVICES AND PROVIDED VIA FLOCK’S SOCIAL MEDIA PAGES AND CHANNELS SHOULD NOT BE USED FOR THE DIAGNOSIS OR TREATMENT OF ANY MEDICAL CONDITION. ALWAYS CONSULT YOUR DOCTOR OR OTHER QUALIFIED HEALTH CARE PROVIDER IF YOU HAVE ANY QUESTIONS ABOUT A MEDICAL CONDITION. NEVER DISREGARD PROFESSIONAL OR MEDICAL ADVICE OR DELAY IN SEEKING IT BECAUSE OF SOMETHING YOU HAVE READ ON THE SERVICES OR ON FLOCK’S SOCIAL MEDIA PAGES AND CHANNELS.
THE FOREGOING DISCLAIMERS SHALL NOT LIMIT THE MORE GENERAL DISCLAIMERS IN SECTION 9 OR ELSEWHERE IN THIS AGREEMENT.

2.ELIGIBILITY AND ACCOUNTS.

2.1 Eligibility. You must be at least 16 years of age to use the Services within the United States. If you are under 16 years of age, please do not use or attempt to register to use the Services.

2.2 Account Creation. In order to use certain features of the Services, you must register for an account with Flock (“Flock Account”) and provide certain information about yourself as prompted by the registration form. You represent and warrant that: (a) all required registration information you submit is truthful and accurate; and (b) you will maintain the accuracy of such information at all times. You may also create a Flock Account by logging into your account with certain third-party sites, including, but not limited to, Facebook or Google (each such account, a "Third-Party Account"), as described below. As part of the functionality of the Services, you may link your Flock Account with Third-Party Accounts, by either: (a) providing your Third-Party Account login information to Flock through the Site or App; or (b) allowing Flock to access your Third-Party Account, as permitted under the applicable terms and conditions that govern your use of each Third-Party Account. By registering your Flock Account through a third-party site, you represent that you are entitled to disclose your Third-Party Account login information to Flock and/or grant Flock access to your Third-Party Account (including, but not limited to, for use for the purposes described herein), without breach by you of any of the terms and conditions that govern your use of the applicable Third-Party Account and without obligating Flock to pay any fees or making Flock subject to any usage limitations imposed by such third-party sites. You may not create more than one Flock Account. Flock may suspend or terminate your Flock Account in accordance with Sections 6.4 and 11.  

2.3 Third-Party Account Information. By granting Flock access to any Third-Party Accounts, you understand that Flock will access, make available and store (if applicable) certain content that you have provided to and stored in your Third-Party Account (“Third-Party Account Information”), as further detailed in our Privacy Policy, so that it is available on and through the Site or App via your Flock Account. Depending on the Third-Party Account you choose, and subject to the privacy settings that you have set in such Third-Party Accounts, personally identifiable information that you post to your Third-Party Accounts will be available on and through your Flock Account on the Services. Please note that, if a Third-Party Account or associated service becomes unavailable or Flock’s access to such Third-Party Account is terminated by the third-party site, then Third-Party Account Information will no longer be available on and through the Services.  You have the ability to disable the connection between your Flock account and your Third-Party Accounts, at any time.  Platform Flock makes no effort to review any Third-Party Account Information for any purpose, including but not limited to for accuracy, legality or non-infringement. Flock is not responsible for any Third-Party Account Information.  

2.4 Account Responsibilities. You must safeguard and not disclose your Flock Account username and password and supervise the use of your Flock Account. You agree to keep your Flock Account information up to date and accurate, including your shipping address. You are responsible for maintaining the confidentiality of your Flock Account login information and are fully responsible for all activities that occur under your Flock Account. You agree to immediately notify Flock upon discovery of any unauthorized use, or suspected unauthorized use, of your Flock Account or any other breach of security by e-mailing hi@flockshopping.com

2.5 Account Restrictions. Your access to the Services and your Flock Account is solely for your own personal use.  You shall not authorize or enable others to use your Flock Account.  You are solely responsible for all activities that occur under your Flock Account.  You shall not use the user name or password of any other user at any time, or attempt to impersonate another user, or any person who is not a user. Flock is not, cannot, and will not be liable for any loss or damage arising from your failure to comply with the above requirements.  

3. SITE AND MOBILE APP

3.1 Personal, Non-Commercial Limited License. Subject to the terms of this Agreement, Flock grants you a limited, revocable, non-transferable, non-exclusive license to use the Services for your personal, noncommercial use. Subject to the terms of this Agreement, Flock grants you a limited, revocable, non-transferable, non-exclusive license to install and use the App, in executable object code format only, solely on your own handheld mobile device and for your personal, noncommercial use. 

3.2 Certain Restrictions. The rights granted to you in this Agreement are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Services; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Services; (c) you shall not access the Services in order to build a similar or competitive service; (d) except as expressly stated herein, no part of the Services may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means; and (e) your use of the Services shall at all times comply with this Agreement and all applicable laws and regulations. Commercial use of any content on the Services or any goods purchased through the Services is strictly prohibited.  Any future release, update, or other addition to functionality of the Services shall be subject to the terms of this Agreement. All copyright and other proprietary notices on any Services content must be retained on all copies thereof.

3.3 Modification. Flock reserves the right, at any time, to modify, suspend, or discontinue the Services or any part thereof with or without notice. You agree that Flock will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Services or any part thereof.

3.4 Ownership. Excluding User Content, you acknowledge that all the intellectual property rights in the Services are owned by Flock. The provision of the Services does not transfer to you or any third party any rights, title, or interest in or to such intellectual property rights. Flock and its distributors reserve all rights not granted in this Agreement.

3.5 App Platforms. You acknowledge and agree that the availability of the App is dependent on the third-party app platform from which you received the App, e.g., the Apple App Store® or the Google Play® Store (“App Platform”). You acknowledge that this Agreement is between you and Flock and not with the App Platform. Flock, not the App Platform, is solely responsible for the App, the content thereof, maintenance, support services, and warranty therefor, and addressing any claims relating thereto (e.g., product liability, legal compliance, or intellectual property infringement). You agree to pay all fees charged by the App Platform in connection with your use of the App. Each App Platform may have its own terms and conditions to which you must agree before downloading the App from it. You agree to comply with, and your license to use the App is conditioned upon your compliance with, all applicable agreements, terms, and conditions of use/service, and other policies of the applicable App Platform. You acknowledge that the App Platform (and its subsidiaries) is a third-party beneficiary of this Agreement and will have the right to enforce this Agreement.  For additional terms and conditions if you access or download the App from the Apple App Store, go to Accessing and Downloading the Application from Apple® in Section 15.6 below.

4. COMMUNICATIONS

4.1 Push Notifications. When you install our App on your mobile device you agree to receive push notifications, which are messages a mobile application sends you on your mobile device even when the App is not on. You can turn off notifications by visiting your mobile device’s “settings” page.

4.2 Email and Opt-Out. You agree that we may send you emails concerning our products and services, as well as those of third parties. You may opt-out of promotional emails by following the unsubscribe instructions in a promotional email. If you opt-out of receiving all promotional messages from Flock, you may continue to receive promotional messages for a short period while Flock processes your request, and you may also receive communications from Flock confirming the receipt of your opt-out request. This opt out will not apply to operational or informational emails related to your account.  

5. USER CONTENT

5.1 User Content. “User Content” means any and all information and content that a user submits to, or uses with, the Services, including without limitation, content in the user’s profile, user reviews (e.g. photographs, videos) and/or postings. You acknowledge and agree that Flock is not responsible for any User Content, including its accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Flock does not assume and will not have any liability or responsibility to you or any other person or user for your use or misuse of any User Content. 

5.2 User Content Restrictions. You agree not to use the Services, or any of Flock’s social media pages or channels to collect, upload, transmit, display, or distribute any User Content that (a) violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (b) is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive (e.g., material that promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual) or otherwise objectionable material of any kind or nature; or (c) in violation of any law, regulation, or obligations or restrictions imposed by any third-party.

5.3 User Content Responsibilities. You are solely responsible for your User Content. You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that makes you or any third-party personally identifiable. You hereby represent and warrant that your User Content does not violate the Acceptable Use Policy (defined below). You may not state or imply that your User Content is in any way provided, sponsored, or endorsed by Flock. You acknowledge and agree that Flock is not responsible for any User Content, including its accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Flock does not assume and will not have any liability or responsibility to you or any other person or user for your use or misuse of any User Content. Because you alone are responsible for your User Content (and not Flock), you may expose yourself to liability if, for example, your User Content violates the Acceptable Use Policy or other applicable laws. Flock is not obligated to backup any User Content and you are solely responsible for creating backup copies of your User Content, if you desire.

5.4 Right to Remove Content.  You agree that Flock has the right to remove any content, including User Content, from the Services at any time, with or without cause and/or notice.  Posting and sharing content on the Services is a privilege and not a right.  Flock also has the right to refuse, move, or block access to any material submitted on or through the Services, and to establish general practices and limits concerning use of the Services.  The decision of whether or not to remove content from the Services is within Flock’s sole and complete discretion.  Flock has no obligations, contractual or otherwise, to take or refrain from taking any action.  Under no circumstances will Flock be liable for removing or failing to remove any User Content.

5.5 License. You hereby grant, and you represent and warrant that you have the right to grant, to Flock an irrevocable, nonexclusive, royalty-free and fully-paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use your User Content, and to grant sublicenses of the foregoing, solely for the purposes of including your User Content in the Services. You agree to irrevocably waive (and cause to be waived) any claims and assertions of moral rights or attribution with respect to your User Content.

5.6 Feedback. If you provide Flock any feedback or suggestions regarding the Services (“Feedback”), you hereby assign to Flock all rights in the Feedback and agree that Flock shall have the right to use such Feedback and related information in any manner it deems appropriate. Flock will treat any Feedback you provide to Flock as non-confidential and non-proprietary. You agree that you will not submit to Flock any information or ideas that you consider to be confidential or proprietary. 

5.7 Other Users.  Each user of the Services is solely responsible for any and all of its User Content. Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content and we make no guarantees regarding the accuracy, currency, suitability, or quality of any User Content and we assume no responsibility for any User Content. Your interactions with other users are solely between you and such user. You agree that Flock will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any other user, we are under no obligation to become involved.

6. ACCEPTABLE USE POLICY. The following sets forth Flock’s “Acceptable Use Policy”:

6.1 Reviews. You must have a valid Flock Account to leave a review on Flock. Prior to posting a review, you will need to create an account and finish Flock’s verification procedure. You agree not to post reviews on the Services, any of Flock’s social media pages, or other third-party channels that are not based upon your personal experience or are otherwise designed for any purpose other than providing other users on the Services with an accurate description of your personal experience. Reviews based on secondhand, non-personal experiences are not allowed. In addition to the other restrictions contained in the Acceptable Use Policy, reviews posted on the Services, any of Flock’s social media pages, or other third party channels must not: (a) be written exclusively in capital letters; (b) be plagiarized; (c) contain spam, advertisements, and/or links to external websites; (d) contain disparaging information about Flock, its employees, or any other person, (e) contain overly detailed or sexual descriptions of an individual’s physical appearance, or lewd personal attacks against a specific individual or group of individuals affiliated with Flock, (f) contain references to Flock or its competitors other than the product being reviewed, or (g) contain unrelated personal grievances. If you are an owner, volunteer, or employee of a competitor you may not post reviews about your own services or products.

6.2 Photos and Videos. Photos and videos (“media files”) must exclusively feature the user and/or the products the image files illustrate. Media files must not include messy or cluttered backgrounds, product wrapping, currency, paraphernalia, brand names other than that of the product being reviewed, or other any objects other than the product and the user. Media files must be clear and must not be blurry, fuzzy, or contain any flash reflections.  Media files must contain an accurate depiction of the product they illustrate and must be limited to the user. Image files cannot contain pornography or other graphic images and must otherwise abide by the guidelines set forth in this Section 6.

6.3 Technological Restrictions. In addition, you agree not to use the Services to: (a) upload, transmit, or distribute any computer viruses, worms, or any software intended to damage or alter a computer system or data; (b) send unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (c) harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent; (d) interfere with, disrupt, or create an undue burden on servers or networks connected to the Services or violate the regulations, policies or procedures of such networks; (e) attempt to or impersonate another user or Flock or gain unauthorized access to the Services, other computer systems or networks connected to or used together with the Services, through password mining or other means; (f) harass or interfere with another user’s use and enjoyment of the Services; or (g) introduce software or automated agents or scripts to the Services so as to produce multiple accounts, generate automated searches, requests and queries, or to strip, scrape, or mine data from the Services.

6.4 Monitoring, Suspension, and Termination. We reserve the right (but have no obligation) to review any User Content (including any media files or reviews), investigate, and/or take appropriate action against you in our sole discretion (including removing or modifying your User Content, terminating your Flock Account in accordance with Section 11, and/or reporting you to law enforcement authorities) if we in our sole discretion suspect that you have violated the Acceptable Use Policy or any other provision of this Agreement or otherwise create liability for us or any other person.

7. SALES TERMS

7.1 Terms of Sale and Shipping.  Unless otherwise stated or required by law, the following terms shall also apply to all goods purchased through the Services.  Flock will ship or deliver the goods purchased to the destination provided by you, as soon as reasonably practicable.  Flock reserves the right to fill orders on a pro rata basis if inventory shortages arise.  We also reserve the right to hold your shipment because of inclement weather or any other shipping related delays.  All delivery dates provided are best estimates based on prevailing conditions when given and Flock will not liable if it fails to meet any estimated delivery dates.  Risk of loss and title all goods passes to you upon our delivery to our contracted shipping agent.  Goods purchased from the Services are for your personal use and not subject to commercial resale.  We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer.

7.2 Returns and Refunds.  Items purchased through the Services can be returned within 30 days of order placement of shipment in most cases. Items should be unused or gently used. Some products have different policies or requirements associated with them, as may be disclosed at the point of sale.  Flock does not take title to returned items until the item arrives at our warehouse. Please allow 30 days for your return to be processed. You understand and agree that: (a) We have the authority to make the final decision on the refund amount and when it is generated; (b) refunds will happen through the same mode by which the payment was made, however, you may have the option to request for a change to credit/reward points, which will be approved at Flock’s sole discretion; and (c) if your refund doesn’t appear in your Flock Account, and the processing time for your refund method has passed, you may contact us for further assistance at hi@flockshopping.com. Flock monitors return activity for abuse and reserves the right to limit return or exchange at Flock in all instances. We may ask you for a driver’s license or government ID to verify your identity. 

7.3 Product Descriptions. Flock does not warrant that product descriptions are accurate, complete, reliable, current, or error-free. If a product offered through the Services is not as described, your sole remedy is to return it in unused condition for a refund. 

7.4 Pricing. We aim to ensure that prices provided on our Services are correct and up-to-date. From time to time, however, prices for certain products may not be current or may be inaccurate on account of technical issues, typographical errors or incorrect product information provided to us by a third party. In the event of such a case, notwithstanding anything to the contrary, we reserve the right to cancel the order without any further liability or notice to you at our sole discretion, which will be conclusive and binding. Subject to the foregoing, the price displayed at the time of ordering a product through our Services shall be the price charged at the time of delivery. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.

7.5 Sweepstakes and Promotions.  From time to time Flock may provide opportunities to participate in special promotions, contests, games and/or sweepstakes.  Terms for special promotions such as group deals shall be provided on the website.  Coupons and special promotions cannot be combined unless specifically authorized.  The rules, regulations and procedures governing any contests, games and/or sweepstakes shall be accessible through a hypertext link displayed on the web page where the contest or game may be located.  By entering or participating in any of them, you agree to be subject to those rules, regulations and procedures.  Please remember to read the rules carefully before participating.  

7.6 Loyalty Program. From time to time Flock may offer a loyalty program, where you can earn reward credits for participating in certain activities on the Site or App.  Reward credits can be applied toward future purchases from Flock.  Credits will be applied at checkout and can be redeemed for merchandise only.  Credits cannot be applied towards taxes, shipping, handling, or previous purchases.  Your loyalty points and rewards credits are registered to your personal account and may not be sold, transferred, assigned to, or otherwise shared with, family, friends, or others.  Redemption of rewards credits cannot be combined with any other coupons, credits, offers, or discounts, unless otherwise expressly indicated by Flock.  There are no participation or membership fees associated with the Program.  Reward credits accrued in connection with this program are promotional, have no cash value and cannot be redeemed for cash.  Flock reserves the right to change, modify, discontinue, or cancel the loyalty program or any part of thereof, at any time and in its sole discretion, without notice to you.  

8. INDEMNITY
You agree to indemnify and hold Flock (and its officers, employees, and agents) harmless from any and all losses, damages, liabilities, claims, actions, judgments, awards, penalties, fines, costs and/or expenses (including reasonably attorneys’ fees) arising from or relating to any claim or demand made by any third party due to or arising out of (a) your use or misuse of the Services, (b) your User Content, (c) your violation of this Agreement; or (d) your violation of applicable laws or regulations. Flock reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Flock. Flock will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.

9. DISCLAIMERS
THE SITE, APP, AND SERVICES ARE PROVIDED “AS-IS” AND “AS AVAILABLE” AND EXPRESSLY DISCLAIM, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT THE SITE OR SERVICES: (A) WILL MEET YOUR REQUIREMENTS; (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (C) WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE.
SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.

10. LIMITATION ON LIABILITY
TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL FLOCK BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT OR YOUR USE OF, OR INABILITY TO USE, THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICES ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, FLOCK’S LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO ONE HUNDRED US DOLLARS ($100). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.
SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.

11. TERM AND TERMINATION.
Subject to this Section, this Agreement will remain in full force and effect while you maintain a Flock Account and/or use the Services. We may (a) suspend your rights to use the Services (including your Flock Account) or (b) terminate this Agreement, at any time and for any reason whatsoever, at our sole and complete discretion. Upon termination of this Agreement, your Flock Account and right to access and use the Services will terminate immediately. You understand that any termination of your Flock Account involves deletion of your User Content associated therewith from our Services and databases. Flock will not have any liability whatsoever to you for any termination of this Agreement, including for termination of your Flock Account or deletion of your User Content. Sections 8, 9, 10 and 14 of this Agreement will survive termination.

12. FLOCK TRADEMARKS, COPYRIGHT AND PROPRIETARY RIGHTS
The Flock name and logo are trademarks of Flock.  All other company names, logos, and trademarks mentioned herein are the property of their respective owners.  Nothing herein shall be construed as granting, by implication, estoppel, or otherwise, any license or right to use the Flock or Flock name, logos, or trademarks without obtaining the written permission of Flock or such other third party owner, as applicable. 
All content on the Services, including but not limited to designs, data and databases, text, graphics, images, photographs, illustrations, audio and video material, artwork, proprietary information, client-side code (e.g. HTML, JavaScript, etc.) and server-side code (e.g. active server pages, VBScript, databases, etc.) and all copyrightable elements of the Services, and their selection and arrangement (excluding any of User Content) (the “Site Content”) individually and/or collectively, are the proprietary property of Flock and is protected by U.S. copyright law, international treaties and other intellectual property rights. 
Except as otherwise stated therein, Site Content may not be copied, transmitted, displayed, performed, distributed (for compensation or otherwise), licensed, altered, framed, stored for subsequent use or otherwise used in whole or in part in any manner without prior written consent, except to the extent permitted by the Copyright Act of 1976 (17 U.S.C. §107), as amended, and then, only with notices of Flock’s proprietary rights, provided however, that you may download the information and print out hard copies for your own personal, noncommercial use, so long as you do not remove any copyright or other notice as may be contained in the information as downloaded.  
You acknowledge that Flock will aggressively enforce its intellectual property rights to the fullest extent of the law, including, without limitation, the seeking of criminal prosecution.

13. COPYRIGHT POLICY.
Flock respects the intellectual property of others and asks that users of our Services do the same. In connection with our Services, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination, in appropriate circumstances, of users of our Services who are repeat infringers of intellectual property rights, including copyrights. If you believe that one of our users is, through the use of our Services, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright Agent:
Your physical or electronic signature;
Description of the copyrighted work(s) that you claim to have been infringed;
Description of the material on our services that you claim is infringing and that you request us to remove;
Sufficient information to permit us to locate such material;
Your address, telephone number, and e-mail address;
A statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and
A statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner. 
Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact (falsities) in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement.
The Designated Copyright Agent for Flock is:
Flock Shopping LLC. 651 N BROAD ST, STE 205 #7774 MIDDLETOWN, DE 19709 

14. ARBITRATION AGREEMENT AND JURY TRIAL WAIVER, CLASS ACTION WAIVER, AND FORUM SELECTION CLAUSE.

14.1 Arbitration Agreement and Waivers.  Except for disputes brought in small claims court, all disputes between you and Flock arising out of, relating to or in connection with the Services shall be exclusively settled through binding arbitration pursuant to the then-current rules of the American Arbitration Association (“AAA”) for consumer arbitration. There is no judge or jury in arbitration. The arbitration will be conducted by a single, neutral arbitrator. Arbitration procedures are simpler and more limited than rules applicable in court and review by a court is limited.  The arbitration will be conducted by telephone, online and/or be solely based on written submissions, the specific manner to be chosen by the party initiating the arbitration. The arbitration will not require any personal appearance by the parties or witnesses unless otherwise mutually agreed in writing by the parties.
YOU AND FLOCK AGREE THAT ANY SUCH ARBITRATION SHALL BE CONDUCTED ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION.
Notwithstanding any provision in this Agreement to the contrary, if the class-action waiver in the prior sentence is deemed invalid or unenforceable, however, neither you nor we are entitled to arbitration. This arbitration agreement is subject to the Federal Arbitration Act. The arbitrator’s award may be entered in any court of competent jurisdiction. Notwithstanding any provision in this Agreement to the contrary, we agree that if Flock makes any future change to this dispute resolution provision, it will not apply to any individual claim(s) that you had already provided notice of to Flock. Information on AAA and how to start arbitration can be found at www.adr.org or by calling 800-778-7879. 

14.2 Governing Law and Jurisdiction. If the arbitration in this Section provision is found unenforceable or to not apply for a given dispute, then the proceeding must be brought exclusively in a court of competent jurisdiction in Philadelphia, Pennsylvania. You hereby accept the exclusive jurisdiction of such court for this purpose. This Agreement is governed by the laws of the Commonwealth of Pennsylvania, without regard to conflict of law provisions. You and Flock agree that the United Nations Convention on Contracts for the International Sale of Goods will not apply.

14.3 Pre-Arbitration Dispute Resolution. We are always interested in resolving disputes amicably and efficiently, and most customer concerns can be resolved quickly and to the customer’s satisfaction by emailing customer support at hi@flockshopping.com. If such efforts prove unsuccessful, a party who intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute. Such notice should be sent to the address identified in Section 16 below (“Notice Address”).

14.4 Confidentiality. All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator, will be strictly confidential for the benefit of all parties. 
Future Changes to Arbitration Agreement. Notwithstanding any provision in this Agreement to the contrary, we agree that if it makes any future change to this Arbitration Agreement (other than a change to the Notice Address) while you are a user of the Service, you may reject any such change by sending us written notice within thirty (30) calendar days of the change to the Notice Address. By rejecting any future change, you are agreeing that you will arbitrate any dispute between us in accordance with the language of this Arbitration Agreement as of the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement). 

15. CONSENT TO RECEIVE NOTICES ELECTRONICALLY
You consent to receive electronically any agreements, notices, disclosures, and other communications (collectively, “Notices”) to which this Agreement refers including, without limitation, by e-mail, posting information and materials online at flockshop.co a push notice through the App, or other electronic communication technology that may hereafter be adopted or developed.  You agree that all Notices that we provide to you electronically satisfy any legal requirements that such communications be in writing.  Also, please note that your consent to receive notices is separate from any election that you make with respect to receipt of marketing communications, which is discussed under the Privacy Policy. 

16. GENERAL
16.1 Website Availability.  Flock will make reasonable efforts to keep the Services operational.  However, certain technical difficulties, routine site maintenance/upgrades and other events outside the control of Flock may, from time to time, result in temporary interruptions to the Services.  In addition, Flock reserves the right at any time and from time to time to modify or discontinue (on a temporary or permanent basis) certain functions of the Services or the entirety of the Services, with or without notice.  

16.2 No Support or Maintenance. You acknowledge and agree that Flock will have no obligation to provide you with any support or maintenance in connection with the Services. 

16.3 Changes to Terms of Use.  You agree that Flock may modify this Agreement at any time by adding new terms or by deleting or amending existing terms.  Such modifications will be effective as of the date that the updated Terms of Service are posted on the Site and/or App (“Effective Date”).  Your continued access and use of the Services after the Effective Date constitutes your acknowledgment of such modifications and your agreement to abide, and be bound, by the Agreement as modified.  If you do not accept such modification(s), then you must stop using the Services.

16.4 Links to Affiliate Websites.  The Services may contain links to websites controlled or offered by our parent, subsidiaries, related companies, and affiliates, which may contain terms of service that are different from this Agreement.  You should read the terms of services and privacy policies on each such website, as your accessing such website constitutes your agreement to be bound by the terms and conditions and privacy policies contained in such website.

16.5 Accessing and Downloading the Application from Apple. The following additional terms apply when you access or download the App through or from iTunes® or the App Store® (“App Store Sourced Mobile App”):
You acknowledge and agree that (a) this Agreement is concluded between you and Flock only, and not Apple, and (b) that Flock, not Apple, is solely responsible for the App Store Sourced Mobile App and content thereof. The license granted to you in the Agreement to use the App Store Sourced Mobile App and Services is a non-transferable right to use the App Store Sourced Mobile App on any Apple Device that you own or control, and only as permitted by the Usage Rules set forth in the App Store Terms of Service.
You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App Store Sourced Mobile App.
In the event of any failure of the App Store Sourced Mobile App to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the App Store Sourced Mobile App to you.  To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App Store Sourced Mobile App. As between Flock and Apple, any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Flock.
You and Flock acknowledge that, as between Flock and Apple, Apple is not responsible for addressing any claims you have or any claims of any third-party relating to the App Store Sourced Mobile App or your possession and use of the App Store Sourced Mobile App, including, but not limited to: (a) product liability claims; (b) any claim that the App Store Sourced Mobile App fails to conform to any applicable legal or regulatory requirement; and (c) claims arising under consumer protection or similar legislation.
You and Flock acknowledge that, in the event of any third-party claim that the App Store Sourced Mobile App or your possession and use of that App Store Sourced Mobile App infringes that third-party’s intellectual property rights, as between Flock and Apple, Flock, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by this Agreement.
You and Flock acknowledge and agree that Apple, and Apple’s subsidiaries, are third-party beneficiaries of the Agreement as related to your license of the App Store Sourced Mobile App, and that, upon your acceptance of the terms and conditions of the Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce the Agreement as related to your license of the App Store Sourced Mobile App against you as a third-party beneficiary thereof.
You represent and warrant that (a) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (b) you are not listed on any U.S. Government list of prohibited or restricted parties.
Without limiting any other terms of this Agreement, you must comply with all applicable third-party terms of agreement when using the App Store Sourced Mobile App.

16.6 Miscellaneous. This Agreement, and any documents incorporated herein by reference, constitutes the entire agreement between you and Flock regarding the use of the Services. Our failure to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision, nor shall any single or partial exercise by Flock of any right or power hereunder preclude further exercise of that or any other right hereunder. The section titles in this Agreement are for convenience only and have no legal or contractual effect. The word “including” means “including without limitation.” If any provision of this Agreement is, for any reason, held to be invalid or unenforceable, the other provisions of this Agreement will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. Nothing contained herein shall be construed to establish an employment, partnership, or joint venture relationship between you and Flock. This Agreement, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Flock’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. The terms of this Agreement shall be binding upon assignees.
FLOCK CONTACT INFORMATION: hi@flockshopping.com`}
              </Text>
            </VStack>
          </ScrollView>
          {userId && <BottomBar />}
        </>
      )}
    </>
  );
};

export default Terms;
