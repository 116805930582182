import ClaimedButton from "../buttons/claimedButton";
import DailyLoginIcon from "../icons/dailyLoginIcon";
const { HStack, Text, VStack, Center } = require("native-base");
const ClaimedRewardsCard = ({ title, subTitle }) => {
  return (
    <HStack
      w="372px"
      py={"16px"}
      px={"20px"}
      opacity={0.4}
      h="82px"
      _dark={{
        bg: "#202427",
      }}
      _light={{
        bg: "#F5E0FF",
      }}
      rounded={17}
      alignItems={"center"}
      justifyContent="space-between"
    >
      <HStack alignItems={"center"} space={2}>
        <Center
          size={"50px"}
          rounded="20px"
          bg={{
            linearGradient: {
              colors: ["#8D49DE", "#691DE3"],
              start: [0, 0],
              end: [1, 0],
            },
          }}
        >
          <DailyLoginIcon />
        </Center>
        <VStack>
          <Text
            w={40}
            noOfLines={2}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
            fontSize={16}
            lineHeight={21}
            _light={{
              color: "#682481",
            }}
            _dark={{
              color: "white",
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              fontFamily: "HK_500Medium",
            }}
            fontSize={12}
            lineHeight={21}
            _light={{
              color: "#682481",
            }}
            _dark={{
              color: "White",
            }}
          >
            {subTitle}
          </Text>
        </VStack>
      </HStack>
      <ClaimedButton />
    </HStack>
  );
};

export default ClaimedRewardsCard;
