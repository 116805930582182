import { TextInput, Pressable } from "react-native";
import { Center, Text, View, FlatList, HStack } from "native-base";
import { useIsFocused } from "@react-navigation/native";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import { useDispatch } from "react-redux";
import { getUser } from "../../util/utilFunctions";
import PlusEllipseIconOutlined from "../../components/icons/plusEllipseIconOutlined";
import { navigate } from "../../RootNavigation";
import { hideLoader, showLoader } from "../../redux/currentState/actions";
import { Pages } from "../../constants/pages";
import { privateApi } from "../../util/API";
import TrendingProductCard from "./product/trendingProductCard";
import { setAnalytics } from "../../analytics";

const collectionScreenProductCard = ({ route, navigation }) => {
  const name = route?.params?.name;
  const product_skus = route?.params?.product_skus;
  const collectionId = route?.params?.collectionId;
  const type = route?.params?.type;

  const dispatch = useDispatch();

  const isFocused = useIsFocused();

  const deleteCollection = async (id) => {
    dispatch(showLoader());
    const userId = await getUser();
    if (userId !== undefined && userId !== null) {
      let res = await privateApi(
        "/api/products/collection/",
        { user_id: userId, collection_id: id },
        "delete"
      );

      setAnalytics(
        "Delete Collection",
        `Collection ${
          res?.body?.status === 200 ? "Deleted Successfully" : "Deletion Failed"
        }`,
        {
          status: res?.body?.status,
        }
      );
      if (res.status === 200) {
        dispatch(hideLoader());

        navigation.navigate(Pages.UserScreen, {
          id: userId,
          type: "profile",
        });
      }
    }
    dispatch(hideLoader());
  };

  const renderItem = ({ item }) => {
    return (
      <Center m={1}>
        <TrendingProductCard
          productImage={item && item?.images && item?.images[0]}
          retailPrice={item?.retail_price}
          groupDiscount={item?.group_discount}
          productName={item?.product?.name}
          brandName={item?.brand_name}
          productId={item?.product?.id}
          productSkuId={item?.id}
        />
      </Center>
    );
  };

  return (
    <>
      <View mx={2} mt={2} flex={1}>
        <HStack justifyContent={"space-between"}>
          <Text style={{ color: "white" }}> Collection Name*</Text>
          {type && type === "profile" ? (
            <Pressable
              onPress={() => {
                deleteCollection(collectionId);
              }}
            >
              <HStack
                alignItems={"center"}
                justifyContent="center"
                borderWidth="1px"
                borderColor={"#94A3B8"}
                rounded="20px"
                height={"25px"}
                width="69px"
                space={1.5}
              >
                <Text
                  fontSize={"16px"}
                  color="#94A3B8"
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                >
                  Delete
                </Text>
              </HStack>
            </Pressable>
          ) : null}
        </HStack>
        <TextInput
          style={{
            height: 40,
            width: "98%",
            borderColor: "white",
            borderWidth: 1,
            marginTop: 10,
            borderRadius: 5,
            color: "white",

            marginLeft: 5,
            paddingLeft: 5,
          }}
          value={name}
          editable={false}
          selectTextOnFocus={false}
          contextMenuHidden={true}
        />

        <HStack justifyContent={"space-between"}>
          <Text
            style={{
              color: "grey",
              letterSpacing: 2,
              marginTop: 20,
              marginLeft: 5,
            }}
          >
            PRODUCTS {`(${product_skus.length})`}
          </Text>
          {type && type === "profile" ? (
            <Pressable
              onPress={() => {
                setAnalytics(
                  "Edit Button To Edit Collection",
                  "Edit Button To Edit Collection Clicked"
                );
                navigate("CreateCollection", {
                  title: "Edit Collection",
                  name: name,
                  product_skus: product_skus,
                  editCollection: true,
                  collectionId: collectionId,
                });
              }}
            >
              <HStack
                alignItems={"center"}
                justifyContent="center"
                borderWidth="1px"
                borderColor={"#94A3B8"}
                rounded="20px"
                height={"25px"}
                width="69px"
                space={1.5}
                marginTop="5"
              >
                <PlusEllipseIconOutlined />
                <Text
                  fontSize={"16px"}
                  color="#94A3B8"
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                >
                  Edit
                </Text>
              </HStack>
            </Pressable>
          ) : null}
        </HStack>
        {isFocused && product_skus && (
          <FlatList
            maxToRenderPerBatch={4}
            updateCellsBatchingPeriod={20}
            showsVerticalScrollIndicator={false}
            initialNumToRender={2}
            keyExtractor={(item) => item.id}
            data={product_skus}
            numColumns={2}
            renderItem={renderItem}
            onEndReachedThreshold={0}
          />
        )}
      </View>
      <BottomBar />
    </>
  );
};

export default collectionScreenProductCard;
