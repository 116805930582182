import { IconButton, Modal } from "native-base";

import { useDispatch, useSelector } from "react-redux";
import { goBackDialog } from "../../redux/currentState/actions";
import CloseIcon from "../icons/closeIcon";
import FlockDealSteps from "./whatIsAFlockDeal/flockDealSteps";

const LearnMoreModal = () => {
  const dispatch = useDispatch();
  const openDialog = useSelector((state) => state.currentState.openDialog);
  return (
    <Modal
      size="full"
      bg="black:alpha.95"
      isOpen={openDialog}
      onClose={() => dispatch(goBackDialog())}
    >
      <IconButton
        onPress={() => dispatch(goBackDialog())}
        size={10}
        position={"absolute"}
        right={"8%"}
        top={"8%"}
        _pressed={{
          bg: "black:alpha.90",
        }}
        icon={<CloseIcon size={8} />}
      />
      <FlockDealSteps isLearnMore={true} />
    </Modal>
  );
};

export default LearnMoreModal;
