import { createContext } from "react";
import { useTimer } from "../hooks/useTimer";
export const TimerContext = createContext();

const TimerHOC = (props) => {
  const timer = useTimer(props.starting_time);

  return (
    <TimerContext.Provider value={timer}>
      {props.children}
    </TimerContext.Provider>
  );
};

export default TimerHOC;
