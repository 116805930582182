import {
  Center,
  FlatList,
  HStack,
  Image,
  ScrollView,
  Text,
  useColorModeValue,
  View,
  VStack,
} from "native-base";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import ChevronBackArrowIcon from "../../components/icons/chevronBackArrowIcon";

const LevelBenefitScreen = ({ navigation, route }) => {
  const backgroundColor = useColorModeValue("white", "#111315");
  const userId = useSelector((state) => state.user.id);
  const levels = [
    {
      level: "1",
      points: "0-500",
      steps: ["Earn 1X points for every dollar you spend"],
      slide: (
        <HStack
          w={{
            md: "375px",
            lg: "390px",
            xl: "430px",
          }}
          justifyContent={"space-between"}
          h="58px"
          bg="#2B2F33"
          roundedLeft={"full"}
          pr={8}
        >
          {[1, 2].map((item) => (
            <Center
              size={"58px"}
              rounded={"full"}
              bg={{
                linearGradient:
                  route?.params?.level >= item
                    ? {
                        colors: [
                          "primary.ctaLinearSecondColor",
                          "primary.ctaLinearFirstColor",
                        ],
                        start: [0, 0],
                        end: [1, 0],
                      }
                    : {
                        colors: ["#515151", "#515151"],
                        start: [0, 0],
                        end: [1, 0],
                      },
              }}
            >
              <Center size={"49px"} rounded={"full"} bg={"#1A1C1E"}>
                <Text
                  color={route?.params?.level >= item ? "#fff" : "#94A3B8"}
                  fontSize="26px"
                  style={{
                    fontFamily: "HK_800ExtraBold",
                  }}
                >
                  {item}
                </Text>
              </Center>
            </Center>
          ))}
        </HStack>
      ),
    },
    {
      level: "2",
      points: "501-1500",
      steps: [
        "Earn 1.25X points for each dollar you spend",
        "Early access to exclusive deals",
      ],
      slide: (
        <HStack
          pr={8}
          w={{
            md: "375px",
            lg: "390px",
            xl: "430px",
          }}
          justifyContent={"space-between"}
          h="58px"
          bg="#2B2F33"
          roundedLeft={"full"}
        >
          {[2, 3].map((item) => (
            <Center
              size={"58px"}
              rounded={"full"}
              bg={{
                linearGradient:
                  route?.params?.level >= item
                    ? {
                        colors: [
                          "primary.ctaLinearSecondColor",
                          "primary.ctaLinearFirstColor",
                        ],
                        start: [0, 0],
                        end: [1, 0],
                      }
                    : {
                        colors: ["#515151", "#515151"],
                        start: [0, 0],
                        end: [1, 0],
                      },
              }}
            >
              <Center size={"49px"} rounded={"full"} bg={"#1A1C1E"}>
                <Text
                  color={route?.params?.level >= item ? "#fff" : "#94A3B8"}
                  fontSize="26px"
                  style={{
                    fontFamily: "HK_800ExtraBold",
                  }}
                >
                  {item}
                </Text>
              </Center>
            </Center>
          ))}
        </HStack>
      ),
    },
    {
      level: "3",
      points: "1501 onwards",
      steps: [
        "Earn 1.5X points for each dollar you spend",
        "Early access to exclusive deals",
        "Access to invite only events with celebs, founders, and makeup artists",
      ],
      slide: (
        <HStack
          pr={8}
          w={{
            md: "375px",
            lg: "390px",
            xl: "430px",
          }}
          h="58px"
          bg="#2B2F33"
          roundedLeft={"full"}
        >
          {[3].map((item) => (
            <Center
              size={"58px"}
              rounded={"full"}
              bg={{
                linearGradient:
                  route?.params?.level >= item
                    ? {
                        colors: [
                          "primary.ctaLinearSecondColor",
                          "primary.ctaLinearFirstColor",
                        ],
                        start: [0, 0],
                        end: [1, 0],
                      }
                    : {
                        colors: ["#515151", "#515151"],
                        start: [0, 0],
                        end: [1, 0],
                      },
              }}
            >
              <Center size={"49px"} rounded={"full"} bg={"#1A1C1E"}>
                <Text
                  color={route?.params?.level >= item ? "#fff" : "#94A3B8"}
                  fontSize="26px"
                  style={{
                    fontFamily: "HK_800ExtraBold",
                  }}
                >
                  {item}
                </Text>
              </Center>
            </Center>
          ))}
        </HStack>
      ),
    },
  ];
  const [current, setCurrent] = useState(levels[0]);
  const _onViewableItemsChanged = useCallback(({ viewableItems, changed }) => {
    setCurrent(viewableItems[0]?.item);
  }, []);
  const _viewabilityConfig = {
    itemVisiblePercentThreshold: 50,
  };

  const renderItem = ({ item }) => {
    return item.slide;
  };
  return (
    <>
      <ScrollView>
        <VStack flex={1} space={"50px"}>
          <Center bg={"#1A1C1E"}>
            <VStack
              justifyContent={"space-around"}
              w="full"
              h="250px"
              rounded={"40px"}
              bg={{
                linearGradient: {
                  colors: [
                    "primary.ctaLinearSecondColor",
                    "primary.ctaLinearFirstColor",
                  ],
                  start: [0, 0],
                  end: [1, 0],
                },
              }}
            >
              <HStack w="full" pt={12} pl={8}>
                <ChevronBackArrowIcon
                  onPress={() => {
                    navigation.goBack();
                  }}
                />
              </HStack>

              <VStack w="full" alignItems={"center"}>
                {/* <Image
                size={20}
                resizeMethod="scale"
                resizeMode="contain"
                source={require("./../../assets/images/pngs/amethystLarge.png")}
                alt=""
              /> */}
                <Text
                  letterSpacing={"10"}
                  color={"#fff"}
                  fontSize="36px"
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                >
                  Level {current?.level}
                </Text>
                <Text
                  textTransform={"uppercase"}
                  letterSpacing={"2"}
                  color={"#fff"}
                  fontSize="16px"
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                >
                  {current?.points} points
                </Text>
              </VStack>
            </VStack>

            <VStack bg={backgroundColor} w="full">
              <VStack
                w="full"
                h="250px"
                p={4}
                bg={"#1A1C1E"}
                roundedBottom={"40px"}
                space={4}
              >
                <HStack
                  w="full"
                  justifyContent={"space-around"}
                  alignItems="center"
                >
                  <HStack alignItems="center">
                    <Text
                      letterSpacing={"3"}
                      color={"#94A3B8"}
                      fontSize="14px"
                      style={{
                        fontFamily: "HK_700Bold",
                      }}
                    >
                      BADGE BENEFITS
                    </Text>
                    <Image
                      size={"20px"}
                      resizeMethod="scale"
                      resizeMode="contain"
                      source={require("./../../assets/images/gifs/heartEyes.gif")}
                      alt=""
                    />
                  </HStack>

                  <HStack
                    justifyContent={"center"}
                    alignItems={"center"}
                    h="30px"
                    w="130px"
                    rounded="full"
                    bg={{
                      linearGradient: {
                        colors: ["#242628", "#1F2123"],
                        start: [0, 0],
                        end: [1, 0],
                      },
                    }}
                  >
                    {/* <Image
                    resizeMethod="scale"
                    resizeMode="contain"
                    source={require("./../../assets/images/pngs/amethyst.png")}
                    alt=""
                  /> */}
                    <Text
                      textTransform={"uppercase"}
                      letterSpacing={"3"}
                      color={"#CB3BFE"}
                      fontSize="14px"
                      style={{
                        fontFamily: "HK_700Bold",
                      }}
                    >
                      Level {current?.level}
                    </Text>
                  </HStack>
                </HStack>
                {current?.steps.map((k) => (
                  <Text
                    color={"#fff"}
                    fontSize="14px"
                    style={{
                      fontFamily: "HK_500Medium",
                    }}
                  >
                    {k}
                  </Text>
                ))}
              </VStack>
            </VStack>
          </Center>
          {/* bg={{
              linearGradient: {
                colors: [
                  "primary.ctaLinearSecondColor",
                  "primary.ctaLinearFirstColor",
                ],
                start: [0, 0],
                end: [1, 0],
              },
            }}
            rounded={"full"}
            width={"1/2"} */}

          <VStack space={"20px"}>
            <View h="58px" bg="#2B2F33" roundedLeft={"full"}>
              <FlatList
                onViewableItemsChanged={_onViewableItemsChanged}
                viewabilityConfig={_viewabilityConfig}
                pagingEnabled
                showsHorizontalScrollIndicator={false}
                horizontal
                data={levels}
                renderItem={renderItem}
              />
            </View>

            {userId && (
              <HStack
                alignItems={"center"}
                justifyContent="space-evenly"
                space={"8px"}
              >
                {/* <Pressable>
            <RightIcon />
          </Pressable> */}
                <Text
                  color={"#94A3B8"}
                  fontSize="16px"
                  style={{
                    fontFamily: "HK_500Medium",
                  }}
                >
                  +
                  {route?.params?.points > 501 &&
                    route?.params?.points < 1501 &&
                    Math.abs(route?.params?.points - 1501)}{" "}
                  {route?.params?.points < 3000 &&
                    route?.params?.points > 1501 &&
                    Math.abs(route?.params?.points - 3000)}{" "}
                  {route?.params?.points < 501 &&
                    Math.abs(route?.params?.points - 501)}{" "}
                  Points to next level
                </Text>

                {/* <Pressable>
            <LeftIcon />
          </Pressable> */}
              </HStack>
            )}
          </VStack>
        </VStack>
      </ScrollView>

      <BottomBar />
    </>
  );
};

export default LevelBenefitScreen;
