import { VStack, FlatList, View } from "native-base";
import React, { useState, useEffect } from "react";
import { HairConcerns } from "../../../../constants/hairConcerns";
import SearchBox from "./searchBox";
import CheckBox from "./checkbox";

const Item = ({ item, selectedHair, setSelectedHair }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selected) {
      if (selectedHair.includes(item)) {
        setSelected(true);
      }
    }
  }, [selectedHair]);

  const onPress = () => {
    if (selectedHair.includes(item)) {
      setSelected(false);
      const filtered = selectedHair.filter((value) => value !== item);
      setSelectedHair(filtered);
    } else {
      setSelected(true);
      setSelectedHair([...selectedHair, item]);
    }
  };
  return (
    <CheckBox
      onPress={onPress}
      checked={selected}
      label={HairConcerns[item]}
      item={item}
    />
  );
};

// Todo : Make a component for Filter.
const HairProfile = ({ selectedHair, setSelectedHair }) => {
  const [concernResults, setConcernResults] = useState(
    Object.keys(HairConcerns)
  );
  const renderItem = ({ item }) => (
    <Item
      item={item}
      selectedHair={selectedHair}
      setSelectedHair={setSelectedHair}
    />
  );

  return (
    <VStack width={"100%"} pb={8} px={4} space={6}>
      <SearchBox
        onChange={async (val) => {
          let concerns = Object.keys(HairConcerns);
          let concernResult = concerns.filter((concern) => {
            return HairConcerns[concern]
              .toLowerCase()
              .includes(val.toLowerCase());
          });
          setConcernResults(concernResult);
        }}
      />

      <FlatList
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 11 }}></View>
        )}
        contentContainerStyle={{
          paddingBottom: 12,
        }}
        data={concernResults}
        initialNumToRender={12}
        renderItem={renderItem}
      />
    </VStack>
  );
};

export default HairProfile;
