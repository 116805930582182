import { privateApi } from "../util/API";
import { useDispatch } from "react-redux";
import { getUser, parse } from "../util/utilFunctions";
import {
  showLoader,
  hideLoader,
  setDialog,
} from "../redux/currentState/actions";
import { useSWRConfig } from "swr";
import { Popups } from "../constants/popups";
import { setAnalytics } from "../analytics";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
import * as RootNavigation from "./../RootNavigation";
import { Pages } from "../constants/pages";
import { Platform } from "react-native";
export const useAddItemToCart = () => {
  const navigation = RootNavigation;
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const addItemToCart = async (
    productSkuId,
    productId,
    bundleId,
    source = "regular",
    otherUserId,
    referralId,
    quantity = 1
  ) => {
    const userId = await getUser();
    dispatch(showLoader());
    let sku = productSkuId;
    let amount = 0;

    try {
      if (userId !== null && userId !== undefined) {
        let r = await privateApi(
          "/api/products/get_product_details/",
          {
            product_id: productId,
          },
          "get"
        );
        if (r.status === 200) {
          let data = r.body;
          let productData = parse(data);

          amount = productData?.product?.find(
            (k, i) => k.id === productSkuId
          )?.retail_price;
        }
        let paramsMap = {
          user: userId,
          product_sku: sku,
          number: quantity,
          amount: amount,
          source: source,
          other_user_id: otherUserId,
          refferal_id: referralId,
          product_bundle: bundleId,
        };
        let params = {};
        Object.keys(paramsMap)
          ?.filter((f) => paramsMap[f] && paramsMap[f] !== "regular")
          .map((k) => (params[k] = paramsMap[k]));

        const res = await privateApi("/api/products/cart/", params, "post");
        console.log("useAddItemToCart", res, params, source);
        if (res.status === 201) {
          setAnalytics("Add To Cart", "Added To Cart", {
            productId: productId,
          });
          mutate(`${apiUrl}/api/products/get_cart/?user_id=${userId}`);
          mutate(
            `${apiUrl}/api/products/get_valentines_discount/?user_id=${userId}`
          );
        } else {
        }
      } else {
        setAnalytics("Add To Cart", `Guest User Tried Adding To Cart`, {
          productId: productId,
        });
        if (Platform.OS === "web") {
          navigation.navigate(Pages.Login);
        } else {
          dispatch(setDialog(Popups.LoginPopup));
        }
      }
      dispatch(hideLoader());
    } catch (error) {}
    dispatch(hideLoader());
  };

  return addItemToCart;
};
