import { Text } from "native-base";

export const CenterText = ({ text }) => (
  <Text
    isTruncated
    textAlign={"center"}
    style={{
      fontFamily: "HK_500Medium",
    }}
    w={"180px"}
    fontSize={24}
    lineHeight={24}
    color="white"
  >
    {text}
  </Text>
);
