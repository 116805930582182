import { Box, Center, FlatList, Stack } from "native-base";
import { Dimensions } from "react-native";
import BeautyBuddiesCard from "../../../components/cards/beautyBuddiesCard";
import GradientText from "../../../components/gradientText";

const FriendsOnFlock = ({ data }) => {
  const renderItem = ({ item }) => (
    <Center m={1.5}>
      {item === "extra" ? (
        <Box
          w={{
            md: Dimensions.get("screen").width / 2 - 12,
            lg: Dimensions.get("screen").width / 2 - 12,
            xl: "200px",
          }}
        ></Box>
      ) : (
        <BeautyBuddiesCard
          cardWidth={{
            md: Dimensions.get("screen").width / 2 - 12,
            lg: Dimensions.get("screen").width / 2 - 12,
            xl: "200px",
          }}
          imageWidth={{
            md: Dimensions.get("screen").width / 2 - 15,
            lg: Dimensions.get("screen").width / 2 - 20,
            xl: "187px",
          }}
          item={item}
        />
      )}
    </Center>
  );

  return (
    <>
      <FlatList
        ListHeaderComponent={
          data && (
            <Stack pb={"12px"} h="42px">
              <GradientText
                text={`Friends on flock (${data?.length})`}
              ></GradientText>
            </Stack>
          )
        }
        px={"21px"}
        flex={1}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        initialNumToRender={2}
        columnWrapperStyle={{
          justifyContent: "center",
        }}
        data={data && data?.length % 2 !== 0 ? data?.concat(["extra"]) : data}
        numColumns={2}
        renderItem={renderItem}
      />
    </>
  );
};

export default FriendsOnFlock;
