import { HStack, IconButton, Text } from "native-base";
import CloseIcon from "../../icons/closeIcon";

const FilterPopUpTopBar = ({ onPress }) => {
  return (
    <HStack justifyContent={"center"} alignItems="center" py={1}>
      <HStack width={"55%"} justifyContent="flex-end">
        <Text
          style={{
            fontFamily: "HK_700Bold",
          }}
          fontSize={24}
          color="white"
        >
          Filter
        </Text>
      </HStack>

      <HStack width={"35%"} justifyContent="flex-end">
        <IconButton
          _pressed={{
            bg: "#2B2F33",
          }}
          onPress={onPress}
          icon={<CloseIcon size={10} />}
        />
      </HStack>
    </HStack>
  );
};

export default FilterPopUpTopBar;
