import { useBreakpointValue } from "native-base";
import { useEffect, useState } from "react";

const useGetNumberOfColumns = (defaultNumberOfColumns) => {
  const [columnNumber, setColumnNumber] = useState(defaultNumberOfColumns);
  const breakpoint = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: "xl",
    xxl: "xxl",
  });
  const map = {
    base: 2,
    sm: 2,
    md: 3,
    lg: 4,
    xl: defaultNumberOfColumns,
    xxl: defaultNumberOfColumns,
  };

  useEffect(() => {
    setColumnNumber(map[breakpoint]);
  }, [breakpoint, columnNumber]);
  return columnNumber;
};

export default useGetNumberOfColumns;
