import { createContext } from "react";
import { useSelector } from "react-redux";
import { useAddToCart } from "../hooks/useAddToCart";
import { useDeleteFromCart } from "../hooks/useDeleteFromCart";
import useGetCartItems from "../hooks/useGetCartItems";
export const QuantityButtonContext = createContext();
const QuantityButtonHOC = (props) => {
  const userId = useSelector((state) => state.user.id);
  const { data } = useGetCartItems(userId);
  const addToCart = useAddToCart();
  const deleteFromCart = useDeleteFromCart();
  return (
    <QuantityButtonContext.Provider
      value={{
        data,
        addToCart,
        deleteFromCart,
      }}
    >
      {props.children}
    </QuantityButtonContext.Provider>
  );
};

export default QuantityButtonHOC;
