import { useRoute } from "@react-navigation/native";
import {
  Center,
  HStack,
  Image,
  Slider,
  Text,
  VStack,
  Icon,
  Pressable,
  ChevronUpIcon,
  PresenceTransition,
  ChevronDownIcon,
} from "native-base";
import { useContext, useState } from "react";
import { setAnalytics } from "../../analytics";
import { CheckoutContext } from "../../hoc/CheckoutHOC";
import RewardsIcon from "../icons/rewardsIcon";
const FlockBucksRedeemCard = () => {
  const route = useRoute();
  const [showSlider, setShowSlider] = useState();
  const {
    applyOtherDiscounts,
    redeemPercent,
    setRedeemPercent,
    higherPoints,
    onChangeValue,
    setOnChangeValue,
    setOnChangeEndValue,
  } = useContext(CheckoutContext);

  return (
    <>
      {applyOtherDiscounts && (
        <VStack
          w="full"
          roundedBottom={"20px"}
          _light={{
            bg: "#FAF1FF",
          }}
        >
          <Pressable w="full" onPress={() => setShowSlider(!showSlider)}>
            <HStack
              px={2}
              w="full"
              h="40px"
              alignItems={"center"}
              justifyContent="space-between"
              rounded={"10px"}
              bg={{
                linearGradient: {
                  colors: [
                    "primary.ctaLinearFirstColor",
                    "primary.ctaLinearSecondColor",
                  ],
                  start: [0, 0],
                  end: [1, 0],
                },
              }}
            >
              <HStack alignItems={"center"} space={2}>
                {showSlider ? (
                  <ChevronUpIcon color="white" />
                ) : (
                  <ChevronDownIcon color="white" />
                )}
                <Image
                  h="65px"
                  w="50px"
                  resizeMethod="scale"
                  resizeMode="contain"
                  source={require("./../../assets/images/pngs/FLockbucks1.png")}
                  alt=""
                ></Image>
              </HStack>
              <HStack alignItems={"center"}>
                <Text
                  _light={{
                    color: "white",
                  }}
                  _dark={{
                    color: "#94A3B8",
                  }}
                  _web={{
                    style: {
                      fontFamily: "Hero_400Regular",
                    },
                  }}
                  fontSize="12px"
                  style={{
                    fontFamily: "HK_400Regular",
                  }}
                >
                  Usable Points On This Order:{" "}
                  <Text
                    _web={{
                      style: {
                        fontFamily: "Hero_700Bold",
                      },
                    }}
                    fontSize="14px"
                    style={{
                      fontFamily: "HK_600SemiBold",
                    }}
                  >
                    {higherPoints}
                  </Text>
                </Text>
                <RewardsIcon size={4} />
              </HStack>
            </HStack>
          </Pressable>
          {showSlider && (
            <PresenceTransition
              visible={showSlider}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 500,
                },
              }}
            >
              <VStack
                space={4}
                w="full"
                rounded={"20px"}
                _light={{
                  bg: "#FAF1FF",
                }}
                _dark={{
                  bg: "#1A1C1E",
                }}
                p={4}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Text
                  _light={{
                    color: "#464646",
                  }}
                  _dark={{
                    color: "white",
                  }}
                  _web={{
                    style: {
                      fontFamily: "Hero_400Regular",
                    },
                  }}
                  fontSize="14px"
                  style={{
                    fontFamily: "HK_500Medium",
                  }}
                >
                  Use FlockBucks for Additional Discounts
                </Text>

                <HStack
                  justifyContent={"space-between"}
                  space={4}
                  w="full"
                  alignItems="center"
                >
                  <Slider
                    minValue={0}
                    maxValue={higherPoints}
                    defaultValue={0}
                    value={onChangeValue}
                    onChange={(v) => {
                      setOnChangeValue(Math.floor(v));
                      setRedeemPercent([
                        {
                          label: 25,
                          pressed: false,
                        },
                        {
                          label: 50,
                          pressed: false,
                        },
                        {
                          label: 100,
                          pressed: false,
                        },
                      ]);
                    }}
                    onChangeEnd={(v) => {
                      setOnChangeEndValue(Math.floor(v));
                    }}
                    h="6px"
                    colorScheme="#8D49DE"
                    w="60%"
                  >
                    <Slider.Track bg="#58595B">
                      <Slider.FilledTrack bg="#8D49DE" />
                    </Slider.Track>
                    <Slider.Thumb borderWidth="0" bg="transparent">
                      <Icon
                        as={
                          <Center
                            bg="white"
                            borderColor="#8D49DE"
                            borderWidth={"3px"}
                            rounded={"full"}
                          ></Center>
                        }
                        name="park"
                        color="white"
                        size="6"
                      />
                    </Slider.Thumb>
                  </Slider>
                  <HStack alignItems={"center"}>
                    <Text
                      _light={{
                        color: "#682481",
                      }}
                      _dark={{
                        color: "white",
                      }}
                      fontSize={{
                        base: "12px",
                        sm: "14px",
                        md: "16px",
                        lg: "22px",
                        xl: "22px",
                      }}
                      style={{
                        fontFamily: "HK_600SemiBold",
                      }}
                    >
                      {isNaN(onChangeValue) ? 0 : onChangeValue}
                    </Text>
                    <RewardsIcon size={4} />
                  </HStack>
                </HStack>
                <Text
                  _light={{
                    color: "#464646",
                  }}
                  _dark={{
                    color: "white",
                  }}
                  _web={{
                    style: {
                      fontFamily: "Hero_400Regular",
                    },
                  }}
                  fontSize="14px"
                  style={{
                    fontFamily: "HK_400Regular",
                  }}
                >
                  Slide the bar to use FlockBucks on this order or choose:
                </Text>
                <HStack w="full" justifyContent={"space-evenly"}>
                  {redeemPercent.map((k, index) => (
                    <Pressable
                      onPress={() => {
                        let copy = [...redeemPercent];
                        copy.map((k) => {
                          if (k.pressed) {
                            return (k.pressed = false);
                          }
                        });
                        copy[index] = { ...copy[index], pressed: true };
                        setRedeemPercent(copy);
                        let redeemedPoints = (k.label * higherPoints) / 100;
                        setOnChangeValue(redeemedPoints);
                        setOnChangeEndValue(redeemedPoints);
                        setAnalytics(
                          "Discount Button",
                          `${k.label}% of ${higherPoints} selected`,
                          {
                            screen: route?.name,
                            discountPercent: k.label,
                            usablePoints: higherPoints,
                            redeemedPoints: redeemedPoints,
                          }
                        );
                      }}
                    >
                      <Center
                        w="90px"
                        h="32px"
                        rounded={"8px"}
                        _dark={{
                          bg: {
                            linearGradient: k.pressed
                              ? {
                                  colors: [
                                    "primary.ctaLinearSecondColor",
                                    "primary.ctaLinearFirstColor",
                                  ],
                                  start: [0, 0],
                                  end: [1, 0],
                                }
                              : {
                                  colors: ["#202427", "#202427"],
                                  start: [0, 0],
                                  end: [1, 0],
                                },
                          },
                        }}
                        _light={{
                          bg: {
                            linearGradient: k.pressed
                              ? {
                                  colors: [
                                    "primary.ctaLinearSecondColor",
                                    "primary.ctaLinearFirstColor",
                                  ],
                                  start: [0, 0],
                                  end: [1, 0],
                                }
                              : {
                                  colors: ["#EED1FF", "#EED1FF"],
                                  start: [0, 0],
                                  end: [1, 0],
                                },
                          },
                        }}
                      >
                        <Center
                          w="88px"
                          h="30px"
                          rounded={"8px"}
                          _light={{
                            bg: "#EED1FF",
                          }}
                          _dark={{
                            bg: "#202427",
                          }}
                          alignItems="center"
                        >
                          <Text
                            _light={{
                              color: "#682481",
                            }}
                            _dark={{
                              color: false ? "#fff" : "#94A3B8",
                            }}
                            _web={{
                              style: {
                                fontFamily: "Hero_400Regular",
                              },
                            }}
                            fontSize="14px"
                            style={{
                              fontFamily: "HK_400Regular",
                            }}
                          >
                            {k.label}%
                          </Text>
                        </Center>
                      </Center>
                    </Pressable>
                  ))}
                </HStack>
              </VStack>
            </PresenceTransition>
          )}
        </VStack>
      )}
    </>
  );
};

export default FlockBucksRedeemCard;
