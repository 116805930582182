import { Icon } from "native-base";
import { Path, G, LinearGradient, Stop, Defs } from "react-native-svg";

const GradientTikTokIcon = () => {
  return (
    <Icon
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G fill="none">
        <Path
          d="M12.7632 3.72234C12.7632 3.72234 13.2732 4.22234 12.7632 3.72234C12.0797 2.94197 11.703 1.93978 11.7032 0.902344H8.61324V13.3023C8.58941 13.9734 8.30611 14.609 7.82301 15.0753C7.3399 15.5416 6.69469 15.8022 6.02324 15.8023C4.60324 15.8023 3.42324 14.6423 3.42324 13.2023C3.42324 11.4823 5.08324 10.1923 6.79324 10.7223V7.56234C3.34324 7.10234 0.323242 9.78234 0.323242 13.2023C0.323242 16.5323 3.08324 18.9023 6.01324 18.9023C9.15324 18.9023 11.7032 16.3523 11.7032 13.2023V6.91234C12.9562 7.81219 14.4606 8.29499 16.0032 8.29234V5.20234C16.0032 5.20234 14.1232 5.29234 12.7632 3.72234Z"
          fill="url(#paint0_linear_45_15261)"
        />
        <Defs>
          <LinearGradient
            id="paint0_linear_45_15261"
            x1="0.323242"
            y1="0.902344"
            x2="21.2384"
            y2="7.66023"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#691DE3" />
            <Stop offset="0.0001" stop-color="#691DE3" />
            <Stop offset="1" stop-color="#FF5A92" />
          </LinearGradient>
        </Defs>
      </G>
    </Icon>
  );
};

export default GradientTikTokIcon;
