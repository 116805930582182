import { Box, FlatList, ScrollView, VStack } from "native-base";
import BottomBar from "../../../components/bars/navigationBars/bottomBar";
import useGetOrders from "../../../hooks/useGetOrders";
import OrderCard from "../../../components/cards/orderCard";
import { useLoader } from "../../../hooks/useLoader";
import { Platform } from "react-native";
const YourOrders = () => {
  const { data, isValidating } = useGetOrders();

  useLoader(isValidating);
  return (
    <>
      {Platform.OS === "web" ? (
        <VStack space={4}>
          {data?.map((item) => (
            <OrderCard order={item} />
          ))}
        </VStack>
      ) : (
        <>
          <FlatList
            px={2}
            flex={1}
            showsVerticalScrollIndicator={false}
            ItemSeparatorComponent={() => (
              <Box style={{ paddingVertical: 5 }}></Box>
            )}
            contentContainerStyle={{
              paddingVertical: 8,
            }}
            data={data}
            renderItem={({ item }) => <OrderCard order={item} />}
          />
          <BottomBar />
        </>
      )}
    </>
  );
};

export default YourOrders;
