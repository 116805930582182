import { Button, HStack, Text, useColorModeValue } from "native-base";
import ChevronLeftArrowIcon from "../../../components/icons/chevronLeftArrowIcon";

const ViewMoreTopBar = ({ title, icon, buttonText, bg, onPress }) => {
  const backgroundColor = useColorModeValue("white", "#111315");
  return (
    <>
      <HStack
        alignItems={"center"}
        justifyContent="space-between"
        pb="20px"
        bg={bg}
      >
        <HStack alignItems={"center"}>
          <Text
            _web={{
              style: {
                fontFamily: "AustinCyr_400",
              },
            }}
            _light={{
              color: "#682481",
            }}
            textAlign={"center"}
            color={"#94A3B8"}
            fontSize="22px"
            lineHeight={"30px"}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
          >
            {title}
          </Text>
          {icon && icon}
        </HStack>
        <Button
          onPress={onPress}
          _pressed={{ bg: backgroundColor }}
          variant={"ghost"}
          _text={{
            color: "#8F8F8F",
            fontSize: 14,
            lineHeight: 21,
            fontFamily: "Hero_700Bold",
          }}
        >
          {buttonText}
        </Button>
        {/* <Button
          _pressed={{ bg: backgroundColor }}
          onPress={onPress}
          variant={"ghost"}
          _text={{
            color: "#1A94FF",
            fontSize: 14,
            lineHeight: 21,
            fontFamily: "HK_600SemiBold",
          }}
          endIcon={<ChevronLeftArrowIcon color={"#1A94FF"} />}
        >
          {buttonText}
        </Button> */}
      </HStack>
    </>
  );
};
export default ViewMoreTopBar;
