import axios from "axios";
import useSWR from "swr";
import Constants from "expo-constants";
import { getHeaders } from "../util/utilFunctions";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const fetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });

export default function useCheckUserExists(param, type) {
  let encodedURI = encodeURIComponent(type?.toLowerCase());

  const { data, mutate, isValidating } = useSWR(
    type !== undefined && type !== ""
      ? `${apiUrl}/api/users/check_user_exists/?${param}=${encodedURI}`
      : null,
    fetcher
  );
  return { data, mutate, isValidating };
}
