import { useDispatch, useSelector } from "react-redux";
import {
  closeDialog,
  hideLoader,
  setDialog,
  showLoader,
} from "../redux/currentState/actions";
import { useState } from "react";
import { privateApi } from "../util/API";
import { useSWRConfig } from "swr";
import Constants from "expo-constants";
import * as RootNavigation from "./../RootNavigation";
import { getUser } from "../util/utilFunctions";
const apiUrl = Constants.expoConfig.extra.apiUrl;

export const useAddNewAddress = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const form = useSelector((state) => state.form);
  const { mutate } = useSWRConfig();
  const navigation = RootNavigation;

  const addNewAddress = async () => {
    dispatch(showLoader());
    const userId = await getUser();
    if (userId !== null && userId !== undefined) {
      const data = {
        ...form.shipping,
        type: "shipping",
        user_id: userId,
        first_name: form.shipping["name"],
        last_name: form.shipping["name"],
      };

      let res = await privateApi("/api/users/add_address/", data, "post");

      mutate(`${apiUrl}/api/users/get_payment_details/?user_id=${userId}`);
      mutate(`${apiUrl}/api/users/get_addresses/?user_id=${userId}`);
      dispatch({
        type: "REFRESH_DETAILS",
      });
      dispatch(setDialog(closeDialog()));
    } else {
      navigation.navigate(Pages.Login);
    }
    navigation.navigationRef.goBack();
    dispatch(hideLoader());
  };

  return { addNewAddress, error };
};
