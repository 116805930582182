const { Image, useColorMode } = require("native-base");

const DrySkinTypeIcon = () => {
  const { colorMode } = useColorMode();

  return (
    <Image
      width={62}
      height={70}
      resizeMethod="scale"
      resizeMode="contain"
      source={
        colorMode === "dark"
          ? require("./../../../../assets/images/pngs/grayDrySkin.png")
          : require("./../../../../assets/images/pngs/lightinactivedryskin.png")
      }
      alt=""
    />
  );
};

export default DrySkinTypeIcon;
