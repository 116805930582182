import { useFocusEffect } from "@react-navigation/native";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { resetFilters } from "../redux/filter/actions";

const useResetFilter = () => {
  const dispatch = useDispatch();
  useFocusEffect(
    useCallback(() => {
      dispatch(resetFilters());
    }, [])
  );
};

export default useResetFilter;
