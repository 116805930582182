import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";

var store;

store = configureStore({
  reducer: rootReducer,
  devTools:
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__(),
});

export var store;
