import {
  Text,
  Modal,
  HStack,
  VStack,
  Slide,
  Pressable,
  Box,
  Divider,
  Center,
  FlatList,
  View,
} from "native-base";
import React, { useEffect, useState } from "react";
import FilterPopUpTopBar from "../../bars/popupTopBars/filterPopUpTopBar";
import { useDispatch, useSelector } from "react-redux";
import { goBackDialog } from "../../../redux/currentState/actions";
import { resetCacheFilter } from "../../../redux/filter/actions";
import CommunityFilterPopUpBottomBar from "../../bars/communityFilterPopUpBottomBar";
import SkinType from "./skinType";
import HairType from "./hairType";
import Ethinicity from "./ethinicity";
import Age from "./age";
import HairTexture from "./hairTexture";
import { changeFilter } from "../../../redux/communityFilter/actions";
import Ethnicity from "./ethinicity";

const CommunityFilterPopUp = () => {
  const dispatch = useDispatch();
  const skinTypeFilter = useSelector(
    (state) => state.communityFilter.cachedFilters.skin_type
  );
  const hairTypeFilter = useSelector(
    (state) => state.communityFilter.cachedFilters.hair_type
  );
  const hairTextureFilter = useSelector(
    (state) => state.communityFilter.cachedFilters.hair_texture
  );
  const ethnicityFilter = useSelector(
    (state) => state.communityFilter.cachedFilters.ethnicity
  );

  const [selectedEthnicity, setSelectedEthnicity] = useState(ethnicityFilter);
  const [selectedHairType, setSelectedHairType] = useState(hairTypeFilter);

  const [selectedSkinType, setSelectedSkinType] = useState(skinTypeFilter);
  const [selectedHairTexture, setSelectedHairTexture] =
    useState(hairTextureFilter);

  const openDialog = useSelector((state) => state.currentState.openDialog);

  const low = useSelector(
    (state) => state.communityFilter.cachedFilters.ageRangeFrom
  );
  const high = useSelector(
    (state) => state.communityFilter.cachedFilters.ageRangeTo
  );
  const [ageFilter, setAgeFilter] = useState([]);
  useEffect(() => {
    if (low !== 0 || high !== 700) {
      setAgeFilter([0]);
    } else {
      setAgeFilter([]);
    }
  }, [low, high]);

  const [filterBy, resetFilterBy] = useState([
    // {
    //   label: "Age",

    //   pressed: true,
    // },
    {
      label: "Skin Type",
      component: <SkinType />,
      pressed: true,
    },

    {
      label: "Hair Type",
      component: <HairType />,
      pressed: false,
    },
    {
      label: "Hair Texture",
      component: <HairTexture />,
      pressed: false,
    },
    {
      label: "Ethnicity",
      component: <Ethinicity />,
      pressed: false,
    },
  ]);

  const checkForFilter = async () => {
    dispatch(resetCacheFilter());
    dispatch(goBackDialog());
  };

  const onPressApplyFilter = () => {
    dispatch(
      changeFilter({
        ethnicity: selectedEthnicity,
        hair_type: selectedHairType,
        skin_type: selectedSkinType,
        hair_texture: selectedHairTexture,
      })
    );
  };

  return (
    <>
      <Modal
        size={"full"}
        isOpen={openDialog}
        onClose={() => checkForFilter()}
        bg="black:alpha.80"
      >
        <Slide overlay={false} in={openDialog} placement="bottom">
          <VStack
            height={"90%"}
            roundedTop={"40px"}
            marginBottom={"0"}
            marginTop={"auto"}
            bg={"#2B2F33"}
            width="100%"
          >
            <FilterPopUpTopBar onPress={() => checkForFilter()} />

            <HStack height={"75%"} pt={1}>
              <View width={"35%"} bg={"#202427"}>
                <FlatList
                  showsVerticalScrollIndicator={false}
                  data={filterBy}
                  renderItem={({ item, index }) => (
                    <Pressable
                      key={index}
                      onPress={() => {
                        let copy = [...filterBy];
                        copy.map((k) => {
                          if (k.pressed) {
                            return (k.pressed = false);
                          }
                        });
                        copy[index] = { ...copy[index], pressed: true };
                        resetFilterBy(copy);
                      }}
                    >
                      <HStack
                        height={16}
                        justifyContent={"space-between"}
                        alignItems="center"
                        pl={2}
                      >
                        <Text
                          fontSize={16}
                          style={{
                            fontFamily: "HK_600SemiBold",
                          }}
                          textAlign={"center"}
                          color={item.pressed ? "white" : "#94A3B8"}
                        >
                          {item.label}

                          {"   "}
                          {[
                            // {
                            //   label: "Age",
                            //   filter: ageFilter,
                            // },
                            {
                              label: "Skin Type",
                              filter: selectedSkinType,
                            },
                            {
                              label: "Hair Type",
                              filter: selectedHairType,
                            },
                            {
                              label: "Hair Texture",
                              filter: selectedHairTexture,
                            },
                            {
                              label: "Ethnicity",
                              filter: selectedEthnicity,
                            },
                          ].map(
                            (i, k) =>
                              i?.filter?.length > 0 &&
                              index === k && (
                                <Center>
                                  <Box
                                    bg={"#8D49DE"}
                                    height={1.5}
                                    width={1.5}
                                    rounded="full"
                                  ></Box>
                                </Center>
                              )
                          )}
                        </Text>

                        <Divider
                          orientation="vertical"
                          bg={item.pressed ? "#8D49DE" : "#202427"}
                          thickness="3"
                        />
                      </HStack>
                    </Pressable>
                  )}
                />
              </View>
              <View w={"65%"} bg={"#2B2F33"}>
                <FlatList
                  contentContainerStyle={{
                    flexDirection: "row",
                  }}
                  data={filterBy}
                  renderItem={({ item }) => {
                    return item?.label === "Ethnicity" ? (
                      <HStack justifyContent={"center"}>
                        {item?.pressed && (
                          <Ethnicity
                            selectedEthnicity={selectedEthnicity}
                            setSelectedEthnicity={setSelectedEthnicity}
                          />
                        )}
                      </HStack>
                    ) : item?.label === "Hair Type" ? (
                      <HStack justifyContent={"center"}>
                        {item?.pressed && (
                          <HairType
                            selectedHairType={selectedHairType}
                            setSelectedHairType={setSelectedHairType}
                          />
                        )}
                      </HStack>
                    ) : item.label === "Hair Texture" ? (
                      <HStack justifyContent={"center"}>
                        {item?.pressed && (
                          <HairTexture
                            selectedHairTexture={selectedHairTexture}
                            setSelectedHairTexture={setSelectedHairTexture}
                          />
                        )}
                      </HStack>
                    ) : item.label === "Skin Type" ? (
                      <HStack justifyContent={"center"}>
                        {item?.pressed && (
                          <SkinType
                            selectedSkinType={selectedSkinType}
                            setSelectedSkinType={setSelectedSkinType}
                          />
                        )}
                      </HStack>
                    ) : null;
                  }}
                />
              </View>
            </HStack>
          </VStack>

          <CommunityFilterPopUpBottomBar
            onPressApplyFilter={onPressApplyFilter}
          />
        </Slide>
      </Modal>
    </>
  );
};

export default CommunityFilterPopUp;
