import { Text } from "native-base";

const Error = ({ error }) => {
  return (
    <Text
      fontSize={12}
      style={{
        fontFamily: "HK_600SemiBold",
      }}
      color="#ff0000"
    >
      {error}!
    </Text>
  );
};

export default Error;
