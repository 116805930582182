import { Button, useColorModeValue } from "native-base";

const GrayBorderButton = ({ onPress, text }) => {
  const backgroundColor = useColorModeValue("white", "#111315");
  return (
    <Button
      onPress={onPress}
      width={"full"}
      height={"full"}
      rounded={"10px"}
      variant={"outline"}
      borderWidth={2}
      borderColor={"#58595B"}
      _pressed={{
        backgroundColor: backgroundColor,
      }}
      _text={{
        fontSize: 14,
        color: "white",
        textAlign: "center",
        fontFamily: "HK_500Medium",
      }}
    >
      {text}
    </Button>
  );
};

export default GrayBorderButton;
