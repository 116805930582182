import CheckoutHOC from "../../hoc/CheckoutHOC";
import CheckoutScreen from "./checkoutScreen";

const CheckoutScreenWrapper = (props) => {
  return (
    <CheckoutHOC {...props}>
      <CheckoutScreen {...props} />
    </CheckoutHOC>
  );
};

export default CheckoutScreenWrapper;
