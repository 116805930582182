import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const WebSearchIcon = ({ color = "#464646", size }) => {
  return (
    <Icon viewBox="0 0 13 12">
      <G>
        <Path
          d="M8.97996 7.54717H8.44245L8.25194 7.36192C8.94177 6.55508 9.3209 5.52501 9.32015 4.45969C9.32015 3.57765 9.06078 2.71541 8.57482 1.98202C8.08887 1.24863 7.39816 0.677019 6.59005 0.339476C5.78194 0.00193231 4.89272 -0.0863844 4.03483 0.0856937C3.17695 0.257772 2.38893 0.682516 1.77043 1.30621C1.15193 1.92991 0.730721 2.72455 0.560077 3.58965C0.389433 4.45474 0.477014 5.35144 0.811744 6.16634C1.14648 6.98124 1.71332 7.67775 2.4406 8.16779C3.16788 8.65783 4.02293 8.91938 4.89763 8.91938C5.99305 8.91938 7.00003 8.51458 7.77567 7.8422L7.95938 8.03431V8.57633L11.3613 12L12.3751 10.9777L8.97996 7.54717ZM4.89763 7.54717C3.20346 7.54717 1.83588 6.1681 1.83588 4.45969C1.83588 2.75129 3.20346 1.37221 4.89763 1.37221C6.59179 1.37221 7.95938 2.75129 7.95938 4.45969C7.95938 6.1681 6.59179 7.54717 4.89763 7.54717Z"
          fill={color}
        />
      </G>
    </Icon>
  );
};

export default WebSearchIcon;
