import {
  SET_HOME,
  ADD_HOME
} from './constants';


const initialState = {
  groups:[],
  products:[],
  people:[],
  trending:[],
  page:1,
  filters: {
  }
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REFRESH':
      return {
        groups:[],
        products:[],
        people:[],
        trending:[],
        page:1,
        filters: {
        }
      }
    case SET_HOME:
      return {
        ...state,
        groups:action.groups,
        products: action.products,
        people:action.people,
        trending:action.trending,
        page: 1
      };
      case ADD_HOME:
        return {
          ...state,
          groups:[...state.groups,...action.groups],
          products: [...state.products, ...action.products],
          people: [...state.people, ...action.people],
          page: action.page
        };
    default:
      return state;
  }
};

export default homeReducer;
