import { Input, Stack } from "native-base";
import SearchIconOutlined from "../icons/searchIconOutlined";
import { setSearch } from "../../redux/search/actions";
import { useDispatch, useSelector } from "react-redux";
import { setAnalytics } from "../../analytics";

const CollectionsSearchBar = ({ search, setSearch }) => {
  return (
    <Stack mt={"25px"} px={"5px"} w="100%">
      <Input
        h="50px"
        borderWidth={"1px"}
        borderColor={"white"}
        rounded={"8px"}
        InputRightElement={
          <Stack px={4}>
            <SearchIconOutlined size={4} />
          </Stack>
        }
        color="white"
        _focus={{
          borderWidth: "2px",
          borderColor: "#888990",
          backgroundColor: "black",
          color: "white",
        }}
        onChangeText={(val) => {
          setAnalytics("search bar", "search bar clicked", {
            text: val,
          });
          setSearch(val);
        }}
        autoCapitalize="none"
        fontSize={16}
        lineHeight={24}
        style={{
          fontFamily: "HK_400Regular",
        }}
        value={search}
        placeholder={`Search Products`}
      />
    </Stack>
  );
};

export default CollectionsSearchBar;
