import {
  Center,
  HStack,
  Image,
  PresenceTransition,
  Pressable,
  Text,
} from "native-base";
import { useState } from "react";
import { getDate } from "../../../util/utilFunctions";
import ChevronDownArrowIcon from "../../icons/chevronDownArrowIcon";
import ChevronUpArrowIcon from "../../icons/chevronUpArrowIcon";

const AffiliateOrderProductCard = ({ data }) => {
  const [showOrderDetails, setShowOrderDetails] = useState();

  return (
    <>
      <Pressable
        onPress={() => {
          setShowOrderDetails(!showOrderDetails);
        }}
      >
        <HStack alignItems={"center"} justifyContent="space-between">
          <HStack alignItems={"center"} space={2} width={"40%"}>
            <Center size={4}>
              {showOrderDetails ? (
                <ChevronUpArrowIcon size={2} />
              ) : (
                <ChevronDownArrowIcon size={2} />
              )}
            </Center>
            <Image
              bgColor={"white"}
              size={"30px"}
              rounded={"10px"}
              resizeMethod="scale"
              resizeMode="contain"
              source={{
                uri: data?.product?.images[0],
              }}
              alt=""
            />
            <Text
              style={{ fontFamily: "HK_500Medium" }}
              fontSize={"13px"}
              lineHeight={16}
              _light={{
                color: "#464646",
              }}
              _dark={{
                color: "white",
              }}
              noOfLines={2}
            >
              {data?.product?.name}
            </Text>
          </HStack>

          <Text
            w={"8%"}
            style={{ fontFamily: "HK_500Medium" }}
            fontSize={"16px"}
            lineHeight={"20px"}
            _light={{
              color: "#464646",
            }}
            _dark={{
              color: "white",
            }}
            letterSpacing={"0.3px"}
          >
            ${data?.total_product_earnings}
          </Text>
        </HStack>
        {data?.product_affiliate_rewards?.length > 0 && showOrderDetails && (
          <PresenceTransition
            visible={showOrderDetails}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 500,
              },
            }}
          >
            {data?.product_affiliate_rewards?.length > 0 &&
              data?.product_affiliate_rewards?.map((item) => (
                <HStack
                  pt={3}
                  alignItems={"center"}
                  justifyContent="space-between"
                  pl={8}
                  pr={4}
                  w="full"
                >
                  <Text
                    style={{ fontFamily: "HK_400Regular" }}
                    fontSize={"13px"}
                    _light={{
                      color: "#8F8F8F",
                    }}
                    _dark={{
                      color: "#94A3B8",
                    }}
                  >
                    {getDate(item?.created_at)}
                  </Text>
                  <Text
                    _light={{
                      color: "#8F8F8F",
                    }}
                    _dark={{
                      color: "#94A3B8",
                    }}
                    textAlign={"right"}
                    style={{ fontFamily: "HK_500Medium" }}
                    fontSize={"13px"}
                  >
                    ${item?.points}
                  </Text>
                </HStack>
              ))}
          </PresenceTransition>
        )}
      </Pressable>
    </>
  );
};

export default AffiliateOrderProductCard;
