import { Box, FlatList, Stack } from "native-base";
import { useEffect, useState } from "react";
import * as Contacts from "expo-contacts";
import InviteSmsCard from "../../../components/cards/inviteSmsCard";
import GradientText from "../../../components/gradientText";
const InviteFriendsOnFlock = ({ data }) => {
  const [contacts, setContacts] = useState();
  useEffect(() => {
    getContacts();
  }, [data]);

  const getContacts = async () => {
    const { data: numbers } = await Contacts.getContactsAsync({
      fields: [Contacts.Fields.PhoneNumbers],
    });
    setContacts(numbers);
  };

  const renderItem = ({ item }) => <InviteSmsCard item={item} />;

  return (
    <>
      <FlatList
        ListHeaderComponent={
          data && (
            <Stack pb={"12px"} h="42px">
              <GradientText
                text={`From contacts (${data?.length})`}
              ></GradientText>
            </Stack>
          )
        }
        px={"21px"}
        flex={1}
        ItemSeparatorComponent={<Box h={6}></Box>}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        initialNumToRender={2}
        data={contacts
          ?.map((k) =>
            [
              ...new Set(
                k?.phoneNumbers?.map((g) =>
                  g?.number?.replace(/\s/g, "").split("-").join("")
                )
              ),
            ].map((c) => {
              if (data?.includes(c)) {
                return {
                  name: k?.name,
                  number: c,
                };
              }
            })
          )
          .flatMap((j) => j)
          .filter((k) => k)}
        renderItem={renderItem}
      />
    </>
  );
};

export default InviteFriendsOnFlock;
