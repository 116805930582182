import { ScrollView, Text, VStack } from "native-base";
import BottomBar from "../../components/bars/navigationBars/bottomBar";

const Shipping = () => {
  return (
    <>
      <ScrollView zIndex={0} width="100%" showsVerticalScrollIndicator={false}>
        <VStack space={8} pb={4} px={4} pt={2}>
          <Text
            style={{
              fontFamily: "HK_500Medium",
            }}
            fontSize={16}
            lineHeight={22}
            color="white"
          >
            {`Orders will be processed within 1-2 business days. Shipping times vary by state after processing. Please allow 1-5 business days for delivery. However, due to delays related to demand and COVID-19, Flock orders may experience delays. 

Standard business day shipping is FREE on all orders. 

Hawaii & Alaska
We currently only offer shipping to the continental US. We aim to open up shipping to other locations. Please check back for updates! 

Parcel Forwarding Services
If you choose to use a parcel forwarding company, we will not be liable for any damage, defect, or loss that occurs. We will not offer replacements or refunds for orders or items that are reported missing or damaged that we reasonably believe to have been delivered to a parcel forwarding company. Any order addressed to a parcel forwarding company is placed at your own risk. Sephora is not responsible for lost or stolen packages.

Multiple Shipping Addresses
We are unable to ship to multiple addresses in a single order. To ship to multiple addresses, we recommend placing a separate order for each address.

Restricted Hazardous Items
Certain items, including aerosols and alcohol-based products (e.g. pressurized spray cans, hairspray, nail polish remover, etc.) are restricted from air-transport due to U.S. Department of Transportation regulations and must be shipped by ground shipping only and will potentially take additional time to arrive. Please reference your estimated delivery date during checkout and post-purchase notifications for estimated delivery.

Business Days & Holidays
All orders process and ship Monday - Friday, excluding federal holidays within the United States. Based on the shipping method selected at checkout, if the order is placed after the cut-off time, it will be processed the following business day.

Packages do not ship from our distribution centers on weekends or the following US holidays:

New Year’s Day
Martin Luther King Jr. Day
President's Day
Memorial Day
4th of July
Labor Day
Columbus Day
Veteran's Day
Thanksgiving
Christmas

Tracking Orders
When your order is ready to be shipped, you will receive a shipment confirmation email. Most of our orders are shipped within 1-2 business days after the order has been placed. The email will contain shipment details and your tracking number. To track the order, click on the tracking number located near the bottom of the email. To track an order on Flock, visit the Account Information area. You will need to sign in to access your account information. Title to the products in your order is transferred from Flock to you when your credit card is charged and your products are shipped. Risk of loss with respect to the products in your order is transferred from Flock to you when your order is delivered to you. This transfer of title does not affect our returns policy. 

For your convenience, you will see the expected delivery date for your order when you select a delivery option, on the Order Confirmation page, on the Order Details page, and on the Order Confirmation email.
`}
          </Text>
        </VStack>
      </ScrollView>
      <BottomBar />
    </>
  );
};

export default Shipping;
