import { useEffect, useState } from "react";
const COUNTRYCODEMAP = {
  IN: "91",
  US: "1",
  PK: "92",
};
export const useGetCountryCodeWeb = () => {
  const [countryCode, setCountryCode] = useState();

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          setCountryCode(COUNTRYCODEMAP["IN"]);
        } else {
          setCountryCode(COUNTRYCODEMAP[response?.country_code]);
        }
      })
      .catch((data, status) => {});
  }, []);

  return countryCode;
};
