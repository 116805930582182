import { useNavigation, useRoute } from "@react-navigation/native";
import { Text, Button, Center } from "native-base";
import { useSelector } from "react-redux";
import ProductCard from "../../../components/cards/product/productCard";
import ColumnLayout from "../../../components/layouts/columnLayout";
import { Pages } from "../../../constants/pages";
import useGetFavorites from "../../../hooks/useGetFavorites";
import { useGetProductDetails } from "../../../hooks/useGetProductDetails";

const Favorites = ({ userId }) => {
  const route = useRoute();
  const { data: favorites } = useGetFavorites(userId);
  const navigation = useNavigation();

  console.log("favorites", favorites);
  const renderItem = ({ item, index }) => (
    <Products item={item} index={index} userId={userId} />
  );
  return (
    <>
      {favorites?.length > 0 ? (
        <ColumnLayout
          defaultNumberOfColumns={route?.params?.type === "profile" ? 3 : 4}
          data={favorites}
          renderItem={renderItem}
        />
      ) : route?.params?.type === "profile" ? (
        <Center w="full">
          <Text
            textAlign={"center"}
            fontSize={14}
            lineHeight={16}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
            color="#58595B"
          >
            You haven't added any Favories yet!
          </Text>
          <Button
            onPress={() => navigation.navigate(Pages.ShopScreen)}
            py={0}
            variant={"ghost"}
            _text={{
              color: "#1A94FF",
              fontSize: 14,
              lineHeight: "17px",
              fontFamily: "HK_600SemiBold",
            }}
          >
            Browse Products
          </Button>
        </Center>
      ) : (
        <Center w="full">
          <Text
            textAlign={"center"}
            fontSize={14}
            lineHeight={16}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
            color="#58595B"
          >
            Not added any Favories yet!
          </Text>
        </Center>
      )}
    </>
  );
};

const Products = ({ item, index, userId }) => {
  const ownUserId = useSelector((state) => state.user.id);
  const { product } = useGetProductDetails(item?.product?.id);
  return (
    <Center m={1.5}>
      {item?.id && item?.id !== null && (
        <ProductCard
          variantLength={
            [product?.sizes, product?.colors, product?.scents, product?.types]
              ?.filter((k) => k)
              .flatMap((f) => f).length
          }
          source={userId === ownUserId ? null : "wishlist"}
          otherUserId={userId === ownUserId ? null : userId}
          productImage={item?.images[0]}
          retailPrice={item?.retail_price}
          variants={[item?.color, item?.size, item?.scent]}
          productName={item?.product_name}
          brandName={item?.brand_name}
          productId={item?.product?.id}
          productSkuId={item?.id}
          key={index}
        />
      )}
    </Center>
  );
};
export default Favorites;
