import { Center, Image, ScrollView, Text, VStack } from "native-base";
import { Platform } from "react-native";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";

const AboutFlockScreen = () => {
  return (
    <>
      {Platform.OS === "web" ? (
        <>
          <VStack space={8} pb={4} px={6} pt={4} flex={1}>
            <Text
              _dark={{
                color: "white",
              }}
              _light={{
                color: '"#464646"',
              }}
              style={{
                fontFamily: "Hero_400Regular",
              }}
              fontSize={16}
              lineHeight={22}
            >
              We know the struggles of finding the right foundation shade. The
              worry if this skincare is ok to use if you have acne. The process
              of finding the right products, trying to find real reviews, and
              saving money seems never-ending!{"\n\n"} Flock is built from all
              of your community feedback. We are excited to partner with you to
              create a better way of shopping online that works for all skin.
              {"\n\n"} Here’s to ~the~ social, easy way to shop for beauty and
              wellness.
            </Text>
            <Text
              textAlign={"right"}
              style={{
                fontFamily: "Hero_700Bold",
              }}
              fontSize={16}
              lineHeight={22}
              _dark={{
                color: "white",
              }}
              _light={{
                color: '"#464646"',
              }}
              letterSpacing={3}
            >
              -VITA & MALAVIKA{"\n"}
              <Text
                textAlign={"right"}
                style={{
                  fontFamily: "HK_500Medium",
                }}
                fontSize={16}
                lineHeight={22}
                color="white"
                letterSpacing={0}
              >
                Co-Founders, Flock
              </Text>
            </Text>
          </VStack>
          <FooterSectionWeb />
        </>
      ) : (
        <>
          <ScrollView
            zIndex={0}
            width="100%"
            showsVerticalScrollIndicator={false}
          >
            <VStack space={8} pb={4} px={6} pt={4}>
              <Center>
                <Image
                  resizeMethod="scale"
                  resizeMode="contain"
                  source={require("./../../assets/images/pngs/flockPurpleLogo.png")}
                  alt=""
                />
              </Center>
              <Text
                style={{
                  fontFamily: "HK_500Medium",
                }}
                fontSize={16}
                lineHeight={22}
                color="white"
              >
                We know the struggles of finding the right foundation shade. The
                worry if this skincare is ok to use if you have acne. The
                process of finding the right products, trying to find real
                reviews, and saving money seems never-ending!{"\n\n"} Flock is
                built from all of your community feedback. We are excited to
                partner with you to create a better way of shopping online that
                works for all skin.{"\n\n"} Here’s to ~the~ social, easy way to
                shop for beauty and wellness.
              </Text>
              <Text
                textAlign={"right"}
                style={{
                  fontFamily: "HK_700Bold",
                }}
                fontSize={16}
                lineHeight={22}
                color="white"
                letterSpacing={3}
              >
                -VITA & MALAVIKA{"\n"}
                <Text
                  textAlign={"right"}
                  style={{
                    fontFamily: "HK_500Medium",
                  }}
                  fontSize={16}
                  lineHeight={22}
                  color="white"
                  letterSpacing={0}
                >
                  Co-Founders, Flock
                </Text>
              </Text>
            </VStack>
          </ScrollView>
          <BottomBar />
        </>
      )}
    </>
  );
};

export default AboutFlockScreen;
