import {
  Center,
  Divider,
  HStack,
  IconButton,
  Input,
  ScrollView,
  Stack,
  Text,
  VStack,
  ZStack,
} from "native-base";
import { Dimensions, Linking, Pressable } from "react-native";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import SendUsAMessageButton from "../../components/buttons/sendUsAMessageButton";
import BlueClockIconOutlined from "../../components/icons/blueClockIconOutlined";
import BlueHeadPhoneIcon from "../../components/icons/blueHeadPhoneIcon";
import ChatEllipseIcon from "../../components/icons/chatEllipseIcon";
import MessageIconOutlined from "../../components/icons/messageIconOutlined";
import PurpleChevronRightIcon from "../../components/icons/purpleChevronRightIcon";
import ChevronLeftArrowIcon from "../../components/icons/chevronLeftArrowIcon";
import { useSelector } from "react-redux";

const HelpAndSupportScreen = () => {
  const userValue = useSelector((state) => state.user);

  return (
    <>
      <ZStack>
        <VStack
          px={6}
          pt={4}
          // pb={6}

          h={Dimensions.get("screen").height / 5}
          width="100%"
          bg={{
            linearGradient: {
              colors: [
                "primary.ctaLinearSecondColor",
                "primary.ctaLinearFirstColor",
              ],
              start: [0, 0],
              end: [1, 0],
            },
          }}
        >
          <Text
            style={{
              fontFamily: "HK_600SemiBold",
            }}
            fontSize={32}
            lineHeight={48}
            color="white"
          >
            Hi {userValue?.username} 👋
          </Text>
          <Text
            style={{
              fontFamily: "HK_500Medium",
            }}
            fontSize={16}
            lineHeight={24}
            color="white"
          >
            Let us know if we can help you with anything at all.
          </Text>
        </VStack>
        <VStack
          space={"20px"}
          pb={4}
          alignItems="center"
          pt={10}
          px={4}
          mt={Dimensions.get("screen").height / 9}
          width="full"
        >
          {/* <VStack bg="#202427" rounded={10} width={"full"}>
            <VStack p={4} space={3}>
              <Text
                style={{
                  fontFamily: "HK_600SemiBold",
                }}
                fontSize={18}
                lineHeight={27}
                color="white"
              >
                Start a conversation
              </Text>
              <HStack alignItems={"center"} space={4}>
                <Center position={"relative"}>
                  <ChatEllipseIcon />
                  <Center position={"absolute"}>
                    <MessageIconOutlined />
                  </Center>
                </Center>
                <VStack space={1}>
                  <Text
                    style={{
                      fontFamily: "HK_400Regular",
                    }}
                    fontSize={14}
                    lineHeight={18.24}
                    color="#94A3B8"
                  >
                    Our usual reply time
                  </Text>
                  <HStack alignItems={"center"} space={1.5}>
                    <BlueClockIconOutlined />
                    <Text
                      style={{
                        fontFamily: "HK_600SemiBold",
                      }}
                      fontSize={14}
                      lineHeight={16.8}
                      color="white"
                    >
                      A few minutes
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
              <HStack>
                <SendUsAMessageButton />
              </HStack>
            </VStack>
            <Divider bg="#58595B" />
            <HStack alignItems={"center"} p={4}>
              <Text
                style={{
                  fontFamily: "HK_400Regular",
                }}
                fontSize={14}
                lineHeight={18.2}
                color="white"
              >
                See all your conversations
              </Text>
            </HStack>
          </VStack> */}
          {/* <VStack bg="#202427" rounded={10} p={4} width={"full"} space={3}>
            <Text
              style={{
                fontFamily: "HK_600SemiBold",
              }}
              fontSize={18}
              lineHeight={27}
              color="white"
            >
              Drop us your Query, we’ll e-mail you!
            </Text>
            <Input
              borderColor="#58595B"
              borderWidth={1}
              autoCapitalize='none'
              rounded={4}
              placeholderTextColor={"#94A3B8"}
              fontSize={14}
              lineHeight={20}
              style={{
                fontFamily: "HK_400Regular",
              }}
              placeholder="Enter your query here"
              _focus={{
                borderColor: "#58595B",
                backgroundColor: "#1A1C1E",
                color: "white",
              }}
              InputRightElement={
                <Stack bg="#8D49DE">
                  <PurpleChevronRightIcon />
                </Stack>
              }
            />
          </VStack> */}

          <HStack
            w="100%"
            bg="#202427"
            rounded={10}
            p={4}
            justifyContent="space-between"
          >
            <HStack space={3}>
              <BlueHeadPhoneIcon />
              <VStack space={1}>
                <Text
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                  fontSize={14}
                  lineHeight={21}
                  color="#94A3B8"
                >
                  Email us
                </Text>
                <Text
                  onPress={() => {
                    // Linking.openURL("tel:98765 43210");
                    Linking.openURL(
                      "mailto:hi@flockshopping.com?subject=SendMail&body=Description"
                    );
                  }}
                  style={{
                    fontFamily: "HK_400Regular",
                  }}
                  fontSize={16}
                  lineHeight={24}
                  color="white"
                >
                  hi@flockshopping.com
                </Text>
                {/* <Text
                  style={{
                    fontFamily: "HK_400Regular",
                  }}
                  fontSize={12}
                  lineHeight={18}
                  color="#94A3B8"
                >
                  9AM - 5PM
                </Text> */}
              </VStack>
            </HStack>
            <HStack alignSelf={"center"}>
              <IconButton
                onPress={() => {
                  // Linking.openURL("tel:98765 43210");
                  Linking.openURL(
                    "mailto:hi@flockshopping.com?subject=SendMail&body=Description"
                  );
                }}
                icon={<ChevronLeftArrowIcon />}
              ></IconButton>
            </HStack>
          </HStack>
        </VStack>
      </ZStack>
      <ScrollView></ScrollView>
      <BottomBar />
    </>
  );
};

export default HelpAndSupportScreen;
