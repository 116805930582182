import { STORE_FOLLOWING, ADD_FOLLOWING, DELETE_FOLLOWING } from "./constants";

const initialState = [];

const followingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REFRESH":
      return [];
    case STORE_FOLLOWING:
      return {
        ...createFollowing(action.people),
      };
    case ADD_FOLLOWING:
      return {
        ...state,
        ...add(action.person),
      };
    case DELETE_FOLLOWING:
      return {
        ...deleteItem(state, action.person),
      };

    default:
      return state;
  }
};

const createFollowing = (people) => {
  const el = {};
  for (let it in people) {
    el[people[it]] = true;
  }

  return el;
};

const add = (id) => {
  const el = {};
  el[id] = true;
  return el;
};

const deleteItem = (following, id) => {
  const s = { ...following };
  delete s[id];
  return { ...s };
};
export default followingReducer;
