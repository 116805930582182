import {
  HStack,
  VStack,
  Text,
  Image,
  Center,
  Pressable,
  IconButton,
} from "native-base";
import QuantityButton from "../../buttons/quantityButton";
import { useDeleteFromCart } from "../../../hooks/useDeleteFromCart";
import { Swipeable } from "react-native-gesture-handler";
import TrashRedIcon from "../../icons/trashRedIcon";
import { useNavigation, useRoute } from "@react-navigation/native";
import { BrandImages } from "../../../constants/brands";
import { Pages } from "../../../constants/pages";
import { setAnalytics } from "../../../analytics";
import QuantityButtonHOC from "../../../hoc/QuantityButtonHOC";
import FullWidthAccordion from "../../accordions/fullWidthAccordion";
import { useGetProductDetails } from "../../../hooks/useGetProductDetails";

const CartCard = ({ product, variants, previousNum }) => {
  const navigation = useNavigation();
  const route = useRoute();
  const deleteFromCart = useDeleteFromCart();
  const { product: productDetails } = useGetProductDetails(
    product?.product?.id
  );
  const map = {
    Color: productDetails?.colors,
    Size: productDetails?.sizes,
    Scent: productDetails?.scents,
    Type: productDetails?.types,
  };

  return (
    <>
      {product ? (
        <>
          <Pressable
            onPress={() => {
              setAnalytics("CartCard", "Product Card In The Cart Clicked", {
                productId: product?.product.id,
                productSkuId: product?.id,
                screen: route.name,
              });

              navigation.navigate(Pages.ProductScreen, {
                id: product?.product.id,
                sku_id: product?.id,
              });
            }}
          >
            <Swipeable
              renderRightActions={() => (
                <HStack
                  justifyContent={"center"}
                  alignItems={"center"}
                  width="90px"
                  roundedRight="17px"
                  h="138px"
                  bg="#3A1F1F"
                >
                  <IconButton
                    onPress={() => {
                      deleteFromCart(product.id);
                      setAnalytics("Remove From cart", "Removed from Cart", {
                        productId: product.id,
                      });
                    }}
                    size={"16"}
                    _pressed={{
                      bg: "#3A1F1F",
                    }}
                    icon={<TrashRedIcon />}
                  ></IconButton>
                </HStack>
              )}
            >
              <HStack
                flex={1}
                rounded="17px"
                h="138px"
                space={4}
                width="100%"
                _light={{
                  bg: "#FAF1FF",
                }}
                _dark={{
                  bg: "#202427",
                }}
                px={4}
                py={5}
              >
                <VStack position={"relative"} alignItems="center">
                  <Image
                    resizeMethod="scale"
                    resizeMode="stretch"
                    w={"94px"}
                    h="88.44px"
                    rounded="10px"
                    source={{
                      uri: product && product.images && product.images[0],
                    }}
                    alt=""
                  />

                  <Center top="70%" position={"absolute"}>
                    <Center h="40px" w="88px">
                      <QuantityButtonHOC>
                        <QuantityButton skuId={product.id} />
                      </QuantityButtonHOC>
                    </Center>
                  </Center>
                </VStack>
                <VStack flex={1} space={3} alignSelf="flex-start">
                  <VStack space={2}>
                    <HStack space={2}>
                      <Image
                        size={4}
                        rounded={"full"}
                        resizeMethod="scale"
                        resizeMode="contain"
                        source={{
                          uri: BrandImages[product.brand_name],
                        }}
                        alt=""
                      />

                      <Text
                        noOfLines={1}
                        textTransform={"uppercase"}
                        _light={{
                          color: "#464646",
                        }}
                        _dark={{
                          color: "#94A3B8",
                        }}
                        _web={{
                          style: {
                            fontFamily: "Hero_700Bold",
                          },
                        }}
                        letterSpacing={2}
                        fontSize={"10px"}
                        lineHeight={16}
                        style={{
                          fontFamily: "HK_600SemiBold",
                        }}
                      >
                        {product.brand_name}
                      </Text>
                    </HStack>
                    <Text
                      noOfLines={1}
                      _light={{
                        color: "#682481",
                      }}
                      _dark={{
                        color: "white",
                      }}
                      _web={{
                        style: {
                          fontFamily: "AustinCyr_400",
                        },
                      }}
                      fontSize={"14px"}
                      style={{
                        fontFamily: "HK_400Regular",
                      }}
                      lineHeight={18}
                    >
                      {product.name ? product.name : product.product_name}
                    </Text>
                  </VStack>
                  <HStack space={"4px"} flexWrap={"wrap"}>
                    {variants
                      ?.filter(
                        (item, index) => item !== undefined && item !== null
                      )
                      .map((k, i) => (
                        <>
                          {map[k?.label] && (
                            <FullWidthAccordion
                              previousSkuId={product.id}
                              previousNum={previousNum}
                              id={product?.product?.id}
                              type="card"
                              label={k?.label}
                              items={map[k?.label]}
                              val={k?.variant}
                            />
                          )}
                        </>
                      ))}
                  </HStack>
                </VStack>

                <VStack alignItems={"center"} justifyContent="center">
                  <Text
                    _web={{
                      style: {
                        fontFamily: "Hero_700Bold",
                      },
                      fontSize: "20px",
                    }}
                    _light={{
                      color: "#682481",
                    }}
                    _dark={{
                      color: "white",
                    }}
                    style={{
                      fontFamily: "HK_700Bold",
                    }}
                    fontSize={"16px"}
                  >
                    $
                    {product?.deal
                      ? product?.deal_price?.toFixed(2)
                      : product?.retail_price?.toFixed(2)}
                  </Text>
                </VStack>
              </HStack>
            </Swipeable>
          </Pressable>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CartCard;
