import { Button } from "native-base";

const ClaimedButton = () => {
  return (
    <Button
      width={"100px"}
      _dark={{
        bg: "#2B2F33",
      }}
      _light={{
        bg: "white",
      }}
      opacity={0.7}
      rounded={10}
      h="40px"
      _text={{
        fontWeight: 600,
        fontSize: 14,
        _dark: {
          color: "#94A3B8",
        },
        _light: {
          color: "#464646",
        },

        fontFamily: "HK_600SemiBold",
      }}
    >
      Claimed
    </Button>
  );
};

export default ClaimedButton;
