import { HStack, Image, Pressable, Text, VStack } from "native-base";
import TrendingFlatAnimatedIcon from "../icons/animatedIcons/trendingFlatAnimatedIcon";
import UserFollowIcon from "../icons/userFollowIcon";

const ReviewCard = () => {
  return (
    <>
      <VStack
        p={2}
        bg="#202427"
        borderRadius={"10px"}
        h={{
          md: "179px",
          lg: "179px",
          xl: "179px",
        }}
        w={{
          md: "279px",
          lg: "279px",
          xl: "279px",
        }}
        justifyContent="space-around"
      >
        <VStack space={3}>
          <HStack space={2}>
            <HStack alignItems={"center"} space={2}>
              <Image
                bg="white"
                borderRadius={"20px"}
                size={"16px"}
                resizeMethod="scale"
                resizeMode="contain"
                source={{
                  uri: "https://resized-c-im.s3.amazonaws.com/test2/92977dacd0cc791fbcfab227faed943c/066d9f04180d5087f84818f2b323cc07/1.jpg",
                }}
                alt=""
              />
              <Text
                textAlign={"center"}
                fontSize={"11px"}
                color="white"
                style={{
                  fontFamily: "HK_600SemiBold",
                }}
              >
                Mineral Sunsilk Moisturizer SPF30 by Coola
              </Text>
            </HStack>
            <TrendingFlatAnimatedIcon />
          </HStack>
          <Text
            fontSize={"11px"}
            color="white"
            style={{
              fontFamily: "HK_400Regular",
            }}
          >
            I love Kiehl’s! It's a great product for the quality and how it
            affects my skin. I've tried other products that are more expensive,
            but they didn't give me the same results.
          </Text>
        </VStack>
        <VStack w="full">
          <HStack bg="#2B2F33" borderRadius={"8px"} px="6px" py="5px" w="full">
            <HStack
              alignItems={"center"}
              w="full"
              justifyContent={"space-between"}
            >
              <HStack space={2} alignItems={"center"}>
                <Image
                  bg="white"
                  borderRadius={"20px"}
                  size={"20px"}
                  resizeMethod="scale"
                  resizeMode="contain"
                  source={{
                    uri: "https://resized-c-im.s3.amazonaws.com/test2/92977dacd0cc791fbcfab227faed943c/066d9f04180d5087f84818f2b323cc07/1.jpg",
                  }}
                  alt=""
                />
                <Text
                  textAlign={"center"}
                  fontSize={"13px"}
                  color="white"
                  style={{
                    fontFamily: "HK_500Medium",
                  }}
                >
                  @Mineral
                </Text>
              </HStack>
              <Pressable>
                <HStack
                  w="74px"
                  px={3}
                  height="24px"
                  justifyContent="space-around"
                  alignItems="center"
                  space={1}
                  bg={{
                    linearGradient: {
                      colors: [
                        "primary.ctaLinearSecondColor",
                        "primary.ctaLinearFirstColor",
                      ],
                      start: [0, 0],
                      end: [1, 0],
                    },
                  }}
                  rounded={"4px"}
                  _text={{
                    color: "#ffffff",
                  }}
                >
                  <UserFollowIcon />
                  <Text
                    color="white"
                    fontSize={10}
                    style={{
                      fontFamily: "Poppins_500Medium",
                    }}
                  >
                    {false ? "Following" : "Follow"}
                  </Text>
                </HStack>
              </Pressable>
            </HStack>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default ReviewCard;
