import { useNavigation } from "@react-navigation/native";
import { Box, SectionList, VStack } from "native-base";
import ViewMoreTopBar from "../../../components/bars/topBars/viewMoreTopBar";
import CollectionCard from "../../../components/cards/collectionCard";
import { Pages } from "../../../constants/pages";
import useGetProducts from "../../../hooks/useGetProducts";

const Collections = ({ celebId }) => {
  const { data } = useGetProducts("collectionProducts", {}, celebId);
  const navigation = useNavigation();
  const renderItem = ({ item }) => (
    <CollectionCard item={item} bundleId={data && data[0]?.id} />
  );

  const collection = [
    {
      name: "Skin care",
      product_skus:
        data &&
        data[0]?.products
          ?.filter(
            (k, i) =>
              k !== undefined && k !== null && k.product_type === "Skincare"
          )
          ?.map((g) => g),
      onPress: () =>
        navigation.navigate(Pages.CategoryScreen, {
          fetcherType: "collectionProducts",
          celebId: celebId,
          source: "wishlist",
          otherUserId: celebId,
          data:
            data &&
            data[0]?.products
              ?.filter(
                (k, i) =>
                  k !== undefined && k !== null && k.product_type === "Skincare"
              )
              ?.map((g) => g),
        }),
    },
    {
      name: "Makeup",
      product_skus:
        data &&
        data[0]?.products
          ?.filter(
            (k, i) =>
              k !== undefined && k !== null && k.product_type === "Makeup"
          )
          ?.map((g) => g),
      onPress: () =>
        navigation.navigate(Pages.CategoryScreen, {
          fetcherType: "collectionProducts",
          celebId: celebId,
          source: "wishlist",
          otherUserId: celebId,
          data:
            data &&
            data[0]?.products
              ?.filter(
                (k, i) =>
                  k !== undefined && k !== null && k.product_type === "Makeup"
              )
              ?.map((g) => g),
        }),
    },
    {
      name: "Hair Care",
      product_skus:
        data &&
        data[0]?.products
          ?.filter(
            (k, i) => k !== undefined && k !== null && k.product_type === "Hair"
          )
          ?.map((g) => g),
      onPress: () =>
        navigation.navigate(Pages.CategoryScreen, {
          fetcherType: "collectionProducts",
          celebId: celebId,
          source: "wishlist",
          otherUserId: celebId,
          data:
            data &&
            data[0]?.products
              ?.filter(
                (k, i) =>
                  k !== undefined && k !== null && k.product_type === "Hair"
              )
              ?.map((g) => g),
        }),
    },
  ];

  return (
    <>
      {data?.length > 0 && (
        <VStack pl={"28px"}>
          <SectionList
            ListHeaderComponent={
              <ViewMoreTopBar
                title="Collections"
                buttonText="View all"
                onPress={() =>
                  navigation.navigate(Pages.CategoryScreen, {
                    fetcherType: "collectionProducts",
                    celebId: celebId,
                  })
                }
              />
            }
            pt={"20px"}
            flex={1}
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              paddingBottom: 14,
            }}
            sections={data ? [{ data: collection }] : []}
            ItemSeparatorComponent={() => (
              <Box style={{ paddingVertical: 20 }}></Box>
            )}
            showsVerticalScrollIndicator={false}
            stickySectionHeadersEnabled
            renderSectionHeader={(item) => <>{item.section?.header}</>}
            renderItem={renderItem}
          ></SectionList>
        </VStack>
      )}
    </>
  );
};

export default Collections;
