import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SET_DIALOG,
  SET_ACTIVE_CARD,
  SHARE_LINK,
  SET_LOGGED_IN,
  SET_LOGGED_OUT,
  SET_TYPE_OF_ADDRESS,
  GO_BACK_DIALOG,
  SET_ERROR,
  SHOW_LOADER,
  SET_BOTTOM_ICON,
  HIDE_LOADER,
  SET_PRODUCT_ORDER,
  SET_PAGE,
  SET_FILTER_PAGE,
  LOADED_PAGE,
  SET_APPLY_FILTER,
  SET_APPLY_PRODUCTS_FILTER,
  SET_REFRESH,
  UNSET_REFRESH,
  SET_NOTIFICATION_LENGTH,
  SHOW_NETWORK,
  HIDE_NETWORK,
  REFERRAL_ID,
} from "./constants";

const initialState = {
  openDialog: false,
  dialog: "",
  link: "",
  loggedIn: false,
  typeOfAddress: "",
  orderOfDialogues: [],
  error: "",
  loader: false,
  network: false,
  applyFilter: false,
  applyProductsFilter: false,
  total: 0,
  bottomIcon: "Home",
  page: 1,
  filterPage: 1,
  loadPage: false,
  notificationsLength: 0,
  showNotifications: false,
  referralId: "",
};

const currentStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REFRESH":
      return {
        openDialog: false,
        dialog: "",
        params: "",
        link: "",
        loggedIn: false,
        typeOfAddress: "",
        orderOfDialogues: [],
        error: "",
        loader: false,
        applyFilter: false,
        total: 0,
        bottomIcon: "Home",
        page: 1,
        filterPage: 1,
        loadPage: false,
      };
    case OPEN_DIALOG:
      return {
        ...state,
        openDialog: true,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.page,
        loadPage: true,
      };
    case SET_FILTER_PAGE:
      return {
        ...state,
        page: action.filterPage,
        loadPage: true,
      };
    case LOADED_PAGE:
      return {
        ...state,
        loadPage: false,
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        dialog: "",
        openDialog: false,
        orderOfDialogues: [],
      };
    case SET_APPLY_FILTER:
      return {
        ...state,
        applyFilter: !state.applyFilter,
      };
    case SET_APPLY_PRODUCTS_FILTER:
      return {
        ...state,
        applyProductsFilter: !state.applyProductsFilter,
      };
    case SET_REFRESH:
      return {
        ...state,
        refresh: true,
      };
    case UNSET_REFRESH:
      return {
        ...state,
        refresh: false,
      };
    case SET_ERROR:
      if (action.error) {
        return {
          ...state,
          error: action.error,
          loggedIn: true,
          openDialog: true,
          //dialog: Popups.Error
        };
      } else {
        return {
          ...state,
          loggedIn: true,
          openDialog: true,
          //dialog: Popups.Error
        };
      }
    case SET_BOTTOM_ICON:
      return {
        ...state,
        bottomIcon: action.bottomIcon,
      };
    case SET_PRODUCT_ORDER:
      //let arr1 = sortProducts(action.products)
      let arr1 = [];
      return {
        ...state,
        productOrder: arr1,
      };
    case GO_BACK_DIALOG:
      if (state.orderOfDialogues.length > 1) {
        return {
          ...state,
          dialog: state.orderOfDialogues[state.orderOfDialogues.length - 2],
          orderOfDialogues: state.orderOfDialogues.slice(0, -1),
        };
      } else {
        return {
          ...state,
          dialog: "",
          openDialog: false,
          orderOfDialogues: [],
        };
      }
    case SHARE_LINK:
      return {
        ...state,
        link: action.link,
      };

    case SET_DIALOG:
      return {
        ...state,
        openDialog: true,
        dialog: action.dialog,
        params: action.params,
        orderOfDialogues: [...state.orderOfDialogues, action.dialog],
      };
    case SHOW_LOADER:
      return {
        ...state,
        loader: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        loader: false,
      };
    case HIDE_NETWORK:
      return {
        ...state,
        network: false,
      };
    case SHOW_NETWORK:
      return {
        ...state,
        network: true,
      };
    case REFERRAL_ID:
      return {
        ...state,
        ...action.referralId,
      };
    case SET_ACTIVE_CARD:
      return {
        ...state,
        activeCard: action.activeCardType,
        activeCardId: action.id,
      };
    case SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: true,
      };
    case SET_LOGGED_OUT:
      return {
        ...state,
        loggedIn: false,
      };
    case SET_TYPE_OF_ADDRESS:
      return {
        ...state,
        typeOfAddress: action.typeOfAddress,
      };
    case SET_NOTIFICATION_LENGTH:
      let showNotifications =
        action.length > state.notificationsLength ? true : false;

      return {
        ...state,
        notificationsLength: action.length,
        showNotifications: showNotifications,
      };
    default:
      return state;
  }
};

export default currentStateReducer;
