import { Center, FlatList, Text, VStack } from "native-base";
import HeadingBar from "../../components/bars/headingBar";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import { Dimensions } from "react-native";
import useGetProducts from "../../hooks/useGetProducts";
import ProductCard from "../../components/cards/product/productCard";
import { useSelector } from "react-redux";

const ProductsScreen = ({ route }) => {
  const referralId = useSelector((state) => state.currentState.referralId);
  const { data } = useGetProducts(
    "specificCollectionProducts",
    {},
    undefined,
    route?.params?.collectionId
  );

  const renderItem = ({ item }) => {
    return (
      <Center m={1.5}>
        <ProductCard
          source={
            referralId
              ? "referral"
              : route?.params?.source
              ? route?.params?.source
              : "regular"
          }
          variantLength={
            [item?.size, item?.scent, item?.colors, item?.type]?.filter(
              (k) => k
            ).length
          }
          referralId={referralId}
          otherUserId={route?.params?.otherUserId}
          productImage={item && item?.images && item?.images[0]}
          groupPrice={item?.group_price}
          retailPrice={item?.retail_price}
          groupDiscount={item?.group_discount}
          productName={item?.product_name}
          brandName={item?.brand_name}
          productId={item?.product?.id}
          productSkuId={item?.id}
          bundleId={
            route?.params?.fetcherType === "collectionProducts" &&
            data &&
            data[0]?.id
          }
        />
      </Center>
    );
  };
  return (
    <VStack
      space={{
        xl: "50px",
      }}
    >
      <HeadingBar>
        <Center>
          <Text
            color="#682481"
            fontSize={"36px"}
            style={{
              fontFamily: "AustinCyr_400",
            }}
          >
            {data && data[0]?.name}
          </Text>
        </Center>
      </HeadingBar>
      <Center h={Dimensions.get("screen").height}>
        <FlatList
          columnWrapperStyle={{
            justifyContent: "center",
          }}
          showsVerticalScrollIndicator={false}
          initialNumToRender={2}
          keyExtractor={(item) => item?.id}
          ListEmptyComponent={
            <Text
              color="white"
              fontSize="16px"
              style={{
                fontFamily: "HK_700Bold",
              }}
            >
              {data?.length === 0
                ? "No products found, try other filter"
                : "loading..."}
            </Text>
          }
          data={data && data[0]?.product_skus}
          numColumns={4}
          renderItem={renderItem}
        />
      </Center>
      <FooterSectionWeb />
    </VStack>
  );
};

export default ProductsScreen;
