import { useDispatch } from "react-redux";
import { reloadWishlist } from "../redux/cart/actions";
import {
  hideLoader,
  setDialog,
  showLoader,
} from "../redux/currentState/actions";
import { Popups } from "../constants/popups";
import { deleteFromWishlist } from "../redux/wishlist/actions";
import { privateApi } from "../util/API";
import { getUser } from "../util/utilFunctions";
import { useSWRConfig } from "swr";
import { setAnalytics } from "../analytics";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
export const useRemoveFromWishlist = () => {
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const removeWishlist = async (productId) => {
    dispatch(showLoader());
    const userId = await getUser();
    try {
      if (userId !== null && userId !== undefined) {
        let res = await privateApi(
          "/api/products/wishlist/",
          { user_id: userId, product_sku_id: productId },
          "delete"
        );

        if (res.status === 200 || res.status === 204) {
          setAnalytics("Remove from Favorites", "Removed From Favorites", {
            productId: productId,
          });
          dispatch(deleteFromWishlist(productId));
          mutate(`${apiUrl}/api/products/get_wishlist/?user_id=${userId}`);
          dispatch(reloadWishlist(true));

          dispatch(hideLoader());
          return;
        }
      } else {
        dispatch(hideLoader());
        dispatch(setDialog(Popups.LoginPopup));
      }
    } catch (error) {}
    dispatch(hideLoader());
  };
  return removeWishlist;
};
