const { Image } = require("native-base");

const FireAnimatedIcon = () => {
  return (
    <Image
      size={6}
      resizeMethod="scale"
      resizeMode="contain"
      source={require("./../../../assets/images/gifs/fire.gif")}
      alt=""
    />
  );
};

export default FireAnimatedIcon;
