import { useNavigation, useRoute } from "@react-navigation/native";
import {
  Center,
  FlatList,
  HStack,
  Image,
  Pressable,
  Text,
  VStack,
  Box,
  PresenceTransition,
} from "native-base";
import { useState } from "react";
import { setAnalytics } from "../../analytics";
import { BrandImages } from "../../constants/brands";
import { Pages } from "../../constants/pages";
import BagHeartIcon from "../icons/bagHeartIcon";

const ShopTheLookCard = ({ item }) => {
  const [showCollections, setShowCollections] = useState();
  const navigation = useNavigation();
  console.log("item", item);
  const route = useRoute();
  return (
    <VStack mx={2} borderRadius={"20px"}>
      <Center position={"relative"}>
        <Image
          borderRadius={"10px"}
          h={"400px"}
          w={{
            md: "300px",
            lg: "310px",
            xl: "350px",
          }}
          resizeMethod="scale"
          resizeMode="cover"
          source={{
            uri: item?.thumbnail,
          }}
          alt=""
        />

        <VStack
          w="full"
          position={"absolute"}
          bottom={0}
          zIndex={8}
          flexDir="row"
          alignItems={"center"}
        >
          <Pressable
            onPress={() => setShowCollections(!showCollections)}
            w="full"
            h="70px"
            bg="#111315:alpha.60"
            flexDir="row"
            justifyContent={"center"}
          >
            <HStack
              alignItems={"center"}
              space={2}
              w="full"
              justifyContent={"center"}
            >
              <BagHeartIcon />
              <Text
                style={{
                  color: "white",
                  fontSize: 16,
                  lineHeight: 20,
                  fontFamily: "HK_400Regular",
                }}
              >
                {showCollections ? "Explore all" : "See Products"}
              </Text>
            </HStack>
          </Pressable>
        </VStack>
      </Center>
      {showCollections && (
        <PresenceTransition
          visible={showCollections}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 500,
            },
          }}
        >
          <FlatList
            bg={"#202427"}
            pt={"17px"}
            h="250px"
            borderBottomRadius={"20px"}
            showsVerticalScrollIndicator={false}
            _contentContainerStyle={{
              alignItems: "center",

              paddingBottom: 10,
            }}
            ItemSeparatorComponent={() => (
              <Box style={{ paddingVertical: 6 }}></Box>
            )}
            data={item?.product_skus}
            renderItem={({ item }) => (
              <Pressable
                onPress={() => {
                  setAnalytics("Product Card", "Product Card Clicked", {
                    productId: item?.product,
                    productSkuId: item?.id,
                    productName: item?.product_name,
                    brandName: item?.brand_name,
                    screen: route?.name,
                    type: "Shop The Look Product Card",
                  });

                  navigation.navigate(Pages.ProductScreen, {
                    id: item?.product,
                    sku_id: item?.id,
                  });
                }}
              >
                <HStack
                  justifyContent={"space-evenly"}
                  alignItems={"center"}
                  borderWidth={"1px"}
                  borderColor={"#2B2F33"}
                  borderRadius={"6px"}
                  h={"82px"}
                  w={{
                    md: "280px",
                    lg: "290px",
                    xl: "326px",
                  }}
                >
                  <Image
                    borderRadius={"10px"}
                    h={"60px"}
                    w={{
                      md: "63px",
                      lg: "63px",
                      xl: "63px",
                    }}
                    resizeMethod="scale"
                    resizeMode="cover"
                    source={{
                      uri: item?.images[0],
                    }}
                    alt=""
                  />
                  <VStack w="50%" space={2}>
                    <Text
                      numberOfLines={2}
                      style={{
                        color: "white",
                        fontSize: 12,
                        lineHeight: 12,
                        fontFamily: "HK_600SemiBold",
                      }}
                    >
                      {item?.product_name}
                    </Text>
                    <HStack alignItems={"center"} space={2}>
                      <Image
                        rounded={"full"}
                        h={"20px"}
                        w={{
                          md: "20px",
                          lg: "20px",
                          xl: "20px",
                        }}
                        resizeMethod="scale"
                        resizeMode="cover"
                        source={{
                          uri: BrandImages[item?.brand_name],
                        }}
                        alt=""
                      />
                      <Text
                        textTransform={"uppercase"}
                        numberOfLines={2}
                        style={{
                          color: "#94A3B8",
                          fontSize: 11,
                          lineHeight: 16,
                          fontFamily: "HK_600SemiBold",
                        }}
                      >
                        {item?.brand_name}
                      </Text>
                    </HStack>
                  </VStack>
                  <Text
                    letterSpacing={2}
                    style={{
                      color: "white",
                      fontSize: 16,
                      lineHeight: 21,
                      fontFamily: "HK_700Bold",
                    }}
                  >
                    ${item?.retail_price}
                  </Text>
                </HStack>
              </Pressable>
            )}
          />
        </PresenceTransition>
      )}
    </VStack>
  );
};

export default ShopTheLookCard;
