import CartCard from "../../../components/cards/cart/cartCard";
import { FlatList, View } from "native-base";
import useGetCartItems from "../../../hooks/useGetCartItems";
import { useRoute } from "@react-navigation/native";
const Bag = () => {
  const route = useRoute();
  const { data } = useGetCartItems(route?.params?.id);

  return (
    <FlatList
      pt={"16px"}
      h="full"
      showsVerticalScrollIndicator={false}
      ItemSeparatorComponent={() => (
        <View style={{ paddingVertical: 6 }}></View>
      )}
      contentContainerStyle={{
        paddingBottom: 30,
      }}
      data={data?.filter((k) => k.product_sku !== null)}
      initialNumToRender={5}
      renderItem={({ item }) => (
        <>
          {item?.product_sku !== null && (
            <CartCard
              userId={route?.params?.id}
              variants={[
                item?.product_sku?.color,
                item?.product_sku?.size,
                item?.product_sku?.scent,
                item?.product_sku?.type,
              ]}
              product={item?.product_sku}
            />
          )}
        </>
      )}
    />
  );
};

export default Bag;
