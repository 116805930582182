import BlueSendIcon from "../icons/blueSendIcon";
import { Box, HStack, Pressable, Text } from "native-base";
import * as RootNavigation from "./../../RootNavigation";
import { useShare } from "../../hooks/useShare";
const ShareButton = () => {
  const route = RootNavigation.navigationRef.getCurrentRoute();

  const onShare = useShare();
  const path = `product/${route?.params?.id}`;
  const analytics = {
    category: "Share Icon Product Screen Topbar",
    event: `Shared Product From Product Screen`,
    eventProperties: {
      productId: route?.params?.id,
      productSkuId: route?.params?.sku_id,
      screen: route?.name,
    },
  };
  return (
    <Pressable
      onPress={() => {
        onShare(path, analytics);
      }}
    >
      <Box
        rounded={"10px"}
        bg={{
          linearGradient: true
            ? {
                colors: [
                  "primary.ctaLinearFirstColor",
                  "primary.ctaLinearSecondColor",
                ],
                start: [0, 0],
                end: [1, 0],
              }
            : {
                colors: ["#58595B", "#58595B"],
                start: [0, 0],
                end: [1, 0],
              },
        }}
      >
        <HStack
          space={2}
          py={"12px"}
          px={"16px"}
          rounded={"10px"}
          width={"110px"}
          h="50px"
          alignItems="center"
          justifyContent={"center"}
        >
          <Text
            textTransform={"uppercase"}
            fontSize={14}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
            color="white"
          >
            Share
          </Text>
          <BlueSendIcon color="#ffffff" />
        </HStack>
      </Box>
    </Pressable>
  );
};

export default ShareButton;
