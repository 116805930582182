import { Icon } from "native-base";
import { Path, G, LinearGradient, Stop, Defs } from "react-native-svg";

const GradientFbIcon = () => {
  return (
    <Icon
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G fill="none">
        <Path
          d="M20.1322 10.0251C20.1322 4.49123 15.6409 0 10.1071 0C4.57326 0 0.0820312 4.49123 0.0820312 10.0251C0.0820312 14.8772 3.53065 18.9173 8.10208 19.8496V13.0326H6.09707V10.0251H8.10208V7.5188C8.10208 5.58396 9.67602 4.01003 11.6109 4.01003H14.1171V7.01754H12.1121C11.5607 7.01754 11.1096 7.46867 11.1096 8.02005V10.0251H14.1171V13.0326H11.1096V20C16.1723 19.4987 20.1322 15.2281 20.1322 10.0251Z"
          fill="url(#paint0_linear_45_15263)"
        />
        <Defs>
          <LinearGradient
            id="paint0_linear_45_15263"
            x1="0.0820312"
            y1="0"
            x2="26.0306"
            y2="9.64887"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#691DE3" />
            <Stop offset="0.0001" stop-color="#691DE3" />
            <Stop offset="1" stop-color="#FF5A92" />
          </LinearGradient>
        </Defs>
      </G>
    </Icon>
  );
};

export default GradientFbIcon;
