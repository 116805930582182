import axios from "axios";
import useSWR from "swr";
import { getHeaders, parse } from "../util/utilFunctions";
import { useSelector } from "react-redux";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const fetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return parse(res.data);
    });

export default function useGetAddressList() {
  const userId = useSelector((state) => state.user.id);
  const { data } = useSWR(
    userId ? `${apiUrl}/api/users/get_addresses/?user_id=${userId}` : null,
    fetcher
  );

  return { data };
}
