import { Center, Pressable } from "native-base";
import { useContext } from "react";
import { WishlistContext } from "../../hoc/WishlistHOC";
import WebGradientOutlineWishlistIcon from "../icons/webIcons/webGradientOutlineWishlistIcon";

const ProductScreenWishlistButton = () => {
  const { onPress, filled } = useContext(WishlistContext);
  return (
    <Pressable
      _hover={{ bg: "#FAF1FF", rounded: "full", size: 16 }}
      onPress={onPress}
    >
      <Center bg="#FAF1FF" rounded={"full"} size={16}>
        <WebGradientOutlineWishlistIcon
          color={filled ? "url(#paint0_linear_218_483)" : "#FAF1FF"}
        />
      </Center>
    </Pressable>
  );
};

export default ProductScreenWishlistButton;
