import {
  VIEW_PRODUCT,
  ADD_GROUPS_PRODUCTS,
  STORE_PRODUCT,
  SET_SKU,
  ADD_REVIEW,
  ADD_RATING
} from "./constants";

const initialState = {};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REFRESH":
      return {};
    case VIEW_PRODUCT:
      return {
        ...action.product,
      };
    case VIEW_PRODUCT:
      return {
        id: action.id,
      };
    case STORE_PRODUCT:
      const p = store(action.product);
      return {
        id: state.id,
        ...p,
      };
    case ADD_REVIEW:
      const reviews = state.reviews ? [...state.reviews, action.review] : [action.review]
      return {
        ...state,
        reviews: reviews,
      };
    case ADD_GROUPS_PRODUCTS:
      return {
        ...state,
        groups: action.groups,
      };
    case SET_SKU:
      return {
        ...state,
        skuId: action.id,
      };
    default:
      return state;
  }
};

const store = (pl) => {
  let product = pl.product;
  let details = pl.product_details[0]
  let reviews = calculateReviews(pl.reviews, pl.ratings)
  let averageRating = calculateRating(pl.ratings)
  let values = {};
  let variantsMapping = {};
  let skuId = null;
  let colors = [];
  let sizes = [];
  let types = [];
  let scents = [];
  let variants = [];
  var pr;
  try {
    for (let it = 0; it < product.length; it++) {
      pr = product[it];
      if (skuId === null) {
        skuId = pr.id;
      }
      if (pr.color !== null) {
        colors.indexOf(pr.color) === -1 ? colors.push(pr.color) : null;
      }
      if (pr.size !== null) {
        sizes.indexOf(pr.size) === -1 ? sizes.push(pr.size) : null;
      }
      if (pr.type !== null) {
        types.indexOf(pr.type) === -1 ? types.push(pr.type) : null;
      }
      if (pr.scent !== null) {
        scents.indexOf(pr.scent) === -1 ? scents.push(pr.scent) : null;
      }
      values[pr.id] = pr;
    }

    for (let it = 0; it < product.length; it++) {
      pr = product[it];
      item = [];
      if (pr.color !== null && colors.length > 1) {
        item.push(colors.indexOf(pr.color));
      } else {
        item.push(null);
      }
      if (pr.size !== null && sizes.length > 1) {
        item.push(sizes.indexOf(pr.size));
      } else {
        item.push(null);
      }
      if (pr.type !== null && types.length > 1) {
        item.push(types.indexOf(pr.type));
      } else {
        item.push(null);
      }
      if (pr.scent !== null && scents.length > 1) {
        item.push(scents.indexOf(pr.scent));
      } else {
        item.push(null);
      }
      variants.push(item);
      variantsMapping[item] = pr.id;
    }

    let p = {};
    p.variants = variants;
    p.variant = values[skuId];

    p.variantValues = [
      colors.indexOf(p.variant.color) !== -1 && colors.length > 1
        ? colors.indexOf(p.variant.color)
        : null,
      sizes.indexOf(p.variant.size) !== -1 && sizes.length > 1
        ? sizes.indexOf(p.variant.size)
        : null,
      types.indexOf(p.variant.type) !== -1 && types.length > 1
        ? types.indexOf(p.variant.type)
        : null,
      scents.indexOf(p.variant.scent) !== -1 && scents.length > 1
        ? scents.indexOf(p.variant.scent)
        : null,
    ];
    p.variantsMapping = variantsMapping;
    p.skus = values;
    p.skuId = skuId;
    p.scents = scents;
    p.colors = colors;
    p.types = types;
    p.sizes = sizes;
    p.details = details
    p.reviews = reviews
    p.averageRating = averageRating
    return p;
  } catch (ex) {}
};

const calculateReviews = (reviews, ratings) => {
  let rat;
  rat = ratings.reduce((acc, rating) => {
    acc[rating.user_id] = rating.rating; // or what ever object you want inside
    return acc;
  }, {});
  let rev = [...reviews];
  reviews.map((review, index) => {
    if (rat[review.user_id]) {
      rev[index]["rating"] = rat[review.user_id];
    }
  });
  return rev;
};

const calculateRating = (ratings) => {
  if (ratings.length > 0) {
    let avg = ratings.reduce((acc, rating) => {
      acc = acc + rating.rating // or what ever object you want inside
      return acc
    }, 0)
    return Math.ceil(avg / ratings.length)
  }

  return 0;
};

export default productReducer;
