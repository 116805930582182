import { Center, HStack, Image, Pressable, Text, VStack } from "native-base";
import HeadingBar from "../headingBar";
import WebStepsArrowIcon from "../../icons/webIcons/webStepsArrowIcon";
import TopBarWeb from "../navigationBars/topBarWeb";
import { Pages } from "../../../constants/pages";
import useGetUser from "../../../hooks/useGetUser";
import { useSelector } from "react-redux";
import { gradientTextWeb } from "../../../constants/gradient";
const SurveyScreenTopBar = ({ route, navigation }) => {
  const userId = useSelector((state) => state.user.id);
  const { data } = useGetUser("profile", userId);

  return (
    <>
      <VStack w="full" pb="30px">
        <TopBarWeb />
        <HeadingBar>
          <VStack alignItems={"center"} w="full" space={"30px"} h="full">
            <Text
              fontSize={"36px"}
              _web={{
                style: {
                  ...gradientTextWeb(1),
                  fontFamily: "AustinCyr_400",
                },
              }}
            >
              Beauty Quiz
            </Text>
            <HStack px={8}>
              {[
                {
                  label: "About you",
                  content: "Age & Identity",
                  route: Pages.AboutYourSelfScreen,
                },
                {
                  label: "Skin",
                  content: "Tone, Type & Concerns",
                  route: "GettingToKnowYourSkinScreen",
                },
                {
                  label: "Hair",
                  content: "Color, Texture, etc..",
                  route: Pages.UnderstandingHair,
                },
                {
                  label: "Ingredients",
                  content: "Exclusions",
                  route: "SpecificIngredientsScreen",
                },
                {
                  label: "Preferences",
                  content: "Things you like",
                  route: "PreferencesScreen",
                },
                // {
                //   label: "Brands",
                //   content: "Your Favs",
                //   route: Pages.FavoriteBrands,
                // },
                {
                  label: "Explore Products",
                  route: "Yay",
                },
              ]?.map((k, i) => (
                <Pressable
                  onPress={() => {
                    if (data?.user[0]?.quiz_completed) {
                      navigation.navigate(k?.route);
                    }
                  }}
                >
                  <Center>
                    {route?.name === k?.route ? (
                      <Image
                        width={"214px"}
                        height={"56px"}
                        resizeMethod="scale"
                        source={
                          i === 0
                            ? require("./../../../assets/images/pngs/startstep.png")
                            : i === 6
                            ? require("./../../../assets/images/pngs/gradendarrow.png")
                            : require("./../../../assets/images/pngs/midStep.png")
                        }
                        alt=""
                      />
                    ) : (
                      <>
                        {i === 5 ? (
                          <Image
                            width={"214px"}
                            height={"58px"}
                            resizeMethod="scale"
                            source={require("./../../../assets/images/pngs/endStep.png")}
                            alt=""
                          />
                        ) : (
                          <WebStepsArrowIcon />
                        )}
                      </>
                    )}

                    <VStack position={"absolute"} space={-2}>
                      <Text
                        color={route?.name === k?.route ? "white" : "#682481"}
                        fontSize={"21px"}
                        style={{
                          fontFamily: "AustinCyr_400",
                        }}
                      >
                        {k?.label}
                      </Text>
                      <Text
                        letterSpacing={1}
                        color={route?.name === k?.route ? "white" : "#682481"}
                        fontSize={"11px"}
                        style={{
                          fontFamily: "Hero_400Regular",
                        }}
                      >
                        {k?.content}
                      </Text>
                    </VStack>
                  </Center>
                </Pressable>
              ))}
            </HStack>
          </VStack>
        </HeadingBar>
      </VStack>
    </>
  );
};

export default SurveyScreenTopBar;
