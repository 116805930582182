import { Icon } from "native-base";
import { Path, G, LinearGradient, Stop, Defs } from "react-native-svg";

const GradientLinkIcon = () => {
  return (
    <Icon
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G fill="none">
        <Path
          d="M12.3268 13.9023L10.8268 15.4023C10.1637 16.0652 9.26449 16.4376 8.32684 16.4376C7.3892 16.4376 6.48995 16.0652 5.82684 15.4023V15.4023C5.16392 14.7392 4.7915 13.8399 4.7915 12.9023C4.7915 11.9646 5.16392 11.0654 5.82684 10.4023L8.82684 7.40228C9.48995 6.73936 10.3892 6.36694 11.3268 6.36694C12.2645 6.36694 13.1637 6.73936 13.8268 7.40228V7.40228"
          stroke="url(#paint0_linear_177_15050)"
          stroke-width="2"
          stroke-linecap="round"
        />
        <Path
          d="M12.3267 3.90228L13.8267 2.40228C14.4898 1.73936 15.389 1.36694 16.3267 1.36694C17.2643 1.36694 18.1636 1.73936 18.8267 2.40228V2.40228C19.4896 3.06539 19.862 3.96464 19.862 4.90228C19.862 5.83993 19.4896 6.73918 18.8267 7.40228L15.8267 10.4023C15.1636 11.0652 14.2643 11.4376 13.3267 11.4376C12.389 11.4376 11.4898 11.0652 10.8267 10.4023"
          stroke="url(#paint1_linear_177_15050)"
          stroke-width="2"
          stroke-linecap="round"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_177_15050"
          x1="4.7915"
          y1="6.36694"
          x2="16.7747"
          y2="10.3547"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stop-color="#691DE3" />
          <Stop offset="0.0001" stop-color="#691DE3" />
          <Stop offset="1" stop-color="#FF5A92" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_177_15050"
          x1="10.8267"
          y1="1.36694"
          x2="22.8098"
          y2="5.35474"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stop-color="#691DE3" />
          <Stop offset="0.0001" stop-color="#691DE3" />
          <Stop offset="1" stop-color="#FF5A92" />
        </LinearGradient>
      </Defs>
    </Icon>
  );
};

export default GradientLinkIcon;
