import AppLayout from "./components/layouts/appLayout";
import * as SplashScreen from "expo-splash-screen";
//import RNUxcam from "react-native-ux-cam";
import {
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
  Poppins_800ExtraBold,
} from "@expo-google-fonts/poppins";
import { setCsrf } from "./util/API";
import { getPushToken, getUser, setPushToken } from "./util/utilFunctions";
import { Inter_400Regular, Inter_700Bold } from "@expo-google-fonts/inter";
import { useCallback, useEffect, useState, useRef } from "react";
import * as Font from "expo-font";
import * as Notifications from "expo-notifications";
import { View, Platform } from "react-native";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { setAnalytics } from "./analytics";

import { requestTrackingPermissionsAsync } from "expo-tracking-transparency";

SplashScreen.preventAutoHideAsync();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

function App() {
  // RNUxcam.optIntoSchematicRecordings(); // for ios recording
  // const configuration = {
  //   userAppKey: "qgy5p7uwk6d5l92",
  //   enableAutomaticScreenNameTagging: false,
  //   enableImprovedScreenCapture: true,
  // };
  // RNUxcam.startWithConfiguration(configuration);

  const [appIsReady, setAppIsReady] = useState(false);

  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();

  const responseListener = useRef();

  useEffect(() => {
    async function prepare() {
      try {
        await Font.loadAsync({
          Hero_700Bold: require("./assets/fonts/HeroBold700.otf"),
          Hero_400Regular: require("./assets/fonts/HeroRegular400.otf"),
          LeituraNewsRoman: require("./assets/fonts/LeituraNewsRoman1.otf"),
          AustinCyr_400: require("./assets/fonts/AustinCyr-Roman.ttf"),
          Tomatoes_400Regular: require("./assets/fonts/Tomatoes.ttf"),
          HK_400Regular: require("./assets/fonts/hk-grotesk-FONT/HKGrotesk-Regular.otf"),
          HK_500Medium: require("./assets/fonts/hk-grotesk-FONT/HKGrotesk-Medium.otf"),
          HK_600SemiBold: require("./assets/fonts/hk-grotesk-FONT/HKGrotesk-SemiBold.otf"),
          HK_700Bold: require("./assets/fonts/hk-grotesk-FONT/HKGrotesk-Bold.otf"),
          HK_800ExtraBold: require("./assets/fonts/hk-grotesk-FONT/HKGrotesk-ExtraBold.otf"),
          Inter_400Regular,
          Inter_700Bold,
          Poppins_400Regular,
          Poppins_500Medium,
          Poppins_600SemiBold,
          Poppins_700Bold,
          Poppins_800ExtraBold,
        });
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };
        try {
        } catch {}

        setCsrf();

        const userId = await getUser();

        if (userId) {
          const stringUserId = userId.toString();
          const K = await AsyncStorage.getItem("username");
          try {
            setAnalytics("Open App", "Opened App");
          } catch {}
        } else {
          try {
            setAnalytics("Open App", "Opened App");
          } catch {}
        }

        registerForPushNotificationsAsync().then((token) =>
          setPushToken(token)
        );

        const { status } = await requestTrackingPermissionsAsync();
        if (status === "granted") {
        }

        notificationListener.current =
          Notifications.addNotificationReceivedListener((notification) => {
            setNotification(notification);
          });

        responseListener.current =
          Notifications.addNotificationResponseReceivedListener((response) => {
            setAnalytics("Push notification", "Push Notification Clicked", {
              body: response.notification.request.content.body,
            });
          });

        return () => {
          Notifications.removeNotificationSubscription(notificationListener);
          Notifications.removeNotificationSubscription(responseListener);
        };
      } catch (e) {
        console.warn(e);
      } finally {
        setAppIsReady(true);
      }
    }
    prepare();
  }, []);

  async function registerForPushNotificationsAsync() {
    let token;

    try {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        token = null;
        return;
      }
      let pushtoken = await getPushToken();
      if (pushtoken === undefined || pushtoken === null) {
        pushtoken = (await Notifications.getExpoPushTokenAsync()).data;
      }
      token = pushtoken;
      if (Platform.OS === "android") {
        Notifications.setNotificationChannelAsync("default", {
          name: "default",
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: "#FF231F7C",
        });
      }
    } catch {
      token = null;
    }

    return token;
  }

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }

  return (
    <View
      onLayout={onLayoutRootView}
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <AppLayout />
      </View>
    </View>
  );
}

export default App;
