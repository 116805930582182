import { useContext, useState } from "react";
import { EmailLoginContext } from "../../hoc/EmailLoginHOC";
import Error from "../error";
import { Center, IconButton, Input, Text, VStack } from "native-base";
import EyeIcon from "../icons/eyeIcon";
import { Ionicons } from "@expo/vector-icons";
import PrimaryCTAButton from "../buttons/primaryCTAButton";
const EmailLoginForm = () => {
  const [passwordType, setPasswordType] = useState("password");
  const { error, handleInput, handleEmailLogin } =
    useContext(EmailLoginContext);
  return (
    <VStack space={"20px"}>
      {[
        {
          label: "Email Address or username*",
          placeholder: "name@email.com",
          field: "email",
        },
        {
          label: "Password*",
          placeholder: "******",
          field: "password",
        },
      ].map((k, i) => (
        <VStack space={"6px"}>
          <Text
            letterSpacing={3}
            textTransform="uppercase"
            color={"#682481"}
            fontSize="11px"
            style={{
              fontFamily: "Hero_700Bold",
            }}
          >
            {k?.label}
          </Text>
          <Input
            px="22px"
            py="13px"
            type={i === 1 && passwordType}
            w={{
              md: "full",
              lg: "full",
              xl: "303px",
            }}
            h="39px"
            color="#464646"
            variant="outline"
            focusOutlineColor="#682481"
            _focus={{
              bg: "none",
              color: "#464646",
            }}
            _hover={{
              bg: "none",
              color: "#464646",
              borderColor: "#682481",
            }}
            style={{
              fontFamily: "Hero_700Bold",
            }}
            borderRadius="7px"
            borderColor="#C1C9D2"
            fontSize="14px"
            placeholderTextColor={"#C1C9D2"}
            placeholder={k?.placeholder}
            borderWidth={"1px"}
            onChangeText={(val) => handleInput(k?.field)(val)}
            InputRightElement={
              i === 1 && (
                <IconButton
                  onPress={() => {
                    if (passwordType === "text") {
                      setPasswordType("password");
                    } else {
                      setPasswordType("text");
                    }
                  }}
                  icon={
                    passwordType === "text" ? (
                      <Ionicons name="md-eye-off-outline" />
                    ) : (
                      <EyeIcon />
                    )
                  }
                />
              )
            }
          ></Input>
        </VStack>
      ))}
      {error && <Error error={error} />}
      <Center h="36px">
        <PrimaryCTAButton onPress={handleEmailLogin} gradientType="2">
          <Text
            letterSpacing={2}
            textTransform={"uppercase"}
            fontSize={"11px"}
            style={{
              fontFamily: "Hero_700Bold",
            }}
            color="white"
          >
            Login
          </Text>
        </PrimaryCTAButton>
      </Center>
    </VStack>
  );
};

export default EmailLoginForm;
