import { Divider, FlatList } from "native-base";
import BottomBar from "../components/bars/navigationBars/bottomBar";
import ProductNotificationCard from "../components/cards/productNotificationCard";
import UpdateNotificationCard from "../components/cards/updateNotificationCard";
import QuizNotificationCard from "../components/cards/quizNotificationCard";
import UserNotificationCard from "../components/cards/userNotificationCard";
import useGetNotifications from "../hooks/useGetNotifications";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
import { useSWRConfig } from "swr";
import { hideLoader, showLoader } from "../redux/currentState/actions";
import { privateApi } from "../util/API";
import { BackHandler, Platform } from "react-native";
const NotificationsScreen = ({ navigation }) => {
  const { data } = useGetNotifications();
  const userId = useSelector((state) => state.user.id);
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  useEffect(() => {
    if (Platform.OS === "android") {
      androidHardwareBackHandler();
    } else {
      removeNotificationsCount();
    }
  }, [navigation]);

  const removeNotificationsCount = async () => {
    navigation.addListener("beforeRemove", async (e) => {
      dispatch(showLoader());
      const res = await privateApi(
        "/api/users/notification_seen/",
        {
          id: userId,
        },
        "put"
      );

      if (res?.status === 200) {
        mutate(`${apiUrl}/api/users/get_user_notifications/?user_id=${userId}`);
        dispatch(hideLoader());
      }
    });
  };

  const androidHardwareBackHandler = async () => {
    async function handleBackButton() {
      dispatch(showLoader());
      const res = await privateApi(
        "/api/users/notification_seen/",
        {
          id: userId,
        },
        "put"
      );

      if (res?.status === 200) {
        mutate(`${apiUrl}/api/users/get_user_notifications/?user_id=${userId}`);
        dispatch(hideLoader());
      }
      navigation.goBack();
      return true;
    }

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      handleBackButton
    );

    return () => backHandler.remove();
  };

  const renderItem = ({ item }) =>
    item?.notification_type === "Followed User" ? (
      <>
        <UserNotificationCard notification={item} />
        <Divider bg="#58595B" />
      </>
    ) : item?.notification_type === "New Review" ||
      item?.notification_type === "Selling Fast" ||
      item?.notification_type === "New Deal" ? (
      <>
        <ProductNotificationCard notification={item} />
        <Divider bg="#58595B" />
      </>
    ) : item?.notification_type === "Take Beauty Quiz" ? (
      <>
        <QuizNotificationCard notification={item} />
        <Divider bg="#58595B" />
      </>
    ) : item?.notification_type !== "Group Expired" &&
      item?.notification_type !== "Group Ending" &&
      item?.notification_type !== "Created Group" &&
      item?.notification_type !== "Joined Group" &&
      item?.notification_type !== "Group Filled" ? (
      <>
        <UpdateNotificationCard notification={item} />
        <Divider bg="#58595B" />
      </>
    ) : null;

  return (
    <>
      <FlatList
        flex={1}
        showsVerticalScrollIndicator={false}
        // ItemSeparatorComponent={() => <Divider bg="#58595B" />}
        data={data?.filter((k) => k)}
        renderItem={renderItem}
      />

      <BottomBar />
    </>
  );
};

export default NotificationsScreen;
