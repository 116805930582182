import { HStack, Text } from "native-base";

const ProcessingChip = ({ text }) => {
  return (
    <HStack
      borderRadius="full"
      // px={"12px"}
      // py={"4px"}
      height="29px"
      width={"91px"}
      alignItems="center"
      bg="#FFF5C7"
      space={2}
      justifyContent="center"
    >
      <Text
        fontSize={14}
        style={{ fontFamily: "HK_600SemiBold" }}
        color="#CC8100"
      >
        {text}
      </Text>
    </HStack>
  );
};

export default ProcessingChip;
