import {
  Center,
  Input,
  Text,
  VStack,
  IconButton,
  Image,
  Pressable,
  ScrollView,
} from "native-base";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { SignupContext } from "../../hoc/SignupHOC";
import PrimaryCTAButton from "../buttons/primaryCTAButton";
import Error from "../error";
import EyeIcon from "../icons/eyeIcon";
import GreenTickIcon from "../icons/greenTickIcon";
import { Ionicons } from "@expo/vector-icons";

import { useUploadImage } from "../../hooks/useUploadImage";
const SignupForm = () => {
  const { handleInput, username, signup } = useContext(SignupContext);
  const form = useSelector((state) => state.form);
  const [passwordType, setPasswordType] = useState();
  const [pickImage, image, setImage] = useUploadImage();

  return (
    <ScrollView h="300px" pr={4}>
      <VStack space={"20px"} w="full">
        <VStack space={"6px"} w="full">
          <Text
            letterSpacing={3}
            textTransform="uppercase"
            color={"#682481"}
            fontSize="11px"
            style={{
              fontFamily: "Hero_700Bold",
            }}
          >
            Profile picture (optional)
          </Text>
          <Center w="full">
            <Pressable onPress={pickImage}>
              {image ? (
                <Image
                  resizeMethod="scale"
                  alt=""
                  size={"56px"}
                  rounded={"full"}
                  source={{ uri: image }}
                />
              ) : (
                <Center size={"56px"} rounded="full" bg="#C1C9D2"></Center>
              )}
            </Pressable>
          </Center>
        </VStack>

        {[
          {
            label: "User name*",
            placeholder: "Alex Page",
            field: "username",
            error: username?.status === 400 && (
              <Error error={username?.message} />
            ),
            verified:
              !/\s/g.test(form?.username) &&
              username?.status === 200 &&
              form?.username,
          },
          {
            label: "First name*",
            placeholder: "Alex",
            field: "first_name",
          },
          {
            label: "Last name*",
            placeholder: "Alex",
            field: "last_name",
          },
          form?.phone_number
            ? undefined
            : {
                label: "Password*",
                placeholder: "******",
                field: "password",
              },
          form?.phone_number
            ? undefined
            : {
                label: "Retype Password*",
                placeholder: "******",
                field: "confirmpassword",
                verified:
                  form?.password &&
                  form?.confirmpassword &&
                  form?.password === form?.confirmpassword,
              },
        ]
          .filter((g, k) => g)
          .map((k, i) => (
            <VStack space={"6px"}>
              <Text
                letterSpacing={3}
                textTransform="uppercase"
                color={"#682481"}
                fontSize="11px"
                style={{
                  fontFamily: "Hero_700Bold",
                }}
              >
                {k?.label}
              </Text>
              <Input
                rightElement={
                  k?.verified && (
                    <Center px={3}>
                      <GreenTickIcon />
                    </Center>
                  )
                }
                px="22px"
                py="13px"
                type={i === 3 && passwordType}
                w={{
                  md: "full",
                  lg: "full",
                  xl: "303px",
                }}
                h="39px"
                color="#464646"
                variant="outline"
                focusOutlineColor="#682481"
                _focus={{
                  bg: "none",
                  color: "#464646",
                }}
                _hover={{
                  bg: "none",
                  color: "#464646",
                  borderColor: "#682481",
                }}
                style={{
                  fontFamily: "Hero_700Bold",
                }}
                borderRadius="7px"
                borderColor="#C1C9D2"
                fontSize="14px"
                placeholderTextColor={"#C1C9D2"}
                placeholder={k?.placeholder}
                borderWidth={"1px"}
                onChangeText={(val) => handleInput(k?.field)(val)}
                InputRightElement={
                  i === 3 && (
                    <IconButton
                      onPress={() => {
                        if (passwordType === "text") {
                          setPasswordType("password");
                        } else {
                          setPasswordType("text");
                        }
                      }}
                      icon={
                        passwordType === "text" ? (
                          <Ionicons name="md-eye-off-outline" />
                        ) : (
                          <EyeIcon />
                        )
                      }
                    />
                  )
                }
              ></Input>
              {k?.error}
            </VStack>
          ))}

        <Text
          letterSpacing={3}
          textTransform="uppercase"
          color={"#682481"}
          fontSize="11px"
          style={{
            fontFamily: "Hero_700Bold",
          }}
        >
          Your Socials (optional)
        </Text>
        {[
          {
            label: "Instagram Handle",
            name: "instagram_handle",
            // icon: <InstagramIcon />,
          },
          {
            label: "Facebook Username",
            name: "facebook_username",
            // icon: <FacebookIcon />,
          },
          {
            label: "TikTok Handle",
            name: "tiktok_handle",
            // icon: <TiktokIcon />,
          },
        ].map((k) => (
          <VStack space={"6px"}>
            <Text
              letterSpacing={3}
              textTransform="uppercase"
              color={"#682481"}
              fontSize="11px"
              style={{
                fontFamily: "Hero_700Bold",
              }}
            >
              {k?.label}
            </Text>
            <Input
              px="22px"
              py="13px"
              w="303px"
              h="39px"
              color="#464646"
              variant="outline"
              focusOutlineColor="#682481"
              _focus={{
                bg: "none",
                color: "#464646",
              }}
              _hover={{
                bg: "none",
                color: "#464646",
                borderColor: "#682481",
              }}
              style={{
                fontFamily: "Hero_700Bold",
              }}
              borderRadius="7px"
              borderColor="#C1C9D2"
              fontSize="14px"
              placeholderTextColor={"#C1C9D2"}
              placeholder={k?.placeholder}
              borderWidth={"1px"}
              onChangeText={(val) => handleInput(k?.name)(val)}
            ></Input>
          </VStack>
        ))}
        <Center h="36px" w="303px">
          <PrimaryCTAButton
            onPress={() => {
              if (
                username?.status === 200 &&
                form?.phone_number &&
                form?.username
              ) {
                signup("7");
              } else if (
                username?.status === 200 &&
                form?.username &&
                form?.first_name &&
                form?.last_name
              ) {
                signup("6");
              }
            }}
            gradientType="2"
          >
            <Text
              letterSpacing={2}
              textTransform={"uppercase"}
              fontSize={"11px"}
              style={{
                fontFamily: "Hero_700Bold",
              }}
              color="white"
            >
              sign up
            </Text>
          </PrimaryCTAButton>
        </Center>
      </VStack>
    </ScrollView>
  );
};

export default SignupForm;
