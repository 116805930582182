import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const GreenTickIcon = ({ size }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={size} viewBox="0 0 13 10">
        <G
          width="13"
          height="10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M1 5.26667L4.55556 9L11.6667 1"
            stroke="#0F9737"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </G>
      </Icon>
    </>
  );
};

export default GreenTickIcon;
