import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const GrayFbIcon = ({ color = "#888990" }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}

      <Icon viewBox="0 0 25 24">
        <G
          width="25"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M22.5 12C22.5 6.48 18.02 2 12.5 2C6.98 2 2.5 6.48 2.5 12C2.5 16.84 5.94 20.87 10.5 21.8V15H8.5V12H10.5V9.5C10.5 7.57 12.07 6 14 6H16.5V9H14.5C13.95 9 13.5 9.45 13.5 10V12H16.5V15H13.5V21.95C18.55 21.45 22.5 17.19 22.5 12Z"
            fill={color}
          />
        </G>
      </Icon>
    </>
  );
};

export default GrayFbIcon;
