import {
  HStack,
  VStack,
  Text,
  Image,
  View,
  Pressable,
  Box,
  Center,
  Popover,
  IconButton,
  Button,
} from "native-base";
import JoinFlockDealButton from "../../buttons/joinFlockDealButton";
import * as RootNavigation from "./../../../RootNavigation";
import { useDispatch, useSelector } from "react-redux";
import { BrandImages } from "../../../constants/brands";
import { Pages } from "../../../constants/pages";
import { closeDialog } from "../../../redux/currentState/actions";
import LockIcon from "../../icons/lockIcon";
import { setAnalytics } from "../../../analytics";
import { useGetGroupDetails } from "../../../hooks/useGetGroupDetails";
import { useShare } from "../../../hooks/useShare";
import BiPeopleIcon from "../../icons/filledIcons/biPeopleIcon";
import GroupCardTimerText from "../../texts/groupCardTimerText";
import TimerHOC from "../../../hoc/TimerHOC";

const GroupCard = ({ group }) => {
  const dispatch = useDispatch();
  const route = RootNavigation.navigationRef.getCurrentRoute();
  const userId = useSelector((state) => state.user.id);
  const onShare = useShare();
  const onJoinPress = () => {
    setAnalytics("Group Card", "Group Card Clicked", {
      productId: group?.product_id,
      productSkuId: group?.product_sku_id,
      screen: route.name,
    });
    dispatch(closeDialog());
    RootNavigation.navigate(Pages.JoinGroupScreen, {
      id: group.id,
      product_id: group?.product_id,
      sku_id: group?.product_sku_id,
    });
  };
  const onSharePress = () => {
    onShare(`group/${group?.id}`, {
      category: "Share Button",
      event: `Shared Group via Share Button`,
      eventProperties: {
        groupId: group?.id,
        screen: route?.name,
        type: "Group Card",
      },
    });
  };

  const { data } = useGetGroupDetails(group?.id);

  return (
    <>
      {group && group?.group_members_images && (
        <Pressable
          onPress={() => onJoinPress()}
          bg="#2B2F33"
          w={{
            md: "350px",
            lg: "380px",
            xl: "400px",
          }}
          borderRadius={"20px"}
        >
          <HStack
            w="full"
            justifyContent={"space-between"}
            borderRadius={"20px"}
            bg="#202427"
            h={{
              md: "190px",
              lg: "190px",
              xl: "180px",
            }}
            pr={4}
            pl={3}
            pt={3.5}
          >
            <VStack flex={1} justifyContent="space-between" pb={6}>
              <VStack width={"75%"} space={"6px"}>
                <HStack space={4} alignItems={"center"}>
                  <HStack space={2}>
                    <Image
                      bg="black"
                      size={4}
                      rounded={"full"}
                      resizeMethod="scale"
                      resizeMode="contain"
                      source={{
                        uri: BrandImages[group.brand_name],
                      }}
                      alt=""
                    />
                    <Text
                      noOfLines={3}
                      textTransform={"uppercase"}
                      color="#94A3B8"
                      letterSpacing={2}
                      fontSize={"10px"}
                      lineHeight={16}
                      style={{
                        fontFamily: "HK_600SemiBold",
                      }}
                    >
                      {group.brand_name}
                    </Text>
                  </HStack>
                  {!group?.public && (
                    <Popover
                      placement="top"
                      trigger={(triggerProps) => {
                        return (
                          <IconButton
                            {...triggerProps}
                            icon={<LockIcon />}
                          ></IconButton>
                        );
                      }}
                    >
                      <Popover.Content
                        accessibilityLabel="Delete Customerd"
                        w="120px"
                        h={"50px"}
                        bg="#2B2F33"
                        borderWidth={"0"}
                        py={"4px"}
                        borderRadius={"5px"}
                      >
                        <Popover.Arrow bg="#2B2F33" borderColor={"#2B2F33"} />

                        <Text
                          fontSize={"12px"}
                          color={"white"}
                          textAlign="center"
                          style={{
                            fontFamily: "HK_700Bold",
                          }}
                        >
                          Private Deal{"\n"}
                          <Text
                            color={"white"}
                            opacity={"0.6"}
                            textAlign="center"
                            style={{
                              fontFamily: "HK_500Medium",
                            }}
                          >
                            Invite Basis only
                          </Text>
                        </Text>
                      </Popover.Content>
                    </Popover>
                  )}
                </HStack>
                <Text
                  noOfLines={2}
                  color="white"
                  fontSize={"14px"}
                  style={{
                    fontFamily: "HK_400Regular",
                  }}
                  lineHeight={18}
                >
                  {group.product_name}
                </Text>
                <HStack space={"4px"} flexWrap={"wrap"}>
                  {[
                    data?.product[0]?.size,
                    data?.product[0]?.color,
                    data?.product[0]?.scent,
                    data?.product[0]?.type,
                  ]
                    .filter(
                      (item, index) =>
                        index < 2 && item !== undefined && item !== null
                    )
                    .map((variant, i) => (
                      <Center
                        mt={1}
                        py={"2px"}
                        px={"9px"}
                        rounded={"4px"}
                        key={i}
                        bg="#2B2F33"
                      >
                        <Text
                          noOfLines={1}
                          style={{
                            fontFamily: "HK_400Regular",
                          }}
                          fontSize="12px"
                          color={"white"}
                        >
                          {variant}
                        </Text>
                      </Center>
                    ))}
                </HStack>
              </VStack>
              <TimerHOC starting_time={group?.starting_time}>
                <GroupCardTimerText />
              </TimerHOC>
            </VStack>
            <VStack
              py={{
                md: "2.5",
                lg: "2.5",
                xl: "0",
              }}
            >
              <View position={"relative"}>
                <Image
                  bg={"white"}
                  borderTopRadius={"10px"}
                  resizeMethod="scale"
                  resizeMode="contain"
                  w={{
                    md: "128px",
                    lg: "128px",
                    xl: "133px",
                  }}
                  h={{
                    md: "104px",
                    lg: "104px",
                    xl: "108px",
                  }}
                  source={{
                    uri: data?.product[0]?.images[0],
                  }}
                  alt=""
                ></Image>

                <Box
                  position={"absolute"}
                  bottom={{
                    md: "-38%",
                    lg: "-38%",
                    xl: "-37%",
                  }}
                  right={"-6.5%"}
                >
                  <JoinFlockDealButton
                    text={
                      data &&
                      data.users &&
                      data.users[0] &&
                      data.users[0].user_id === userId
                        ? "SHARE"
                        : "JOIN"
                    }
                    groupPrice={group.group_price}
                    retailPrice={group.retail_price}
                    groupDiscount={group.group_discount}
                    onPress={
                      data &&
                      data.users &&
                      data.users[0] &&
                      data.users[0].user_id === userId
                        ? onSharePress
                        : onJoinPress
                    }
                  />
                </Box>
              </View>
            </VStack>
          </HStack>
          <HStack
            justifyContent={"space-evenly"}
            bg="#2B2F33"
            borderRadius={"20px"}
            alignItems={"center"}
            p={2}
          >
            <HStack space={1}>
              <BiPeopleIcon />
              <Text
                color="white"
                fontSize={"12px"}
                lineHeight={"16px"}
                style={{
                  fontFamily: "HK_500Medium",
                }}
              >
                {data?.users?.length}/{data?.product[0]?.min_members_required}
              </Text>
            </HStack>
            {data?.users?.map((k) => (
              <HStack space={"8px"} alignItems="center">
                <Image
                  size={"24px"}
                  rounded={"full"}
                  resizeMethod="scale"
                  resizeMode="cover"
                  fallbackSource={require("./../../../assets/images/pngs/profile-pic.png")}
                  source={{
                    uri: k?.image,
                  }}
                  alt=""
                />
                <Text
                  w="60px"
                  noOfLines={1}
                  color="white"
                  fontSize={"13px"}
                  lineHeight={"16.25px"}
                  style={{
                    fontFamily: "HK_500Medium",
                  }}
                >
                  {k?.username}
                </Text>
              </HStack>
            ))}
            <Button
              onPress={onSharePress}
              p={0}
              bg="#202427"
              w="75px"
              h="25px"
              rounded={"full"}
              _text={{
                letterSpacing: 2,
                color: "#1A94FF",
                fontFamily: "HK_700Bold",
                fontSize: "12px",
                lineHeight: "15px",
                textTransform: "uppercase",
              }}
            >
              Invite
            </Button>
          </HStack>
        </Pressable>
      )}
    </>
  );
};
export default GroupCard;
