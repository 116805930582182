import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const ChevronDownArrowIcon = ({ size }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={size} viewBox="0 0 10 5">
        <G
          width="10"
          height="15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M1 0.5L5 4.5L9 0.5"
            stroke="#94A3B8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </G>
      </Icon>
    </>
  );
};

export default ChevronDownArrowIcon;
