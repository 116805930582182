import { useRoute } from "@react-navigation/native";
import { HStack, Image, Pressable, Text, VStack } from "native-base";
import DashIcon from "../icons/dashIcon";
import EllipseIcon from "../icons/ellipseIcon";
import SocialLinksPopover from "../popovers/socialLinksPopover";

const UserDetailCard = ({ pickImage, userData, image }) => {
  const route = useRoute();
  return (
    <VStack
      justifyContent={"space-between"}
      p={4}
      _light={{
        bg: "white",
        borderColor: "#E9B9FF",
        borderWidth: "1px",
      }}
      _dark={{
        bg: "primary.card",
      }}
      borderRadius={"20px"}
      h="200px"
      w={{
        md: "100%",
        lg: "100%",
        xl: "580px",
      }}
    >
      <HStack space={3}>
        {route?.params?.type === "profile" ? (
          <Pressable onPress={pickImage}>
            <Image
              alt=""
              size={100}
              bg="#5E17EB"
              rounded="8px"
              resizeMethod="scale"
              resizeMode="cover"
              fallbackSource={require("./../../assets/images/pngs/fallbackuser.png")}
              source={{
                uri: image ? image : userData?.image,
              }}
            ></Image>
          </Pressable>
        ) : (
          <Image
            alt=""
            size={100}
            bg="#5E17EB"
            rounded="8px"
            resizeMethod="scale"
            resizeMode="cover"
            fallbackSource={require("./../../assets/images/pngs/fallbackuser.png")}
            source={{
              uri: userData?.image,
            }}
          ></Image>
        )}
        <VStack flex={1} space={1}>
          <Text
            letterSpacing={2}
            textTransform={"uppercase"}
            fontSize={"22px"}
            style={{
              fontFamily: "Hero_700Bold",
            }}
            _light={{
              color: "#464646",
            }}
            _dark={{
              color: "white",
            }}
          >
            {userData?.username}
          </Text>
          <Text
            fontSize={14}
            lineHeight={"18px"}
            style={{
              fontFamily: "LeituraNewsRoman",
            }}
            _light={{
              color: "#464646",
            }}
            _dark={{
              color: "white",
            }}
          >
            {userData?.bio}
          </Text>
        </VStack>
      </HStack>
      <HStack
        alignItems={"center"}
        h="20px"
        justifyContent={"center"}
        space={6}
      >
        {[userData?.age, userData?.ethnicity, userData?.sunsign]?.map(
          (k, i) => (
            <>
              {k ? (
                <Text
                  noOfLines={1}
                  textAlign={"center"}
                  textTransform={"capitalize"}
                  _light={{
                    color: "#464646",
                  }}
                  _dark={{
                    color: "#94A3B8",
                  }}
                  fontSize={"14px"}
                  style={{
                    fontFamily: "Hero_400Regular",
                  }}
                >
                  {k}
                  {i === 0 && " Year old"}
                </Text>
              ) : (
                <DashIcon />
              )}
              {i < 3 && <EllipseIcon color="#464646" />}
            </>
          )
        )}

        <SocialLinksPopover userData={userData} />
      </HStack>
    </VStack>
  );
};

export default UserDetailCard;
