const { Image, Center, useColorMode } = require("native-base");

const ActiveDryHairTypeIcon = () => {
  const { colorMode } = useColorMode();
  return (
    <Center bg="#BE54FF40" borderRadius={"10px"}>
      <Image
        width={62}
        height={70}
        resizeMethod="scale"
        resizeMode="contain"
        source={
          colorMode === "dark"
            ? require("./../../../../assets/images/pngs/activeDryHairType.png")
            : require("./../../../../assets/images/pngs/lightactivedryhair.png")
        }
        alt=""
      />
    </Center>
  );
};

export default ActiveDryHairTypeIcon;
