import {
  Input,
  Stack,
  VStack,
  FlatList,
  View,
  HStack,
  Center,
  Pressable,
  Text,
} from "native-base";

import SearchIconOutlined from "../../../icons/searchIconOutlined";
import { Brands } from "../../../../constants/brands";
import { useDispatch, useSelector } from "react-redux";
import {
  changeProductsFilter,
  deleteProductsChipsValue,
  setProductsChips,
} from "../../../../redux/productsFilter/actions";

import CheckboxWhiteTickIcon from "../../../icons/checkboxWhiteTickIcon";
import { useEffect } from "react";
import { useState } from "react";
import Checkbox from "../../filterPopUp/buildingBlocks/checkbox";

const Item = ({ item, setSelectedBrands, selectedBrands }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selected) {
      if (selectedBrands.includes(item)) {
        setSelected(true);
      }
    }
  }, [selectedBrands]);

  const onPress = () => {
    if (selectedBrands.includes(item)) {
      setSelected(false);
      const filtered = selectedBrands.filter((value) => value !== item);
      setSelectedBrands(filtered);
      // dispatch(changeFilter({ brands: selectedBrands }));
    } else {
      setSelectedBrands([...selectedBrands, item]);
      setSelected(true);
      // dispatch(changeFilter({ brands: selectedBrands }));
    }
    // dispatch(changeFilter({ brands: selectedBrands }));
  };

  return (
    <Checkbox
      onPress={onPress}
      checked={selected}
      label={Brands[item]}
      item={item}
    />
  );
};

const Brand = ({ selectedBrands, setSelectedBrands }) => {
  const brandFilter = useSelector(
    (state) => state.productsFilter.cachedFilters.brands
  );
  const dispatch = useDispatch();
  const [brandResults, setBrandResults] = useState(Object.keys(Brands));

  const changeVal = async (val) => {
    let brandNames = Object.keys(Brands);
    let brResults = brandNames.filter((brand) => {
      return Brands[brand].toLowerCase().includes(val.toLowerCase());
    });
    setBrandResults(brResults);
  };

  const renderItem = ({ item }) => {
    return (
      <Item
        item={item}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
      />
    );
  };

  return (
    <VStack width={"100%"} pb={8} px={4} space={6}>
      <Input
        borderWidth={"1px"}
        borderColor={"#94A3B8"}
        style={{
          fontFamily: "HK_400Regular",
        }}
        autoCapitalize="none"
        placeholderTextColor="#94A3B8"
        fontSize={14}
        onChangeText={(val) => changeVal(val)}
        rounded={8}
        InputRightElement={
          <Stack px={4}>
            <SearchIconOutlined size={4} />
          </Stack>
        }
        color="white"
        _focus={{
          borderColor: "#94A3B8",
          backgroundColor: "#1A1C1E",
          color: "white",
        }}
        placeholder="Find Brands"
      />

      <FlatList
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 11 }}></View>
        )}
        contentContainerStyle={{
          paddingBottom: 12,
        }}
        data={brandResults}
        initialNumToRender={12}
        renderItem={renderItem}
        //   <Pressable
        //     onPress={() => {
        //       let vals = [...brandFilter];
        //       if (vals.includes(item)) {
        //         vals = [...vals.filter((value) => value !== item)];
        //       } else {
        //         vals.push(item);
        //       }

        //       dispatch(changeProductsFilter({ brands: vals, type: "all" }));
        //     }}
        //   >
        //     <HStack space={"10px"} alignItems="center">
        //       <Center
        //         p={"5px"}
        //         rounded={"4px"}
        //         borderWidth={"1px"}
        //         bg={
        //           brandFilter.includes(item) === true ? "purple.700" : "#2B2F33"
        //         }
        //         borderColor="#58595B"
        //         size={"20px"}
        //       >
        //         {brandFilter.includes(item) === true && (
        //           <CheckboxWhiteTickIcon />
        //         )}
        //       </Center>
        //       <Text
        //         fontSize={"16px"}
        //         style={{
        //           fontFamily: "HK_600SemiBold",
        //         }}
        //         color={
        //           brandFilter.includes(item) === true ? "white" : "#94A3B8"
        //         }
        //       >
        //         {Brands[item]}
        //       </Text>
        //     </HStack>
        //   </Pressable>
        // )}
      />
    </VStack>
  );
};

export default Brand;
