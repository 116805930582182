import {
  VIEW_BRAND
  } from './constants'
  
  
  export function viewBrand(brand){
    return {
      type: VIEW_BRAND,
      brand: brand,
    }
  }