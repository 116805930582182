import {
  Avatar,
  Text,
  HStack,
  VStack,
  Flex,
  Center,
  Image,
  Pressable,
} from "native-base";
import { useEffect, useState } from "react";
import { useShare } from "../../hooks/useShare";

const SpotsLeftCard = ({ images, id }) => {
  const [number, setNumber] = useState(0);

  const onShare = useShare();
  useEffect(() => {
    if (images && images.length >= 3) {
      setNumber(0);
    } else if (images) {
      setNumber(3 - images.length);
    } else {
      setNumber(0);
    }
  }, [images]);

  return (
    <Center
      bg={{
        linearGradient: {
          colors: [
            "primary.ctaLinearSecondColor",
            "primary.ctaLinearFirstColor",
          ],
          start: [0, 0],
          end: [1, 0],
        },
      }}
      rounded={"20px"}
      h="100px"
      w={{
        md: "346px",
        lg: "352px",
        xl: "372px",
      }}
    >
      <Flex
        alignItems={"center"}
        justifyContent={"space-around"}
        flexDirection={"row"}
        bg={"#202427"}
        rounded={"20px"}
        width={"100%"}
        py={4}
        px={2}
        h="98px"
        w={{
          md: "344px",
          lg: "350px",

          xl: "370px",
        }}
      >
        <VStack alignItems={"center"}>
          <Text
            letterSpacing={3}
            fontSize={"12px"}
            color={"#ffffff"}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
          >
            {number}/3{" "}
          </Text>
          <Text
            textTransform={"uppercase"}
            letterSpacing={3}
            fontSize={"12px"}
            color={"#94A3B8"}
            style={{
              fontFamily: "HK_700Bold",
            }}
          >
            spots open!
          </Text>
        </VStack>
        <HStack space={2}>
          {number === 2 &&
            [1, 2].map((i, j) => (
              <Pressable
                onPress={() =>
                  onShare(`group/${id}`, {
                    category: "Invite Friends Button",
                    event: `Shared Group via Invite Button`,
                    eventProperties: {
                      groupId: id,
                    },
                  })
                }
              >
                <Center
                  bg="#3B3B3B"
                  justifyContent={"center"}
                  size={"70px"}
                  rounded="full"
                  borderColor={"#1A94FF"}
                  borderWidth="1px"
                >
                  <Image
                    size={"24px"}
                    alt=""
                    source={require("./../../assets/images/gifs/blueMessageIcon.gif")}
                  />
                  <Text
                    lineHeight={"11px"}
                    textAlign={"center"}
                    color={"white"}
                    fontSize={"11px"}
                    style={{
                      fontFamily: "HK_600SemiBold",
                    }}
                  >
                    Invite{"\n"}Friends
                  </Text>
                </Center>
              </Pressable>
            ))}
          {number === 1 && (
            <Pressable
              onPress={() =>
                onShare(`group/${id}`, {
                  category: "Invite Friends Button",
                  event: `Shared Group via Invite Button`,
                  eventProperties: {
                    groupId: id,
                  },
                })
              }
            >
              <Center
                bg="#3B3B3B"
                justifyContent={"center"}
                size={"70px"}
                rounded="full"
                borderColor={"#1A94FF"}
                borderWidth="1px"
              >
                <Image
                  size={"24px"}
                  alt=""
                  source={require("./../../assets/images/gifs/blueMessageIcon.gif")}
                />
                <Text
                  lineHeight={"11px"}
                  textAlign={"center"}
                  color={"white"}
                  fontSize={"11px"}
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                >
                  Invite{"\n"}Friends
                </Text>
              </Center>
            </Pressable>
          )}

          {images &&
            images
              .filter((item, index) => index < 3)
              .map((image) => {
                return (
                  <Avatar
                    bg="#5E17EB"
                    size={"70px"}
                    fallbackSource={{
                      uri: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
                    }}
                    source={{
                      uri: image.image,
                    }}
                  ></Avatar>
                );
              })}
        </HStack>
      </Flex>
    </Center>
  );
};

export default SpotsLeftCard;
