import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const DiscountLeftBookmarkIconFilled = ({ color = "#8D49DE" }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={12} viewBox="0 0 38 20">
        <G
          width="38"
          height="20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M1.85208 0H37.9982V20H1.85208C1.02154 20 0.552976 19.0462 1.0606 18.3888L7.0666 10.6112C7.34462 10.2512 7.34462 9.74884 7.0666 9.38881L1.0606 1.61119C0.552976 0.953838 1.02154 0 1.85208 0Z"
            fill={color}
          />
        </G>
      </Icon>
    </>
  );
};

export default DiscountLeftBookmarkIconFilled;
