import {
  HStack,
  Text,
  Center,
  Pressable,
  useBreakpointValue,
} from "native-base";
import { Platform } from "react-native";
import GradientBorderButton from "../buttons/gradientBorderButton";
import ChevronDownArrowIcon from "../icons/chevronDownArrowIcon";
import ChevronLeftArrowIcon from "../icons/chevronLeftArrowIcon";
import ChevronUpArrowIcon from "../icons/chevronUpArrowIcon";

const FullWidthFilledAccordion = ({
  label,
  onPress,
  badge,
  startIcon,
  endText,
  pressed,
}) => {
  const breakPoint = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: "xl",
  });
  return (
    <>
      {label === "My Affiliate Earnings" && Platform.OS !== "web" ? (
        <Center w="full" h="50px">
          <GradientBorderButton onPress={onPress} gradientType="1">
            <HStack justifyContent="space-between" w="full" px={2}>
              <HStack space={2} alignItems="center">
                {startIcon && startIcon}
                <Text
                  _light={{
                    color: "#464646",
                  }}
                  _dark={{
                    color: "white",
                  }}
                  fontSize={16}
                  lineHeight={30}
                  style={{
                    fontFamily: "HK_400Regular",
                  }}
                >
                  {label}
                </Text>
              </HStack>
              <HStack alignItems={"center"} space={4}>
                {badge && badge}
                {label === "My Affiliate Earnings" && (
                  <Text
                    _light={{
                      color: "#464646",
                    }}
                    _dark={{
                      color: "white",
                    }}
                    fontSize={16}
                    style={{
                      fontFamily: "HK_800ExtraBold",
                    }}
                  >
                    $ {endText}
                  </Text>
                )}
                <ChevronLeftArrowIcon />
              </HStack>
            </HStack>
          </GradientBorderButton>
        </Center>
      ) : (
        <Pressable
          onPress={onPress}
          _hover={{
            bg: "#FAF1FF",
          }}
        >
          <HStack
            rounded="8px"
            space={{
              base: 2,
              sm: 2,
              md: 2,
              lg: 2,
            }}
            justifyContent={{
              base: "center",
              sm: "center",
              md: "center",
              lg: "center",
              xl: "space-between",
            }}
            w="full"
            h="50px"
            bg="#202427"
            px={2}
            _light={{
              bg: pressed ? "#E9B9FF" : "white",
              borderColor: "#E9B9FF",
              borderWidth: "1px",
            }}
            _dark={{
              bg: "#202427",
            }}
          >
            <HStack space={2} alignItems="center">
              {startIcon && startIcon}
              <Text
                _light={{
                  color: "#464646",
                }}
                _dark={{
                  color: "white",
                }}
                fontSize={16}
                lineHeight={30}
                style={{
                  fontFamily: "HK_400Regular",
                }}
              >
                {label}
              </Text>
            </HStack>
            <HStack alignItems={"center"} space={4}>
              {badge && badge}
              {label === "My Affiliate Earnings" && (
                <Text
                  _light={{
                    color: "#464646",
                  }}
                  _dark={{
                    color: "white",
                  }}
                  fontSize={16}
                  style={{
                    fontFamily: "HK_800ExtraBold",
                  }}
                >
                  $ {endText}
                </Text>
              )}
              {breakPoint === "xl" ? (
                <ChevronLeftArrowIcon />
              ) : pressed ? (
                <ChevronUpArrowIcon />
              ) : (
                <ChevronDownArrowIcon />
              )}
            </HStack>
          </HStack>
        </Pressable>
      )}
    </>
  );
};

export default FullWidthFilledAccordion;
