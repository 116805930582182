import {
  STORE_FOLLOWING,
  ADD_FOLLOWING,
  DELETE_FOLLOWING
} from './constants'


export function storeFollowing(people) {
  return {
    type: STORE_FOLLOWING,
    people:people
  }
}

export function addToFollowing(person) {
  return {
    type: ADD_FOLLOWING,
    person:person
  }
}

export function deleteFromFollowing(person) {
  return {
    type: DELETE_FOLLOWING,
    person:person
  }
}

