import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const LocationIconFilled = ({ onPress, size = 8 }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={size} onPress={onPress} viewBox="0 0 24 24">
        <G
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M11.8929 5C10.3305 5.00184 8.83275 5.62329 7.72802 6.72801C6.62329 7.83274 6.00185 9.33054 6.00001 10.8929C5.99814 12.1696 6.41517 13.4117 7.18715 14.4286C7.18715 14.4286 7.34786 14.6402 7.37411 14.6707L11.8929 20L16.4138 14.668C16.4373 14.6396 16.5986 14.4286 16.5986 14.4286L16.5991 14.427C17.3707 13.4105 17.7876 12.169 17.7857 10.8929C17.7839 9.33054 17.1624 7.83274 16.0577 6.72801C14.953 5.62329 13.4552 5.00184 11.8929 5ZM11.8929 13.0357C11.469 13.0357 11.0547 12.91 10.7024 12.6746C10.35 12.4391 10.0753 12.1044 9.91312 11.7129C9.75093 11.3213 9.7085 10.8905 9.79118 10.4748C9.87386 10.0591 10.078 9.67731 10.3776 9.37763C10.6773 9.07794 11.0591 8.87386 11.4748 8.79117C11.8905 8.70849 12.3213 8.75093 12.7129 8.91312C13.1045 9.0753 13.4391 9.34996 13.6746 9.70235C13.91 10.0547 14.0357 10.469 14.0357 10.8929C14.035 11.461 13.809 12.0056 13.4073 12.4073C13.0056 12.809 12.461 13.035 11.8929 13.0357Z"
            fill="#888990"
          />
        </G>
      </Icon>
    </>
  );
};

export default LocationIconFilled;
