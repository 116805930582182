import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const ActiveSearchIcon = () => {
  return (
    <Icon viewBox="0 0 18 18">
      <G width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M13.1911 11.6083H12.3575L12.0621 11.3234C12.7215 10.5574 13.2034 9.65499 13.4734 8.6809C13.7434 7.70682 13.7947 6.6851 13.6237 5.68886C13.1278 2.7555 10.6798 0.413034 7.72533 0.0542773C6.68664 -0.0771269 5.63166 0.0308227 4.64111 0.369866C3.65056 0.708909 2.75069 1.27006 2.01038 2.01038C1.27006 2.75069 0.708909 3.65056 0.369866 4.64111C0.0308227 5.63166 -0.0771269 6.68664 0.0542773 7.72533C0.413034 10.6798 2.7555 13.1278 5.68886 13.6237C6.6851 13.7947 7.70682 13.7434 8.6809 13.4734C9.65499 13.2034 10.5574 12.7215 11.3234 12.0621L11.6083 12.3575V13.1911L16.0928 17.6755C16.5254 18.1082 17.2324 18.1082 17.665 17.6755C18.0976 17.2429 18.0976 16.536 17.665 16.1033L13.1911 11.6083ZM6.86009 11.6083C4.23273 11.6083 2.11185 9.48746 2.11185 6.86009C2.11185 4.23273 4.23273 2.11185 6.86009 2.11185C9.48746 2.11185 11.6083 4.23273 11.6083 6.86009C11.6083 9.48746 9.48746 11.6083 6.86009 11.6083Z"
          fill="white"
        />
      </G>
    </Icon>
  );
};

export default ActiveSearchIcon;
