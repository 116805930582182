export const SKINSHADEMAP = {
  "Very light": "#FFEBDE",
  Light: "#ECC9AB",
  Medium: "#D8A387",
  Tan: "#C08556",
  Deep: "#895432",
  "Very Deep": "#44302D",
  "very light": "#FFEBDE",
  light: "#ECC9AB",
  medium: "#D8A387",
  tan: "#C08556",
  deep: "#895432",
  "very Deep": "#44302D",
};
