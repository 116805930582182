import { Pressable, VStack, Text } from "native-base";
import { useSelector } from "react-redux";
import BathAndBodyIcon from "./../../assets/images/svgs/bathAndBody.svg";
import FragranceIcon from "./../../assets/images/svgs/fragrance.svg";
import ForMenIcon from "./../../assets/images/svgs/forMen.svg";
import MakeupIcon from "./../../assets/images/svgs/makeup.svg";
import SkincareIcon from "./../../assets/images/svgs/skincare.svg";
import ToolsAndBrushesIcon from "./../../assets/images/svgs/toolsAndBrushes.svg";
import HairCareIcon from "./../../assets/images/svgs/haircare.svg";
import ValuesAndGiftSetsIcon from "./../../assets/images/svgs/valueAndGiftSets.svg";

const HomeScreenFilterCategoryCard = ({
  label,
  onPress,
  index,
  type = null,
  selected = false,
}) => {
  return (
    <Pressable
      onPress={onPress}
      borderColor={
        (type === null && selected) || (type === "products" && selected)
          ? //   productTypes.includes(index) === true) ||
            // productTypes2.includes(index) === true
            "#8D49DE"
          : "#58595B"
      }
      borderWidth={
        // productTypes.includes(index) === true
        selected && type === null ? "2px" : "1px"
      }
      rounded={"10px"}
      width={"110px"}
      py={2}
    >
      <VStack space={1} alignItems={"center"}>
        {label === "Bath & Body" && <BathAndBodyIcon />}
        {label === "Fragrance" && <FragranceIcon />}
        {label === "Men" && <ForMenIcon />}
        {label === "Hair" && <HairCareIcon />}
        {label === "Skincare" && <SkincareIcon />}
        {label === "Makeup" && <MakeupIcon />}
        {label === "Tools & Brushes" && <ToolsAndBrushesIcon />}
        {label === "Value & Gift Sets" && <ValuesAndGiftSetsIcon />}
        <Text
          color={
            (type === null && selected) ||
            //  productTypes.includes(index) === true
            (type === "products" && selected)
              ? "white"
              : "#94A3B8"
          }
          style={{
            fontFamily: "Inter_400Regular",
          }}
          fontSize={10}
          lineHeight={15}
          textAlign={"center"}
        >
          {label}
        </Text>
      </VStack>
    </Pressable>
  );
};

export default HomeScreenFilterCategoryCard;
