import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const DashIcon = () => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={2} viewBox="0 0 4 2">
        <G width="4" height="2" fill="none" xmlns="http://www.w3.org/2000/svg">
          <Path
            d="M0.09375 1.24023H3.8877V0.351562H0.09375V1.24023Z"
            fill="#94A3B8"
          />
        </G>
      </Icon>
    </>
  );
};

export default DashIcon;
