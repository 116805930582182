import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

const YellowStarIconFilled = ({ size }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={size} viewBox='0 0 22 22'>
        <G width='22' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <Path
            d='M11.6283 0.563256L14.4861 6.62794L20.8764 7.60213C21.0175 7.62346 21.1502 7.68576 21.2593 7.78198C21.3684 7.8782 21.4495 8.0045 21.4936 8.14658C21.5377 8.28865 21.543 8.44082 21.5087 8.58585C21.4745 8.73088 21.4022 8.86297 21.3 8.96717L16.675 13.6841L17.7667 20.3514C17.7908 20.4987 17.7751 20.6501 17.7213 20.7885C17.6676 20.9269 17.5779 21.0468 17.4626 21.1346C17.3472 21.2224 17.2106 21.2747 17.0684 21.2855C16.9261 21.2963 16.7838 21.2652 16.6576 21.1957L10.9427 18.0526L5.22698 21.1986C5.10061 21.2677 4.95831 21.2986 4.81611 21.2876C4.67391 21.2766 4.53747 21.2242 4.42217 21.1363C4.30686 21.0485 4.21728 20.9286 4.1635 20.7903C4.10973 20.6519 4.09391 20.5006 4.11783 20.3534L5.20952 13.6899L0.584538 8.97294C0.482342 8.86875 0.410041 8.73665 0.375823 8.59162C0.341605 8.4466 0.346839 8.29443 0.39093 8.15235C0.435022 8.01028 0.516209 7.88398 0.625298 7.78776C0.734386 7.69153 0.867016 7.62923 1.00817 7.60791L7.39842 6.6289L10.2563 0.564219C10.3195 0.430244 10.4172 0.317443 10.5384 0.238541C10.6595 0.159639 10.7993 0.117776 10.942 0.117676C11.0847 0.117576 11.2245 0.159242 11.3458 0.237974C11.467 0.316706 11.5649 0.42937 11.6283 0.563256Z'
            fill='#FFC400'
          />
        </G>
      </Icon>
    </>
  );
};

export default YellowStarIconFilled;
