import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../redux/currentState/actions";
import { publicApi } from "../util/API";
import * as RootNavigation from "../RootNavigation";
import { Pages } from "../constants/pages";
import { setAnalytics } from "../analytics";
import { useState } from "react";
import { Platform } from "react-native";

export const useVerifyOtpPhone = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [nextPressed, setNextPressed] = useState();
  const verifyOtpPhone = async (phone, code) => {
    if (!phone && !code) {
      setNextPressed(true);
      return;
    }
    dispatch(showLoader());
    let res = await publicApi(
      "/api/users/verify_phone_otp/",
      {
        phone: phone,
        code: code,
      },
      "post"
    );

    if (res.body.data.verified_status === true) {
      if (Platform.OS === "web") {
        setNextPressed(true);
      } else {
        RootNavigation.navigate(Pages.UploadPictureScreen, {
          type: "phone",
        });
      }
    } else if (res.body.data.verified_status === false) {
      setError(res.body.message);
    }
    setAnalytics(
      "Verify Phone Otp",
      `Phone Otp ${
        res.body.data.verified_status === true
          ? "Verified"
          : "Verification Failed"
      }`,
      {
        phone: phone,
        status: res.body.data.verified_status,
        message: res.body.message,
      }
    );
    dispatch(hideLoader());
  };

  return { verifyOtpPhone, error, nextPressed };
};
