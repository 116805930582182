import { Menu, HStack, Pressable, Text } from "native-base";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ChevronDownArrowIcon from "../icons/chevronDownArrowIcon";
import ChevronUpArrowIcon from "../icons/chevronUpArrowIcon";
import { storeForm } from "../../redux/form/actions";
import { setAnalytics } from "../../analytics";

const items = [
  "-",
  "Native American",
  "Black",
  "Latinx/Hispanic",
  "Middle Eastern",
  "Asian",
  "Pacific Islander",
  "South Asian",
  "Southeast Asian",
  "White",
];
const SurveyIdentityDropDown = ({ ethnicity }) => {
  const [addToCollectionPressed, setAddToCollectionPressed] = useState();
  const dispatch = useDispatch();
  const [label, setLabel] = useState(ethnicity ? ethnicity : items[0]);

  return (
    <>
      <Menu
        _dark={{
          bg: "#202427",
          borderColor: "#58595B",
          borderWidth: "0.5px",
          borderRadius: "5px",
        }}
        _light={{
          bg: "white",
        }}
        onOpen={() => setAddToCollectionPressed(true)}
        onClose={() => setAddToCollectionPressed(false)}
        my={2}
        _text={{ color: "#94A3B8" }}
        trigger={(triggerProps) => {
          return (
            <>
              <Pressable {...triggerProps} w="full">
                <HStack
                  px="22px"
                  py="13px"
                  w={{
                    base: "full",
                    sm: "full",
                    md: "full",
                    lg: "full",
                    xl: "303px",
                  }}
                  h="39px"
                  color="#464646"
                  variant="outline"
                  focusOutlineColor="#BE54FF"
                  _focus={{
                    bg: "none",
                    color: "#464646",
                  }}
                  _hover={{
                    bg: "none",
                    color: "#464646",
                    borderColor: "#BE54FF",
                  }}
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                  borderRadius="7px"
                  borderColor="#C1C9D2"
                  fontSize="14px"
                  borderWidth={"1px"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Text
                    _dark={{
                      color: label ? "white" : "#94A3B8",
                    }}
                    _light={{
                      color: label ? "#464646" : "#94A3B8",
                    }}
                    _style={{
                      fontFamily: "HK_500Medium",
                      fontSize: "16px",
                    }}
                  >
                    {label && label}
                  </Text>
                  {addToCollectionPressed ? (
                    <ChevronUpArrowIcon size={3} />
                  ) : (
                    <ChevronDownArrowIcon size={3} />
                  )}
                </HStack>
              </Pressable>
            </>
          );
        }}
      >
        {items.map((item, index) => (
          <Menu.Item
            w="full"
            _hover={{
              bg: "#F5F5F5",
            }}
            _pressed={{
              bg: "#202427",
            }}
            key={index}
            _text={{
              _dark: {
                color: label === item ? "white" : "#94A3B8",
              },
              _light: {
                color: "#000000D9",
              },
              _web: {
                fontFamily: "Hero_400Regular",
              },
              fontFamily: "HK_400Regular",
              fontSize: "12px",
            }}
            value={item}
            onPress={() => {
              setAnalytics(
                "Identity Dropdown Survey Screen",
                "Identity Dropdown Clicked",
                {
                  screen: "AboutYourSelfScreen",
                  ethnicity: items[index],
                }
              );
              setLabel(item);
              dispatch(storeForm({ ethnicity: items[index] }));
            }}
          >
            {item}
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
};

export default SurveyIdentityDropDown;
