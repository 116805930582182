import { SectionList } from "native-base";
import useGetUser from "../../hooks/useGetUser";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import UserDetails from "./buildingBlocks/userDetails";
import UserActivities from "./buildingBlocks/userActivities";
import useResetFilter from "../../hooks/useResetFilter";

const UserScreen = ({ route }) => {
  useResetFilter();
  const { data } = useGetUser(route?.params?.type, route?.params?.id);

  const components = [
    {
      data: [<UserDetails userData={data?.user[0]} points={data?.points} />],
    },
    {
      data: [<UserActivities data={data} />, <FooterSectionWeb />],
    },
  ];

  return (
    <SectionList
      keyboardShouldPersistTaps="handled"
      sections={components}
      showsVerticalScrollIndicator={false}
      stickySectionHeadersEnabled
      renderSectionHeader={(item) => <>{item.section?.header}</>}
      renderItem={({ item }) => item}
    />
  );
};

export default UserScreen;
