import { Box, Pressable, Text } from "native-base";

const GradientRoundedCornerNextButton = ({ onPress, text = "Next" }) => {
  return (
    <>
      <Pressable onPress={onPress}>
        <Box
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
          w="173.35px"
          h="50px"
          alignSelf={"center"}
          bg={{
            linearGradient: {
              colors: [
                "primary.ctaLinearFirstColor",
                "primary.ctaLinearSecondColor",
              ],
              start: [0, 0],
              end: [1, 0],
            },
          }}
          rounded="10px"
          _text={{
            color: "#ffffff",
          }}
        >
          <Text
            color="white"
            style={{
              fontFamily: "HK_600SemiBold",
            }}
            fontSize="14px"
          >
            {text}
          </Text>
        </Box>
      </Pressable>
    </>
  );
};

export default GradientRoundedCornerNextButton;
