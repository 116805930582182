// import {
//   VStack,
//   Text,
//   Box,
//   Input,
//   ScrollView,
//   Pressable,
//   Center,
// } from "native-base";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useState } from "react";
// import { initialiseForm, storeForm } from "../../redux/form/actions";
// //import { useGetKeyboardStatus } from "../../hooks/useGetKeyboardStatus";
// import { Pages } from "../../constants/pages";
// import { Keyboard } from "react-native";
// import GreenCheckmark from "./../../assets/images/svgs/GreenCheckmark.svg";

// import { publicApi } from "../../util/API";
// import { hideLoader, showLoader } from "../../redux/currentState/actions";
// import useCheckUserExists from "../../hooks/useCheckUserExists";
// import { useNavigation } from "@react-navigation/native";
// import PrimaryCTAButton from "../../components/buttons/primaryCTAButton";
// import CTAButtonText from "../../components/texts/ctaButtonText";
// const RegisterEmailScreen = () => {
//   const state = {
//     email: "",
//     password: "",
//     username: "",
//     image: "",
//     first_name: "",
//     last_name: "",
//     refferal_code: "",
//   };
//   // const keyboardStatus = useGetKeyboardStatus();
//   const form = useSelector((state) => state.form);
//   const dispatch = useDispatch();
//   const navigation = useNavigation();
//   const { data: username } = useCheckUserExists("username", form?.username);
//   const { data: email } = useCheckUserExists("email", form?.email);
//   useEffect(() => {
//     dispatch(initialiseForm());
//     dispatch(storeForm({ ...state, error: null }));
//   }, []);

//   const handleInput = (name) => (val) => {
//     setNextPressed();
//     dispatch(storeForm({ [name]: val, error: null }));
//   };
//   const [nextPressed, setNextPressed] = useState();

//   return (
//     <ScrollView
//       showsVerticalScrollIndicator={false}
//       keyboardShouldPersistTaps="handled"
//     >
//       <VStack
//         // pb={
//         //   keyboardStatus === "Keyboard Hidden" || keyboardStatus === undefined
//         //     ? 0
//         //     : 96
//         // }
//         alignItems={"center"}
//         px={{
//           md: "10px",
//           lg: "28px",
//           xl: "28px",
//         }}
//         pt={12}
//         space={"149px"}
//       >
//         <VStack w="full" space={"30px"}>
//           <VStack space={"10px"} w="full">
//             <Text
//               color={"#94A3B8"}
//               fontSize={"16px"}
//               letterSpacing={"sm"}
//               style={{
//                 fontFamily: "HK_500Medium",
//               }}
//             >
//               What's your email address?
//             </Text>
//             <Input
//               onChangeText={(val) => handleInput("email")(val)}
//               fontSize={"16px"}
//               lineHeight={"21px"}
//               autoCapitalize="none"
//               style={{
//                 fontFamily: "HK_700Bold",
//               }}
//               placeholderTextColor={"white"}
//               borderWidth={"1px"}
//               borderColor={"#58595B"}
//               p={"16px"}
//               rounded={"8px"}
//               color="white"
//               _focus={{
//                 borderColor: "#58595B",
//                 backgroundColor: "black",
//                 color: "white",
//               }}
//             ></Input>
//             {/\s/g.test(form?.email) && (
//               <Text
//                 fontSize={14}
//                 style={{
//                   fontFamily: "HK_400Regular",
//                 }}
//                 color="#ff0000"
//               >
//                 Email can't contain spaces.
//               </Text>
//             )}
//             {email?.status === 400 && (
//               <Text
//                 fontSize={14}
//                 style={{
//                   fontFamily: "HK_400Regular",
//                 }}
//                 color="#ff0000"
//               >
//                 {email?.message}.
//               </Text>
//             )}
//           </VStack>
//           <VStack space={"10px"} w="full">
//             <Text
//               color={"#94A3B8"}
//               fontSize={"16px"}
//               letterSpacing={"sm"}
//               style={{
//                 fontFamily: "HK_500Medium",
//               }}
//             >
//               Username
//             </Text>
//             <Input
//               rightElement={
//                 !/\s/g.test(form?.username) &&
//                 username?.status === 200 &&
//                 form?.username && (
//                   <Center px={3}>
//                     <GreenCheckmark />
//                   </Center>
//                 )
//               }
//               onChangeText={async (val) => handleInput("username")(val)}
//               fontSize={"16px"}
//               lineHeight={"21px"}
//               autoCapitalize="none"
//               style={{
//                 fontFamily: "HK_700Bold",
//               }}
//               placeholderTextColor={"white"}
//               borderWidth={"1px"}
//               borderColor={"#58595B"}
//               p={"16px"}
//               rounded={"8px"}
//               color="white"
//               _focus={{
//                 borderColor: "#58595B",
//                 backgroundColor: "black",
//                 color: "white",
//               }}
//             ></Input>
//             {/\s/g.test(form?.username) && (
//               <Text
//                 fontSize={14}
//                 style={{
//                   fontFamily: "HK_400Regular",
//                 }}
//                 color="#ff0000"
//               >
//                 Username can't contain spaces.
//               </Text>
//             )}
//             {username?.status === 400 && (
//               <Text
//                 fontSize={14}
//                 style={{
//                   fontFamily: "HK_400Regular",
//                 }}
//                 color="#ff0000"
//               >
//                 {username?.message}.
//               </Text>
//             )}
//           </VStack>
//           <VStack space={"10px"} w="full">
//             <Text
//               color={"#94A3B8"}
//               fontSize={"16px"}
//               letterSpacing={"sm"}
//               style={{
//                 fontFamily: "HK_500Medium",
//               }}
//             >
//               Referral Code (Optional)
//             </Text>
//             <Input
//               onChangeText={async (val) => handleInput("refferal_code")(val)}
//               fontSize={"16px"}
//               lineHeight={"21px"}
//               autoCapitalize="none"
//               style={{
//                 fontFamily: "HK_700Bold",
//               }}
//               placeholderTextColor={"white"}
//               borderWidth={"1px"}
//               borderColor={"#58595B"}
//               p={"16px"}
//               rounded={"8px"}
//               color="white"
//               _focus={{
//                 borderColor: "#58595B",
//                 backgroundColor: "black",
//                 color: "white",
//               }}
//             ></Input>
//           </VStack>
//           {(!form?.username || !form?.email) && nextPressed && (
//             <Text
//               fontSize={14}
//               style={{
//                 fontFamily: "HK_400Regular",
//               }}
//               color="#ff0000"
//             >
//               Fill credentials.
//             </Text>
//           )}
//         </VStack>

//         <Center h="50px" w="173.35px">
//           <PrimaryCTAButton
//             onPress={async () => {
//               setNextPressed(true);
//               Keyboard.dismiss();

//               if (
//                 !/\s/g.test(form?.username) &&
//                 !/\s/g.test(form?.email) &&
//                 form?.username &&
//                 form?.email &&
//                 username?.status === 200 &&
//                 email?.status === 200
//               ) {
//                 dispatch(showLoader());

//                 try {
//                   let res = await publicApi(
//                     "/api/users/send_otp_registration/",
//                     {
//                       email: form?.email,
//                     },
//                     "post"
//                   );

//                   if (res.body.status === 200) {
//                     navigation.navigate(Pages.VerifyScreen);
//                   }
//                 } catch (error) {}
//                 dispatch(hideLoader());
//               }
//             }}
//             gradientType="2"
//           >
//             <CTAButtonText text="Next" />
//           </PrimaryCTAButton>
//         </Center>
//       </VStack>
//     </ScrollView>
//   );
// };

// export default RegisterEmailScreen;

import { ScrollView } from "native-base";
import SignupHOC from "../../hoc/SignupHOC";
import EmailSignupForm from "../../components/forms/emailSignupForm.native";
import { useInitialiseForm } from "../../hooks/useInitialiseForm";

const RegisterEmailScreen = () => {
  useInitialiseForm();
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps="handled"
    >
      <SignupHOC>
        <EmailSignupForm />
      </SignupHOC>
    </ScrollView>
  );
};

export default RegisterEmailScreen;
