import axios from "axios";
import useSWR from "swr";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
import { getHeaders } from "../util/utilFunctions";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const fetcher = async (url) =>
  axios
    .get(url, {
      crossDomain: true,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data.data;
      } else {
        return null;
      }
    });
export default function useGetCelebContent(celebid) {
  const { data, mutate, isValidating, error } = useSWR(
    `${apiUrl}/api/users/content_of_celeb/?user_id=${celebid}`,
    fetcher
  );
  useCheckNetwork(error);
  useLoader(isValidating);
  return { data, mutate, isValidating };
}

///api/products/bundles_of_celeb/
