import { RESET_PRODUCTS_FILTERS, 
  CHANGE_PRODUCTS_FILTER,
  SET_PRODUCTS_PRICE,
  SET_APPLY_PRODUCTS_FILTER,
  RESET_PRODUCTS_CACHE_FILTERS,
  SET_PRODUCTS_CHIPS,
  DELETE_PRODUCTS_CHIPS,
  RESET_PRODUCTS_FILTERS_POPUP,
  DELETE_PRODUCTS_CHIPS_VALUE } from "./constants";

export function resetProductsFilters(filters) {
  return {
    type: RESET_PRODUCTS_FILTERS
  };
}

export function resetProductsFiltersPopup(filters) {
  return {
    type: RESET_PRODUCTS_FILTERS_POPUP
  };
}

export function changeProductsFilter(filter) {
  return {
    type: CHANGE_PRODUCTS_FILTER,
    filter: filter,
  };
}

export function setProductsPrice(low,high) {
  return {
    type: SET_PRODUCTS_PRICE,
    low: low,
    high:high
  };
}

export function setApplyProductsFilter() {
  return {
    type: SET_APPLY_PRODUCTS_FILTER
  };
}

export function resetProductsCacheFilters() {
  return {
    type: RESET_PRODUCTS_CACHE_FILTERS
  };
}

export function setProductsChips(chip) {
  return {
    type: SET_PRODUCTS_CHIPS,
    chip: chip,
  };
}

export function deleteProductsChips(itemId) {
  return {
    type: DELETE_PRODUCTS_CHIPS,
    itemId: itemId,
  };
}
export function deleteProductsChipsValue(value) {
  return {
    type: DELETE_PRODUCTS_CHIPS_VALUE,
    value: value,
  };
}
