import {
  Box,
  Center,
  HStack,
  Image,
  Pressable,
  SectionList,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import { useDispatch } from "react-redux";
import HighestEarnerProductCard from "../../components/cards/product/highestEarnerProductCard";
import BlueArrowIcon from "../../components/icons/blueArrowIcon";
import { Popups } from "../../constants/popups";
import useGetRewards from "../../hooks/useGetRewards";
import { setDialog } from "../../redux/currentState/actions";
import Orders from "./orders";
const AffiliateScreen = () => {
  const { data: rewards } = useGetRewards("affiliateRewards");
  const dispatch = useDispatch();
  const backgroundColor = useColorModeValue("white", "#111315");
  return (
    <>
      <SectionList
        px={8}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        stickySectionHeadersEnabled
        sections={[
          {
            data: [
              <Image
                w="full"
                h="188px"
                rounded={"40px"}
                style={{
                  overflow: "hidden",
                }}
                resizeMode="cover"
                resizeMethod="scale"
                source={require("./../../assets/images/pngs/affiliatewebbanner.png")}
                alt=""
              />,
            ],
          },
          {
            data: [
              <Stack
                direction={{
                  base: "column",
                  sm: "column",
                  md: "column",
                  lg: "column",
                  xl: "row",
                }}
                justifyContent={"space-between"}
                alignItems="center"
                pt="20px"
              >
                <HStack
                  justifyContent={"center"}
                  alignItems="center"
                  space={4}
                  flexWrap={"wrap"}
                  w="40%"
                >
                  {[
                    {
                      label: "Total Earnings",
                      points: rewards?.reward_stats?.total_earnings
                        ? rewards?.reward_stats?.total_earnings
                        : 0,
                    },
                    {
                      label: "Redeemable",
                      points: rewards?.reward_stats?.redeamable_earnings
                        ? rewards?.reward_stats?.redeamable_earnings
                        : 0,
                    },
                  ]?.map((k, i) => (
                    <Pressable
                      h="64px"
                      w="102px"
                      _hover={{
                        bg: "#FAF1FF",
                        h: "64px",
                        w: "102px",
                        rounded: "10px",
                      }}
                      onPress={() => {
                        if (i === 1 && k?.points > 0) {
                          dispatch(setDialog(Popups.RedeemEarningsModal));
                        }
                      }}
                    >
                      <VStack alignItems={"center"}>
                        <Text
                          textAlign={"center"}
                          _light={{
                            color: "#682481",
                          }}
                          _dark={{
                            color: "#94A3B8",
                          }}
                          fontSize="24px"
                          style={{
                            fontFamily: "HK_600SemiBold",
                          }}
                        >
                          ${k?.points}
                        </Text>
                        <HStack alignItems={"center"} space={1}>
                          <Text
                            textAlign={"center"}
                            _light={{
                              color: i === 1 ? "#682481" : "#464646",
                            }}
                            _dark={{
                              color: i === 1 ? "#1A94FF" : "#94A3B8",
                            }}
                            fontSize="12px"
                            style={{
                              fontFamily: "HK_700Bold",
                            }}
                          >
                            {k?.label}
                          </Text>
                          {i === 1 && <BlueArrowIcon color="#682481" />}
                        </HStack>
                      </VStack>
                    </Pressable>
                  ))}
                </HStack>
                <Center flex={1} w="full">
                  {rewards?.reward_stats?.highest_earner?.earning > 0 && (
                    <HighestEarnerProductCard
                      data={rewards?.reward_stats?.highest_earner}
                    />
                  )}
                </Center>
              </Stack>,
            ],
          },

          {
            header: (
              <Center w="full" safeAreaTop bg={backgroundColor}>
                <HStack
                  w="full"
                  _light={{
                    bg: "white",
                  }}
                  _dark={{
                    bg: "#1A1C1E",
                  }}
                  h="30px"
                  px={3}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Text
                    textTransform={"uppercase"}
                    textAlign={"center"}
                    _light={{
                      color: "#682481",
                    }}
                    _dark={{
                      color: "#94A3B8",
                    }}
                    fontSize="12px"
                    style={{
                      fontFamily: "HK_700Bold",
                    }}
                  >
                    Product
                  </Text>
                  <HStack space={4}>
                    {[
                      {
                        label: "Earning",
                      },
                    ]?.map((k) => (
                      <Text
                        textTransform={"uppercase"}
                        textAlign={"center"}
                        _light={{
                          color: "#682481",
                        }}
                        _dark={{
                          color: "#94A3B8",
                        }}
                        fontSize="12px"
                        style={{
                          fontFamily: "HK_700Bold",
                        }}
                      >
                        {k?.label}
                      </Text>
                    ))}
                  </HStack>
                </HStack>
              </Center>
            ),
            data: [<Orders data={rewards?.data} />],
          },
        ]}
        renderSectionHeader={(item) => <>{item.section?.header}</>}
        renderItem={({ item }) => item}
        ItemSeparatorComponent={(props) => {
          return <Box w="full" h="20px"></Box>;
        }}
      ></SectionList>
    </>
  );
};

export default AffiliateScreen;
