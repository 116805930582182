import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

const FollowUserIconOutlined = () => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon viewBox='0 0 15 14'>
        <G width='15' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <Path
            d='M15 6H13V4H12V6H10V7H12V9H13V7H15V6ZM5 1C5.49445 1 5.9778 1.14662 6.38893 1.42133C6.80005 1.69603 7.12048 2.08648 7.3097 2.54329C7.49892 3.00011 7.54843 3.50277 7.45196 3.98773C7.3555 4.47268 7.1174 4.91814 6.76777 5.26777C6.41814 5.6174 5.97268 5.8555 5.48773 5.95196C5.00277 6.04843 4.50011 5.99892 4.04329 5.8097C3.58648 5.62048 3.19603 5.30005 2.92133 4.88893C2.64662 4.4778 2.5 3.99445 2.5 3.5C2.5 2.83696 2.76339 2.20107 3.23223 1.73223C3.70107 1.26339 4.33696 1 5 1ZM5 0C4.30777 0 3.63108 0.205271 3.0555 0.589856C2.47993 0.974441 2.03133 1.52107 1.76642 2.16061C1.50151 2.80015 1.4322 3.50388 1.56725 4.18282C1.7023 4.86175 2.03564 5.48539 2.52513 5.97487C3.01461 6.46436 3.63825 6.7977 4.31718 6.93275C4.99612 7.0678 5.69985 6.99849 6.33939 6.73358C6.97893 6.46867 7.52556 6.02007 7.91014 5.4445C8.29473 4.86892 8.5 4.19223 8.5 3.5C8.5 2.57174 8.13125 1.6815 7.47487 1.02513C6.8185 0.368749 5.92826 0 5 0ZM10 14H9V11.5C9 10.837 8.73661 10.2011 8.26777 9.73223C7.79893 9.26339 7.16304 9 6.5 9H3.5C2.83696 9 2.20107 9.26339 1.73223 9.73223C1.26339 10.2011 1 10.837 1 11.5V14H0V11.5C0 10.5717 0.368749 9.6815 1.02513 9.02513C1.6815 8.36875 2.57174 8 3.5 8H6.5C7.42826 8 8.3185 8.36875 8.97487 9.02513C9.63125 9.6815 10 10.5717 10 11.5V14Z'
            fill='#94A3B8'
          />
        </G>
      </Icon>
    </>
  );
};

export default FollowUserIconOutlined;
