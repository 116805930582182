import {
  Button,
  Center,
  HStack,
  IconButton,
  Link,
  PresenceTransition,
  Pressable,
  ScrollView,
  Text,
  VStack,
} from "native-base";
import { useState } from "react";
import { Linking } from "react-native";
import { useSelector } from "react-redux";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import ChevronLeftArrowIcon from "../../components/icons/chevronLeftArrowIcon";
import MinusIcon from "../../components/icons/minusIcon";
import PlusIcon from "../../components/icons/plusIcon";
import { Pages } from "../../constants/pages";
``;

const FAQScreen = ({ navigation }) => {
  const userId = useSelector((state) => state.user.id);
  const [faqs, setFaqs] = useState([
    {
      question: "How do I place an order?",

      answer: (
        <Text
          style={{ fontFamily: "HK_500Medium" }}
          fontSize={16}
          lineHeight={22}
          color="white"
        >
          You can purchase products on Flock in two methods.{"\n\n"}
          <Text
            style={{ fontFamily: "HK_700Bold" }}
            fontSize={16}
            lineHeight={22}
            color="white"
          >
            Group-buying{"\n"}
            <Text
              style={{ fontFamily: "HK_500Medium" }}
              fontSize={16}
              lineHeight={22}
              color="white"
            >
              Purchase products in groups of 3 to unlock exclusive discounts.
              You can create your own group, invite friends, or join existing
              Flock Deal Groups. Groups are active for 24hrs and automatically
              complete orders when all spots are filled. If the group isn’t
              filled, payment methods will not be charged.{"\n\n"}
            </Text>
          </Text>
          <Text
            style={{ fontFamily: "HK_700Bold" }}
            fontSize={16}
            lineHeight={22}
            color="white"
          >
            Shop Solo{"\n"}
            <Text
              style={{ fontFamily: "HK_500Medium" }}
              fontSize={16}
              lineHeight={22}
              color="white"
            >
              Shop on your own. Add products to your cart and check out–no
              strings attached. And you get the best price guarantee.
            </Text>
          </Text>
        </Text>
      ),

      pressed: false,
    },

    {
      question: "When will my card be charged?",
      answer: `Your card will be charged once your order is confirmed. This can occur in 2 ways:
1. The group of 3 is filled within a 24-hour timeframe.
2. You place an order solo`,
      pressed: false,
    },

    {
      question: "Is this a subscription service?",
      answer: "No, there are no fees to use Flock.",
      pressed: false,
    },

    {
      question: "How do Flock rewards work?",
      answer: "More info on rewards coming soon!",
      pressed: false,
    },
    {
      question: "How does your best price guarantee work?",
      answer:
        "Our Best Price Guarantee - If a qualifying competitor reduces its price or offers a lower price on an item that we offer within 15 days of purchase, we will gladly match up to a 20% difference in the prices and provide a credit equal to the difference on your next order, as long as the item is in stock, and matches the size and color. If the competitor's item is 'Final Sale',  you will be issued a credit valid for 90 days for the price difference. Please note, that in all instances, the item must be in stock and match the size and color and we are unable to match promotional discounts, flash sales or outlet prices.",
      pressed: false,
    },

    {
      question: "How much does shipping cost?",
      answer: (
        <Text
          style={{ fontFamily: "HK_500Medium" }}
          fontSize={16}
          lineHeight={22}
          color="white"
        >
          Enjoy{" "}
          <Text
            style={{ fontFamily: "HK_700Bold" }}
            fontSize={16}
            lineHeight={22}
            color="white"
          >
            free shipping{" "}
          </Text>
          on all orders.
        </Text>
      ),

      pressed: false,
    },

    {
      question: "Will the product be sent to me directly?",
      answer: "Yes, we ship items directly to each group member.",
      pressed: false,
    },

    {
      question: "How long does it take to receive my order?",
      answer:
        "Please allow us 1-2 business days for processing. Once items are shipped, expect your order to arrive in 1-5 business days pending your location in the U.S.",
      pressed: false,
    },

    {
      question: "How do I return an order?",
      answer: (
        <Text
          style={{ fontFamily: "HK_500Medium" }}
          fontSize={16}
          lineHeight={22}
          color="white"
        >
          Check out our returns policy{" "}
          <Pressable onPress={() => navigation.navigate(Pages.ReturnsPolicy)}>
            <Text mb={-0.5} color={"#1A94FF"}>
              here
            </Text>
          </Pressable>
        </Text>
      ),
      pressed: false,
    },

    {
      question: "How much does it cost to return an item?",
      answer: (
        <Text
          style={{ fontFamily: "HK_500Medium" }}
          fontSize={16}
          lineHeight={22}
          color="white"
        >
          Check out our returns policy{" "}
          <Pressable onPress={() => navigation.navigate(Pages.ReturnsPolicy)}>
            <Text mb={-0.5} color={"#1A94FF"}>
              here
            </Text>
          </Pressable>
        </Text>
      ),
      pressed: false,
    },

    {
      question: "How do I track my order?",
      answer: `You will receive a shipment confirmation email when your order is ready to be shipped. This email will contain shipment details and your tracking number. To track your order, click on the tracking number attached in your email.

To track an order on Flock, visit your Account Information page. You will need to be signed into your account to access this page. Your order info will appear on your account once your payment method is charged and your package is shipped.

The risk of loss concerning the products in your order is transferred from Flock to you once your order is delivered. This transfer of title does not affect our returns policy.

For your convenience, you will see the expected delivery date for your order while you complete your purchase–when selecting a delivery option, on the Order Confirmation page & the Order Details page, and in your Order Confirmation email.
      `,
      pressed: false,
    },

    {
      question: "How do I update my profile?",
      answer: (
        <Text
          style={{ fontFamily: "HK_500Medium" }}
          fontSize={16}
          lineHeight={22}
          color="white"
        >
          Select your{" "}
          <Pressable
            onPress={() =>
              navigation.navigate(Pages.UserScreen, {
                id: userId,
                type: "profile",
              })
            }
          >
            <Text mb={-0.5} color={"#1A94FF"}>
              ‘Profile’{" "}
            </Text>
          </Pressable>
          on the bottom right corner of the app. You can edit your{" "}
          <Pressable
            onPress={() =>
              navigation.navigate(Pages.UserScreen, {
                id: userId,
                type: "profile",
              })
            }
          >
            <Text mb={-0.5} color={"#1A94FF"}>
              profile,{" "}
            </Text>
          </Pressable>
          <Pressable
            onPress={() => navigation.navigate(Pages.PaymentMethodsScreen)}
          >
            <Text mb={-0.5} color={"#1A94FF"}>
              payment,{" "}
            </Text>
          </Pressable>
          <Pressable onPress={() => navigation.navigate(Pages.AddressesScreen)}>
            <Text mb={-0.5} color={"#1A94FF"}>
              addresses{" "}
            </Text>
          </Pressable>
          and other{" "}
          <Pressable onPress={() => navigation.navigate(Pages.SettingScreen)}>
            <Text mb={-0.5} color={"#1A94FF"}>
              settings
            </Text>
          </Pressable>{" "}
          here.
        </Text>
      ),

      pressed: false,
    },

    {
      question: "How do I delete my account?",
      answer: (
        <Text
          style={{ fontFamily: "HK_500Medium" }}
          fontSize={16}
          lineHeight={22}
          color="white"
        >
          We hate to see you leave, but thank you for trying Flock! We would
          love to hear feedback on how we can improve our user experience. To
          delete your account, please email us at{" "}
          <Pressable
            onPress={() =>
              Linking.openURL(
                "mailto:hi@flockshopping.com?subject=SendMail&body=Description"
              )
            }
          >
            <Text mb={-0.5} color={"#1A94FF"}>
              hi@flockshopping.com
            </Text>
          </Pressable>
        </Text>
      ),
      pressed: false,
    },
  ]);

  return (
    <>
      <ScrollView zIndex={0} width="100%" showsVerticalScrollIndicator={false}>
        <VStack space={8} pb={4} px={6} pt={4}>
          <VStack space={2}>
            {faqs.map((item, index) => (
              <VStack key={index}>
                <HStack>
                  <Button
                    px={0}
                    onPress={() => {
                      let copy = [...faqs];
                      copy[index] = {
                        ...copy[index],
                        pressed: !copy[index].pressed,
                      };
                      setFaqs(copy);
                    }}
                    _text={{
                      fontFamily: "HK_700Bold",
                      fontSize: 16,
                      lineHeight: 20,
                      color: "#94A3B8",
                    }}
                    variant={"ghost"}
                    leftIcon={
                      item.pressed ? (
                        <IconButton
                          onPress={() => {
                            let copy = [...faqs];
                            copy[index] = { ...copy[index], pressed: false };
                            setFaqs(copy);
                          }}
                          icon={<MinusIcon />}
                        />
                      ) : (
                        <IconButton
                          onPress={() => {
                            let copy = [...faqs];
                            copy[index] = { ...copy[index], pressed: true };
                            setFaqs(copy);
                          }}
                          icon={<PlusIcon size={2} />}
                        />
                      )
                    }
                  >
                    {item.question}
                  </Button>
                </HStack>
                {item.pressed && (
                  <PresenceTransition
                    visible={item.pressed}
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                      transition: {
                        duration: 500,
                      },
                    }}
                  >
                    <HStack pl={8}>
                      <Text
                        style={{ fontFamily: "HK_500Medium" }}
                        fontSize={16}
                        lineHeight={22}
                        color="white"
                      >
                        {item.answer}
                      </Text>
                    </HStack>
                  </PresenceTransition>
                )}
              </VStack>
            ))}
          </VStack>
          {/* <Center>
            <Text
              style={{ fontFamily: "HK_500Medium" }}
              fontSize={16}
              lineHeight={22}
              color="white"
            >
              Didn’t find what you’re looking for?
            </Text>
            <Button
              _text={{
                fontFamily: "HK_600SemiBold",
                fontSize: 14,
                lineHeight: 21,
                color: "#1A94FF",
              }}
              variant={"ghost"}
              rightIcon={
                <HStack alignItems={"center"}>
                  <ChevronLeftArrowIcon color="#1A94FF" />
                </HStack>
              }
            >
              Let us help you
            </Button>
          </Center> */}
        </VStack>
      </ScrollView>
      <BottomBar />
    </>
  );
};

export default FAQScreen;
