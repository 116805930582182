import { TextInput, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  View,
  FlatList,
  HStack,
  Image,
  VStack,
  Center,
  Box,
  Button,
  Pressable,
} from "native-base";

import { useDispatch, useSelector } from "react-redux";
import { createFilters } from "../../util/utilFunctions";
import CollectionsSearchBar from "../cards/collectionsSearchBar";
import { React, useState, useEffect } from "react";
import { setAnalytics } from "../../analytics";
import * as RootNavigation from "./../../RootNavigation";
import EditCollectionPopUpTopBar from "../bars/popupTopBars/editCollectionPopUpTopBar";
import useGetProducts from "../../hooks/useGetProducts";
import { useCreateCollection } from "../../hooks/useCreateCollection";
import { LinearGradient } from "expo-linear-gradient";
import { privateApi } from "../../util/API";
import { hideLoader, showLoader } from "../../redux/currentState/actions";
import { ReturnVariants } from "../../screens/productScreen/buildingBlocks/productDetails";
import { useGetProductDetails } from "../../hooks/useGetProductDetails";
import FullWidthAccordion from "../accordions/fullWidthAccordion";

const ListItem = ({
  item,
  onPress,
  selectedItems,
  setSelectedProductSku,
  selectedProductSku,
}) => {
  const select = selectedItems.some((x) => x?.id === item.id);

  // console.log(item?.id);

  const route = {};
  const [selected, setSelected] = useState(false);
  const { product } = useGetProductDetails(
    item?.default_sku ? item?.id : item?.product?.id
  );

  const skuSelected =
    selectedProductSku?.[item?.default_sku ? item?.default_sku : item?.id]
      ?.selectedSku;

  let image = null;
  if (skuSelected) {
    image = product?.skus?.[skuSelected]?.images?.[0];
  }

  // console.log(skuSelected, "SKU", product, image);

  // console.log(product, "ITEM", item, "99999");

  useEffect(() => {
    setSelected(select);
  }, [selectedItems, select]);

  const type = "card";

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: selected ? "#8D49DE" : "#252628",
        padding: selected ? 1 : 0,
        borderRadius: 17,
      }}
      my={1}
    >
      {selected && (
        <Image
          position={"absolute"}
          zIndex={"100"}
          height={5}
          width={5}
          right={0}
          alt="selected"
          source={require("../../assets/images/pngs/selectedTick.png")}
        />
      )}

      <TouchableOpacity onPress={onPress} style={styles.listItem}>
        <HStack p={2} space={2}>
          <Image
            size={"70px"}
            source={{
              uri: image ? image : item.images[0],
            }}
            alt=""
            rounded={"8"}
          />

          <VStack w={"65%"}>
            <Text
              noOfLines={2}
              color={"white"}
              fontSize={16}
              lineHeight={24}
              w={"65%"}
              style={{
                fontFamily: "HK_600SemiBold",
              }}
            >
              {item.name ? item.name : item.product_name}
            </Text>

            <VStack space={"1px"}>
              {product?.variant?.color !== null && (
                <FullWidthAccordion
                  // type={type}
                  id={item?.default_sku ? item?.default_sku : item?.id}
                  setSelectedProductSku={setSelectedProductSku}
                  selectedProductSku={selectedProductSku}
                  type={"card"}
                  label={"Color"}
                  items={product?.colors}
                  wholeProduct={product}
                  val={
                    item?.default_sku
                      ? product?.skus[item?.default_sku]?.color
                      : item?.color
                  }
                />
              )}
              {product?.variant?.scent !== null && (
                <FullWidthAccordion
                  id={item?.default_sku ? item?.default_sku : item?.id}
                  wholeProduct={product}
                  setSelectedProductSku={setSelectedProductSku}
                  selectedProductSku={selectedProductSku}
                  type={type}
                  label={"Fragrance"}
                  items={product?.scents}
                  val={
                    item?.default_sku
                      ? product?.skus[item?.default_sku]?.scent
                      : item?.scent
                  }
                />
              )}
              {product?.variant?.size !== null && (
                <FullWidthAccordion
                  id={item?.default_sku ? item?.default_sku : item?.id}
                  wholeProduct={product}
                  setSelectedProductSku={setSelectedProductSku}
                  selectedProductSku={selectedProductSku}
                  type={type}
                  label={"Size"}
                  items={product?.sizes}
                  val={
                    product?.skus[
                      item?.default_sku ? item?.default_sku : item?.id
                    ]?.size
                  }
                />
              )}
              {product?.variant?.type !== null && (
                <FullWidthAccordion
                  id={item?.default_sku ? item?.default_sku : item?.id}
                  wholeProduct={product}
                  setSelectedProductSku={setSelectedProductSku}
                  selectedProductSku={selectedProductSku}
                  type={type}
                  label={"Type"}
                  items={product?.types}
                  val={
                    product?.skus[
                      item?.default_sku ? item?.default_sku : item?.id
                    ]?.type
                  }
                />
              )}
            </VStack>

            {/* <ReturnVariants type="card" id={item?.id || item?.product?.id} /> */}
          </VStack>

          {/* <Text>***prop</Text> */}

          <HStack alignItems={"center"}>
            <Text
              color={"white"}
              fontSize={16}
              style={{
                fontFamily: "HK_700Bold",
              }}
              textAlign="center"
            >
              ${item.retail_price}
            </Text>
          </HStack>
        </HStack>
      </TouchableOpacity>
    </View>
  );
};

const EditCollectionScreenProductCard = () => {
  const route = RootNavigation.navigationRef.getCurrentRoute();
  const dispatch = useDispatch();

  const [selectedProductSku, setSelectedProductSku] = useState({});

  const title = route?.params?.title;
  const name = route?.params?.name;
  const product_skus = route?.params?.product_skus;
  const editCollection = route?.params?.editCollection;
  const collectionId = route?.params?.collectionId;

  const [text, setText] = useState(name ? name : "");
  const filter = useSelector((state) => state.productsFilter);
  const filterValues = createFilters(filter);

  const { data, size, setSize } = useGetProducts("homeProducts", filterValues);

  const [selectedItems, setSelectedItems] = useState([]);
  const [firstThreeProducts, setFirstThreeProducts] = useState([]);

  const [search, setSearch] = useState("");

  const [products, setProducts] = useState([]);

  const { createCollection, setError, error } = useCreateCollection();

  // console.log(
  //   selectedProductSku,
  //   product_skus,
  //   selectedItems,
  //   "201***************"
  // );

  const handleOnPress = (product) => {
    if (selectedItems.some((item) => item.id === product.id)) {
      const deleted = selectedItems.filter((item) => item.id != product.id);
      const filtered = firstThreeProducts.filter(
        (item) => item.id != product.id
      );
      const first = deleted.slice(0, 3);
      setFirstThreeProducts(first);
      setSelectedItems(deleted);
    } else {
      if (firstThreeProducts.length < 3) {
        setFirstThreeProducts([...firstThreeProducts, product]);
      }
      setSelectedItems([...selectedItems, product]);
    }

    // here you can add you code what do you want if user just do single tap
    setAnalytics(
      "Product Card",
      "Product Card Selected From Create Collection",
      {
        productId: product?.id,
        productSkuId: product?.default_sku,
        screen: route.name,
      }
    );
  };

  // console.log(selectedItems, "UUUUU", product_skus, "****************");

  useEffect(() => {
    if (product_skus?.length > 0) {
      setSelectedItems([...product_skus, ...selectedItems]);
      if (product_skus.length > 3) {
        const first = product_skus.slice(0, 3);
        setFirstThreeProducts(first);
      } else {
        setFirstThreeProducts(product_skus);
      }
    }
  }, [product_skus]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (search) {
          const res = await privateApi(
            `/api/home/search/?query_string=${search}`,
            "",
            "get"
          );
          console.log(res, "What i getting");
          setProducts(res?.body?.products);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [search]);

  return (
    <>
      <VStack height={"100%"} bg={"#2B2F33"} width="100%">
        <VStack pl={"5"} safeAreaTop>
          <EditCollectionPopUpTopBar title={title ? title : null} />
        </VStack>
        <View p="5">
          <Text
            fontSize={15}
            style={{
              color: "white",
              marginRight: 180,
              fontFamily: "Poppins_500Medium",
            }}
          >
            {" "}
            Collection Name
          </Text>
          <TextInput
            style={{
              height: 40,

              borderColor: "white",
              borderWidth: 1,
              marginTop: 10,
              borderRadius: 5,
              color: "white",

              marginLeft: 5,
              paddingLeft: 10,
            }}
            value={text}
            placeholder="Type here !"
            onChangeText={(newText) => {
              setText(newText);
              setError(null);
            }}
          />
          {error ? (
            <Text mt={2} color="red.900">
              {error}
            </Text>
          ) : null}
        </View>

        <View px={5} style={{ marginTop: 15 }}>
          <Text
            fontSize={15}
            style={{
              color: "white",
              marginRight: 210,
              fontFamily: "Poppins_500Medium",
            }}
          >
            {" "}
            Add Products
          </Text>

          <View style={{ marginBottom: 5 }}>
            <CollectionsSearchBar search={search} setSearch={setSearch} />
          </View>
        </View>

        {data && (
          <>
            <View style={{ flex: 1, padding: 15 }}>
              <FlatList
                data={
                  search?.length > 1 && products?.length > 0
                    ? [
                        // ...selectedItems,
                        ...products?.filter((item) => {
                          let temp = selectedItems?.some(
                            (x) =>
                              x.default_sku === item.default_sku ||
                              x.id === item.default_sku
                          );
                          if (temp) {
                            return false;
                          }
                          return true;
                        }),
                      ]
                    : product_skus
                    ? [
                        ...selectedItems,
                        // ...product_skus?.filter((item) => {
                        //   let temp = selectedItems.some(
                        //     (x) =>
                        //       x?.default_sku === item?.default_sku ||
                        //       x?.id === item?.default_sku ||
                        //       x?.id === item?.id ||
                        //       x?.product?.id === x?.id ||
                        //       x?.product?.id === item?.product?.id ||
                        //       x?.is === item?.product?.id
                        //   );
                        //   if (temp) {
                        //     return false;
                        //   }
                        //   return true;
                        // }),
                        ...data.filter((item) => {
                          let temp = selectedItems.some(
                            (x) =>
                              x?.id === item?.default_sku ||
                              x?.product?.id === item.id ||
                              x?.id === item?.id ||
                              x?.default_sku === item?.default_sku
                          );
                          if (temp) {
                            return false;
                          }
                          return true;
                        }),
                      ]
                    : selectedItems?.length > 0 && !product_skus
                    ? [
                        ...selectedItems,
                        ...data.filter((item) => {
                          let temp = selectedItems.some(
                            (x) =>
                              x.default_sku === item.default_sku ||
                              x?.id === item.default_sku
                          );
                          if (temp) {
                            return false;
                          }
                          return true;
                        }),
                      ]
                    : data
                }
                ListEmptyComponent={() => {
                  <Center>
                    <Text color={"white"}>No Product!</Text>
                  </Center>;
                }}
                renderItem={({ item }) => (
                  <ListItem
                    onPress={() => handleOnPress(item)}
                    selectedItems={selectedItems}
                    selected={selectedItems.some((item) => item?.id)}
                    item={item}
                    setSelectedProductSku={setSelectedProductSku}
                    selectedProductSku={selectedProductSku}
                  />
                )}
                onEndReached={() => {
                  setSize(size + 1);
                }}
                keyExtractor={(item) => item.id}
              />
            </View>
          </>
        )}
        <HStack
          bg="#2B2F33"
          h={{
            md: "120px",
            lg: "120px",
            xl: "120px",
          }}
          px={4}
          justifyContent="space-between"
        >
          <HStack>
            {firstThreeProducts.map((item) => {
              const skuSelected =
                selectedProductSku?.[
                  item?.default_sku ? item?.default_sku : item?.id
                ]?.selectedSku;

              const product =
                selectedProductSku?.[
                  item?.default_sku ? item?.default_sku : item?.id
                ]?.product;

              let image = null;
              if (skuSelected && product) {
                image = product?.skus?.[skuSelected]?.images?.[0];
              }

              return (
                <Image
                  rounded={"4"}
                  mr={3}
                  style={{
                    width: 40,
                    height: 40,
                  }}
                  source={{
                    uri: image ? image : item?.images[0],
                  }}
                  alt="gift box"
                />
              );
            })}

            {selectedItems?.length > 3 ? (
              <View h="36" w="36" borderRadius="18" bg="#3C3C3C">
                <Text
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    textAlignVertical: "center",
                    ...Platform.select({
                      ios: {
                        lineHeight: 36, // as same as height
                      },
                      android: {
                        lineHeight: 36,
                      },
                    }),
                  }}
                  color="#94A3B8"
                  fontSize="14px"
                >
                  + {selectedItems.length - 3}{" "}
                </Text>
              </View>
            ) : (
              <Box></Box>
            )}
          </HStack>
          <Pressable
            onPress={() => {
              const selctedIds = selectedItems.map((item) =>
                item?.default_sku ? item?.default_sku : item?.id
              );
              const skusId = Object.values(selectedProductSku);
              const finalId = selctedIds?.map((item) => {
                if (skusId?.some((x) => x?.id === item)) {
                  return selectedProductSku[item]?.selectedSku;
                }
                return item;
              });

              // const ids = selectedItems.forEach((item) => {
              //   let pushed = false;
              //   console.log(item, item.default_sku, "457****");
              //   const dSKU = item.default_sku ? item.default_sku : item.id;
              //   Object.values(selectedProductSku)?.forEach((x) => {
              //     console.log(x, pushed, finalId, "Only focus on this");
              //     if (x?.id === dSKU) {
              //       finalId.push(x?.selectedSku);
              //     }
              //     // TODO: MAKE AN CHECK
              //     // Here
              //     else if (
              //       !(x?.skus?.find(dSKU) && x?.skus?.find(x?.selectedSku))
              //     ) {
              //       finalId.push(dSKU);
              //     }
              //   });
              // });

              if (text && finalId.length > 0 && !editCollection) {
                createCollection(text, finalId, "", "post");
              }
              if (text && finalId.length > 0 && editCollection) {
                createCollection(text, finalId, collectionId, "put");
              }
            }}
          >
            <LinearGradient
              start={[0, 1]}
              end={[1, 0]}
              colors={["#D75D86", "#5E17EB"]}
              style={{
                padding: 1,
                borderRadius: 10,
              }}
            >
              <Center
                h="45px"
                w="100px"
                variant={"ghost"}
                rounded={10}
                backgroundColor="#2B2F33"
              >
                <Text
                  style={{
                    color: "white",
                    fontFamily: "HK_500Medium",
                    fontSize: 16,
                  }}
                >
                  Save
                </Text>
              </Center>
            </LinearGradient>
          </Pressable>
        </HStack>
      </VStack>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 1,
    backgroundColor: "#8D49DE",
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
  },
  listItem: {
    backgroundColor: "#252628",

    overflow: "scroll",
    borderRadius: 17,
    width: "100%",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderColor: "violet",
    borderWidth: 1,
    borderRadius: 5,
  },
});

export default EditCollectionScreenProductCard;
