import { Button, Center, FlatList, VStack } from "native-base";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UsersContext } from "../../hoc/UsersHOC";
import useGetNumberOfColumns from "../../hooks/useGetNumberOfColumns";
import { showLoader } from "../../redux/currentState/actions";
import BeautyBuddiesCard from "../cards/beautyBuddiesCard";

const UsersColumnLayout = () => {
  const userId = useSelector((state) => state.user.id);
  const key = (item) => item?.id;
  const dispatch = useDispatch();
  const { data, setSize } = useContext(UsersContext);
  const numberOfColumns = useGetNumberOfColumns(5);

  const renderItem = ({ item }) => (
    <Center m={1.5}>
      <BeautyBuddiesCard
        cardWidth={{
          base: "170px",
          sm: "170px",
          md: "200px",
          lg: "200px",
          xl: "200px",
          xxl: "200px",
        }}
        imageWidth={{
          base: "175px",
          sm: "175px",
          md: "175px",
          lg: "175px",
          xl: "175px",
          xxl: "175px",
        }}
        item={item}
      />
    </Center>
  );
  return (
    <VStack w="full" alignItems={"center"} space={4} pb={8}>
      <FlatList
        key={numberOfColumns}
        keyExtractor={key}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        initialNumToRender={2}
        columnWrapperStyle={{
          justifyContent: numberOfColumns === 2 ? "center" : "flex-start",
        }}
        data={data?.filter((k) => k?.id !== userId && !k?.celeb)}
        _web={{
          numColumns: numberOfColumns,
        }}
        renderItem={renderItem}
      />

      {data?.length > 0 && (
        <Button
          _pressed={{
            bg: "#FAF1FF",
          }}
          _hover={{
            bg: "#FAF1FF",
          }}
          onPress={() => {
            dispatch(showLoader());
            setSize();
          }}
          variant={"ghost"}
          _text={{
            color: "#464646",
            fontFamily: "Hero_700Bold",
            fontSize: "18px",
          }}
        >
          Load more
        </Button>
      )}
    </VStack>
  );
};

export default UsersColumnLayout;
