import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const WebBagIcon = () => {
  return (
    <Icon viewBox="0 0 21 20">
      <G>
        <Path
          d="M19.3878 4.89796H15.102V4.69388C15.102 3.44898 14.6075 2.25508 13.7272 1.3748C12.847 0.494532 11.6531 0 10.4082 0C9.16327 0 7.96936 0.494532 7.08909 1.3748C6.20882 2.25508 5.71429 3.44898 5.71429 4.69388V4.89796H1.42857C1.04969 4.89796 0.686328 5.04847 0.418419 5.31638C0.15051 5.58429 0 5.94765 0 6.32653V18.5714C0 18.9503 0.15051 19.3137 0.418419 19.5816C0.686328 19.8495 1.04969 20 1.42857 20H19.3878C19.7666 20 20.13 19.8495 20.3979 19.5816C20.6658 19.3137 20.8163 18.9503 20.8163 18.5714V6.32653C20.8163 5.94765 20.6658 5.58429 20.3979 5.31638C20.13 5.04847 19.7666 4.89796 19.3878 4.89796ZM6.93878 4.69388C6.93878 3.77374 7.3043 2.89129 7.95494 2.24065C8.60557 1.59001 9.48802 1.22449 10.4082 1.22449C11.3283 1.22449 12.2108 1.59001 12.8614 2.24065C13.512 2.89129 13.8776 3.77374 13.8776 4.69388V4.89796H6.93878V4.69388ZM19.5918 18.5714C19.5918 18.6256 19.5703 18.6775 19.5321 18.7157C19.4938 18.754 19.4419 18.7755 19.3878 18.7755H1.42857C1.37445 18.7755 1.32254 18.754 1.28426 18.7157C1.24599 18.6775 1.22449 18.6256 1.22449 18.5714V6.32653C1.22449 6.27241 1.24599 6.2205 1.28426 6.18222C1.32254 6.14395 1.37445 6.12245 1.42857 6.12245H5.71429V7.95918C5.71429 8.12156 5.77879 8.27729 5.89361 8.39211C6.00843 8.50692 6.16415 8.57143 6.32653 8.57143C6.48891 8.57143 6.64463 8.50692 6.75945 8.39211C6.87427 8.27729 6.93878 8.12156 6.93878 7.95918V6.12245H13.8776V7.95918C13.8776 8.12156 13.9421 8.27729 14.0569 8.39211C14.1717 8.50692 14.3274 8.57143 14.4898 8.57143C14.6522 8.57143 14.8079 8.50692 14.9227 8.39211C15.0375 8.27729 15.102 8.12156 15.102 7.95918V6.12245H19.3878C19.4419 6.12245 19.4938 6.14395 19.5321 6.18222C19.5703 6.2205 19.5918 6.27241 19.5918 6.32653V18.5714Z"
          fill="#464646"
        />
      </G>
    </Icon>
  );
};

export default WebBagIcon;
