import { Button, HStack, useColorModeValue } from "native-base";
import { useState } from "react";
import RegisterEmailScreen from "./emailScreen";
import RegisterNumberScreen from "./numberScreen";

const PhoneEmailSignupScreen = () => {
  const [signUpType, setSignUpType] = useState([
    {
      pressed: true,
      component: <RegisterEmailScreen />,
      label: "Email",
    },
    {
      pressed: false,
      component: <RegisterNumberScreen />,
      label: "Phone",
    },
  ]);
  const backgroundColor = useColorModeValue("white", "#111315");
  return (
    <>
      <HStack pt="20px" w="full" justifyContent={"space-around"}>
        {signUpType?.map((k, index) => (
          <Button
            _pressed={{
              bg: backgroundColor,
            }}
            onPress={() => {
              let copy = [...signUpType];

              copy.map((k) => {
                if (k?.pressed) {
                  return (k.pressed = false);
                }
              });

              copy[index] = { ...copy[index], pressed: true };
              setSignUpType(copy);
            }}
            w="1/3"
            borderBottomWidth="2px"
            borderBottomColor={k?.pressed ? "#7B61FF" : backgroundColor}
            variant={"ghost"}
            _text={{
              fontSize: "16px",
              fontFamily: "HK_500Medium",
              color: k?.pressed ? "#fff" : "gray.500",
            }}
          >
            {k?.label}
          </Button>
        ))}
      </HStack>

      {signUpType?.map((k) => (
        <>{k?.pressed && k?.component}</>
      ))}
    </>
  );
};

export default PhoneEmailSignupScreen;
