import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const ChevronBackArrowIcon = ({ onPress }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={8} onPress={onPress} viewBox="0 0 36 36">
        <G
          width="36"
          height="36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            opacity="0.12"
            d="M27 0H9C4.02944 0 0 4.02944 0 9V27C0 31.9706 4.02944 36 9 36H27C31.9706 36 36 31.9706 36 27V9C36 4.02944 31.9706 0 27 0Z"
            fill="white"
          />
          <Path
            d="M20.3963 12.42C20.5857 12.2291 20.6919 11.9711 20.6919 11.7022C20.6919 11.4334 20.5857 11.1754 20.3963 10.9845C20.3035 10.8902 20.1928 10.8153 20.0707 10.7642C19.9487 10.7131 19.8177 10.6868 19.6853 10.6868C19.553 10.6868 19.422 10.7131 19.2999 10.7642C19.1778 10.8153 19.0672 10.8902 18.9743 10.9845L13.3493 16.722C13.1605 16.9134 13.0546 17.1714 13.0546 17.4403C13.0546 17.7092 13.1605 17.9672 13.3493 18.1586L18.9743 23.8961C19.0672 23.9904 19.1778 24.0653 19.2999 24.1164C19.422 24.1675 19.553 24.1938 19.6853 24.1938C19.8177 24.1938 19.9487 24.1675 20.0707 24.1164C20.1928 24.0653 20.3035 23.9904 20.3963 23.8961C20.5852 23.7047 20.6911 23.4467 20.6911 23.1778C20.6911 22.9089 20.5852 22.6509 20.3963 22.4595L15.7872 17.4375L20.3963 12.42Z"
            fill="white"
          />
        </G>
      </Icon>
    </>
  );
};

export default ChevronBackArrowIcon;
