import {
  Divider,
  HStack,
  Pressable,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import ClaimedRewards from "./claimedRewards";
import useGetRewards from "../../hooks/useGetRewards";
import UnclaimedRewards from "./unclaimedRewards";
import { useDispatch } from "react-redux";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import RewardsIcon from "../../components/icons/rewardsIcon";
import ExclaimationBlueEllipseIcon from "../../components/icons/exclaimationBlueEllipseIcon";
import { convertPoints } from "../../util/utilFunctions";
import { Popups } from "../../constants/popups";
import { setDialog } from "../../redux/currentState/actions";
import useResetFilter from "../../hooks/useResetFilter";
const RewardsScreen = () => {
  useResetFilter();
  const { data } = useGetRewards("allRewards");
  const backgroundColor = useColorModeValue("white", "#111315");
  const dispatch = useDispatch();
  console.log("data", data && JSON.parse(data[0].actions_json).unclaimed);
  return (
    <>
      <VStack space={"50px"} flex={1}>
        <VStack>
          <VStack
            h={{
              md: "186px",
              lg: "186px",
              xl: "186px",
            }}
            roundedBottom={{
              md: "40px",
              lg: "40px",
              xl: "100px",
            }}
            bg={{
              linearGradient: {
                colors: ["#691DE3", "#FF5A92"],
                start: [0, 0],
                end: [1, 0],
              },
            }}
            justifyContent="center"
            alignItems="center"
          >
            <VStack alignItems="center">
              <Text
                color="#FAF1FF"
                fontSize={"36px"}
                style={{
                  fontFamily: "AustinCyr_400",
                }}
              >
                Rewards
              </Text>
              <Stack
                space={4}
                direction={{
                  base: "column",
                  sm: "column",
                  md: "column",
                  lg: "column",
                  xl: "row",
                }}
                justifyContent="center"
                alignItems={"center"}
              >
                <Text
                  w="40%"
                  color="#FAF1FF"
                  fontSize={"16px"}
                  style={{
                    fontFamily: "LeituraNewsRoman",
                  }}
                >
                  Rewards on Flock are fr! You earn on simple tasks which can be
                  claimed for real-time purchases. So cool, isn’t it? Now go!
                  Shop till you drop!
                </Text>
                <HStack alignItems={"center"}>
                  <RewardsIcon size={7} />
                  <Text
                    color="#FAF1FF"
                    fontSize={"36px"}
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                  >
                    {data && data[0].points}
                  </Text>
                </HStack>
                <Divider bg="#E9B9FF" orientation="vertical" h="30px" />
                <Pressable
                  onPress={() => {
                    dispatch(setDialog(Popups.RewardsInfoModal));
                  }}
                >
                  <HStack
                    borderBottomWidth={"1px"}
                    borderBottomColor={"white"}
                    alignItems={"center"}
                    space={"4px"}
                    justifyContent="center"
                    // w="130px"
                    h="30px"
                    px={2}
                  >
                    <ExclaimationBlueEllipseIcon />

                    {data && (
                      <Text
                        style={{
                          fontFamily: "HK_400Regular",
                        }}
                        fontSize={16}
                        lineHeight={20}
                        color="white"
                      >
                        worth{" "}
                        <Text
                          style={{
                            fontFamily: "HK_600SemiBold",
                          }}
                          fontSize={16}
                          lineHeight={20}
                          color="white"
                        >
                          $
                          {data && data[0].points
                            ? convertPoints(data[0].points).toFixed(2)
                            : 0}
                        </Text>
                      </Text>
                    )}
                  </HStack>
                </Pressable>
              </Stack>
            </VStack>
          </VStack>
        </VStack>

        <Stack
          w="full"
          space={{
            base: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: 4,
          }}
          px={{
            base: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: "120px",
          }}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
          justifyContent="center"
        >
          <VStack w="60%">
            <HStack
              space={2}
              bg={backgroundColor}
              h="50px"
              alignItems={"center"}
            >
              {/* <LevelupIcon /> */}
              {data && (
                <Text
                  _light={{
                    color: "#682481",
                  }}
                  _dark={{
                    color: "#94A3B8",
                  }}
                  fontSize={"21px"}
                  style={{
                    fontFamily: "AustinCyr_400",
                  }}
                >
                  Level up ({data && data[0].level})
                </Text>
              )}
            </HStack>
            <UnclaimedRewards
              data={
                data
                  ? JSON.parse(data[0].actions_json).unclaimed?.filter(
                      (k) =>
                        k?.action_type !== 1 &&
                        k?.action_type !== 6 &&
                        k?.action_type !== 5
                    )
                  : [
                      {
                        action_type: 3,
                        action_name: "Start your favorites",
                        reward_points: 3,
                        one_time: true,
                        is_claimable: false,
                      },
                      {
                        action_type: 4,
                        action_name: "Make a purchase",
                        reward_points: 0,
                        one_time: false,
                        is_claimable: false,
                      },

                      {
                        action_type: 2,
                        action_name: "Complete the Beauty Quiz",
                        reward_points: 100,
                        one_time: true,
                        is_claimable: false,
                      },
                    ]
              }
            />
          </VStack>
          <Divider bg="#FAF1FF" orientation="vertical" h={"full"} />
          <VStack w="40%">
            {data && JSON.parse(data[0].actions_json).claimed.length > 0 && (
              <HStack bg={backgroundColor}>
                <HStack
                  w="full"
                  bg={backgroundColor}
                  alignItems={"center"}
                  space={2}
                  h="50px"
                  borderTopColor={"#FAF1FF"}
                  borderTopWidth={"1px"}
                >
                  <Text
                    color="#94A3B8"
                    _light={{
                      color: "#682481",
                    }}
                    _dark={{
                      color: "#94A3B8",
                    }}
                    fontSize={"21px"}
                    style={{
                      fontFamily: "AustinCyr_400",
                    }}
                  >
                    - Claimed (
                    {data && JSON.parse(data[0].actions_json).claimed.length})
                  </Text>
                </HStack>
              </HStack>
            )}

            <ClaimedRewards
              data={data && JSON.parse(data[0].actions_json).claimed}
            />
          </VStack>
        </Stack>

        <FooterSectionWeb />
      </VStack>
    </>
  );
};

export default RewardsScreen;
