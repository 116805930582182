import { Text } from "native-base";

const CTAButtonText = ({ text }) => {
  return (
    <Text
      textTransform={"capitalize"}
      fontSize={"16px"}
      style={{
        fontFamily: "HK_600SemiBold",
      }}
      color="white"
    >
      {text}
    </Text>
  );
};

export default CTAButtonText;
