import { ScrollView, Text, VStack } from "native-base";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import { Platform } from "react-native";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";

const PrivacyPolicyScreen = () => {
  return (
    <>
      {Platform.OS === "web" ? (
        <>
          <VStack space={8} pb={4} px={4} pt={2}>
            <Text
              _dark={{
                color: "white",
              }}
              _light={{
                color: "#464646",
              }}
              style={{
                fontFamily: "Hero_400Regular",
              }}
              fontSize={16}
              lineHeight={22}
            >
              {`This privacy policy (the “Policy”) describes how Flock [Flock Shopping LLC] (“Flock”, “we”, “us” and “our”) collect, use, and share your Personal Information (defined below), including information provided by you through flockshop.co and the Flock mobile app (collectively, the “Website”).

By visiting our Website, submitting information, using our services available through the Website, and/or purchasing any products, you agree to let us collect and use your Personal Information as described in this Policy. If you object to the collection, use, and sharing practices described in this Policy, you may not use our Website. Therefore, before you provide us with any information, you should read through this Policy in its entirety and understand its terms.

We may update the Policy from time to time, so please check it occasionally. We encourage you to revisit this page often to remain fully informed of our Policy or you can contact us at any time to obtain the latest copy of this Policy.

This Policy describes:

Personal Information We Collect
How We Collect Your Personal Information
How We Use Your Personal Information
How We Share Your Personal Information With Third Parties
California Privacy Rights
Changes to the Policy
Questions About This Policy

PERSONAL INFORMATION WE COLLECT

Flock collects information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, to any individual or a household (“Personal Information”). Flock also collects non-Personal Information that cannot be linked or associated with any individual person or household. When non-Personal Information is combined with other information so that it does identify an individual person, we treat that combination as Personal Information. We may collect, use, store and transfer different kinds of Personal Information about you, which we have grouped together as follows:


User Content
such as content, communications, pictures, videos, and other information that you create, share, or otherwise communicate with others.
Identifiers
such as your first name, last name, alias, username or similar unique personal identifier, account name, online identifier, and/or date of birth.
Contact Information
such as your email address and telephone numbers.
Financial Information
such as credit or debit card information, payment details, or other payment information.
Profile Data
such as username, password, profile picture, skin type (e.g. complexion, oily, dry, combination), hair type (e.g. oily, dry, thin, thick), groups you have joined on the Website, number of friends on the Website, details about the products you have purchased, payments you have made, reviews, surveys, purchase features, product wish lists, rewards, purchase or product preferences, and/or other details of products you have purchased from us in the past.
Networks and Connections
such as information about the people, pages, accounts, groups, and tags you are connected to and how you interact and communicate with them.
Technical Information
such as internet protocol (IP) address, browser type and version, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access our Website and other Flock platforms.
Usage Data
includes information about how you use our Website and services, such as length of stay, clickstream, and activity on the site (daily check-in times, products viewed, etc.).
Marketing and Communications Information
such as your preferences and consent in receiving marketing correspondence, email communications, and your communication preferences, and/or message content from support requests and/or general contact submissions.
Inference Data
including inferences drawn from the Personal Information identified above.

If You Do Not Provide Personal Information

Where we need to collect Personal Information by law, or under the terms of a contract we have with you, and you do not provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with products or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.


Children’s Privacy

The website and other Flock platforms are intended for adult use only and are not directed towards children, minors, or anyone under the age of 16. If you are under the age of 13, you are not authorized to provide us with any Personal Information. If the parent or guardian of a child under 13 believes that the child has provided us with any Personal Information, the parent or guardian of that child should contact us at hi@flockshopping.com to have this Personal Information deleted from our files.


HOW WE COLLECT YOUR PERSONAL
INFORMATION


Information You Provide To Us:

Flock may collect Personal Information from you that you voluntarily provide to us in various ways, including, but not limited to:


Create an account with us
Place an order or purchase products from us or otherwise use our Website
Email, submit a contact form, or otherwise communicate with us
Publish content, such as video or photo reviews, and interact with other users on the Website
Receiving rewards on the Website
Respond to surveys or marketing emails
Otherwise interact with the Website

Information We Collect When You Use Our Services

Automated technologies or interactions

As is true of most websites, we receive and store certain types of Personal Information whenever you interact with us online. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data. When you access and use our Website and platforms from your mobile devices, we receive data from that mobile device. This may include your device ID, IP address and device type. You may manage how your mobile browser handles cookies and related technologies by adjusting your mobile device privacy and security settings. Please refer to instructions provided by your mobile service provider or the manufacturer of your device to learn how to adjust your settings.

Cookies and Tracking Technologies

We and our service providers use cookies, pixels, and other tracking technology to recognize your browser or device and to capture and remember certain information about your activities on our Website. For example, cookies gather information about how long you spend on a web page so that we can understand what web pages are of interest to users. If you prefer, you can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off cookies by adjusting your browser settings. If you turn off your cookies, some of the features of the Website may not function properly.


Information We Collect From Third Parties
We collect Personal Information from various third parties, including those listed below. The collection, use, and disclosure of Personal Information received from third parties is governed by the privacy policies listed on the website where the information was submitted by the user. Third parties may send their own cookies and pixel tags to you, and may collect information and use it in a way inconsistent with this Policy. Please carefully review these third-party privacy policies to understand how your information may be collected, used and disclosed by these third parties.
Third Party Service Providers

We collect Personal Information from service providers including payment processors, distributors, fulfillment centers, shipping providers, email service providers, website hosting platforms, and social media platforms.
Social Media Sites

When you create a Flock account using your Facebook or Google log-in or link your Flock account to Facebook or Google we receive Personal Information from those third-party social media sites, which may include your public profile information and email.
Google Analytics

We use third party cookies provided by Google Analytics to assist us in better understanding our Website visitors. These cookies collect IP address and usage data, such as the length of time a user spends on a page, the pages a user visits, and the websites a user visits before and after visiting our Website. Based on this information, Google Analytics compiles data about website traffic and interactions, which we use to offer better user experiences and tools in the future. For information on how to opt-out from Google Analytics, visit https://tools.google.com/dlpage/gaoptout.


Your ‘Do Not Track’ Browser Setting

Some web browsers incorporate a Do Not Track (“DNT”) feature that signals to the websites that you visit that you do not want to have your online activity tracked. At this time, our Website does not respond to DNT signals. Other third party websites may keep track of your browsing activities when they provide you with content, which enables them to customize what they present to you on their websites.


HOW WE USE YOUR PERSONAL INFORMATION

Purposes For Which We Will Use Your Personal Information

Our primary purpose in collecting Personal Information is to provide you the products and services that you have ordered or requested with a safe, smooth, efficient, and customized experience. We may also use your Personal Information for the following business purposes:


Assist you with setting up and managing your account with us.
Resolve disputes.
Allow you to participate in the interactive features of the Website.
Troubleshoot problems and support.
Collect and process payments and complete transactions.
Provide the products, services, and customer support you request.
Verify your identity and account information in connection with products or services you request.
To conduct online surveys.
Sending you in application push notifications and reminders with respect to the products or services you request.
Measuring consumer interest in our products and services; and communicate to you about online and offline offers.
Enforce our General Terms of Use and the terms of this Policy.
Customize, measure and improve our services, content, and advertising.
To protect our interests, including establishing, exercising and defending legal rights and claims.
As necessary to comply with legal requirements, to prevent fraud, to co-operate with law enforcement and regulatory authorities, and to stop other prohibited, illegal, or harmful activities.
For purposes disclosed at the time you provide/we request your Personal Information or as otherwise set forth in this Policy.

Promotions

We may offer certain promotions through the Website that may require your Personal Information. By participating in such promotions, you are agreeing to the official rules issued by Flock that govern those promotions. We may also use your information in advertising or marketing associated with the promotions and/or the contests. If you choose to enter a promotion, you agree that Personal Information may be disclosed to Flock or the public in connection with the administration of such promotions, including, in connection with award/prize fulfillment, and as required by the applicable laws or permitted by the promotion’s official terms and conditions.


HOW WE SHARE YOUR PERSONAL INFORMATION

We share your Personal Information with third parties only in the ways that are described in this Policy and with our business partners, suppliers and sub-contractors in order to provide you with our goods and services and perform any contract we enter into with them or you.


User Disclosures

Some Personal Information is disclosed as a matter of course as a result of your use of the website. For example, if you choose to review a product by uploading a video review. Any Personal Information shared via any public forum on our website will become public information.


Service Providers

We may use third-party service providers to perform certain business services and may disclose Personal Information to such service providers as needed for them to perform these business services. Service providers are only allowed to use, disclose or retain the Personal Information to provide these services and are prohibited from selling Personal Information. Business services provided include, but are not limited to, website development services, fulfillment and shipping services, hosting services, communications services, website development services, payment processing services, and analytics services.


Advertising Networks

We allow certain third party advertising partners to place tracking technology such as cookies and pixels on our websites. This technology allows these advertising partners to receive information about your activities on our Platform, which is then associated with your browser or device. These companies may use this data to serve you more relevant interest based ads as you browse the internet.


Internal Third Parties

We may share Personal Information with our successors, assigns, licensees, affiliates, or business partners and others, including where disclosure of your Personal Information is needed to protect the employees, independent contractors, officers, directors, members, or users of Flock who may use the Personal Information for the purposes described above.


Business Transactions

We may do business with third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your Personal Information in the same way as set out in this Policy.


Legal Process

Subject to applicable law, we may disclose information about you (i) if we are required to do so by law, regulation or legal process, such as a subpoena; (ii) in response to requests by government entities, such as law enforcement authorities; (iii) when we believe disclosure is necessary or appropriate to prevent physical, financial or other harm, injury or loss; or (iv) in connection with an investigation of suspected or actual unlawful activity.


COMMUNICATIONS AND OPT OUT

By establishing an account, you may consent to receive periodic commercial/promotional email communications from us. You may opt-out of receiving such promotional communications at any time by following the unsubscribe instructions in a promotional email. This opt out will not apply to operational or informational emails related to your account and purchases. You may continue to receive promotional email messages for a short period while we process your request.


CALIFORNIA PRIVACY RIGHTS

California residents have the right to receive information that identifies any third party companies or individuals that Flock has shared your Personal Information for that third parties own marketing purposes within the previous calendar year, as well as a description of the categories of Personal Information disclosed to that third party. You may obtain this information once a year and free of charge by contacting us at the address below.


CHANGES TO THIS PRIVACY NOTICE

Flock may modify or update this Policy periodically in our sole discretion. Any changes will be effective upon the revised Policy being posted to our Website. You are solely responsible for reviewing this Policy for any changes. As such, we encourage you to revisit this page often to remain fully informed of our Policy or you can contact us at any time to obtain the latest copy of this Policy.
Your continued use of the Website following any changes to this Policy will indicate your acknowledgement of and consent to such changes and to be bound by the terms of such changes, with the understanding that the updated Policy applies to all of your Personal Information, including the Personal Information collected before the changes went into effect.


QUESTIONS ABOUT THIS POLICY

For questions or comments regarding our Policy, you can email us at hi@flockshopping.com or write to us at:

651 N BROAD ST
STE 205 #7774 MIDDLETOWN, DE 19709

Last Updated: 03/28/2022`}
            </Text>
          </VStack>
          <FooterSectionWeb />
        </>
      ) : (
        <>
          {" "}
          <ScrollView
            zIndex={0}
            width="100%"
            showsVerticalScrollIndicator={false}
          >
            <VStack space={8} pb={4} px={4} pt={2}>
              <Text
                style={{
                  fontFamily: "HK_500Medium",
                }}
                fontSize={16}
                lineHeight={22}
                color="white"
              >
                {`This privacy policy (the “Policy”) describes how Flock [Flock Shopping LLC] (“Flock”, “we”, “us” and “our”) collect, use, and share your Personal Information (defined below), including information provided by you through flockshop.co and the Flock mobile app (collectively, the “Website”).

By visiting our Website, submitting information, using our services available through the Website, and/or purchasing any products, you agree to let us collect and use your Personal Information as described in this Policy. If you object to the collection, use, and sharing practices described in this Policy, you may not use our Website. Therefore, before you provide us with any information, you should read through this Policy in its entirety and understand its terms.

We may update the Policy from time to time, so please check it occasionally. We encourage you to revisit this page often to remain fully informed of our Policy or you can contact us at any time to obtain the latest copy of this Policy.

This Policy describes:

Personal Information We Collect
How We Collect Your Personal Information
How We Use Your Personal Information
How We Share Your Personal Information With Third Parties
California Privacy Rights
Changes to the Policy
Questions About This Policy

PERSONAL INFORMATION WE COLLECT

Flock collects information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, to any individual or a household (“Personal Information”). Flock also collects non-Personal Information that cannot be linked or associated with any individual person or household. When non-Personal Information is combined with other information so that it does identify an individual person, we treat that combination as Personal Information. We may collect, use, store and transfer different kinds of Personal Information about you, which we have grouped together as follows:


User Content
such as content, communications, pictures, videos, and other information that you create, share, or otherwise communicate with others.
Identifiers
such as your first name, last name, alias, username or similar unique personal identifier, account name, online identifier, and/or date of birth.
Contact Information
such as your email address and telephone numbers.
Financial Information
such as credit or debit card information, payment details, or other payment information.
Profile Data
such as username, password, profile picture, skin type (e.g. complexion, oily, dry, combination), hair type (e.g. oily, dry, thin, thick), groups you have joined on the Website, number of friends on the Website, details about the products you have purchased, payments you have made, reviews, surveys, purchase features, product wish lists, rewards, purchase or product preferences, and/or other details of products you have purchased from us in the past.
Networks and Connections
such as information about the people, pages, accounts, groups, and tags you are connected to and how you interact and communicate with them.
Technical Information
such as internet protocol (IP) address, browser type and version, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access our Website and other Flock platforms.
Usage Data
includes information about how you use our Website and services, such as length of stay, clickstream, and activity on the site (daily check-in times, products viewed, etc.).
Marketing and Communications Information
such as your preferences and consent in receiving marketing correspondence, email communications, and your communication preferences, and/or message content from support requests and/or general contact submissions.
Inference Data
including inferences drawn from the Personal Information identified above.

If You Do Not Provide Personal Information

Where we need to collect Personal Information by law, or under the terms of a contract we have with you, and you do not provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with products or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.


Children’s Privacy

The website and other Flock platforms are intended for adult use only and are not directed towards children, minors, or anyone under the age of 16. If you are under the age of 13, you are not authorized to provide us with any Personal Information. If the parent or guardian of a child under 13 believes that the child has provided us with any Personal Information, the parent or guardian of that child should contact us at hi@flockshopping.com to have this Personal Information deleted from our files.


HOW WE COLLECT YOUR PERSONAL
INFORMATION


Information You Provide To Us:

Flock may collect Personal Information from you that you voluntarily provide to us in various ways, including, but not limited to:


Create an account with us
Place an order or purchase products from us or otherwise use our Website
Email, submit a contact form, or otherwise communicate with us
Publish content, such as video or photo reviews, and interact with other users on the Website
Receiving rewards on the Website
Respond to surveys or marketing emails
Otherwise interact with the Website

Information We Collect When You Use Our Services

Automated technologies or interactions

As is true of most websites, we receive and store certain types of Personal Information whenever you interact with us online. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data. When you access and use our Website and platforms from your mobile devices, we receive data from that mobile device. This may include your device ID, IP address and device type. You may manage how your mobile browser handles cookies and related technologies by adjusting your mobile device privacy and security settings. Please refer to instructions provided by your mobile service provider or the manufacturer of your device to learn how to adjust your settings.

Cookies and Tracking Technologies

We and our service providers use cookies, pixels, and other tracking technology to recognize your browser or device and to capture and remember certain information about your activities on our Website. For example, cookies gather information about how long you spend on a web page so that we can understand what web pages are of interest to users. If you prefer, you can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off cookies by adjusting your browser settings. If you turn off your cookies, some of the features of the Website may not function properly.


Information We Collect From Third Parties
We collect Personal Information from various third parties, including those listed below. The collection, use, and disclosure of Personal Information received from third parties is governed by the privacy policies listed on the website where the information was submitted by the user. Third parties may send their own cookies and pixel tags to you, and may collect information and use it in a way inconsistent with this Policy. Please carefully review these third-party privacy policies to understand how your information may be collected, used and disclosed by these third parties.
Third Party Service Providers

We collect Personal Information from service providers including payment processors, distributors, fulfillment centers, shipping providers, email service providers, website hosting platforms, and social media platforms.
Social Media Sites

When you create a Flock account using your Facebook or Google log-in or link your Flock account to Facebook or Google we receive Personal Information from those third-party social media sites, which may include your public profile information and email.
Google Analytics

We use third party cookies provided by Google Analytics to assist us in better understanding our Website visitors. These cookies collect IP address and usage data, such as the length of time a user spends on a page, the pages a user visits, and the websites a user visits before and after visiting our Website. Based on this information, Google Analytics compiles data about website traffic and interactions, which we use to offer better user experiences and tools in the future. For information on how to opt-out from Google Analytics, visit https://tools.google.com/dlpage/gaoptout.


Your ‘Do Not Track’ Browser Setting

Some web browsers incorporate a Do Not Track (“DNT”) feature that signals to the websites that you visit that you do not want to have your online activity tracked. At this time, our Website does not respond to DNT signals. Other third party websites may keep track of your browsing activities when they provide you with content, which enables them to customize what they present to you on their websites.


HOW WE USE YOUR PERSONAL INFORMATION

Purposes For Which We Will Use Your Personal Information

Our primary purpose in collecting Personal Information is to provide you the products and services that you have ordered or requested with a safe, smooth, efficient, and customized experience. We may also use your Personal Information for the following business purposes:


Assist you with setting up and managing your account with us.
Resolve disputes.
Allow you to participate in the interactive features of the Website.
Troubleshoot problems and support.
Collect and process payments and complete transactions.
Provide the products, services, and customer support you request.
Verify your identity and account information in connection with products or services you request.
To conduct online surveys.
Sending you in application push notifications and reminders with respect to the products or services you request.
Measuring consumer interest in our products and services; and communicate to you about online and offline offers.
Enforce our General Terms of Use and the terms of this Policy.
Customize, measure and improve our services, content, and advertising.
To protect our interests, including establishing, exercising and defending legal rights and claims.
As necessary to comply with legal requirements, to prevent fraud, to co-operate with law enforcement and regulatory authorities, and to stop other prohibited, illegal, or harmful activities.
For purposes disclosed at the time you provide/we request your Personal Information or as otherwise set forth in this Policy.

Promotions

We may offer certain promotions through the Website that may require your Personal Information. By participating in such promotions, you are agreeing to the official rules issued by Flock that govern those promotions. We may also use your information in advertising or marketing associated with the promotions and/or the contests. If you choose to enter a promotion, you agree that Personal Information may be disclosed to Flock or the public in connection with the administration of such promotions, including, in connection with award/prize fulfillment, and as required by the applicable laws or permitted by the promotion’s official terms and conditions.


HOW WE SHARE YOUR PERSONAL INFORMATION

We share your Personal Information with third parties only in the ways that are described in this Policy and with our business partners, suppliers and sub-contractors in order to provide you with our goods and services and perform any contract we enter into with them or you.


User Disclosures

Some Personal Information is disclosed as a matter of course as a result of your use of the website. For example, if you choose to review a product by uploading a video review. Any Personal Information shared via any public forum on our website will become public information.


Service Providers

We may use third-party service providers to perform certain business services and may disclose Personal Information to such service providers as needed for them to perform these business services. Service providers are only allowed to use, disclose or retain the Personal Information to provide these services and are prohibited from selling Personal Information. Business services provided include, but are not limited to, website development services, fulfillment and shipping services, hosting services, communications services, website development services, payment processing services, and analytics services.


Advertising Networks

We allow certain third party advertising partners to place tracking technology such as cookies and pixels on our websites. This technology allows these advertising partners to receive information about your activities on our Platform, which is then associated with your browser or device. These companies may use this data to serve you more relevant interest based ads as you browse the internet.


Internal Third Parties

We may share Personal Information with our successors, assigns, licensees, affiliates, or business partners and others, including where disclosure of your Personal Information is needed to protect the employees, independent contractors, officers, directors, members, or users of Flock who may use the Personal Information for the purposes described above.


Business Transactions

We may do business with third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your Personal Information in the same way as set out in this Policy.


Legal Process

Subject to applicable law, we may disclose information about you (i) if we are required to do so by law, regulation or legal process, such as a subpoena; (ii) in response to requests by government entities, such as law enforcement authorities; (iii) when we believe disclosure is necessary or appropriate to prevent physical, financial or other harm, injury or loss; or (iv) in connection with an investigation of suspected or actual unlawful activity.


COMMUNICATIONS AND OPT OUT

By establishing an account, you may consent to receive periodic commercial/promotional email communications from us. You may opt-out of receiving such promotional communications at any time by following the unsubscribe instructions in a promotional email. This opt out will not apply to operational or informational emails related to your account and purchases. You may continue to receive promotional email messages for a short period while we process your request.


CALIFORNIA PRIVACY RIGHTS

California residents have the right to receive information that identifies any third party companies or individuals that Flock has shared your Personal Information for that third parties own marketing purposes within the previous calendar year, as well as a description of the categories of Personal Information disclosed to that third party. You may obtain this information once a year and free of charge by contacting us at the address below.


CHANGES TO THIS PRIVACY NOTICE

Flock may modify or update this Policy periodically in our sole discretion. Any changes will be effective upon the revised Policy being posted to our Website. You are solely responsible for reviewing this Policy for any changes. As such, we encourage you to revisit this page often to remain fully informed of our Policy or you can contact us at any time to obtain the latest copy of this Policy.
Your continued use of the Website following any changes to this Policy will indicate your acknowledgement of and consent to such changes and to be bound by the terms of such changes, with the understanding that the updated Policy applies to all of your Personal Information, including the Personal Information collected before the changes went into effect.


QUESTIONS ABOUT THIS POLICY

For questions or comments regarding our Policy, you can email us at hi@flockshopping.com or write to us at:

651 N BROAD ST
STE 205 #7774 MIDDLETOWN, DE 19709

Last Updated: 03/28/2022`}
              </Text>
            </VStack>
          </ScrollView>
          <BottomBar />
        </>
      )}
    </>
  );
};

export default PrivacyPolicyScreen;
