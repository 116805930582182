import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const WebtiktokIcon = () => {
  return (
    <Icon viewBox="0 0 21 25">
      <G>
        <Path
          d="M18.2105 5.97356C18.0485 5.88984 17.8909 5.79807 17.738 5.69858C17.2937 5.40482 16.8863 5.05868 16.5246 4.66763C15.6197 3.63218 15.2817 2.58173 15.1572 1.84627H15.1622C15.0582 1.2358 15.1012 0.84082 15.1077 0.84082H10.9859V16.779C10.9859 16.993 10.9859 17.2045 10.9769 17.4135C10.9769 17.4395 10.9744 17.4635 10.9729 17.4915C10.9729 17.503 10.9729 17.515 10.9704 17.527V17.536C10.9269 18.1078 10.7436 18.6603 10.4366 19.1447C10.1295 19.629 9.70812 20.0305 9.20947 20.3138C8.68977 20.6095 8.10198 20.7646 7.50406 20.7638C5.58366 20.7638 4.02723 19.1979 4.02723 17.264C4.02723 15.3301 5.58366 13.7642 7.50406 13.7642C7.86758 13.7638 8.22887 13.821 8.57451 13.9337L8.5795 9.73688C7.53024 9.60134 6.46427 9.68473 5.44885 9.98178C4.43342 10.2788 3.49058 10.7831 2.6798 11.4628C1.96937 12.0801 1.37211 12.8166 0.914888 13.6392C0.740897 13.9392 0.0844296 15.1446 0.00493361 17.101C-0.0450639 18.2115 0.288419 19.3619 0.447411 19.8374V19.8474C0.547406 20.1274 0.934887 21.0828 1.56636 21.8883C2.07555 22.5344 2.67714 23.1019 3.35177 23.5727V23.5627L3.36177 23.5727C5.35717 24.9286 7.56956 24.8396 7.56956 24.8396C7.95254 24.8241 9.23547 24.8396 10.6924 24.1492C12.3083 23.3837 13.2283 22.2433 13.2283 22.2433C13.816 21.5618 14.2833 20.7853 14.6102 19.9469C14.9832 18.9664 15.1077 17.7905 15.1077 17.3205V8.86492C15.1577 8.89492 15.8236 9.3354 15.8236 9.3354C15.8236 9.3354 16.7831 9.95037 18.28 10.3508C19.354 10.6358 20.8009 10.6958 20.8009 10.6958V6.60403C20.2939 6.65903 19.2645 6.49904 18.2105 5.97356Z"
          fill="#D274FF"
        />
      </G>
    </Icon>
  );
};

export default WebtiktokIcon;
