import { Input, Stack, VStack } from "native-base";
import HomeScreenFilterCategoryCard from "../../../cards/homeScreenFilterCategoryCard";
import SearchIconOutlined from "../../../icons/searchIconOutlined";
import MasonryList from "@react-native-seoul/masonry-list";
import { ProductType } from "../../../../constants/categories";
import {
  changeProductsFilter,
  deleteProductsChipsValue,
  setProductsChips,
} from "../../../../redux/productsFilter/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Item = ({ selectedCategory, setSelectedCategory, item, dispatch }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selected) {
      if (selectedCategory.includes(item)) {
        setSelected(true);
      }
    }
  }, [selectedCategory]);

  const onPress = () => {
    if (selectedCategory.includes(item)) {
      setSelected(false);
      const filtered = selectedCategory.filter((value) => value !== item);
      setSelectedCategory(filtered);
      dispatch(deleteProductsChipsValue(ProductType[item]));
    } else {
      setSelected(true);
      setSelectedCategory([...selectedCategory, item]);
      dispatch(
        setProductsChips({
          label: "Category",
          value: ProductType[item],
        })
      );
    }
  };

  return (
    <VStack alignItems={"center"} marginY={1.5} marginX={2}>
      <HomeScreenFilterCategoryCard
        index={item}
        label={ProductType[item]}
        onPress={onPress}
        selected={selected}
        type={"products"}
      />
    </VStack>
  );
};

const Category = ({ selectedCategory, setSelectedCategory }) => {
  const dispatch = useDispatch();

  const renderItem = ({ item }) => (
    <Item
      item={item}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      dispatch={dispatch}
    />
  );

  return (
    <VStack width={"100%"} pb={8} px={2} space={3}>
      <Input
        borderWidth={"1px"}
        borderColor={"#94A3B8"}
        style={{
          fontFamily: "HK_400Regular",
        }}
        autoCapitalize="none"
        placeholderTextColor="#94A3B8"
        rounded={8}
        fontSize={14}
        InputRightElement={
          <Stack px={4}>
            <SearchIconOutlined size={4} />
          </Stack>
        }
        color="white"
        _focus={{
          borderColor: "#2E303C",
          backgroundColor: "#1A1C1E",
          color: "white",
        }}
        placeholder="Search Categories"
      />

      <MasonryList
        keyExtractor={(item) => item}
        data={Object.keys(ProductType).filter((k) => k !== "0")}
        numColumns={2}
        renderItem={renderItem}
      />
    </VStack>
  );
};

export default Category;
