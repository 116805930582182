import { Center, Pressable, Text, VStack } from "native-base";
import { ImageBackground } from "react-native";
import { useDispatch } from "react-redux";
import { setAnalytics } from "../../../analytics";
import { Pages } from "../../../constants/pages";
import { closeDialog } from "../../../redux/currentState/actions";
import * as RootNavigation from "./../../../RootNavigation";
const FlockDealCard = ({ showDealSteps, setShowDealSteps }) => {
  const dispatch = useDispatch();
  return (
    <>
      <Pressable
        onPress={() => {
          setAnalytics(
            "Home Screen Flock Deals Card",
            `Flock Deals Card Clicked`
          );
          dispatch(closeDialog());
          RootNavigation.navigate(Pages.FlockDeals);
        }}
      >
        <Center
          h={"131px"}
          w={{
            md: "348px",
            lg: "368px",
            xl: "398px",
          }}
        >
          <ImageBackground
            position="relative"
            resizeMode="cover"
            source={require("./../../../assets/images/pngs/flockDealsCard.png")}
            alt=""
            style={{
              height: "100%",
              width: "100%",

              borderRadius: 20,

              overflow: "hidden",
            }}
          >
            <VStack
              w="full"
              alignItems={"center"}
              space={1}
              position={"absolute"}
              bottom={"12%"}
            >
              <Text
                textTransform={"uppercase"}
                letterSpacing={3}
                color={"white"}
                fontSize="12px"
                style={{
                  fontFamily: "HK_700Bold",
                }}
              >
                Buy in groups for discounts!
              </Text>
              <Pressable
                onPress={() => {
                  setShowDealSteps(!showDealSteps);
                  setAnalytics(
                    "Home Screen Flock Deals Card",
                    `How Does It Work Clicked`
                  );
                }}
              >
                <Center
                  w="118px"
                  h="18px"
                  rounded={"full"}
                  px={"4px"}
                  py={1}
                  borderWidth={"1px"}
                  borderColor={"white"}
                >
                  <Text
                    letterSpacing={"1"}
                    textTransform={"uppercase"}
                    lineHeight={"10px"}
                    color={"white"}
                    fontSize="8px"
                    style={{
                      fontFamily: "HK_700Bold",
                    }}
                  >
                    How does it work?
                  </Text>
                </Center>
              </Pressable>
            </VStack>
          </ImageBackground>
        </Center>
      </Pressable>
    </>
  );
};

export default FlockDealCard;
