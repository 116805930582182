import { Icon } from "native-base";
import { Path, G, ClipPath, Rect, Defs } from "react-native-svg";

const BlueSendIcon = ({ color = "#1A94FF" }) => {
  return (
    <Icon size={5} viewBox="0 0 21 20">
      <G clip-path="url(#clip0_6516_124307)">
        <Path
          d="M18 10.0002L2 2L4.39994 10.0002L2.0004 18L18 10.0002ZM12.8225 10.8002L4.59674 14.9129L5.8303 10.8002H12.8225ZM12.8221 9.20022H5.8303L4.59633 5.08712L12.8221 9.20022Z"
          fill={color}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_6516_124307">
          <Rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.445312)"
          />
        </ClipPath>
      </Defs>
    </Icon>
  );
};

export default BlueSendIcon;
