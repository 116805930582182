import {
    STORE_FORM,
    DELETE_FORM,
    STORE_FORM_ERROR,
    INITIALISE_FORM
} from './constants'


const initialState = {}

const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REFRESH':
            return {}
        case INITIALISE_FORM:
            return {...action.values}
        case STORE_FORM:
            return { ...state, ...action.values }
        case DELETE_FORM:
            return {}
        case STORE_FORM_ERROR:
            return { ...state, error: action.error }
        default: return state
    }
}

export default formReducer