import { useDispatch, useSelector } from "react-redux";
import {
  closeDialog,
  hideLoader,
  setDialog,
  showLoader,
} from "../redux/currentState/actions";
import { useState } from "react";
import { privateApi } from "../util/API";
import { setAnalytics } from "../analytics";
import { useSWRConfig } from "swr";
import Constants from "expo-constants";
import { Popups } from "../constants/popups";
import * as RootNavigation from "./../RootNavigation";
const apiUrl = Constants.expoConfig.extra.apiUrl;

export const useAddDefaultAddress = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const userId = useSelector((state) => state.user.id);
  const { mutate } = useSWRConfig();
  const navigation = RootNavigation;

  const addDefaultAddress = async (selectedValue) => {
    dispatch(showLoader());
    try {
      if (userId) {
        const typeOfAddress = "shipping";
        let res = await privateApi(
          "/api/users/add_default_address/",
          {
            id: selectedValue,
            type: typeOfAddress,
            user_id: userId,
          },
          "put"
        );
        if (res.status === 200 || res.status === 201) {
          setAnalytics("Confirm Button", "Added Default Address");
          mutate(`${apiUrl}/api/users/get_payment_details/?user_id=${userId}`);
          mutate(`${apiUrl}/api/users/get_addresses/?user_id=${userId}`);
          dispatch(closeDialog());

          navigation.navigationRef.goBack();
        } else {
          setAnalytics("Confirm Button", "Adding Default Address Failed", {
            popUp: "Oops you broke it, error pop up",
          });
          dispatch(setDialog(Popups.Error));
        }
      }
    } catch (error) {}
    dispatch(hideLoader());
  };

  return { addDefaultAddress, error };
};
