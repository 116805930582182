import { Image } from "native-base";
const WebStepsArrowIcon = () => {
  return (
    <Image
      width={"214px"}
      height={"56px"}
      resizeMethod="scale"
      source={require("./../../../assets/images/pngs/Steps.png")}
      alt=""
    />
  );
};

export default WebStepsArrowIcon;
