import { Button } from "native-base";

const DoItLaterButton = ({ onPress }) => {
  return (
    <Button
    position={"absolute"}
      bottom={"390px"}
      onPress={onPress}
      variant={"ghost"}
      _text={{
        color: "#FFFFFF",
        fontSize: 14,
        fontFamily: "Poppins_500Medium",
        lineHeight: 21,
      }}
    >
      Skip for now
    </Button>
  );
};

export default DoItLaterButton;
