import { HStack, Modal, Pressable, Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { goBackDialog } from "../../redux/currentState/actions";
import ShareButton from "../buttons/shareButton";
import RewardsIcon from "../icons/rewardsIcon";
import * as RootNavigation from "./../../RootNavigation";
import * as Clipboard from "expo-clipboard";
import { useEffect, useState } from "react";
import GreenTickIcon from "../icons/greenTickIcon";

import { createDeepLink } from "../../hooks/useShare";
import { setAnalytics } from "../../analytics";
const RefferalModal = () => {
  const dispatch = useDispatch();
  const openDialog = useSelector((state) => state.currentState.openDialog);
  const route = RootNavigation.navigationRef.getCurrentRoute();
  const [copyStatus, setCopyStatus] = useState();
  const userId = useSelector((state) => state.user.id);
  const [link, setLink] = useState();
  useEffect(() => {
    getLink();
  }, []);
  const getLink = async () => {
    let k = await createDeepLink(`product/${route?.params?.id}`, userId);
    setLink(k);
  };

  return (
    <Modal
      size="full"
      bg="black:alpha.80"
      isOpen={openDialog}
      onClose={() => dispatch(goBackDialog())}
    >
      <Modal.Content
        bg={"#2B2F33"}
        rounded={"20px"}
        width={{
          md: "300px",
          lg: "380px",
          xl: "400px",
        }}
      >
        <VStack py={6} space={6} px={2} w="full">
          <VStack w="full">
            <HStack alignItems={"center"} space={2}>
              <RewardsIcon />
              <Text
                fontSize={"28px"}
                style={{
                  fontFamily: "HK_600SemiBold",
                }}
                color="#ffffff"
              >
                Invite Friends to Earn
              </Text>
            </HStack>
            <Text
              fontSize={"18px"}
              style={{
                fontFamily: "HK_500Medium",
              }}
              color="#ffffff"
            >
              On every purchase made with your shared link, you earn in
              Flockbucks!
            </Text>
          </VStack>
          <HStack alignItems={"center"} justifyContent="space-between">
            <Pressable
              onPress={async () => {
                let p = await Clipboard.setStringAsync(link);
                if (p) {
                  setCopyStatus(p);
                }
                setAnalytics(
                  "Copied link",
                  "Copied Link For Sharing Product From Product Screen",
                  {
                    link: link,
                    copyStatus: p,
                  }
                );
              }}
            >
              <HStack
                h="50px"
                alignItems={"center"}
                bg="#1A1C1E"
                borderRadius={"10px"}
                w="234px"
                justifyContent={"space-around"}
              >
                <Text
                  numberOfLines={1}
                  w="40%"
                  fontSize={"14px"}
                  style={{
                    fontFamily: "HK_500Medium",
                  }}
                  color="#ffffff"
                >
                  {link}
                </Text>
                <HStack alignItems={"center"} space={2}>
                  {copyStatus && <GreenTickIcon />}
                  <Text
                    fontSize={"16px"}
                    style={{
                      fontFamily: "HK_600SemiBold",
                    }}
                    color={copyStatus ? "#ffffff" : "#1A94FF"}
                  >
                    {copyStatus ? "Copied" : "Copy"}
                  </Text>
                </HStack>
              </HStack>
            </Pressable>

            <ShareButton />
          </HStack>
        </VStack>
      </Modal.Content>
    </Modal>
  );
};

export default RefferalModal;
