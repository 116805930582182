import { Image } from "native-base";

const RewardsIcon = ({ size = 5 }) => {
  return (
    <Image
      size={size}
      resizeMethod="scale"
      resizeMode="contain"
      source={require("./../../assets/images/pngs/rewardsIcon.png")}
      alt=""
    />
  );
};

export default RewardsIcon;
