import {
  Center,
  HStack,
  Pressable,
  Text,
  VStack,
  Checkbox,
  Stack,
} from "native-base";
import { useState } from "react";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import { useSelector } from "react-redux";
import { setAnalytics } from "../../analytics";
import useGetUser from "../../hooks/useGetUser";
import SurveyScreenBottomBar from "../../components/bars/bottomBars/surveyScreenBottomBar";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";

const SpecificIngredientsScreen = ({ navigation, route }) => {
  const userId = useSelector((state) => state.user.id);
  const { data } = useGetUser("profile", userId);

  const editUserDetails = useEditUserDetails();
  const [likedIngredients, setLikedIngredients] = useState([
    {
      label: "Hyaluronic Acid",
      clicked: data?.user[0]?.liked_ingredients?.find(
        (k) => k === "Hyaluronic Acid"
      )
        ? true
        : false,
    },
    {
      label: "Lactic Acid",
      clicked: data?.user[0]?.liked_ingredients?.find(
        (k) => k === "Lactic Acid"
      )
        ? true
        : false,
    },

    {
      label: "Niacinamide",
      clicked: data?.user[0]?.liked_ingredients?.find(
        (k) => k === "Niacinamide"
      )
        ? true
        : false,
    },

    {
      label: "Salicylic Acid",
      clicked: data?.user[0]?.liked_ingredients?.find(
        (k) => k === "Salicylic Acid"
      )
        ? true
        : false,
    },
    {
      label: "Vitmain C",
      clicked: data?.user[0]?.liked_ingredients?.find((k) => k === "Vitmain C")
        ? true
        : false,
    },

    {
      label: "Retinol",
      clicked: data?.user[0]?.liked_ingredients?.find((k) => k === "Retinol")
        ? true
        : false,
    },
    {
      label: "SPF",
      clicked: data?.user[0]?.liked_ingredients?.find((k) => k === "SPF")
        ? true
        : false,
    },
    {
      label: "CBD",
      clicked: data?.user[0]?.liked_ingredients?.find((k) => k === "CBD")
        ? true
        : false,
    },

    {
      label: "Hypoallergenic",
      clicked: data?.user[0]?.liked_ingredients?.find(
        (k) => k === "Hypoallergenic"
      )
        ? true
        : false,
    },
    {
      label: "Collagen",
      clicked: data?.user[0]?.liked_ingredients?.find((k) => k === "Collagen")
        ? true
        : false,
    },
    {
      label: "Reef Safe SPF",
      clicked: data?.user[0]?.liked_ingredients?.find(
        (k) => k === "Reef Safe SPF"
      )
        ? true
        : false,
    },
  ]);

  const [dislikedIngredients, setDislikedIngredients] = useState([
    {
      label: "Parabens",
      clicked: data?.user[0]?.disliked_ingredients?.find(
        (k) => k === "Parabens"
      )
        ? true
        : false,
    },
    {
      label: "Sulfates",
      clicked: data?.user[0]?.disliked_ingredients?.find(
        (k) => k === "Sulfates"
      )
        ? true
        : false,
    },
    {
      label: "Oil",
      clicked: data?.user[0]?.disliked_ingredients?.find((k) => k === "Oil")
        ? true
        : false,
    },
    {
      label: "Silicones",
      clicked: data?.user[0]?.disliked_ingredients?.find(
        (k) => k === "Silicones"
      )
        ? true
        : false,
    },
    {
      label: "Alcohol",
      clicked: data?.user[0]?.disliked_ingredients?.find((k) => k === "Alcohol")
        ? true
        : false,
    },

    // {
    //   label: "Gluten",
    //   clicked: data?.user[0]?.disliked_ingredients?.find((k) => k === "Gluten")
    //     ? true
    //     : false,
    // },
    // {
    //   label: "phthalates",
    //   clicked: data?.user[0]?.disliked_ingredients?.find(
    //     (k) => k === "phthalates"
    //   )
    //     ? true
    //     : false,
    // },

    // {
    //   label: "Retinyl Palmitate",
    //   clicked: data?.user[0]?.disliked_ingredients?.find(
    //     (k) => k === "Retinyl Palmitate"
    //   )
    //     ? true
    //     : false,
    // },
    // {
    //   label: "Mineral Oil",
    //   clicked: data?.user[0]?.disliked_ingredients?.find(
    //     (k) => k === "Mineral Oil"
    //   )
    //     ? true
    //     : false,
    // },
    // {
    //   label: "Triclosan",
    //   clicked: data?.user[0]?.disliked_ingredients?.find(
    //     (k) => k === "Triclosan"
    //   )
    //     ? true
    //     : false,
    // },

    // {
    //   label: "Formaldehydes",
    //   clicked: data?.user[0]?.disliked_ingredients?.find(
    //     (k) => k === "Formaldehydes"
    //   )
    //     ? true
    //     : false,
    // },

    // {
    //   label: "Formaldehydes releasing agents",
    //   clicked: data?.user[0]?.disliked_ingredients?.find(
    //     (k) => k === "Formaldehydes releasing agents"
    //   )
    //     ? true
    //     : false,
    // },
    // {
    //   label: "Oxybenzone",
    //   clicked: data?.user[0]?.disliked_ingredients?.find(
    //     (k) => k === "Oxybenzone"
    //   )
    //     ? true
    //     : false,
    // },
  ]);

  const [checkAll, setCheckAll] = useState(false);

  const handleRequest = async (text) => {
    if (text === "Skip") {
      setAnalytics(
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
        }
      );
    } else {
      const liked_ingredients = likedIngredients
        .filter((el) => el?.clicked)
        .map((el2) => el2?.label);
      const disliked_ingredients = dislikedIngredients
        .filter((el) => el?.clicked)
        .map((el2) => el2?.label);

      editUserDetails(
        {
          liked_ingredients,
          disliked_ingredients,
        },
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
          liked_ingredients,
          disliked_ingredients,
          edited: data?.user[0]?.quiz_completed,
        }
      );
    }
    navigation.navigate("PreferencesScreen");
  };

  return (
    <>
      <VStack space={12} w="full">
        <Stack
          w="full"
          justifyContent="center"
          space={{
            base: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: "50px",
          }}
          px={{
            base: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: "80px",
          }}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
        >
          <VStack
            space={"8px"}
            w={{
              xl: "20%",
            }}
          >
            <Text
              color="#682481"
              fontSize={"21px"}
              style={{
                fontFamily: "AustinCyr_400",
              }}
            >
              Ingredients you dislike
            </Text>
            <Text
              color="#464646"
              fontSize={"14px"}
              style={{
                fontFamily: "LeituraNewsRoman",
              }}
            >
              These are some items you don’t want in the products you use.
            </Text>
          </VStack>
          <VStack
            space={12}
            w={{
              xl: "50%",
            }}
          >
            <Stack
              borderWidth={"1px"}
              borderColor="#FAF1FF"
              borderRadius={"10px"}
              justifyContent="center"
              space={4}
              p={4}
              direction={{
                base: "column",
                sm: "column",
                md: "column",
                lg: "column",
                xl: "row",
              }}
            >
              <VStack w="full">
                <Checkbox
                  height={"20px"}
                  borderWidth={"1px"}
                  onChange={(check) => {
                    if (check) {
                      setDislikedIngredients(
                        dislikedIngredients.map((item) => {
                          item.clicked = true;
                          return item;
                        })
                      );
                    } else {
                      setDislikedIngredients(
                        dislikedIngredients.map((item) => {
                          item.clicked = false;
                          return item;
                        })
                      );
                    }
                  }}
                  _light={{
                    bg: "#F0F0F0",
                    borderColor: "#94A3B8",
                  }}
                  _dark={{
                    bg: "#2B2F33",
                    borderColor: "#58595B",
                  }}
                  borderRadius="2px"
                  _text={{
                    _dark: {
                      color: "#fff",
                    },
                    _light: {
                      color: "#464646",
                    },
                    _web: {
                      fontFamily: "Hero_400Regular",
                      fontSize: "14px",
                    },
                    fontSize: "16px",
                    fontFamily: "HK_500Medium",
                  }}
                >
                  Select all
                </Checkbox>
                <HStack alignItems={"center"} flexWrap="wrap" space={"10px"}>
                  {dislikedIngredients.map((label, index) => (
                    <Pressable
                      onPress={() => {
                        const copy = [...dislikedIngredients];
                        copy[index] = {
                          ...copy[index],
                          clicked: !label.clicked,
                        };
                        setDislikedIngredients(copy);
                      }}
                    >
                      <Center
                        mt={"10px"}
                        h="30px"
                        w="100%"
                        px={"20px"}
                        mx={"2px"}
                        rounded={"full"}
                        _light={{
                          bg: label.clicked ? "#E9B9FF" : "#F0F0F0",
                        }}
                        _dark={{
                          bg: "#2B2F33",
                        }}
                      >
                        <Text
                          color={label.clicked ? "white" : "#94A3B8"}
                          fontSize={"16px"}
                          style={{
                            fontFamily: "HK_500Medium",
                          }}
                        >
                          {label.label}
                        </Text>
                      </Center>
                    </Pressable>
                  ))}
                </HStack>
              </VStack>
            </Stack>
            <SurveyScreenBottomBar
              showNextButton={
                likedIngredients.find((el) => el?.clicked) ||
                dislikedIngredients.find((el) => el?.clicked)
              }
              nextOnPress={() => {
                handleRequest("Next");
              }}
            />
          </VStack>
        </Stack>
        <FooterSectionWeb />
      </VStack>
    </>
  );
};

export default SpecificIngredientsScreen;
