import { Center, Pressable } from "native-base";
import { useContext } from "react";
import { AddToCartContext } from "../../hoc/AddToCartHOC";
import WebGradientActiveCartIcon from "../icons/webIcons/webGradientActiveCartIcon";
import WebGradientCartIcon from "../icons/webIcons/webGradientCartIcon";

const CartIconButton = () => {
  const { onPress, inCart } = useContext(AddToCartContext);
  return (
    <Pressable onPress={onPress}>
      <Center bg="#FAF1FF" size={"30px"} rounded="10px">
        {inCart ? <WebGradientActiveCartIcon /> : <WebGradientCartIcon />}
      </Center>
    </Pressable>
  );
};

export default CartIconButton;
