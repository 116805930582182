import axios from "axios";
import useSWR from "swr";
import { useSelector } from "react-redux";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
import { getHeaders } from "../util/utilFunctions";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const allRewardsFetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });
const amountRewardsFetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });
const affiliateRewardsFetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });

const claimedRewardsFetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });

export default function useGetRewards(type) {
  const userId = useSelector((state) => state.user.id);
  const allRewardsKey = `${apiUrl}/api/points/rewards/?user_id=${userId}`;
  const amountRewardsKey = `${apiUrl}/api/points/get_amount_rewards/?user_id=${userId}`;
  const affiliateRewardsKey = `${apiUrl}/api/points/get_affiliate_earnings_page/?user_id=${userId}`;
  const claimedRewardsKey = `${apiUrl}/api/points/get_affiliate_reward/?user_id=${userId}`;
  const typeKeyMap = {
    allRewards: allRewardsKey,
    amountRewards: amountRewardsKey,
    affiliateRewards: affiliateRewardsKey,
    claimedRewards: claimedRewardsKey,
  };
  const typeFetcherMap = {
    allRewards: allRewardsFetcher,
    amountRewards: amountRewardsFetcher,
    affiliateRewards: affiliateRewardsFetcher,
    claimedRewards: claimedRewardsFetcher,
  };

  const { data, mutate, isValidating, error } = useSWR(
    userId && type ? typeKeyMap[type] : null,
    type ? typeFetcherMap[type] : null
  );
  useCheckNetwork(error);
  useLoader(isValidating);
  return { data, mutate, isValidating, error };
}
