import { Box, FlatList } from "native-base";
import ReviewCard from "./cards/reviewCard";

const Reviews = () => {
  const renderItem = ({ item }) => <ReviewCard />;
  return (
    <FlatList
      showsHorizontalScrollIndicator={false}
      horizontal
      ItemSeparatorComponent={() => (
        <Box style={{ paddingHorizontal: 6 }}></Box>
      )}
      flexGrow={0}
      data={[1, 2, 3, 4]}
      renderItem={renderItem}
    />
  );
};

export default Reviews;
