import axios from "axios";
import useSWR from "swr";
import { getHeaders } from "../util/utilFunctions";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const buddySearchfetcher = async (url) =>
  axios
    .get(url, {
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });

const allSearchfetcher = async (url) =>
  axios
    .get(url, {
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });

export default function useGetSearchResults(type, query) {
  const getBuddySearchKey = `${apiUrl}/api/home/beautybuddy_search/?query_string=${query}`;
  const getAllSearchKey = `${apiUrl}/api/home/search/?query_string=${query}`;
  const { data, mutate, isValidating, error } = useSWR(
    query !== undefined && query !== null && query !== ""
      ? type === "buddySearch"
        ? getBuddySearchKey
        : type === "allSearch"
        ? getAllSearchKey
        : null
      : null,
    type === "buddySearch"
      ? buddySearchfetcher
      : type === "allSearch"
      ? allSearchfetcher
      : null,
    {
      keepPreviousData: true,
    }
  );
  useCheckNetwork(error);

  return { data, mutate, isValidating, error };
}
