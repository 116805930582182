import { combineReducers } from "redux";
import userReducer from "./user/reducers";
import personReducer from "./person/reducers";
import productReducer from "./product/reducers";
import groupReducer from "./group/reducers";
import currentStateReducer from "./currentState/reducers";
import searchReducer from "./search/reducers";
import followingReducer from "./following/reducers";
import cartReducer from "./cart/reducers";
import formReducer from "./form/reducers";
import homeReducer from "./home/reducers";
import filterReducer from "./filter/reducers";
import productsReducer from "./products/reducers";
import productsFilterReducer from "./productsFilter/reducers";
import orderReducer from "./order/reducers";
import wishlistReducer from "./wishlist/reducers";
import brandReducer from "./brand/reducers";
import communityFilterReducer from "./communityFilter/reducers";

const rootReducer = combineReducers({
  user: userReducer,
  product: productReducer,
  person: personReducer,
  search: searchReducer,
  group: groupReducer,
  currentState: currentStateReducer,
  following: followingReducer,
  cart: cartReducer,
  form: formReducer,
  home: homeReducer,
  filter: filterReducer,
  productsFilter: productsFilterReducer,
  communityFilter: communityFilterReducer,
  products: productsReducer,
  order: orderReducer,
  wishlist: wishlistReducer,
  brand: brandReducer,
});

export default rootReducer;
