// import { Center, Input, Text, VStack } from "native-base";
// import { useState } from "react";
// import { Keyboard } from "react-native";
// import { useDispatch, useSelector } from "react-redux";
// import OTP from "../../components/otp";
// import { useVerifyOtpForgetPassword } from "../../hooks/useVerifyOtpForgetPassword";
// import { useVerifyOtpEmailSignup } from "../../hooks/useVerifyOtpEmailSignup";
// import { hideLoader, showLoader } from "../../redux/currentState/actions";
// import { useVerifyOtpPhone } from "../../hooks/useVerifyOtpPhone";
// import Error from "../../components/error";
// import PrimaryCTAButton from "../../components/buttons/primaryCTAButton";
// import CTAButtonText from "../../components/texts/ctaButtonText";

// const VerifyScreen = ({ route }) => {
//   const form = useSelector((state) => state.form);
//   const [first, setFirst] = useState();
//   const [second, setSecond] = useState();
//   const [third, setThird] = useState();
//   const [fourth, setFourth] = useState();
//   const [fifth, setFifth] = useState();
//   const [sixth, setSixth] = useState();
//   const dispatch = useDispatch();
//   const [nextPressed, setNextPressed] = useState();
//   const { verifyOtpForgetPassword, error: verifyOtpForgetPasswordError } =
//     useVerifyOtpForgetPassword();
//   const { verifyOtpEmailSignup, error: verifyOtpEmailSignupError } =
//     useVerifyOtpEmailSignup();
//   const { verifyOtpPhone, error: verifyOtpPhoneError } = useVerifyOtpPhone();

//   return (
//     <>
//       <VStack
//         space={"20px"}
//         pt={12}
//         px={{
//           md: "10px",
//           lg: "28px",
//           xl: "28px",
//         }}
//         h="full"
//       >
//         <Input
//           isReadOnly={true}
//           value={route?.params?.type === "phone" ? form?.phone : form?.email}
//           fontSize={"16px"}
//           lineHeight={"21px"}
//           autoCapitalize="none"
//           style={{
//             fontFamily: "HK_700Bold",
//           }}
//           placeholderTextColor={"white"}
//           borderWidth={"1px"}
//           borderColor={"#58595B"}
//           p={"16px"}
//           rounded={"8px"}
//           color="white"
//           _focus={{
//             borderColor: "#58595B",
//             backgroundColor: "black",
//             color: "white",
//           }}
//         ></Input>
//         <Text
//           color={"#94A3B8"}
//           fontSize={"16px"}
//           letterSpacing={"sm"}
//           style={{
//             fontFamily: "HK_500Medium",
//           }}
//         >
//           Enter Code
//         </Text>

//         <VStack>
//           <OTP
//             first={first}
//             second={second}
//             third={third}
//             fourth={fourth}
//             fifth={fifth}
//             sixth={sixth}
//             setFirst={(val) => setFirst(val)}
//             setSecond={(val) => setSecond(val)}
//             setThird={(val) => setThird(val)}
//             setFourth={(val) => setFourth(val)}
//             setFifth={(val) => setFifth(val)}
//             setSixth={(val) => setSixth(val)}
//             setNextPressed={(val) => setNextPressed(val)}
//           />

//           {(verifyOtpEmailSignupError ||
//             verifyOtpForgetPasswordError ||
//             verifyOtpPhoneError) && <Error error={verifyOtpEmailSignupError} />}

//           {!(first && second && third && fourth && fifth && sixth) &&
//             nextPressed && <Error error="Fill credentials." />}
//         </VStack>
//         <Center>
//           <Center h="50px" w="173.35px">
//             <PrimaryCTAButton
//               onPress={async () => {
//                 Keyboard.dismiss();
//                 setNextPressed(true);
//                 if (first && second && third && fourth && fifth && sixth) {
//                   dispatch(showLoader());
//                   try {
//                     if (
//                       route?.params?.type === "phone" &&
//                       form?.country_code &&
//                       form?.phone &&
//                       !/\s/g.test(form?.country_code) &&
//                       !/\s/g.test(form?.phone)
//                     ) {
//                       verifyOtpPhone(
//                         `+${form.country_code}${form?.phone}`,
//                         first + second + third + fourth + fifth + sixth
//                       );
//                     } else if (
//                       route?.params?.type === "forgetPassword" &&
//                       form?.email &&
//                       !/\s/g.test(form?.email)
//                     ) {
//                       verifyOtpForgetPassword(
//                         form?.email,
//                         first + second + third + fourth + fifth + sixth
//                       );
//                     } else {
//                       verifyOtpEmailSignup(
//                         form?.email,
//                         first + second + third + fourth + fifth + sixth
//                       );
//                     }
//                   } catch (error) {}
//                   dispatch(hideLoader());
//                 }
//               }}
//               gradientType="2"
//             >
//               <CTAButtonText text="Next" />
//             </PrimaryCTAButton>
//           </Center>
//         </Center>
//       </VStack>
//     </>
//   );
// };

// export default VerifyScreen;

import SignupHOC from "../../hoc/SignupHOC";
import VerifyOtpForm from "../../components/forms/verifyOtpForm";

const VerifyScreen = () => {
  return (
    <>
      <SignupHOC>
        <VerifyOtpForm />
      </SignupHOC>
    </>
  );
};

export default VerifyScreen;
