import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const BackIcon = ({ color }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={8} viewBox="0 0 36 36">
        <G
          width="36"
          height="36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M20.3958 12.4207C20.5852 12.2298 20.6914 11.9718 20.6914 11.703C20.6914 11.4341 20.5852 11.1761 20.3958 10.9852C20.303 10.8909 20.1923 10.816 20.0702 10.7649C19.9482 10.7138 19.8172 10.6875 19.6848 10.6875C19.5525 10.6875 19.4215 10.7138 19.2994 10.7649C19.1774 10.816 19.0667 10.8909 18.9738 10.9852L13.3488 16.7227C13.16 16.9141 13.0541 17.1721 13.0541 17.441C13.0541 17.7099 13.16 17.968 13.3488 18.1593L18.9738 23.8968C19.0667 23.9911 19.1774 24.066 19.2994 24.1171C19.4215 24.1682 19.5525 24.1945 19.6848 24.1945C19.8172 24.1945 19.9482 24.1682 20.0702 24.1171C20.1923 24.066 20.303 23.9911 20.3958 23.8968C20.5847 23.7055 20.6906 23.4474 20.6906 23.1785C20.6906 22.9096 20.5847 22.6516 20.3958 22.4602L15.7867 17.4382L20.3958 12.4207Z"
            fill="white"
          />
        </G>
      </Icon>
    </>
  );
};

export default BackIcon;
