import { useRoute } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import { setAnalytics } from "../../../../analytics";
import { useGoBack } from "../../../../hooks/useGoBack";
import { hideLoader, showLoader } from "../../../../redux/currentState/actions";
import { privateApi } from "../../../../util/API";
import { getUser } from "../../../../util/utilFunctions";
import ChevronBackArrowIcon from "../../../icons/chevronBackArrowIcon";
import { useSWRConfig } from "swr";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
export const BackIcon = () => {
  const goBack = useGoBack();
  const route = useRoute();
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  return (
    <ChevronBackArrowIcon
      onPress={async () => {
        const userId = await getUser();
        if (route?.name === "NotificationsScreen") {
          dispatch(showLoader());
          const res = await privateApi(
            "/api/users/notification_seen/",
            {
              id: userId,
            },
            "put"
          );

          if (res?.status === 200) {
            mutate(
              `${apiUrl}/api/users/get_user_notifications/?user_id=${userId}`
            );
            dispatch(hideLoader());
          }
        }

        setAnalytics("Back Button Top Bar", "Back Button Clicked", {
          screen: route?.name,
        });
        goBack();
      }}
    />
  );
};
