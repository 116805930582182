import { useNavigation } from "@react-navigation/native";
import { Center, HStack, IconButton, Text, View } from "native-base";
import { useSelector } from "react-redux";
import { setAnalytics } from "../analytics";
import { Pages } from "../constants/pages";
import useGetCartFlockDeals from "../hooks/useGetCartFlockDeals";
import useGetCartItems from "../hooks/useGetCartItems";
import useGetNotifications from "../hooks/useGetNotifications";
import FilledWhiteBellIcon from "./icons/filledIcons/filledWhiteBellIcon";
import FilledWhiteCartIcon from "./icons/filledIcons/filledWhiteCartIcon";
import ActiveSearchIcon from "./icons/bottomBarIcons/activeIcons/activeSearchIcon";
const BellAndCartIcon = () => {
  const { data } = useGetNotifications();
  const navigation = useNavigation();
  const userId = useSelector((state) => state.user.id);
  const { data: cartItem } = useGetCartItems();
  const { data: flockDeals } = useGetCartFlockDeals(userId);

  return (
    <>
      <HStack alignItems={"center"} space={"12px"} h="full">
        {/* <IconButton
          size={"30px"}
          onPress={() => {
            setAnalytics("Top Bar", `Notifications Bell Icon Clicked`);
            navigation.navigate(Pages.Search);
          }}
          icon={<ActiveSearchIcon />}
        ></IconButton> */}
        <IconButton
          size={"30px"}
          onPress={async () => {
            setAnalytics("Top Bar", `Notifications Bell Icon Clicked`);
            navigation.navigate("NotificationsScreen");
          }}
          icon={
            <View>
              <FilledWhiteBellIcon />
              {data && data?.filter((k) => !k.seen)?.length > 0 && (
                <Center
                  position={"absolute"}
                  w="4"
                  h="4"
                  left={"45%"}
                  top={"-10%"}
                  bg="red.600"
                  rounded={"full"}
                >
                  <Text
                    color={"white"}
                    fontSize={"8px"}
                    style={{
                      fontFamily: "HK_600SemiBold",
                    }}
                  >
                    {data?.filter((k) => !k.seen)?.length < 100
                      ? data?.filter((k) => !k.seen)?.length
                      : "99+"}
                  </Text>
                </Center>
              )}
            </View>
          }
        />
        <IconButton
          size={"30px"}
          onPress={() => {
            setAnalytics("Top Bar", `Cart Icon Clicked`);
            navigation.navigate(Pages.Cart);
          }}
          icon={
            <>
              <FilledWhiteCartIcon />
              {cartItem?.map((k) => k?.number).reduce((k, i) => k + i, 0) >
                0 && (
                <Center
                  position={"absolute"}
                  w="4"
                  h="4"
                  left={"30%"}
                  top={"-27%"}
                  bg="red.600"
                  rounded={"full"}
                >
                  <Text
                    color={"white"}
                    fontSize={"8px"}
                    style={{
                      fontFamily: "HK_600SemiBold",
                    }}
                  >
                    {cartItem
                      ?.map((k) => k?.number)
                      .reduce((k, i) => k + i, 0) < 99
                      ? cartItem
                          ?.map((k) => k?.number)
                          .reduce((k, i) => k + i, 0)
                      : "99+"}
                  </Text>
                </Center>
              )}
            </>
          }
        />
      </HStack>
    </>
  );
};

export default BellAndCartIcon;
