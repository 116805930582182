import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

const SearchIconOutlined = ({ size = 5 }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={size} viewBox='0 0 24 24'>
        <G width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <Path
            d='M16.261 16.2607L22.0001 21.9999'
            stroke={'#94A3B8'}
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <Path
            d='M16.9239 9.08696C16.9239 13.4152 13.4152 16.9239 9.08696 16.9239C4.75873 16.9239 1.25 13.4152 1.25 9.08696C1.25 4.75873 4.75873 1.25 9.08696 1.25C13.4152 1.25 16.9239 4.75873 16.9239 9.08696Z'
            stroke={'#94A3B8'}
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </G>
      </Icon>
    </>
  );
};

export default SearchIconOutlined;
