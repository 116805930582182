import {
  Text,
  HStack,
  VStack,
  Pressable,
  ScrollView,
  Center,
} from "native-base";
import { useEffect, useState } from "react";
import PlusIcon from "../../../components/icons/plusIcon";
import { useNavigation } from "@react-navigation/native";
import PrimaryCTAButton from "../../../components/buttons/primaryCTAButton";
import CTAButtonText from "../../../components/texts/ctaButtonText";
import PaymentCard from "../../../components/cards/paymentCard";
import useGetCardList from "../../../hooks/useGetCardList";
import { useAddDefaultPaymentCard } from "../../../hooks/useAddDefaultPaymentCard";

const PaymentMethodsScreen = () => {
  const { data } = useGetCardList();
  const navigation = useNavigation();
  const [selectedValue, setSelectedValue] = useState(
    data?.cards?.length > 0 && data?.cards?.find((c) => c.id).id
  );
  const handleChange = async (i) => {
    setSelectedValue(i);
  };

  const { addDefaultPaymentCard } = useAddDefaultPaymentCard();

  return (
    <>
      <VStack px={"10px"} py={4} space={2}>
        <Text
          pt={5}
          fontSize={16}
          style={{
            fontFamily: "HK_700Bold",
          }}
          color="#94A3B8"
        >
          Saved Payment Method
        </Text>
        <Pressable
          onPress={() => {
            navigation.navigate("addcard");
          }}
        >
          <HStack
            alignItems={"center"}
            borderRadius={"8px"}
            justifyContent="space-between"
            _light={{
              bg: "white",
              borderColor: "#E9B9FF",
              borderWidth: "1px",
            }}
            _dark={{
              bg: "primary.card",
            }}
            p={"16px"}
          >
            <Text
              fontSize={16}
              style={{
                fontFamily: "HK_600SemiBold",
              }}
              color="#94A3B8"
            >
              Add New Payment Mode
            </Text>
            <PlusIcon size={"2.5"} />
          </HStack>
        </Pressable>
      </VStack>
      <ScrollView px={"10px"} showsVerticalScrollIndicator={false}>
        <VStack space={"8px"}>
          <>
            {data?.cards?.length > 0 &&
              data?.cards?.map((card, index) => {
                return (
                  <>
                    <PaymentCard
                      card={card}
                      isPaymentPopUp={true}
                      onCheck={(event) => handleChange(card.id)}
                      isChecked={
                        selectedValue
                          ? selectedValue === card.id
                          : data?.cards?.find((c) => c.id).id === card.id
                      }
                    />
                  </>
                );
              })}
          </>
        </VStack>
      </ScrollView>
      <Center safeAreaBottom py={4}>
        <Center w="150px" h="50px">
          <PrimaryCTAButton
            gradientType="1"
            onPress={() => addDefaultPaymentCard(selectedValue)}
          >
            <CTAButtonText text="confirm" />
          </PrimaryCTAButton>
        </Center>
      </Center>
    </>
  );
};

export default PaymentMethodsScreen;
