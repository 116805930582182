export const STORE_WISHLIST='STORE_CART_WISHLIST'
export const ADD_WISHLIST= 'ADD_CART_WISHLIST'
export const DELETE_WISHLIST= 'DELETE_CART_WISHLIST'
export const STORE_GROUPS = 'STORE_GROUPS'
export const ADD_GROUP = 'ADD_GROUP'
export const DELETE_GROUP = 'DELETE_GROUP'
export const STORE_CART = 'STORE_CART'
export const ADD_CART = 'ADD_CART'
export const DELETE_CART = 'DELETE_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const STORE_VALUES = 'STORE_VALUES'
export const RELOAD_CART = 'RELOAD_CART'
export const RELOAD_WISHLIST = 'RELOAD_WISHLIST'
export const RELOAD_GROUPS = 'RELOAD_GROUPS'