import { Image, Box, Text, HStack, Center } from "native-base";
import { useEffect } from "react";
import { useState } from "react";

const TimerChip = ({ time = null, images }) => {
  var now = Date.now();
  var d = new Date(0);

  function secondsToString(seconds) {
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    return numhours + ":" + numminutes;
  }

  const [timer, setTimer] = useState();
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (time !== null) {
      now = Date.now() / 1000;
      setTimer(secondsToString(86400 + time - now));
      setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 60000);
    }
  }, [seconds, time]);

  return (
    <Center
      h="47px"
      width="372px"
      rounded={"full"}
      bg={{
        linearGradient: {
          colors: [
            "primary.ctaLinearSecondColor",
            "primary.ctaLinearFirstColor",
          ],
          start: [0, 0],
          end: [1, 0],
        },
      }}
    >
      <HStack
        h="45px"
        width="370px"
        bg={"#202427"}
        rounded={"full"}
        justifyContent={"space-around"}
        alignItems="center"
      >
        <Image
          resizeMode="contain"
          source={require("./../../assets/images/pngs/flockDealTimerText.png")}
          alt=""
        />

        {images?.length < 3 && (
          <Text
            fontSize={20}
            color="#ffffff"
            style={{
              fontFamily: "HK_600SemiBold",
            }}
          >
            {time && time !== null ? timer : ""}
          </Text>
        )}
      </HStack>
    </Center>
  );
};

export default TimerChip;
