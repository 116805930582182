import { Image } from "native-base";

const TrendingFlatAnimatedIcon = () => {
  return (
    <Image
      size={4}
      resizeMethod="scale"
      resizeMode="contain"
      source={require("./../../../assets/images/gifs/trendingFlat.gif")}
      alt=""
    />
  );
};

export default TrendingFlatAnimatedIcon;
