import { Center, Input, Stack, Text, VStack } from "native-base";
import { useContext } from "react";
import { gradientTextWeb } from "../../constants/gradient";
import { AddNewAddressContext } from "../../hoc/AddNewAddressHOC";
import PrimaryCTAButton from "../buttons/primaryCTAButton";

const ShippingAddressForm = () => {
  const { handleInput, addNewAddress } = useContext(AddNewAddressContext);

  return (
    <VStack space={"24px"} w="full" alignItems={"center"}>
      <Text
        letterSpacing={2}
        _web={{
          style: {
            ...gradientTextWeb(1),
            fontFamily: "AustinCyr_400",
          },
        }}
        fontSize="36px"
      >
        Address
      </Text>

      <VStack space={12} w="full" alignItems={"center"}>
        <Stack
          w="full"
          alignItems={"center"}
          justifyContent="center"
          space={"24px"}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
        >
          <VStack space={2}>
            {[
              {
                label: "Title(Optional)",
                placeholder: "Home",
                field: "title",
              },
              {
                label: "Name",
                placeholder: "Alex",
                field: "name",
              },
              {
                label: "Street",
                placeholder: "4",
                field: "street",
              },
              {
                label: "Unit",
                placeholder: "9",
                field: "unit",
              },
            ].map((k, i) => (
              <VStack space={"6px"}>
                <Text
                  letterSpacing={2}
                  color={"#464646"}
                  fontSize="14px"
                  style={{
                    fontFamily: "Hero_400Regular",
                  }}
                >
                  {k?.label}
                </Text>
                <Input
                  px="22px"
                  py="13px"
                  w={{
                    md: "full",
                    lg: "full",
                    xl: "303px",
                  }}
                  h="39px"
                  color="#464646"
                  variant="outline"
                  focusOutlineColor="#682481"
                  _focus={{
                    bg: "none",
                    color: "#464646",
                  }}
                  _hover={{
                    bg: "none",
                    color: "#464646",
                    borderColor: "#682481",
                  }}
                  style={{
                    fontFamily: "Hero_400Regular",
                  }}
                  borderRadius="7px"
                  borderColor="#C1C9D2"
                  fontSize="14px"
                  placeholderTextColor={"#C1C9D2"}
                  placeholder={k?.placeholder}
                  borderWidth={"1px"}
                  onChangeText={(val) => handleInput(val, k?.field, "shipping")}
                ></Input>
              </VStack>
            ))}
          </VStack>
          <VStack space={2}>
            {[
              {
                label: "City",
                placeholder: "Home",
                field: "city",
              },
              {
                label: "State",
                placeholder: "Alex",
                field: "state",
              },
              {
                label: "Zipcode",
                placeholder: "4696",
                field: "zipcode",
              },
              {
                label: "Phone number",
                placeholder: "+18974569044",
                field: "phone_number",
              },
            ].map((k, i) => (
              <VStack space={"6px"}>
                <Text letterSpacing={2} fontSize="14px" color={"#464646"}>
                  {k?.label}
                </Text>
                <Input
                  px="22px"
                  py="13px"
                  w={{
                    md: "full",
                    lg: "full",
                    xl: "303px",
                  }}
                  h="39px"
                  color="#464646"
                  variant="outline"
                  focusOutlineColor="#682481"
                  _focus={{
                    bg: "none",
                    color: "#464646",
                  }}
                  _hover={{
                    bg: "none",
                    color: "#464646",
                    borderColor: "#682481",
                  }}
                  style={{
                    fontFamily: "Hero_400Regular",
                  }}
                  borderRadius="7px"
                  borderColor="#C1C9D2"
                  fontSize="14px"
                  placeholderTextColor={"#C1C9D2"}
                  placeholder={k?.placeholder}
                  borderWidth={"1px"}
                  onChangeText={(val) => handleInput(val, k?.field, "shipping")}
                ></Input>
              </VStack>
            ))}
          </VStack>
        </Stack>
        <Center h="50px" w="200px">
          <PrimaryCTAButton gradientType="2" onPress={addNewAddress}>
            <Text
              letterSpacing={2}
              textTransform={"uppercase"}
              fontSize={"16px"}
              style={{
                fontFamily: "Hero_700Bold",
              }}
              color="white"
            >
              Confirm
            </Text>
          </PrimaryCTAButton>
        </Center>
      </VStack>
    </VStack>
  );
};

export default ShippingAddressForm;
