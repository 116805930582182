import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const WebLinkedInIcon = () => {
  return (
    <Icon viewBox="0 0 24 25">
      <G>
        <Path
          d="M22.0805 0.84082H2.05071C0.95625 0.84082 0 1.62832 0 2.70993V22.7842C0 23.8717 0.95625 24.8408 2.05071 24.8408H22.0746C23.175 24.8408 24 23.8653 24 22.7842V2.70993C24.0064 1.62832 23.175 0.84082 22.0805 0.84082ZM7.43946 20.846H4.00125V10.1558H7.43946V20.846ZM5.83929 8.53046H5.81464C4.71429 8.53046 4.00179 7.71136 4.00179 6.686C4.00179 5.64189 4.73304 4.84207 5.85804 4.84207C6.98304 4.84207 7.67143 5.636 7.69607 6.686C7.69554 7.71136 6.98304 8.53046 5.83929 8.53046ZM20.0052 20.846H16.567V15.0008C16.567 13.6005 16.0666 12.6437 14.8227 12.6437C13.8723 12.6437 13.3098 13.2865 13.0596 13.9128C12.9659 14.1378 12.9407 14.4442 12.9407 14.7571V20.846H9.5025V10.1558H12.9407V11.6435C13.4411 10.931 14.2227 9.90564 16.0414 9.90564C18.2984 9.90564 20.0057 11.3933 20.0057 14.6006L20.0052 20.846Z"
          fill="#D274FF"
        />
      </G>
    </Icon>
  );
};

export default WebLinkedInIcon;
