import { Center, HStack, Text } from "native-base";
import { useContext } from "react";
import { gradientTextWeb } from "../../constants/gradient";
import { AddToCartContext } from "../../hoc/AddToCartHOC";
import WebGradientActiveCartIcon from "../icons/webIcons/webGradientActiveCartIcon";
import WebGradientCartIcon from "../icons/webIcons/webGradientCartIcon";
import PillGradientBorderButton from "./pillGradientBorderButton";

const AddToCartButton = () => {
  const { onPress, inCart } = useContext(AddToCartContext);
  return (
    <Center w="241px" h="60px">
      <PillGradientBorderButton onPress={onPress} gradientType="2">
        <HStack alignItems={"center"} space={2}>
          {inCart ? <WebGradientActiveCartIcon /> : <WebGradientCartIcon />}
          <Text
            letterSpacing={"2"}
            textTransform={"uppercase"}
            _web={{
              style: {
                ...gradientTextWeb(1),
                fontFamily: "Hero_700Bold",
              },
            }}
            fontSize={"18px"}
          >
            {inCart ? "In cart" : "Add to cart"}
          </Text>
        </HStack>
      </PillGradientBorderButton>
    </Center>
  );
};

export default AddToCartButton;
