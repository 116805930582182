import { HStack, Text } from "native-base";

const ShippedChip = () => {
  return (
    <HStack
      borderRadius="full"
      px={"12px"}
      py={"4px"}
      height="29px"
      width={"91px"}
      alignItems="center"
      bg="#DBEEFF"
      space={2}
      justifyContent="center"
    >
      <Text
        fontSize={14}
        style={{ fontFamily: "HK_600SemiBold" }}
        color="#0D5BB5"
      >
        Shipped
      </Text>
    </HStack>
  );
};

export default ShippedChip;
