import { BackIcon } from "./buildingBlocks/backIcon";
import { CenterText } from "./buildingBlocks/centerText";
import TopBar from "./templates/topBar";

const BackButtonTopBar = ({ heading, icon }) => {
  return (
    <>
      <TopBar
      heading={heading}
        content={(
          <>
            <BackIcon />
            {icon && icon}
            {heading?<CenterText text = {heading} />:<></>}
          </>
        )} />
    </>
  );
};

export default BackButtonTopBar;
