import { Center, Input, Text, VStack, Stack } from "native-base";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { SignupContext } from "../../hoc/SignupHOC";
import PrimaryCTAButton from "../buttons/primaryCTAButton";
import Error from "../error";
import GreenTickIcon from "../icons/greenTickIcon";
import OTP from "../otp";
import SignupForm from "./signupForm";

const SignupOptionForm = () => {
  const {
    countryCode,
    first,
    second,
    third,
    fourth,
    fifth,
    sixth,
    setFirst,
    setSecond,
    setThird,
    setFourth,
    setFifth,
    setSixth,
    phone,
    sendOtp,
    email,
    sendEmailRegistrationOtp,
    shouldVerifyEmailOtp,
    shouldVerifyPhoneOtp,
    emailOtpVerificationError,
    phoneOtpVerificationError,
    handleInput,
    verifyOtpPhone,
    verifyOtpEmailSignup,
    emailOtpVerified,
    phoneOtpVerified,
  } = useContext(SignupContext);
  const form = useSelector((state) => state.form);

  return (
    <VStack space={"20px"}>
      {phoneOtpVerified || emailOtpVerified ? (
        <SignupForm />
      ) : (
        <VStack space={"20px"}>
          {[
            {
              label: "Email Address*",
              placeholder: "name@email.com",
              field: "email",
              error: email?.status === 400 && <Error error={email?.message} />,
              verified:
                form?.email &&
                !/\s/g.test(form?.email) &&
                email?.status === 200,
            },
            {
              label: "Mobile Number*",
              placeholder: "98765432100",
              field: "phone_number",
              error: phone?.status === 400 && <Error error={phone?.message} />,
              verified:
                form?.phone_number &&
                !/\s/g.test(form?.phone_number) &&
                phone?.status === 200,
            },
          ].map((k, i) => (
            <VStack space={"6px"}>
              <Text
                letterSpacing={3}
                textTransform="uppercase"
                color={"#682481"}
                fontSize="11px"
                style={{
                  fontFamily: "HK_700Bold",
                }}
              >
                {k?.label}
              </Text>
              <Input
                rightElement={
                  k?.verified && (
                    <Center px={3}>
                      <GreenTickIcon />
                    </Center>
                  )
                }
                leftElement={
                  i === 1 && (
                    <Stack direction={"row"} pl={4}>
                      +{countryCode}
                    </Stack>
                  )
                }
                px="22px"
                py="13px"
                w={{
                  md: "full",
                  lg: "full",
                  xl: "303px",
                }}
                h="39px"
                color="#464646"
                variant="outline"
                focusOutlineColor="#682481"
                _focus={{
                  bg: "none",
                  color: "#464646",
                }}
                _hover={{
                  bg: "none",
                  color: "#464646",
                  borderColor: "#682481",
                }}
                style={{
                  fontFamily: "HK_700Bold",
                }}
                borderRadius="7px"
                borderColor="#C1C9D2"
                fontSize="14px"
                placeholderTextColor={"#C1C9D2"}
                placeholder={k?.placeholder}
                borderWidth={"1px"}
                onChangeText={(val) => {
                  handleInput(k?.field)(val);
                }}
              ></Input>
              {k?.error}
              {i !== 1 && (
                <Text
                  textAlign={"center"}
                  letterSpacing={3}
                  textTransform="uppercase"
                  color={"#682481"}
                  fontSize="11px"
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                >
                  or
                </Text>
              )}
            </VStack>
          ))}

          {(shouldVerifyEmailOtp || shouldVerifyPhoneOtp) && (
            <VStack space={"6px"}>
              <Text
                letterSpacing={3}
                textTransform="uppercase"
                color={"#682481"}
                fontSize="11px"
                style={{
                  fontFamily: "Hero_700Bold",
                }}
              >
                Enter Code
              </Text>
              <OTP
                textColor="#464646"
                h="38px"
                w="38px"
                borderColor="#682481"
                first={first}
                second={second}
                third={third}
                fourth={fourth}
                fifth={fifth}
                sixth={sixth}
                setFirst={(val) => setFirst(val)}
                setSecond={(val) => setSecond(val)}
                setThird={(val) => setThird(val)}
                setFourth={(val) => setFourth(val)}
                setFifth={(val) => setFifth(val)}
                setSixth={(val) => setSixth(val)}
              />
              {(emailOtpVerificationError || phoneOtpVerificationError) && (
                <Error
                  error={emailOtpVerificationError || phoneOtpVerificationError}
                />
              )}
            </VStack>
          )}

          <Center h="36px">
            <PrimaryCTAButton
              onPress={() => {
                let otp = first + second + third + fourth + fifth + sixth;
                if (
                  form?.email &&
                  !form?.phone_number &&
                  email?.status !== 400
                ) {
                  if (shouldVerifyEmailOtp) {
                    verifyOtpEmailSignup(form?.email, otp);
                  } else {
                    sendEmailRegistrationOtp();
                  }
                } else if (
                  form?.phone_number &&
                  !form?.email &&
                  phone?.status !== 400
                ) {
                  if (shouldVerifyPhoneOtp) {
                    verifyOtpPhone(`+${countryCode}${form?.phone_number}`, otp);
                  } else {
                    sendOtp(false);
                  }
                }
              }}
              gradientType="2"
            >
              <Text
                letterSpacing={2}
                textTransform={"uppercase"}
                fontSize={"11px"}
                style={{
                  fontFamily: "Hero_700Bold",
                }}
                color="white"
              >
                {phoneOtpVerified || emailOtpVerified ? "Confirm" : "Next"}
              </Text>
            </PrimaryCTAButton>
          </Center>
        </VStack>
      )}
    </VStack>
  );
};

export default SignupOptionForm;
