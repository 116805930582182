import {
  Center,
  Divider,
  HStack,
  IconButton,
  Modal,
  ScrollView,
  Text,
  VStack,
} from "native-base";

import { useDispatch, useSelector } from "react-redux";
import { goBackDialog } from "../../redux/currentState/actions";
import CloseIcon from "../icons/closeIcon";
import ListIcon from "./../../assets/images/svgs/listalt.svg";
const list = [
  "Spend $50 and get $20 back",
  "Spent $100 and get $50 back",
  "Spend $200+ and get $120 back",
  "Cashback is based on the total user spend between between Dec 20th 2022 12:00am EST to Jan 31st 11:59pm EST.",
  "Limited to one cashback redemption per customer.",
  "Cashback value will be added to the user account in the form of Flockbucks for future purchases on Feb 1st",
];

const BuyMoreSaveMoreModal = () => {
  const dispatch = useDispatch();
  const openDialog = useSelector((state) => state.currentState.openDialog);
  return (
    <Modal
      size="full"
      bg="black:alpha.80"
      isOpen={openDialog}
      onClose={() => dispatch(goBackDialog())}
    >
      <Modal.Content
        bg={"#2B2F33"}
        rounded={"20px"}
        width={{
          md: "300px",
          lg: "390px",
          xl: "420px",
        }}
      >
        <HStack justifyContent={"flex-end"}>
          <IconButton
            onPress={() => dispatch(goBackDialog())}
            _icon={{ size: 8 }}
            icon={<CloseIcon />}
          ></IconButton>
        </HStack>
        <ScrollView showsVerticalScrollIndicator={false}>
          <VStack pb={4} space={4} px={6} w="full">
            <VStack space={2} w="full">
              <HStack alignItems={"center"} space={2}>
                <ListIcon />
                <Text
                  fontSize={"12px"}
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                  color="#94A3B8"
                >
                  Terms & Conditions
                </Text>
              </HStack>
              <Text
                fontSize={"28px"}
                style={{
                  fontFamily: "HK_600SemiBold",
                }}
                color="#ffffff"
              >
                Buy more! Save more!
              </Text>

              <Text
                fontSize={"18px"}
                style={{
                  fontFamily: "HK_500Medium",
                }}
                color="#ffffff"
              >
                Earn cashback on your total spend on Flock between Dec 20th 2022
                12am EST to Jan 31st 11:59pm EST.
              </Text>
            </VStack>
            <Divider bg="#58595B" />

            <VStack space={1}>
              <Text
                fontSize={"14px"}
                style={{
                  fontFamily: "HK_500Medium",
                }}
                color="#ffffff"
              >
                Between Dec 20th 2022 12am EST to Jan 31st 11:59pm EST:
              </Text>
              {list?.map((k) => (
                <HStack space={1.5}>
                  <Center rounded={"full"} mt={2} size={1} bg="white"></Center>
                  <Text
                    fontSize={"14px"}
                    style={{
                      fontFamily: "HK_500Medium",
                    }}
                    color="#ffffff"
                  >
                    {k}
                  </Text>
                </HStack>
              ))}
            </VStack>
          </VStack>
        </ScrollView>
      </Modal.Content>
    </Modal>
  );
};

export default BuyMoreSaveMoreModal;
