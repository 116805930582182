import { Pressable, HStack, Text, Menu, VStack } from "native-base";
import ChevronLeftArrowIcon from "../icons/chevronLeftArrowIcon";
import { Dimensions, Platform } from "react-native";
import { useEffect, useState } from "react";
import { useGetProductDetails } from "../../hooks/useGetProductDetails";
import { useNavigation, useRoute } from "@react-navigation/native";
import { setError } from "../../redux/currentState/actions";
import { useSWRConfig } from "swr";
import ChevronDownArrowIcon from "../icons/chevronDownArrowIcon";
import ChevronUpArrowIcon from "../icons/chevronUpArrowIcon";
import Constants from "expo-constants";
import { useChangeCartVariant } from "../../hooks/useChangeCartVariant";
const apiUrl = Constants.expoConfig.extra.apiUrl;

const FullWidthAccordion = ({
  setSelectedSku,
  label,
  items,
  val,
  type,
  id,
  setSelectedProductSku,
  selectedProductSku,
  wholeProduct,
  previousSkuId,
  previousNum,
  selectedSkus,
  setSelectedSkus,
}) => {
  const [value, setValue] = useState(val);
  const route = useRoute();
  const navigation = useNavigation();
  const { product } = useGetProductDetails(id ? id : route?.params?.id);
  const { mutate } = useSWRConfig();
  const changeCartVariant = useChangeCartVariant();
  useEffect(() => {
    setValue(val);
  }, [val]);

  const [arrow, setArrow] = useState(<ChevronDownArrowIcon size={2} />);

  const onSelect = (index, item) => {
    setValue(item);
    if (wholeProduct) {
      let sku_id = null;
      if (wholeProduct?.variant?.color) {
        Object.values(wholeProduct.skus).map((z) => {
          if (z?.color === item) {
            sku_id = z.id;
          }
        });
      }
      if (wholeProduct?.variant?.scent) {
        Object.values(wholeProduct.skus).map((z) => {
          if (z?.scent === item) {
            sku_id = z.id;
          }
        });
      }
      if (wholeProduct?.variant?.size) {
        Object.values(wholeProduct.skus).map((z) => {
          if (z?.size === item) {
            sku_id = z.id;
          }
        });
      }
      if (wholeProduct?.variant?.type) {
        Object.values(wholeProduct.skus).map((z) => {
          if (z?.type === item) {
            sku_id = z.id;
          }
        });
      }

      if ((id, selectedProductSku, setSelectedProductSku)) {
        const temp = selectedProductSku[id];
        if (temp) {
          temp["selectedSku"] = sku_id;
          setSelectedProductSku({ ...selectedProductSku, [id]: temp });
        } else {
          let temp1 = {};
          temp1["id"] = id;
          temp1["selectedSku"] = sku_id;
          temp1["skus"] = Object.values(wholeProduct.skus);
          temp1["product"] = wholeProduct;
          setSelectedProductSku({ ...selectedProductSku, [id]: temp1 });
        }
      }
    }
    if (type === "change" || type === "card") {
      try {
        let k = 0;
        let tempVal = [...product?.variantValues];
        if (label === "Color") {
          k = 0;
        } else if (label === "Size") {
          k = 1;
        } else if (label === "Type") {
          k = 2;
        } else if (label === "Fragrance") {
          k = 3;
        }
        tempVal[k] = index;

        if (product?.variantsMapping[tempVal]) {
          let it = product?.variantsMapping[tempVal];
          changeCartVariant(it, previousSkuId, previousNum);
          navigation.setParams({
            sku_id: it,
          });

          mutate(
            `${apiUrl}/api/products/get_product_details/?product_id=${route?.params?.id}`
          );
        } else {
          setError("Does not exist");
        }
      } catch (ex) {}
    }
    if (type === "collectioncard") {
      try {
        let k = 0;
        let tempVal = [...product?.variantValues];
        if (label === "Color") {
          k = 0;
        } else if (label === "Size") {
          k = 1;
        } else if (label === "Type") {
          k = 2;
        } else if (label === "Fragrance") {
          k = 3;
        }
        tempVal[k] = index;

        if (product?.variantsMapping[tempVal]) {
          let it = product?.variantsMapping[tempVal];
          console.log("accor", product);
          let copy = [...selectedSkus];

          if (copy.find((f) => f.sku === it)) {
            copy = [...copy.filter((f) => f.sku !== it)];
          } else {
            copy = [
              ...copy,
              {
                product: product?.details,
                sku: it,
              },
            ];
          }

          setSelectedSkus(copy);
          setSelectedSku(it);
        } else {
          setError("Does not exist");
        }
      } catch (ex) {}
    }
    if (product) {
      try {
        let k = 0;
        let tempVal = [...product?.variantValues];
        if (label === "Color") {
          k = 0;
        } else if (label === "Size") {
          k = 1;
        } else if (label === "Type") {
          k = 2;
        } else if (label === "Fragrance") {
          k = 3;
        }
        tempVal[k] = index;

        if (product?.variantsMapping[tempVal]) {
          let it = product?.variantsMapping[tempVal];

          navigation.setParams({
            sku_id: it,
          });

          mutate(
            `${apiUrl}/api/products/get_product_details/?product_id=${route?.params?.id}`
          );
        } else {
          setError("Does not exist");
        }
      } catch (ex) {}
    }
  };

  // useEffect(() => {
  //   const tempLabels = {};
  //   if (product) {
  //     if (items.length > 1) {
  //       let k = 0;
  //       var item;
  //       var tempVal;
  //       if (label === "Color") {
  //         k = 0;
  //       } else if ((label = "Fragrance")) {
  //         k = 1;
  //       } else if ((label = "Size")) {
  //         k = 2;
  //       } else if ((label = "Type")) {
  //         k = 3;
  //       }
  //       items.map((item, index) => {
  //         tempVal = [...product.variantValues];
  //         tempVal[k] = index;
  //         if (product.variants.indexOf(tempVal) === -1) {
  //           tempLabels[index] = false;
  //         } else {
  //           tempLabels[index] = false;
  //         }
  //         return null;
  //       });
  //       setLabelMapping(tempLabels);
  //     }
  //   }
  // }, [product]);

  return (
    <>
      {items?.length > 1 ? (
        <Menu
          h="200px"
          _android={{
            w: Dimensions.get("window").width,
          }}
          _ios={{
            w: Dimensions.get("window").width,
          }}
          _dark={{
            bg: "#2B2F33",
          }}
          _light={{
            bg: "white",
          }}
          my={2}
          onOpen={() => setArrow(<ChevronUpArrowIcon size={2} />)}
          onClose={() => setArrow(<ChevronDownArrowIcon size={2} />)}
          trigger={(triggerProps) => {
            return (
              <Pressable {...triggerProps} w="full">
                {type === "card" || type === "collectioncard" ? (
                  <HStack
                    w="70%"
                    space={2}
                    alignItems={"center"}
                    mt={1}
                    py={"2px"}
                    px={"9px"}
                    rounded={"4px"}
                    _light={{
                      bg: "#E9B9FF",
                    }}
                    _dark={{
                      bg: "#2B2F33",
                    }}
                  >
                    <Text
                      noOfLines={1}
                      _web={{
                        style: {
                          fontFamily: "Hero_400Regular",
                        },
                      }}
                      style={{
                        fontFamily: "HK_400Regular",
                      }}
                      fontSize="12px"
                      _light={{
                        color: "#464646",
                      }}
                      _dark={{
                        color: "white",
                      }}
                    >
                      {value}
                    </Text>
                    {arrow}
                  </HStack>
                ) : type === "change" ? (
                  <Text
                    textTransform={"uppercase"}
                    numberOfLines={2}
                    color="#682481"
                    fontSize={"8px"}
                    style={{
                      fontFamily: "Hero_400Regular",
                    }}
                  >
                    {type}
                  </Text>
                ) : (
                  <HStack
                    alignItems="center"
                    justifyContent="space-between"
                    px={2}
                    py={3}
                    _light={{
                      borderColor: "#E9B9FF",
                    }}
                    _dark={{
                      borderColor: "#58595B",
                    }}
                    _web={{
                      height: "40px",
                    }}
                    height="70px"
                    borderWidth={1}
                    borderRadius="8px"
                  >
                    <VStack>
                      <Text
                        noOfLines={2}
                        _dark={{
                          color: "#fff",
                        }}
                        _light={{
                          color: "#464646",
                        }}
                        fontSize={14}
                        lineHeight={21}
                        style={{
                          fontFamily: "Inter_700Bold",
                        }}
                      >
                        {value}
                      </Text>
                      {Platform.OS !== "web" && (
                        <Text
                          _dark={{
                            color: "#58595B",
                          }}
                          _light={{
                            color: "#464646",
                          }}
                          fontSize={14}
                          lineHeight={21}
                          style={{
                            fontFamily: "Inter_400Regular",
                          }}
                        >
                          {label}
                        </Text>
                      )}
                    </VStack>
                    <ChevronLeftArrowIcon />
                  </HStack>
                )}
              </Pressable>
            );
          }}
        >
          {items.map((item, index) => (
            <Menu.Item
              key={index}
              _text={{
                _dark: {
                  color: "white",
                },
                _light: {
                  color: "#464646",
                },
              }}
              value={item}
              onPress={() => {
                onSelect(index, item);
              }}
            >
              {item}
            </Menu.Item>
          ))}
        </Menu>
      ) : null}
    </>
  );
};

export default FullWidthAccordion;
