import { Center, HStack } from "native-base";
import { Dimensions } from "react-native";
import { useDispatch } from "react-redux";
import { goBackDialog } from "../../redux/currentState/actions";
import { setAnalytics } from "../../analytics";
import * as RootNavigation from "./../../RootNavigation";
import { resetFilters } from "../../redux/filter/actions";
import PrimaryCTAButton from "../buttons/primaryCTAButton";
import CTAButtonText from "../texts/ctaButtonText";
import GrayBorderButton from "../buttons/grayBorderButton";
const FilterPopUpBottomBar = ({ onPressApplyFilter, eventProperties }) => {
  const dispatch = useDispatch();
  const route = RootNavigation.navigationRef.getCurrentRoute();
  const onApply = () => {
    setAnalytics("Filter Apply Button", "Filter Applied On HomeScreen", {
      screen: route?.name,
      ...eventProperties,
    });
    onPressApplyFilter();
    dispatch({
      type: "SET_APPLY_FILTER",
    });
    dispatch(goBackDialog());
  };

  const onReset = () => {
    setAnalytics("Filter Reset Button", "Filter Reset On HomeScreen", {
      screen: route?.name,
      ...eventProperties,
    });
    dispatch(resetFilters());
    dispatch({
      type: "SET_APPLY_FILTER",
    });
    dispatch(goBackDialog());
  };
  return (
    <HStack
      bg={"#2B2F33"}
      justifyContent="center"
      space={2}
      height={Dimensions.get("window").height / 10}
      safeAreaBottom
      alignItems="center"
      px={2}
      position={"absolute"}
      bottom={0}
      top={"auto"}
      width={"100%"}
    >
      <Center w="48%" h="50px">
        <GrayBorderButton text="Reset" onPress={onReset} />
      </Center>
      <Center h="50px" w="48%">
        <PrimaryCTAButton onPress={onApply} gradientType="1">
          <CTAButtonText text="Apply" />
        </PrimaryCTAButton>
      </Center>
    </HStack>
  );
};

export default FilterPopUpBottomBar;
