import { HStack, Text, Pressable } from "native-base";
import FlockAvatar from "./../../assets/images/svgs/flockAvatar.svg";
import { useSelector } from "react-redux";
import { Pages } from "../../constants/pages";
import { setAnalytics } from "../../analytics";
import { useNavigation } from "@react-navigation/native";

const QuizNotificationCard = ({ notification }) => {
  const userId = useSelector((state) => state.user.id);

  const navigation = useNavigation();

  return (
    <>
      <Pressable
        onPress={async () => {
          setAnalytics("In app notification", "In app notification clicked", {
            id: notification?.item_id,
            seen: notification?.seen,
            type: notification?.type,
          });
          if ((userId !== null) & (userId !== undefined)) {
            navigation.navigate(Pages.LetsRoll);
          }
        }}
      >
        <HStack
          space={3}
          px={2}
          py={4}
          bg={notification?.seen ? "black" : "#202427"}
        >
          <FlockAvatar />
          <Text
            flex={1}
            color={"white"}
            opacity={notification?.seen ? 0.6 : 1}
            fontSize={"14px"}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
          >
            {notification?.message}
          </Text>
        </HStack>
      </Pressable>
    </>
  );
};

export default QuizNotificationCard;
