import { Image } from "native-base";

const EyesAnimatedIcon = ({ size }) => {
  return (
    <Image
      size={size}
      resizeMode="contain"
      source={require("./../../../assets/images/gifs/eyes.gif")}
      alt=""
    />
  );
};

export default EyesAnimatedIcon;
