import { useDispatch } from "react-redux";
import { storeForm } from "../../redux/form/actions";
import {
  Center,
  Input,
  ScrollView,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import { Dimensions } from "react-native";
import { useSendForgetPasswordOtp } from "../../hooks/useSendForgetPasswordOtp";
import PrimaryCTAButton from "../../components/buttons/primaryCTAButton";
import CTAButtonText from "../../components/texts/ctaButtonText";
import Error from "../../components/error";

const ForgetPasswordScreen = () => {
  const dispatch = useDispatch();
  const handleInput = (name) => (val) => {
    dispatch(storeForm({ [name]: val, error: null }));
  };
  const cursorColor = useColorModeValue("black", "white");
  const { sendForgetPasswordOtp, error } = useSendForgetPasswordOtp();
  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
      >
        <VStack safeAreaTop space={Dimensions.get("window").height / 30}>
          <VStack
            w="full"
            space={Dimensions.get("window").height / 20}
            px={"28px"}
          >
            <VStack space={"16px"} w="full">
              <VStack space={"10px"} w="full">
                <Text
                  color={"#94A3B8"}
                  fontSize={"16px"}
                  letterSpacing={"sm"}
                  style={{
                    fontFamily: "HK_500Medium",
                  }}
                >
                  Enter Email Id
                </Text>
                <Input
                  _input={{
                    selectionColor: cursorColor,
                  }}
                  focusOutlineColor="#58595B"
                  onChangeText={(val) => handleInput("email")(val)}
                  fontSize={"16px"}
                  lineHeight={"21px"}
                  autoCapitalize="none"
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                  placeholder="name@email.com"
                  placeholderTextColor={"#58595B"}
                  borderWidth={"1px"}
                  borderColor={"#58595B"}
                  p={"16px"}
                  rounded={"8px"}
                  color="white"
                  _focus={{
                    backgroundColor: "#111315",
                    color: "white",
                  }}
                ></Input>
                {error && <Error error={error} />}
              </VStack>
            </VStack>
            <Center>
              <Center h="50px" w="173.35px">
                <PrimaryCTAButton
                  onPress={sendForgetPasswordOtp}
                  gradientType="2"
                >
                  <CTAButtonText text="Send Code" />
                </PrimaryCTAButton>
              </Center>
            </Center>
          </VStack>
        </VStack>
      </ScrollView>
    </>
  );
};

export default ForgetPasswordScreen;
