import { privateApi, publicApi } from "../util/API";
import { Pages } from "../constants/pages";
import { useDispatch, useSelector } from "react-redux";
import {
  closeDialog,
  hideLoader,
  showLoader,
} from "../redux/currentState/actions";
import { setAnalytics } from "../analytics";
import { Keyboard } from "react-native";
import { storeFormError } from "../redux/form/actions";
import { getPushToken, setUser } from "../util/utilFunctions";
import { recievedUser } from "../redux/user/actions";

import * as RootNavigation from "../RootNavigation";
import { useState } from "react";

export const useEmailLogin = () => {
  const [error, setError] = useState();
  const route = RootNavigation.navigationRef.getCurrentRoute();
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const handleEmailLogin = async () => {
    Keyboard.dismiss();
    if (!form?.email && !form?.password) {
      setError("Fill credentials");
      return;
    } else if (!form?.email) {
      setError("Fill username or email address");
      return;
    } else if (!form?.password) {
      setError("Fill password");
      return;
    }

    if (
      form?.password &&
      form?.email &&
      !/\s/g.test(form?.email) &&
      !/\s/g.test(form?.password)
    ) {
      dispatch(showLoader());
      try {
        let res = await publicApi(
          "/api/users/login/",
          {
            username: form?.email,
            password: form?.password,
            login_type: "6",
          },
          "post"
        );

        if (res.body.status !== 400 && res.status === 200) {
          let res2 = await publicApi(
            "/api/users/get_user/",
            {
              id: JSON.parse(res.body.user)?.user_id,
              login_type: "6",
            },
            "get"
          );
          console.log("/api/users/get_user/", res2);
          setUser(
            JSON.parse(res2.body.id)[0],
            JSON.parse(res.body.token),
            JSON.parse(res.body.user)?.username,
            JSON.parse(res.body.auth_token)
          );
          dispatch(recievedUser({ id: JSON.parse(res2.body.id)[0] }));
          const push_token = await getPushToken();
          if (push_token !== null && push_token !== undefined) {
            const res3 = await privateApi(
              "/api/users/push_token/",
              {
                token: push_token,
                date: "23135134",
                active: true,
                user: JSON.parse(res2.body.id)[0],
              },
              "put"
            );
          }
          let stringUserid = JSON.parse(res2.body.id)[0];

          setAnalytics("Login Button", "Logged In Via Email", {
            screen: route?.name,
          });
          dispatch(closeDialog());
          RootNavigation.navigate(Pages.Home);
        } else {
          setError(res.body.message);
          dispatch(storeFormError(res.body.message));
        }
      } catch (error) {
        setError("Something went wrong!");
        console.log("errorcatch", error);
        dispatch(storeFormError("Something went wrong!"));
      }
      dispatch(hideLoader());
    }
  };

  return { handleEmailLogin, error, setError };
};
