import {
  Center,
  FlatList,
  HStack,
  Pressable,
  Text,
  View,
  VStack,
} from "native-base";
import PillGradientBorderButton from "../../components/buttons/pillGradientBorderButton";
import { Pages } from "../../constants/pages";
import CartProductCard from "../../components/cards/cart/cartProductCard";
import { useNavigation, useRoute } from "@react-navigation/native";
import { gradientTextWeb } from "../../constants/gradient";
import { useClearCart } from "../../hooks/useClearCart";
const CartProducts = ({ product, cart }) => {
  const route = useRoute();
  const navigation = useNavigation();
  const clearCart = useClearCart();
  return (
    <VStack space={8}>
      <HStack
        px={4}
        h="56px"
        borderRadius={"10px"}
        bg="#FAF1FF"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Text
          letterSpacing={2}
          textTransform={"uppercase"}
          color="#682481"
          fontSize={"18px"}
          style={{
            fontFamily: "Hero_700Bold",
          }}
        >
          Product
        </Text>
        <HStack alignItems="center" space={"60px"}>
          {[
            "Price",
            route?.params?.type === "single" ? null : "Quantity",
            route?.params?.type === "single" ? null : "Subtotal",
          ]
            ?.filter((f) => f)
            ?.map((k) => (
              <Text
                letterSpacing={2}
                textTransform={"uppercase"}
                color="#682481"
                fontSize={"18px"}
                style={{
                  fontFamily: "Hero_700Bold",
                }}
              >
                {k}
              </Text>
            ))}
        </HStack>
      </HStack>

      <VStack space={10}>
        {route?.params?.type === "single" ? (
          <CartProductCard
            item={product?.skus[route?.params?.sku_id]}
            variants={[
              product?.skus[route?.params?.sku_id]?.color
                ? {
                    label: "Color",
                    variant: product?.skus[route?.params?.sku_id]?.color,
                  }
                : null,
              product?.skus[route?.params?.sku_id]?.size
                ? {
                    label: "Size",
                    variant: product?.skus[route?.params?.sku_id]?.size,
                  }
                : null,
              product?.skus[route?.params?.sku_id]?.scent
                ? {
                    label: "Scent",
                    variant: product?.skus[route?.params?.sku_id]?.scent,
                  }
                : null,
              product?.skus[route?.params?.sku_id]?.type
                ? {
                    label: "Type",
                    variant: product?.skus[route?.params?.sku_id]?.type,
                  }
                : null,
            ]}
          />
        ) : (
          <FlatList
            showsVerticalScrollIndicator={false}
            ItemSeparatorComponent={() => (
              <View style={{ paddingVertical: 6 }}></View>
            )}
            contentContainerStyle={{
              paddingBottom: 30,
              height: 400,
            }}
            data={cart?.filter((k) => k.product_sku !== null)}
            initialNumToRender={5}
            ListEmptyComponent={
              <Center h="full">
                <Text
                  textAlign={"center"}
                  letterSpacing={"2"}
                  textTransform={"uppercase"}
                  _light={{
                    color: "#682481",
                  }}
                  _dark={{
                    color: "#94A3B8",
                  }}
                  fontSize={"18px"}
                  style={{
                    fontFamily: "Hero_700Bold",
                  }}
                >
                  Cart is empty
                </Text>
              </Center>
            }
            renderItem={({ item }) => (
              <>
                {item?.product_sku !== null && (
                  <CartProductCard
                    item={item}
                    variants={[
                      item?.product_sku?.color
                        ? {
                            label: "Color",
                            variant: item?.product_sku?.color,
                          }
                        : null,
                      item?.product_sku?.size
                        ? {
                            label: "Size",
                            variant: item?.product_sku?.size,
                          }
                        : null,
                      item?.product_sku?.scent
                        ? {
                            label: "Scent",
                            variant: item?.product_sku?.scent,
                          }
                        : null,
                      item?.product_sku?.type
                        ? {
                            label: "Type",
                            variant: item?.product_sku?.type,
                          }
                        : null,
                    ]}
                  />
                )}
              </>
            )}
          />
        )}
        <HStack alignItems={"center"} justifyContent="space-between">
          <Pressable onPress={clearCart}>
            <Text
              letterSpacing={"2"}
              textTransform={"uppercase"}
              _web={{
                style: {
                  ...gradientTextWeb(1),
                  fontFamily: "Hero_700Bold",
                },
              }}
              fontSize={"18px"}
            >
              Clear cart
            </Text>
          </Pressable>
          <Center w="300px" h="60px">
            <PillGradientBorderButton
              onPress={() => navigation.navigate(Pages.ShopScreen)}
              gradientType="2"
            >
              <Text
                letterSpacing={"2"}
                textTransform={"uppercase"}
                _web={{
                  style: {
                    ...gradientTextWeb(1),
                    fontFamily: "Hero_700Bold",
                  },
                }}
                fontSize={"18px"}
              >
                Continue Shopping
              </Text>
            </PillGradientBorderButton>
          </Center>
        </HStack>
      </VStack>
    </VStack>
  );
};

export default CartProducts;
