import { Center, HStack, Image, Pressable, Text, VStack } from "native-base";
import { Video } from "expo-av";
import promo from "./../../assets/mp4/promo.mp4";
import { useRef, useState } from "react";
import PlayIcon from "../icons/filledIcons/playIcon";
import FollowUserIconOutlined from "../icons/followUserIconOutlined";

import ShareIcon from "../icons/shareIcon";
import WishListIcon from "../icons/wishListIcon";

const Posts = (props) => {
  const [play, setPlay] = useState(false);

  const [status, setStatus] = useState({});
  const video = useRef(null);
  return (
    <>
      <VStack alignItems={"center"} space="15px">
        {/* <Image
          bg="white"
          borderRadius={"20px"}
          h={{
            md: "300px",
            lg: "310px",
            xl: "400px",
          }}
          w={{
            md: "300px",
            lg: "310px",
            xl: "392px",
          }}
          resizeMethod="scale"
          resizeMode="contain"
          source={{
            uri: "https://resized-c-im.s3.amazonaws.com/test2/92977dacd0cc791fbcfab227faed943c/066d9f04180d5087f84818f2b323cc07/0.jpg",
          }}
          alt=""
        /> */}
        <Pressable>
          <Center
            borderRadius={"20px"}
            h={{
              md: "300px",
              lg: "310px",
              xl: "400px",
            }}
            w={{
              md: "300px",
              lg: "310px",
              xl: "392px",
            }}
          >
            <Video
              shouldPlay={play}
              posterSource={{
                uri: "https://resized-c-im.s3.amazonaws.com/test2/92977dacd0cc791fbcfab227faed943c/066d9f04180d5087f84818f2b323cc07/0.jpg",
              }}
              posterStyle={{
                height: "100%",
                width: "100%",
                overflow: "hidden",
                borderRadius: 20,
                resizeMode: "cover",
              }}
              usePoster={true}
              isLooping={false}
              isMuted={true}
              style={{
                height: "100%",
                width: "100%",
                overflow: "hidden",
                borderRadius: 20,
              }}
              ref={video}
              source={promo}
              resizeMode="cover"
              onPlaybackStatusUpdate={(status) => setStatus(() => status)}
            />
            <Center position={"absolute"}>
              <Pressable
                onPress={() =>
                  status.isPlaying
                    ? video.current.pauseAsync()
                    : video.current.playAsync()
                }
              >
                <PlayIcon />
              </Pressable>
            </Center>
          </Center>
        </Pressable>
        <VStack w="full" justifyContent={"center"} px={"20px"} space={4}>
          <HStack space={6}>
            <WishListIcon postId={props?.postId} type="outlined" />
            <ShareIcon
              type={"arrowShareIcon"}
              path={`post/${props?.postId}`}
              analytics={{
                category: "Share Icon",
                event: `Shared Post`,
                eventProperties: {
                  postId: props?.postId,

                  // screen: route?.name,
                },
              }}
            />
          </HStack>
          <HStack alignItems={"center"} justifyContent="space-between">
            <HStack alignItems={"center"} space={2}>
              <Image
                bg="white"
                borderRadius={"20px"}
                size={"20px"}
                resizeMethod="scale"
                resizeMode="contain"
                source={{
                  uri: "https://resized-c-im.s3.amazonaws.com/test2/92977dacd0cc791fbcfab227faed943c/066d9f04180d5087f84818f2b323cc07/1.jpg",
                }}
                alt=""
              />
              <Text
                textAlign={"center"}
                fontSize={"16px"}
                color="white"
                style={{
                  fontFamily: "HK_600SemiBold",
                }}
              >
                Mineral
              </Text>
              <Text
                textAlign={"center"}
                fontSize={"12px"}
                color="#94A3B8"
                style={{
                  fontFamily: "HK_500Medium",
                }}
              >
                @Mineral
              </Text>
            </HStack>
            <Pressable onPress={() => onFollow(review?.id)}>
              <HStack alignItems={"center"} space={1}>
                {true && <FollowUserIconOutlined />}
                <Text
                  color="#94A3B8"
                  fontSize={14}
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                >
                  {false ? "Following" : "Follow"}
                </Text>
              </HStack>
            </Pressable>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default Posts;
