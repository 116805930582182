import { VStack, FlatList, View } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import SearchBox from "../filterPopUp/buildingBlocks/searchBox";
import CheckBox from "../filterPopUp/buildingBlocks/checkbox";
import { HairTextures } from "../../../constants/hairTextures";
import { changeFilter } from "../../../redux/filter/actions";

const Item = ({ item, selectedHairTexture, setSelectedHairTexture }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selected) {
      if (selectedHairTexture.includes(item)) {
        setSelected(true);
      }
    }
  }, [selectedHairTexture]);

  const onPress = () => {
    if (selectedHairTexture.includes(item)) {
      setSelected(false);
      const filtered = selectedHairTexture.filter((value) => value !== item);
      setSelectedHairTexture(filtered);
    } else {
      setSelected(true);
      setSelectedHairTexture([...selectedHairTexture, item]);
    }
  };

  return (
    <CheckBox
      onPress={onPress}
      checked={selected}
      label={`${HairTextures[item]} Hair`}
      item={item}
    />
  );
};

const HairTexture = ({ selectedHairTexture, setSelectedHairTexture }) => {
  const [hairTypeResults, setHairTypeResults] = useState(
    Object.keys(HairTextures)
  );

  const renderItem = ({ item }) => (
    <Item
      item={item}
      selectedHairTexture={selectedHairTexture}
      setSelectedHairTexture={setSelectedHairTexture}
    />
  );

  return (
    <VStack width={"100%"} pb={8} px={4} space={6}>
      <SearchBox
        onChange={async (val) => {
          let hairTypes = Object.keys(HairTextures);
          let hairTypeResult = hairTypes.filter((hairType) => {
            return HairTextures[hairType]
              .toLowerCase()
              .includes(val.toLowerCase());
          });
          setHairTypeResults(hairTypeResult);
        }}
      />

      <FlatList
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 11 }}></View>
        )}
        contentContainerStyle={{
          paddingBottom: 12,
        }}
        data={hairTypeResults}
        initialNumToRender={12}
        renderItem={renderItem}
      />
    </VStack>
  );
};

export default HairTexture;
