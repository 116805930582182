export const Ethnicities = {
  1: "Native American",
  2: "Black",
  3: "Latinx/Hispanic",
  4: "Middle Eastern",
  5: "Asian",
  6: "Pacific Islander",
  7: "South Asian",
  8: "Southeast Asian",
  9: "White",
};
