import { Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";

const Price = () => {
  const dispatch = useDispatch();
  const low = useSelector((state) => state.filter.cachedFilters.priceRangeFrom);
  const high = useSelector((state) => state.filter.cachedFilters.priceRangeTo);

  const changeValues = (values) => {
    dispatch({
      type: "SET_PRICE",
      low: values[0],
      high: values[1],
    });
  };

  return (
    <VStack space={4} width="full" px={4}>
      <VStack>
        <Text
          color="#94A3B8"
          style={{
            fontFamily: "HK_400Regular",
          }}
          fontSize="14px"
        >
          Drag the Slider to filter by Price range:
        </Text>
      </VStack>
    </VStack>
  );
};

export default Price;
