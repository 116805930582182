// import {
//   Center,
//   HStack,
//   IconButton,
//   Image,
//   Input,
//   Pressable,
//   ScrollView,
//   Text,
//   VStack,
// } from "native-base";
// import { useDispatch, useSelector } from "react-redux";
// import ResetIcon from "../../components/icons/resetIcon";
// import { useGetKeyboardStatus } from "../../hooks/useGetKeyboardStatus";
// import { storeForm } from "../../redux/form/actions";
// import { useUploadImage } from "../../hooks/useUploadImage";
// import { useState } from "react";
// import { useSignup } from "../../hooks/useSignup";
// import Error from "../../components/error";
// import PrimaryCTAButton from "../../components/buttons/primaryCTAButton";
// import CTAButtonText from "../../components/texts/ctaButtonText";

// const UploadPictureScreen = () => {
//   const keyboardStatus = useGetKeyboardStatus();
//   const form = useSelector((state) => state.form);
//   const dispatch = useDispatch();
//   const [nextButtonPressed, setNextButtonPressed] = useState();
//   const [pickImage, image, setImage] = useUploadImage();
//   const handleInput = (name) => (val) => {
//     setNextButtonPressed();
//     dispatch(storeForm({ [name]: val, error: null }));
//   };
//   const { signup, error: signupError } = useSignup();
//   return (
//     <ScrollView
//       h="full"
//       showsVerticalScrollIndicator={false}
//       keyboardShouldPersistTaps="handled"
//     >
//       <VStack
//         pb={
//           keyboardStatus === "Keyboard Hidden" || keyboardStatus === undefined
//             ? 0
//             : 96
//         }
//         alignItems={"center"}
//         px={{
//           md: "10px",
//           lg: "28px",
//           xl: "28px",
//         }}
//         h="full"
//         space={6}
//         justifyContent={"center"}
//       >
//         <Text
//           textAlign={"center"}
//           color={"white"}
//           fontSize={"30px"}
//           style={{
//             fontFamily: "Poppins_500Medium",
//           }}
//         >
//           Wait a moment.. What do we call you?
//         </Text>
//         {[
//           {
//             label: "First name",
//             onChangeText: (val) => handleInput("first_name")(val),
//           },
//           {
//             label: "Last name",
//             onChangeText: (val) => handleInput("last_name")(val),
//           },
//         ].map((k, i) => (
//           <VStack space={"10px"} w="full">
//             <Text
//               color={"#94A3B8"}
//               fontSize={"16px"}
//               letterSpacing={"sm"}
//               style={{
//                 fontFamily: "HK_500Medium",
//               }}
//             >
//               {k?.label}
//             </Text>
//             <Input
//               onChangeText={k?.onChangeText}
//               fontSize={"16px"}
//               lineHeight={"21px"}
//               autoCapitalize="none"
//               style={{
//                 fontFamily: "HK_700Bold",
//               }}
//               placeholderTextColor={"white"}
//               borderWidth={"1px"}
//               borderColor={"#58595B"}
//               p={"16px"}
//               rounded={"8px"}
//               color="white"
//               _focus={{
//                 borderColor: "#58595B",
//                 backgroundColor: "black",
//                 color: "white",
//               }}
//             ></Input>
//           </VStack>
//         ))}
//         {signupError && <Error error={signupError} />}
//         {(!form?.first_name || !form?.last_name) && nextButtonPressed && (
//           <Error error="Fill first name and last name." />
//         )}
//         <VStack alignItems={"center"} space={2} w="full">
//           <HStack w="full" alignItems={"center"} justifyContent="space-between">
//             <Text
//               color={"#94A3B8"}
//               fontSize={"16px"}
//               style={{
//                 fontFamily: "HK_500Medium",
//               }}
//             >
//               Upload your Profile Picture (Optional)
//             </Text>
//             <IconButton
//               onPress={() => {
//                 setImage(null);
//               }}
//               icon={<ResetIcon />}
//             ></IconButton>
//           </HStack>
//           {image ? (
//             <Image
//               alt=""
//               size={"150px"}
//               rounded={"10px"}
//               source={{ uri: image }}
//             />
//           ) : (
//             <Pressable onPress={() => pickImage()}>
//               <Center w="150px" h="150px" bg="#2B2F33" rounded={"20px"}>
//                 <Text
//                   color={"#1A94FF"}
//                   fontSize={"15px"}
//                   style={{
//                     fontFamily: "HK_600SemiBold",
//                   }}
//                 >
//                   Upload
//                 </Text>
//               </Center>
//             </Pressable>
//           )}
//         </VStack>
//         <Center>
//           <Center h="50px" w="173.35px">
//             <PrimaryCTAButton
//               onPress={() => {
//                 setNextButtonPressed(true);
//                 if (form?.first_name && form?.last_name) {
//                   signup();
//                 }
//               }}
//               gradientType="2"
//             >
//               <CTAButtonText text="Next" />
//             </PrimaryCTAButton>
//           </Center>
//         </Center>
//       </VStack>
//     </ScrollView>
//   );
// };

// export default UploadPictureScreen;

import { ScrollView } from "native-base";
import SignupHOC from "../../hoc/SignupHOC";
import SignupForm from "../../components/forms/signupForm";

const UploadPictureScreen = () => {
  return (
    <ScrollView
      h="full"
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps="handled"
    >
      <SignupHOC>
        <SignupForm />
      </SignupHOC>
    </ScrollView>
  );
};

export default UploadPictureScreen;
