import { Center, Divider, Hidden, HStack, Text, VStack } from "native-base";
import { useContext } from "react";
import { setAnalytics } from "../../analytics";
import AddPaymentDetailsGhostButton from "../../components/buttons/addPaymentDetailsGhostButton";
import AddShippingAddressGhostButton from "../../components/buttons/addShippingAddressGhostButton";
import PillGradientButton from "../../components/buttons/pillGradientButton";
import FlockBucksRedeemCard from "../../components/cards/flockBucksRedeemCard";
import PaymentCard from "../../components/cards/paymentCard";
import ShippingAddressCard from "../../components/cards/shippingAddressCard";
import PromoCodeBox from "../../components/promoCodeBox";
import BillTable from "../../components/tables/billTable";
import { CheckoutContext } from "../../hoc/CheckoutHOC";
const CartTotal = () => {
  const {
    isDetailsFilled,
    onCheckout,
    price,
    shippingAddress,
    paymentCard,
    applyOtherDiscounts,
  } = useContext(CheckoutContext);
  return (
    <>
      <Center bg="#FAF1FF" h="56px" borderTopRadius={"10px"} w="full">
        <Text
          textAlign={"center"}
          letterSpacing={2}
          textTransform={"uppercase"}
          color="#682481"
          fontSize={"18px"}
          style={{
            fontFamily: "Hero_700Bold",
          }}
        >
          Cart Total
        </Text>
      </Center>
      <VStack
        alignItems={"center"}
        p={{
          base: 2,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 8,
        }}
        space={"20px"}
      >
        <HStack justifyContent={"space-between"} w="full">
          <Text
            textAlign={"center"}
            letterSpacing={2}
            color="#232323"
            fontSize={"20px"}
            style={{
              fontFamily: "Hero_400Regular",
            }}
          >
            Subtotal
          </Text>
          <Text
            textAlign={"center"}
            letterSpacing={2}
            textTransform={"uppercase"}
            color="#682481"
            fontSize={"24px"}
            style={{
              fontFamily: "Hero_700Bold",
            }}
          >
            ${price}
          </Text>
        </HStack>

        {applyOtherDiscounts && (
          <>
            <Divider bg="#FAF1FF" w="full" />
            <PromoCodeBox />
            <Divider bg="#FAF1FF" w="full" />
          </>
        )}
        {shippingAddress ? (
          <ShippingAddressCard address={shippingAddress} />
        ) : (
          <AddShippingAddressGhostButton />
        )}
        {paymentCard ? (
          <PaymentCard card={paymentCard} />
        ) : (
          <AddPaymentDetailsGhostButton />
        )}
        <FlockBucksRedeemCard />
        <Divider bg="#FAF1FF" w="full" />
        <Center w="full">
          <BillTable />
        </Center>
        <Hidden from={"base"} till="xl">
          <Center w="241px" h="60px">
            <PillGradientButton
              gradientType="2"
              onPress={() => {
                if (isDetailsFilled) {
                  console.log("clicked");
                  onCheckout();
                } else {
                  setAnalytics(
                    "Confirm Button",
                    "Fill Details Pop-up opened for address and payment",
                    route?.params?.type === "single"
                      ? singlePurchaseEvents
                      : multiplePurchaseEvents
                  );
                }
              }}
            >
              <Text
                letterSpacing={"2"}
                textTransform={"uppercase"}
                color="white"
                fontSize={"18px"}
                style={{
                  fontFamily: "Hero_700Bold",
                }}
              >
                Checkout
              </Text>
            </PillGradientButton>
          </Center>
        </Hidden>
      </VStack>
    </>
  );
};

export default CartTotal;
