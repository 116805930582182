import { Center, HStack, Text, VStack } from "native-base";
import { useState } from "react";
import GradientBorderButton from "../../buttons/gradientBorderButton";
import WebPhoneIcon from "../../icons/webIcons/webPhoneIcon";
import EmailLoginHOC from "../../../hoc/EmailLoginHOC";
import EmailLoginForm from "../../forms/emailLoginForm";
import PhoneLoginHOC from "../../../hoc/PhoneLoginHOC";
import PhoneLoginForm from "../../forms/phoneLoginForm";

const LoginElement = () => {
  const [loginType, setLoginType] = useState("email");

  return (
    <VStack space={"20px"}>
      {loginType === "email" ? (
        <EmailLoginHOC>
          <EmailLoginForm />
        </EmailLoginHOC>
      ) : (
        <PhoneLoginHOC>
          <PhoneLoginForm />
        </PhoneLoginHOC>
      )}

      <Center h="36px">
        <GradientBorderButton
          gradientType="2"
          bg="white"
          onPress={() => {
            if (loginType === "email") {
              setLoginType("phone");
            } else {
              setLoginType("email");
            }
          }}
        >
          <HStack
            justifyContent={"center"}
            space={loginType === "email" ? 2 : 0}
          >
            {loginType === "email" && <WebPhoneIcon />}
            <Text
              letterSpacing={2}
              textTransform={"uppercase"}
              fontSize={"11px"}
              style={{
                fontFamily: "Hero_700Bold",
              }}
              color="#682481"
            >
              {loginType === "email" ? "Login with an OTP" : "Login with Email"}
            </Text>
          </HStack>
        </GradientBorderButton>
      </Center>
    </VStack>
  );
};
export default LoginElement;
