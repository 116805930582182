export const OPEN_DIALOG = "OPEN_DIALOG";
export const CLOSE_DIALOG = "CLOSE_DIALOG";
export const SET_DIALOG = "SET_DIALOG";
export const SET_ACTIVE_CARD = "SET_ACTIVE_CARD";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const SET_LOGGED_OUT = "SET_LOGGED_OUT";
export const OPEN_POPUP_AFTER_LOGIN = "OPEN_POPUP_AFTER_LOGIN";
export const SET_TYPE_OF_ADDRESS = "SET_TYPE_OF_ADDRESS";
export const GO_BACK_DIALOG = "GO_BACK_DIALOG";
export const SHARE_LINK = "SHARE_LINK";
export const SET_ERROR = "SET_ERROR";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const SET_BOTTOM_ICON = "SET_BOTTOM_ICON";
export const SET_PRODUCT_ORDER = "SET_PRODUCT_ORDER";
export const SET_PAGE = "SET_PAGE";
export const SET_FILTER_PAGE = "SET_FILTER_PAGE";
export const LOADED_PAGE = "LOADED_PAGE";
export const SET_APPLY_FILTER = "SET_APPLY_FILTER";
export const SET_APPLY_PRODUCTS_FILTER = "SET_APPLY_PRODUCTS_FILTER";
export const SET_REFRESH = "SET_REFRESH";
export const UNSET_REFRESH = "UNSET_REFRESH";
export const SET_NOTIFICATION_LENGTH = "SET_NOTIFICATION_LENGTH";
export const HIDE_NETWORK = "HIDE_NETWORK";
export const SHOW_NETWORK = "SHOW_NETWORK";
export const REFERRAL_ID = "REFERRAL_ID";
