import { Icon } from "native-base";
import { Path, G, LinearGradient, Stop, Defs } from "react-native-svg";

const GradientInstaIcon = () => {
  return (
    <Icon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G fill="none">
        <Path
          d="M5.88203 0.902344H14.282C17.482 0.902344 20.082 3.50234 20.082 6.70234V15.1023C20.082 16.6406 19.471 18.1159 18.3832 19.2036C17.2955 20.2913 15.8203 20.9023 14.282 20.9023H5.88203C2.68203 20.9023 0.0820312 18.3023 0.0820312 15.1023V6.70234C0.0820313 5.16409 0.693101 3.68884 1.78081 2.60112C2.86852 1.51341 4.34378 0.902344 5.88203 0.902344ZM5.68203 2.90234C4.72725 2.90234 3.81158 3.28163 3.13645 3.95676C2.46132 4.63189 2.08203 5.54756 2.08203 6.50234V15.3023C2.08203 17.2923 3.69203 18.9023 5.68203 18.9023H14.482C15.4368 18.9023 16.3525 18.5231 17.0276 17.8479C17.7027 17.1728 18.082 16.2571 18.082 15.3023V6.50234C18.082 4.51234 16.472 2.90234 14.482 2.90234H5.68203ZM15.332 4.40234C15.6636 4.40234 15.9815 4.53404 16.2159 4.76846C16.4503 5.00288 16.582 5.32082 16.582 5.65234C16.582 5.98386 16.4503 6.30181 16.2159 6.53623C15.9815 6.77065 15.6636 6.90234 15.332 6.90234C15.0005 6.90234 14.6826 6.77065 14.4481 6.53623C14.2137 6.30181 14.082 5.98386 14.082 5.65234C14.082 5.32082 14.2137 5.00288 14.4481 4.76846C14.6826 4.53404 15.0005 4.40234 15.332 4.40234ZM10.082 5.90234C11.4081 5.90234 12.6799 6.42913 13.6176 7.36681C14.5552 8.30449 15.082 9.57626 15.082 10.9023C15.082 12.2284 14.5552 13.5002 13.6176 14.4379C12.6799 15.3756 11.4081 15.9023 10.082 15.9023C8.75595 15.9023 7.48418 15.3756 6.5465 14.4379C5.60881 13.5002 5.08203 12.2284 5.08203 10.9023C5.08203 9.57626 5.60881 8.30449 6.5465 7.36681C7.48418 6.42913 8.75595 5.90234 10.082 5.90234ZM10.082 7.90234C9.28638 7.90234 8.52332 8.21841 7.96071 8.78102C7.3981 9.34363 7.08203 10.1067 7.08203 10.9023C7.08203 11.698 7.3981 12.4611 7.96071 13.0237C8.52332 13.5863 9.28638 13.9023 10.082 13.9023C10.8777 13.9023 11.6407 13.5863 12.2034 13.0237C12.766 12.4611 13.082 11.698 13.082 10.9023C13.082 10.1067 12.766 9.34363 12.2034 8.78102C11.6407 8.21841 10.8777 7.90234 10.082 7.90234Z"
          fill="url(#paint0_linear_45_15259)"
        />
        <Defs>
          <LinearGradient
            id="paint0_linear_45_15259"
            x1="0.0820312"
            y1="0.902344"
            x2="25.9814"
            y2="10.5089"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#691DE3" />
            <Stop offset="0.0001" stop-color="#691DE3" />
            <Stop offset="1" stop-color="#FF5A92" />
          </LinearGradient>
        </Defs>
      </G>
    </Icon>
  );
};

export default GradientInstaIcon;
