import { Box, Center, FlatList } from "native-base";
import { useContext } from "react";
import UserHorizontalCard from "../../components/cards/user/userHorizontalCard";
import { UsersContext } from "../../hoc/UsersHOC";

const CelebHorizontalStack = () => {
  const { data } = useContext(UsersContext);
  const renderItem = ({ item }) => <UserHorizontalCard item={item} />;

  return (
    <Center
      w={{
        xl: "full",
      }}
    >
      <FlatList
        contentContainerStyle={{
          justifyContent: {
            xl: "center",
          },
        }}
        horizontal
        ItemSeparatorComponent={() => <Box w="3"></Box>}
        keyboardShouldPersistTaps="handled"
        showsHorizontalScrollIndicator={false}
        initialNumToRender={2}
        data={data}
        renderItem={renderItem}
      />
    </Center>
  );
};

export default CelebHorizontalStack;
