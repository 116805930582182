import {
  Button,
  Center,
  HStack,
  Image,
  Pressable,
  Stack,
  Text,
  VStack,
} from "native-base";
import FireAnimatedIcon from "./icons/animatedIcons/fireAnimatedIcon";
import ChevronLeftArrowIcon from "./icons/chevronLeftArrowIcon";
import GradientPercentOff from "./icons/gradientPercentOff";

const FlockSuggestsProduct = () => {
  return (
    <>
      <VStack alignItems={"center"} w="full">
        <VStack alignItems={"center"} w="full">
          <HStack justifyContent={"center"} w="full">
            <Text
              lineHeight={"27.5px"}
              fontSize={"22px"}
              color="white"
              style={{
                fontFamily: "HK_700Bold",
              }}
            >
              Flock Suggests
            </Text>
            <FireAnimatedIcon />
          </HStack>
          <Text
            lineHeight={"16.25px"}
            fontSize={"13px"}
            color="#94A3B8"
            style={{
              fontFamily: "HK_500Medium",
            }}
          >
            Hand-picked product of the day!
          </Text>
          <Button
            variant={"ghost"}
            _text={{
              color: "#888990",
              fontSize: 14,
              lineHeight: 21,
              fontFamily: "HK_600SemiBold",
            }}
            endIcon={
              <Stack pl={2}>
                <ChevronLeftArrowIcon color={"#888990"} />
              </Stack>
            }
          >
            See more like this
          </Button>
        </VStack>

        <VStack alignItems={"center"} space="15px">
          <Image
            bg="white"
            borderRadius={"20px"}
            h={{
              md: "300px",
              lg: "310px",
              xl: "392px",
            }}
            w={{
              md: "300px",
              lg: "310px",
              xl: "392px",
            }}
            resizeMethod="scale"
            resizeMode="contain"
            source={{
              uri: "https://resized-c-im.s3.amazonaws.com/test2/92977dacd0cc791fbcfab227faed943c/066d9f04180d5087f84818f2b323cc07/1.jpg",
            }}
            alt=""
          />
          <Center position="absolute" left={"0"} top={"6%"}>
            <GradientPercentOff />
            <Text
              position={"absolute"}
              color="white"
              fontSize={"12px"}
              lineHeight={"15.64px"}
              style={{
                fontFamily: "HK_700Bold",
              }}
            >
              {30}%
            </Text>
          </Center>
          <Text
            textAlign={"center"}
            w="250px"
            noOfLines={2}
            lineHeight={"25px"}
            fontSize={"20px"}
            color="white"
            style={{
              fontFamily: "HK_600SemiBold",
            }}
          >
            Mineral Sunsilk Moisturizer SPF30 by Coola
          </Text>
          <Pressable>
            <HStack
              px={3}
              height="50px"
              justifyContent="space-around"
              alignItems="center"
              space={1}
              bg={{
                linearGradient: {
                  colors: [
                    "primary.ctaLinearSecondColor",
                    "primary.ctaLinearFirstColor",
                  ],
                  start: [0, 0],
                  end: [1, 0],
                },
              }}
              rounded={10}
              _text={{
                color: "#ffffff",
              }}
            >
              <Text
                letterSpacing={3}
                textTransform={"uppercase"}
                color="white"
                fontSize={10}
                style={{
                  fontFamily: "HK_700Bold",
                }}
              >
                Buy Now
              </Text>
              <Text
                strikeThrough
                letterSpacing={0}
                color="white"
                style={{
                  fontFamily: "HK_500Medium",
                }}
                fontSize={"16px"}
              >
                ${30}
              </Text>
              <Text
                letterSpacing={0}
                color="white"
                style={{
                  fontFamily: "HK_700Bold",
                }}
                fontSize={"16px"}
              >
                ${20}
              </Text>
            </HStack>
          </Pressable>
        </VStack>
      </VStack>
    </>
  );
};

export default FlockSuggestsProduct;
