import { useEffect, useState } from "react";

export const useCreateParams = (paramsmap) => {
  const [params, setParams] = useState();
  useEffect(() => {
    let copy = { ...params };
    Object.keys(paramsmap)
      ?.filter((f) => paramsmap[f])
      .map((k) => {
        copy = { ...copy, [k]: paramsmap[k] };
      });
    setParams(copy);
  }, []);

  return params;
};
