import { Box, HStack, Pressable, Text } from "native-base";
import { useShare } from "../../hooks/useShare";
import SendOutlinedIcon from "../icons/sendOutlinedIcon";

const InviteFriendsGradientButton = ({ groupId, screen }) => {
  const onShare = useShare();
  return (
    <Pressable
      onPress={() => {
        onShare(`group/${groupId}`, {
          category: "Invite Friends Button",
          event: `Shared Group via Invite Button`,
          eventProperties: {
            groupId: groupId,
            screen: screen,
          },
        });
      }}
    >
      <Box
        rounded={"10px"}
        bg={{
          linearGradient: {
            colors: [
              "primary.ctaLinearFirstColor",
              "primary.ctaLinearSecondColor",
            ],
            start: [0, 0],
            end: [1, 0],
          },
        }}
      >
        <HStack
          w={"165px"}
          h={"40px"}
          rounded={"10px"}
          justifyContent={"center"}
          alignItems="center"
          space={2}
        >
          <SendOutlinedIcon />
          <Text
            fontSize={16}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
            color={"#ffffff"}
          >
            Invite Friends
          </Text>
        </HStack>
      </Box>
    </Pressable>
  );
};

export default InviteFriendsGradientButton;
