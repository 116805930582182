import { useNavigation } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import { setAnalytics } from "../analytics";
import { hideLoader, showLoader } from "../redux/currentState/actions";
import { privateApi } from "../util/API";
import { getUser } from "../util/utilFunctions";
import Constants from "expo-constants";
import { useSWRConfig } from "swr";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const useDeleteCollection = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const deleteCollection = async (id) => {
    dispatch(showLoader());
    const userId = await getUser();
    if (userId !== undefined && userId !== null) {
      let res = await privateApi(
        "/api/products/collection/",
        { user_id: userId, collection_id: id },
        "delete"
      );

      setAnalytics(
        "Delete Collection",
        `Collection ${
          res?.body?.status === 200 ? "Deleted Successfully" : "Deletion Failed"
        }`,
        {
          status: res?.body?.status,
        }
      );
      if (res.status === 200) {
        dispatch(hideLoader());
        mutate(`${apiUrl}/api/users/get_user_details/?user_id=${userId}`);
        navigation.goBack();
      }
    }
    dispatch(hideLoader());
  };
  return deleteCollection;
};

export default useDeleteCollection;
