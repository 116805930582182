import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Pressable,
  SectionList,
  Text,
  useBreakpointValue,
  VStack,
} from "native-base";
import { ImageBackground } from "react-native";

import { useDispatch, useSelector } from "react-redux";
import TopBarWeb from "../../components/bars/navigationBars/topBarWeb";
import PrimaryCTAButton from "../../components/buttons/primaryCTAButton";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import { GRADIENTMAP } from "../../constants/gradient";
import { Pages } from "../../constants/pages";
import useGetUser from "../../hooks/useGetUser";
import useResetFilter from "../../hooks/useResetFilter";
import FoundersSectionWeb from "./buildingBlocks/foundersSectionWeb";
import SaveBigSectionWeb from "./buildingBlocks/saveBigSectionWeb";
import SecondSectionWeb from "./buildingBlocks/secondSectionWeb";
import ThirdSectionWeb from "./buildingBlocks/thirdSectionWeb";

const HomeScreen = ({ navigation }) => {
  useResetFilter();
  const screen = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: "xl",
  });
  const userId = useSelector((state) => state.user.id);
  const { data } = useGetUser("profile", userId);

  const components = [
    {
      header: <TopBarWeb />,
      data: [
        screen !== "xl" ? (
          <>
            <Center h={"700px"} w="full">
              <ImageBackground
                position="relative"
                resizeMode="cover"
                source={require("./../../assets/images/pngs/mobilethreewomen.png")}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",

                  borderRadius: 20,

                  overflow: "hidden",
                }}
              >
                <VStack pt="80px" alignItems={"center"} px="20px">
                  <Text
                    textAlign={"center"}
                    color={"#682481"}
                    fontSize="36px"
                    lineHeight={"45px"}
                    style={{
                      fontFamily: "AustinCyr_400",
                    }}
                  >
                    Discover personalized Beauty & Wellness products for your
                    unique Skin and Hair type!
                  </Text>
                  {!data?.user[0]?.quiz_completed && (
                    <Center w="223px" h="60px" pt="10px">
                      <PrimaryCTAButton
                        onPress={() => {
                          if (userId) {
                            navigation.navigate(Pages.AboutYourSelfScreen);
                          } else {
                            navigation.navigate("Login");
                          }
                        }}
                        gradientType="2"
                      >
                        <HStack alignItems={"center"} space={2}>
                          <Image
                            alt=""
                            size={6}
                            resizeMethod="scale"
                            source={require("./../../assets/images/gifs/flower.gif")}
                          ></Image>
                          <Text
                            letterSpacing={2}
                            textTransform={"uppercase"}
                            fontSize={"16px"}
                            style={{
                              fontFamily: "HK_600SemiBold",
                            }}
                            color="white"
                          >
                            Beauty Quiz
                          </Text>
                        </HStack>
                      </PrimaryCTAButton>
                    </Center>
                  )}
                </VStack>
              </ImageBackground>
            </Center>
          </>
        ) : (
          <Center h={"700px"} w="full">
            <ImageBackground
              position="relative"
              resizeMode="cover"
              source={require("./../../assets/images/pngs/landingPage.png")}
              alt=""
              style={{
                height: "100%",
                width: "100%",

                borderRadius: 20,

                overflow: "hidden",
              }}
            >
              <VStack pt="80px" px="20px" space={6}>
                <Text
                  w="45%"
                  color={"#682481"}
                  fontSize="48px"
                  lineHeight={"60px"}
                  _web={{
                    style: {
                      fontFamily: "AustinCyr_400",
                    },
                  }}
                >
                  Discover personalized Beauty & Wellness products for your
                  unique Skin and Hair type!
                </Text>
                {!data?.user[0]?.quiz_completed && (
                  <HStack>
                    <Pressable
                      onPress={() => {
                        if (userId) {
                          navigation.navigate(Pages.AboutYourSelfScreen);
                        } else {
                          navigation.navigate("Login");
                        }
                      }}
                    >
                      <Center
                        alignItems="center"
                        flexDirection="row"
                        justifyContent="space-around"
                        w="223px"
                        h="60px"
                        alignSelf={"center"}
                        bg={{
                          linearGradient: {
                            colors: GRADIENTMAP["2"],
                            start: [0, 0],
                            end: [1, 0],
                          },
                        }}
                        rounded="10px"
                        _text={{
                          color: "#ffffff",
                        }}
                      >
                        <HStack
                          justifyContent={"center"}
                          mb="2px"
                          bg={"white"}
                          alignItems="center"
                          flexDirection="row"
                          space="2"
                          w="full"
                          h="full"
                          alignSelf={"center"}
                          rounded="10px"
                          _text={{
                            color: "#ffffff",
                          }}
                        >
                          <Image
                            alt=""
                            size={6}
                            resizeMethod="scale"
                            source={require("./../../assets/images/gifs/gradientFlower.gif")}
                          ></Image>
                          <Text
                            letterSpacing={2}
                            textTransform={"uppercase"}
                            fontSize={"16px"}
                            style={{
                              fontFamily: "HK_600SemiBold",
                            }}
                            color={"#682481"}
                          >
                            Beauty Quiz
                          </Text>
                        </HStack>
                      </Center>
                    </Pressable>
                  </HStack>
                )}
              </VStack>
            </ImageBackground>
          </Center>
        ),
      ],
    },
    {
      data: [
        <SecondSectionWeb quizCompleted={data?.user[0]?.quiz_completed} />,
        <ThirdSectionWeb />,

        <FooterSectionWeb />,
      ],
    },
  ];

  return (
    <>
      <SectionList
        ItemSeparatorComponent={() => <Box h="80px"></Box>}
        keyboardShouldPersistTaps="handled"
        sections={components}
        showsVerticalScrollIndicator={false}
        stickySectionHeadersEnabled
        renderSectionHeader={(item) => <>{item.section?.header}</>}
        renderItem={({ item }) => item}
      />
    </>
  );
};

export default HomeScreen;
