import { VStack, FlatList, View } from "native-base";
import { useEffect, useState } from "react";
import CheckBox from "./checkbox";

const Sort = ({ selectedPriceSort, setSelectedPriceSort }) => {
  const [selected, setSelected] = useState([
    { label: "Low to High", pressed: false },
    { label: "High to Low", pressed: false },
  ]);
  useEffect(() => {
    if (
      selectedPriceSort === "High to Low" ||
      selectedPriceSort === "descprice"
    ) {
      let copy = [...selected];
      copy[1] = { ...copy[1], pressed: true };
      setSelected(copy);
    } else if (
      selectedPriceSort === "Low to High" ||
      selectedPriceSort === "ascprice"
    ) {
      let copy = [...selected];
      copy[0] = { ...copy[0], pressed: true };
      setSelected(copy);
    }
  }, [selectedPriceSort]);

  const renderItem = ({ item, index }) => {
    const onPress = () => {
      let copy = [...selected];

      copy?.map((k, i) => {
        if (k?.pressed) {
          copy[i] = { ...copy[i], pressed: false };
        }
      });
      copy[index] = { ...copy[index], pressed: !item.pressed };
      setSelected(copy);

      setSelectedPriceSort(copy?.find((k) => k.pressed)?.label);
    };
    return (
      <CheckBox
        onPress={onPress}
        checked={selected?.find((k) => k.pressed && k.label === item.label)}
        label={item.label}
      />
    );
  };

  return (
    <VStack width={"100%"} px={4} space={6}>
      <FlatList
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 11 }}></View>
        )}
        contentContainerStyle={{
          paddingBottom: 12,
        }}
        data={selected}
        initialNumToRender={10}
        renderItem={renderItem}
      />
    </VStack>
  );
};

export default Sort;
