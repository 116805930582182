import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const ActiveProfileIcon = () => {
  return (
    <Icon viewBox="0 0 28 28" size={6}>
      <G width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M13.9997 2.33398C7.55618 2.33398 2.33301 7.55715 2.33301 14.0007C2.33301 20.4442 7.55618 25.6673 13.9997 25.6673C20.4432 25.6673 25.6663 20.4442 25.6663 14.0007C25.6663 7.55715 20.4432 2.33398 13.9997 2.33398Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          d="M4.98242 21.4037C4.98242 21.4037 7.58292 18.0833 13.9996 18.0833C20.4163 18.0833 23.0179 21.4037 23.0179 21.4037M13.9996 14C14.9278 14 15.8181 13.6313 16.4745 12.9749C17.1308 12.3185 17.4996 11.4283 17.4996 10.5C17.4996 9.57174 17.1308 8.6815 16.4745 8.02513C15.8181 7.36875 14.9278 7 13.9996 7C13.0713 7 12.1811 7.36875 11.5247 8.02513C10.8683 8.6815 10.4996 9.57174 10.4996 10.5C10.4996 11.4283 10.8683 12.3185 11.5247 12.9749C12.1811 13.6313 13.0713 14 13.9996 14Z"
          fill="white"
        />
        <Path
          d="M4.98242 21.4037C4.98242 21.4037 7.58292 18.0833 13.9996 18.0833C20.4163 18.0833 23.0179 21.4037 23.0179 21.4037C22.1785 22.7691 19.1998 25.5 13.9998 25.5C8.79976 25.5 5.82153 22.7691 4.98242 21.4037Z"
          fill="white"
        />
        <Path
          d="M4.98242 21.4037C4.98242 21.4037 7.58292 18.0833 13.9996 18.0833C20.4163 18.0833 23.0179 21.4037 23.0179 21.4037M13.9996 14C14.9278 14 15.8181 13.6313 16.4745 12.9749C17.1308 12.3185 17.4996 11.4283 17.4996 10.5C17.4996 9.57174 17.1308 8.6815 16.4745 8.02513C15.8181 7.36875 14.9278 7 13.9996 7C13.0713 7 12.1811 7.36875 11.5247 8.02513C10.8683 8.6815 10.4996 9.57174 10.4996 10.5C10.4996 11.4283 10.8683 12.3185 11.5247 12.9749C12.1811 13.6313 13.0713 14 13.9996 14Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          d="M4.98242 21.4037C4.98242 21.4037 7.58292 18.0833 13.9996 18.0833C20.4163 18.0833 23.0179 21.4037 23.0179 21.4037C22.1785 22.7691 19.1998 25.5 13.9998 25.5C8.79976 25.5 5.82153 22.7691 4.98242 21.4037Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </G>
    </Icon>
  );
};

export default ActiveProfileIcon;
