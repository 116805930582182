import {
  Text,
  Modal,
  HStack,
  VStack,
  Pressable,
  ScrollView,
  Center,
} from "native-base";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { privateApi } from "../../util/API";
import { closeDialog } from "../../redux/currentState/actions";
import { getUser } from "../../util/utilFunctions";
import PlusIcon from "../icons/plusIcon";
import { setDialog } from "../../redux/currentState/actions";
import { Popups } from "../../constants/popups";
import useGetCardList from "../../hooks/useGetCardList";
import { useSWRConfig } from "swr";
import PaymentCard from "../cards/paymentCard";
import { setAnalytics } from "../../analytics";
import Constants from "expo-constants";
import PrimaryCTAButton from "../buttons/primaryCTAButton";
import { gradientTextWeb } from "../../constants/gradient";
const apiUrl = Constants.expoConfig.extra.apiUrl;

const CardsPopUp = ({ navigation }) => {
  const { data } = useGetCardList();
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState(
    data?.cards.find((c) => c.id).id
  );

  const handleChange = async (i) => {
    setSelectedValue(i);
  };

  const onPress = async () => {
    const userId = await getUser();

    if (userId !== null && userId !== undefined) {
      const id = selectedValue;
      let res = await privateApi(
        "/api/payments/add_default_card/",
        { id: id, user_id: userId },
        "put"
      );
      if (res.status === 200 || res.status === 201) {
        setAnalytics("Confirm Button", "Added Default Card for Payment");
        mutate(`${apiUrl}/api/users/get_payment_details/?user_id=${userId}`);
        mutate(`${apiUrl}/api/payments/get_cards/?user_id=${userId}`);
        dispatch(closeDialog());
      } else {
        setAnalytics("Confirm Button", "Adding Default Card Failed", {
          popUp: "Oops you broke it, error pop up",
        });
        dispatch(setDialog(Popups.Error));
      }
    } else {
      setAnalytics("Confirm Button", "Guest User Tried To Add Default Card", {
        popUp: "Login Pop Up",
      });
      dispatch(setDialog(Popups.LoginPopup));
    }
  };

  return (
    <>
      <Pressable
        size={"full"}
        bg="black:alpha.80"
        onPress={() => navigation.goBack()}
      ></Pressable>
      <Center
        position="absolute"
        left={0}
        top={0}
        bottom={0}
        right={0}
        margin={"auto"}
        rounded={"20px"}
        w={{
          base: "full",
          sm: "full",
          md: "full",
          lg: "full",
          xl: "45%",
        }}
        _dark={{
          bg: "#2B2F33",
        }}
        _light={{
          bg: "white",
        }}
        p={{
          base: 4,
          sm: 4,
          md: 4,
          lg: 4,
          xl: "30px",
        }}
        h="500px"
      >
        <VStack space={6} w="full">
          <VStack w="full">
            <Text
              textAlign={"center"}
              letterSpacing={2}
              fontSize="36px"
              _web={{
                style: {
                  ...gradientTextWeb(1),
                  fontFamily: "AustinCyr_400",
                },
              }}
            >
              Saved Payment Methods
            </Text>

            <Pressable
              onPress={() => {
                navigation.navigate("addcard");
              }}
            >
              <HStack
                alignItems={"center"}
                borderRadius={"8px"}
                justifyContent="space-between"
                _light={{
                  bg: "white",
                  borderColor: "#E9B9FF",
                  borderWidth: "1px",
                }}
                _dark={{
                  bg: "primary.card",
                }}
                p={"16px"}
              >
                <Text
                  fontSize={16}
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                  _light={{
                    color: "#464646",
                  }}
                  _dark={{
                    color: "#94A3B8",
                  }}
                >
                  Add New Payment Mode
                </Text>
                <PlusIcon size={"2.5"} />
              </HStack>
            </Pressable>
          </VStack>

          <ScrollView
            keyboardShouldPersistTaps="handled"
            showsVerticalScrollIndicator={false}
          >
            <VStack space={"8px"} h="200px">
              {data?.cards.map((card, index) => {
                return (
                  <PaymentCard
                    card={card}
                    isPaymentPopUp={true}
                    onCheck={(event) => handleChange(card.id)}
                    isChecked={
                      selectedValue
                        ? selectedValue === card.id
                        : data?.cards.find((c) => c.id).id === card.id
                    }
                  />
                );
              })}
            </VStack>
          </ScrollView>
          <Center>
            <Center h="50px" w="200px">
              <PrimaryCTAButton gradientType="2" onPress={onPress}>
                <Text
                  letterSpacing={2}
                  textTransform={"uppercase"}
                  fontSize={"16px"}
                  style={{
                    fontFamily: "Hero_700Bold",
                  }}
                  color="white"
                >
                  Confirm
                </Text>
              </PrimaryCTAButton>
            </Center>
          </Center>
        </VStack>
      </Center>
    </>
  );
};

export default CardsPopUp;
