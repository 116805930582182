import { publicApi } from "../util/API";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../redux/currentState/actions";
import { useGetCountryCodeWeb } from "./useGetCountryCodeWeb";
import { useState } from "react";
import { Pages } from "../constants/pages";
import * as RootNavigation from "./../RootNavigation";
import { Platform } from "react-native";

// let res = await publicApi(
//   "/api/users/send_otp_email/",
//   {
//     email: form?.email,
//   },
//   "post"
// );

export const useSendOtp = () => {
  const countryCode = useGetCountryCodeWeb();
  const [error, setError] = useState();
  const [nextPressed, setNextPressed] = useState();
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const navigation = RootNavigation;
  const sendOtp = async (isLogin) => {
    if (Platform.OS === "web" || isLogin) {
      if (!form?.phone_number) {
        setError("Enter phone number");
        return;
      } else if (form?.phone_number?.length < 10) {
        setError("Please check phone number");
        return;
      }
    } else {
      if (!form?.phone_number && !form?.username) {
        setError("Fill credentials");
        return;
      } else if (!form?.phone_number) {
        setError("Enter phone number");
        return;
      } else if (form?.phone_number?.length < 10) {
        setError("Please check phone number");
        return;
      } else if (!form?.username) {
        setError("Fill username");
        return;
      }
    }

    if (form?.phone_number && form?.phone_number?.length === 10) {
      if (form?.phone_number && !/\s/g.test(form?.phone_number)) {
        dispatch(showLoader());

        try {
          let res = await publicApi(
            "/api/users/send_login_otp/",
            {
              phone: `+${countryCode}${form?.phone_number}`,
              login: isLogin,
            },
            "post"
          );
          console.log(
            "/api/users/send_login_otp/",
            `+${countryCode}${form?.phone_number}`,
            isLogin,
            res
          );
          if (res.body.status === 200) {
            if (Platform.OS !== "web" && !isLogin) {
              navigation.navigate(Pages.VerifyScreen, {
                type: "phone",
              });
            }
            setNextPressed(true);
          } else if (res.body.status_code === 404) {
            setError(res.body.message);
          }
        } catch (error) {
          console.log("catchblock", error);
        }
        dispatch(hideLoader());
      }
    }
  };

  return { sendOtp, error, setError, nextPressed };
};
