import {
  Center,
  Divider,
  HStack,
  Pressable,
  Stack,
  Text,
  VStack,
} from "native-base";
import { useState } from "react";
import BlackHairColorIcon from "../../components/icons/hairColorIcons/blackHairColorIcon";
import BlondeHairColorIcon from "../../components/icons/hairColorIcons/blondeHairColorIcon";
import RedHairColorIcon from "../../components/icons/hairColorIcons/redHairColorIcon";
import BrownHairColorIcon from "../../components/icons/hairColorIcons/brownHairColorIcon";
import MultiHairColorIcon from "../../components/icons/hairColorIcons/multiHairColorIcon";
import CoilyHairTextureIcon from "../../components/icons/hairTextureIcons/inactiveIcons/coilyHairTextureIcon";
import CurlyHairTextureIcon from "../../components/icons/hairTextureIcons/inactiveIcons/curlyHairTextureIcon";
import WavyHairTextureIcon from "../../components/icons/hairTextureIcons/inactiveIcons/wavyHairTextureIcon";
import StraightHairTextureIcon from "../../components/icons/hairTextureIcons/inactiveIcons/straightHairTextureIcon";
import DryHairTypeIcon from "../../components/icons/hairTypeIcons/inactiveIcons/dryHairTypeIcon";
import FineHairTypeIcon from "../../components/icons/hairTypeIcons/inactiveIcons/fineHairTypeIcon";
import OilyHairTypeIcon from "../../components/icons/hairTypeIcons/inactiveIcons/oilyHairTypeIcon";
import ThickHairTypeIcon from "../../components/icons/hairTypeIcons/inactiveIcons/thickHairTypeIcon";
import TreatedHairTypeIcon from "../../components/icons/hairTypeIcons/inactiveIcons/treatedHairTypeIcon";
import ActiveDryHairTypeIcon from "../../components/icons/hairTypeIcons/activeIcons/activeDryHairTypeIcon";
import ActiveFineHairTypeIcon from "../../components/icons/hairTypeIcons/activeIcons/activeFineHairTypeIcon";
import ActiveThickHairTypeIcon from "../../components/icons/hairTypeIcons/activeIcons/activeThickHairTypeIcon";
import ActiveOilyHairTypeIcon from "../../components/icons/hairTypeIcons/activeIcons/activeOilyHairTypeIcon";
import ActiveTreatedHairTypeIcon from "../../components/icons/hairTypeIcons/activeIcons/activeTreatedHairTypeIcon";
import ActiveCoilyHairTextureIcon from "../../components/icons/hairTextureIcons/activeIcons/activeCoilyHairTextureIcon";
import ActiveCurlyHairTextureIcon from "../../components/icons/hairTextureIcons/activeIcons/activeCurlyHairTextureIcon";
import ActiveWavyHairTextureIcon from "../../components/icons/hairTextureIcons/activeIcons/activeWavyHairTextureIcon";
import ActiveStraightHairTextureIcon from "../../components/icons/hairTextureIcons/activeIcons/activeStraightHairTextureIcon";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import { useSelector } from "react-redux";
import { setAnalytics } from "../../analytics";
import useGetUser from "../../hooks/useGetUser";
import SurveyScreenBottomBar from "../../components/bars/bottomBars/surveyScreenBottomBar";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";

const UnderstandingYourHairScreen = ({ navigation, route }) => {
  const userId = useSelector((state) => state.user.id);
  const { data } = useGetUser("profile", userId);

  const hairColorInitialState = [
    {
      label: "Black",
      icon: <BlackHairColorIcon />,
      activeIcon: <BlackHairColorIcon />,
      clicked: data?.user[0]?.hair_color === "Black" ? true : false,
    },
    {
      activeIcon: <BrownHairColorIcon />,
      icon: <BrownHairColorIcon />,
      label: "Brown",

      clicked: data?.user[0]?.hair_color === "Brown" ? true : false,
    },
    {
      icon: <BlondeHairColorIcon />,
      activeIcon: <BlondeHairColorIcon />,
      label: "Blonde",

      clicked: data?.user[0]?.hair_color === "Blonde" ? true : false,
    },
    {
      icon: <RedHairColorIcon />,
      activeIcon: <RedHairColorIcon />,
      label: "Red",

      clicked: data?.user[0]?.hair_color === "Red" ? true : false,
    },
    {
      icon: <MultiHairColorIcon />,
      activeIcon: <MultiHairColorIcon />,
      label: "Multi",

      clicked: data?.user[0]?.hair_color === "Multi" ? true : false,
    },
  ];

  const hairTextureInitialState = [
    {
      activeIcon: <ActiveStraightHairTextureIcon />,
      label: "Straight",
      icon: <StraightHairTextureIcon />,

      clicked: data?.user[0]?.hair_texture === "Straight" ? true : false,
    },
    {
      activeIcon: <ActiveWavyHairTextureIcon />,
      icon: <WavyHairTextureIcon />,
      label: "Wavy",

      clicked: data?.user[0]?.hair_texture === "Wavy" ? true : false,
    },
    {
      activeIcon: <ActiveCurlyHairTextureIcon />,
      icon: <CurlyHairTextureIcon />,
      label: "Curly",

      clicked: data?.user[0]?.hair_texture === "Curly" ? true : false,
    },
    {
      activeIcon: <ActiveCoilyHairTextureIcon />,
      icon: <CoilyHairTextureIcon />,
      label: "Coily",

      clicked: data?.user[0]?.hair_texture === "Coily" ? true : false,
    },
  ];

  const hairTypeInitialState = [
    {
      activeIcon: <ActiveFineHairTypeIcon />,
      icon: <FineHairTypeIcon />,
      label: "Fine",
      text: <p>Fine</p>,
      clicked: data?.user[0]?.hair_type?.find((k) => k === "Fine")
        ? true
        : false,
    },
    {
      activeIcon: <ActiveThickHairTypeIcon />,
      icon: <ThickHairTypeIcon />,
      label: "Thick",
      text: <p>Thick</p>,
      clicked: data?.user[0]?.hair_type?.find((k) => k === "Thick")
        ? true
        : false,
    },
    {
      activeIcon: <ActiveDryHairTypeIcon />,
      icon: <DryHairTypeIcon />,
      label: "Dry",
      text: <p>Dry</p>,
      clicked: data?.user[0]?.hair_type?.find((k) => k === "Dry")
        ? true
        : false,
    },
    {
      activeIcon: <ActiveOilyHairTypeIcon />,
      icon: <OilyHairTypeIcon />,
      label: "Oily",
      text: <p>Oily</p>,
      clicked: data?.user[0]?.hair_type?.find((k) => k === "Oily")
        ? true
        : false,
    },
    {
      activeIcon: <ActiveTreatedHairTypeIcon />,
      icon: <TreatedHairTypeIcon />,
      label: "Treated",
      text: <p>Treated</p>,
      clicked: data?.user[0]?.hair_type?.find((k) => k === "Treated")
        ? true
        : false,
    },
  ];

  const editUserDetails = useEditUserDetails();
  const [hairColor, setHairColor] = useState(hairColorInitialState);
  const [hairTexture, setHairTexture] = useState(hairTextureInitialState);
  const [hairType, setHairType] = useState(hairTypeInitialState);
  const [hairConcerns, setHairConcerns] = useState([
    {
      label: "Heat Protectant",
      clicked: data?.user[0]?.hair_concerns?.find(
        (k) => k === "Heat Protectant"
      )
        ? true
        : false,
    },
    {
      label: "Frizz",
      clicked: data?.user[0]?.hair_concerns?.find((k) => k === "Frizz")
        ? true
        : false,
    },

    {
      label: "Thinning Hair",
      clicked: data?.user[0]?.hair_concerns?.find((k) => k === "Thinning Hair")
        ? true
        : false,
    },

    {
      label: "Volume",
      clicked: data?.user[0]?.hair_concerns?.find((k) => k === "Volume")
        ? true
        : false,
    },
    {
      label: "Oily Scalp",
      clicked: data?.user[0]?.hair_concerns?.find((k) => k === "Oily Scalp")
        ? true
        : false,
    },

    {
      label: "Dry Scalp",
      clicked: data?.user[0]?.hair_concerns?.find((k) => k === "Dry Scalp")
        ? true
        : false,
    },
    {
      label: "Damaged Hair",
      clicked: data?.user[0]?.hair_concerns?.find((k) => k === "Damaged Hair")
        ? true
        : false,
    },
    {
      label: "High shine",
      clicked: data?.user[0]?.hair_concerns?.find((k) => k === "High shine")
        ? true
        : false,
    },

    {
      label: "Reduce Brass",
      clicked: data?.user[0]?.hair_concerns?.find((k) => k === "Reduce Brass")
        ? true
        : false,
    },
    {
      label: "Curling",
      clicked: data?.user[0]?.hair_concerns?.find((k) => k === "Curling")
        ? true
        : false,
    },
  ]);
  const [hold, setHold] = useState([
    {
      label: "Light",
      clicked: data?.user[0]?.hair_product_hold?.find((k) => k === "Light")
        ? true
        : false,
    },
    {
      label: "Medium",
      clicked: data?.user[0]?.hair_product_hold?.find((k) => k === "Medium")
        ? true
        : false,
    },
    {
      label: "Strong",
      clicked: data?.user[0]?.hair_product_hold?.find((k) => k === "Strong")
        ? true
        : false,
    },
  ]);

  const handleRequest = async (text) => {
    if (text === "Skip") {
      setAnalytics(
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
        }
      );
    } else {
      const hair_concerns = hairConcerns
        ?.filter((el) => el?.clicked)
        ?.map((el2) => el2?.label);
      const hair_type = hairType
        ?.filter((el) => el?.clicked)
        ?.map((el2) => el2?.label);
      const hair_texture = hairTexture?.find((k) => k?.clicked)?.label;
      const hair_color = hairColor?.find((k) => k?.clicked)?.label;
      const hair_product_hold = hold
        ?.filter((el) => el?.clicked)
        ?.map((el2) => el2?.label);

      editUserDetails(
        {
          hair_concerns,
          hair_type,
          hair_texture,
          hair_color,
          hair_product_hold,
        },
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
          hair_concerns,
          hair_type,
          hair_texture,
          hair_color,
          hair_product_hold,
          edited: data?.user[0]?.quiz_completed,
        }
      );
    }
    navigation.navigate("SpecificIngredientsScreen");
  };

  return (
    <>
      <VStack space={12} w="full">
        <Stack
          w="full"
          justifyContent="center"
          space={{
            base: 4,
            sm: 4,
            md: 4,
            lg: 4,
            xl: "50px",
          }}
          px={{
            base: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: "80px",
          }}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
        >
          <VStack
            space={"8px"}
            w={{
              xl: "20%",
            }}
          >
            <Text
              color="#682481"
              fontSize={"21px"}
              style={{
                fontFamily: "AustinCyr_400",
              }}
            >
              Your Hair
            </Text>
            <Text
              color="#464646"
              fontSize={"14px"}
              style={{
                fontFamily: "LeituraNewsRoman",
              }}
            >
              Flock suggests customized hair care products based on your hair
              type, texture, and concerns. This helps us to provide you with the
              most effective and tailored hair care routine.
            </Text>
          </VStack>
          <VStack
            space={12}
            w={{
              xl: "50%",
            }}
          >
            <Stack
              borderWidth={"1px"}
              borderColor="#FAF1FF"
              borderRadius={"10px"}
              justifyContent="center"
              space={4}
              p={4}
              direction={{
                base: "column",
                sm: "column",
                md: "column",
                lg: "column",
                xl: "row",
              }}
            >
              <VStack w="30%" space={"10px"} alignItems="center">
                <Text
                  textAlign={"center"}
                  letterSpacing={3}
                  textTransform="uppercase"
                  color={"#682481"}
                  fontSize="11px"
                  style={{
                    fontFamily: "Hero_700Bold",
                  }}
                  _light={{
                    color: "#682481",
                  }}
                  _dark={{
                    color: "white",
                  }}
                >
                  Your Haircolor
                </Text>
                <HStack
                  flexWrap={"wrap"}
                  w="70%"
                  justifyContent={"center"}
                  space={2}
                >
                  {hairColor?.map((item, index) => (
                    <VStack
                      mt={1.5}
                      key={index}
                      alignItems={"center"}
                      space={1}
                    >
                      <Pressable
                        onPress={() => {
                          let copy = hairColor.map((i, j) => {
                            return {
                              ...hairColor[j],
                              clicked: false,
                            };
                          });

                          copy[index] = {
                            ...copy[index],
                            clicked: !item.clicked,
                          };
                          setHairColor(copy);
                        }}
                      >
                        {item.clicked
                          ? item.activeIcon
                            ? item.activeIcon
                            : item.icon
                          : item.icon}
                      </Pressable>
                      <Text
                        textAlign={"center"}
                        _dark={{
                          color: item.clicked ? "white" : "#58595B",
                        }}
                        _light={{
                          color: item.clicked ? "#682481" : "#464646",
                        }}
                        _web={{
                          style: {
                            fontFamily: item.clicked
                              ? "Hero_700Bold"
                              : "Hero_400Regular",
                          },
                          fontSize: "14px",
                        }}
                        fontSize={16}
                        style={{
                          fontFamily: "Poppins_500Medium",
                        }}
                        lineHeight={20}
                      >
                        {item.label}
                      </Text>
                    </VStack>
                  ))}
                </HStack>
              </VStack>
              <Divider bg="#FAF1FF" orientation="vertical" h={"full"} />
              <VStack w="70%" space={8}>
                <VStack>
                  <Text
                    letterSpacing={3}
                    textTransform="uppercase"
                    color={"#682481"}
                    fontSize="11px"
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                    _light={{
                      color: "#682481",
                    }}
                    _dark={{
                      color: "white",
                    }}
                  >
                    Hair Texture
                  </Text>

                  <HStack space={4} flexWrap="wrap">
                    {hairTexture?.map((item, index) => (
                      <VStack
                        mt={1.5}
                        key={index}
                        alignItems={"center"}
                        space={1}
                      >
                        <Pressable
                          onPress={() => {
                            let copy = hairTexture.map((i, j) => {
                              return {
                                ...hairTexture[j],
                                clicked: false,
                              };
                            });

                            copy[index] = {
                              ...copy[index],
                              clicked: !item.clicked,
                            };
                            setHairTexture(copy);
                          }}
                        >
                          {item.clicked
                            ? item.activeIcon
                              ? item.activeIcon
                              : item.icon
                            : item.icon}
                        </Pressable>
                        <Text
                          textAlign={"center"}
                          _dark={{
                            color: item.clicked ? "white" : "#58595B",
                          }}
                          _light={{
                            color: item.clicked ? "#682481" : "#464646",
                          }}
                          _web={{
                            style: {
                              fontFamily: item.clicked
                                ? "Hero_700Bold"
                                : "Hero_400Regular",
                            },
                            fontSize: "14px",
                          }}
                          fontSize={16}
                          style={{
                            fontFamily: "Poppins_500Medium",
                          }}
                          lineHeight={20}
                        >
                          {item.label}
                        </Text>
                      </VStack>
                    ))}
                  </HStack>
                </VStack>
                <VStack>
                  <Text
                    letterSpacing={3}
                    textTransform="uppercase"
                    color={"#682481"}
                    fontSize="11px"
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                    _light={{
                      color: "#682481",
                    }}
                    _dark={{
                      color: "white",
                    }}
                  >
                    Hair type
                  </Text>
                  <HStack space={4} flexWrap="wrap">
                    {hairType?.map((item, index) => (
                      <VStack
                        mt={1.5}
                        key={index}
                        alignItems={"center"}
                        space={1}
                      >
                        <Pressable
                          onPress={() => {
                            let copy = hairType.map((i, j) => {
                              return {
                                ...hairType[j],
                                clicked: false,
                              };
                            });

                            copy[index] = {
                              ...copy[index],
                              clicked: !item.clicked,
                            };
                            setHairType(copy);
                          }}
                        >
                          {item.clicked
                            ? item.activeIcon
                              ? item.activeIcon
                              : item.icon
                            : item.icon}
                        </Pressable>
                        <Text
                          textAlign={"center"}
                          _dark={{
                            color: item.clicked ? "white" : "#58595B",
                          }}
                          _light={{
                            color: item.clicked ? "#682481" : "#464646",
                          }}
                          _web={{
                            style: {
                              fontFamily: item.clicked
                                ? "Hero_700Bold"
                                : "Hero_400Regular",
                            },
                            fontSize: "14px",
                          }}
                          fontSize={16}
                          style={{
                            fontFamily: "Poppins_500Medium",
                          }}
                          lineHeight={20}
                        >
                          {item.label}
                        </Text>
                      </VStack>
                    ))}
                  </HStack>
                </VStack>
                <Divider bg="#FAF1FF" />

                <VStack space={2}>
                  <Text
                    letterSpacing={3}
                    textTransform="uppercase"
                    color={"#682481"}
                    fontSize="11px"
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                    _light={{
                      color: "#682481",
                    }}
                    _dark={{
                      color: "white",
                    }}
                  >
                    Any Hair concerns?
                  </Text>
                  <HStack alignItems={"center"} flexWrap="wrap" space={"10px"}>
                    {hairConcerns.map((label, index) => (
                      <Pressable
                        onPress={() => {
                          const copy = [...hairConcerns];
                          copy[index] = {
                            ...copy[index],
                            clicked: !label.clicked,
                          };
                          setHairConcerns(copy);
                        }}
                      >
                        <Center
                          mt={"10px"}
                          h="30px"
                          w="100%"
                          rounded={"full"}
                          px={"20px"}
                          mx={"2px"}
                          _light={{
                            bg: label.clicked ? "#E9B9FF" : "#F0F0F0",
                          }}
                          _dark={{
                            bg: "#2B2F33",
                          }}
                        >
                          <Text
                            _dark={{
                              color: label.clicked ? "white" : "#94A3B8",
                            }}
                            _light={{
                              color: label.clicked ? "#682481" : "#464646",
                            }}
                            fontSize={"16px"}
                            _web={{
                              fontFamily: label.clicked
                                ? "Hero_700Bold"
                                : "Hero_400Regular",
                              fontSize: "14px",
                            }}
                            style={{
                              fontFamily: "HK_500Medium",
                            }}
                          >
                            {label.label}
                          </Text>
                        </Center>
                      </Pressable>
                    ))}
                  </HStack>
                </VStack>
              </VStack>
            </Stack>
            <SurveyScreenBottomBar
              showNextButton={
                hairTexture?.find((k) => k?.clicked) ||
                hairColor?.find((k) => k?.clicked) ||
                hold?.find((el) => el?.clicked) ||
                hairType?.find((el) => el?.clicked) ||
                hairConcerns?.find((el) => el?.clicked)
              }
              nextOnPress={() => {
                handleRequest("Next");
              }}
            />
          </VStack>
        </Stack>
        <FooterSectionWeb />
      </VStack>
    </>
  );
};

export default UnderstandingYourHairScreen;
