import { HStack, View, Text, VStack, FlatList, Center } from "native-base";
import { useState } from "react";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import { useSelector } from "react-redux";
import { setAnalytics } from "../../analytics";
import InterestCard from "../../components/cards/interestCard";
import useGetUser from "../../hooks/useGetUser";
import SurveyScreenBottomBar from "../../components/bars/bottomBars/surveyScreenBottomBar";

const ScentsScreen = ({ navigation, route }) => {
  const userId = useSelector((state) => state.user.id);
  const { data } = useGetUser("profile", userId);

  const editUserDetails = useEditUserDetails();
  const [choices, setChoices] = useState([
    {
      label: "Fresh",
      pressed: data?.user[0]?.scent_categories?.find((k) => k === "Fresh")
        ? true
        : false,
    },
    {
      label: "Layerable",
      pressed: data?.user[0]?.scent_categories?.find((k) => k === "Layerable")
        ? true
        : false,
    },
    {
      label: "Unisex/Genderless",
      pressed: data?.user[0]?.scent_categories?.find(
        (k) => k === "Unisex/Genderless"
      )
        ? true
        : false,
    },
    {
      label: "Warm & Spicy",
      pressed: data?.user[0]?.scent_categories?.find(
        (k) => k === "Warm & Spicy"
      )
        ? true
        : false,
    },
    {
      label: "Woody & Earthy",
      pressed: data?.user[0]?.scent_categories?.find(
        (k) => k === "Woody & Earthy"
      )
        ? true
        : false,
    },
    {
      label: "Floral",
      pressed: data?.user[0]?.scent_categories?.find((k) => k === "Floral")
        ? true
        : false,
    },
  ]);

  const handleRequest = async (text) => {
    if (text === "Skip") {
      setAnalytics(
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
        }
      );
    } else {
      const scent_categories = choices
        .filter((el) => el?.pressed)
        .map((el2) => el2?.label);

      editUserDetails(
        { scent_categories: scent_categories },
        `${text} Button Survey Screen`,
        `${text} Button Clicked`,
        {
          screen: route?.name,
          scent_categories,
          edited: data?.user[0]?.quiz_completed,
        }
      );
    }
    navigation.navigate("SpecificIngredientsScreen");
  };
  return (
    <>
      <View p={4} h="full">
        <VStack bg="#1C212E" p={4} rounded={16} space={4} alignItems={"center"}>
          <Text
            textAlign={"center"}
            color={"white"}
            fontSize={30}
            style={{
              fontFamily: "Poppins_500Medium",
            }}
            lineHeight={44}
          >
            How about scents?
          </Text>
          <Text
            textAlign={"center"}
            color={"#94A3B8"}
            fontSize={14}
            style={{
              fontFamily: "Poppins_400Regular",
            }}
            lineHeight={17}
          >
            Pick one
          </Text>
          <VStack
            width={"full"}
            justifyContent={"center"}
            alignItems="center"
            space={4}
          >
            <FlatList
              showsVerticalScrollIndicator={false}
              scrollEnabled={false}
              data={choices}
              numColumns={2}
              renderItem={({ item, index }) => (
                <Center m={2}>
                  <InterestCard
                    interestName={item.label}
                    onPress={() => {
                      let copy = [...choices];
                      copy[index] = { ...copy[index], pressed: !item.pressed };
                      setChoices(copy);
                    }}
                    cardPressed={item.pressed}
                  />
                </Center>
              )}
            />
          </VStack>
        </VStack>
      </View>
      <HStack position={"absolute"} bottom={0}>
        <SurveyScreenBottomBar
          showSkipButton={!data?.user[0]?.quiz_completed}
          showNextButton={choices?.find((el) => el?.pressed)}
          skipOnPress={() => {
            handleRequest("Skip");
          }}
          nextOnPress={() => {
            handleRequest("Next");
          }}
        />
      </HStack>
    </>
  );
};

export default ScentsScreen;
