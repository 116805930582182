import { useNavigation } from "@react-navigation/native";
import { FlatList, View } from "native-base";
import { useSelector } from "react-redux";
import { setAnalytics } from "../../analytics";
import RewardsCard from "../../components/cards/rewardsCard";
import { Pages } from "../../constants/pages";
import { useShare } from "../../hooks/useShare";

const UnclaimedRewards = ({ data }) => {
  const navigation = useNavigation();

  const userId = useSelector((state) => state.user.id);
  const onShare = useShare();

  return (
    <>
      <FlatList
        showsVerticalScrollIndicator={false}
        _web={{
          py: 0,
          px: 0,
        }}
        columnWrapperStyle={{
          flexDirection: "row",
          gap: 8,
          // justifyContent: "center",
        }}
        py={"20px"}
        px={"10px"}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 6 }}></View>
        )}
        numColumns={2}
        data={data ? data : []}
        initialNumToRender={5}
        renderItem={({ item }) => (
          <>
            <RewardsCard
              points={item.reward_points}
              actionType={item.action_type}
              buttonText={
                item.action_name === "Complete the Beauty Quiz"
                  ? "Take quiz"
                  : item.action_name === "Create First Flock Deal"
                  ? "Create"
                  : item.action_name === "Start your favorites"
                  ? "Add to favorites"
                  : item.action_name
              }
              cardHeading={item.action_name}
              isClaimable={item.is_claimable}
              onPress={async () => {
                setAnalytics(
                  "Rewards Action Button",
                  `${item.action_name} Clicked`,
                  {
                    action_name: item.action_name,
                  }
                );
                if (item.action_name === "Make a purchase") {
                  navigation.navigate(Pages.ShopScreen);
                } else if (item.action_name === "Start your favorites") {
                  navigation.navigate(Pages.ShopScreen);
                } else if (item.action_name === "Complete the Beauty Quiz") {
                  if (userId === null || userId === undefined) {
                    navigation.navigate(Pages.Login);
                  } else {
                    navigation.navigate(Pages.AboutYourSelfScreen);
                  }
                } else if (item.action_type === 5) {
                  onShare("flock/");
                }
              }}
            />
          </>
        )}
        getItemLayout={(data, index) => ({
          length: 114,
          offset: 114 * index,
          index,
        })}
      />
    </>
  );
};

export default UnclaimedRewards;
