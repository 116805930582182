import React, { useState, useEffect } from "react";
import { VStack, FlatList, View } from "native-base";
import { Brands } from "../../../../constants/brands";
import CheckBox from "./checkbox";
import { useDispatch, useSelector } from "react-redux";
import SearchBox from "./searchBox";
import { changeFilter } from "../../../../redux/filter/actions";

const Item = ({ item, setSelectedBrands, selectedBrands }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selected) {
      if (selectedBrands.includes(item)) {
        setSelected(true);
      }
    }
  }, [selectedBrands]);

  const onPress = () => {
    if (selectedBrands.includes(item)) {
      setSelected(false);
      const filtered = selectedBrands.filter((value) => value !== item);
      setSelectedBrands(filtered);
      // dispatch(changeFilter({ brands: selectedBrands }));
    } else {
      setSelectedBrands([...selectedBrands, item]);
      setSelected(true);
      // dispatch(changeFilter({ brands: selectedBrands }));
    }
    // dispatch(changeFilter({ brands: selectedBrands }));
  };

  return (
    <CheckBox
      onPress={onPress}
      checked={selected}
      label={Brands[item]}
      item={item}
    />
  );
};

const Brand = ({ selectedBrands, setSelectedBrands }) => {
  const [brandResults, setBrandResults] = useState(Object.keys(Brands));

  const renderItem = ({ item }) => (
    <Item
      item={item}
      setSelectedBrands={setSelectedBrands}
      selectedBrands={selectedBrands}
    />
  );

  return (
    <VStack width={"100%"} pb={8} px={4} space={6}>
      <SearchBox
        onChange={async (val) => {
          let brandNames = Object.keys(Brands);
          let brResults = brandNames.filter((brand) => {
            return Brands[brand].toLowerCase().includes(val.toLowerCase());
          });
          setBrandResults(brResults);
        }}
      />
      <FlatList
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 11 }}></View>
        )}
        contentContainerStyle={{
          paddingBottom: 12,
        }}
        data={brandResults}
        initialNumToRender={10}
        renderItem={renderItem}
      />
    </VStack>
  );
};

export default Brand;
