import { useEffect } from "react";
import { Pages } from "../../constants/pages";
import { BackHandler } from "react-native";
import { gradientTextWeb } from "../../constants/gradient";
import { Center, HStack, Pressable, Text, VStack } from "native-base";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
const OrderSuccessfulScreen = ({ navigation }) => {
  useEffect(() => {
    function handleBackButton() {
      navigation.navigate(Pages.Home);
      return true;
    }

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      handleBackButton
    );

    return () => backHandler.remove();
  }, [navigation]);
  return (
    <>
      <VStack flex={1}>
        <Center bg="#E0FFDF" py={12} w="full">
          <Text
            fontSize={"36px"}
            _web={{
              style: {
                ...gradientTextWeb(1),
                fontFamily: "AustinCyr_400",
              },
            }}
          >
            Order Confirmed!
          </Text>
          <HStack alignItems={"center"} space={1}>
            <Text
              color={"#464646"}
              fontSize={"14px"}
              _web={{
                style: {
                  fontFamily: "Hero_700Bold",
                },
              }}
            >
              Your Order has been placed.
            </Text>
            <Pressable onPress={() => navigation.navigate(Pages.Orders)}>
              <Text
                fontSize={"14px"}
                _web={{
                  style: {
                    ...gradientTextWeb(3),
                    fontFamily: "Hero_400Regular",
                  },
                }}
              >
                your orders
              </Text>
              <Center
                h={"1px"}
                bg={{
                  linearGradient: {
                    colors: ["#8F49FF", "#FF4483"],
                    start: [0, 0],
                    end: [1, 0],
                  },
                }}
              ></Center>
            </Pressable>
          </HStack>
        </Center>
      </VStack>
      <FooterSectionWeb />
    </>
  );
};

export default OrderSuccessfulScreen;
