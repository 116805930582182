import {
  Center,
  HStack,
  Pressable,
  Text,
  Image,
  useColorModeValue,
} from "native-base";

const InterestCard = ({
  interestName,
  onPress,
  cardPressed,
  icon,
  widthCenterMain = "144px",
  widhtCenterSub = "141px",
}) => {
  const borderColor = useColorModeValue("#C1C9D2", "#3B3B3B");
  return (
    <Pressable onPress={onPress}>
      <Center
        h="75px"
        w={widthCenterMain}
        _light={{
          borderColor: !cardPressed ? "#C1C9D2" : "",
        }}
        _dark={{
          borderColor: !cardPressed ? "#3B3B3B" : "",
        }}
        borderWidth={!cardPressed ? "1px" : "0px"}
        bg={{
          linearGradient: cardPressed
            ? {
                colors: [
                  "primary.ctaLinearSecondColor",
                  "primary.ctaLinearFirstColor",
                ],
                start: [0, 0],
                end: [1, 0],
              }
            : {
                colors: [borderColor, borderColor],
                start: [0, 0],
                end: [1, 0],
              },
        }}
        rounded={"10px"}
      >
        <Center
          h="71px"
          w={widhtCenterSub}
          _light={{
            bg: "white",
          }}
          _dark={{
            bg: "#1C212E",
          }}
          rounded={"10px"}
        >
          <HStack space={1} alignItems="center">
            {icon && icon}
            <Text
              _light={{
                color: cardPressed ? "#682481" : "#464646",
              }}
              _dark={{
                color: cardPressed ? "white" : "#94A3B8",
              }}
              _web={{
                style: {
                  fontFamily: "HK_500Medium",
                },
              }}
              isTruncated
              noOfLines={2}
              textAlign={"center"}
              style={{
                fontFamily: "Poppins_500Medium",
              }}
              fontSize={16}
              lineHeight={24}
            >
              {interestName}
            </Text>
          </HStack>
        </Center>
      </Center>
    </Pressable>
  );
};

export default InterestCard;
