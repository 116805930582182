import { createIcon } from "native-base";
import { Circle } from "react-native-svg";
const EllipseIcon = ({ color = "#C4C4C4" }) => {
  const CustomIcon = createIcon({
    width: "3",
    height: "3",
    viewBox: "0 0 3 3",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    path: [<Circle key={3} cx="1.5" cy="1.5" r="1.5" fill={color} />],
  });
  return (
    <>
      <CustomIcon size={"1"} />
    </>
  );
};

export default EllipseIcon;
