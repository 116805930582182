import { useDispatch } from "react-redux";
import { reloadWishlist } from "../redux/cart/actions";
import {
  hideLoader,
  setDialog,
  showLoader,
} from "../redux/currentState/actions";
import { Popups } from "../constants/popups";
import { addToWishlist } from "../redux/wishlist/actions";
import { privateApi } from "../util/API";
import { getUser } from "../util/utilFunctions";
import { setAnalytics } from "../analytics";
import * as RootNavigation from "./../RootNavigation";
import { Platform } from "react-native";
import { Pages } from "../constants/pages";
export const useAddToWishlist = () => {
  const navigation = RootNavigation;
  const dispatch = useDispatch();
  const addWishlist = async (productId) => {
    dispatch(showLoader());
    if (productId !== undefined && productId !== null) {
      const userId = await getUser();
      try {
        if (userId !== null && userId !== undefined) {
          let res = await privateApi(
            "/api/products/wishlist/",
            { user: userId, product_sku: productId },
            "post"
          );

          if (res.status === 201) {
            let data = res.body;
            dispatch(addToWishlist(productId));
            dispatch(reloadWishlist(true));
            dispatch(hideLoader());
            setAnalytics("Add to Favorites", "Added To Favorites", {
              productId: productId,
            });
            return data.id;
          }
        } else {
          setAnalytics(
            "Add To Favorites",
            `Guest User Tried Adding To Favorites`,
            {
              productId: productId,
            }
          );
          if (Platform.OS === "web") {
            navigation.navigate(Pages.Login);
          } else {
            dispatch(setDialog(Popups.LoginPopup));
          }
        }
      } catch (error) {}
      dispatch(hideLoader());
    }
  };
  return addWishlist;
};
