import {
  Center,
  CloseIcon,
  HStack,
  IconButton,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import BlueArrowIcon from "../../components/icons/blueArrowIcon";
import ArrowBackIcon from "../../components/icons/webIcons/arrowBackIcon";
import { Pages } from "../../constants/pages";
import { changeFilter } from "../../redux/filter/actions";

const Shopmenu = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const productTypes = useSelector((state) => state.filter.productTypes);
  const menuItems = [
    {
      label: "Makeup",
      index: "4",
    },
    {
      label: "Skincare",
      index: "6",
    },
    {
      label: "HairCare",
      index: "3",
    },
    {
      label: "Tools",
      index: "7",
    },
  ];
  return (
    <>
      <VStack bg="#FAF1FF" h="full" space={8} roundedBottomLeft={"70px"}>
        <HStack
          pt={4}
          alignItems={"center"}
          justifyContent="space-between"
          w="full"
          px={4}
        >
          <HStack alignItems={"center"} space={2}>
            <IconButton
              onPress={() => {
                navigation.goBack();
              }}
              size={8}
              _icon={{
                color: "#682481",
              }}
              icon={<ArrowBackIcon size={10} />}
            ></IconButton>
            <Text
              fontSize={"36px"}
              style={{
                fontFamily: "AustinCyr_400",
              }}
              color="#682481"
            >
              Shop
            </Text>
          </HStack>
          <IconButton
            onPress={() => {
              navigation.goBack();
            }}
            size={8}
            _icon={{
              color: "#682481",
            }}
            icon={<CloseIcon />}
          ></IconButton>
        </HStack>
        <VStack w="full" px={6} space={6}>
          {menuItems?.map((k, i) => (
            <Pressable
              w="full"
              flexDir={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              onPress={() => {
                let vals = [...productTypes];
                if (vals.includes(k.index) === true) {
                  vals = [...vals.filter((value) => value !== k.index)];
                } else {
                  vals.push(k.index);
                }
                dispatch(changeFilter({ productTypes: vals }));
                dispatch({
                  type: "SET_APPLY_FILTER",
                });

                navigation.navigate(Pages.ShopScreen);
              }}
              _hover={{
                bg: route?.name === "Home" ? "none" : "#FAF1FF",
              }}
            >
              <Text
                textTransform={"uppercase"}
                fontSize={"36px"}
                style={{
                  fontFamily: "AustinCyr_400",
                }}
                color="#682481"
              >
                {k?.label}
              </Text>
              <BlueArrowIcon color="#F3A9FF" />
            </Pressable>
          ))}
          <Center>
            <Pressable
              flexDir={"row"}
              alignItems="center"
              onPress={() => {
                navigation.navigate(Pages.ShopScreen);
              }}
            >
              <Text
                textAlign={"center"}
                textTransform={"uppercase"}
                fontSize={"14px"}
                color="#682481"
                style={{
                  fontFamily: "Hero_700Bold",
                }}
              >
                Shop all
              </Text>
            </Pressable>
          </Center>
        </VStack>
      </VStack>
    </>
  );
};

export default Shopmenu;
