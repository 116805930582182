import { useNavigation, useRoute } from "@react-navigation/native";
import { Box, SectionList } from "native-base";
import ViewMoreTopBar from "../../../components/bars/topBars/viewMoreTopBar";
import CollectionCard from "../../../components/cards/collectionCard";
import useGetCollections from "../../../hooks/useGetCollections";

const Collections = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { data: collections } = useGetCollections(route?.params?.id);

  const renderItem = ({ item }) => <CollectionCard item={item} />;

  return (
    <>
      {collections?.length > 0 && (
        <SectionList
          ListHeaderComponent={
            <ViewMoreTopBar
              title="Collections"
              buttonText={route?.params?.type === "profile" && "Add"}
              onPress={() => {
                if (route?.params?.type === "profile") {
                  navigation.navigate("addcollection");
                }
              }}
            />
          }
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={{
            paddingBottom: 14,
          }}
          sections={collections ? [{ data: collections }] : []}
          ItemSeparatorComponent={() => (
            <Box style={{ paddingVertical: 20 }}></Box>
          )}
          showsVerticalScrollIndicator={false}
          stickySectionHeadersEnabled
          renderSectionHeader={(item) => <>{item.section?.header}</>}
          renderItem={renderItem}
        ></SectionList>
      )}
    </>
  );
};

export default Collections;
