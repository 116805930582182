import { Center, HStack, Stack, Text, VStack } from "native-base";
import HeadingBar from "../../components/bars/headingBar.js";
import FooterSectionWeb from "../../components/footer/footerSectionWeb.js";
import { GRADIENTMAP, gradientTextWeb } from "../../constants/gradient.js";
import ProductsHOC from "../../hoc/ProductsHOC.js";
import FilterAndProductsSection from "./filterAndProductsSection.js";

const ShopScreen = () => {
  return (
    <VStack
      space={{
        xl: "50px",
      }}
    >
      <HeadingBar>
        <VStack alignItems={"center"} w="full">
          <Text
            fontSize={"36px"}
            _web={{
              style: {
                ...gradientTextWeb(1),
                fontFamily: "AustinCyr_400",
              },
            }}
          >
            Shop
          </Text>
          <Stack
            direction={{
              base: "column",
              sm: "column",
              md: "column",
              lg: "column",
              xl: "row",
            }}
            justifyContent={"center"}
            space={6}
            w="full"
            alignItems={"center"}
          >
            {[
              {
                label: "AUTHENTICITY GUARANTEED",
              },
              {
                label: "EASY RETURNS",
              },
              {
                label: "2-5 Days SHipping within the U.S.",
              },
            ].map((k, i) => (
              <HStack alignItems={"center"} space={3}>
                <Text
                  textTransform={"uppercase"}
                  letterSpacing={2}
                  fontSize={"16px"}
                  _web={{
                    style: {
                      ...gradientTextWeb(1),
                      fontFamily: "AustinCyr_400",
                    },
                  }}
                >
                  {k?.label}
                </Text>
                {i !== 2 && (
                  <Center
                    size={1}
                    rounded={"full"}
                    bg={{
                      linearGradient: {
                        colors: GRADIENTMAP["1"],
                        start: [0, 0],
                        end: [1, 0],
                      },
                    }}
                  ></Center>
                )}
              </HStack>
            ))}
          </Stack>
        </VStack>
      </HeadingBar>
      <ProductsHOC fetcherType="homeProducts">
        <FilterAndProductsSection />
      </ProductsHOC>

      <FooterSectionWeb />
    </VStack>
  );
};

export default ShopScreen;
