import {
  Text,
  Modal,
  HStack,
  VStack,
  Slide,
  Pressable,
  Box,
  Divider,
  Center,
  FlatList,
  View,
} from "native-base";
import { useEffect, useMemo, useState } from "react";
import Price from "./buildingBlocks/price";
import Brand from "./buildingBlocks/brand";
import FilterPopUpBottomBar from "../../bars/filterPopUpBottomBar";
import FilterPopUpTopBar from "../../bars/popupTopBars/filterPopUpTopBar";
import { useDispatch, useSelector } from "react-redux";
import { goBackDialog } from "../../../redux/currentState/actions";
import Category from "./buildingBlocks/category";
import { changeFilter, resetCacheFilter } from "../../../redux/filter/actions";
import HairProfile from "./buildingBlocks/hairProfile";
import SkinProfile from "./buildingBlocks/skinProfile";
import Sort from "./buildingBlocks/sort";

const FilterPopUp = () => {
  const dispatch = useDispatch();

  const low = useSelector((state) => state.filter.cachedFilters.priceRangeFrom);
  const high = useSelector((state) => state.filter.cachedFilters.priceRangeTo);
  const [priceFilter, setPriceFilter] = useState([]);
  const brandFilter = useSelector((state) => state.filter.cachedFilters.brands);

  const productTypes = useSelector(
    (state) => state.filter.cachedFilters.productTypes
  );
  const hairConcernFilter = useSelector(
    (state) => state.filter.cachedFilters.concerns
  );
  const skinConcernFilter = useSelector(
    (state) => state.filter.cachedFilters.skinConcerns
  );
  const openDialog = useSelector((state) => state.currentState.openDialog);
  const priceSort = useSelector(
    (state) => state.filter.cachedFilters.price_sort
  );

  const [selectedSkin, setSelectedSkin] = useState(skinConcernFilter);
  const [selectedBrands, setSelectedBrands] = useState(brandFilter);
  const [selectedHair, setSelectedHair] = useState(hairConcernFilter);
  const [selectedCategory, setSelectedCategory] = useState(productTypes);
  const [selectedPriceSort, setSelectedPriceSort] = useState(priceSort);

  useEffect(() => {
    if (low !== 0 || high !== 700) {
      setPriceFilter([0]);
    } else {
      setPriceFilter([]);
    }
  }, [low, high]);

  const [filterBy, resetFilterBy] = useState([
    {
      label: "Price",

      pressed: true,
    },

    {
      label: "Brand",

      pressed: false,
    },
    {
      label: "Category",

      pressed: false,
    },
    {
      label: "Skin",

      pressed: false,
    },
    {
      label: "Hair",

      pressed: false,
    },
    {
      label: "Sort",

      pressed: false,
    },
  ]);

  const onPressApplyFilter = () => {
    dispatch(
      changeFilter({
        skinConcerns: selectedSkin,
        brands: selectedBrands,
        concerns: selectedHair,
        productTypes: selectedCategory,
        price_sort:
          selectedPriceSort === "Low to High"
            ? "ascprice"
            : selectedPriceSort === "High to Low"
            ? "descprice"
            : null,
      })
    );
  };

  const checkForFilter = async () => {
    dispatch(resetCacheFilter());
    dispatch(goBackDialog());
  };
  const renderItem = useMemo(() => ({ item }) => (
    <HStack justifyContent={"center"}>
      {item.pressed && item?.label === "Price" && <Price />}
      {item.pressed && item?.label === "Brand" && (
        <Brand
          selectedBrands={selectedBrands}
          setSelectedBrands={setSelectedBrands}
        />
      )}
      {item.pressed && item?.label === "Hair" && (
        <HairProfile
          selectedHair={selectedHair}
          setSelectedHair={setSelectedHair}
        />
      )}
      {item.pressed && item?.label === "Skin" && (
        <SkinProfile
          selectedSkin={selectedSkin}
          setSelectedSkin={setSelectedSkin}
        />
      )}
      {item.pressed && item?.label === "Category" && (
        <Category
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      )}
      {item.pressed && item?.label === "Sort" && (
        <Sort
          selectedPriceSort={selectedPriceSort}
          setSelectedPriceSort={(val) => setSelectedPriceSort(val)}
        />
      )}
    </HStack>
  ));
  return (
    <>
      <Modal
        size={"full"}
        isOpen={openDialog}
        onClose={() => checkForFilter()}
        bg="black:alpha.80"
      >
        <Slide overlay={false} in={openDialog} placement="bottom">
          <VStack
            height={"90%"}
            roundedTop={"40px"}
            marginBottom={"0"}
            marginTop={"auto"}
            bg={"#2B2F33"}
            width="100%"
          >
            <FilterPopUpTopBar onPress={() => checkForFilter()} />

            <HStack height={"75%"} pt={1}>
              <View width={"35%"} bg={"#202427"}>
                <FlatList
                  showsVerticalScrollIndicator={false}
                  data={filterBy}
                  renderItem={({ item, index }) => (
                    <Pressable
                      key={index}
                      onPress={() => {
                        let copy = [...filterBy];
                        copy.map((k) => {
                          if (k.pressed) {
                            return (k.pressed = false);
                          }
                        });
                        copy[index] = { ...copy[index], pressed: true };
                        resetFilterBy(copy);
                      }}
                    >
                      <HStack
                        height={16}
                        justifyContent={"space-between"}
                        alignItems="center"
                        pl={2}
                      >
                        <Text
                          fontSize={16}
                          style={{
                            fontFamily: "HK_600SemiBold",
                          }}
                          textAlign={"center"}
                          color={item.pressed ? "white" : "#94A3B8"}
                        >
                          {item.label}

                          {"   "}
                          {[
                            { label: "Price", filter: priceFilter },
                            {
                              label: "Brand",
                              filter: selectedBrands,
                            },
                            {
                              label: "Category",
                              filter: selectedCategory,
                            },
                            {
                              label: "Skin",
                              filter: selectedSkin,
                            },
                            {
                              label: "Hair",
                              filter: selectedHair,
                            },
                            {
                              label: "Sort",
                              filter: selectedPriceSort,
                            },
                          ].map(
                            (i, k) =>
                              i?.filter?.length > 0 &&
                              index === k && (
                                <Center>
                                  <Box
                                    bg={"#8D49DE"}
                                    height={1.5}
                                    width={1.5}
                                    rounded="full"
                                  ></Box>
                                </Center>
                              )
                          )}
                        </Text>

                        <Divider
                          orientation="vertical"
                          bg={item.pressed ? "#8D49DE" : "#202427"}
                          thickness="3"
                        />
                      </HStack>
                    </Pressable>
                  )}
                />
              </View>
              <View w={"65%"} bg={"#2B2F33"}>
                <FlatList
                  contentContainerStyle={{
                    flexDirection: "row",
                  }}
                  data={filterBy}
                  renderItem={renderItem}
                />
              </View>
            </HStack>
          </VStack>

          <FilterPopUpBottomBar
            onPressApplyFilter={onPressApplyFilter}
            eventProperties={{
              skinConcerns: selectedSkin,
              brands: selectedBrands,
              concerns: selectedHair,
              productTypes: selectedCategory,
              price_sort:
                selectedPriceSort === "Low to High"
                  ? "ascprice"
                  : selectedPriceSort === "High to Low"
                  ? "descprice"
                  : null,
            }}
          />
        </Slide>
      </Modal>
    </>
  );
};

export default FilterPopUp;
