import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const SendIcon = () => {
  return (
    <Icon viewBox="0 0 17 17" size={"16px"}>
      <G width="17" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M3.09105 13.3805C2.86883 13.4694 2.65771 13.4499 2.45771 13.3219C2.25771 13.1943 2.15771 13.0083 2.15771 12.7639V10.2805C2.15771 10.125 2.20216 9.98609 2.29105 9.86387C2.37994 9.74165 2.50216 9.66387 2.65771 9.63054L7.49105 8.43054L2.65771 7.23054C2.50216 7.1972 2.37994 7.11943 2.29105 6.99721C2.20216 6.87498 2.15771 6.73609 2.15771 6.58054V4.09721C2.15771 3.85276 2.25771 3.66654 2.45771 3.53854C2.65771 3.41098 2.86883 3.39165 3.09105 3.48054L13.3577 7.81387C13.6355 7.93609 13.7744 8.14165 13.7744 8.43054C13.7744 8.71943 13.6355 8.92498 13.3577 9.0472L3.09105 13.3805Z"
          fill="white"
        />
      </G>
    </Icon>
  );
};

export default SendIcon;
