export const Discounts ={ 
    1: "Above 20%",
    2: "10% - 20%",
    3: "5% - 10%",
    4: "Below 5%",
  };

  export const DiscountsMapping ={ 
    1: {
      mindiscount:20,
      maxdiscount:100
    },
    2: {
      mindiscount:10,
      maxdiscount:20
    },
    3: {
      mindiscount:5,
      maxdiscount:10
    },
    4: {
      mindiscount:0,
      maxdiscount:5
    },
  };