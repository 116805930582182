import { useNavigation } from "@react-navigation/native";
import {
  Center,
  Hidden,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from "native-base";
import { useSelector } from "react-redux";
import PrimaryCTAButton from "../../../components/buttons/primaryCTAButton";
import { Pages } from "../../../constants/pages";

let getStartedSteps = [
  {
    heading: "The Beauty Quiz",
    description:
      "Everyone is unique! Different types and shades of Skin & Hair. We understand that picking the right product for you has been tedious, but not anymore. All you have to do is take the 4-point Beauty Quiz and discover all the products that are recommended for your unique skin and hair.",
  },
  {
    heading: "Product Recommendations",
    description: `Our product range is carefully selected to suit every skin and hair type: there's something for everyone at Flock. From Cleanse & Hydrate facial cleanser, Phakey's eye rollers and long-lasting eyeliners, to Kiss&Blush's mineral foundations and highlighters, discover the best beauty buys from the brands you love.`,
  },
  {
    heading: "Shop & Enjoy the Savings!",
    description: `The higher the cart value the higher the discount. So, what are you waiting for? Buy more, Save more. Discover a wide range of products and fill your bags now!`,
  },
];

const SecondSectionWeb = ({ quizCompleted }) => {
  const userId = useSelector((state) => state.user.id);
  const navigation = useNavigation();
  return (
    <>
      <VStack
        px="20px"
        alignItems={"center"}
        justifyContent="center"
        pt={{
          base: "50px",
          sm: "50px",
          md: "50px",
          lg: "50px",
          xl: "150px",
        }}
      >
        <Stack
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          }}
          space={6}
          alignItems="center"
          justifyContent={"center"}
        >
          <Image
            alt=""
            w={{
              base: "353px",
              sm: "353px",

              md: "353px",
              lg: "353px",
              xl: "45%",
            }}
            h={{
              base: "243px",
              sm: "243px",
              md: "243px",
              lg: "243px",
              xl: "600px",
            }}
            resizeMode="contain"
            resizeMethod="scale"
            fallbackSource={require("./../../../assets/images/pngs/web1.png")}
            source={require("./../../../assets/images/pngs/web1.png")}
          ></Image>
          <VStack flex={1} space={"38px"} justifyContent="center">
            <HStack alignItems={"center"} space={1}>
              <Image
                alt=""
                size={16}
                resizeMode="contain"
                resizeMethod="scale"
                fallbackSource={require("/assets/images/gifs/stars.gif")}
                source={require("/assets/images/gifs/stars.gif")}
              ></Image>
              <Text
                color={"#682481"}
                fontSize="48px"
                style={{
                  fontFamily: "AustinCyr_400",
                }}
              >
                3 Steps to Get Started:
              </Text>
            </HStack>
            {getStartedSteps?.map((k, i) => (
              <HStack alignItems={"center"} space={4}>
                <Text
                  opacity={0.4}
                  fontSize={"100px"}
                  color={"#682481"}
                  style={{
                    fontFamily: "AustinCyr_400",
                  }}
                >
                  {i + 1}
                </Text>
                <VStack flex={1}>
                  <Text
                    color={"#682481"}
                    fontSize={"36px"}
                    style={{
                      fontFamily: "AustinCyr_400",
                    }}
                  >
                    {k?.heading}
                  </Text>
                  <Text
                    color={"#464646"}
                    fontSize={"12px"}
                    style={{
                      fontFamily: "LeituraNewsRoman",
                    }}
                  >
                    {k?.description}
                  </Text>
                  {i === 0 && !quizCompleted && (
                    <Center w="287px" pt="10px">
                      <PrimaryCTAButton
                        onPress={() => {
                          if (userId) {
                            navigation.navigate(Pages.AboutYourSelfScreen);
                          } else {
                            navigation.navigate("Login");
                          }
                        }}
                        gradientType="2"
                      >
                        <Text
                          letterSpacing={2}
                          textTransform={"uppercase"}
                          fontSize={"16px"}
                          style={{
                            fontFamily: "Hero_700Bold",
                          }}
                          color="white"
                        >
                          ~Take the Beauty Quiz~
                        </Text>
                      </PrimaryCTAButton>
                    </Center>
                  )}
                </VStack>
              </HStack>
            ))}
          </VStack>
          {/* <Hidden only="xl">
            <Center
              my="50px"
              w="50%"
              h={"1px"}
              bg={{
                linearGradient: {
                  colors: ["#FFFFFF", "#BE54FF", "#FFFFFF"],
                  start: [0, 0],
                  end: [1, 0],
                },
              }}
            ></Center>
          </Hidden> */}
        </Stack>
        <Stack
          flexWrap={"wrap"}
          direction={{
            base: "column-reverse",
            sm: "column-reverse",
            md: "column-reverse",
            lg: "column-reverse",
            xl: "row",
          }}
          space={8}
          justifyContent="center"
        >
          <VStack
            w={{
              xl: "40%",
            }}
            justifyContent="center"
            space={"20px"}
          >
            <Text
              color={"#682481"}
              fontSize="48px"
              lineHeight={"60px"}
              style={{
                fontFamily: "AustinCyr_400",
              }}
            >
              Find people like you :{"\n"}
              Beauty Buddies
            </Text>
            <Text
              color={"#464646"}
              fontSize={"12px"}
              style={{
                fontFamily: "LeituraNewsRoman",
              }}
            >
              Connect with fellow users who share your unique beauty traits and
              interests. Whether you're looking for makeup tips, hair care
              recommendations, or skincare advice, Flock allows you to discover
              and connect with others who have the same skin type, skin shade,
              hair type, hair texture, interests, and beauty concerns as you.
            </Text>
          </VStack>
          <Hidden only={["xl", "xxl"]}>
            <Image
              alt=""
              w={{
                xl: "45%",
              }}
              h={"460px"}
              resizeMode="contain"
              resizeMethod="scale"
              fallbackSource={require("./../../../assets/images/pngs/web2.png")}
              source={require("./../../../assets/images/pngs/web2Mobile.png")}
            ></Image>
          </Hidden>
          <Hidden from="base" till="xxl">
            <Image
              alt=""
              w={{
                xl: "45%",
              }}
              h={"460px"}
              resizeMode="contain"
              resizeMethod="scale"
              fallbackSource={require("./../../../assets/images/pngs/web2.png")}
              source={require("./../../../assets/images/pngs/web2.png")}
            ></Image>
          </Hidden>
        </Stack>
        <Center
          mt="50px"
          w="50%"
          h={"1px"}
          bg={{
            linearGradient: {
              colors: ["#FFFFFF", "#BE54FF", "#FFFFFF"],
              start: [0, 0],
              end: [1, 0],
            },
          }}
        ></Center>
      </VStack>
    </>
  );
};

export default SecondSectionWeb;
