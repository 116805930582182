import { Icon } from "native-base";
import { Path, G, Defs, LinearGradient, Stop } from "react-native-svg";

const WebGradientCartIcon = () => {
  return (
    <Icon viewBox="0 0 24 21">
      <G>
        <Path
          d="M13.3176 11.7472H15.6288V8.50285H19.0841V6.33273H15.6288V3.09924H13.3176V6.33273H9.8623V8.50285H13.3176V11.7472Z"
          fill="url(#paint0_linear_45_14708)"
        />
        <Path
          d="M9.85135 16.0932H19.0963C19.329 16.0926 19.5562 16.0259 19.748 15.902C19.9397 15.7781 20.0872 15.6027 20.171 15.3988L23.4414 7.41272H20.9683L18.2989 13.9231H10.6256L5.43691 2.23697C5.26098 1.84099 4.96399 1.50286 4.58347 1.26531C4.20295 1.02776 3.75597 0.901455 3.29902 0.902348H0.606445V3.07247H3.29902L8.78819 15.4205C8.87488 15.6186 9.0221 15.7883 9.2113 15.908C9.4005 16.0277 9.6232 16.0921 9.85135 16.0932Z"
          fill="url(#paint1_linear_45_14708)"
        />
        <Path
          d="M10.4439 20.4337C11.4012 20.4337 12.1773 19.705 12.1773 18.8061C12.1773 17.9072 11.4012 17.1785 10.4439 17.1785C9.48653 17.1785 8.71045 17.9072 8.71045 18.8061C8.71045 19.705 9.48653 20.4337 10.4439 20.4337Z"
          fill="url(#paint2_linear_45_14708)"
        />
        <Path
          d="M18.3819 20.9024C19.3392 20.9024 20.1153 20.1737 20.1153 19.2748C20.1153 18.3759 19.3392 17.6472 18.3819 17.6472C17.4245 17.6472 16.6484 18.3759 16.6484 19.2748C16.6484 20.1737 17.4245 20.9024 18.3819 20.9024Z"
          fill="url(#paint3_linear_45_14708)"
        />

        <Defs>
          <LinearGradient
            id="paint0_linear_45_14708"
            x1="9.8623"
            y1="3.09924"
            x2="21.6094"
            y2="7.74558"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#691DE3" />
            <Stop offset="0.0001" stop-color="#691DE3" />
            <Stop offset="1" stop-color="#FF5A92" />
          </LinearGradient>
          <LinearGradient
            id="paint1_linear_45_14708"
            x1="0.606445"
            y1="0.902344"
            x2="26.2678"
            y2="15.2101"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#691DE3" />
            <Stop offset="0.0001" stop-color="#691DE3" />
            <Stop offset="1" stop-color="#FF5A92" />
          </LinearGradient>
          <LinearGradient
            id="paint2_linear_45_14708"
            x1="8.71045"
            y1="17.1785"
            x2="13.1282"
            y2="18.9236"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#691DE3" />
            <Stop offset="0.0001" stop-color="#691DE3" />
            <Stop offset="1" stop-color="#FF5A92" />
          </LinearGradient>
          <LinearGradient
            id="paint3_linear_45_14708"
            x1="16.6484"
            y1="17.6472"
            x2="21.0661"
            y2="19.3924"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stop-color="#691DE3" />
            <Stop offset="0.0001" stop-color="#691DE3" />
            <Stop offset="1" stop-color="#FF5A92" />
          </LinearGradient>
        </Defs>
      </G>
    </Icon>
  );
};

export default WebGradientCartIcon;
