import { Center, HStack, Modal, Text, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { setAnalytics } from "../../analytics";
import { goBackDialog } from "../../redux/currentState/actions";
import AccountDeleteButton from "../buttons/accountDeleteButton";
import GrayBorderButton from "../buttons/grayBorderButton";

const DeleteAccountModal = () => {
  const dispatch = useDispatch();
  const openDialog = useSelector((state) => state.currentState.openDialog);
  return (
    <Modal
      size="full"
      bg="black:alpha.80"
      isOpen={openDialog}
      onClose={() => dispatch(goBackDialog())}
    >
      <Modal.Content
        bg={"#2B2F33"}
        rounded={"20px"}
        width={{
          md: "300px",
          lg: "350px",
          xl: "400px",
        }}
      >
        <VStack py={6} space={6} px={6} w="full">
          <VStack space={2} w="full">
            <Text
              textAlign={"center"}
              fontSize={"28px"}
              style={{
                fontFamily: "HK_600SemiBold",
              }}
              color="#ffffff"
            >
              Do you want to delete this account?
            </Text>
            <Text
              textAlign={"center"}
              px={"20px"}
              fontSize={"16px"}
              style={{
                fontFamily: "HK_500Medium",
              }}
              color="#ffffff"
            >
              Your account will be deactivated and you'll be signed out.
            </Text>
          </VStack>
          <HStack width={"full"} justifyContent={"space-evenly"}>
            <Center w="48%" h="50px">
              <GrayBorderButton
                text="Cancel"
                onPress={async () => {
                  setAnalytics(
                    "CancelButton",
                    "Cancel Button Clicked From Delete Account Popup",
                    {
                      screen: "SettingsScreen",
                    }
                  );
                  dispatch(goBackDialog());
                }}
              />
            </Center>
            <AccountDeleteButton isModal={true} />
          </HStack>
        </VStack>
      </Modal.Content>
    </Modal>
  );
};

export default DeleteAccountModal;
