import FollowUserIconOutlined from "./../../../components/icons/followUserIconOutlined";
import GrayStarIconFilled from "./../../../components/icons/grayStarIconFilled";
import YellowStarIconFilled from "./../../../components/icons/yellowStarIconFilled";
import { useState } from "react";
import { Pressable, Center, HStack, Avatar, Text, VStack } from "native-base";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useGetProductReviews } from "../../../hooks/useGetProductReviews";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Pages } from "../../../constants/pages";
import { useOnFollow } from "../../../hooks/useOnFollow";
import GradientBorderButton from "../../../components/buttons/gradientBorderButton";
import { gradientTextWeb } from "../../../constants/gradient";
const Reviews = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { data } = useGetProductReviews(route?.params?.id);

  return (
    <>
      <VStack
        w="80%"
        borderWidth={"1px"}
        borderColor="#E9B9FF"
        borderRadius="20px"
        p={"40px"}
        space={10}
      >
        <HStack alignItems={"center"} justifyContent="space-between">
          <Text
            fontSize={"32px"}
            style={{
              fontFamily: "AustinCyr_400",
            }}
            _dark={{
              color: "#94A3B8",
            }}
            _light={{
              color: "#682481",
            }}
          >
            Customer reviews
          </Text>
          <Center h="50px" w="196px">
            <GradientBorderButton
              onPress={() =>
                navigation.navigate("rateandreview", {
                  id: route?.params?.id,
                })
              }
              gradientType="1"
            >
              <Text
                fontSize={"18px"}
                _web={{
                  style: {
                    ...gradientTextWeb(1),
                    fontFamily: "Hero_400Regular",
                  },
                }}
              >
                Write a review
              </Text>
            </GradientBorderButton>
          </Center>
        </HStack>
        <VStack space={8}>
          {data?.reviews.map((review, index) => {
            return <ReviewCard review={review} key={index} />;
          })}
        </VStack>
      </VStack>
    </>
  );
};

const ReviewCard = ({ review }) => {
  const navigation = useNavigation();
  const userId = useSelector((state) => state.user.id);
  const following = useSelector((state) => state.following[review?.id]);
  const onFollow = useOnFollow();

  return (
    <>
      <HStack justifyContent={"space-between"} alignItems={"center"}>
        <HStack space={8} alignItems={"center"}>
          <HStack space={2} alignItems={"center"}>
            <Pressable
              onPress={() => {
                if (review?.celeb) {
                  navigation.navigate(Pages.CelebScreen, {
                    id: review?.id,
                    username: review?.username,
                  });
                } else {
                  navigation.navigate(Pages.UserScreen, {
                    id: review?.id,
                    username: review?.username,
                  });
                }
              }}
            >
              <Avatar
                bg="#5E17EB"
                size={"sm"}
                fallbackSource={require("./../../../assets/images/pngs/profile-pic.png")}
                source={
                  review?.image.indexOf("http://") == 0 ||
                  review?.image.indexOf("https://") == 0
                    ? {
                        uri: review?.image,
                      }
                    : require("./../../../assets/images/pngs/profile-pic.png")
                }
                _text={{
                  textTransform: "uppercase",
                }}
              >
                {review?.username[0]}
              </Avatar>
            </Pressable>

            <VStack>
              {review.rating ? (
                <HStack space={1}>
                  {[...Array(review.rating)].map((item, index) => (
                    <YellowStarIconFilled key={index} />
                  ))}
                  {[...Array(5 - review.rating)].map((item, index) => (
                    <GrayStarIconFilled key={index} />
                  ))}
                </HStack>
              ) : (
                <></>
              )}
              <Text
                _light={{
                  color: "#464646",
                }}
                _dark={{
                  color: "#ffffff",
                }}
                _web={{
                  style: {
                    fontFamily: "Hero_400Regular",
                  },
                }}
                fontSize={14}
                textTransform="capitalize"
                style={{
                  fontFamily: "HK_500Medium",
                }}
              >
                {review?.username}
              </Text>
            </VStack>
          </HStack>
          {userId !== review?.user_id && (
            <Pressable onPress={() => onFollow(review?.id)}>
              <HStack alignItems={"center"} space={1}>
                {!following && <FollowUserIconOutlined />}
                <Text
                  color="#94A3B8"
                  fontSize={14}
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                >
                  {following ? "Following" : "Follow"}
                </Text>
              </HStack>
            </Pressable>
          )}
        </HStack>
      </HStack>
      <VStack space={2} px={10}>
        <HStack flexWrap={"wrap"} py={1} alignItems={"center"} space={2}>
          {[
            review.skin_type
              ? {
                  color: "#B2E9AA",
                  label: review.skin_type,
                }
              : null,
            review.hair_texture
              ? {
                  color: "#E7AAE9",
                  label: review.hair_texture,
                }
              : null,
            review.hair_type !== null &&
            review.hair_type?.filter((k, i) => k !== null).length > 0
              ? {
                  color: "#B2AAE9",
                  label:
                    review.hair_type?.filter((k, i) => k !== null).length > 0
                      ? review.hair_type
                      : `${review.hair_type}, `,
                }
              : null,
          ]
            ?.filter((f) => f)
            .map((detail, i) => (
              <Center
                px={"10px"}
                h="20px"
                bg={detail.color}
                rounded={"6px"}
                mt={1}
              >
                <Text
                  style={{
                    textTransform: "capitalize",
                    color: "black",
                    fontFamily: "HK_500Medium",
                    fontSize: 11,
                  }}
                >
                  {i === 0
                    ? detail.label
                        ?.split("\u21b5")
                        ?.join("")
                        ?.replace(/[\n\r\s]/g, " ")
                    : `${detail.label} hair`}
                </Text>
              </Center>
            ))}
        </HStack>
        <Text
          _light={{
            color: "#464646",
          }}
          _dark={{
            color: "#ffffff",
            opacity: "0.6",
          }}
          _web={{
            style: {
              fontFamily: "Hero_700Bold",
            },
          }}
          fontSize={14}
          lineHeight={20}
          style={{
            fontFamily: "HK_500Medium",
          }}
        >
          {review?.review}
        </Text>
      </VStack>
    </>
  );
};

export default Reviews;
