import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const SendOutlinedIcon = ({ onPress }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon onPress={onPress} viewBox="0 0 18 18">
        <G
          width="18"
          height="18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M16.6792 0.698078C16.5749 0.611518 16.4482 0.556371 16.3138 0.539061C16.1794 0.521751 16.0428 0.542992 15.9201 0.600308L1.18555 7.51639V8.85464L7.37435 11.3301L11.3441 17.4375H12.6826L16.9119 1.42732C16.9462 1.29621 16.9425 1.15805 16.9013 1.02894C16.8601 0.899831 16.7831 0.785088 16.6792 0.698078ZM11.8562 16.1611L8.44401 10.9114L13.6947 5.1605L12.8639 4.40196L7.57214 10.1977L2.46945 8.15665L15.5983 1.99404L11.8562 16.1611Z"
            fill="white"
          />
        </G>
      </Icon>
    </>
  );
};

export default SendOutlinedIcon;
