import "react-native-gesture-handler";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";
import * as Linking from "expo-linking";
import { navigationRef } from "./RootNavigation";
import { useEffect, useState } from "react";
import { getUser } from "./util/utilFunctions";
import { privateApi, publicApi } from "./util/API";
import { useDispatch } from "react-redux";
import { storeFollowing } from "./redux/following/actions";
import { storeWishlist } from "./redux/wishlist/actions";
import { recievedUser } from "./redux/user/actions";
import { Pages } from "./constants/pages";
import { NavigationContainer } from "@react-navigation/native";
import { firebase } from "@react-native-firebase/dynamic-links";
// Top bars
import BackButtonTopBar from "./components/bars/topBars/backButtonTopBar";
import TopBar from "./components/bars/navigationBars/topBar";
import SignUpTopBar from "./components/bars/topBars/signUpTopBar";
import OrderSuccessfulScreenTopBar from "./components/bars/topBars/orderSuccessfulScreenTopBar";
import CategoryScreenTopBar from "./components/bars/topBars/categoryScreenTopBar";
import SurveyScreenTopBar from "./components/bars/topBars/surveyScreenTopBar";
import UserScreenTopBar from "./components/bars/topBars/userScreenTopBar";
import ProductScreenTopBar from "./components/bars/topBars/productScreenTopBar";
import BackButtonAndCenterTextTopBar from "./components/bars/topBars/backButtonAndCenterTextTopBar";
import SearchTopBar from "./components/bars/searchTopBar";
//screens
import ShareIcon from "./components/icons/shareIcon";
import HomeScreen from "./screens/homeScreen/homeScreen";
import CartScreen from "./screens/cartScreen/cartScreen";
import SearchScreen from "./screens/searchScreen/searchScreen";
import CategoriesScreen from "./screens/categoriesScreen/categoriesScreen";
import SettingScreen from "./screens/settingScreens/settingScreen";
import AboutFlockScreen from "./screens/settingScreens/aboutFlockScreen";
import PrivacyPolicyScreen from "./screens/settingScreens/privacyPolicyScreen";
import FAQScreen from "./screens/settingScreens/faqScreen";
import HelpAndSupportScreen from "./screens/settingScreens/helpAndSupportScreen";
import NotificationsScreen from "./screens/notificationsScreen";
import Returns from "./screens/settingScreens/returnsPolicy";
import Shipping from "./screens/settingScreens/shippingPolicy";
import Terms from "./screens/settingScreens/termsAndConditions";
import Authenticity from "./screens/settingScreens/authenticity";
import UploadPictureScreen from "./screens/registration/uploadPictureScreen";
import VerifyScreen from "./screens/registration/verifyScreen";
import ForgetPasswordScreen from "./screens/forgetPasswordScreens/forgetPasswordScreen";
import BeautyBuddiesScreen from "./screens/beautyBuddiesScreens/beautyBuddiesScreen";
import SocialLinksScreen from "./screens/registration/socialLinksScreen";
import RegisterNumberScreen from "./screens/registration/numberScreen";
import PaymentMethodsScreen from "./screens/userScreen/buildingBlocks/paymentMethodsScreen";
import AddressesScreen from "./screens/userScreen/buildingBlocks/addressesScreen";
import PhoneEmailSignupScreen from "./screens/registration/phoneEmailSignupScreen";
import ProductScreen from "./screens/productScreen/productScreen";
import YourOrders from "./screens/userScreen/buildingBlocks/yourOrders";
import OrderSuccessfulScreen from "./screens/buyScreens/orderSuccessfulScreen";
import CongratsScreen from "./screens/buyScreens/congratsScreen";
import ProductsScreen from "./screens/productsScreen/productsScreen";
import FoundationScreen from "./screens/surveyScreens/foundationScreen";
import SkinTypeScreen from "./screens/surveyScreens/skinTypeScreen";
import UnderstandingYourHairScreen from "./screens/surveyScreens/understandingYourHairScreen";
import LetsRollScreen from "./screens/surveyScreens/letsRollScreen";
import SkillsScreen from "./screens/surveyScreens/skillsScreen";
import ScentsScreen from "./screens/surveyScreens/scentsScreen";
import YayScreen from "./screens/surveyScreens/yayScreen";
import GettingToKnowYourSkinScreen from "./screens/surveyScreens/gettingToKnowYourSkinScreen";
import SpecificIngredientsScreen from "./screens/surveyScreens/specificIngredientsScreen";
import PreferencesScreen from "./screens/surveyScreens/preferencesScreen";
import AboutYourSelfScreen from "./screens/surveyScreens/aboutYourSelfScreen";
import RegisterEmailScreen from "./screens/registration/emailScreen";
import Login from "./screens/login/login";
import RegisterPasswordScreen from "./screens/registration/passwordScreen";
import FindFriendsSearchScreen from "./screens/searchScreen/findFriendsSearchScreen";
import ProfileCuratedForYou from "./screens/profileScreen/curratedForYou";
import CelebScreen from "./screens/celebScreen/celebScreen";
import CurlyHairFeedScreen from "./screens/curlyHairFeed/curlyHairFeed";
import FlockDealExitGroupScreen from "./screens/buyScreens/flockDealExitGroupScreen";
import SignupOptionsScreen from "./screens/registration/signupOptionsScreen";
import RewardsScreen from "./screens/deckScreens/rewardsScreen";
import PhoneLoginScreen from "./screens/login/phoneLoginScreen";
import LevelBenefitScreen from "./screens/deckScreens/levelBenefitScreen";
import useGetVersionOfApp from "./hooks/useGetVersionOfApp.native";
import VersionModal from "./components/versionModal";
import { Platform } from "react-native";
import Constants from "expo-constants";
import UserScreen from "./screens/userScreen/userScreen";
import { useColorModeValue } from "native-base";
import { setAnalytics } from "./analytics";
import AffiliateScreen from "./screens/affiliateScreens";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setReferralID } from "./redux/currentState/actions";
import RewardsScreenTopBar from "./components/bars/topBars/rewardsScreenTopBar";
import collectionScreenProductCard from "./components/cards/collectionScreenProductCard";
import EditCollectionScreenProductCard from "./components/popUps/editCollectionScreenProductCard";
import TopBarWeb from "./components/bars/navigationBars/topBarWeb";
import ShopScreen from "./screens/shopScreen";
import Brands from "./screens/brands";
import FavoriteBrands from "./screens/surveyScreens/favoriteBrands";
import RateAndReview from "./screens/productScreen/buildingBlocks/rateAndReview";
import SavedAddresses from "./screens/addresses/savedAddresses";
import SavedAddressesHOC from "./hoc/SavedAddressesHOC";
import { Popups } from "./constants/popups";
import AddressPopUp from "./components/popUps/addressPopUp";
import ShippingAddressPopUp from "./components/popUps/shippingAddressPopUp";
import ShippingAddressModal from "./components/modals/shippingAddressModal";
import PaymentMethodPopUp from "./components/popUps/paymentMethodPopUp";
import CardsPopUp from "./components/popUps/cardsPopUp";
import Profile from "./screens/profile";
import Mainmenu from "./screens/Mainmenu";
import Shopmenu from "./screens/shopMenu";
import CommunityScreenWrapper from "./screens/communityScreen/communityScreenWrapper";
import CheckoutScreenWrapper from "./screens/buyScreens/checkoutScreenWrapper";
import CollectionModal from "./screens/userScreen/buildingBlocks/collectionModal";
import CuratedForYouScreen from "./screens/userScreen/buildingBlocks/curatedForYouScreen";

const Stack = createStackNavigator();
const prefix = Linking.createURL("/");

function Navigation() {
  const [openModal, setOpenModal] = useState(false);
  const { data } = useGetVersionOfApp();
  const dispatch = useDispatch();

  const setOpenLink = async () => {
    await AsyncStorage.setItem("firstLink", "true");
  };

  useEffect(() => {
    check();
  }, []);

  useEffect(() => {
    if (data?.android_version && Platform.OS === "android") {
      const version = parseInt(data?.android_version);
      if (version > Constants.expoConfig.android.versionCode) {
        setOpenModal(true);
      }
    }
    if (data?.ios_version && Platform.OS === "ios") {
      const version = parseInt(data?.ios_version);
      if (version > parseInt(Constants.expoConfig.ios.buildNumber)) {
        setOpenModal(true);
      }
    }
  }, [data]);

  const check = async () => {
    const userId = await getUser();

    if (userId !== null && userId !== undefined) {
      dispatch(recievedUser({ id: userId }));
      findUserDetails(userId);
    }
  };

  const findUserDetails = async (userId) => {
    let res = await privateApi(
      "/api/home/loggedin_home/",
      {
        user_id: userId,
        page: 1,
        v2: true,
      },
      "get"
    );

    if (res.status === 200 || res.status === "200") {
      dispatch(recievedUser({ id: userId }));
      dispatch(storeFollowing(res.body.data.following_ids));
      dispatch(storeWishlist(res.body.data.wishlist));
    }
  };

  const config = {
    screens: {
      // name of screen
      product:
        Platform.OS === "web"
          ? {
              path: "product/:id/:sku_id/:bundle_id?/:source?/:otherUserId?/:referralId?",
              parse: {
                id: (id) => `${id}`,
                sku_id: (sku_id) => `${sku_id}`,
                bundle_id: (bundle_id) => `${bundle_id}`,
                source: (source) => `${source}`,
                otherUserId: (otherUserId) => `${otherUserId}`,
                referralId: (referralId) => `${referralId}`,
              },
            }
          : {
              path: "product/:id",
              parse: {
                id: (id) => `${id}`,
              },
            },
      profile: {
        path: "profile/:id/:username/:referralId?",
        parse: {
          id: (id) => `${id}`,

          username: (username) => `${username}`,
        },
      },
      UserScreen: {
        path: "profile/:id",
        parse: {
          id: (id) => `${id}`,
        },
      },
      PrivacyPolicyScreen: {
        path: "PrivacyPolicyScreen",
      },
      Terms: {
        path: "Terms",
      },
    },
  };
  const getSearchParamFromURL = (url, param) => {
    const include = url.includes(param);

    if (!include) return null;

    const params = url.split(/([&,?,=])/);
    const index = params.indexOf(param);
    const value = params[index + 2];
    return value;
  };

  const linking = {
    prefixes: [
      prefix,
      "https://shoppingflock.page.link/",
      "http://shoppingflock.page.link/",
      "https://flock-5b35f.web.app/",
      "http://flock-5b35f.web.app/",
      "https://app.flockshopping.com/",
      "http://app.flockshopping.com/",
      "https://flockshopping.com/",
      "http://flockshopping.com/",
    ],
    config,

    async getInitialURL() {
      // If the app was opened with a Firebase Dynamic Link
      try {
        let dynamicLink = await firebase.dynamicLinks().getInitialLink();
        console.log("dyn", dynamicLink);
        if (dynamicLink) {
          const { url } = dynamicLink;
          const K = await AsyncStorage.getItem("firstLink");
          const data = JSON.parse(K);
          let k = getSearchParamFromURL(url, "referralId");
          dispatch(setReferralID({ referralId: k }));
          console.log("url", url, k);
          if (url !== null) {
            setAnalytics("Referred By", "Referred By Friend", {
              deepLink: url,
            });
          }
          let newUser = false;
          if (data === true) {
            newUser = true;
          } else {
            setOpenLink();
          }
          if (url !== null) {
            let res = await publicApi(
              "/api/points/referral_code/",
              {
                deep_link: url,
                new_user: newUser,
              },
              "post"
            );
          }
          return url;
        }
      } catch {}
      // If the app was opened with any other link (sometimes the Dynamic Link also ends up here, so it needs to be resolved
      const initialUrl = await Linking.getInitialURL();
      if (initialUrl) {
        try {
          const resolvedLink = await firebase
            .dynamicLinks()
            .resolveLink(initialUrl);
          return resolvedLink ? resolvedLink.url : initialUrl;
        } catch {
          return initialUrl;
        }
      }
      return "";
    },
    subscribe(listener) {
      const handleDynamicLink = (dynamicLink) => {
        listener(dynamicLink.url);
      };
      // Listen to incoming links from deep linking
      const unsubscribeToDynamicLinks = firebase
        .dynamicLinks()
        .onLink(handleDynamicLink);
      return () => {
        // Clean up the event listeners
        unsubscribeToDynamicLinks();
      };
    },
  };

  if (Platform.OS !== "web" && openModal) {
    return (
      <VersionModal
        open={openModal}
        setOpen={() => {
          setAnalytics("Update Version", `Updated Version`, {
            platform: Platform.OS,
            version:
              Platform.OS === "android"
                ? parseInt(data?.android_version)
                : parseInt(data?.ios_version),
          });
          const url =
            Platform.OS === "android"
              ? "https://play.google.com/store/apps/details?id=com.flock.shopping"
              : Platform.OS === "ios"
              ? "https://apps.apple.com/us/app/flock-shopping/id1638910085?itsct=apps_box_link&itscg=30200"
              : "https://flockshopping.com";
          Linking.openURL(url);
        }}
      />
    );
  }

  const backgroundColor = useColorModeValue("white", "#111315");
  var random_boolean = Math.random() < 0.5;

  useEffect(() => {
    const f = async () => {
      const k = await findAndSetFirst();
      if (!k) {
        redirect();
      }
    };
    f();
  }, []);

  const setFirst = async () => {
    await AsyncStorage.setItem("firstVisit", "true");
  };

  const findAndSetFirst = async () => {
    const K = await AsyncStorage.getItem("firstVisit");
    const data = JSON.parse(K);

    if (data === true) {
    } else {
      setFirst();
    }
    return data;
  };
  const redirect = async () => {
    if (random_boolean) {
      setAnalytics("Redirect To LoginScreen", "Redirected To LoginScreen");
      navigationRef.navigate("Login");
    } else {
      setAnalytics("Redirect To HomeScreen", "Redirected To HomeScreen");
      navigationRef.navigate("Home");
    }
  };

  return (
    <NavigationContainer
      ref={navigationRef}
      theme={{
        colors: {
          background: backgroundColor,
        },
      }}
      linking={linking}
    >
      <Stack.Navigator
        screenOptions={() => ({
          headerShown: false,
          title: "Flock",
          ...TransitionPresets.SlideFromRightIOS,
        })}
      >
        <Stack.Group>
          <Stack.Screen
            name={Pages.Home}
            options={{
              headerShown: false,
              header: (props) => <TopBar {...props} />,
              animationEnabled: false,
            }}
            component={HomeScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={"curated"}
            options={{
              headerShown: true,
              header: (props) => <TopBarWeb {...props} />,
              animationEnabled: true,
            }}
            component={CuratedForYouScreen}
          ></Stack.Screen>
          <Stack.Screen
            options={{
              animationEnabled: false,
              presentation: Platform.OS === "web" ? "transparentModal" : "card",
              headerShown: false,
            }}
            name={Pages.Login}
            component={Login}
          ></Stack.Screen>
          <Stack.Screen
            options={{
              animationEnabled: false,
              presentation: "transparentModal",
              headerShown: false,
            }}
            name={"rateandreview"}
            component={RateAndReview}
          ></Stack.Screen>
          <Stack.Screen
            options={{
              animationEnabled: false,
              presentation: "transparentModal",
              headerShown: false,
            }}
            name={"addcollection"}
            component={CollectionModal}
          ></Stack.Screen>
          <Stack.Screen
            options={{
              animationEnabled: false,
              presentation: "transparentModal",
              headerShown: false,
            }}
            name={"editcollection"}
            component={CollectionModal}
          ></Stack.Screen>
          <Stack.Screen
            options={{
              animationEnabled: false,
              presentation: "transparentModal",
              headerShown: false,
            }}
            name={Pages.savedaddresses}
            component={(props) => (
              <SavedAddressesHOC {...props}>
                <SavedAddresses {...props} />
              </SavedAddressesHOC>
            )}
          ></Stack.Screen>
          <Stack.Screen
            options={{
              animationEnabled: false,
              presentation: "transparentModal",
              headerShown: false,
            }}
            name={Popups.SavedAddressesPopup}
            component={(props) => (
              <SavedAddressesHOC {...props}>
                <AddressPopUp {...props} />
              </SavedAddressesHOC>
            )}
          ></Stack.Screen>
          <Stack.Screen
            options={{
              animationEnabled: false,
              presentation: "transparentModal",
              headerShown: false,
            }}
            name={Popups.AddAddress}
            component={ShippingAddressPopUp}
          ></Stack.Screen>
          <Stack.Screen
            options={{
              animationEnabled: false,
              presentation: "transparentModal",
              headerShown: false,
            }}
            name={"menu"}
            component={Mainmenu}
          ></Stack.Screen>
          <Stack.Screen
            options={{
              animationEnabled: false,
              presentation: "transparentModal",
              headerShown: false,
            }}
            name={"shopmenu"}
            component={Shopmenu}
          ></Stack.Screen>
          <Stack.Screen
            options={{
              animationEnabled: false,
              presentation: "transparentModal",
              headerShown: false,
            }}
            name={"address"}
            component={ShippingAddressModal}
          ></Stack.Screen>
          <Stack.Screen
            options={{
              animationEnabled: false,
              presentation: "transparentModal",
              headerShown: false,
            }}
            name={"addcard"}
            component={PaymentMethodPopUp}
          ></Stack.Screen>
          <Stack.Screen
            options={{
              animationEnabled: false,
              presentation: "transparentModal",
              headerShown: false,
            }}
            name={"savedcards"}
            component={CardsPopUp}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.Community}
            options={{
              headerShown: true,
              animationEnabled: false,
              header: (props) =>
                Platform.OS === "web" ? (
                  <TopBarWeb />
                ) : (
                  <BackButtonAndCenterTextTopBar
                    {...props}
                    centerText="Community"
                  />
                ),
            }}
            component={CommunityScreenWrapper}
          />
          <Stack.Screen
            name={"profile"}
            options={({ route }) => ({
              title: route.params.username,
              headerShown: true,
              animationEnabled: false,
              header: (props) => <TopBarWeb {...props} />,
            })}
            component={Profile}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.Brands}
            options={{
              headerShown: true,
              animationEnabled: false,
              header: (props) => <TopBarWeb {...props} />,
            }}
            component={Brands}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.UserScreen}
            options={{
              headerShown: true,
              header: (props) =>
                Platform.OS === "web" ? (
                  <TopBarWeb />
                ) : (
                  <UserScreenTopBar {...props} />
                ),
            }}
            component={UserScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.Products}
            options={{
              headerShown: true,
              header: (props) => <TopBar {...props} />,
              animationEnabled: false,
            }}
            component={CategoriesScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.Search}
            options={{
              headerShown: true,
              header: (props) => {
                if (Platform.OS === "web") {
                  return <TopBarWeb {...props} />;
                } else {
                  return <SearchTopBar {...props} />;
                }
              },
              animationEnabled: false,
            }}
            component={SearchScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.NotificationsScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar
                  {...props}
                  centerText="Notifications"
                />
              ),
            }}
            component={NotificationsScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.Cart}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar {...props} centerText="Cart" />
              ),
              animationEnabled: false,
            }}
            component={CartScreen}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.SettingScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar
                  {...props}
                  centerText={"Settings"}
                />
              ),
            }}
            component={SettingScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.AboutFlockScreen}
            options={{
              headerShown: true,
              header: (props) => {
                if (Platform.OS === "web") {
                  return <TopBarWeb {...props} />;
                } else {
                  return (
                    <BackButtonAndCenterTextTopBar
                      {...props}
                      centerText="About Flock"
                    />
                  );
                }
              },
            }}
            component={AboutFlockScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.PrivacyPolicyScreen}
            options={{
              headerShown: true,
              header: (props) => {
                if (Platform.OS === "web") {
                  return <TopBarWeb {...props} />;
                } else {
                  <BackButtonAndCenterTextTopBar
                    {...props}
                    centerText="Privacy Policy"
                  />;
                }
              },
            }}
            component={PrivacyPolicyScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.ReturnsPolicy}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar
                  {...props}
                  centerText="Returns Policy"
                />
              ),
            }}
            component={Returns}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.Authenticity}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar
                  {...props}
                  centerText="Authenticity"
                />
              ),
            }}
            component={Authenticity}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.ShippingPolicy}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar
                  {...props}
                  centerText="Shipping Policy"
                />
              ),
            }}
            component={Shipping}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.Terms}
            options={{
              headerShown: true,
              header: (props) => {
                if (Platform.OS === "web") {
                  return <TopBarWeb {...props} />;
                } else {
                  <BackButtonAndCenterTextTopBar
                    {...props}
                    centerText="Terms and conditions"
                  />;
                }
              },
            }}
            component={Terms}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.FAQScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar {...props} centerText="F.A.Q" />
              ),
            }}
            component={FAQScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.HelpAndSupportScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar
                  {...props}
                  centerText="Help & Support"
                />
              ),
            }}
            component={HelpAndSupportScreen}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.VerifyScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <SignUpTopBar {...props} progressBarFilled={40} />
              ),
            }}
            component={VerifyScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.ForgetPasswordScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <SignUpTopBar {...props} progressBarFilled={56} />
              ),
            }}
            component={ForgetPasswordScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.UploadPictureScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <SignUpTopBar {...props} progressBarFilled={80} />
              ),
            }}
            component={UploadPictureScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.BeautyBuddiesScreen}
            options={{
              headerShown: true,
              header: (props) =>
                Platform.OS === "web" ? (
                  <TopBarWeb {...props} />
                ) : (
                  <BackButtonTopBar {...props} icon={<ShareIcon />} />
                ),
            }}
            component={BeautyBuddiesScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.SignupOptionsScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <SignUpTopBar {...props} progressBarFilled={0} />
              ),
            }}
            component={SignupOptionsScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={"PhoneLoginScreen"}
            options={{
              headerShown: false,
              header: (props) => (
                <BackButtonTopBar {...props} icon={<ShareIcon />} />
              ),
            }}
            component={PhoneLoginScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.RegisterNumber}
            options={{
              headerShown: true,
              header: (props) => (
                <SignUpTopBar {...props} progressBarFilled={20} />
              ),
            }}
            component={RegisterNumberScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.SocialLinksScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <SignUpTopBar {...props} progressBarFilled={20} />
              ),
            }}
            component={SocialLinksScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.PaymentMethodsScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar
                  {...props}
                  centerText="Payment Methods"
                />
              ),
            }}
            component={PaymentMethodsScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.AddressesScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar
                  {...props}
                  centerText="Addresses"
                />
              ),
            }}
            component={(props) => (
              <SavedAddressesHOC {...props}>
                <AddressesScreen {...props} />
              </SavedAddressesHOC>
            )}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.PhoneEmailSignupScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <SignUpTopBar {...props} progressBarFilled={0} />
              ),
            }}
            component={PhoneEmailSignupScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.CheckoutScreen}
            options={{
              headerShown: true,
              header: (props) =>
                Platform.OS === "web" ? (
                  <TopBarWeb {...props} />
                ) : (
                  <BackButtonAndCenterTextTopBar
                    {...props}
                    centerText="Order Info"
                  />
                ),
            }}
            component={CheckoutScreenWrapper}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.ProductScreen}
            options={({ route }) => ({
              headerShown: true,

              header: (props) =>
                Platform.OS === "web" ? (
                  <TopBarWeb {...props} />
                ) : (
                  <ProductScreenTopBar {...props} />
                ),
            })}
            component={ProductScreen}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.Orders}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar
                  {...props}
                  centerText="Your orders"
                />
              ),
            }}
            component={YourOrders}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.CategoryScreen}
            options={{
              headerShown: true,
              header: (props) =>
                Platform.OS === "web" ? (
                  <TopBarWeb {...props} />
                ) : (
                  <CategoryScreenTopBar {...props} />
                ),
              animationEnabled: false,
            }}
            component={ProductsScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.OrderSuccessfulScreen}
            options={{
              headerShown: true,
              gestureEnabled: false,
              header: (props) =>
                Platform.OS === "web" ? (
                  <TopBarWeb {...props} />
                ) : (
                  <OrderSuccessfulScreenTopBar {...props} />
                ),
            }}
            component={OrderSuccessfulScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.CongratsScreen}
            options={{
              headerShown: false,
              gestureEnabled: false,
            }}
            component={CongratsScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.Skills}
            options={{
              headerShown: true,
              header: (props) => (
                <SurveyScreenTopBar {...props} progressBarFilled={"64"} />
              ),
            }}
            component={SkillsScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.Scents}
            options={{
              headerShown: true,
              header: (props) => (
                <SurveyScreenTopBar {...props} progressBarFilled={"72"} />
              ),
            }}
            component={ScentsScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={"SpecificIngredientsScreen"}
            options={{
              headerShown: true,
              header: (props) => (
                <SurveyScreenTopBar {...props} progressBarFilled={"80"} />
              ),
            }}
            component={SpecificIngredientsScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={"PreferencesScreen"}
            options={{
              headerShown: true,
              header: (props) => (
                <SurveyScreenTopBar {...props} progressBarFilled={"full"} />
              ),
            }}
            component={PreferencesScreen}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.Yay}
            options={{
              headerShown: true,
              header: (props) => <SurveyScreenTopBar {...props} />,
            }}
            component={YayScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={"GettingToKnowYourSkinScreen"}
            options={{
              headerShown: true,
              header: (props) => (
                <SurveyScreenTopBar {...props} progressBarFilled={"32"} />
              ),
            }}
            component={GettingToKnowYourSkinScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.Foundation}
            options={{
              headerShown: true,
              header: (props) => (
                <SurveyScreenTopBar {...props} progressBarFilled={"40"} />
              ),
            }}
            component={FoundationScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.SkinType}
            options={{
              headerShown: true,
              header: (props) => (
                <SurveyScreenTopBar {...props} progressBarFilled={"48"} />
              ),
            }}
            component={SkinTypeScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.UnderstandingHair}
            options={{
              headerShown: true,
              header: (props) => (
                <SurveyScreenTopBar {...props} progressBarFilled={"56"} />
              ),
            }}
            component={UnderstandingYourHairScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.LetsRoll}
            options={{
              headerShown: false,
            }}
            component={LetsRollScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.AboutYourSelfScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <SurveyScreenTopBar {...props} progressBarFilled={"10"} />
              ),
            }}
            component={AboutYourSelfScreen}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.RegisterEmail}
            options={{
              headerShown: true,
              header: (props) => (
                <SignUpTopBar {...props} progressBarFilled={20} />
              ),
            }}
            component={RegisterEmailScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.FavoriteBrands}
            options={{
              headerShown: true,
              header: (props) => (
                <SurveyScreenTopBar {...props} progressBarFilled={"full"} />
              ),
            }}
            component={FavoriteBrands}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.RegisterPassword}
            options={{
              headerShown: true,
              header: (props) => (
                <SignUpTopBar {...props} progressBarFilled={56} />
              ),
            }}
            component={RegisterPasswordScreen}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.CelebScreen}
            options={({ route }) => ({
              title: route.params.username,
              headerShown: true,
              header: (props) =>
                Platform.OS === "web" ? (
                  <TopBarWeb {...props} />
                ) : (
                  <BackButtonAndCenterTextTopBar
                    {...props}
                    centerText="Celeb"
                  />
                ),
              animationEnabled: false,
            })}
            component={CelebScreen}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.FindFriendsSearchScreen}
            options={{
              headerShown: false,
              header: (props) => <SearchTopBar {...props} />,
              animationEnabled: false,
            }}
            component={FindFriendsSearchScreen}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.CuratedForYouProfile}
            options={{
              headerShown: false,
              header: (props) => <BackButtonTopBar {...props} />,
              animationEnabled: false,
            }}
            component={ProfileCuratedForYou}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.FlockDealExitGroupScreen}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar
                  {...props}
                  centerText="Flock Deal group"
                />
              ),
            }}
            component={FlockDealExitGroupScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.ShopScreen}
            options={{
              headerShown: true,
              header: (props) => <TopBarWeb {...props} />,
              animationEnabled: false,
            }}
            component={ShopScreen}
          ></Stack.Screen>

          <Stack.Screen
            name={Pages.CurlyHairFeedScreen}
            options={{
              headerShown: true,
              header: (props) => <CurlyHairTopBar {...props} />,
              animationEnabled: false,
            }}
            component={CurlyHairFeedScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={"LevelBenefitScreen"}
            options={{
              headerShown: false,
            }}
            component={LevelBenefitScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.RewardsScreen}
            options={{
              header: (props) =>
                Platform.OS === "web" ? (
                  <TopBarWeb {...props} />
                ) : (
                  <RewardsScreenTopBar {...props} />
                ),
              headerShown: true,
            }}
            component={RewardsScreen}
          ></Stack.Screen>
          <Stack.Screen
            name={Pages.AffiliateScreen}
            options={{
              headerShown: false,
              header: (props) => <TopBar {...props} />,
              animationEnabled: false,
            }}
            component={AffiliateScreen}
          ></Stack.Screen>

          <Stack.Screen
            name={"Collection"}
            options={{
              headerShown: true,
              header: (props) => (
                <BackButtonAndCenterTextTopBar
                  {...props}
                  centerText="Collections"
                />
              ),
              animationEnabled: false,
            }}
            component={collectionScreenProductCard}
          ></Stack.Screen>
          <Stack.Screen
            name={"CreateCollection"}
            options={{
              headerShown: false,
              header: (props) => (
                <BackButtonAndCenterTextTopBar
                  {...props}
                  centerText="Create a Collection"
                />
              ),
              animationEnabled: false,
            }}
            component={EditCollectionScreenProductCard}
          />
        </Stack.Group>
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default Navigation;
