import {
  Box,
  Center,
  HStack,
  Pressable,
  Text,
  useColorModeValue,
} from "native-base";
import { Platform } from "react-native";

const NextButton = ({ onPress, yupButtonPressed }) => {
  const backgroundColor = useColorModeValue("white", "#111315");
  return (
    <>
      {Platform.OS === "web" ? (
        <Pressable onPress={onPress}>
          <Center
            py={"12px"}
            px={"16px"}
            h="40px"
            width={"100px"}
            rounded={"8px"}
            bg={{
              linearGradient: {
                colors: [
                  "primary.ctaLinearFirstColor",
                  "primary.ctaLinearSecondColor",
                ],
                start: [0, 0],
                end: [1, 0],
              },
            }}
          >
            <Text
              fontSize={14}
              style={{
                fontFamily: "Inter_400Regular",
              }}
              color="white"
            >
              Next
            </Text>
          </Center>
        </Pressable>
      ) : (
        <Pressable onPress={onPress}>
          <Box
            rounded={"full"}
            bg={{
              linearGradient: {
                colors: [
                  "primary.ctaLinearSecondColor",
                  "primary.ctaLinearFirstColor",
                ],
                start: [0, 0],
                end: [1, 0],
              },
            }}
          >
            <HStack
              bg={!yupButtonPressed && backgroundColor}
              py={"12px"}
              px={"16px"}
              rounded={"full"}
              width={"110px"}
              m="0.5"
              justifyContent={"space-around"}
              alignItems="center"
            >
              <Text
                fontSize={16}
                style={{
                  fontFamily: "Poppins_500Medium",
                }}
                color="#94A3B8"
                lineHeight={24}
              >
                Next
              </Text>
            </HStack>
          </Box>
        </Pressable>
      )}
    </>
  );
};

export default NextButton;
