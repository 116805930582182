import {
  HStack,
  VStack,
  Text,
  Image,
  Divider,
  PresenceTransition,
  Pressable,
  useColorModeValue,
} from "native-base";
import { useState } from "react";
import MinusIcon from "../icons/minusIcon";
import PlusIcon from "./../icons/plusIcon";
import ProcessingChip from "../chips/processingChip";
import ShippedChip from "../chips/shippedChip";
import DeliveredChip from "../chips/deliveredChip";
import { convertUnixToDate } from "../../util/utilFunctions";

const ChipMatching = {
  processing: <ProcessingChip text="Placed" />,
  placed: <ProcessingChip text="Placed" />,
  shipping: <ShippedChip />,
  delivered: <DeliveredChip />,
};

const OrderCard = ({ order }) => {
  const chip = ChipMatching[order.status];
  const [showOrderDetails, setShowOrderDetails] = useState();

  const iconColor = useColorModeValue("#682481", "#94A3B8");
  return (
    <>
      <Pressable
        onPress={() => {
          setShowOrderDetails(!showOrderDetails);
        }}
      >
        <VStack
          rounded={"xl"}
          p={6}
          space={5}
          _light={{
            bg: "white",
            borderColor: "#E9B9FF",
            borderWidth: "1px",
          }}
          _dark={{
            bg: "primary.card",
          }}
          borderWidth="1px"
        >
          <VStack space={3}>
            <VStack space={4}>
              {showOrderDetails ? (
                <MinusIcon size={3} color={iconColor} />
              ) : (
                <PlusIcon size={3} color={iconColor} />
              )}

              <HStack alignItems={"center"} justifyContent="space-between">
                <Text
                  fontSize={18}
                  style={{ fontFamily: "HK_600SemiBold" }}
                  lineHeight={18}
                  _light={{
                    color: "#464646",
                  }}
                  _dark={{
                    color: "white",
                  }}
                >
                  Order ID {order?.id}
                </Text>
                {chip}
              </HStack>
            </VStack>
            <HStack alignItems={"center"} justifyContent="space-between">
              <Text
                style={{ fontFamily: "HK_600SemiBold" }}
                fontSize={12}
                _light={{
                  color: "#8F8F8F",
                }}
                _dark={{
                  color: "#94A3B8",
                }}
              >
                {convertUnixToDate(order?.date)}
              </Text>
              <Text
                style={{ fontFamily: "HK_600SemiBold" }}
                fontSize={12}
                _light={{
                  color: "#8F8F8F",
                }}
                _dark={{
                  color: "#94A3B8",
                }}
              >
                Order value:{`  `}
                <Text
                  style={{ fontFamily: "HK_600SemiBold" }}
                  fontSize={14}
                  _light={{
                    color: "#464646",
                  }}
                  _dark={{
                    color: "white",
                  }}
                >
                  ${order?.selling_price?.toFixed(2)}
                </Text>
              </Text>
            </HStack>
          </VStack>

          {showOrderDetails && (
            <PresenceTransition
              visible={showOrderDetails}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 500,
                },
              }}
            >
              <VStack space={3}>
                <HStack alignItems={"center"} space={2}>
                  <Image
                    size={"40px"}
                    rounded={"8px"}
                    resizeMethod="scale"
                    resizeMode="stretch"
                    source={{
                      uri: order?.product_image,
                    }}
                    alt=""
                  />
                  <Text
                    style={{ fontFamily: "HK_600SemiBold" }}
                    fontSize={16}
                    lineHeight={16}
                    _light={{
                      color: "#464646",
                    }}
                    _dark={{
                      color: "white",
                    }}
                    noOfLines={1}
                    width={"80%"}
                  >
                    {order?.product_name}
                    {"\n"}
                  </Text>
                </HStack>
                <Divider bg={"#EBEBF0"} />
              </VStack>
            </PresenceTransition>
          )}
        </VStack>
      </Pressable>
    </>
  );
};

export default OrderCard;
