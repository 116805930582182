import { Center, Icon, Image } from "native-base";
import { Path, G, Rect } from "react-native-svg";
import { useSelector } from "react-redux";
import { IconButton } from "native-base";
import useGetCartItems from "../../hooks/useGetCartItems";
import { useDeleteFromCart } from "../../hooks/useDeleteFromCart";
import { useAddItemToCart } from "../../hooks/useAddItemToCart";
import WebGradientCartIcon from "./webIcons/webGradientCartIcon";

const CartIcon = ({
  productSkuId,
  type = "bag",
  productId,
  bundleId,
  source,
  otherUserId,
  referralId,
}) => {
  const deleteCart = useDeleteFromCart();
  const addItemToCart = useAddItemToCart();
  const userId = useSelector((state) => state.user.id);
  const { data } = useGetCartItems(userId);
  const inCart =
    data?.length > 0 &&
    data?.map((i) => i?.product_sku?.id).find((k) => k === productSkuId);

  const onPress = () => {
    if (inCart) {
      deleteCart(productSkuId);
    } else {
      addItemToCart(
        productSkuId,
        productId,
        bundleId,
        source,
        otherUserId,
        referralId
      );
    }
  };

  const IconType = () => {
    if (type === "outlined") {
      return <CartIconOutlined />;
    } else if (type === "borderCart") {
      if (inCart) {
        return <ActiveCartBorderIcon />;
      } else {
        return <CartPlusIconOutlined />;
      }
    } else if (type === "cart") {
      if (inCart) {
        return <ActiveCartIcon />;
      } else {
        return <CartOutlinedIcon />;
      }
    } else if (type === "gradient") {
      if (inCart) {
        return <CartGradientIcon />;
      } else {
        return <CartGradientIcon />;
      }
    } else {
      return <BagIcon />;
    }
  };

  return <IconButton icon={<IconType />} onPress={() => onPress()} />;
};

export const CartPlusIconOutlined = () => {
  return (
    <Icon size={"40px"} viewBox="0 0 40 41">
      <G width="40" height="41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Rect
          x="1"
          y="1.5"
          width="38"
          height="38"
          rx="9"
          stroke="#58595B"
          stroke-width="2"
        />
        <Path
          d="M19.5384 21.3333H21.3135V18.8417H23.9672V17.175H21.3135V14.6917H19.5384V17.175H16.8848V18.8417H19.5384V21.3333Z"
          fill="#94A3B8"
        />
        <Path
          d="M16.8755 24.6667H23.9757C24.1544 24.6662 24.3289 24.615 24.4762 24.5198C24.6235 24.4247 24.7367 24.29 24.8011 24.1333L27.3127 18H25.4134L23.3633 23H17.4702L13.4852 14.025C13.3501 13.7209 13.122 13.4612 12.8298 13.2788C12.5375 13.0963 12.1942 12.9993 11.8433 13H9.77539V14.6667H11.8433L16.059 24.15C16.1256 24.3022 16.2387 24.4325 16.384 24.5244C16.5293 24.6163 16.7003 24.6659 16.8755 24.6667Z"
          fill="#94A3B8"
        />
        <Path
          d="M17.3313 28C18.0665 28 18.6626 27.4404 18.6626 26.75C18.6626 26.0596 18.0665 25.5 17.3313 25.5C16.596 25.5 16 26.0596 16 26.75C16 27.4404 16.596 28 17.3313 28Z"
          fill="#94A3B8"
        />
        <Path
          d="M23.5442 28C24.2794 28 24.8754 27.4404 24.8754 26.75C24.8754 26.0596 24.2794 25.5 23.5442 25.5C22.8089 25.5 22.2129 26.0596 22.2129 26.75C22.2129 27.4404 22.8089 28 23.5442 28Z"
          fill="#94A3B8"
        />
      </G>
    </Icon>
  );
};

export const CartPlusIcon = () => {
  return (
    <Icon size={"18px"} viewBox="0 0 16 17">
      <G width="16" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M8.24767 9.66691H9.56561V7.67355H11.5359V6.3402H9.56561V4.35352H8.24767V6.3402H6.27734V7.67355H8.24767V9.66691Z"
          fill="#94A3B8"
        />
        <Path
          d="M6.27177 12.3334H11.5435C11.6763 12.333 11.8058 12.2921 11.9152 12.216C12.0245 12.1398 12.1086 12.0321 12.1564 11.9068L14.0213 7.00005H12.6111L11.0888 11.0001H6.71328L3.7545 3.82001C3.65418 3.57672 3.48483 3.36897 3.26784 3.22301C3.05085 3.07706 2.79597 2.99945 2.5354 3H1V4.33335H2.5354L5.66551 11.9201C5.71495 12.0418 5.7989 12.1461 5.90679 12.2196C6.01468 12.2932 6.14167 12.3328 6.27177 12.3334Z"
          fill="#94A3B8"
        />
        <Path
          d="M6.60955 15C7.15546 15 7.59801 14.5523 7.59801 14C7.59801 13.4477 7.15546 13 6.60955 13C6.06364 13 5.62109 13.4477 5.62109 14C5.62109 14.5523 6.06364 15 6.60955 15Z"
          fill="#94A3B8"
        />
        <Path
          d="M11.2228 15C11.7687 15 12.2113 14.5523 12.2113 14C12.2113 13.4477 11.7687 13 11.2228 13C10.6769 13 10.2344 13.4477 10.2344 14C10.2344 14.5523 10.6769 15 11.2228 15Z"
          fill="#94A3B8"
        />
      </G>
    </Icon>
  );
};

export const CartIconOutlined = () => {
  return (
    <Icon size={6} viewBox="0 0 26 26">
      <G width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M22.5868 7.90792C22.4352 7.70208 22.1968 7.58292 21.9368 7.58292L8.57935 7.59375L8.41685 6.75958C8.15685 5.36208 6.92185 4.34375 5.49185 4.34375H2.97852C2.53435 4.34375 2.16602 4.70125 2.16602 5.15625C2.16602 5.60042 2.53435 5.96875 2.97852 5.96875H5.49185C6.14185 5.96875 6.70518 6.42375 6.82435 7.06292L7.10602 8.53625L7.11685 8.57958L8.37352 16.1196C8.43852 16.5096 8.78518 16.8021 9.17518 16.8021H18.1343C19.456 16.8021 20.6368 15.9137 21.0052 14.6354L22.7168 8.62292C22.7927 8.37375 22.7385 8.11375 22.5868 7.90792ZM19.4343 14.1912C19.2718 14.7762 18.741 15.1771 18.1343 15.1771H9.86851L8.87185 9.21875L20.8643 9.20792L19.4343 14.1912Z"
          fill="#94A3B8"
        />
        <Path
          d="M10.8327 22.7493C12.0293 22.7493 12.9993 21.7793 12.9993 20.5827C12.9993 19.3861 12.0293 18.416 10.8327 18.416C9.63607 18.416 8.66602 19.3861 8.66602 20.5827C8.66602 21.7793 9.63607 22.7493 10.8327 22.7493Z"
          fill="#94A3B8"
        />
        <Path
          d="M17.8737 22.7601C19.0703 22.7601 20.0404 21.79 20.0404 20.5934C20.0404 19.3968 19.0703 18.4268 17.8737 18.4268C16.6771 18.4268 15.707 19.3968 15.707 20.5934C15.707 21.79 16.6771 22.7601 17.8737 22.7601Z"
          fill="#94A3B8"
        />
      </G>
    </Icon>
  );
};
export const CartGradientIcon = () => {
  return (
    <Center bg="#FAF1FF" size={"30px"} rounded="10px">
      <WebGradientCartIcon />
    </Center>
  );
};

const BagIcon = () => {
  return (
    <Icon viewBox="0 0 11 11">
      <G width="11" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M10.3337 9.37342L9.66838 2.7823C9.64879 2.58066 9.56228 2.39425 9.42561 2.25916C9.28893 2.12407 9.11182 2.04993 8.92857 2.05109H7.36986C7.28028 1.47699 7.01033 0.956356 6.60766 0.581078C6.20499 0.205799 5.69548 0 5.16904 0C4.64261 0 4.13309 0.205799 3.73042 0.581078C3.32775 0.956356 3.0578 1.47699 2.96823 2.05109H1.40951C1.22627 2.04993 1.04915 2.12407 0.912478 2.25916C0.775803 2.39425 0.689292 2.58066 0.669702 2.7823L0.00433968 9.37342C-0.0069294 9.48914 0.00380789 9.60619 0.0358612 9.71704C0.0679145 9.8279 0.120574 9.9301 0.190455 10.0171C0.260103 10.1033 0.345453 10.1721 0.440887 10.2192C0.536322 10.2662 0.639677 10.2903 0.744148 10.29H9.59394C9.69841 10.2903 9.80176 10.2662 9.8972 10.2192C9.99263 10.1721 10.078 10.1033 10.1476 10.0171C10.2175 9.9301 10.2702 9.8279 10.3022 9.71704C10.3343 9.60619 10.345 9.48914 10.3337 9.37342ZM5.16904 0.815259C5.49906 0.815956 5.81957 0.937594 6.08052 1.16117C6.34147 1.38476 6.52816 1.69769 6.61144 2.05109H3.72665C3.80992 1.69769 3.99661 1.38476 4.25756 1.16117C4.51852 0.937594 4.83903 0.815956 5.16904 0.815259ZM0.744148 9.46611L1.40951 2.87498H2.93566V4.11082C2.93566 4.22007 2.97487 4.32485 3.04468 4.40211C3.11449 4.47936 3.20917 4.52276 3.30789 4.52276C3.40661 4.52276 3.50129 4.47936 3.5711 4.40211C3.6409 4.32485 3.68012 4.22007 3.68012 4.11082V2.87498H6.65797V4.11082C6.65797 4.22007 6.69718 4.32485 6.76699 4.40211C6.8368 4.47936 6.93147 4.52276 7.0302 4.52276C7.12892 4.52276 7.2236 4.47936 7.2934 4.40211C7.36321 4.32485 7.40243 4.22007 7.40243 4.11082V2.87498H8.92857L9.59394 9.46611H0.744148Z"
          fill="#888990"
        />
      </G>
    </Icon>
  );
};

const CartOutlinedIcon = () => {
  return (
    <Icon viewBox="0 0 19 16">
      <G width="19" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M10.1715 8.68141H12.0208V6.08544H14.7856V4.34901H12.0208V1.76172H10.1715V4.34901H7.40674V6.08544H10.1715V8.68141Z"
          fill="#94A3B8"
        />
        <Path
          d="M7.39735 12.155H14.7947C14.981 12.1545 15.1627 12.1012 15.3162 12.0021C15.4696 11.9029 15.5876 11.7626 15.6546 11.5994L18.2715 5.20931H16.2927L14.1567 10.4186H8.01688L3.86512 1.06791C3.72435 0.751065 3.48671 0.480509 3.18224 0.290432C2.87776 0.100355 2.52011 -0.000711182 2.15448 3.76709e-06H0V1.73644H2.15448L6.54666 11.6167C6.61602 11.7753 6.73382 11.911 6.88521 12.0068C7.0366 12.1026 7.2148 12.1542 7.39735 12.155Z"
          fill="#94A3B8"
        />
        <Path
          d="M7.87187 15.6242C8.63789 15.6242 9.25887 15.0411 9.25887 14.3219C9.25887 13.6026 8.63789 13.0195 7.87187 13.0195C7.10585 13.0195 6.48486 13.6026 6.48486 14.3219C6.48486 15.0411 7.10585 15.6242 7.87187 15.6242Z"
          fill="#94A3B8"
        />
        <Path
          d="M14.2244 15.9992C14.9904 15.9992 15.6114 15.4161 15.6114 14.6969C15.6114 13.9776 14.9904 13.3945 14.2244 13.3945C13.4584 13.3945 12.8374 13.9776 12.8374 14.6969C12.8374 15.4161 13.4584 15.9992 14.2244 15.9992Z"
          fill="#94A3B8"
        />
      </G>
    </Icon>
  );
};

const ActiveCartBorderIcon = () => {
  return (
    <Icon size={"40px"} viewBox="0 0 40 41">
      <G
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Rect
          x="1.16211"
          y="1.25"
          width="38.2861"
          height="38.5"
          rx="9.25"
          stroke="#58595B"
          stroke-width="1.5"
        />
        <Path
          d="M17.1969 24.6667H24.2591C24.4369 24.6662 24.6104 24.615 24.7569 24.5198C24.9034 24.4247 25.0161 24.29 25.0801 24.1333L27.5783 18H25.6892L23.65 23H17.7884L13.8247 14.025C13.6904 13.7209 13.4635 13.4612 13.1728 13.2788C12.8821 13.0963 12.5407 12.9993 12.1916 13H10.1348V14.6667H12.1916L16.3848 24.15C16.451 24.3022 16.5635 24.4325 16.708 24.5244C16.8525 24.6163 17.0226 24.6659 17.1969 24.6667Z"
          fill="#0F9737"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.934 12.7526C27.3445 13.1195 27.3815 13.7516 27.0166 14.1644L21.7118 20.1644C21.527 20.3734 21.2636 20.4951 20.9854 20.4999C20.7073 20.5047 20.4399 20.3922 20.2481 20.1897L17.5957 17.3897C17.2168 16.9897 17.2322 16.3568 17.63 15.9759C18.0278 15.595 18.6574 15.6104 19.0362 16.0104L20.9431 18.0234L25.5297 12.8356C25.8947 12.4229 26.5234 12.3857 26.934 12.7526Z"
          fill="#0F9737"
        />
        <Path
          d="M17.6503 28C18.3816 28 18.9745 27.4404 18.9745 26.75C18.9745 26.0596 18.3816 25.5 17.6503 25.5C16.919 25.5 16.3262 26.0596 16.3262 26.75C16.3262 27.4404 16.919 28 17.6503 28Z"
          fill="#0F9737"
        />
        <Path
          d="M23.83 28C24.5613 28 25.1542 27.4404 25.1542 26.75C25.1542 26.0596 24.5613 25.5 23.83 25.5C23.0987 25.5 22.5059 26.0596 22.5059 26.75C22.5059 27.4404 23.0987 28 23.83 28Z"
          fill="#0F9737"
        />
      </G>
    </Icon>
  );
};

const ActiveCartIcon = () => {
  return (
    <Icon viewBox="0 0 18 16">
      <G width="18" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M7.19693 12.6667H14.2591C14.4369 12.6662 14.6104 12.615 14.7569 12.5198C14.9034 12.4247 15.0161 12.29 15.0801 12.1333L17.5783 6.00001H15.6892L13.65 11H7.78838L3.82475 2.02501C3.69036 1.7209 3.46349 1.46121 3.17281 1.27877C2.88213 1.09633 2.54068 0.999328 2.19162 1.00001H0.134766V2.66668H2.19162L6.38478 12.15C6.45101 12.3022 6.56347 12.4325 6.708 12.5244C6.85252 12.6164 7.02265 12.6659 7.19693 12.6667Z"
          fill="#0F9737"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.9338 0.752601C17.3444 1.11952 17.3813 1.75159 17.0164 2.16437L11.7116 8.16437C11.5268 8.37339 11.2634 8.49506 10.9852 8.49986C10.7071 8.50467 10.4397 8.39217 10.2479 8.18966L7.59549 5.38967C7.21664 4.98973 7.23199 4.35676 7.62979 3.97587C8.02758 3.59499 8.65717 3.61042 9.03602 4.01036L10.9429 6.02336L15.5296 0.835647C15.8945 0.422864 16.5232 0.385683 16.9338 0.752601Z"
          fill="#0F9737"
        />
        <Path
          d="M7.6506 16C8.38192 16 8.97476 15.4404 8.97476 14.75C8.97476 14.0597 8.38192 13.5 7.6506 13.5C6.91929 13.5 6.32645 14.0597 6.32645 14.75C6.32645 15.4404 6.91929 16 7.6506 16Z"
          fill="#0F9737"
        />
        <Path
          d="M13.83 16C14.5613 16 15.1541 15.4404 15.1541 14.75C15.1541 14.0597 14.5613 13.5 13.83 13.5C13.0987 13.5 12.5058 14.0597 12.5058 14.75C12.5058 15.4404 13.0987 16 13.83 16Z"
          fill="#0F9737"
        />
      </G>
    </Icon>
  );
};

export default CartIcon;
