import { Icon } from "native-base";
import { Path, G, Rect, ClipPath, Defs } from "react-native-svg";

const ExclaimationBlueEllipseIcon = ({ size }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={size} viewBox="0 0 20 20">
        <G
          width="20"
          height="20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <G clip-path="url(#clip0_2156_46012)">
            <Path
              d="M12.584 1.0875C12.2475 0.743175 11.8456 0.469576 11.4019 0.282779C10.9582 0.0959817 10.4816 -0.000244141 10.0002 -0.000244141C9.51879 -0.000244141 9.04223 0.0959817 8.59852 0.282779C8.15482 0.469576 7.75293 0.743175 7.41646 1.0875L6.63896 1.885L5.52646 1.87125C5.0449 1.86555 4.56706 1.9562 4.12104 2.13786C3.67502 2.31952 3.26983 2.58853 2.92929 2.92907C2.58875 3.26961 2.31974 3.67481 2.13807 4.12083C1.95641 4.56685 1.86577 5.04469 1.87146 5.52625L1.88396 6.63875L1.08896 7.41625C0.74464 7.75271 0.47104 8.1546 0.284243 8.59831C0.0974465 9.04201 0.0012207 9.51858 0.0012207 10C0.0012207 10.4814 0.0974465 10.958 0.284243 11.4017C0.47104 11.8454 0.74464 12.2473 1.08896 12.5838L1.88521 13.3612L1.87146 14.4738C1.86577 14.9553 1.95641 15.4332 2.13807 15.8792C2.31974 16.3252 2.58875 16.7304 2.92929 17.0709C3.26983 17.4115 3.67502 17.6805 4.12104 17.8621C4.56706 18.0438 5.0449 18.1344 5.52646 18.1287L6.63896 18.1163L7.41646 18.9112C7.75293 19.2556 8.15482 19.5292 8.59852 19.716C9.04223 19.9028 9.51879 19.999 10.0002 19.999C10.4816 19.999 10.9582 19.9028 11.4019 19.716C11.8456 19.5292 12.2475 19.2556 12.584 18.9112L13.3615 18.115L14.474 18.1287C14.9555 18.1344 15.4334 18.0438 15.8794 17.8621C16.3254 17.6805 16.7306 17.4115 17.0711 17.0709C17.4117 16.7304 17.6807 16.3252 17.8624 15.8792C18.044 15.4332 18.1347 14.9553 18.129 14.4738L18.1165 13.3612L18.9115 12.5838C19.2558 12.2473 19.5294 11.8454 19.7162 11.4017C19.903 10.958 19.9992 10.4814 19.9992 10C19.9992 9.51858 19.903 9.04201 19.7162 8.59831C19.5294 8.1546 19.2558 7.75271 18.9115 7.41625L18.1152 6.63875L18.129 5.52625C18.1347 5.04469 18.044 4.56685 17.8624 4.12083C17.6807 3.67481 17.4117 3.26961 17.0711 2.92907C16.7306 2.58853 16.3254 2.31952 15.8794 2.13786C15.4334 1.9562 14.9555 1.86555 14.474 1.87125L13.3615 1.88375L12.584 1.08875V1.0875Z"
              fill="#1A94FF"
            />
            <Path
              d="M13.0891 7.3077C13.0891 9.53299 10.6165 9.38188 10.6165 10.9203V10.9287C10.6165 11.1061 10.4727 11.25 10.2952 11.25H8.94611C8.76863 11.25 8.62477 11.1061 8.62477 10.9287V10.8379C8.62477 8.46153 10.7951 8.61264 10.7951 7.5C10.7951 7.01923 10.438 6.73077 9.84731 6.73077C9.36679 6.73077 8.87575 6.94088 8.42937 7.37031C8.31498 7.48036 8.13611 7.48731 8.0111 7.38951L7.12388 6.69556C6.97436 6.57862 6.95701 6.357 7.0892 6.22073C7.82991 5.45714 8.79243 5 10.0671 5C12.0863 5.00002 13.0891 6.08518 13.0891 7.3077ZM10.8912 13.75C10.8912 14.4368 10.3418 15 9.64125 15C8.95443 15 8.39125 14.4368 8.39125 13.75C8.39125 13.0632 8.95443 12.5 9.64125 12.5C10.3418 12.5 10.8912 13.0632 10.8912 13.75Z"
              fill="white"
            />
          </G>
          <Defs>
            <ClipPath id="clip0_2156_46012">
              <Rect width="20" height="20" fill="white" />
            </ClipPath>
          </Defs>
        </G>
      </Icon>
    </>
  );
};

export default ExclaimationBlueEllipseIcon;
