import { Box, FlatList } from "native-base";
import ClaimedRewardsCard from "../../components/cards/claimedRewardsCard";

const ClaimedRewards = ({ data }) => {
  const renderItem = ({ item }) => (
    <ClaimedRewardsCard title={item.action_name} />
  );
  const getItemLayout = (data, index) => ({
    length: 114,
    offset: 114 * index,
    index,
  });

  return (
    <>
      <FlatList
        contentContainerStyle={{
          height: 300,

          alignItems: "center",
        }}
        showsVerticalScrollIndicator={false}
        pb={"20px"}
        px={"10px"}
        ItemSeparatorComponent={() => (
          <Box style={{ paddingVertical: 6 }}></Box>
        )}
        data={data ? data : []}
        initialNumToRender={5}
        renderItem={renderItem}
        getItemLayout={getItemLayout}
      />
    </>
  );
};

export default ClaimedRewards;
