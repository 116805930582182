import { useDispatch } from "react-redux";
import { privateApi } from "../util/API";
import { addToFollowing } from "../redux/following/actions";
import {
  hideLoader,
  setDialog,
  showLoader,
} from "../redux/currentState/actions";
import { Popups } from "../constants/popups";
import { useSWRConfig } from "swr";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
import * as RootNavigation from "./../RootNavigation";
import { Platform } from "react-native";
import { Pages } from "../constants/pages";
export const useFollowUser = () => {
  const navigation = RootNavigation;
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const followUser = async (userId, followingId) => {
    dispatch(showLoader());
    if (userId !== null && userId !== undefined) {
      let res = await privateApi(
        "/api/followers/follower/",
        { follower: userId, following: followingId },
        "post"
      );

      if (res.status === 201) {
        let followers = "followers";
        let following = "following";

        mutate(
          `${apiUrl}/api/followers/get_followers_list/?user_id=${userId}&query=${followers}`
        );
        mutate(
          `${apiUrl}/api/followers/get_followers_list/?user_id=${userId}&query=${following}`
        );
        mutate(`${apiUrl}/api/users/get_user_details/?user_id=${userId}`);
        mutate(
          `${apiUrl}/api/users/get_person_details/?user_id=${followingId}`
        );

        dispatch(addToFollowing(followingId));
      } else {
      }
    } else {
      if (Platform.OS === "web") {
        navigation.navigate(Pages.Login);
      } else {
        dispatch(setDialog(Popups.LoginPopup));
      }
    }
    dispatch(hideLoader());
  };
  return followUser;
};
