export const ProductType = {
  0: "Brands",
  1: "Bath & Body",
  2: "Fragrance",
  3: "Hair",
  4: "Makeup",
  5: "Men",
  6: "Skincare",
  7: "Tools & Brushes",
  8: "Value & Gift Sets",
};

export const SubproductType1 = {
  9: {
    label: "Body Moisturizers",
    icon: require("./../assets/images/pngs/bodymoisturizer.png"),
  },
  10: {
    label: "Beauty Supplements",
    icon: require("./../assets/images/pngs/supplement.png"),
  },
  11: {
    label: "Body Care",
    icon: require("./../assets/images/pngs/bodycare.png"),
  },
  12: {
    label: "Bath & Shower",
    icon: require("./../assets/images/pngs/bathandshower.png"),
  },
  13: {
    label: "Self Tanners",
    icon: require("./../assets/images/pngs/selftanner.png"),
  },
  14: {
    label: "Mini Size",
    icon: require("./../assets/images/pngs/minisize.png"),
  },
  15: {
    label: "Value & Gift Sets",
    icon: require("./../assets/images/pngs/valueandgiftsets.png"),
  },
  16: {
    label: "Men",
    icon: require("./../assets/images/pngs/formenfragrance.png"),
  },
  17: {
    label: "Women",
    icon: require("./../assets/images/pngs/forwomenfragrance.png"),
  },
  18: {
    label: "Value & Gift Sets",
    icon: require("./../assets/images/pngs/valueandgiftsetsfragrance.png"),
  },
  19: {
    label: "Mini Size",
    icon: require("./../assets/images/pngs/minisizefragrance.png"),
  },
  20: {
    label: "Candles & Home Scents",
    icon: require("./../assets/images/pngs/candle.png"),
  },
  21: {
    label: "Tools",
    icon: require("./../assets/images/pngs/tools.png"),
  },
  22: {
    label: "Hair Styling & Treatments",
    icon: require("./../assets/images/pngs/hairstyling.png"),
  },
  23: {
    label: "Shampoo & Conditioner",
    icon: require("./../assets/images/pngs/shampoo.png"),
  },
  24: {
    label: "Value & Gift Sets",
    icon: require("./../assets/images/pngs/valueandgiftsetshair.png"),
  },
  25: {
    label: "Mini Size",
    icon: require("./../assets/images/pngs/minisizehair.png"),
  },
  26: { label: "Shop by Concern" },
  27: {
    label: "Brushes & Applicators",
    icon: require("./../assets/images/pngs/brushes.png"),
  },
  28: { label: "Cheek", icon: require("./../assets/images/pngs/cheek.png") },
  29: { label: "Eye", icon: require("./../assets/images/pngs/eye.png") },
  30: {
    label: "Accessories",
    icon: require("./../assets/images/pngs/accessories.png"),
  },
  31: { label: "Face", icon: require("./../assets/images/pngs/face.png") },
  32: { label: "Lip", icon: require("./../assets/images/pngs/lip.png") },
  33: {
    label: "Makeup Palettes",
    icon: require("./../assets/images/pngs/palette.png"),
  },
  34: {
    label: "Mini Size",
    icon: require("./../assets/images/pngs/minisizemakeup.png"),
  },
  35: {
    label: "Value & Gift Sets",
    icon: require("./../assets/images/pngs/valueandgiftsetsmakeup.png"),
  },
  36: { label: "Nail", icon: require("./../assets/images/pngs/nails.png") },
  37: {
    label: "Shaving",
    icon: require("./../assets/images/pngs/shaving.png"),
  },
  38: {
    label: "Skincare",
    icon: require("./../assets/images/pngs/skincaremen.png"),
  },
  39: {
    label: "Value & Gift Sets",
    icon: require("./../assets/images/pngs/valueandgiftsetsmen.png"),
  },
  40: {
    label: "Hair",
    icon: require("./../assets/images/pngs/haircaremen.png"),
  },
  41: {
    label: "Other Needs",
    icon: require("./../assets/images/pngs/otherneedsmen.png"),
  },
  42: {
    label: "Moisturizers",
    icon: require("./../assets/images/pngs/moisturizerskincare.png"),
  },
  43: {
    label: "Eye Care",
    icon: require("./../assets/images/pngs/eyecare.png"),
  },
  44: {
    label: "High Tech Tools",
    icon: require("./../assets/images/pngs/hightechtool.png"),
  },
  45: {
    label: "Wellness",
    icon: require("./../assets/images/pngs/wellness.png"),
  },
  46: {
    label: "Value & Gift Sets",
    icon: require("./../assets/images/pngs/valueandgiftsetsskincare.png"),
  },
  47: {
    label: "Treatments",
    icon: require("./../assets/images/pngs/treatments.png"),
  },
  48: {
    label: "Cleansers",
    icon: require("./../assets/images/pngs/cleansers.png"),
  },
  49: {
    label: "Lip Balms & Treatments",
    icon: require("./../assets/images/pngs/lipbalms.png"),
  },
  50: {
    label: "Mini Size",
    icon: require("./../assets/images/pngs/minisizeskincare.png"),
  },
  51: { label: "Masks", icon: require("./../assets/images/pngs/masks.png") },
  52: { label: "Sunscreen" },
  53: {
    label: "Self Tanners",
    icon: require("./../assets/images/pngs/selftanner.png"),
  },
  54: {
    label: "Beauty Tools",
    icon: require("./../assets/images/pngs/accessories.png"),
  },
  55: {
    label: "Hair Tools",
    icon: require("./../assets/images/pngs/tools.png"),
  },
  56: {
    label: "Brushes & Applicators",
    icon: require("./../assets/images/pngs/brushes.png"),
  },
  57: { label: "Mini Size" },
};

export const SubproductType2 = {
  58: "Body Lotions & Body Oils",
  59: "Hand Cream & Foot Cream",
  60: "Deodorant & Antiperspirant",
  61: "Cellulite & Stretch Marks",
  62: "Hand Sanitizer & Hand Soap",
  63: "Body Wash & Shower Gel",
  64: "Scrub & Exfoliants",
  65: "Bath Soaks & Bubble Bath",
  66: "For Body",
  67: "For Face",
  68: "Cologne",
  69: "Perfume",
  70: "Rollerballs & Travel Size",
  71: "Body Mist & Hair Mist",
  72: "Cologne Gift Sets",
  73: "Perfume Gift Sets",
  74: "Candles",
  75: "Accessories",
  76: "Hair Straighteners & Flat Irons",
  77: "Hair Dryers",
  78: "Brushes & Combs",
  177: "Curling Irons",
  79: "Scalp Treatments",
  80: "Dry Shampoo",
  81: "Hair Masks",
  82: "Hair Styling Products",
  83: "Hair Primers",
  84: "Hair Oil",
  85: "Hair Supplements",
  86: "Leave-In Conditioner",
  87: "Hair Spray",
  88: "Hair Dye & Root Touch-Ups",
  89: "Conditioner",
  90: "Shampoo",
  91: "Damaged Hair",
  92: "Color Care",
  93: "Face Brushes",
  94: "Eye Brushes",
  95: "Sponges & Applicators",
  96: "Brush Sets",
  97: "Brush Cleaners",
  98: "Cheek Palettes",
  99: "Bronzer",
  100: "Blush",
  101: "Eyebrow",
  102: "Eyeliner",
  103: "Eye Sets",
  104: "Eyeshadow",
  105: "Mascara",
  106: "Eye Primer",
  107: "Eye Palettes",
  108: "False Eyelashes",
  109: "Under-Eye Concealer",
  110: "Tweezers & Eyebrow Tools",
  111: "Makeup Removers",
  112: "Eyelash Curlers",
  113: "Makeup Bags & Travel Cases",
  114: "Blotting Papers",
  115: "Contour",
  116: "Setting Spray & Powder",
  117: "Face Sets",
  118: "Highlighter",
  119: "Foundation",
  120: "Concealer",
  121: "Face Primer",
  122: "Color Correct",
  123: "BB & CC Cream",
  124: "Tinted Moisturizer",
  125: "Lip Gloss",
  126: "Lip Liner",
  127: "Lip Balm & Treatment",
  128: "Lipstick",
  178: "Lip Stain",
  129: "Lip Plumper",
  130: "Lip Sets",
  131: "Liquid Lipstick",
  132: "Aftershave",
  133: "Face Wash",
  134: "Moisturizer & Treatments",
  135: "Sunscreen",
  136: "Eye Cream",
  137: "Skincare Sets",
  138: "Shampoo & Conditioner",
  139: "Body Products",
  140: "Face Oils",
  141: "Moisturizers",
  142: "Decollete & Neck Creams",
  143: "Mists & Essences",
  144: "BB & CC Creams",
  179: "Night Creams",
  145: "Eye Masks",
  146: "Eye Creams & Treatments",
  147: "Anti-Aging",
  148: "Facial Cleansing Brushes",
  149: "Hair Removal",
  150: "Teeth Whitening",
  151: "Facial Rollers",
  152: "Beauty Supplements",
  153: "Holistic Wellness",
  154: "Facial Peels",
  155: "Face Serums",
  156: "Blemish & Acne Treatments",
  157: "Face Wipes",
  158: "Face Wash & Cleansers",
  159: "Exfoliators",
  160: "Toners",
  161: "Makeup Removers",
  162: "Blotting Papers",
  163: "Face Masks",
  164: "Sheet Masks",
  165: "Face Sunscreen",
  166: "Body Sunscreen",
  167: "For Face",
  168: "For Body",
  169: "Tweezers & Eyebrow Tools",
  170: "Sharpeners",
  171: "Teeth Whitening",
  172: "Eyelash Curlers",
  173: "Hair Straighteners & Flat Irons",
  174: "Accessories",
  175: "Eye Brushes",
  176: "Facial Cleansing Brushes",
};

export const SubproductType1Mapping = {
  1: [9, 10, 11, 12, 13, 14, 15],
  2: [16, 17, 18, 19, 20],
  3: [21, 22, 23, 24, 25, 26],
  4: [27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
  5: [37, 38, 39, 40, 41],
  6: [42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53],
  7: [54, 55, 56],
  8: [57],
};

export const SubproductType2Mapping = {
  9: [58, 59],
  11: [60, 61, 62],
  12: [63, 64, 65],
  13: [66, 67],
  16: [68],
  17: [69, 70, 71],
  18: [72, 73],
  20: [74],
  21: [75, 76, 77, 78, 177],
  22: [79, 80, 81, 82, 83, 84, 85, 86, 87, 88],
  23: [89, 90],
  26: [91, 92],
  27: [93, 94, 95, 96, 97],
  28: [98, 99, 100],
  29: [101, 102, 103, 104, 105, 106, 107, 108, 109],
  30: [110, 111, 112, 113, 114],
  31: [115, 116, 117, 118, 119, 120, 121, 122, 123, 124],
  32: [125, 126, 127, 128, 178, 129, 130, 131],
  37: [132],
  38: [133, 134, 135, 136],
  39: [137],
  40: [138],
  41: [139],
  42: [140, 141, 142, 143, 144, 179],
  43: [145, 146],
  44: [147, 148, 149, 150],
  45: [151, 152, 153],
  47: [154, 155, 156],
  48: [157, 158, 159, 160, 161, 162],
  51: [163, 164],
  52: [165, 166],
  53: [167, 168],
  54: [169, 170, 171, 172],
  55: [173, 174],
  56: [175, 176],
};
