import {
  Avatar,
  HStack,
  IconButton,
  Text,
  View,
  VStack,
  Center,
  Box,
  Image,
} from "native-base";
import { useEffect, useState } from "react";
import { Animated, Easing } from "react-native";
import TrendingFlatAnimatedIcon from "../icons/animatedIcons/trendingFlatAnimatedIcon";
import EducationIcon from "../icons/educationIcon";
import LockIcon from "../icons/lockIcon";
import SwipeLeftIcon from "../icons/swipeLeftIcon";
const flockDealSteps = [
  "Get your spot in the Flock Deal Group by adding your payment and shipping info",
  "Invite your friends or wait for other Flockstars to join the Flock Deal Group",
  "The Flock Deal offer unlocks once all the spots are filled!",
  "The order is automatically placed with discounts for everyone in the Group!",
];
const HowFlockDealsWorkCard = ({ productImage, groupMemberImages }) => {
  const [count, setCount] = useState(0);
  const [spinAnim, setSpinAnim] = useState(new Animated.Value(0));

  const spin = spinAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });
  useEffect(() => {
    Animated.loop(
      Animated.timing(spinAnim, {
        toValue: 1,
        duration: 1500,
        easing: Easing.linear,
        useNativeDriver: true,
      })
    ).start();
  }, [spinAnim]);
  return (
    <>
      <VStack
        borderTopWidth={"2px"}
        borderBottomWidth={"2px"}
        borderColor={"#313758"}
        bg={{
          linearGradient: {
            colors: ["rgba(46, 48, 60, 0.5)", "rgba(34, 36, 45, 0.5)"],
            start: [0, 0],
            end: [1, 0],
          },
        }}
        space={6}
        p={4}
      >
        <View top={0} right={6} position={"absolute"}>
          <EducationIcon />
        </View>
        <VStack space={2}>
          <Text
            letterSpacing={3}
            color="#94A3B8"
            fontSize={"12px"}
            textTransform="uppercase"
            style={{
              fontFamily: "HK_700Bold",
            }}
          >
            About Flock deals:
          </Text>
          <Text
            color="white"
            fontSize={"14px"}
            textTransform="none"
            lineHeight={"18px"}
            style={{
              fontFamily: "HK_400Regular",
            }}
          >
            1. 3 spots to fill within 24 Hours. The order is placed only if the
            group fills.{"\n"}
            2. Everyone in the group gets a discount. The product is shipped
            directly to you.
          </Text>
        </VStack>
        <VStack space={6}>
          <HStack alignItems={"center"} justifyContent="space-between">
            <Text
              letterSpacing={3}
              color="#94A3B8"
              fontSize={"12px"}
              textTransform="uppercase"
              style={{
                fontFamily: "HK_700Bold",
              }}
            >
              how{" "}
              <Text
                letterSpacing={3}
                color="white"
                fontSize={"12px"}
                textTransform="uppercase"
                style={{
                  fontFamily: "HK_800ExtraBold",
                }}
              >
                flock{" "}
                <Text
                  letterSpacing={3}
                  color="white"
                  fontSize={"12px"}
                  textTransform="uppercase"
                  style={{
                    fontFamily: "HK_400Regular",
                  }}
                >
                  deals{" "}
                </Text>
              </Text>
              <Text
                letterSpacing={3}
                color="#94A3B8"
                fontSize={"12px"}
                textTransform="uppercase"
                style={{
                  fontFamily: "HK_700Bold",
                }}
              >
                work:
              </Text>
            </Text>
            <SwipeLeftIcon />
          </HStack>
          <HStack alignItems={"center"}>
            <Center
              left={"10%"}
              top={"-30%"}
              position={"absolute"}
              bg={{
                linearGradient: {
                  colors: [
                    "primary.ctaLinearFirstColor",
                    "primary.ctaLinearSecondColor",
                  ],
                  start: [0, 0],
                  end: [1, 0],
                },
              }}
              rounded={"full"}
              size={"25px"}
            >
              <Avatar
                size={"20px"}
                borderWidth={0}
                bg="#5E17EB"
                source={
                  groupMemberImages
                    ? {
                        uri: groupMemberImages[0],
                      }
                    : require("./../../assets/images/pngs/profile-pic.png")
                }
                fallbackSource={require("./../../assets/images/pngs/profile-pic.png")}
              ></Avatar>
            </Center>
            {count > 0 && (
              <Avatar
                left={"60%"}
                top={"-25%"}
                position={"absolute"}
                size={"20px"}
                borderWidth={0}
                bg="#5E17EB"
                source={
                  groupMemberImages
                    ? {
                        uri: groupMemberImages[2],
                      }
                    : require("./../../assets/images/pngs/profile-pic.png")
                }
                fallbackSource={require("./../../assets/images/pngs/profile-pic.png")}
              ></Avatar>
            )}
            <Box bg="#2E303C" roundedLeft={"xl"} width={"282px"} height={"8px"}>
              <Box
                bg={{
                  linearGradient: {
                    colors: [
                      "primary.ctaLinearFirstColor",
                      "primary.ctaLinearSecondColor",
                    ],
                    start: [0, 0],
                    end: [1, 0],
                  },
                }}
                roundedLeft={"xl"}
                roundedRight={count > 0 ? 0 : "xl"}
                width={count > 0 ? "full" : "1/4"}
                height={"8px"}
              ></Box>
            </Box>

            {count !== flockDealSteps.length - 1 ? (
              <View position={"relative"}>
                <Image
                  size={"50px"}
                  rounded="12px"
                  resizeMethod="scale"
                  resizeMode="contain"
                  source={{
                    uri: productImage,
                  }}
                  alt=""
                ></Image>

                <Center
                  size={"50px"}
                  rounded="12px"
                  position={"absolute"}
                  bg="black:alpha.70"
                >
                  <LockIcon />
                </Center>
              </View>
            ) : (
              <Center>
                <Image
                  position={"absolute"}
                  size={24}
                  resizeMethod="scale"
                  resizeMode="cover"
                  source={require("./../../assets/images/gifs/productSplash.gif")}
                  alt=""
                />
                <Center
                  bg={{
                    linearGradient: {
                      colors: [
                        "primary.ctaLinearFirstColor",
                        "primary.ctaLinearSecondColor",
                      ],
                      start: [0, 0],
                      end: [1, 0],
                    },
                  }}
                  rounded="12px"
                  size="50px"
                >
                  <Image
                    size={"47px"}
                    rounded="12px"
                    resizeMethod="scale"
                    resizeMode="contain"
                    source={{
                      uri: productImage,
                    }}
                    alt=""
                  ></Image>
                </Center>
              </Center>
            )}

            {count > 0 && (
              <Avatar
                left={"35%"}
                top={"80%"}
                position={"absolute"}
                size={"20px"}
                borderWidth={0}
                bg="#5E17EB"
                source={
                  groupMemberImages
                    ? {
                        uri: groupMemberImages[1],
                      }
                    : require("./../../assets/images/pngs/profile-pic.png")
                }
                fallbackSource={require("./../../assets/images/pngs/profile-pic.png")}
              ></Avatar>
            )}
          </HStack>
          <HStack justifyContent={"center"} alignItems="center" space={2}>
            <Text
              alignSelf={"flex-start"}
              letterSpacing={0.5}
              color="#94A3B8"
              fontSize={"14px"}
              textTransform="none"
              lineHeight={"18px"}
              style={{
                fontFamily: "HK_600SemiBold",
              }}
            >
              {count + 1}/{flockDealSteps.length}
            </Text>
            <Text
              letterSpacing={0.5}
              w={"70%"}
              color="white"
              fontSize={"14px"}
              textTransform="none"
              lineHeight={"18px"}
              style={{
                fontFamily: "HK_600SemiBold",
              }}
            >
              {flockDealSteps[count]}
            </Text>
            {count !== flockDealSteps.length - 1 ? (
              <IconButton
                onPress={() => {
                  setCount(count + 1);
                }}
                icon={<TrendingFlatAnimatedIcon />}
              />
            ) : (
              <Animated.Image
                style={{
                  height: 24,
                  width: 24,
                  transform: [{ rotate: spin }],
                }}
                source={require("./../../assets/images/pngs/animated_cog.png")}
              />
            )}
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default HowFlockDealsWorkCard;
