import { createContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useCheckSpace } from "../hooks/useCheckSpace";
import { useGetCountryCodeWeb } from "../hooks/useGetCountryCodeWeb";
import { usePhoneLogin } from "../hooks/usePhoneLogin";
import { useSendOtp } from "../hooks/useSendOtp";
import { storeForm } from "../redux/form/actions";
export const PhoneLoginContext = createContext();

const PhoneLoginHOC = (props) => {
  const { checkSpace, error: spaceError, label } = useCheckSpace();
  const dispatch = useDispatch();
  const countryCode = useGetCountryCodeWeb();
  const { handlePhoneLogin, error, setError } = usePhoneLogin();
  const {
    sendOtp,
    error: otpError,
    setError: setOtpError,
    nextPressed: shouldMoveToPhoneLogin,
  } = useSendOtp();
  const handleInput = (name) => (val) => {
    checkSpace(val, name);
    setError();
    setOtpError();
    dispatch(storeForm({ [name]: val, error: null }));
  };
  const [first, setFirst] = useState();
  const [second, setSecond] = useState();
  const [third, setThird] = useState();
  const [fourth, setFourth] = useState();
  const [fifth, setFifth] = useState();
  const [sixth, setSixth] = useState();

  return (
    <PhoneLoginContext.Provider
      value={{
        error,
        handleInput,
        handlePhoneLogin,
        countryCode,
        sendOtp,
        otpError,
        setOtpError,
        shouldMoveToPhoneLogin,
        first,
        second,
        third,
        fourth,
        fifth,
        sixth,
        setFirst,
        setSecond,
        setThird,
        setFourth,
        setFifth,
        setSixth,
        spaceError,
        label,
      }}
    >
      {props.children}
    </PhoneLoginContext.Provider>
  );
};

export default PhoneLoginHOC;
