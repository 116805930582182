import { Text, VStack } from "native-base";
import HeadingBar from "../../../components/bars/headingBar.js";
import FooterSectionWeb from "../../../components/footer/footerSectionWeb.js";
import { gradientTextWeb } from "../../../constants/gradient.js";
import ProductsHOC from "../../../hoc/ProductsHOC";
import UsersHOC from "../../../hoc/UsersHOC";
import CuratedProducts from "../../profileScreen/curratedForYou/curatedProducts";
import CuratedUsers from "../../profileScreen/curratedForYou/curatedUsers";

const CuratedForYouScreen = () => {
  return (
    <VStack space={16} alignItems="center">
      <VStack space={"40px"} alignItems="center" w="full">
        <HeadingBar>
          <Text
            fontSize={"36px"}
            _web={{
              style: {
                ...gradientTextWeb(1),
                fontFamily: "AustinCyr_400",
              },
            }}
          >
            Curated for you
          </Text>
        </HeadingBar>
        <ProductsHOC fetcherType="homeProducts">
          <CuratedProducts />
        </ProductsHOC>
        <UsersHOC fetcherType="curatedUsersfetcher">
          <CuratedUsers />
        </UsersHOC>
      </VStack>
      <FooterSectionWeb />
    </VStack>
  );
};

export default CuratedForYouScreen;
