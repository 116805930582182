import { Center, Divider, FlatList, VStack } from "native-base";
import ViewMoreTopBar from "../../../components/bars/topBars/viewMoreTopBar";
import ShopTheLookCard from "../../../components/cards/shopTheLookCard";
import EyesAnimatedIcon from "../../../components/icons/animatedIcons/eyesAnimatedIcon";
import useGetCelebContent from "../../../hooks/useGetCelebContent";

const ShopTheLook = ({ celebid }) => {
  const { data } = useGetCelebContent(celebid);
  const renderItem = ({ item }) => {
    return <ShopTheLookCard item={item} />;
  };

  return (
    <>
      {data?.length > 0 && (
        <VStack pl={"28px"}>
          <ViewMoreTopBar
            title="Shop the Look"
            icon={<EyesAnimatedIcon size={8} />}
            buttonText="View more"
          />
          <FlatList
            showsHorizontalScrollIndicator={false}
            horizontal
            flexGrow={0}
            data={data ? data : []}
            renderItem={renderItem}
          />
          <Center pt={8}>
            <Divider w="100px" bg="#94A3B8" opacity={50} />
          </Center>
        </VStack>
      )}
    </>
  );
};

export default ShopTheLook;
