import { Center } from "native-base";

const HeadingBar = (props) => {
  return (
    <Center bg="#FAF1FF" py={12} w="full">
      {props?.children}
    </Center>
  );
};

export default HeadingBar;
