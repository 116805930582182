import { Text, VStack, Box } from "native-base";
import {
  deleteProductsChipsValue,
  setProductsChips,
} from "../../../../redux/productsFilter/actions";
import { useDispatch, useSelector } from "react-redux";

const CustomLabel = (props) => {
  return <Box w="6" h="6" bg="red.800"></Box>;
};

const Price = () => {
  const dispatch = useDispatch();
  const low = useSelector(
    (state) => state.productsFilter.cachedFilters.priceRangeFrom
  );
  const high = useSelector(
    (state) => state.productsFilter.cachedFilters.priceRangeTo
  );

  const changeValues = (values) => {
    dispatch(deleteProductsChipsValue("$" + low + " - $" + high));
    dispatch({
      type: "SET_PRODUCTS_PRICE",
      low: values[0],
      high: values[1],
    });
    dispatch(
      setProductsChips({
        label: "Price",
        value: "$" + values[0] + " - $" + values[1],
      })
    );
  };

  return (
    <VStack space={4} width="full" px={4}>
      <VStack>
        <Text
          color="#94A3B8"
          style={{
            fontFamily: "HK_400Regular",
          }}
          fontSize="14px"
        >
          Drag the Slider to filter by Price range:
        </Text>
      </VStack>
    </VStack>
  );
};

export default Price;
