import axios from "axios";
import useSWRInfinite from "swr/infinite";
import { useSelector } from "react-redux";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
import { getHeaders } from "../util/utilFunctions";
const apiUrl = Constants.expoConfig.extra.apiUrl;

const homeProductsFetcher = async ([url, filters]) =>
  axios
    .get(url, {
      params: {
        ...filters,
        v2: true,
      },
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });

const categoryProductsfetcher = async ([url, filters]) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      params: {
        ...filters,
      },
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });

const popularProductsFetcher = async ([url, userId]) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
      params: {
        user_id: userId,
        v2: true,
      },
    })
    .then((res) => {
      return res.data.data.trending_products;
    });

const curatedProductsfetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      console.log("curatedddd", res);
      return res.data;
    });

const bundleFetcher = async (url) =>
  axios
    .get(url, {
      crossDomain: true,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data.data[0];
      } else {
        return null;
      }
    });

const womensDayProductsFetcher = async ([url, filters]) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      params: {
        ...filters,
      },
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });

const getSpecificCollectionFetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),

      crossDomain: true,
    })
    .then((res) => {
      console.log("resgetSpecificCollectionFetcher", res);
      return res.data;
    });

export default function useGetProducts(type, filters, celeb_id, collection_id) {
  const userId = useSelector((state) => state.user.id);

  const getCategoryProductsKey = (pageIndex) => {
    return [`${apiUrl}/api/products/product/?page=${pageIndex + 1}`, filters];
  };

  const getHomeProductsKey = (pageIndex, previousPageData) => {
    if (!previousPageData) {
      return [`${apiUrl}/api/home/home_products_paginated/?limit=10`, filters];
    } else {
      return [previousPageData.next, filters];
    }
  };

  const getPopularProductsKey = (pageIndex, previousPageData) => {
    return [
      userId ? `${apiUrl}/api/home/loggedin_home/` : `${apiUrl}/api/home/home/`,

      userId,
    ];
  };

  const getCuratedProductsKey = (pageIndex, previousPageData) => {
    if (!previousPageData) {
      return `${apiUrl}/api/home/curated_for_you_products/?limit=10&page=1&user_id=${userId}`;
    } else {
      return previousPageData.next;
    }
  };

  const getBundlesKey = (pageIndex, previousPageData) => {
    return celeb_id
      ? `${apiUrl}/api/products/bundles_of_celeb/?celeb_id=${celeb_id}`
      : null;
  };
  const getCollectionKey = (pageIndex, previousPageData) => {
    return collection_id
      ? `${apiUrl}/api/products/collection/?collection_id=${collection_id}`
      : null;
  };

  const getWomensDayProductsKey = (pageIndex, previousPageData) => {
    if (!previousPageData) {
      return [
        `${apiUrl}/api/home/curated_for_you_products/?limit=10&page=1&user_id=${userId}`,
        filters,
      ];
    } else {
      return [previousPageData.next, filters];
    }
  };

  const typeKeyMap = {
    homeProducts: getHomeProductsKey,
    categoryProducts: getCategoryProductsKey,
    popularProducts: getPopularProductsKey,
    curatedProducts: getCuratedProductsKey,
    collectionProducts: getBundlesKey,
    womensDayProducts: getWomensDayProductsKey,
    specificCollectionProducts: getCollectionKey,
  };
  const typeFetcherMap = {
    homeProducts: homeProductsFetcher,
    categoryProducts: categoryProductsfetcher,
    popularProducts: popularProductsFetcher,
    curatedProducts: curatedProductsfetcher,
    collectionProducts: bundleFetcher,
    womensDayProducts: womensDayProductsFetcher,
    specificCollectionProducts: getSpecificCollectionFetcher,
  };

  const { data, size, setSize, isValidating, mutate, error } = useSWRInfinite(
    type ? typeKeyMap[type] : null,
    type ? typeFetcherMap[type] : null,
    {
      revalidateFirstPage: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: true,
    }
  );

  useCheckNetwork(error);
  useLoader(isValidating);
  return {
    data:
      type === "categoryProducts" ||
      type === "popularProducts" ||
      type === "collectionProducts"
        ? data?.flatMap((data) => data)
        : data?.flatMap((item) => item?.data),

    mutate,
    setSize:
      type === "popularProducts" || type === "collectionProducts"
        ? undefined
        : () => {
            setSize(size + 1);
          },
    size,
  };
}
