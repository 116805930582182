import {
  STORE_GROUPS,
  ADD_GROUP,
  DELETE_GROUP,
  STORE_CART,
  ADD_CART,
  DELETE_CART,
  CLEAR_CART,
  STORE_WISHLIST,
  ADD_WISHLIST,
  DELETE_WISHLIST,
  STORE_VALUES,
  RELOAD_CART,
  RELOAD_WISHLIST,
  RELOAD_GROUPS,
} from "./constants";

const initialState = {
  cart: [],
  groups: [],
  wishlist: [],
  wishlistItems: [],
  groupItems: [],
  cartItems: [],
  reloadWishlist: true,
  reloadCart: true,
  reloadGroups: true,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REFRESH":
      return {
        cart: [],
        groups: [],
        wishlist: [],
        wishlistItems: [],
        groupItems: [],
        cartItems: [],
        reloadWishlist: true,
        reloadCart: true,
        reloadGroups: true,
      };
    case STORE_VALUES:
      return {
        ...state,
        wishlist: [...action.wishlistItems],
        cart: [...action.cartItems],
        groups: [...action.groups],
      };
    case STORE_WISHLIST:
      return {
        ...state,
        wishlist: [...action.wishlistItems],
      };
    case ADD_WISHLIST:
      return {
        ...state,
        wishlist: [...state.wishlist, action.product],
      };
    case RELOAD_WISHLIST:
      return {
        ...state,
        reloadWishlist: action.reloadWishlist,
      };
    case RELOAD_CART:
      return {
        ...state,
        reloadCart: action.reloadCart,
      };
    case RELOAD_GROUPS:
      return {
        ...state,
        reloadGroups: action.reloadGroups,
      };

    case DELETE_WISHLIST:
      return {
        ...state,
        wishlist: [
          ...state.wishlist.slice(0, action.itemId),
          ...state.wishlist.slice(action.itemId + 1),
        ],
      };
    case STORE_CART:
      return {
        ...state,
        cart: [...reduceCart(action.cartItems)],
      };
    case ADD_CART:
      return {
        ...state,
        cart: [...state.cart, action.product],
      };
    case DELETE_CART:
      return {
        ...state,
        cart: [
          ...state.cart.slice(0, action.itemId),
          ...state.cart.slice(action.itemId + 1),
        ],
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: [],
      };
    case STORE_GROUPS:
      return {
        ...state,
        groups: [...action.groups],
      };
    case ADD_GROUP:
      return {
        ...state,
        groups: [...state.groups, action.group],
      };
    case DELETE_GROUP:
      return {
        ...state,
        groups: [
          ...state.groups.slice(0, action.itemId),
          ...state.groups.slice(action.itemId + 1),
        ],
      };
    default:
      return state;
  }
};

const reduceCart = (cartItems) => {
  let products = cartItems.filter(function (x) {
    return x.product_sku !== null;
  });
  return products;
};

export default cartReducer;
