import { useState, useEffect } from "react";
import { Center, FlatList, Input, Stack, VStack } from "native-base";
import HomeScreenFilterCategoryCard from "../../../cards/homeScreenFilterCategoryCard";
import SearchIconOutlined from "../../../icons/searchIconOutlined";
import { ProductType } from "../../../../constants/categories";
import { changeFilter } from "../../../../redux/filter/actions";
import { useDispatch, useSelector } from "react-redux";

const Item = ({ selectedCategory, setSelectedCategory, item }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selected) {
      if (selectedCategory.includes(item)) {
        setSelected(true);
      }
    }
  }, [selectedCategory]);

  const onPress = () => {
    if (selectedCategory.includes(item)) {
      setSelected(false);
      const filtered = selectedCategory.filter((value) => value !== item);
      setSelectedCategory(filtered);
    } else {
      setSelected(true);
      setSelectedCategory([...selectedCategory, item]);
    }
  };

  return (
    <Center m={1.5}>
      <HomeScreenFilterCategoryCard
        index={item}
        label={ProductType[item]}
        onPress={onPress}
        selected={selected}
      />
    </Center>
  );
};

const Category = ({ selectedCategory, setSelectedCategory }) => {
  // const productTypes = useSelector(
  //   (state) => state.filter.cachedFilters.productTypes
  // );

  // const dispatch = useDispatch();
  const renderItem = ({ item, index }) => (
    <Item
      item={item}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
    />
  );

  return (
    <VStack width={"100%"} pb={8} px={2} space={3}>
      <Input
        borderWidth={"1px"}
        borderColor={"#94A3B8"}
        style={{
          fontFamily: "HK_400Regular",
        }}
        autoCapitalize="none"
        placeholderTextColor="#94A3B8"
        rounded={8}
        fontSize={14}
        InputRightElement={
          <Stack px={4}>
            <SearchIconOutlined size={4} />
          </Stack>
        }
        color="white"
        _focus={{
          borderColor: "#2E303C",
          backgroundColor: "#1A1C1E",
          color: "white",
        }}
        placeholder="Search Categories"
      />

      <FlatList
        columnWrapperStyle={{
          flexDirection: "row",
          justifyContent: "center",
        }}
        keyExtractor={(item) => item}
        data={Object.keys(ProductType).filter((k) => k !== "0")}
        numColumns={2}
        renderItem={renderItem}
      />
    </VStack>
  );
};

export default Category;
