import { useDispatch, useSelector } from "react-redux";
import { setDialog } from "../redux/currentState/actions";
import { Popups } from "../constants/popups";
import { getUser } from "../util/utilFunctions";
import { setAnalytics } from "../analytics";
import { useFollowUser } from "./useFollowUser";
import { useUnfollowUser } from "./useUnfollowUser";
import { useRoute } from "@react-navigation/native";
import { useSWRConfig } from "swr";
import { Platform } from "react-native";
import { Pages } from "../constants/pages";
import * as RootNavigation from "./../RootNavigation";
export const useOnFollow = () => {
  const navigation = RootNavigation;
  const dispatch = useDispatch();
  const route = useRoute();
  const following = useSelector((state) => state.following);
  const followUser = useFollowUser();
  const unfollowUser = useUnfollowUser();
  const { mutate } = useSWRConfig();
  const onFollow = async (it) => {
    const userId = await getUser();
    if (userId !== null && userId !== undefined) {
      if (following[it] !== undefined && following[it] === true) {
        unfollowUser(userId, it);

        setAnalytics("Unfollow", `Unfollowed`, {
          beautyBuddyId: it,
          screen: route?.name,
        });
      } else {
        followUser(userId, it);

        setAnalytics("Follow", `Followed`, {
          beautyBuddyId: it,
          screen: route?.name,
        });
      }
    } else {
      setAnalytics("Follow", `Guest User Tried To Follow`, {
        beautyBuddyId: it,
        screen: route?.name,
      });
      if (Platform.OS === "web") {
        navigation.navigate(Pages.Login);
      } else {
        dispatch(setDialog(Popups.LoginPopup));
      }
    }
  };

  return onFollow;
};
