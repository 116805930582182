import { useNavigation } from "@react-navigation/native";
import { Center, Text, HStack, Pressable, Stack } from "native-base";
import { ImageBackground } from "react-native";
import { Pages } from "../../../constants/pages";
import FollowButton from "../../buttons/followButton";

const UserHorizontalCard = ({ item }) => {
  const navigation = useNavigation();
  return (
    <Pressable
      onPress={() =>
        navigation.navigate(Pages.CelebScreen, {
          id: item?.id,
          username: item?.username,
        })
      }
    >
      <Center
        h="250px"
        w={{
          base: "300px",
          sm: "300px",
          md: "360px",
          lg: "370px",
          xl: "500px",
        }}
      >
        <ImageBackground
          source={
            item?.image?.indexOf("http://") == 0 ||
            item?.image?.indexOf("https://") == 0
              ? {
                  uri: item?.image,
                }
              : require("./../../../assets/images/pngs/profile-pic.png")
          }
          alt=""
          resizeMode={
            item?.image?.indexOf("http://") == 0 ||
            item?.image?.indexOf("https://") == 0
              ? "contain"
              : "contain"
          }
          backgroundRepeat="no-repeat"
          resizeMethod="scale"
          style={{
            borderRadius: 20,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            // backgroundRepeat: "no-repeat",

            // backgroundImage: `url(${item?.image}) `,
            // backgroundBlendMode: "overlay",
          }}
        >
          <Stack
            direction={{
              base: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
            h="full"
            bg={{
              linearGradient: {
                colors: ["#00000000", "#000000BF"],
              },
            }}
            alignItems={"flex-end"}
            w="full"
            px={8}
            pb={8}
            justifyContent={{
              base: "flex-end",
              sm: "flex-end",
              md: "flex-end",
              lg: "space-between",
              xl: "space-between",
            }}
          >
            <Text
              textTransform={"capitalize"}
              color="white"
              fontSize={"21px"}
              style={{
                fontFamily: "AustinCyr_400",
              }}
            >
              {item?.username}
            </Text>
            <Center w="175px">
              <FollowButton followingId={item?.id} celeb={item?.celeb} />
            </Center>
          </Stack>
        </ImageBackground>
      </Center>
    </Pressable>
  );
};

export default UserHorizontalCard;
