import { HStack, IconButton, Text } from "native-base";
import QuantMinusIcon from "../icons/quantMinusIcon";
import QuantPlusIcon from "../icons/quantPlusIcon";
import { QuantityButtonContext } from "../../hoc/QuantityButtonHOC";
import { useContext } from "react";

const QuantityButton = ({ skuId }) => {
  const { data, addToCart, deleteFromCart } = useContext(QuantityButtonContext);

  return (
    <>
      {data?.length > 0 &&
        data
          ?.map((k) => {
            if (
              k !== null &&
              k !== undefined &&
              k.product_sku &&
              k.product_sku.id
            ) {
              return k.product_sku.id;
            }
          })
          .find((j) => j === skuId) === skuId && (
          <HStack
            _light={{
              bg: {
                base: "#E9B9FF",
                sm: "#E9B9FF",
                md: "#E9B9FF",
                lg: "#E9B9FF",
                xl: "white",
              },
            }}
            _dark={{
              bg: "#2B2F33",
            }}
            borderWidth="1px"
            borderColor={"#FAF1FF"}
            h="full"
            w="full"
            alignItems="center"
            space={"8px"}
            rounded={"4px"}
            justifyContent="space-between"
          >
            <IconButton
              size={"28px"}
              _pressed={{
                bg: "#2B2F33",
              }}
              onPress={() => {
                const val =
                  data?.find((g) => g?.product_sku?.id === skuId)?.number - 1;

                if (val > 0) {
                  addToCart(skuId, val);
                } else {
                  deleteFromCart(skuId);
                }
              }}
              icon={<QuantMinusIcon color="#464646" />}
            />

            <Text
              _light={{
                color: "#464646",
              }}
              _dark={{
                color: "#94A3B8",
              }}
              _web={{
                style: {
                  fontFamily: "Hero_700Bold",
                },
              }}
              fontSize={16}
              lineHeight={18}
              style={{
                fontFamily: "HK_600SemiBold",
              }}
            >
              {data?.length > 0
                ? data.length > 0 &&
                  data
                    .map((k) => k?.product_sku?.id)
                    .find((j) => j === skuId) === skuId &&
                  data.length > 0 &&
                  data.map((g) => {
                    if (g?.product_sku?.id === skuId) {
                      return g.number;
                    }
                  })
                : 1}
            </Text>
            <IconButton
              size={"28px"}
              _pressed={{
                bg: "#2B2F33",
              }}
              onPress={() => {
                const val =
                  data?.find((g) => g?.product_sku?.id === skuId)?.number + 1;

                addToCart(skuId, val);
              }}
              icon={<QuantPlusIcon color="#464646" />}
            />
          </HStack>
        )}
    </>
  );
};

export default QuantityButton;
