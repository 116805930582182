import { Center, Pressable } from "native-base";
import { GRADIENTMAP } from "../../constants/gradient";

const PrimaryCTAButton = (props) => {
  return (
    <Pressable onPress={props.onPress} w="100%" h="100%">
      <Center
        px={"16px"}
        py={"12px"}
        w="100%"
        h="100%"
        borderRadius={"10px"}
        bg={{
          linearGradient: {
            colors: GRADIENTMAP[props?.gradientType],
            start: [0, 0],
            end: [1, 0],
          },
        }}
      >
        {props.children}
      </Center>
    </Pressable>
  );
};

export default PrimaryCTAButton;
