import axios from "axios";
import useSWRInfinite from "swr/infinite";
import Constants from "expo-constants";
import { useCheckNetwork } from "./useCheckNetwork";
import { useLoader } from "./useLoader";
import { getHeaders } from "../util/utilFunctions";
const apiUrl = Constants.expoConfig.extra.apiUrl;
const curatedUsersfetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
      params: {
        v2: true,
      },
    })
    .then((res) => {
      return res.data;
    });

const followersAndFollowingfetcher = async (url) =>
  axios
    .get(url, {
      headers: await getHeaders(),
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });
const celebUsersfetcher = async (url) =>
  axios
    .get(url, {
      crossDomain: true,
    })
    .then((res) => {
      return res.data;
    });

const allUsersFetcher = async ([url, page, userId, filters]) =>
  axios
    .get(url, {
      crossDomain: true,
      params: {
        ...filters,

        page: page,
        user_id: userId,
      },
    })
    .then((res) => {
      return res.data;
    });
export default function useGetBuddies(type, userId, query, filters) {
  const getCuratedUsersKey = (pageIndex, previousPageData) => {
    if (!previousPageData) {
      return `${apiUrl}/api/home/curated_for_you_users/?user_id=${userId}&limit=10`;
    } else {
      return previousPageData.next;
    }
  };

  const getFollowerFollowingKey = (pageIndex, previousPageData) => {
    if (!previousPageData) {
      return `${apiUrl}/api/followers/get_followers_list/?user_id=${userId}&query=${type}`;
    } else {
      return previousPageData.next;
    }
  };

  const getAllUsersKey = (pageIndex, previousPageData) => {
    return [
      query !== undefined && query !== null && query !== ""
        ? null
        : `${apiUrl}/api/home/home_users_paginated/`,

      pageIndex + 1,
      userId,
      filters,
    ];
  };
  const getCelebUsersKey = (pageIndex, previousPageData) => {
    return `${apiUrl}/api/users/get_celeb_users/`;
  };

  const typeKeyMap = {
    curatedUsersfetcher: getCuratedUsersKey,
    followers: getFollowerFollowingKey,
    following: getFollowerFollowingKey,
    allUsersFetcher: getAllUsersKey,
    celebUsers: getCelebUsersKey,
  };
  const typeFetcherMap = {
    curatedUsersfetcher: curatedUsersfetcher,
    followers: followersAndFollowingfetcher,
    following: followersAndFollowingfetcher,
    allUsersFetcher: allUsersFetcher,
    celebUsers: celebUsersfetcher,
  };

  const { data, size, setSize, isValidating, mutate, error } = useSWRInfinite(
    type ? typeKeyMap[type] : null,
    type ? typeFetcherMap[type] : null
  );
  useCheckNetwork(error);
  useLoader(isValidating);
  return {
    data:
      type === "allUsersFetcher"
        ? data?.flatMap((users) => users.users)
        : data?.flatMap((item) => item?.data),
    isValidating,
    mutate,
    setSize: () => {
      setSize(size + 1);
    },
    size,

    error,
  };
}
