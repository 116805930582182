import { VStack, Box, View } from "native-base";
import { useState } from "react";
import CartScreenBottomBar from "../../components/bars/bottomBars/cartScreenBottomBar";
import BottomBar from "../../components/bars/navigationBars/bottomBar";
import PurpleUnderLineTabsBar from "../../components/bars/purpleUnderlineTabsBar";
import useGetCartItems from "../../hooks/useGetCartItems";
import Bag from "./buildingBlocks/bag";
import Favorites from "./buildingBlocks/favorites";

const CartScreen = ({ route }) => {
  const [navTabs, setNavTabs] = useState([
    {
      label: "Bag",
      component: <Bag />,
      pressed: route?.params?.label !== "Flock Deals" && true,
    },

    {
      label: "Favorites",
      component: <Favorites />,
      pressed: false,
    },
  ]);
  const { data } = useGetCartItems(route?.params?.id);
  return (
    <>
      <PurpleUnderLineTabsBar
        items={navTabs}
        setItems={(val) => setNavTabs(val)}
      />
      <View width="100%" flex={1}>
        <VStack py={4} px={2}>
          {navTabs.map((tab, index) => {
            if (tab.pressed) {
              return <Box key={index}>{tab.component}</Box>;
            }
          })}
        </VStack>
      </View>
      {navTabs[0].pressed && (
        <CartScreenBottomBar shouldProceed={data?.length > 0} />
      )}
      <BottomBar />
    </>
  );
};

export default CartScreen;
