import { createContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCheckSpace } from "../hooks/useCheckSpace";
import useCheckUserExists from "../hooks/useCheckUserExists";
import { useGetCountryCodeWeb } from "../hooks/useGetCountryCodeWeb";
import { useSendEmailRegistrationOtp } from "../hooks/useSendEmailRegistrationOtp";
import { useSendOtp } from "../hooks/useSendOtp";
import { useSignup } from "../hooks/useSignup";
import { useVerifyOtpEmailSignup } from "../hooks/useVerifyOtpEmailSignup";
import { useVerifyOtpPhone } from "../hooks/useVerifyOtpPhone";
import { storeForm } from "../redux/form/actions";
export const SignupContext = createContext();

const SignupHOC = (props) => {
  const { checkSpace, error: spaceError, label } = useCheckSpace();
  const form = useSelector((state) => state.form);
  const dispatch = useDispatch();

  const { signup, error: signupError } = useSignup();

  //phone otp
  const {
    sendOtp,
    error: sendPhoneOtpError,
    setError: setPhoneOtpError,
    nextPressed: shouldVerifyPhoneOtp,
  } = useSendOtp();
  //email otp
  const {
    sendEmailRegistrationOtp,
    shouldProceed: shouldVerifyEmailOtp,
    error: emailRegistrationOtpError,
    setError: setEmailRegistrationOtpError,
  } = useSendEmailRegistrationOtp();

  //verify email otp
  const {
    verifyOtpEmailSignup,
    error: emailOtpVerificationError,
    shouldProceed: emailOtpVerified,
  } = useVerifyOtpEmailSignup();

  //verify phone otp
  const {
    verifyOtpPhone,
    error: phoneOtpVerificationError,
    nextPressed: phoneOtpVerified,
  } = useVerifyOtpPhone();

  const handleInput = (name) => (val) => {
    checkSpace(val, name);
    setEmailRegistrationOtpError();
    setPhoneOtpError();
    dispatch(storeForm({ [name]: val, error: null }));
  };
  const countryCode = useGetCountryCodeWeb();

  const { data: phone } = useCheckUserExists(
    "phone",
    `+${countryCode}${form?.phone_number}`
  );
  const { data: email } = useCheckUserExists("email", form?.email);
  const { data: username } = useCheckUserExists("username", form?.username);
  const [first, setFirst] = useState();
  const [second, setSecond] = useState();
  const [third, setThird] = useState();
  const [fourth, setFourth] = useState();
  const [fifth, setFifth] = useState();
  const [sixth, setSixth] = useState();

  return (
    <SignupContext.Provider
      value={{
        countryCode,
        phone,
        sendOtp,
        sendPhoneOtpError,
        shouldVerifyPhoneOtp,
        verifyOtpPhone,
        phoneOtpVerificationError,
        phoneOtpVerified,
        email,
        sendEmailRegistrationOtp,
        shouldVerifyEmailOtp,
        verifyOtpEmailSignup,
        emailOtpVerificationError,
        emailOtpVerified,
        handleInput,
        signup,
        first,
        second,
        third,
        fourth,
        fifth,
        sixth,
        setFirst,
        setSecond,
        setThird,
        setFourth,
        setFifth,
        setSixth,
        username,
        signupError,
        spaceError,
        label,
        emailRegistrationOtpError,
      }}
    >
      {props.children}
    </SignupContext.Provider>
  );
};

export default SignupHOC;
