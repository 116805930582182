// import { Center, HStack, ScrollView, Text, VStack } from "native-base";
// import { useEffect, useState } from "react";
// import { Dimensions, TextInput } from "react-native";
// import { useDispatch, useSelector } from "react-redux";
// import ContinueAsGuestButton from "../../components/buttons/continueAsGuestButton";
// import LoginButton from "../../components/buttons/loginbutton";
// import LoginWithEmailAddress from "../../components/buttons/loginWithEmailAddress";
// import { Pages } from "../../constants/pages";
// import { useGetKeyboardStatus } from "../../hooks/useGetKeyboardStatus";
// import {
//   closeDialog,
//   hideLoader,
//   showLoader,
// } from "../../redux/currentState/actions";
// import { initialiseForm, storeForm } from "../../redux/form/actions";
// import { publicApi } from "../../util/API";
// import WelcomeHeading from "./welcomeHeading";
// import * as Cellular from "expo-cellular";
// import OTP from "../../components/otp";
// import PrimaryCTAButton from "../../components/buttons/primaryCTAButton";
// import CTAButtonText from "../../components/texts/ctaButtonText";
// import GradientBorderButton from "../../components/buttons/gradientBorderButton";
// import * as RootNavigation from "./../../RootNavigation";
// const PhoneLoginScreen = ({ route, setLoginType, loginType }) => {
//   const navigation = RootNavigation;
//   const keyboardStatus = useGetKeyboardStatus();
//   const state = {
//     username: "",
//     image: "",
//     first_name: "",
//     last_name: "",
//     phone_number: "",
//   };

//   const [first, setFirst] = useState();
//   const [second, setSecond] = useState();
//   const [third, setThird] = useState();
//   const [fourth, setFourth] = useState();
//   const [fifth, setFifth] = useState();
//   const [sixth, setSixth] = useState();
//   const form = useSelector((state) => state.form);
//   const dispatch = useDispatch();
//   const [show, setShow] = useState();
//   const [nextPressed, setNextPressed] = useState();
//   const [error, setError] = useState();
//   const [codeError, setCodeError] = useState();
//   const [countryCode, setCountryCode] = useState();
//   const getCountryCode = async () => {
//     let k = await Cellular.getIsoCountryCodeAsync();
//     if (k === "in") {
//       setCountryCode("91");
//     } else if (k === "pk") {
//       setCountryCode("92");
//     } else {
//       setCountryCode("1");
//     }
//   };
//   useEffect(() => {
//     getCountryCode();
//     dispatch(initialiseForm());
//     dispatch(storeForm({ ...state, error: null }));
//   }, []);

//   const handleInput = (name) => (val) => {
//     setError();
//     setNextPressed();
//     dispatch(storeForm({ [name]: val, error: null }));
//   };

//   return (
//     <>
//       <ScrollView
//         showsVerticalScrollIndicator={false}
//         keyboardShouldPersistTaps="handled"
//       >
//         <VStack
//           pb={
//             keyboardStatus === "Keyboard Hidden" || keyboardStatus === undefined
//               ? 0
//               : 96
//           }
//           safeAreaTop
//           space={Dimensions.get("window").height / 30}
//         >
//           <VStack space={Dimensions.get("window").height / 20} px={"28px"}>
//             {route?.name === "PhoneLoginScreen" && <WelcomeHeading />}
//             <VStack space={"16px"}>
//               <VStack space={"10px"}>
//                 <Text
//                   color={"#94A3B8"}
//                   fontSize={"16px"}
//                   letterSpacing={"sm"}
//                   style={{
//                     fontFamily: "HK_500Medium",
//                   }}
//                 >
//                   Enter Phone Number
//                 </Text>

//                 <HStack
//                   pl={6}
//                   alignItems={"center"}
//                   w="full"
//                   borderColor="#58595B"
//                   borderWidth="1px"
//                   borderRadius="8px"
//                   h="45px"
//                 >
//                   <Text
//                     color={"#fff"}
//                     fontSize={"16px"}
//                     letterSpacing={"sm"}
//                     style={{
//                       fontFamily: "HK_700Bold",
//                     }}
//                   >
//                     +{countryCode && countryCode}
//                   </Text>

//                   <TextInput
//                     onChangeText={(val) => handleInput("phone_number")(val)}
//                     editable={show ? false : true}
//                     maxLength={13}
//                     keyboardType="number-pad"
//                     style={{
//                       height: "100%",
//                       flex: 1,
//                       textAlign: "center",
//                       color: "white",
//                       fontSize: 16,
//                       fontFamily: "HK_700Bold",
//                     }}
//                   />
//                 </HStack>

//                 {error !== null && error !== undefined && (
//                   <Text
//                     fontSize={14}
//                     style={{
//                       fontFamily: "HK_400Regular",
//                     }}
//                     color="#ff0000"
//                   >
//                     {error}.
//                   </Text>
//                 )}
//                 {!form?.phone_number && nextPressed && (
//                   <Text
//                     fontSize={14}
//                     style={{
//                       fontFamily: "HK_400Regular",
//                     }}
//                     color="#ff0000"
//                   >
//                     Fill phone number.
//                   </Text>
//                 )}
//                 {/\s/g.test(form?.phone_number) && (
//                   <Text
//                     fontSize={14}
//                     style={{
//                       fontFamily: "HK_400Regular",
//                     }}
//                     color="#ff0000"
//                   >
//                     Phone number can't contain spaces.
//                   </Text>
//                 )}
//               </VStack>
//               {show && (
//                 <VStack space={"10px"}>
//                   <Text
//                     color={"#94A3B8"}
//                     fontSize={"16px"}
//                     letterSpacing={"sm"}
//                     style={{
//                       fontFamily: "HK_500Medium",
//                     }}
//                   >
//                     Enter Code
//                   </Text>
//                   <OTP
//                     first={first}
//                     second={second}
//                     third={third}
//                     fourth={fourth}
//                     fifth={fifth}
//                     sixth={sixth}
//                     setFirst={(val) => setFirst(val)}
//                     setSecond={(val) => setSecond(val)}
//                     setThird={(val) => setThird(val)}
//                     setFourth={(val) => setFourth(val)}
//                     setFifth={(val) => setFifth(val)}
//                     setSixth={(val) => setSixth(val)}
//                     setNextPressed={(val) => setNextPressed(val)}
//                   />

//                   {codeError !== null && codeError !== undefined && (
//                     <Text
//                       fontSize={14}
//                       style={{
//                         fontFamily: "HK_400Regular",
//                       }}
//                       color="#ff0000"
//                     >
//                       {codeError}.
//                     </Text>
//                   )}
//                 </VStack>
//               )}

//               {show ? (
//                 <LoginButton
//                   isLoginPopUp={
//                     route?.name !== "PhoneLoginScreen" && "PhoneLoginScreen"
//                   }
//                   setCodeError={(val) => setCodeError(val)}
//                   code={first + second + third + fourth + fifth + sixth}
//                   countryCode={countryCode}
//                 />
//               ) : (
//                 <Center>
//                   <Center h="50px" w="173.35px">
//                     <PrimaryCTAButton
//                       onPress={async () => {
//                         setNextPressed(true);
//                         if (
//                           form?.phone_number &&
//                           !/\s/g.test(form?.phone_number)
//                         ) {
//                           dispatch(showLoader());

//                           try {
//                             let res = await publicApi(
//                               "/api/users/send_login_otp/",
//                               {
//                                 phone: `+${countryCode}${form?.phone_number}`,
//                                 login: true,
//                               },
//                               "post"
//                             );

//                             if (
//                               res.body.status === 200 &&
//                               res.body.message === "Otp sent successfully"
//                             ) {
//                               setShow(!show);
//                             } else if (res.body.message === "User Not Found") {
//                               setError(res.body.message);
//                             }
//                           } catch (error) {}
//                           dispatch(hideLoader());
//                         }
//                       }}
//                       gradientType="2"
//                     >
//                       <CTAButtonText text="Next" />
//                     </PrimaryCTAButton>
//                   </Center>
//                 </Center>
//               )}
//             </VStack>
//           </VStack>
//           <VStack safeAreaBottom space={Dimensions.get("window").height / 90}>
//             <LoginWithEmailAddress
//               loginType={loginType}
//               setLoginType={(val) => setLoginType(val)}
//             />
//             <VStack alignContent={"center"} justifyContent="center" space={1.5}>
//               <Text
//                 style={{
//                   fontFamily: "HK_500Medium",
//                 }}
//                 fontSize={"16px"}
//                 textAlign={"center"}
//                 color="white"
//               >
//                 Don't have an account?
//               </Text>
//               <Center>
//                 <Center w="173px" h="50px">
//                   <GradientBorderButton
//                     bg={
//                       route?.name === "PhoneLoginScreen" ? "#111315" : "#2B2F33"
//                     }
//                     onPress={() => {
//                       dispatch(closeDialog());
//                       navigation.navigate(Pages.PhoneEmailSignupScreen);
//                     }}
//                     gradientType="1"
//                   >
//                     <Text
//                       color="white"
//                       style={{
//                         fontFamily: "HK_600SemiBold",
//                       }}
//                       fontSize="14px"
//                     >
//                       Sign up
//                     </Text>
//                   </GradientBorderButton>
//                 </Center>
//               </Center>
//             </VStack>

//             {route?.name === "PhoneLoginScreen" && <ContinueAsGuestButton />}
//           </VStack>
//         </VStack>
//       </ScrollView>
//     </>
//   );
// };

// export default PhoneLoginScreen;
import { ScrollView } from "native-base";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initialiseForm } from "../../redux/form/actions";
import PhoneLoginHOC from "../../hoc/PhoneLoginHOC";
import PhoneLoginForm from "../../components/forms/phoneLoginForm";
const PhoneLoginScreen = ({ formType, loginType, setLoginType }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialiseForm());
  }, []);

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
      >
        <PhoneLoginHOC>
          <PhoneLoginForm
            formType={formType}
            loginType={loginType}
            setLoginType={(val) => setLoginType(val)}
          />
        </PhoneLoginHOC>
      </ScrollView>
    </>
  );
};

export default PhoneLoginScreen;
