import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const ActiveHomeIcon = () => {
  return (
    <Icon viewBox="0 0 28 28" size={6}>
      <G width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.859 3.36004C14.0991 3.21332 14.4011 3.21332 14.6412 3.36004L25.8912 10.235C26.2446 10.451 26.3561 10.9126 26.1401 11.2661C25.9241 11.6195 25.4625 11.731 25.109 11.515L14.2501 4.87896L3.3912 11.515C3.03775 11.731 2.57614 11.6195 2.36015 11.2661C2.14415 10.9126 2.25558 10.451 2.60902 10.235L13.859 3.36004ZM5.50011 11.2661C5.36613 11.0617 5.76271 11.2661 5.50011 11.2661C5.57746 11.3841 6.25011 11.8645 6.25011 12.0161V23.25H22.2501V12.0161C22.2501 11.8645 22.9228 11.3841 23.0001 11.2661C22.7375 11.2661 23.1341 11.0617 23.0001 11.2661C23.4143 11.2661 23.7501 11.6019 23.7501 12.0161V23.25C23.7501 23.6478 23.5921 24.0294 23.3108 24.3107C23.0295 24.592 22.6479 24.75 22.2501 24.75H6.25011C5.85228 24.75 5.47075 24.592 5.18945 24.3107C4.90814 24.0294 4.75011 23.6478 4.75011 23.25V12.0161C4.75011 11.6019 5.08589 11.2661 5.50011 11.2661Z"
          fill="white"
        />
        <Path
          d="M5.50011 11.2661C5.57746 11.3841 6.25011 11.8645 6.25011 12.0161V23.25H22.2501V12.0161C22.2501 11.8645 22.9228 11.3841 23.0001 11.2661L14.7658 6.31034C14.4485 6.1194 14.0517 6.1194 13.7345 6.31034L5.50011 11.2661Z"
          fill="white"
        />
      </G>
    </Icon>
  );
};

export default ActiveHomeIcon;
