import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const ArrowBackIcon = ({ size }) => {
  return (
    <Icon viewBox="0 0 40 16" size={size}>
      <G>
        <Path
          d="M38.6666 9C39.2189 9 39.6666 8.55228 39.6666 8C39.6666 7.44772 39.2189 7 38.6666 7L38.6666 9ZM0.459519 7.29289C0.068995 7.68342 0.0689948 8.31658 0.459519 8.70711L6.82348 15.0711C7.214 15.4616 7.84717 15.4616 8.23769 15.0711C8.62822 14.6805 8.62822 14.0474 8.23769 13.6569L2.58084 8L8.2377 2.34315C8.62822 1.95262 8.62822 1.31946 8.2377 0.928932C7.84717 0.538407 7.21401 0.538407 6.82348 0.928932L0.459519 7.29289ZM38.6666 7L1.16663 7L1.16663 9L38.6666 9L38.6666 7Z"
          fill="#682481"
        />
      </G>
    </Icon>
  );
};

export default ArrowBackIcon;
