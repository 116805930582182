import { Box, HStack, Pressable, View, Text, VStack } from "native-base";
import { useState } from "react";
import { Dimensions } from "react-native";
import GrayStarIconFilled from "../../components/icons/grayStarIconFilled";
import YellowStarIconFilled from "../../components/icons/yellowStarIconFilled";
import { Pages } from "../../constants/pages";
import { useEditUserDetails } from "../../hooks/useEditUserDetails";
import { useSelector } from "react-redux";
import { setAnalytics } from "../../analytics";
import useGetUser from "../../hooks/useGetUser";
import SurveyScreenBottomBar from "../../components/bars/bottomBars/surveyScreenBottomBar";

const SkillsScreen = ({ navigation, route }) => {
  const userId = useSelector((state) => state.user.id);

  const { data } = useGetUser("profile", userId);

  const editUserDetails = useEditUserDetails();

  const [skincareRating, setSkinCareRating] = useState([
    ...new Array(
      Number(
        data?.user[0]?.skincare_routine ? data?.user[0]?.skincare_routine : 0
      )
    ).fill({
      icon: <GrayStarIconFilled size={8} />,
      activeIcon: <YellowStarIconFilled size={8} />,
      clicked: true,
    }),
    ...new Array(
      5 -
        Number(
          data?.user[0]?.skincare_routine ? data?.user[0]?.skincare_routine : 0
        )
    ).fill({
      icon: <GrayStarIconFilled size={8} />,
      activeIcon: <YellowStarIconFilled size={8} />,
      clicked: false,
    }),
  ]);
  const [makeupApplicationRating, setMakeupApplicationRating] = useState([
    ...new Array(
      Number(
        data?.user[0]?.makeup_application
          ? data?.user[0]?.makeup_application
          : 0
      )
    ).fill({
      icon: <GrayStarIconFilled size={8} />,
      activeIcon: <YellowStarIconFilled size={8} />,
      clicked: true,
    }),
    ...new Array(
      5 -
        Number(
          data?.user[0]?.makeup_application
            ? data?.user[0]?.makeup_application
            : 0
        )
    ).fill({
      icon: <GrayStarIconFilled size={8} />,
      activeIcon: <YellowStarIconFilled size={8} />,
      clicked: false,
    }),
  ]);

  const [skinRating, setSkinRating] = useState();
  const [makeupRating, setMakeupRating] = useState();

  const ratings = [
    {
      label: "Skincare Routine",
      property: skincareRating,
    },
    {
      label: "Makeup Application",
      property: makeupApplicationRating,
    },
  ];

  const handleRequest = async (text) => {
    if (text === "Skip") {
      setAnalytics(
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
        }
      );
    } else {
      editUserDetails(
        {
          skincare_routine: skinRating
            ? skinRating
            : data?.user[0]?.skincare_routine,
          makeup_application: makeupRating
            ? makeupRating
            : data?.user[0]?.makeup_application,
        },
        `${text} Button Survey Screen`,
        `${text} Button Clicked In Beauty Quiz`,
        {
          screen: route?.name,
          skincare_routine: skinRating
            ? skinRating
            : data?.user[0]?.skincare_routine,
          makeup_application: makeupRating
            ? makeupRating
            : data?.user[0]?.makeup_application,
          edited: data?.user[0]?.quiz_completed,
        }
      );
    }
    navigation.navigate(Pages.Scents);
  };

  return (
    <>
      <View h="full" p={4}>
        <VStack
          bg="#1C212E"
          p={4}
          height={Dimensions.get("window").height / 2 + 120}
          rounded={16}
          space={4}
          alignItems={"center"}
        >
          <Text
            textAlign={"center"}
            color={"white"}
            fontSize={30}
            style={{
              fontFamily: "Poppins_500Medium",
            }}
            lineHeight={44}
          >
            How good are your{"\n"}
            skills?
          </Text>
          <Text
            textAlign={"center"}
            color={"#94A3B8"}
            fontSize={14}
            style={{
              fontFamily: "Poppins_400Regular",
            }}
            lineHeight={17}
          >
            Rate your level of expertise
          </Text>
          <VStack width={"full"} alignItems="center" space={6}>
            {ratings.map((rating, index) => (
              <VStack alignItems="center" space={2} key={index}>
                <Text
                  color={"#94A3B8"}
                  style={{
                    fontFamily: "Poppins_400Regular",
                  }}
                  fontSize={14}
                  lineHeight={17}
                >
                  {rating.label}
                </Text>
                <HStack space={1.5}>
                  {rating.property.map((item, index) => (
                    <Pressable
                      key={index}
                      onPress={() => {
                        let copy = [...rating.property];
                        copy.map((k) => {
                          if (k.clicked) {
                            return (k.clicked = false);
                          }
                        });
                        for (let j = index; j >= 0; j--) {
                          copy[j] = { ...copy[j], clicked: true };
                        }

                        if (rating.label === "Skincare Routine") {
                          setSkinCareRating(copy);
                          setSkinRating(copy[index].clicked && index + 1);
                        }
                        if (rating.label === "Makeup Application") {
                          setMakeupApplicationRating(copy);
                          setMakeupRating(copy[index].clicked && index + 1);
                        }
                      }}
                    >
                      <Box key={index}>
                        {rating.property[index].clicked
                          ? item.activeIcon
                          : item.icon}
                      </Box>
                    </Pressable>
                  ))}
                </HStack>
              </VStack>
            ))}
          </VStack>
        </VStack>
      </View>
      <HStack position={"absolute"} bottom={0}>
        <SurveyScreenBottomBar
          showSkipButton={!data?.user[0]?.quiz_completed}
          showNextButton={
            skinRating ||
            makeupRating ||
            data?.user[0]?.skincare_routine ||
            data?.user[0]?.makeup_application
          }
          skipOnPress={() => {
            handleRequest("Skip");
          }}
          nextOnPress={() => {
            handleRequest("Next");
          }}
        />
      </HStack>
    </>
  );
};

export default SkillsScreen;
