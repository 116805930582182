import { HStack, Input, useColorModeValue } from "native-base";
import { useRef } from "react";

const OTP = ({
  first,
  second,
  third,
  fourth,
  fifth,
  sixth,
  setFirst,
  setSecond,
  setThird,
  setFourth,
  setFifth,
  setSixth,
  textColor = "white",
  h = "45px",
  w = "45px",
  borderColor = "white",
}) => {
  const ref_input1 = useRef();
  const ref_input2 = useRef();
  const ref_input3 = useRef();
  const ref_input4 = useRef();
  const ref_input5 = useRef();
  const ref_input6 = useRef();

  const cursorColor = useColorModeValue("black", "white");

  return (
    <>
      <HStack space={"16px"} justifyContent="center">
        {[
          {
            ref: ref_input1,
            box: first,
          },
          { ref: ref_input2, box: second },
          {
            ref: ref_input3,
            box: third,
          },
          {
            ref: ref_input4,
            box: fourth,
          },
          {
            ref: ref_input5,
            box: fifth,
          },
          {
            ref: ref_input6,
            box: sixth,
          },
        ].map((k, i) => (
          <Input
            w={w}
            h={h}
            color={textColor}
            variant="outline"
            focusOutlineColor={borderColor}
            _focus={{
              bg: "none",
              color: textColor,
            }}
            _hover={{
              bg: "none",
              color: textColor,
              borderColor: borderColor,
            }}
            _input={{
              selectionColor: cursorColor,
            }}
            style={{
              fontFamily: "HK_700Bold",
            }}
            borderRadius="7px"
            borderColor="#C1C9D2"
            fontSize="14px"
            placeholderTextColor={"#C1C9D2"}
            borderWidth={"1px"}
            ref={k.ref}
            onKeyPress={({ nativeEvent: { key: keyValue } }) => {
              // setNextPressed();

              if (keyValue === "Backspace") {
                if (i === 5) {
                  if (sixth?.length > 1) {
                    ref_input5.current.focus();
                  } else {
                    setSixth(keyValue);
                  }
                } else if (i === 4) {
                  if (fifth?.length > 1) {
                    ref_input4.current.focus();
                  } else {
                    setFifth(keyValue);
                  }
                } else if (i === 3) {
                  if (fourth?.length > 1) {
                    ref_input3.current.focus();
                  } else {
                    setFourth(keyValue);
                  }
                } else if (i === 2) {
                  if (third?.length > 1) {
                    ref_input2.current.focus();
                  } else {
                    setThird(keyValue);
                  }
                } else if (i === 1) {
                  if (second?.length > 1) {
                    ref_input1.current.focus();
                  } else {
                    setSecond(keyValue);
                  }
                } else if (i === 0) {
                  if (first?.length > 1) {
                    ref_input1.current.clear();
                  } else {
                    setFirst(keyValue);
                  }
                }
              } else {
                if (i === 0) {
                  setFirst(keyValue);
                  ref_input2.current.focus();
                } else if (i === 1) {
                  setSecond(keyValue);
                  ref_input3.current.focus();
                } else if (i === 2) {
                  setThird(keyValue);
                  ref_input4.current.focus();
                } else if (i === 3) {
                  setFourth(keyValue);
                  ref_input5.current.focus();
                } else if (i === 4) {
                  setFifth(keyValue);
                  ref_input6.current.focus();
                } else if (i === 5) {
                  setSixth(keyValue);
                }
              }
            }}
            value={k.box === "Backspace" || k.box === undefined ? "" : k.box}
            autoFocus={i === 0 ? true : false}
            editable
            maxLength={1}
            // keyboardType="number-pad"
          ></Input>
        ))}
      </HStack>
    </>
  );
};

export default OTP;
