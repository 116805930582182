import { useNavigation, useRoute } from "@react-navigation/native";
import {
  Box,
  Button,
  FlatList,
  HStack,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import { Platform } from "react-native";
import { Pages } from "../../constants/pages";
import ChevronLeftArrowIcon from "../icons/chevronLeftArrowIcon";
import CollectionProductCard from "./../../components/cards/collectionProductCard";

const CollectionCard = ({ item, bundleId }) => {
  const renderItem = ({ item }) => (
    <CollectionProductCard item={item} bundleId={bundleId} />
  );
  const route = useRoute();
  const navigation = useNavigation();
  const backgroundColor = useColorModeValue("white", "#111315");

  return (
    <VStack space={"15px"}>
      <HStack justifyContent={"space-between"} alignItems="center">
        <HStack space={2} alignItems="center" w="60%">
          <Box
            h="8"
            w="1"
            bg={{
              linearGradient: {
                colors: [
                  "primary.ctaLinearSecondColor",
                  "primary.ctaLinearFirstColor",
                ],
                start: [1, 0],
                end: [0, 1],
              },
            }}
          ></Box>
          <Text
            noOfLines={2}
            textTransform={"uppercase"}
            _light={{
              color: "#464646",
            }}
            _dark={{
              color: "white",
            }}
            _web={{
              style: {
                fontFamily: "Hero_700Bold",
              },
            }}
            letterSpacing={2}
            fontSize={"13px"}
            style={{
              fontFamily: "HK_600SemiBold",
            }}
          >
            {item?.name}
          </Text>
          <Text
            _light={{
              color: "#94A3B8",
            }}
            _dark={{
              color: "white",
            }}
            opacity={50}
            fontSize={"13px"}
            style={{
              fontFamily: "HK_500Medium",
            }}
          >
            {item?.product_skus?.length}{" "}
            {item?.product_skus?.length > 1 ? "products" : "product"}
          </Text>
        </HStack>
        {route?.name === Pages.UserScreen || route?.name === "profile" ? (
          route?.params?.type === "profile" ? (
            <Button
              onPress={() =>
                navigation.navigate("editcollection", {
                  collectionId: item?.id,
                })
              }
              _pressed={{ bg: backgroundColor }}
              variant={"ghost"}
              _text={{
                color: "#8F8F8F",
                fontSize: 14,
                lineHeight: 21,
                fontFamily: "Hero_700Bold",
              }}
            >
              Edit
            </Button>
          ) : (
            <>
              <Button
                _pressed={{ bg: backgroundColor }}
                onPress={() =>
                  navigation.navigate(Pages.CategoryScreen, {
                    collectionId: item?.id,
                    source:
                      route?.params?.type === "profile"
                        ? "regular"
                        : "wishlist",
                    otherUserId:
                      route?.params?.type === "profile"
                        ? null
                        : route?.params?.id,
                  })
                }
                variant={"ghost"}
                _text={{
                  color: "#1A94FF",
                  fontSize: 14,
                  lineHeight: 21,
                  fontFamily: "HK_600SemiBold",
                }}
                endIcon={<ChevronLeftArrowIcon color={"#1A94FF"} />}
              >
                View all
              </Button>
            </>
          )
        ) : (
          Platform.OS !== "web" && (
            <Button
              _pressed={{ bg: backgroundColor }}
              onPress={item.onPress}
              variant={"ghost"}
              _text={{
                color: "#1A94FF",
                fontSize: 14,
                lineHeight: 21,
                fontFamily: "HK_600SemiBold",
              }}
              endIcon={<ChevronLeftArrowIcon color={"#1A94FF"} />}
            >
              View all
            </Button>
          )
        )}
      </HStack>
      {item?.description && <Text color={"white"}>{item?.description}</Text>}
      <FlatList
        showsHorizontalScrollIndicator={false}
        horizontal
        ItemSeparatorComponent={() => (
          <Box style={{ paddingHorizontal: 6 }}></Box>
        )}
        flexGrow={0}
        data={item?.product_skus}
        renderItem={renderItem}
      />
    </VStack>
  );
};

export default CollectionCard;
