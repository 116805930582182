import { Button, Text } from "native-base";

const ExitDealButton = ({onPress}) => {
  return (
    <>
      <Button
        w={"190px"}
        h={"50px"}
        rounded={"10px"}
        borderWidth={"2px"}
        borderColor={"#58595B"}
        variant={"outline"}
        onPress={onPress}
      >
        <Text
          color={"#94A3B8"}
          fontSize={"14px"}
          style={{
            fontFamily: "HK_500Medium",
          }}
        >
          Exit Deal
        </Text>
      </Button>
    </>
  );
};

export default ExitDealButton;
