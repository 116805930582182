import { useRoute } from "@react-navigation/native";
import {
  Center,
  HStack,
  Pressable,
  Text,
  useColorModeValue,
} from "native-base";
import { useSelector } from "react-redux";
import { useOnFollow } from "../../hooks/useOnFollow";
import GreenTickIcon from "../icons/greenTickIcon";
import UserFollowIcon from "../icons/userFollowIcon";

const FollowButton = ({ followingId, buttonBg, celeb }) => {
  const route = useRoute();
  const following = useSelector((state) => state.following[followingId]);
  const onFollow = useOnFollow();
  const followTextColor = useColorModeValue(
    following
      ? "#682481"
      : route?.name === "CelebScreen" || route?.name === "profile" || celeb
      ? "white"
      : "#94A3B8",
    following ? "#94A3B8" : "white"
  );

  const borderColor = useColorModeValue("#E7C9F1", "#202427");
  return (
    <HStack justifyContent={"center"} w="100%">
      <Center
        h="40px"
        w="100%"
        bg={{
          linearGradient: following
            ? {
                colors: [borderColor, borderColor],
                start: [0, 0],
                end: [1, 0],
              }
            : {
                colors:
                  route?.name === "CelebScreen" || route?.name === "profile"
                    ? [
                        "primary.ctaLinearFirstColor",
                        "primary.ctaLinearSecondColor",
                      ]
                    : [
                        "primary.ctaLinearSecondColor",
                        "primary.ctaLinearFirstColor",
                      ],
                start: [0, 0],
                end: [1, 0],
              },
        }}
        borderRadius={"6px"}
      >
        <Pressable
          onPress={() => onFollow(followingId)}
          h="38px"
          w="99%"
          _light={{
            bg: following ? "#E7C9F1" : buttonBg,
          }}
          _dark={{
            bg: following ? "#202427" : buttonBg,
          }}
          _web={{
            style: {
              fontFamily: "Hero_700Bold",
            },
          }}
          borderRadius={"6px"}
          flexDir={"row"}
          alignItems="center"
          justifyContent={"center"}
          space={2}
          _hover={{
            _text: {
              color: "white",
            },
            bg: {
              colors: [
                "primary.ctaLinearFirstColor",
                "primary.ctaLinearSecondColor",
              ],
              start: [0, 0],
              end: [1, 0],
            },
          }}
        >
          {({ isHovered, isFocused, isPressed }) => {
            return (
              <>
                {following ? (
                  <GreenTickIcon />
                ) : (
                  <UserFollowIcon
                    color={
                      route?.name === "CelebScreen" ||
                      route?.name === "profile" ||
                      isHovered
                        ? "#ffffff"
                        : followTextColor
                    }
                  />
                )}
                <Text
                  style={{
                    fontFamily: "HK_500Medium",
                    color: isHovered && !following ? "white" : followTextColor,
                    fontSize: "14px",
                  }}
                >
                  {following ? "Following" : "Follow"}
                </Text>
              </>
            );
          }}
        </Pressable>
      </Center>
    </HStack>
  );
};

export default FollowButton;
