import SignupHOC from "../../../hoc/SignupHOC";
import SignupOptionForm from "../../forms/signupOptionForm";
const SignupElement = () => {
  return (
    <SignupHOC>
      <SignupOptionForm />
    </SignupHOC>
  );
};

export default SignupElement;
