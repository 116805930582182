import {
    VIEW_BRAND
}
    from './constants'

const initialState = {
}

const brandReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REFRESH':
            return {}
        case VIEW_BRAND:
            return {
                ...action.brand
            }

        default: return state
    }
}

export default brandReducer