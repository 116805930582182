import {
  Box,
  Button,
  Center,
  FlatList,
  HStack,
  Image,
  Modal,
  Text,
  VStack,
} from "native-base";

import { useDispatch, useSelector } from "react-redux";
import { goBackDialog } from "../../redux/currentState/actions";
import RewardsIcon from "../icons/rewardsIcon";
import RewardsAnimatedIcon from "./../../components/icons/animatedIcons/rewardsAnimatedCoin";
const RewardsInfoModal = () => {
  const dispatch = useDispatch();
  const openDialog = useSelector((state) => state.currentState.openDialog);
  const renderItem = ({ item }) => (
    <>
      <HStack
        justifyContent={"space-around"}
        alignItems="center"
        h={{
          md: "40px",
          lg: "60px",
          xl: "60px",
        }}
        w="332px"
        rounded="10px"
        borderWidth={"2px"}
        borderColor="#202427"
      >
        <Text
          color={"white"}
          fontSize="16px"
          textTransform={"uppercase"}
          letterSpacing={3.5}
          style={{
            fontFamily: "HK_700Bold",
          }}
        >
          <RewardsIcon size={7} /> {item.points}
        </Text>
        <Text
          color={"white"}
          fontSize="16px"
          textTransform={"uppercase"}
          letterSpacing={1.5}
          style={{
            fontFamily: "HK_700Bold",
          }}
        >
          ${item.worth}{" "}
          <Text
            color={"white"}
            fontSize="16px"
            textTransform={"uppercase"}
            letterSpacing={1.5}
            style={{
              fontFamily: "HK_400Regular",
            }}
          >
            off{" "}
          </Text>
          <RewardsAnimatedIcon />
        </Text>
      </HStack>
    </>
  );
  return (
    <Modal
      size="full"
      bg="black:alpha.80"
      isOpen={openDialog}
      onClose={() => dispatch(goBackDialog())}
    >
      <VStack alignItems="center" space={4}>
        <VStack
          h={{
            md: "500px",
            lg: "669px",
            xl: "669px",
          }}
          w="372px"
          bg="#2B2F33"
          rounded={"40px"}
          alignItems="center"
          pt={"30px"}
          px={4}
          space={"20px"}
        >
          <VStack alignItems="center">
            <Image
              resizeMethod="scale"
              resizeMode="contain"
              source={require("./../../assets/images/pngs/FLockbucks1.png")}
              alt=""
            ></Image>
            <Text
              color={"white"}
              fontSize="14px"
              textTransform={"uppercase"}
              letterSpacing={1.5}
              style={{
                fontFamily: "HK_700Bold",
              }}
            >
              for Discounts
            </Text>
          </VStack>
          <Text
            color={"white"}
            fontSize="16px"
            style={{
              fontFamily: "HK_500Medium",
            }}
          >
            Use Flock Bucks to claim discounts on almost all products* on Flock!
          </Text>
          <Center h={"430px"}>
            <FlatList
              showsVerticalScrollIndicator={false}
              ItemSeparatorComponent={() => (
                <Box style={{ paddingVertical: 6 }}></Box>
              )}
              data={[
                {
                  points: 2000,
                  worth: 125,
                },
                {
                  points: 1000,
                  worth: 50,
                },
                {
                  points: 750,
                  worth: 30,
                },
                {
                  points: 500,
                  worth: 17.5,
                },
                {
                  points: 250,
                  worth: 8,
                },
                {
                  points: 100,
                  worth: 3,
                },
              ]}
              initialNumToRender={5}
              renderItem={renderItem}
            />
          </Center>
          <Image
            resizeMethod="scale"
            resizeMode="contain"
            source={require("./../../assets/images/pngs/flockGrayLogo.png")}
            alt=""
          ></Image>
        </VStack>
        <Button
          onPress={() => dispatch(goBackDialog())}
          rounded="10px"
          h="50px"
          w="119px"
          borderWidth={"2px"}
          borderColor="#58595B"
          variant={"ghost"}
          _text={{
            fontSize: "16px",
            fontFamily: "Poppins_500Medium",
            color: "#E5E5E5",
          }}
        >
          Got it
        </Button>
      </VStack>
    </Modal>
  );
};

export default RewardsInfoModal;
