import { VStack, Stack, Divider, Hidden, ScrollView } from "native-base";
import { useState } from "react";
import Collections from "./collections";
import ExtraInfoAccordions from "./extraInfoAccordions";

const UserActivities = ({ data }) => {
  const [component, setComponent] = useState(<Collections />);

  return (
    <>
      <VStack
        space={{
          xl: "80px",
          xxl: "80px",
        }}
        h={{
          xl: "800px",
          xxl: "800px",
        }}
      >
        <Stack
          h="full"
          space={{
            base: 4,
            sm: 4,
            md: 4,
            lg: 4,
          }}
          px={{
            base: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: "120px",
            xxl: "120px",
          }}
          alignItems={{
            xl: "center",
          }}
          pt={"80px"}
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
            xxl: "row",
          }}
          justifyContent="space-evenly"
        >
          <VStack
            h="full"
            w={{
              base: "column",
              sm: "column",
              md: "full",
              lg: "full",
              xl: "30%",
              xxl: "30%",
            }}
          >
            <ExtraInfoAccordions
              setComponent={(val) => setComponent(val)}
              component={component}
              data={data}
            />
          </VStack>
          <Hidden only={["base", "sm", "md", "lg"]}>
            <Divider bg="#E9B9FF" orientation="vertical" h={"full"} />
          </Hidden>
          <Hidden only={["base", "sm", "md", "lg"]}>
            <VStack
              h="full"
              w={{
                base: "column",
                sm: "column",
                md: "full",
                lg: "full",
                xl: "60%",
                xxl: "60%",
              }}
            >
              <ScrollView h="full">{component}</ScrollView>
            </VStack>
          </Hidden>
        </Stack>
      </VStack>
    </>
  );
};

export default UserActivities;
