import { Modal, Text, Pressable, Box, Stack } from "native-base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog, hideLoader } from "../../redux/currentState/actions";
import * as RootNavigation from "../../RootNavigation";
import { Pages } from "../../constants/pages";
import { setAnalytics } from "../../analytics";

const ErrorPopUp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideLoader());
  }, []);
  const route = RootNavigation.navigationRef.getCurrentRoute();
  const openDialog = useSelector((state) => state.currentState.openDialog);
  return (
    <>
      <Modal
        size="full"
        bg="black:alpha.70"
        isOpen={openDialog}
        onClose={() => {
          setAnalytics("Close Error Pop up", "Closed Error Pop up", {
            popup: "ErrorPopUp",
            screen: route?.name,
          });
          dispatch(closeDialog());
        }}
      >
        <Modal.Content
          rounded={"20px"}
          w={{
            md: "full",
            lg: "full",
            xl: "45%",
          }}
          bg="white"
          p={{
            md: 4,
            lg: 4,
            xl: "30px",
          }}
        >
          <Stack
            alignItems={"center"}
            justifyContent="center"
            space={12}
            direction={"column"}
          >
            <Text
              textAlign={"center"}
              _light={{
                color: "#682481",
              }}
              _dark={{
                color: "white",
              }}
              fontSize={"36px"}
              lineHeight="38px"
              textTransform="uppercase"
              style={{
                fontFamily: "HK_700Bold",
              }}
            >
              oops, you broke it!
            </Text>

            <Pressable
              onPress={() => {
                RootNavigation.navigate(Pages.Home);
                dispatch(closeDialog());
              }}
            >
              <Box
                alignItems="center"
                flexDirection="row"
                justifyContent="center"
                w="173.35px"
                h="50px"
                alignSelf={"center"}
                bg={{
                  linearGradient: {
                    colors: [
                      "primary.ctaLinearFirstColor",
                      "primary.ctaLinearSecondColor",
                    ],
                    start: [0, 0],
                    end: [1, 0],
                  },
                }}
                rounded="10px"
                _text={{
                  color: "#ffffff",
                }}
              >
                <Text
                  textTransform="uppercase"
                  color="white"
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                  fontSize="14px"
                >
                  back to home page
                </Text>
              </Box>
            </Pressable>
          </Stack>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ErrorPopUp;
