import { Center, Modal, Spinner } from "native-base";
import { Platform } from "react-native";
import { useSelector } from "react-redux";

const Loader = () => {
  const loader = useSelector((state) => state.currentState.loader);
  return <>{loader && <FlockLoader />}</>;
};
export default Loader;
export const FlockLoader = () => {
  return (
    <>
      {Platform.OS === "web" ? (
        <Center size={"full"} zIndex={4} bg="black:alpha.10" position={"fixed"}>
          <Spinner color="#fff" size={"lg"}></Spinner>
        </Center>
      ) : (
        <Modal size={"full"} isOpen={true} zIndex={4} bg="black:alpha.10">
          <Center position={"absolute"} h="full" w="full">
            <Spinner color="#fff" size={"lg"}></Spinner>
          </Center>
        </Modal>
      )}
    </>
  );
};
