import {
  HStack,
  VStack,
  Text,
  Image,
  View,
  Pressable,
  Center,
} from "native-base";
import DiscountRightBookmarkIconFilled from "../icons/discountRightBookmarkIconFilled";
import { BrandImages } from "../../constants/brands";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Pages } from "../../constants/pages";
import { ReturnVariants } from "../../screens/productScreen/buildingBlocks/productDetails";
//CreateGroupScreen
const FlockDealOrderInfoCard = ({
  image,
  retailPrice,
  groupPrice,
  brandName,
  productName,
  groupDiscount,
  productId,
  productSkuId,
  variants,
}) => {
  const navigation = useNavigation();
  const route = useRoute();
  return (
    <>
      <Pressable
        onPress={() => {
          navigation.navigate(Pages.ProductScreen, {
            id: productId,
            sku_id: productSkuId,
          });
        }}
      >
        <HStack
          flex={1}
          rounded="17px"
          h="130px"
          space={2}
          width="100%"
          bg="#202427"
          alignItems={"center"}
          px={4}
        >
          <View position={"relative"}>
            <Image
              resizeMethod="scale"
              resizeMode="contain"
              size={"94px"}
              rounded="10px"
              source={{
                uri: image,
              }}
              alt=""
            />

            <View position="absolute" top={"-3%"} left={"-15%"}>
              <View position={"relative"}>
                <DiscountRightBookmarkIconFilled />
                <Text
                  position={"absolute"}
                  top="30%"
                  left={"20%"}
                  color="white"
                  fontSize={14}
                  lineHeight={18}
                  style={{
                    fontFamily: "HK_700Bold",
                  }}
                >
                  {groupDiscount}%
                </Text>
              </View>
            </View>
          </View>
          <VStack flex={1} space={2} alignSelf="flex-start" pt={4}>
            <VStack space={2}>
              <HStack space={2}>
                <Image
                  size={4}
                  rounded={"full"}
                  resizeMethod="scale"
                  resizeMode="contain"
                  source={{
                    uri: BrandImages[brandName],
                  }}
                  alt=""
                />
                <Text
                  noOfLines={1}
                  textTransform={"uppercase"}
                  color="#94A3B8"
                  letterSpacing={2}
                  fontSize={"10px"}
                  lineHeight={16}
                  style={{
                    fontFamily: "HK_600SemiBold",
                  }}
                >
                  {brandName}
                </Text>
              </HStack>
              <Text
                noOfLines={1}
                color="white"
                fontSize={"14px"}
                style={{
                  fontFamily: "HK_400Regular",
                }}
                lineHeight={18}
              >
                {productName}
              </Text>
            </VStack>

            {route?.name === "CreateGroupScreen" ? (
              <ReturnVariants type="card" />
            ) : (
              <HStack space={"4px"} flexWrap="wrap">
                {variants
                  ?.filter(
                    (item, index) =>
                      item !== undefined && item !== null && index < 3
                  )
                  .map((variant, i) => (
                    <Center
                      mt={1}
                      py={"2px"}
                      px={"9px"}
                      rounded={"4px"}
                      key={i}
                      bg="#2B2F33"
                    >
                      <Text
                        noOfLines={1}
                        style={{
                          fontFamily: "HK_400Regular",
                        }}
                        fontSize="12px"
                        color={"white"}
                      >
                        {variant}
                      </Text>
                    </Center>
                  ))}
              </HStack>
            )}
          </VStack>
          <VStack alignItems={"center"}>
            <Text
              color="white"
              style={{
                fontFamily: "HK_700Bold",
              }}
              fontSize={"16px"}
            >
              ${groupPrice}
            </Text>
            <Text
              strikeThrough
              color="#58595B"
              fontSize="14px"
              style={{
                fontFamily: "HK_700Bold",
              }}
            >
              ${retailPrice}
            </Text>
          </VStack>
        </HStack>
      </Pressable>
    </>
  );
};

export default FlockDealOrderInfoCard;
