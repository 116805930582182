import { useDispatch } from "react-redux";

import { useSWRConfig } from "swr";
import {
  hideLoader,
  setDialog,
  showLoader,
} from "../redux/currentState/actions";
import { Popups } from "../constants/popups";
import { privateApi } from "../util/API";
import { getUser } from "../util/utilFunctions";
import Constants from "expo-constants";
import { Platform } from "react-native";
import { Pages } from "../constants/pages";
import * as RootNavigation from "./../RootNavigation";
const apiUrl = Constants.expoConfig.extra.apiUrl;
export const useDeleteFromCart = () => {
  const navigation = RootNavigation;
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const deleteCart = async (productSkuId) => {
    dispatch(showLoader());
    const userId = await getUser();
    try {
      if (userId !== null && userId !== undefined) {
        const res = await privateApi(
          "/api/products/cart/",
          { user_id: userId, product_sku_id: productSkuId },
          "delete"
        );

        if (res.status === 200 || res.status === 204) {
          mutate(`${apiUrl}/api/products/get_cart/?user_id=${userId}`);
          mutate(
            `${apiUrl}/api/products/get_valentines_discount/?user_id=${userId}`
          );
        } else {
          dispatch(setDialog(Popups.Error));
        }
      } else {
        if (Platform.OS === "web") {
          navigation.navigate(Pages.Login);
        } else {
          dispatch(setDialog(Popups.LoginPopup));
        }
      }
    } catch (error) {}
    dispatch(hideLoader());
  };
  return deleteCart;
};
