import { Center, Image } from "native-base";

const EmailAnimatedIcon = () => {
  return (
    <Center size={"36px"} rounded="8px" bg="#8D49DE">
      <Image
        size={6}
        resizeMethod="scale"
        resizeMode="contain"
        source={require("./../../../assets/images/gifs/email.gif")}
        alt=""
      />
    </Center>
  );
};

export default EmailAnimatedIcon;
