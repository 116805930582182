import { HStack, IconButton } from "native-base";
import { useSelector } from "react-redux";
import { setAnalytics } from "../../../analytics";
import { Pages } from "../../../constants/pages";
import useGetUser from "../../../hooks/useGetUser";
import CogIconOutlined from "../../icons/cogIconOutlined";
import EditIconOutlined from "../../icons/editIconOutlined";
import ShareIcon from "../../icons/shareIcon";
import { BackIcon } from "./buildingBlocks/backIcon";
import { CenterText } from "./buildingBlocks/centerText";
import TopBar from "./templates/topBar";

const UserScreenTopBar = ({ route, navigation }) => {
  const userId = useSelector((state) => state.user.id);
  const { data } = useGetUser("profile", userId);

  return (
    <TopBar
      content={
        <>
          <BackIcon />
          <CenterText text={route?.params?.username} />
          <HStack>
            {route?.params?.type === "profile" &&
              data?.user[0]?.quiz_completed && (
                <IconButton
                  _pressed={{
                    bg: "#1A1C1E",
                  }}
                  onPress={() => {
                    setAnalytics("Settings Button", `Settings Button Clicked`, {
                      screen: route?.name,
                    });
                    navigation.navigate(Pages.AboutYourSelfScreen);
                  }}
                  icon={<EditIconOutlined />}
                />
              )}

            {route?.params?.type === "profile" && (
              <IconButton
                _pressed={{
                  bg: "#1A1C1E",
                }}
                onPress={() => {
                  setAnalytics("Settings Button", `Settings Button Clicked`, {
                    screen: route?.name,
                  });
                  navigation.navigate(Pages.SettingScreen, {
                    centerText: "Settings",
                  });
                }}
                icon={<CogIconOutlined color={"#888990"} />}
              />
            )}
            {route?.params?.type !== "profile" && (
              <ShareIcon
                type={"iosShareIcon"}
                path={`profile/${route.params.id}`}
                analytics={{
                  category: "Share Icon Beauty Buddy Screen Topbar",
                  event: `Shared Profile From Beauty Buddy Screen`,
                  eventProperties: {
                    profileId: route?.params?.id,
                    username: route?.params?.username,
                    screen: route?.name,
                  },
                }}
              />
            )}
          </HStack>
        </>
      }
    />
  );
};

export default UserScreenTopBar;
