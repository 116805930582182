import { useNavigation, useRoute } from "@react-navigation/native";
import {
  Center,
  HStack,
  IconButton,
  Image,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { setAnalytics } from "../../../analytics";
import EditIconOutlined from "../../../components/icons/editIconOutlined";
import { HAIRCOLOREMAP } from "../../../constants/hairColor";
import { Pages } from "../../../constants/pages";
import { SKINSHADEMAP } from "../../../constants/skinShade";

const BeautyQuiz = ({ user }) => {
  const route = useRoute();
  const navigation = useNavigation();

  return (
    <>
      {!user?.quiz_completed && route?.params?.type === "profile" ? (
        <Pressable
          onPress={() => navigation.navigate(Pages.AboutYourSelfScreen)}
        >
          <Image
            h="193px"
            w="696px"
            resizeMethod="scale"
            resizeMode="contain"
            source={require("./../../../assets/images/pngs/webquizbanner.png")}
            alt=""
          />
        </Pressable>
      ) : user?.quiz_completed ? (
        <VStack space={6} w="full">
          <HStack alignItems={"center"}>
            <Text
              color={"#682481"}
              fontSize={"30px"}
              style={{
                fontFamily: "AustinCyr_400",
              }}
            >
              Beauty Quiz
            </Text>
            {route?.params?.type === "profile" && (
              <IconButton
                onPress={() => {
                  setAnalytics(
                    "Profile Edit Icon",
                    `Profile Edit Icon Clicked`,
                    {
                      screen: route?.name,
                    }
                  );
                  navigation.navigate(Pages.AboutYourSelfScreen);
                }}
                icon={<EditIconOutlined color="#682481" />}
              />
            )}
          </HStack>
          <HStack space={"100px"} flexWrap={"wrap"}>
            {[
              user?.ethnicity
                ? { label: "Identity", detail: user?.ethnicity }
                : null,
              user?.birthday
                ? { label: "D.O.B", detail: user?.birthday }
                : null,
            ]
              .filter((f) => f)
              .map((k) => (
                <VStack space={3}>
                  <Text
                    textTransform={"uppercase"}
                    letterSpacing={2}
                    color={"#682481"}
                    fontSize="11px"
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                  >
                    {k?.label}
                  </Text>
                  <Text
                    letterSpacing={2}
                    color={"#000000D9"}
                    fontSize="12px"
                    style={{
                      fontFamily: "Hero_400Regular",
                    }}
                  >
                    {k?.detail}
                  </Text>
                </VStack>
              ))}
          </HStack>
          <HStack space={"100px"} flexWrap={"wrap"}>
            {[
              user?.skin_tone
                ? {
                    label: "skin tone",
                    detail: user?.skin_tone,
                    color: SKINSHADEMAP[user?.skin_tone],
                  }
                : null,
              user?.skin_type
                ? { label: "skin type", detail: user?.skin_type }
                : null,
              user?.skin_concerns && user?.skin_concerns?.length > 0
                ? { label: "skin concerns", detail: user?.skin_concerns }
                : null,
            ]
              .filter((f) => f)
              .map((k, i) => (
                <VStack space={3} mt={2}>
                  <Text
                    textTransform={"uppercase"}
                    letterSpacing={2}
                    color={"#682481"}
                    fontSize="11px"
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                  >
                    {k?.label}
                  </Text>
                  {i < 2 ? (
                    <HStack alignItems={"center"} space={1}>
                      {k?.color && (
                        <Center
                          size={"24px"}
                          rounded="full"
                          bg={k?.color}
                        ></Center>
                      )}
                      <Text
                        letterSpacing={2}
                        color={"#000000D9"}
                        fontSize="12px"
                        style={{
                          fontFamily: "Hero_400Regular",
                        }}
                      >
                        {k?.detail}
                      </Text>
                    </HStack>
                  ) : (
                    <HStack flexWrap="wrap" w="90%">
                      {k?.detail?.map((f) => (
                        <Center
                          m={1}
                          bg="#FAF1FF"
                          h="30px"
                          px={2}
                          rounded="full"
                        >
                          <Text
                            letterSpacing={2}
                            color={"#000000D9"}
                            fontSize="12px"
                            style={{
                              fontFamily: "Hero_400Regular",
                            }}
                          >
                            {f}
                          </Text>
                        </Center>
                      ))}
                    </HStack>
                  )}
                </VStack>
              ))}
          </HStack>
          <HStack space={"100px"} flexWrap={"wrap"}>
            {[
              user?.hair_color
                ? {
                    label: "hair color",
                    detail: user?.hair_color,
                    color: HAIRCOLOREMAP[user?.hair_color],
                  }
                : null,
              user?.hair_texture
                ? { label: "hair texture", detail: user?.hair_texture }
                : null,
              user?.hair_concerns && user?.hair_concerns?.length > 0
                ? { label: "hair concerns", detail: user?.hair_concerns }
                : null,
            ]
              .filter((f) => f)
              .map((k, i) => (
                <VStack space={3} mt={2}>
                  <Text
                    textTransform={"uppercase"}
                    letterSpacing={2}
                    color={"#682481"}
                    fontSize="11px"
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                  >
                    {k?.label}
                  </Text>
                  {i < 2 ? (
                    <HStack alignItems={"center"} space={1}>
                      {k?.color && (
                        <Center
                          size={"24px"}
                          rounded="full"
                          bg={k?.color}
                        ></Center>
                      )}
                      <Text
                        letterSpacing={2}
                        color={"#000000D9"}
                        fontSize="12px"
                        style={{
                          fontFamily: "Hero_400Regular",
                        }}
                      >
                        {k?.detail}
                      </Text>
                    </HStack>
                  ) : (
                    <HStack flexWrap="wrap" w="90%">
                      {k?.detail?.map((f) => (
                        <Center
                          bg="#FAF1FF"
                          h="30px"
                          m={1}
                          px={2}
                          rounded="full"
                        >
                          <Text
                            letterSpacing={2}
                            color={"#000000D9"}
                            fontSize="12px"
                            style={{
                              fontFamily: "Hero_400Regular",
                            }}
                          >
                            {f}
                          </Text>
                        </Center>
                      ))}
                    </HStack>
                  )}
                </VStack>
              ))}
          </HStack>
          <HStack space={"100px"} flexWrap={"wrap"}>
            {[
              user?.disliked_ingredients &&
              user?.disliked_ingredients?.length > 0
                ? {
                    label: "disliked ingredients",
                    detail: user?.disliked_ingredients,
                  }
                : null,
              user?.conscious_beauty_characteristics &&
              user?.conscious_beauty_characteristics?.length > 0
                ? {
                    label: "preferences",
                    detail: user?.conscious_beauty_characteristics,
                  }
                : null,
            ]
              .filter((f) => f)
              .map((k, i) => (
                <VStack space={3} mt={2}>
                  <Text
                    textTransform={"uppercase"}
                    letterSpacing={2}
                    color={"#682481"}
                    fontSize="11px"
                    style={{
                      fontFamily: "Hero_700Bold",
                    }}
                  >
                    {k?.label}
                  </Text>
                  <HStack space={1} flexWrap={"wrap"} w="90%">
                    {k?.detail?.map((f, l) => (
                      <Text
                        letterSpacing={2}
                        color={"#000000D9"}
                        fontSize="12px"
                        style={{
                          fontFamily: "Hero_400Regular",
                        }}
                      >
                        {f}
                        {k?.detail?.length > 1 &&
                          l < k?.detail?.length - 1 &&
                          ","}
                      </Text>
                    ))}
                  </HStack>
                </VStack>
              ))}
          </HStack>
        </VStack>
      ) : (
        <Center>
          <Text
            textTransform={"uppercase"}
            letterSpacing={2}
            color={"#682481"}
            fontSize="11px"
            style={{
              fontFamily: "Hero_700Bold",
            }}
          >
            No Info available
          </Text>
        </Center>
      )}
    </>
  );
};

export default BeautyQuiz;
