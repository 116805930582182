import { Icon } from "native-base";
import { Path, G } from "react-native-svg";
const WebMenuIcon = () => {
  return (
    <Icon viewBox="0 0 18 16">
      <G>
        <Path
          d="M0 1C0 0.447715 0.447715 0 1 0H17C17.5523 0 18 0.447715 18 1C18 1.55228 17.5523 2 17 2H1C0.447716 2 0 1.55228 0 1ZM6 8C6 7.44772 6.44772 7 7 7H17C17.5523 7 18 7.44772 18 8C18 8.55229 17.5523 9 17 9H7C6.44771 9 6 8.55229 6 8ZM0 15C0 14.4477 0.447715 14 1 14H17C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16H1C0.447716 16 0 15.5523 0 15Z"
          fill="#682481"
        />
      </G>
    </Icon>
  );
};

export default WebMenuIcon;
