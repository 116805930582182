import { Button, HStack, Text } from "native-base";
import { setAnalytics } from "../../analytics";
import * as RootNavigation from "./../../RootNavigation";
const AddPaymentDetailsGhostButton = () => {
  const navigation = RootNavigation;
  return (
    <>
      <HStack justifyContent={"space-between"} alignItems="center" w="full">
        <HStack>
          <Text
            textAlign={"center"}
            letterSpacing={2}
            textTransform={"uppercase"}
            color="#682481"
            fontSize={"14px"}
            style={{
              fontFamily: "Hero_700Bold",
            }}
          >
            Payment Details
          </Text>
        </HStack>
        <Button
          onPress={() => {
            navigation.navigate("addcard");
            setAnalytics(
              "Add Payment Details Card",
              `Add Payment Details Card Popup Opened`
            );
          }}
          w="100px"
          h="32px"
          _hover={{
            bg: "#FAF1FF",
          }}
          variant={"outline"}
          _text={{
            fontSize: "14px",
            color: "#464646",
            fontFamily: "Hero_400Regular",
          }}
        >
          + Add
        </Button>
      </HStack>
    </>
  );
};

export default AddPaymentDetailsGhostButton;
