const { Image, Icon, useColorMode, Center } = require("native-base");

const NormalSkinTypeIcon = () => {
  const { colorMode } = useColorMode();
  return (
    <Center bg="#F0F0F0" borderRadius={"10px"}>
      <Image
        width={62}
        height={70}
        resizeMethod="scale"
        resizeMode="contain"
        source={
          colorMode === "dark"
            ? require("./../../../../assets/images/pngs/grayNormalSkin.png")
            : require("./../../../../assets/images/pngs/lightinactivenormalskin.png")
        }
        alt=""
      />
    </Center>
  );
};

export default NormalSkinTypeIcon;
