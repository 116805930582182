import { useNavigation } from "@react-navigation/native";
import {
  Button,
  HStack,
  IconButton,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from "native-base";
import { Pages } from "../../../constants/pages";
import WebAppleIcon from "../../icons/webIcons/webAppleIcon";
import WebInstaIcon from "../../icons/webIcons/webInstaIcon";
import WebLinkedInIcon from "../../icons/webIcons/webLinkedInIcon";
import WebPlaystoreIcon from "../../icons/webIcons/webPlaystoreIcon";
import WebtiktokIcon from "../../icons/webIcons/webtiktokIcon";

const FooterSectionWeb = () => {
  const navigation = useNavigation();
  const leftSideTabs = [
    {
      label: "Shop",
      onPress: () => {
        navigation.navigate(Pages.ShopScreen);
      },
    },
    {
      label: "About",
      onPress: () => {
        navigation.navigate(Pages.AboutFlockScreen);
      },
    },
    {
      label: "Privacy",
      onPress: () => {
        navigation.navigate(Pages.PrivacyPolicyScreen);
      },
    },
    {
      label: "Terms",
      onPress: () => {
        navigation.navigate(Pages.Terms);
      },
    },
  ];
  return (
    <VStack w="full">
      <VStack
        h={{
          md: "727px",
          lg: "727px",
          xl: "448px",
        }}
        borderWidth={"1px"}
        borderColor="#FAF1FF"
        roundedTop={{
          base: "50px",
          sm: "50px",
          md: "50px",
          lg: "50px",
          xl: "100px",
        }}
        bg={{
          linearGradient: {
            colors: ["#FAF1FF", "#EED1FF"],
            start: [0, 0],
            end: [1, 0],
          },
        }}
        alignItems="center"
        justifyContent={"space-evenly"}
      >
        <Stack
          w="100%"
          pt="50px"
          direction={{
            md: "column",
            lg: "column",
            xl: "row",
          }}
          alignItems="center"
          justifyContent={"space-around"}
          space={8}
          flexWrap={"wrap"}
        >
          <Stack
            alignItems="center"
            direction={{
              md: "column",
              lg: "column",
              xl: "row",
            }}
            flexWrap="wrap"
          >
            {leftSideTabs.map((k) => (
              <Button
                onPress={k?.onPress}
                variant="ghost"
                _text={{
                  fontFamily: "Hero_700Bold",
                  color: "#464646",
                  fontSize: "14px",
                  textTransform: "uppercase",
                  letterSpacing: 4,
                }}
              >
                {k?.label}
              </Button>
            ))}
          </Stack>
          <VStack alignItems={"center"} space={3}>
            <Text
              letterSpacing={2}
              textAlign={"center"}
              color={"#682481"}
              fontSize="14px"
              textTransform={"uppercase"}
              style={{
                fontFamily: "Hero_700Bold",
              }}
            >
              Get the app
            </Text>
            <HStack space={2}>
              {[
                {
                  label: "App Store",
                  icon: <WebAppleIcon />,
                  link: "https://apps.apple.com/us/app/flock-shopping/id1638910085?itsct=apps_box_link&itscg=30200",
                },
                {
                  label: "Google Play",
                  icon: <WebPlaystoreIcon />,
                  link: "https://play.google.com/store/apps/details?id=com.flock.shopping",
                },
              ].map((k) => (
                <Link href={k?.link}>
                  <Button
                    rounded={"8px"}
                    bg="white"
                    borderWidth={"1px"}
                    borderColor="#464646"
                    startIcon={k?.icon}
                    h="35px"
                    variant="outline"
                    _text={{
                      fontFamily: "Hero_400Regular",
                      color: "black",
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    {k.label}
                  </Button>
                </Link>
              ))}
            </HStack>
          </VStack>
        </Stack>
        <Image
          h={{
            md: "100px",
            lg: "100px",
            xl: "121px",
          }}
          w={{
            md: "238px",
            lg: "238px",
            xl: "288px",
          }}
          resizeMethod="scale"
          resizeMode="contain"
          source={require("./../../../assets/images/pngs/flockPurpleLogo.png")}
          alt=""
        />
        <Stack
          w="100%"
          pt="50px"
          direction={{
            md: "column",
            lg: "column",
            xl: "row",
          }}
          alignItems="center"
          justifyContent={"space-around"}
        >
          <Text
            textAlign={"center"}
            color={"#682481"}
            fontSize="14px"
            textTransform={"uppercase"}
            style={{
              fontFamily: "HK_700Bold",
            }}
            letterSpacing={3}
          >
            © 2023. FLOCK SHOPPING. All rights reserved.
          </Text>
          <HStack space={2}>
            {[
              {
                icon: <WebLinkedInIcon />,
                link: "https://www.linkedin.com/company/76993115",
              },
              {
                icon: <WebInstaIcon />,
                link: "https://instagram.com/flockshopping?igshid=YmMyMTA2M2Y=",
              },
              {
                icon: <WebtiktokIcon />,
                link: "https://vm.tiktok.com/ZTdQjgDty/",
              },
            ].map((k) => (
              <Link href={k?.link}>
                <IconButton icon={k?.icon}></IconButton>
              </Link>
            ))}
          </HStack>
        </Stack>
      </VStack>
    </VStack>
  );
};

export default FooterSectionWeb;
