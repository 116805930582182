import { Icon } from 'native-base';
import { Path, G } from 'react-native-svg';

const DiscountRightBookmarkIconFilled = ({ size = 12 }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}
      <Icon size={size} viewBox='0 0 36 20'>
        <G width='36' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <Path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M35.9905 18.6885C35.9594 18.4688 35.8532 18.2608 35.684 18.0882L27.7622 10L35.684 1.91176C35.8532 1.73918 35.9594 1.53115 35.9905 1.31153C36.0215 1.0919 35.9762 0.869568 35.8597 0.670014C35.7432 0.47046 35.5602 0.301761 35.3317 0.183257C35.1032 0.0647532 34.8384 0.00124077 34.5678 0H1.44032C1.05833 0 0.691974 0.123949 0.421861 0.34458C0.151748 0.565211 0 0.864451 0 1.17647V20H2.88065H5.04089H34.5678C34.8384 19.9988 35.1032 19.9352 35.3317 19.8167C35.5602 19.6982 35.7432 19.5295 35.8597 19.33C35.9762 19.1304 36.0215 18.9081 35.9905 18.6885Z'
            fill='#8D49DE'
          />
        </G>
      </Icon>
    </>
  );
};

export default DiscountRightBookmarkIconFilled;
