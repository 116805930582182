import { VStack, FlatList, View } from "native-base";

import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { SkinConcerns } from "../../../../constants/skinConcerns";
import SearchBox from "./searchBox";
import CheckBox from "./checkbox";
import { changeFilter } from "../../../../redux/filter/actions";

const Item = ({ item, selectedSkin, setSelectedSkin, dispatch }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selected) {
      if (selectedSkin.includes(item)) {
        setSelected(true);
      }
    }
  }, [selectedSkin]);

  const onPress = () => {
    if (selectedSkin.includes(item)) {
      setSelected(false);
      const filtered = selectedSkin.filter((value) => value !== item);
      setSelectedSkin(filtered);
      // dispatch(changeFilter({ skinConcerns: filtered }));
    } else {
      setSelected(true);
      // dispatch(changeFilter({ skinConcerns: [...selectedSkin, item] }));
      setSelectedSkin([...selectedSkin, item]);
    }
  };
  return (
    <CheckBox
      onPress={onPress}
      checked={selected}
      label={SkinConcerns[item]}
      item={item}
    />
  );
};

const SkinProfile = ({ selectedSkin, setSelectedSkin }) => {
  const [concernResults, setConcernResults] = useState(
    Object.keys(SkinConcerns)
  );
  const dispatch = useDispatch();

  const renderItem = ({ item }) => (
    <Item
      item={item}
      selectedSkin={selectedSkin}
      setSelectedSkin={setSelectedSkin}
      dispatch={dispatch}
    />
  );

  return (
    <VStack width={"100%"} pb={8} px={4} space={6}>
      <SearchBox
        onChange={async (val) => {
          let concerns = Object.keys(SkinConcerns);
          let concernResult = concerns.filter((concern) => {
            return SkinConcerns[concern]
              .toLowerCase()
              .includes(val.toLowerCase());
          });
          setConcernResults(concernResult);
        }}
      />

      <FlatList
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => (
          <View style={{ paddingVertical: 11 }}></View>
        )}
        contentContainerStyle={{
          paddingBottom: 12,
        }}
        data={concernResults}
        initialNumToRender={12}
        renderItem={renderItem}
      />
    </VStack>
  );
};

export default SkinProfile;
