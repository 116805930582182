import { Button } from "native-base";

const NopeButton = ({ onPress, nopeButtonPressed }) => {
  return (
    <Button
      onPress={onPress}
      height={"61px"}
      width={"142px"}
      borderWidth={!nopeButtonPressed ? "2px" : "0px"}
      borderColor={"#58595B"}
      rounded={16}
      bg={nopeButtonPressed ? "#0F9737" : "#1C212E"}
      variant={nopeButtonPressed ? "solid" : "outline"}
      _text={{
        color: "#E5E5E5",
        fontFamily: "Poppins_500Medium",
        fontSize: 16,
        lineHeight: 24,
      }}
    >
      No
    </Button>
  );
};

export default NopeButton;
