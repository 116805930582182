import { Button, Center, HStack, Text, useColorModeValue } from "native-base";
import * as SMS from "expo-sms";
import { Platform } from "react-native";
import GreenTickIcon from "../icons/greenTickIcon";
import { getUser } from "../../util/utilFunctions";
import { useState } from "react";
import SendIcon from "../icons/filledIcons/sendIcon";
import ProfileFillIcon from "../icons/filledIcons/profileFillIcon";
import { setAnalytics } from "../../analytics";

const InviteSmsCard = ({ item }) => {
  const [isMessageSent, setIsMessageSent] = useState();
  const backgroundColor = useColorModeValue("white", "#111315");
  return (
    <HStack justifyContent={"space-around"} alignItems="center">
      <HStack alignItems="center" space={2} flex={1}>
        <ProfileFillIcon />
        <Text
          color={"white"}
          fontSize={"16px"}
          style={{
            fontFamily: "HK_500Medium",
          }}
        >
          {item?.name}
        </Text>
      </HStack>
      <Center
        h="30px"
        w="99px"
        bg={{
          linearGradient: isMessageSent
            ? {
                colors: [backgroundColor, backgroundColor],
                start: [0, 0],
                end: [1, 0],
              }
            : {
                colors: [
                  "primary.ctaLinearSecondColor",
                  "primary.ctaLinearFirstColor",
                ],
                start: [0, 0],
                end: [1, 0],
              },
        }}
        borderRadius={"6px"}
      >
        <Button
          onPress={async () => {
            const isAvailable = await SMS.isAvailableAsync();

            if (isAvailable) {
              const userId = await getUser();

              const { result } = await SMS.sendSMSAsync(
                item?.number,
                `Join me on Flock! Discover products curated for your skin and hair. Browse what your friends are buying. Shop together to get major discounts on thousands of beauty products! Download Flock here:, https://flock-5b35f.web.app/flock/?referralId=${userId}`
              );
              setAnalytics("Invite via sms", `Invited via sms`, {
                status: result,
              });
              if (result === "sent" && Platform.OS === "ios") {
                setIsMessageSent(true);
              } else if (result === "unknown" && Platform.OS === "android") {
                setIsMessageSent(true);
              } else {
                setIsMessageSent(false);
              }
            } else {
            }
          }}
          p={0}
          _pressed={{
            bg: isMessageSent ? backgroundColor : backgroundColor,
          }}
          h="28px"
          w="97px"
          bg={isMessageSent ? backgroundColor : backgroundColor}
          borderRadius={"6px"}
          _text={{
            fontFamily: "HK_500Medium",
            color: isMessageSent ? "#94A3B8" : "white",
            fontSize: "14px",
          }}
          startIcon={isMessageSent ? <GreenTickIcon /> : <SendIcon />}
        >
          {isMessageSent ? "Invited" : "Invite"}
        </Button>
      </Center>
    </HStack>
  );
};

export default InviteSmsCard;
