import { Icon } from "native-base";
import { Path, G } from "react-native-svg";
const ActiveCartIcon = () => {
  return (
    <Icon viewBox="0 0 31 30" size={6}>
      <G width="31" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M6.74844 12.8762C6.79398 12.3088 7.05159 11.7793 7.46996 11.3932C7.88833 11.0072 8.43676 10.7929 9.00603 10.793H22.658C23.2273 10.7929 23.7757 11.0072 24.1941 11.3932C24.6125 11.7793 24.8701 12.3088 24.9156 12.8762L25.8248 24.1981C25.8498 24.5097 25.81 24.8231 25.708 25.1185C25.6059 25.414 25.4438 25.6851 25.2318 25.9148C25.0199 26.1446 24.7627 26.3279 24.4764 26.4533C24.1901 26.5788 23.8809 26.6436 23.5683 26.6437H8.09575C7.78318 26.6436 7.47401 26.5788 7.18771 26.4533C6.90141 26.3279 6.64418 26.1446 6.43221 25.9148C6.22025 25.6851 6.05814 25.414 5.9561 25.1185C5.85405 24.8231 5.81428 24.5097 5.83929 24.1981L6.74844 12.8762V12.8762Z"
          fill="white"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          d="M20.3613 14.1897V8.52877C20.3613 7.32767 19.8841 6.17576 19.0348 5.32645C18.1855 4.47714 17.0336 4 15.8325 4C14.6314 4 13.4795 4.47714 12.6302 5.32645C11.7808 6.17576 11.3037 7.32767 11.3037 8.52877V14.1897"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </G>
    </Icon>
  );
};

export default ActiveCartIcon;
