import { privateApi } from "../util/API";
import { useDispatch } from "react-redux";
import { getUser } from "../util/utilFunctions";
import { showLoader, hideLoader } from "../redux/currentState/actions";
import { useSWRConfig } from "swr";
import Constants from "expo-constants";
import { useNavigation } from "@react-navigation/native";
import { Pages } from "../constants/pages";
const apiUrl = Constants.expoConfig.extra.apiUrl;
export const useClearCart = () => {
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const navigation = useNavigation();
  const clearCart = async () => {
    dispatch(showLoader());

    const userId = await getUser();
    try {
      if (userId !== null && userId !== undefined) {
        const res = await privateApi(
          "/api/products/delete_cart_items/",
          { user_id: userId },
          "post"
        );

        console.log("resdeltee", res, userId);
        if (res.status === 200) {
          mutate(`${apiUrl}/api/products/get_cart/?user_id=${userId}`);
          mutate(
            `${apiUrl}/api/products/get_valentines_discount/?user_id=${userId}`
          );
        } else {
        }
      } else {
        navigation.navigate(Pages.Login);
      }
      dispatch(hideLoader());
    } catch (error) {}
    dispatch(hideLoader());
  };

  return clearCart;
};
