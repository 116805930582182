import { Icon } from "native-base";
import { Path, G } from "react-native-svg";

const PlusIcon = ({ onPress, size = 3, color = "#94A3B8" }) => {
  return (
    <>
      {/*can give size prop in the Icon component to change the size, size={'5'}, eg: <Icon size={'5'} viewBox='0 0 24 24'> */}

      <Icon size={size} onPress={onPress} viewBox="0 0 10 10">
        <G
          width="10"
          height="10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            d="M10 4.16667H5.83333V0H4.16667V4.16667H0V5.83333H4.16667V10H5.83333V5.83333H10V4.16667Z"
            fill={color}
          />
        </G>
      </Icon>
    </>
  );
};

export default PlusIcon;
