import {
  RESET_PRODUCTS_FILTERS,
  CHANGE_PRODUCTS_FILTER,
  SET_PRODUCTS_PRICE,
  SET_APPLY_PRODUCTS_FILTER,
  RESET_PRODUCTS_CACHE_FILTERS,
  SET_PRODUCTS_CHIPS,
  DELETE_PRODUCTS_CHIPS,
  RESET_PRODUCTS_FILTERS_POPUP,
  DELETE_PRODUCTS_CHIPS_VALUE,
} from "./constants";

const initialState = {
  cachedFilters: {
    priceRangeFrom: 0,
    priceRangeTo: 700,
    discounts: [],
    brands: [],
    concerns: [],
    skinConcerns: [],
    productTypes: [],
    subproductType1: [],
    subproductType2: [],
    chips: [],
  },
  priceRangeFrom: 0,
  priceRangeTo: 700,
  discounts: [],
  brands: [],
  concerns: [],
  skinConcerns: [],
  productTypes: [],
  subproductType1: [],
  subproductType2: [],
  chips: [],
  type: "all",
};

const productsFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REFRESH":
      return {
        cachedFilters: {
          priceRangeFrom: 0,
          priceRangeTo: 700,
          discounts: [],
          brands: [],
          concerns: [],
          skinConcerns: [],
          productTypes: [],
          subproductType1: [],
          subproductType2: [],
          chips: [],
        },
        priceRangeFrom: 0,
        priceRangeTo: 700,
        discounts: [],
        brands: [],
        concerns: [],
        skinConcerns: [],
        productTypes: [],
        subproductType1: [],
        subproductType2: [],
        chips: [],
        type: "all",
      };
    case RESET_PRODUCTS_FILTERS:
      return {
        cachedFilters: {
          priceRangeFrom: 0,
          priceRangeTo: 700,
          discounts: [],
          brands: [],
          concerns: [],
          skinConcerns: [],
          productTypes: [],
          subproductType1: [],
          subproductType2: [],
          chips: [],
        },
        priceRangeFrom: 0,
        priceRangeTo: 700,
        discounts: [],
        brands: [],
        concerns: [],
        skinConcerns: [],
        productTypes: [],
        subproductType1: [],
        subproductType2: [],
        chips: [],
        type: "all",
      };
    case RESET_PRODUCTS_FILTERS_POPUP:
      if (state.type === "brand") {
        return {
          cachedFilters: {
            priceRangeFrom: 0,
            priceRangeTo: 700,
            discounts: [],
            brands: state.brands,
            concerns: state.concerns,
            skinConcerns: state.skinConcerns,
            productTypes: [],
            subproductType1: [],
            subproductType2: [],
            chips: [],
          },
          priceRangeFrom: 0,
          priceRangeTo: 700,
          discounts: [],
          brands: state.brands,
          concerns: state.concerns,
          skinConcerns: state.skinConcerns,
          productTypes: [],
          subproductType1: [],
          subproductType2: [],
          chips: [],
          type: "brand",
        };
      } else {
        return {
          cachedFilters: {
            priceRangeFrom: 0,
            priceRangeTo: 700,
            discounts: [],
            brands: [],
            concerns: [],
            skinConcerns: [],
            productTypes: state.productTypes,
            subproductType1: state.subproductType1,
            subproductType2: state.subproductType2,
            chips: [],
          },
          priceRangeFrom: 0,
          priceRangeTo: 700,
          discounts: [],
          brands: [],
          concerns: [],
          skinConcerns: [],
          productTypes: state.productTypes,
          subproductType1: state.subproductType1,
          subproductType2: state.subproductType2,
          chips: [],
          type: "all",
        };
      }
    case SET_PRODUCTS_PRICE:
      return {
        ...state,
        cachedFilters: {
          ...state.cachedFilters,
          priceRangeFrom: action.low,
          priceRangeTo: action.high,
        },
      };

    case SET_APPLY_PRODUCTS_FILTER:
      return {
        ...state.cachedFilters,
        cachedFilters: {
          ...state.cachedFilters,
        },
      };

    case RESET_PRODUCTS_CACHE_FILTERS:
      return {
        ...state,
        cachedFilters: {
          priceRangeFrom: state.priceRangeFrom,
          priceRangeTo: state.priceRangeTo,
          discounts: state.discounts,
          brands: state.brands,
          concerns: state.concerns,
          skinConcerns: state.skinConcerns,
          productTypes: state.productTypes,
          subproductType1: state.subproductType1,
          subproductType2: state.subproductType2,
          chips: state.chips,
        },
      };

    case CHANGE_PRODUCTS_FILTER:
      return {
        ...state,
        cachedFilters: {
          ...state.cachedFilters,
          ...action.filter,
        },
      };

    case SET_PRODUCTS_CHIPS:
      return {
        ...state,
        cachedFilters: {
          ...state.cachedFilters,
          chips: [...state.cachedFilters.chips, action.chip],
        },
      };
    case DELETE_PRODUCTS_CHIPS:
      return {
        ...state,
        chips: [
          ...state.chips.slice(0, action.itemId),
          ...state.chips.slice(action.itemId + 1),
        ],
        cachedFilters: {
          ...state.cachedFilters,
          chips: [
            ...state.chips.slice(0, action.itemId),
            ...state.chips.slice(action.itemId + 1),
          ],
        },
      };

    case DELETE_PRODUCTS_CHIPS_VALUE:
      return {
        ...state,
        chips: [...state.chips.filter((value) => value.value !== action.value)],
        cachedFilters: {
          ...state.cachedFilters,
          chips: [
            ...state.chips.filter((value) => value.value !== action.value),
          ],
        },
      };
    default:
      return state;
  }
};

export default productsFilterReducer;
