import { Text, Box, Pressable } from "native-base";

const LetsRollButton = ({ onPress }) => {
  return (
    <>
      {/* use Pressable component from native base to make custom buttons */}
      <Pressable
      position={"absolute"}
      bottom={"410px"}
      
      onPress={onPress}>
        <Box
          width={"200px"}
          height={"61px"}
          alignItems="center"
          flexDirection="row"
          justifyContent="space-around"
          borderWidth={"1.5px"}
          borderColor={"#ffffff"}
          rounded={16}
          _text={{
            color: "#ffffff",
          }}
        >
          <Text
            lineHeight={24}
            color="white"
            style={{
              fontFamily: "Poppins_500Medium",
            }}
            fontSize={16}
          >
            Let's Roll
          </Text>
        </Box>
      </Pressable>
    </>
  );
};

export default LetsRollButton;
