import { Box } from "native-base";
import ChevronBackArrowIcon from "../../icons/chevronBackArrowIcon";
import { CenterText } from "./buildingBlocks/centerText";
import TopBar from "./templates/topBar";

const CategoryScreenTopBar = ({ route, navigation }) => {
  return (
    <>
      {navigation?.isFocused() && (
        <>
          <TopBar
            content={
              <>
                <ChevronBackArrowIcon
                  onPress={() => {
                    navigation.goBack();
                  }}
                />
                <CenterText text={route?.params?.defaultValue} />
                <Box />
              </>
            }
          />
        </>
      )}
    </>
  );
};

export default CategoryScreenTopBar;
