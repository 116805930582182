import { Icon } from "native-base";
import { Path, G, Circle } from "react-native-svg";

const PlayIcon = () => {
  return (
    <Icon viewBox="0 0 75 75" size={20}>
      <G width="75" height="75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Circle cx="37.5" cy="37.5" r="37.5" fill="black" fill-opacity="0.5" />
        <Path
          d="M53 38L30.5 50.9904L30.5 25.0096L53 38Z"
          fill="white"
          fill-opacity="0.5"
        />
      </G>
    </Icon>
  );
};

export default PlayIcon;
