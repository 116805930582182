import {
    VIEW_GROUP,
    RESET_GROUP
}
    from './constants'

const initialState = {
    users: [],
    product: {},
    numberOfPositions: 3,
    timeOfStart: null,
    new: true
}

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REFRESH':
            return {
                users: [],
                product: {},
                numberOfPositions: 3,
                timeOfStart: null,
                new: true
            }

        case VIEW_GROUP:
            return {
                ...action.group
            }
        case RESET_GROUP:
            return {
                users: [],
                product: {},
                numberOfPositions: 3,
                timeOfStart: null,
                new: true
            }

        default: return state
    }
}

export default groupReducer