import { Share } from "react-native";
import { useSWRConfig } from "swr";
import { setAnalytics } from "../analytics";
import { publicApi } from "../util/API";
import { getUser } from "../util/utilFunctions";
import Constants from "expo-constants";
const apiUrl = Constants.expoConfig.extra.apiUrl;
export const useShare = () => {
  const { mutate } = useSWRConfig();
  const onShare = async (path, details) => {
    let userId = await getUser();

    try {
      let link = await createDeepLink(path, userId);
      console.log("link", link);
      const result = await Share.share(
        {
          url: link,
          message: link,
        },
        { dialogTitle: "Check this out on Flock!" }
      );

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
        } else {
          if (
            (path.startsWith("group") || path === "flock/") &&
            link !== null &&
            userId
          ) {
            let res = await publicApi(
              "/api/points/referral_code/",
              {
                deep_link: link,
                new_user: path === "flock/" ? true : false,
              },
              "post"
            );

            mutate(`${apiUrl}/api/points/rewards/?user_id=${userId}`);
          }
        }
      } else if (result.action === Share.dismissedAction) {
      }
      setAnalytics(details?.category, details?.event, details?.eventProperties);
    } catch (error) {}
  };

  return onShare;
};

export const createDeepLink = async (path, userId) => {
  let intermediateLink = `https://flock-5b35f.web.app/${path}/`;
  let fallBackPath = `https://flockshopping.com${path}/`;
  if (userId !== undefined && userId !== null) {
    intermediateLink = intermediateLink + "?referralId=" + userId;
    fallBackPath = fallBackPath + "?referralId=" + userId;
  }

  console.log("path", path, fallBackPath);
  const payload = {
    dynamicLinkInfo: {
      domainUriPrefix: "https://shoppingflock.page.link",
      link: intermediateLink,
      desktopInfo: {
        desktop_fallback_link: fallBackPath,
      },

      androidInfo: {
        androidPackageName: "com.flock.shopping",
        androidFallbackLink: fallBackPath,
      },
      iosInfo: {
        iosBundleId: "com.student47.flock",
        iosAppStoreId: "1638910085",
        iosFallbackLink: fallBackPath,
      },
      navigationInfo: {
        enableForcedRedirect: true,
      },
    },
  };
  const url =
    "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyB4i5MuUcsRJC7iahrjtK4dmIoCQHOIai4";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const json = await response.json();
  console.log("json", json);

  let link = json["shortLink"];

  return link;
};
